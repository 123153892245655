var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"lineup-widget",attrs:{"width":"580","lazy":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._t("default",null,{"slot":"activator"}),_c('v-form',{ref:"singleFieldForm",attrs:{"enctype":"multipart/form-data","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',{staticClass:"widget__heading"},[_vm._v(" Lineup ")]),_c('v-card-text',[(_vm.client == 'efl')?_c('match-picker-efl',{attrs:{"includeDetailedMatch":true},on:{"updated":function($event){return _vm.saveDetails($event)}}}):_c('match-picker',{on:{"updated":function($event){return _vm.saveDetails($event)}}}),(_vm.error)?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.client !== 'efl')?_c('div',{staticClass:"spacer"},[_c('label',[_vm._v("Teams to display")]),_c('v-select',{attrs:{"item-text":"name","item-value":"value","items":[
                            { name: 'Both', value: 'both' },
                            { name: 'Club', value: 'club' },
                            { name: 'Opposition', value: 'opposition' },
                        ],"solo":"","flat":"","required":""},on:{"change":function($event){_vm.status = 'needsSaving'}},model:{value:(_vm.updatedData.content.displayTeam),callback:function ($$v) {_vm.$set(_vm.updatedData.content, "displayTeam", $$v)},expression:"updatedData.content.displayTeam"}})],1):_vm._e(),_c('div',[_c('label',[_vm._v("Style")]),_c('v-select',{attrs:{"item-text":"name","item-value":"value","items":[
                            { name: 'Inline', value: '' },
                            { name: 'Inline: Subtle Crest', value: 'inline-subtle-crest' },
                        ],"solo":"","flat":"","required":""},on:{"change":function($event){_vm.status = 'needsSaving'}},model:{value:(_vm.updatedData.content.displayStyle),callback:function ($$v) {_vm.$set(_vm.updatedData.content, "displayStyle", $$v)},expression:"updatedData.content.displayStyle"}})],1),_c('label',[_vm._v("Home team starters")]),_c('v-text-field',{attrs:{"solo":"","flat":""},model:{value:(_vm.updatedData.content.homeTeamStarters),callback:function ($$v) {_vm.$set(_vm.updatedData.content, "homeTeamStarters", $$v)},expression:"updatedData.content.homeTeamStarters"}}),_c('label',[_vm._v("Home team subs")]),_c('v-text-field',{attrs:{"solo":"","flat":""},model:{value:(_vm.updatedData.content.homeTeamSubs),callback:function ($$v) {_vm.$set(_vm.updatedData.content, "homeTeamSubs", $$v)},expression:"updatedData.content.homeTeamSubs"}}),_c('label',[_vm._v("Away team starters")]),_c('v-text-field',{attrs:{"solo":"","flat":""},model:{value:(_vm.updatedData.content.awayTeamStarters),callback:function ($$v) {_vm.$set(_vm.updatedData.content, "awayTeamStarters", $$v)},expression:"updatedData.content.awayTeamStarters"}}),_c('label',[_vm._v("Away team subs")]),_c('v-text-field',{attrs:{"solo":"","flat":""},model:{value:(_vm.updatedData.content.awayTeamSubs),callback:function ($$v) {_vm.$set(_vm.updatedData.content, "awayTeamSubs", $$v)},expression:"updatedData.content.awayTeamSubs"}}),(_vm.updatedData.content.snapshotUTC)?_c('p',[_vm._v(" Snapshot taken at "+_vm._s(_vm._f("moment")(_vm.updatedData.content.snapshotUTC,"kk:mm (Z) DD/MM/YY"))+" ")]):_c('p',[_vm._v(" This widget will take a snapshot of the data "),_c('i',[_vm._v("now")]),_vm._v(". It will not update, unless you update this widget. ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){return _vm.validate()}}},[_vm._v(" Post ")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }