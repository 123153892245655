<template>
<section class="player">
  <v-form v-model="valid" ref="playerForm" lazy-validation>
    <v-layout class="main-body">
      <v-flex>
        <h2 class="subheading">User details</h2>
        <div class="player__panel">
          <v-flex align-center class="player__panel-details">
            <v-layout>
              <div class="flex sm12 md5">
                <label class="label" for="firstName">Email</label>
                <v-text-field id="username" :rules="emailRules" validate-on-blur placeholder="" solo flat v-model="updatedData.username" @change="updateData()"></v-text-field>
              </div>
              <div class="flex sm12 md5 offset-md2">
              </div>
            </v-layout>
            <v-layout>
              <div class="flex sm12 md5">
                <label class="label" for="firstName">First name</label>
                <v-text-field id="firstName" :rules="[rules.required]" validate-on-blur placeholder="First name" solo flat v-model="updatedData.firstName" @change="updateData()"></v-text-field>
              </div>
              <div class="flex sm12 md5 offset-md2">
                <label class="label" for="lastName">Last name</label>
                <v-text-field id="lastName" :rules="[rules.required]" validate-on-blur placeholder="Last name" solo flat v-model="updatedData.lastName" @change="updateData()"></v-text-field>
              </div>
            </v-layout>
          </v-flex>
        </div>

        <h2 class="subheading">Temporary password</h2>
        <div class="player__panel">
          <v-flex align-center class="player__panel-details">
            <v-layout>
              <div class="flex sm12 md5">
                <label class="label" for="firstName">Password</label>
                <v-text-field id="tempPassword" :rules="passRules" validate-on-blur placeholder="Temp password" solo flat v-model="updatedData.tempPassword" @change="updateData()"></v-text-field>
              </div>
              <div class="flex sm12 md5 offset-md2">
                This password will be sent to the user via email and will be required for their first login, at which point they will then set their own.
              </div>
            </v-layout>
          </v-flex>
        </div>

        <h2 class="subheading">User permissions</h2>
        <div class="player__panel">

          <!-- <pre>{{ associatedUserGroups }}</pre>

          <pre>{{ allUserGroups }}</pre> -->

          <table class="permissions-table" cellspacing="0">

            <tr class="bottom-divider">
              <td>Administrator</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.Administrator"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('Administrator') }}</p>
              </td>
            </tr>

            <tr v-if="modules.default.News">
              <td>News</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.News"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('News') }}</p>
              </td>
            </tr>

            <tr v-if="modules.default.Pages">
              <td>Content / Pages</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.Content"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('Content') }}</p>
              </td>
            </tr>

            <tr v-if="modules.default.SiteConfig">
              <td>Website Manager</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.WebsiteManager"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('WebsiteManager') }}</p>
              </td>
            </tr>

            <tr v-if="modules.default.AppConfig">
              <td>App Manager</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.AppManager"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('AppManager') }}</p>
              </td>
            </tr>

            <tr v-if="modules.default.Videos">
              <td>VideoManager</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.VideoManager"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('VideoManager') }}</p>
              </td>
            </tr>

            <!-- <tr>
              <td>Homepage Manager</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.HomepageManager"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('HomepageManager') }}</p>
              </td>
            </tr> -->

            <tr v-if="modules.default.Forms">
              <td>Forms</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.Forms"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('Forms') }}</p>
              </td>
            </tr>

            <tr v-if="modules.default.Organisations" class="bottom-divider">
              <td>Organisations</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.Organisations"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('Organisations') }}</p>
              </td>
            </tr>

            <tr v-if="modules.default.Fixtures">
              <td>Fixtures</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.Fixtures"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('Fixtures') }}</p>
              </td>
            </tr>

            <tr v-if="modules.default.Squads">
              <td>Squads</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.Squads"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('Squads') }}</p>
              </td>
            </tr>

            <tr v-if="modules.default.MatchdayTakeover" class="bottom-divider">
              <td>Matchday Takeover</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.MatchdayTakeover"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('MatchdayTakeover') }}</p>
              </td>
            </tr>

            <tr v-if="modules.default.OverlayPromos">
              <td>Overlay Promos</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.OverlaysPromo"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('OverlaysPromo') }}</p>
              </td>
            </tr>

            <tr v-if="modules.default.Advertising || modules.default.Lab">
              <td>Advertising</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.Advertising"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('Advertising') }}</p>
              </td>
            </tr>

            <tr v-if="modules.default.Sponsors" class="bottom-divider">
              <td>Sponsors</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.Sponsors"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('Sponsors') }}</p>
              </td>
            </tr>

            <tr v-if="modules.default.SsoUsers || modules.default.Streamline" class="bottom-divider">
              <td>User Management</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.SSOUserManagement"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('SSOUserManagement') }}</p>
              </td>
            </tr>

            <!-- <tr>
              <td>Events</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.Events"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('Events') }}</p>
              </td>
            </tr>

            <tr>
              <td>Polls</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.Polls"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('Polls') }}</p>
              </td>
            </tr>

            <tr>
              <td>SSOContentManager</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.SSOContentManager"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('SSOContentManager') }}</p>
              </td>
            </tr>  -->

            <!-- <tr v-for="group in allUserGroups" :key="group.GroupName">
              <td>{{ group.GroupName }}</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups[group.GroupName]"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ group.Description }}</p>
              </td>
            </tr> -->

          </table>
        </div>

      </v-flex>
    </v-layout>
  </v-form>

  <StickyFooter :errorMessage="errorMessage" :status="status" :buttonText="'Create Admin User'" @buttonClicked="create()" />
</section>
</template>

<script>
import axios from "axios"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
  name: "SquadManagement",

  components: {
    StickyFooter
  },

  props: {
    data: {
      type: Object,
      required: false
    }
  },

  data: () => {
    return {
      api: `${process.env.VUE_APP_BASEURL}/adminusers`,
      modules: null,
      updatedData: {},
      status: "saved",
      errorMessage: "",
      dateModalDob: null,
      dateModalDoj: null,
      valid: false,
      saveDisabled: true,
      allUserGroups: [],
      associatedUserGroups: {},
      rules: {
        required: value => !!value || "Required."
        // groups: value => !!this.updatedData.isContent || 'Required.'
      },
      emailRules: [
        value => !!value || "Email address is required",
        value => /.+@.+/.test(value) || "Email must be valid"
      ],
      passRules: [
        value => !!value || "Password is required",
        value => (value && value.length >= 8) || "Password must be at least 8 characters",
        value => /.*[a-z].*/.test(value) || "Password must contain at least one lower case letter",
        value => /.*[A-Z].*/.test(value) || "Password must contain at least one upper case letter",
        value => /\d/.test(value) || "Password must contain at least one number",
        value => /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value) || "Password must contain at least one special character (e.g. ^ $ * . ! @ # % &)"
      ],

    }
  },

  created() {
    this.modules = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
    this.defaultSettings = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/defaultSettings.js")
  },

  mounted() {
    if (this.defaultSettings.default.customPassRules) {
      const customPassRules = this.defaultSettings.default.customPassRules
      this.passRules = [
        value => !!value || "Password is required",
        value => (value && value.length >= (customPassRules.length || 8)) || `Password must be at least ${(customPassRules.length || 8)} characters`,
        value => /.*[A-Z].*/.test(value) || "Password must contain at least one upper case letter",
        value => /\d/.test(value) || "Password must contain at least one number",
        value => /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value) || "Password must contain at least one special character (e.g. ^ $ * . ! @ # % &)"
      ]
      if (customPassRules && customPassRules.lowercase) {
        this.passRules.push(value => /.*[a-z].*/.test(value) || "Password must contain at least one lower case letter")
      }
    }

    this.updatedData = {
      username: null,
      firstName: null,
      lastName: null,
      tempPassword: null,
      groups: null
    }

    this.generateTempPassword()

    this.getPermissionGroups()
    
    this.dataLoaded = true
    this.status = "needsSaving"
    this.$store.commit("completeLoading")
    
  },

  methods: {
    fetchDescription(thisGroupName) {
      for (const group of this.allUserGroups) {
        if (group.GroupName === thisGroupName) {
          return group.Description
        }
      }

      return "No description"
    },

    getPermissionGroups() {
      console.log("enable user")
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/usergroups`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("User groups", response)
          this.allUserGroups = JSON.parse(response.data.body)
        })
        .catch(e => {
          console.error("Problem getting groups", e);
        });
      })
    },

    updateCurrentPermissions() {
      for (const permission of this.updatedData.groups) {
        console.log("updateCurrentPermissions", permission)
        this.associatedUserGroups[permission.GroupName] = true
      }
    },

    updateData() {
      this.status = "needsSaving";
      console.log(this.updatedData);
    },

    create() {
      this.errorMessage = ""
      if (this.$refs.playerForm.validate()) {
        console.log("Validation passed");
        this.addUser();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },

    addUser() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        var data = {
          firstName: this.updatedData.firstName,
          lastName: this.updatedData.lastName,
          username: this.updatedData.username.toLowerCase(),
          email: this.updatedData.username.toLowerCase(),
          tempPassword: this.updatedData.tempPassword,
        }

        const obj = this.associatedUserGroups

        const keys = Object.keys(obj);

        data.associatedUserGroups = keys.filter(function(key) {
          return obj[key]
        });

        axios({
          method: "POST",
          url: `${this.api}`,
          headers: { "Authorization": jwt },
          data
        })
        .then(response => {
          console.log("Saved player:", response);
          console.log("USER ADDED", response.data)
          this.status = "saved";
          if (response.data.success === true) {
            var userData = JSON.parse(response.data.body)
            this.$router.push({
              path: "/admin-users"
            })
          }
        })
        .catch(e => {
          console.error("Problem adding admin user", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem adding user";
        });
      })
    },

    generateTempPassword() {
      var alphaChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      var alphaLen = 10;
      var alphaString = Array(alphaLen).fill(alphaChars).map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join("");
      var numChars = "1234567890";
      var numLen = 3;
      var numString = Array(numLen).fill(numChars).map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join("");
      var otherChars = "!@£$%^&*()";
      var otherLen = 3;
      var otherString = Array(otherLen).fill(otherChars).map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join("");
      var unshuffledString = alphaString+numString+otherString;
      this.updatedData.tempPassword = unshuffledString.split("").sort(function(){return 0.5-Math.random()}).join("");
    }   
  }
}
</script>

<style lang="scss" scoped>
  .player {
    &__panel {
      border: 1px solid $lightgrey;
      background-color: $white;
      padding: 20px 20px 0;
      margin-bottom: 20px;

    &-details {
      flex-direction: column;

      @media (min-width: 960px) {
        flex-direction: row;
      }
    }

    &-img {
      img {
        width: 100%;
      }
    }

    &-opta {
      &-cb {
        label {
          margin-bottom: 0;
        }
      }
    }
  }
}

  .input {
    &__textfield {
      &--basic {
        margin-top: 0;
      }
    }
  }

  .permissions-table {
    tr:last-child td {
      border-bottom: 0 !important;
    }


    tr.bottom-divider td {
      border-bottom: 4px solid #ccc;
    }

    td {
      border-bottom: 1px solid #ebebeb;

      &:first-child {
        text-align: right;
        padding: 0 16px 0 0;
      }

      p {
        margin: 0;
        padding: 0 0 0 40px;
        font-size: 12px;
        text-align: right;
        opacity: 0.7;
      }
    }
  }
</style>
