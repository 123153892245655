<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <!-- <p class="widget-info">TODO: STREAMLINECALENDAR WIDGET INFO.</p> -->

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()">Use this widget</v-btn>
    </template>

    <div class="widget-fieldset" v-else>
      <label class="label">Title</label>
      <v-text-field
        id="widgetData.title"
        solo
        flat
        v-model="updatedData.widgetData.title">
      </v-text-field>

      <label class="label">Style</label>
      <v-select
        id="widgetData.style"
        :items="styleOptions"
        solo
        flat
        v-model="updatedData.widgetData.style">
      </v-select>

      <label class="label">No listings message</label>
      <v-text-field
        id="widgetData.noDataText"
        solo
        flat
        v-model="updatedData.widgetData.noDataText">
      </v-text-field>

      <label class="label">Packages Link</label>
      <v-text-field
        id="widgetData.packagesLink"
        solo
        flat
        v-model="updatedData.widgetData.packagesLink">
      </v-text-field>

    </div>

  </div>

</template>

<script>
export default {
  name: "EventsCalendar",

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent()
      },
      deep: true
    }
  },

  props: {
    editData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    styleOptions: [{text: "Light Mode", value: "light"}, {text: "Dark Mode", value: "dark"}],
    updatedData: {
      title: null,
      style: null,
      noDataText: null,
      packagesLink: null
    }
  }),

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.editData))
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "EventsCalendar"
      this.updatedData.widgetName = "Events Calendar"
      this.updatedData.widgetID = this.$uuid.v1()
      this.updatedData.widgetData = {
        title: null,
        style: null,
        noDataText: null,
        packagesLink: null
      }
      this.$emit("initialised")
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData)
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
