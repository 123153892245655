<template>
  <section class="main-body">
    <v-btn v-if="allowInitData" color="accent" @click="initData()">Initialise Data</v-btn>

    <h3 class="subheading">Hero (Activated user)</h3>
    <section class="section-container">

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="heroActiveMessage" title="rewardsData.heroActiveMessage">Message (Activated User)</label>

          <v-text-field
            id="heroActiveMessage"
            v-model="rewardsData.heroActiveMessage"
            solo
            flat
            @change="updated()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>* Required</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="heroActiveButtonText" title="rewardsData.heroActiveButtonText">Button Text (Activated User)</label>

          <v-text-field
            id="heroActiveButtonText"
            v-model="rewardsData.heroActiveButtonText"
            solo
            flat
            @change="updated()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>* Required</i></p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading">Hero (Not logged in user)</h3>
    <section class="section-container">
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="heroNotLoggedInMessage" title="rewardsData.heroNotLoggedInMessage">Message (Not logged in)</label>

          <v-text-field
            id="heroNotLoggedInMessage"
            v-model="rewardsData.heroNotLoggedInMessage"
            solo
            flat
            @change="updated()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>* Required</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="heroNotLoggedInButtonText" title="rewardsData.heroNotLoggedInButtonText">Button Text (Activated User)</label>

          <v-text-field
            id="heroNotLoggedInButtonText"
            v-model="rewardsData.heroNotLoggedInButtonText"
            solo
            flat
            @change="updated()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>* Required</i></p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading">Hero (Requires activation user)</h3>
    <section class="section-container">
      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="heroRequiresActivatingMessage" title="rewardsData.heroRequiresActivatingMessage">Message (Requires Activation)</label>

          <v-text-field
            id="heroRequiresActivatingMessage"
            v-model="rewardsData.heroRequiresActivatingMessage"
            solo
            flat
            @change="updated()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>* Required</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="heroRequiresActivatingButtonText" title="rewardsData.heroRequiresActivatingButtonText">Button Text (Requires Activation)</label>

          <v-text-field
            id="heroRequiresActivatingButtonText"
            v-model="rewardsData.heroRequiresActivatingButtonText"
            solo
            flat
            @change="updated()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>* Required</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="heroRequiresActivatingButtonUrl" title="rewardsData.heroRequiresActivatingButtonUrl">Button URL (Requires Activation)</label>

          <v-text-field
            id="heroRequiresActivatingButtonUrl"
            v-model="rewardsData.heroRequiresActivatingButtonUrl"
            solo
            flat
            @change="updated()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>* Required</i></p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading">Content</h3>
    <section class="section-container">
      <draggable
        v-if="rewardsData.rewardsPageData"
        v-model="rewardsData.rewardsPageData"
        :options="{
          ghostClass: 'ghost',
          dragClass: 'sortable-drag'
        }"
        @start="drag=true"
        @end="drag=false; status = 'needsSaving';">

        <transition-group name="list-complete" class="featured__items">

          <div v-for="(row, index) in rewardsData.rewardsPageData" class="featured__item draggable-item" :key="row.rowID">
            <div class="featured__item-content">
              <v-icon class="draggable-item__handle-icon">open_with</v-icon>
              <div class="featured__item-content-title">{{ row.rowTitle || 'No title found' }}</div>

              <div class="featured__actions">
                <button class="featured__edit" type="button" @click="openRow(row, index)">
                  <v-icon>edit</v-icon>
                </button>
                <div
                  class="featured__item-content-action"
                  @click="deleteRow(index)">
                  <v-icon color="accent" right>remove_circle</v-icon>
                </div>
              </div>
            </div>
          </div>

        </transition-group>
      </draggable>

      <v-btn @click="openRow()">Add row</v-btn>
    </section>

    <edit-rewards-row
      v-if="rowEditorOpen"
      :rowData="rowData"
      @addRow="addRow($event)"
      @editRow="editRow($event)"
      @closeRow="rowEditorOpen = false; rowData = null">
    </edit-rewards-row>

    <h3 class="subheading">Setup</h3>
    <p>Warning: Changing these settings will change how your rewards system works. Only Administrators can edit these</p>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="rewardsPageName" title="rewardsData.rewardsPageName">Rewards Name</label>

          <v-text-field
            :disabled="!superUser"
            id="rewardsPageName"
            v-model="rewardsData.rewardsPageName"
            solo
            flat
            @change="updated()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>* Required</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="rewardsAccountUrl" title="rewardsData.rewardsAccountUrl">Account URL</label>

          <v-text-field
            :disabled="!superUser"
            id="rewardsAccountUrl"
            v-model="rewardsData.rewardsAccountUrl"
            solo
            flat
            @change="updated()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>* Required</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="displayType" title="rewardsData.displayType">Display Type</label>
          <v-select
            :disabled="!superUser"
            id="displayType"
            outline
            v-model="rewardsData.displayType"
            item-text="name"
            item-value="id"
            :items="[
              {name: 'Cash', id: 'cash'},
              {name: 'Points', id: 'points'},
            ]"
            solo
            flat
          ></v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>* Required</i></p>
          <p><i>This will change how the rewards system is displayed in the UI</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="pointCashConversionRate" title="rewardsData.pointCashConversionRate">Points to Cash Conversion Rate</label>
          <v-text-field
            :disabled="!superUser"
            id="pointCashConversionRate"
            type="number"
            step="0.01"
            v-model="rewardsData.pointCashConversionRate"
            solo
            flat
            @change="updated()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>This will change the Conversion Rate</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="displayCurrency" title="rewardsData.displayCurrency">Currency</label>
          <v-select
            :disabled="!superUser"
            id="displayCurrency"
            outline
            v-model="rewardsData.displayCurrency"
            item-text="name"
            item-value="id"
            :items="[
              {name: 'GBP', id: 'GBP'},
            ]"
            solo
            flat
          ></v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>If using Cash this will change the currency used</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="displayCurrencySymbol" title="rewardsData.displayCurrencySymbol">Currency Symbol</label>
          <v-select
            :disabled="!superUser"
            id="displayCurrencySymbol"
            outline
            v-model="rewardsData.displayCurrencySymbol"
            item-text="name"
            item-value="id"
            :items="[
              {name: '£', id: '&#163;'},
            ]"
            solo
            flat
          ></v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>If using Cash this will change the currency symbol used</i></p>
        </v-flex>
      </v-layout>
    </section>

    <StickyFooter :errorMessage="errorMessage" :status="status" :buttonText="'Save'" @buttonClicked="saveData()" />
    
  </section>
</template>

<script>
import axios from "axios"
import draggable from "vuedraggable"
import StickyFooter from "@/components/Library/StickyFooter"
import EditRewardsRow from "@/components/Rewards/EditRewardsRow";

export default {
  name: "RewardsManager",

  components: {
    draggable,
    StickyFooter,
    EditRewardsRow
  },

  data: () => ({
    api: `${process.env.VUE_APP_REWARDS}/rewards`,
    allowInitData: false,
    rewardsID: "",
    rewardsData: {},
    rowEditorOpen: false,
    rowData: null,
    editIndex: null,
    status: "saved",
    errorMessage: "",
    superUser: false
  }),

  mounted() {
    console.log("Rewards manager mounted");
    this.$getCurrentUserGroups().subscribe((userGroups) => {
      if (userGroups.includes("Administrator")) {
        this.superUser = true
      } else {
        this.superUser = false
      }
    })

    this.fetchData();
  },

  methods: {
    initData() {
      if (this.allowInitData) {
        this.rewardsData.rewardsPageName = "Rewards"
        this.rewardsData.rewardsAccountUrl = ""
        this.rewardsData.heroActiveMessage = "What are rewards?"
        this.rewardsData.heroActiveButtonText = "Account"
        this.rewardsData.heroRequiresActivatingMessage = "Your account needs activating"
        this.rewardsData.heroRequiresActivatingButtonText = "Link account"
        this.rewardsData.heroRequiresActivatingButtonUrl = ""
        this.rewardsData.heroNotLoggedInMessage = "You need to be signed in to access rewards"
        this.rewardsData.heroNotLoggedInButtonText = "Sign in"
        this.rewardsData.rewardsPageData = []
        this.rewardsData.displayType = "cash" // cash || points
        this.rewardsData.pointCashConversionRate = 0.01
        this.rewardsData.displayCurrency = "GBP"
        this.rewardsData.displayCurrencySymbol = "&#163;"

        this.allowInitData = false

        this.updated()
      }
    },

    updated() {
      this.status = "needsSaving"
    },

    openRow(payload, editIndex) {
      if (payload) {
        this.rowData = payload
        this.editIndex = editIndex
      }
      this.rowEditorOpen = true
    },

    addRow(payload) {
      this.rowData = null
      this.rewardsData.rewardsPageData.push(payload)
      this.rowEditorOpen = false

      this.updated()
    },

    editRow(payload) {
      this.rowData = null
      this.rewardsData.rewardsPageData[this.editIndex] = payload
      this.rowEditorOpen = false
      
      this.updated()
    },

    deleteRow(index) {
      this.rewardsData.rewardsPageData.splice(index, 1);

      this.updated()
    },

    fetchData() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("fetchData: ", response);

          if (response.data.data.length > 0) {
            this.rewardsData = response.data.data[0].attributes
            this.rewardsID = response.data.data[0].id
          } else {
            this.allowInitData = true
          }

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving rover data", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    saveData() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PUT",
          url: `${this.api}/${this.rewardsID}`,
          headers: { "Authorization": jwt },
          data: {
            "data": {
              "type": "rewards",
              "id": this.rewardsData.id,
              "attributes": this.rewardsData
            }
          }
        })
        .then(response => {
          console.log("Success", response)
          this.status = "saved",
          this.$store.commit("completeLoading")
        })
        .catch(e => {
          console.error("Error", e)
          this.$store.commit("completeLoading")
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .half {
    width: 50%;
  }

  .section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #DFE3E9;
  }

  .draggable-item {
    transition: all 0.5s, opacity 0.2s;

    &__handle-icon {
      cursor: pointer;
      padding-right: 20px;
    }
  }

  .featured {
    &__subheading {
      margin-top: 0;
    }

    &__item {
      border: 1px solid rgba($purple, 0.7);
      background-color: rgba($purple, 0.1);
      border-radius: 3px;
      margin-bottom: 20px;

      &-content {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &-title {
          color: $purple;
          font-size: 1.4rem;
        }

        &-action {
          cursor: pointer;
        }
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin: 0 0 0 auto;
    }
  }

</style>
