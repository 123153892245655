<template>
    <generic-item :genericData="blogSectionData" />
</template>

<script>
import GenericItem from '../Library/GenericSectionManager/GenericItem.vue';
import { blogSectionData } from "./blogSectionData";

export default {
    name: "BlogManagement",

    components: {
        GenericItem
    },

    setup() {
        return {
            blogSectionData,
        }
    },
}
</script>

<style scoped lang='scss'>
</style>
