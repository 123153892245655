<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <p class="widget-info">This widget allows you to build a carousel of the latest matches of a club.</p>

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()">Use this widget</v-btn>
    </template>

    <div class="widget-fieldset" v-else>
      <label class="label">Title</label>
      <v-text-field
        id="widgetData.title"
        solo
        flat
        v-model="updatedData.widgetData.title">
      </v-text-field>

      <template v-if="this.$route.query.isApp !== 'true'">
        <!-- <label class="label">Style</label>
        <v-select
          id="widgetData.style"
          :items="styleOptions"
          solo
          flat
          v-model="updatedData.widgetData.style">
        </v-select> -->

        <label class="label">Button URL</label>
        <v-text-field
          id="widgetData.buttonURL"
          solo
          flat
          v-model="updatedData.widgetData.buttonURL">
        </v-text-field>
        
        <label class="label">Button Text</label>
        <v-text-field
        id="widgetData.buttonText"
        solo
        flat
        v-model="updatedData.widgetData.buttonText">
        </v-text-field>
      </template>

      <template v-if="teamData.length">
        <label class="label">Team</label>
        <v-select
          v-model="updatedData.widgetData.teamID"
          item-text='teamName'
          item-value='teamID'
          :items="teamData"
          solo
          flat
          />
      </template>
      <p v-else>Loading...</p>
     
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "MatchesCarousel",

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent()
      },
      deep: true
    }
  },

  props: {
    editData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}`,
    styleOptions: [{text: "Light Mode", value: "light"}, {text: "Dark Mode", value: "dark"}, {text: "Club Mode", value: "club"}],
    teamData: [],
    updatedData: {
      title: null,
      style: "club",
      buttonUrl: null,
      buttonText: null,
      teamID: ""
    },
  }),

  created() {
    this.getTeams()
  },

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.editData))
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "MatchesCarousel"
      this.updatedData.widgetName = "Matches Carousel"
      this.updatedData.widgetID = this.$uuid.v1()
      this.updatedData.widgetData = {
        style: "club", // Background colour - light || dark || club - currently club only for this component
        buttonUrl: null,
        buttonText: null,
        teamID: ""
      }
      this.$emit("initialised")
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData)
    },

    getTeams() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
      axios
        .get(`${this.api}/fixtures/opta/filters`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("getTeams response: ", response);
          this.teamData = response.data.body
        })
        .catch(e => {
          console.log("Unable to load teams:", e);
        })
      })
    },
  }
}
</script>

<style scoped lang='scss'>

</style>
