export default {
  aggregatedFeed: {
    showAllNews: 1
  },
  allowUpsellInApp: false,
  pageTypes: [
    {value: "internal", text: "Website Page"},
    {value: "external", text: "External Page"},
    {value: "internalLink", text: "Internal Page Link"},
    // {value: 'homepage', text: 'Homepage'},
  ],
  platformSettings: {
    showWeb: true,
    showApp: true
  },
  defaultNewsCardStyle: "Stack",
  playerManagement: "V3",
  customDataFields: {
    customDataFieldOneName: "",
    customDataFieldOneDescription: "",
    customDataFieldTwoName: "",
    customDataFieldTwoDescription: "",
    customDataFieldThreeName: "",
    customDataFieldThreeDescription: "",
  },
  videoSessionSSOUser: "4000588",
  customTags: [],
  MFARequired: true,
  customPassRules: {
    length: 14,
    lowercase: false,
  },
  supportedCurrencies: [
    {name: "AED", value: "AED"},
    {name: "AUD", value: "AUD"},
    {name: "BHD", value: "BHD"},
    {name: "BND", value: "BND"},
    {name: "CAD", value: "CAD"},
    {name: "CHF", value: "CHF"},
    {name: "CZK", value: "CZK"},
    {name: "DKK", value: "DKK"},
    {name: "EUR", value: "EUR"},
    {name: "GBP", value: "GBP"},
    {name: "HKD", value: "HKD"},
    {name: "IDR", value: "IDR"},
    {name: "ILS", value: "ILS"},
    {name: "INR", value: "INR"},
    {name: "ISK", value: "ISK"},
    {name: "JPY", value: "JPY"},
    {name: "KWD", value: "KWD"},
    {name: "MYR", value: "MYR"},
    {name: "NOK", value: "NOK"},
    {name: "NZD", value: "NZD"},
    {name: "QAR", value: "QAR"},
    {name: "RUB", value: "RUB"},
    {name: "SEK", value: "SEK"},
    {name: "SGD", value: "SGD"},
    {name: "THB", value: "THB"},
    {name: "USD", value: "USD"},
    {name: "VND", value: "VND"},
  ]
}