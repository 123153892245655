import { render, staticRenderFns } from "./DraggablePageRow.vue?vue&type=template&id=f0436dbc&scoped=true"
import script from "./DraggablePageRow.vue?vue&type=script&lang=js"
export * from "./DraggablePageRow.vue?vue&type=script&lang=js"
import style0 from "./DraggablePageRow.vue?vue&type=style&index=0&id=f0436dbc&prod&lang=scss&scoped=true"
import style1 from "./DraggablePageRow.vue?vue&type=style&index=1&id=f0436dbc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0436dbc",
  null
  
)

export default component.exports