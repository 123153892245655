export default {
  aggregatedFeed: {
    showAllNews: 1
  },
  allowUpsellInApp: false,
  pageTypes: [
    { value: "internal", text: "Website Page" },
    // {value: 'external', text: 'External Page'},
    { value: "homepage", text: "Homepage" },
  ],
  videoSessionSSOUser: "5170559",
  platformSettings: {
    showWeb: true,
    showApp: true
  },
  defaultNewsCardStyle: "Stack",
  playerManagement: "V1",
  customDataFields: {
    customDataFieldOneName: "",
    customDataFieldOneDescription: "",
    customDataFieldTwoName: "",
    customDataFieldTwoDescription: "",
    customDataFieldThreeName: "",
    customDataFieldThreeDescription: "",
  },
  customTags: []
}