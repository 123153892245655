<template>
  <v-dialog class="matchstats-widget" v-model="dialog" width="580" lazy>
    <slot slot="activator"></slot>
    <v-form
      enctype="multipart/form-data"
      ref="singleFieldForm"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title class="widget__heading"> Basic Match Stats </v-card-title>

        <v-card-text>
          <match-picker-efl
            v-if="client == 'efl'"
            @updated="saveDetailsEfl($event)"
          />
          <match-picker v-else @updated="saveDetails($event)" />

          <p v-if="updatedData.content.snapshotUTC">
            Snapshot taken at
            {{ updatedData.content.snapshotUTC | moment("kk:mm (Z) DD/MM/YY") }}
          </p>

          <p v-else>
            This widget will take a snapshot of the data <i>now</i>. It will not
            update, unless you update this widget.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" outline flat @click="validate()"> Post </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "axios";
import MatchPicker from "@/components/Library/MatchPicker";
import MatchPickerEfl from "@/components/Library/MatchPickerEfl";
import moment from "moment";

export default {
  name: "FootballBasicMatchStatsWidget",

  components: {
    MatchPicker,
    MatchPickerEfl,
  },

  props: {
    widgetData: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    client: process.env.VUE_APP_CLUB_ID,
    dialog: false,
    valid: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    updatedData: {
      content: {
        snapshotUTC: "",
        displayStyle: "",
        matchID: "",
        seasonID: "",
        teamID: "",
        isHome: null,
        homeTeamStats: null,
        homeTeamCrest: "",
        awayTeamStats: null,
        awayTeamCrest: "",
        homeTeamCrests: null,
        awayTeamCrests: null,
      },
    },
  }),

  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData));
    }
  },

  methods: {
    saveDetails(payload) {
      console.log("Payload", payload);
      this.updatedData.content.matchID = payload.matchID;
      this.updatedData.content.seasonID = payload.seasonID;
      this.updatedData.content.teamID = payload.teamID;

      this.getMatch();
    },

    saveDetailsEfl(payload) {
      console.log("Payload", payload);
      this.updatedData.content.matchID = payload.matchID;
      this.updatedData.content.seasonID = payload.seasonID;
      this.updatedData.content.teamID = payload.teamID;
      this.updatedData.content.competitionID = payload.competitionID;
    },

    getMatch() {
      axios
        .get(
          `${process.env.VUE_APP_WEBAPI}/fixtures/opta/getsingle?seasonID=${this.updatedData.content.seasonID}&teamID=${this.updatedData.content.teamID}&matchID=${this.updatedData.content.matchID}`
        )
        .then((response) => {
          if (response.data.success) {
            this.updatedData.content.snapshotUTC = moment().utc().format();

            const data = response.data.body;
            this.updatedData.content.isHome = data.homeOrAway === "Home";
            this.updatedData.content.homeTeamStats = data.teamData[0].teamStats;
            this.updatedData.content.awayTeamStats = data.teamData[1].teamStats;

            if (data.teamData[0].teamCrest_Custom) {
              this.updatedData.content.homeTeamCrest =
                data.teamData[0].teamCrest_Custom.key;
            } else if (data.teamData[0].teamCrest) {
              this.updatedData.content.homeTeamCrest =
                data.teamData[0].teamCrest;
            } else {
              return "";
            }

            if (data.teamData[0].teamCrests) {
              this.updatedData.content.homeTeamCrests =
                data.teamData[0].teamCrests;
            }

            if (data.teamData[1].teamCrest_Custom) {
              this.updatedData.content.awayTeamCrest =
                data.teamData[1].teamCrest_Custom.key;
            } else if (data.teamData[1].teamCrest) {
              this.updatedData.content.awayTeamCrest =
                data.teamData[1].teamCrest;
            } else {
              return "";
            }

            if (data.teamData[1].teamCrests) {
              this.updatedData.content.awayTeamCrests =
                data.teamData[1].teamCrests;
            }
          }
        })
        .catch((e) => {
          console.error("Problem retrieving match", e);
        });
    },

    updateData() {
      console.log("Update data:", this.updatedData);
      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
    },

    validate() {
      this.errorMessage = "";
      if (this.$refs.singleFieldForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  &__description {
    margin: 0 0 20px;
    color: #7b6b80;
    font-size: 14px;
  }
}

.spacer {
  margin-top: 16px;
}
</style>
