<template>
    <v-dialog
        class="dialog"
        v-model="dialog"
        width="400"
        scrollable
        lazy
        persistent
    >
        <slot slot="activator"></slot>
        <v-card
            class="card"
            v-if="modalData"
        >
            <v-card-title class="card-title">{{ adData ? "Edit" : "Create New" }} Campaign</v-card-title>
            <div class="data-area">
                <label
                    class="label"
                    for="adName"
                >Campaign Name</label>
                <v-text-field
                    id="adName"
                    solo
                    flat
                    v-model="modalData.advertTitle"
                ></v-text-field>

                <v-select
                    v-show="adServers && adServers.length > 1"
                    id="publish-state"
                    class="calendar-listing__filter"
                    :items="adServers"
                    item-value="value"
                    item-text="name"
                    v-model="modalData.advertType"
                    label="Ad Type"
                ></v-select>

                <div v-if="modalData.advertType == 'simple'">
                    <div class="image-uploader__container">
                        <!-- Mobile -->
                        <div class="image-uploader">
                            <div class="image-uploader__info">
                                <media-library-popup @imageSelected="fetchImage($event, 'mobile')">
                                    <div
                                        class="image-uploader__image-container"
                                        :class="{
                                            'image-uploader__image-container--border': !(
                                                modalData.advertData.images.mobile &&
                                                modalData.advertData.images.mobile.key
                                            ),
                                        }"
                                        :style="`background-image: url('${mobileDisplayImage}')`"
                                    >
                                        <p
                                            v-if="mobileImageLoading"
                                            class="loading"
                                        >Loading...</p>
                                        <v-icon
                                            class="image-uploader__icon image-uploader__icon--add"
                                            v-else-if="!(
                                                modalData.advertData.images.mobile &&
                                                modalData.advertData.images.mobile.key
                                            )
                                                "
                                        >add</v-icon>
                                        <div class="image-uploader__overlay">
                                            <span class="image-uploader__edit-message">
                                                <v-icon class="image-uploader__icon image-uploader__icon--edit">edit</v-icon>
                                                Edit</span>
                                        </div>
                                    </div>
                                </media-library-popup>

                                <p class="image-uploader__type">Mobile</p>
                            </div>
                            <v-icon
                                v-if="modalData.advertData.images.mobile.key"
                                @click="deleteImage('mobile')"
                                class="image-uploader__icon image-uploader__icon--delete"
                            >delete</v-icon>
                        </div>
                    </div>
                    <!-- Desktop -->
                    <div class="image-uploader">
                        <div class="image-uploader__info">
                            <media-library-popup @imageSelected="fetchImage($event, 'desktop')">
                                <div
                                    class="image-uploader__image-container"
                                    :class="{
                                        'image-uploader__image-container--border': !(
                                            modalData.advertData.images.desktop &&
                                            modalData.advertData.images.desktop.key
                                        ),
                                    }"
                                    :style="`background-image: url('${desktopDisplayImage}')`"
                                >
                                    <p
                                        v-if="desktopImageLoading"
                                        class="loading"
                                    >Loading...</p>
                                    <v-icon
                                        class="image-uploader__icon image-uploader__icon--add"
                                        v-else-if="!(
                                            modalData.advertData.images.desktop &&
                                            modalData.advertData.images.desktop.key
                                        )
                                            "
                                    >add</v-icon>
                                    <div class="image-uploader__overlay">
                                        <span class="image-uploader__edit-message">
                                            <v-icon class="image-uploader__icon image-uploader__icon--edit">edit</v-icon>
                                            Edit</span>
                                    </div>
                                </div>
                            </media-library-popup>

                            <p class="image-uploader__type">Desktop</p>
                        </div>
                        <v-icon
                            v-if="modalData.advertData.images.desktop.key"
                            @click="deleteImage('desktop')"
                            class="image-uploader__icon image-uploader__icon--delete"
                        >delete</v-icon>
                    </div>

                    <v-select
                        id="publish-state"
                        class="calendar-listing__filter"
                        :items="adSizes"
                        item-value="value"
                        item-text="name"
                        v-model="modalData.advertData.images.mobile.imageSize"
                        label="Mobile Container Size"
                    ></v-select>

                    <v-select
                        id="publish-state"
                        class="calendar-listing__filter"
                        :items="adSizes"
                        item-value="value"
                        item-text="name"
                        v-model="modalData.advertData.images.desktop.imageSize"
                        label="Desktop Container Size"
                    ></v-select>

                    <!-- 
          <label class="label" for="adTitle">Title</label>
          <v-text-field
            id="adTitle"
            solo
            flat
            v-model="modalData.advertData.title"
          ></v-text-field> -->

                    <v-select
                        id="publish-state"
                        class="calendar-listing__filter"
                        :items="[
                            {
                                name: 'No, this advert is not child friendly',
                                value: false
                            },
                            {
                                name: 'Yes, this advert is child friendly',
                                value: true
                            },

                        ]"
                        item-value="value"
                        item-text="name"
                        v-model="modalData.childFriendly"
                        label="Child Friendly Advert"
                    ></v-select>

                    <label
                        class="label"
                        for="adUrl"
                    >URL (full URL)</label>
                    <v-text-field
                        id="adUrl"
                        solo
                        flat
                        placeholder="https://example.com"
                        v-model="modalData.advertData.url"
                    ></v-text-field>
                </div>

                <div v-else-if="modalData.advertType == 'adition'">
                    <label
                        class="label"
                        for="adTagID"
                    >Ad Tag ID</label>
                    <v-text-field
                        id="adTagID"
                        solo
                        flat
                        v-model="modalData.adTagID"
                    ></v-text-field>
                </div>

                <div v-else-if="modalData.advertType == 'gam'">
                    <label
                        class="label"
                        for="gamID"
                    >GAM Tag ID</label>
                    <v-text-field
                        id="gamTagID"
                        solo
                        flat
                        v-model="modalData.gamTagID"
                    ></v-text-field>
                </div>
                <template v-if="modalData.advertData">
                    <div class="date-area">
                        <label
                            class="label"
                            for="post-published-date"
                        >Publish from</label>
                        <datetime
                            v-model="modalData.advertData.publicationData.startAt"
                            type="datetime"
                        ></datetime>
                    </div>
                    <div class="date-area">
                        <label
                            class="label"
                            for="post-published-date"
                        >Publish until (optional)</label>
                        <datetime
                            v-model="modalData.advertData.publicationData.finishAt"
                            type="datetime"
                        ></datetime>
                    </div>
                </template>

                <p
                    v-if="error"
                    class="error-message"
                >{{ error }}</p>
            </div>
            <v-card-actions class="modal-buttons">
                <confirmation-modal
                    title="Missing data"
                    text="Please add a name to your ad"
                    :singleOption="true"
                    :passCondition="!!modalData.advertTitle == true"
                    @confirmed="adData ? editAd() : createAd()"
                >
                    <v-btn
                        accent
                        type="button"
                        color="accent"
                        depressed
                    >
                        {{ loadingRequest ? "Loading..." : "Save" }}
                    </v-btn>
                </confirmation-modal>
                <v-btn
                    text
                    flat
                    @click="cancel()"
                > Cancel </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
import MediaLibraryPopup from "@/components/Library/MediaLibraryPopup";
import ConfirmationModal from "@/components/Library/GlobalModals/ConfirmationModal";

export default {
    name: "AdvertModal",

    components: {
        MediaLibraryPopup,

        ConfirmationModal,
    },

    props: {
        adData: {
            type: Object | Boolean,
            default: false,
        },
    },

    data: () => ({
        advertsAPI: process.env.VUE_APP_ADVERTISINGAPI,
        dialog: false,
        loadingRequest: false,
        error: "",
        imageURL: process.env.VUE_APP_IMAGEURL,
        mobileImageLoading: false,
        desktopImageLoading: false,
        adSizes: [],
        modalData: {},
        templateModalData: {
            advertTitle: "",
            advertType: "simple",
            adTagID: null,
            gamTagID: null,
            childFriendly: false,
            advertData: {
                title: "",
                url: "",
                images: {
                    mobile: {
                        imageSize: "",
                        key: "",
                        mediaLibraryID: null,
                    },
                    desktop: {
                        imageSize: "",
                        key: "",
                        mediaLibraryID: null,
                    },
                },
                publicationData: {
                    finishAt: null,
                    startAt: null,
                },
            },
        },
        mobileDisplayImage: "",
        desktopDisplayImage: "",
        adServers: [],
        advertisingSDK: null,
    }),

    watch: {
        dialog() {
            if (this.dialog == true) {
                this.resetData();
            }
        },
    },

    created() {
        this.advertisingSDK = this.$gcFeatures("Advertising2");
        this.adServers = this.advertisingSDK.getProperties("adServers");
        this.adSizes = this.advertisingSDK.getProperties("adSizes");
    },

    methods: {
        resetData() {
            if (this.adData) {
                this.modalData = JSON.parse(JSON.stringify(this.adData.attributes));
                this.fetchImage(this.modalData.advertData.images.mobile, "mobile");
                this.fetchImage(this.modalData.advertData.images.desktop, "desktop");
            } else {
                this.modalData = JSON.parse(JSON.stringify(this.templateModalData));
                this.mobileDisplayImage = "";
                this.desktopDisplayImage = "";
            }
        },

        createAd() {
            if (!this.loadingRequest) {
                this.error = "";
                this.loadingRequest = true;
                const id = this.$uuid.v1();

                const data = this.$nullifyEmptyString(this.modalData, [
                    "advertData.url",
                    "advertData.publicationData.startAt",
                    "advertData.publicationData.finishAt",
                ]);

                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "POST",
                        url: `${this.advertsAPI}/adverts`,
                        headers: { Authorization: jwt },
                        data: {
                            data: {
                                id,
                                type: "advert",
                                attributes: data,
                            },
                        },
                    })
                        .then((response) => {
                            this.$emit("createAd");
                            this.cancel();
                        })
                        .catch((e) => {
                            console.error("Problem creating ads", e);
                            if (
                                e.response &&
                                e.response.data.errors &&
                                e.response.data.errors[0] &&
                                e.response.data.errors[0].detail &&
                                e.response.data.errors[0].detail.includes("Invalid url")
                            ) {
                                this.error = "Invalid URL";
                            } else {
                                this.error = "An Error has occurred";
                            }
                        })
                        .finally(() => {
                            this.loadingRequest = false;
                        });
                });
            }
        },

        editAd() {
            if (!this.loadingRequest) {
                this.error = "";
                this.loadingRequest = true;
                const id = this.adData.id;

                const data = this.$nullifyEmptyString(this.modalData, [
                    "advertData.url",
                    "advertData.publicationData.startAt",
                    "advertData.publicationData.finishAt",
                ]);

                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "PATCH",
                        url: `${this.advertsAPI}/adverts/${id}`,
                        headers: { Authorization: jwt },
                        data: {
                            data: {
                                id,
                                type: "advert",
                                attributes: data,
                            },
                        },
                    })
                        .then((response) => {
                            this.$emit("editAd");
                            this.cancel();
                        })
                        .catch((e) => {
                            this.error = "An Error has occurred";
                            console.error("Problem editing ads", e);
                        })
                        .finally(() => {
                            this.loadingRequest = false;
                        });
                });
            }
        },

        cancel() {
            this.dialog = false;
        },

        fetchImage(payload, size) {
            if (payload && payload.mediaLibraryID) {
                this[`${size}ImageLoading`] = true;

                this.$store.commit("removeImage", {
                    mediaLibraryID: "",
                    imageKey: "",
                });

                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "GET",
                        url: `${process.env.VUE_APP_BASEURL}/media/${payload.mediaLibraryID}`,
                        headers: { Authorization: jwt },
                    })
                        .then((response) => {
                            console.log("Fetch image response:", response);
                            if (response.data.body.fileType === "image/gif") {
                                this[
                                    `${size}DisplayImage`
                                ] = `${this.imageURL}/${response.data.body.key}`;
                            } else {
                                this[
                                    `${size}DisplayImage`
                                ] = `${this.imageURL}/fit-in/300x300/filters:quality(20)/${response.data.body.key}`;
                            }

                            this.modalData.advertData.images[size].key =
                                response.data.body.key;
                            this.modalData.advertData.images[size].mediaLibraryID =
                                payload.mediaLibraryID;
                            this[`${size}ImageLoading`] = false;
                        })
                        .catch((e) => {
                            this[`${size}ImageLoading`] = false;
                            // window.alert("An error occurred");
                            console.error("Problem fetching image", e);
                        });
                });
            } else {
                this[`${size}displayImage`] = "";
            }
        },

        deleteImage(size) {
            if (confirm("Are you sure you want to delete this image?")) {
                if (
                    this.modalData.advertData &&
                    this.modalData.advertData.images &&
                    this.modalData.advertData.images[size]
                ) {
                    this.modalData.advertData.images[size].key = null;
                    this.modalData.advertData.images[size].mediaLibraryID = null;
                    this[`${size}DisplayImage`] = "";
                }
            }
        },
    },
};
</script>

<style scoped lang='scss'>
.card {
    height: 100%;
}

.card-title {
    font-size: 20px;
    color: #24002f;
    margin-bottom: 15px;
}

.modal-buttons {
    display: flex;
    justify-content: center;
    box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.16);
}

.card-title {
    margin-bottom: 0;
}

.data-area {
    padding-left: 16px;
    padding-right: 16px;
}

.label {
    font-size: 12px;
    font-weight: 500;
    color: #24002f;
}

.tabs {
    /deep/ .v-tabs__bar {
        padding-left: 0;
    }
}

.image-uploader {
    display: flex;
    align-items: center;
    padding: 5px 12px 5px 6px;
    background: #dfe3e9;
    margin-bottom: 8px;
    border-radius: 4px;

    &__container {
        margin-bottom: 16px;
    }

    &__info {
        display: flex;
        align-items: center;
        margin-right: auto;
    }

    &__image-container {
        background: #ffffff;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        width: 87px;
        height: 47px;
        margin-right: 16px;
        position: relative;
        transition: 0.4s;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        &--border {
            border: 1px #959596 dashed;
        }

        &:hover {
            .image-uploader__overlay {
                opacity: 1;
            }
        }
    }

    &__icon {
        cursor: pointer;

        &--add {
            font-weight: bold;
        }

        &--delete {
            font-size: 20px;
            margin-left: 10px;
        }

        &--edit {
            font-size: 12px;
            color: white;
            margin-right: 12px;
        }
    }

    &__type {
        font-size: 12px;
        color: #7b6b80;
        margin-bottom: 0;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        background: rgba($color: #000000, $alpha: 0.5);
        padding: 2px 6px;
        color: white;
        opacity: 0;
        pointer-events: none;
        opacity: 0.4s;
    }

    &__edit-message {
        display: flex;
        align-items: center;
    }
}

.ad-images {
    padding-top: 10px;
}

.date-area {
    margin-bottom: 25px;
}

.tab {
    :deep(.v-tabs__item) {
        padding: 16px;
    }
}

.error-message {
    margin: 10px 0;
    color: #d61244;
    font-size: 14px;
}

.loading-message {
    text-align: center;
    padding: 10px;
    margin-bottom: 0;
}
</style>
