<template>
  <section class="form-preview">
    <div
      class="field-wrapper"
      v-for="(field, index) in fields"
      :key="`field-${index}`"
    >
      <div class="field-wrapper__order-arrows">
        <button
          v-if="index > 0"
          class="form-card__action"
          @click="moveUp(index)"
        >
          <v-icon>keyboard_arrow_up</v-icon>
        </button>
        <button
          v-if="index < fieldsLength - 1"
          class="form-card__action"
          @click="moveDown(index)"
        >
          <v-icon>keyboard_arrow_down</v-icon>
        </button>
      </div>

      <div v-if="field.type === 'input'" class="field-wrap input-wrap">
        <label
          class="label"
          :class="{ required: field.fieldOptions.required }"
          :for="field.model"
          >{{ field.label }}</label
        >
        <input
          class="input"
          v-model="model[field.model]"
          :id="field.model"
          type="text"
          :placeholder="field.placeholder"
          :required="field.fieldOptions.required"
        />
        <label v-if="field.helpText" class="label--help" :for="field.model">{{
          field.helpText
        }}</label>
      </div>

      <div v-if="field.type === 'emailAddress'" class="field-wrap input-wrap">
        <label
          class="label"
          :class="{ required: field.fieldOptions.required }"
          :for="field.model"
          >{{ field.label }}</label
        >
        <input
          class="input"
          v-model="model[field.model]"
          :id="field.model"
          type="text"
          :placeholder="field.placeholder"
          :required="field.fieldOptions.required"
        />
        <label v-if="field.helpText" class="label--help" :for="field.model">{{
          field.helpText
        }}</label>
      </div>

      <div
        v-else-if="field.type === 'textArea'"
        class="field-wrap textarea-wrap"
      >
        <label
          class="label"
          :class="{ required: field.fieldOptions.required }"
          :for="field.model"
          >{{ field.label }}</label
        >
        <textarea
          class="textarea"
          v-model="model[field.model]"
          :id="field.model"
          :placeholder="field.placeholder"
          :required="field.fieldOptions.required"
          :maxlength="field.fieldOptions.max"
          :rows="field.fieldOptions.rows"
        />
        <label v-if="field.helpText" class="label--help" :for="field.model">{{
          field.helpText
        }}</label>
      </div>

      <div v-else-if="field.type === 'select'" class="field-wrap select-wrap">
        <label
          class="label"
          :class="{ required: field.fieldOptions.required }"
          :for="field.model"
          >{{ field.label }}</label
        >
        <select
          :id="field.model"
          v-model="model[field.model]"
          :required="field.fieldOptions.required"
        >
          <option
            v-for="option in field.values"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </select>
        <label v-if="field.helpText" class="label--help" :for="field.model">{{
          field.helpText
        }}</label>
      </div>

      <div
        v-else-if="field.type === 'checkbox'"
        class="field-wrap checkbox-wrap"
      >
        <label
          class="label"
          :class="{ required: field.fieldOptions.required }"
          :for="field.model"
          >{{ field.label }}</label
        >
        <div v-if="field.description" v-html="field.description"></div>
        <input
          class="checkbox"
          v-model="model[field.model]"
          :id="field.model"
          type="checkbox"
          :required="field.fieldOptions.required"
        />
        <label v-if="field.helpText" class="label--help" :for="field.model">{{
          field.helpText
        }}</label>
      </div>

      <div v-else-if="field.type === 'radios'" class="field-wrap radio-wrap">
        <label
          class="label"
          :class="{ required: field.fieldOptions.required }"
          >{{ field.label }}</label
        >

        <div v-for="radio in field.values" :key="radio.id" class="radio-button">
          <label :for="radio.id">{{ radio.name }}</label>
          <input
            type="radio"
            v-model="model[field.model]"
            :id="radio.id"
            :name="field.fieldOptions.name"
            :value="radio.value"
            :required="field.fieldOptions.required"
          />
          <label v-if="field.helpText" class="label--help" :for="field.model">{{
            field.helpText
          }}</label>
        </div>
      </div>

      <div v-else-if="field.type === 'upload'" class="field-wrap upload-wrap">
        <label
          class="label"
          :class="{ required: field.fieldOptions.required }"
          :for="field.model"
          >{{ field.label }}</label
        >

        <input
          type="file"
          @change="convertUpload($event, field.model)"
          :id="field.model"
          :name="field.model"
          accept=".pdf, image/jpeg"
          :required="field.fieldOptions.required"
        />
        <label v-if="field.helpText" class="label--help" :for="field.model">{{
          field.helpText
        }}</label>
      </div>

      <div
        v-else-if="field.type === 'bodyText'"
        class="field-wrap body-text-wrap"
      >
        <div v-html="field.content"></div>
      </div>

      <div class="field-wrapper__actions">
        <button class="field-action" @click="editField(field, index)">
          <v-icon>edit</v-icon>
        </button>
        <button class="field-action" @click="deleteField(index)">
          <v-icon>delete</v-icon>
        </button>
      </div>
    </div>
  </section>
  <!-- </section> -->
</template>

<script>
// import FieldConstructor from '@/components/Forms/FieldConstructor'

export default {
  name: "FormPreview",

  // components: {
  //   FieldConstructor
  // },

  props: {
    fields: {
      type: Array,
    },
  },

  data: () => ({
    model: {},
    fieldsLength: null,
  }),

  updated() {
    this.arrayLength();
  },

  mounted() {
    this.arrayLength();
  },

  methods: {
    deleteField(field) {
      if (confirm("Delete: Are you sure?")) {
        console.log("deleting a field. Key: ", field);
        this.$emit("removeField", field);
      } else {
        console.log("delete cancelled");
      }
    },

    editField(field, index) {
      console.log("editing a field.", field, "index", index);
      this.$emit("editField", field);
    },

    moveUp(field) {
      console.log("moving up", field);
      this.$emit("moveUp", field);
    },

    moveDown(field) {
      console.log("moving down", field);
      this.$emit("moveDown", field);
    },

    arrayLength() {
      this.fieldsLength = this.fields.length;
    },
  },
};
</script>

<style scoped lang='scss'>
.form-preview {
  margin-top: 8px;
  padding: 16px 8px;
}

.field-wrapper {
  display: flex;
  justify-content: flex-start;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin: 0 auto 16px 0;
  align-items: stretch;

  &__order-arrows,
  &__delete {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #e6e8f1;
    padding: 0 8px;
  }

  &__actions {
    height: auto;
    display: flex;
    // flex-direction: column;
    justify-content: space-evenly;
    background-color: #e6e8f1;
    padding: 0 8px;
  }
}

.field-wrap {
  flex-grow: 1;
  background-color: #fff;
  padding: 16px;
}

.label {
  display: block;
  margin: 0 0 8px;
  color: #24002f;
  font-size: 16px;
  font-weight: bold;

  &.required:before {
    content: "* ";
  }

  &--help {
    display: block;
    margin: 0 0 8px;
    color: #24002f;
    font-size: 12px;
    font-weight: norml;
  }
}

.input,
.textarea {
  padding: 8px;
  width: 100%;
  color: #24002f;
  font-size: 16px;
  font-weight: normal;
  background: #fff;
  border: 1px solid rgba(21, 21, 21, 0.5);
  border-radius: 8px;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid #212121;
  }
}

select {
  max-width: 100%;
  min-width: 40px;
  height: 40px;
  color: #24002f;
  font-size: 16px;
  font-weight: normal;
  background: #fff;
  border: 1px solid rgba(21, 21, 21, 0.5);
  border-radius: 8px;
  padding: 4px 16px 4px 8px;
  -webkit-appearance: auto;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid #212121;
  }
}

.button {
  display: block;
  margin: 0 auto;
}

.field-action {
  margin: 8px;
}
</style>
