<template>
  <div>
    <h3 class="subheading">Quick View</h3>

    <div class="analytics-wrapper" v-if="path">
      <single-stat class="stat" title="Total page views" :value="parseInt(pageviews)" />
      <single-stat class="stat" title="Unique page views" :value="parseInt(uniques)" />
      <single-stat class="stat" title="Average time on page" :value="`${duration}s`" />
      <single-stat class="stat" title="Bounce rate" :value="`${bounce}%`" />
    </div>
    <p class="fathom-notice">Analytics powered by Fathom</p>

    <timeline-bar v-if="timeline.length" :timeline="timeline" />

    <div>
      <h3 class="subheading">More Detail</h3>

      <div class="details-panel section-container">
        <div class="details-panel__buttons">
          <button class="details-panel__button" :class="{'is-active': detailedTitle === 'Pageviews By Browser'}" @click="detailData('&field_grouping=browser&sort_by=pageviews:desc', 'views', 'Pageviews By Browser')">Pageviews By Browser</button>
          <button class="details-panel__button" :class="{'is-active': detailedTitle === 'Uniques By Browser'}" @click="detailData('&field_grouping=browser&sort_by=uniques:desc', 'uniques', 'Uniques By Browser')">Uniques By Browser</button>
          <button class="details-panel__button" :class="{'is-active': detailedTitle === 'Pageviews By Country'}" @click="detailData('&field_grouping=country_code&sort_by=pageviews:desc&limit=10', 'views', 'Pageviews By Country')">Pageviews By Country</button>
          <button class="details-panel__button" :class="{'is-active': detailedTitle === 'Uniques By Country'}" @click="detailData('&field_grouping=country_code&sort_by=uniques:desc&limit=10', 'uniques', 'Uniques By Country')">Uniques By Country</button>
        </div>

        <fathom-page-detail-block v-if="detailedData.length" :title="detailedTitle" :data="detailedData" :group="detailedGroup" class="details-panel__list" />
      </div>
    </div>

  </div>

</template>

<script>
import axios from "axios"
import FathomPageDetailBlock from "@/components/Library/Fathom/FathomPageDetailBlock"
import TimelineBar from "@/components/Library/StatBlocks/TimelineBar"
import SingleStat from "@/components/Library/StatBlocks/SingleStat"

export default {
  name: "FathomPageAnalytics",

  components: {
    FathomPageDetailBlock,
    TimelineBar,
    SingleStat
  },

  props: {
    path: {
      type: String,
      required: true
    }
  },

  watch: {
    path: function() {
      this.getInitData()
    }
  },

  data: () => ({
    fathomID: null,
    showFilters: false,
    pageviews: null,
    uniques: null,
    duration: null,
    bounce: null,
    detailedTitle: null,
    detailedData: [],
    detailedGroup: null,
    regionFilter: null,
    timeline: []
  }),

  methods: {
    getInitData() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_CLIENTSETTINGSAPI}/get?settingID=web`,  { "headers": { "Authorization": jwt }})
        .then(response => {
          if (response.data.body) {
            if (response.data.body.settings.fathomID) {
              this.fathomID = response.data.body.settings.fathomID

              this.getPageData()
              this.getTimelineData()
            }
          }
        })
      })
    },

    getPageData() {
      var pathname = this.path
  
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_FATHOMAPI}/fathom/?path=aggregations&entity=pageview&entity_id=${this.fathomID}&aggregates=pageviews,uniques,avg_duration,bounce_rate&filters=%5B%7B%22property%22%3A%22pathname%22%2C%22operator%22%3A%22is%22%2C%22value%22%3A%22%2F${pathname}%22%7D%5D`,
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then(response => {
        console.log("Fathom: ", response);
        this.pageviews = response.data.data[0].pageviews
        this.uniques = response.data.data[0].uniques
        this.duration = parseFloat(response.data.data[0].avg_duration).toFixed(2)
        this.bounce = parseFloat(response.data.data[0].bounce_rate*100).toFixed(2)

      }).catch((error) => {
        console.error("Fathom error", error)
      })
    },

    toggleFilters() {
      this.showFilters = !this.showFilters
    },

    detailData(type, group, title) {
      this.detailedData = []
      this.detailedGroup = group
      this.detailedTitle = title
      var pathname = this.path
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_FATHOMAPI}/fathom/?path=aggregations&entity=pageview&entity_id=${this.fathomID}&aggregates=pageviews,uniques&filters=%5B%7B%22property%22%3A%22pathname%22%2C%22operator%22%3A%22is%22%2C%22value%22%3A%22%2F${pathname}%22%7D%5D${type}`,
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then(response => {
        console.log("Fathom detail: ", response);
        this.detailedData = response.data.data
      }).catch((error) => {
        console.error("Fathom error", error)
      })
    },

    getTimelineData() {
      var pathname = this.path
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_FATHOMAPI}/fathom/?path=aggregations&entity=pageview&entity_id=${this.fathomID}&date_grouping=day&limit=10&aggregates=pageviews,uniques,avg_duration,bounce_rate&filters=%5B%7B%22property%22%3A%22pathname%22%2C%22operator%22%3A%22is%22%2C%22value%22%3A%22%2F${pathname}%22%7D%5D`,
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then(response => {
        console.log("Fathom: ", response);
        this.timeline = response.data.data
      }).catch((error) => {
        console.error("Fathom error", error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.analytics-heading {
  font-weight: 400;
  color: #24002F;
  padding: 8px 64px 8px 0px;
  margin: 0 0 16px 0;
  position: relative;
  border-bottom: 1px solid #DFE3E9;
}

.analytics-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.stat {
  min-width: calc(50% - 16px);
  max-width: calc(50% - 16px);
  margin-right: 16px;
  margin-bottom: 16px;
}

.fathom-notice {
  margin: 8px 0 16px;
  padding: 0 0 16px;
  font-size: 10px;
  font-weight: 300;
  border-bottom: 1px solid #DFE3E9;
}

.details-panel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  // padding: 35px 35px 55px;
  // background-color: #FFFFFF;
  // border: 1px solid #DFE3E9;
  // border-radius: 4px;

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__button {
    padding: 4px 16px 4px 0;
    margin: 0 0 8px;
    width: 140px;
    color: #67247c;
    font-weight: 500;
    text-align: left;
    background: transparent;
    border: 0;
    opacity: 0.75;

    &.is-active {
      opacity: 1;
    }
  }

  &__list {
    width: 100%;
  }
}

</style>
