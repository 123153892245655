<template>

  <v-dialog
      class="organisation-detail-widget"
      v-model="dialog"
      width="580"
      lazy
    >

    <slot slot="activator"></slot>
      <v-form enctype="multipart/form-data" ref="singleFieldForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            class="widget__heading"
          >
            Organisation Detail Widget
          </v-card-title>

          <v-card-text>
            <p class="widget__description">Allows you to display a detail widget for a specific organisation</p>

            <label class="label" for="category">Title</label>
            <p class="widget__description">Add a title if multiple organisation detail widgets are being used, or if the page is not the defined "internal URL"</p>
            <v-select
              id="category"
              outline
              :items="displayTitleOptions"
              v-model="updatedData.title"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
              chips
            ></v-select>

            <label class="label" for="type">Organisation Type</label>
            <v-select
              id="type"
              outline
              :rules="[rules.required]"
              v-model="filter"
              :items="organisationTypes"
              item-text="name"
              item-value="organisationTypeID"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
              chips
              @change="gettingOptions(filter)"
            ></v-select>

            <template v-if="organisationsLoaded">
              <template v-if="organisations.length">
                <label class="label" for="organisation">Organisation To Display</label>
                <v-select
                  id="organisation"
                  outline
                  :rules="[rules.required]"
                  v-model="updatedData.organisation"
                  :items="organisations"
                  item-text="name"
                  item-value="organisationID"
                  solo
                  flat
                  class="text-field--lableless"
                  validate-on-blur
                  return-object
                  chips
                ></v-select>

                <label class="label" for="image">Display Featured Image?</label>
                <v-select
                  id="image"
                  outline
                  v-model="updatedData.showImage"
                  :items="displayImageOptions"
                  solo
                  flat
                  class="text-field--lableless"
                  validate-on-blur
                  chips
                  @change="gettingOptions(filter)"
                ></v-select>

              </template>

              <template v-else>
                No organisations of this type are available to be displayed
              </template>
            </template>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="accent"
              outline
              flat
              @click="validate()"
            >
              Post
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

  </v-dialog>

</template>

<script>
import axios from "axios"

export default {
  name: "OrganisationDetailWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    dialog: false,
    valid: false,
    rules: {
      required: value => !!value || "Required."
    },
    filter: null,
    updatedData: {
      title: null,
      organisation: null,
      showImage: false
    },
    organisationTypes: [
      {name: "all", value: "all"}
    ],
    organisations: [],
    organisationsLoaded: false,
    displayTitleOptions: [
      {text: "Yes, display the Organisation Title", value: true},
      {text: "No, I don't need the Organisation Title to be displayed", value: false},
    ],
    displayImageOptions: [
      {text: "Yes, display the Featured Image", value: true},
      {text: "No, I don't need the Featured Image to be displayed", value: false},
    ]
  }),

  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
    }

    this.getOrganisationTypes()
  },

  methods: {
    getOrganisationTypes() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_ORGANISATIONAPI}/organisationtypes`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          response.data.body.Items.forEach(item => {
            this.organisationTypes.push(item)
          })
          console.log("org types", this.organisationTypes)
        })
        .catch(e => {
          console.error("Problem receiving orgtypes", e)
          this.$store.commit("completeLoading")
        })
      })
    },

    getOrganisationList() {
      this.organisations = []
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_ORGANISATIONAPI}/organisations`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          response.data.body.Items.forEach(item => {
            this.organisations.push(item)
          })
          console.log("orgs", this.organisations)
          this.organisationsLoaded = true
        })
        .catch(e => {
          console.error("Problem receiving orgtypes", e)
          this.$store.commit("completeLoading")
        })
      })
    },

    getFilteredOrganisationList(filter) {
      this.organisations = []
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_ORGANISATIONAPI}/organisations?organisationTypeID=${filter}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          response.data.body.Items.forEach(item => {
            this.organisations.push(item)
          })
          console.log("filtered orgs", this.organisations)
          this.organisationsLoaded = true
        })
        .catch(e => {
          console.error("Problem receiving orgtypes", e)
          this.$store.commit("completeLoading")
        })
      })
    },

    gettingOptions(item) {
      console.warn("you what mate", item)
      if (item === "all") {
        this.getOrganisationList()
      }
      else {
        this.getFilteredOrganisationList(item)
      }
    },

    updateData() {
      console.log("Update data:", this.updatedData);

      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
    },

    validate() {

      if (this.updatedData.title === "") {
        this.updatedData.organisationType = null
      }

      this.errorMessage = ""
      if (this.$refs.singleFieldForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    }
  }
}
</script>

<style scoped lang='scss'>
.widget {
    &__description {
      margin: 0 0 20px;
      color: #7B6B80;
      font-size: 14px;
    }
  }
</style>
