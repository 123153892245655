<template>
    <section
        class="player main-body"
        v-if="dataReceived"
    >
        <v-layout
            class="new-page-alert"
            row
            v-if="defaultSettings.default.playerManagement != 'V3'"
        >
            <v-flex mr-5>
                <v-btn
                    class="accent"
                    @click="editPlayer($route.params.playerID, $route.params.playerType)"
                >Go back to V1</v-btn>
            </v-flex>
            <v-flex mt-4>
                <p>
                    <i>This is the Player Profile V3 management page. Some content is
                        shared between the 2 profiles, but some of the data has been
                        restructured for V3. If the data is already in place when you first
                        visit, any edits to this will also affect on your live site.</i>
                </p>
            </v-flex>
        </v-layout>

        <p
            v-if="defaultSettings.default.playerManagement === 'V3'"
            class="notice"
        >
            Player Profile v3 is in active development<br />After you have finished
            editing/publishing all your players please
            <b>trigger a full site build</b>
        </p>

        <div class="subheading-wrapper">
            <h3
                class="subheading"
                :class="visible === 'section-publish'
                    ? 'subheading__open'
                    : 'subheading__closed'
                    "
                @click="togglePanel('section-publish')"
                ref="section-publish"
            >
                Publish Settings
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-publish'"
                color="accent"
                right
                @click="togglePanel('section-publish')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-publish')"
            >add_circle_outline</v-icon>
        </div>
        <section
            class="section-container section-publish"
            v-show="visible === 'section-publish'"
        >
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="published"
                        title="published"
                    >Published</label>

                    <v-select
                        id="published"
                        item-text="name"
                        item-value="id"
                        :items="[
                            { name: 'Yes, publish the player', id: 1 },
                            { name: 'No, do not display the player', id: 0 },
                        ]"
                        v-model="updatedData.published"
                        solo
                        flat
                        :disabled="updatedData.playerSlug ? false : true"
                        @change="updateData()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>After you have finished editing/publishing all your players
                            please <b>trigger a full site build</b></i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="enable-profile-page"
                        title="enableProfilePage"
                    >Enable Profile Page (Web)</label>

                    <v-select
                        id="enable-profile-page"
                        item-text="name"
                        item-value="id"
                        :items="[
                            { name: 'Yes, enable the web profile page', id: true },
                            { name: 'No, hide the web profile page', id: false },
                        ]"
                        v-model="updatedData.enableProfilePage"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>Players can only be published on web if a slug is present. The
                            Profile page will only display if selected and the player is
                            published</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                v-if="defaultSettings.default.playerPageDefaultLayout"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="apply-default"
                        title="apply-default"
                    >Apply default structure</label>

                    <v-btn
                        @click="applyDefaultLayout()"
                        class="accent"
                    >Apply layout</v-btn>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>If you have requested a default page style use this to apply your
                            default config.</i>
                    </p>
                    <!-- <pre>{{defaultSettings.default.playerPageDefaultLayout}}</pre> -->
                </v-flex>
            </v-layout>
        </section>

        <div
            v-if="!modules.default.LFCExclusive"
            class="subheading-wrapper"
        >
            <h3
                class="subheading"
                :class="visible === 'section-hero' ? 'subheading__open' : 'subheading__closed'
                    "
                @click="togglePanel('section-hero')"
                ref="section-hero"
            >
                Website Page Manager
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-hero'"
                color="accent"
                right
                @click="togglePanel('section-hero')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-hero')"
            >add_circle_outline</v-icon>
        </div>
        <!-- <h3 class="subheading" @click="togglePanel('section-hero')" ref="section-hero">Website Page Manager</h3> -->
        <section
            class="section-container section-hero"
            v-show="visible === 'section-hero'"
        >
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="pageStructure.bio"
                        title="pageStructure.bio"
                    >Display Biography Panel</label>
                    <v-select
                        id="pageStructure.bio"
                        item-text="name"
                        item-value="value"
                        :items="pageStructureOptions"
                        v-model="updatedData.playerProfileData.pageStructure.bio"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                    <!-- <label class="label" for="pageStructure.social" title="pageStructure.social">Display Social Panel</label>
        <v-select
          id="pageStructure.social"
          item-text="name"
          item-value="value"
          :items="pageStructureOptions"
          v-model="updatedData.playerProfileData.pageStructure.social"
          solo
          flat
          @change="updateData()">
        </v-select>
        <label class="label" for="pageStructure.sponsors" title="pageStructure.sponsors">Display Sponsor Panel</label>
        <v-select
          id="pageStructure.sponsors"
          item-text="name"
          item-value="value"
          :items="pageStructureOptions"
          v-model="updatedData.playerProfileData.pageStructure.sponsors"
          solo
          flat
          @change="updateData()">
        </v-select> -->
                    <label
                        class="label"
                        for="pageStructure.stats"
                        title="pageStructure.stats"
                    >Display Stats Panel</label>
                    <v-select
                        id="pageStructure.stats"
                        item-text="name"
                        item-value="value"
                        :items="[
                            { name: 'Yes, show the stats panel', value: 'show' },
                            { name: 'No, hide the stats panel', value: 'hide' },
                        ]"
                        v-model="updatedData.playerProfileData.pageStructure.stats"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                    <label
                        class="label"
                        for="pageStructure.history"
                        title="pageStructure.history"
                    >Display Club History Panel</label>
                    <v-select
                        id="pageStructure.history"
                        item-text="name"
                        item-value="value"
                        :items="pageStructureOptions"
                        v-model="updatedData.playerProfileData.pageStructure.history"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                    <label
                        class="label"
                        for="pageStructure.awards"
                        title="pageStructure.awards"
                    >Display Awards Panel</label>
                    <v-select
                        id="pageStructure.awards"
                        item-text="name"
                        item-value="value"
                        :items="pageStructureOptions"
                        v-model="updatedData.playerProfileData.pageStructure.awards"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                    <label
                        class="label"
                        for="pageStructure.gallery"
                        title="pageStructure.gallery"
                    >Display Gallery</label>
                    <v-select
                        id="pageStructure.gallery"
                        item-text="name"
                        item-value="value"
                        :items="pageStructureOptions"
                        v-model="updatedData.playerProfileData.pageStructure.gallery"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                    <label
                        class="label"
                        for="pageStructure.news"
                        title="pageStructure.news"
                    >Display News</label>
                    <v-select
                        id="pageStructure.news"
                        item-text="name"
                        item-value="value"
                        :items="pageStructureOptions"
                        v-model="updatedData.playerProfileData.pageStructure.news"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                    <label
                        class="label"
                        for="pageStructure.retail"
                        title="pageStructure.retail"
                    >Display Retail</label>
                    <v-select
                        id="pageStructure.retail"
                        item-text="name"
                        item-value="value"
                        :items="pageStructureOptions"
                        v-model="updatedData.playerProfileData.pageStructure.retail"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                    <!-- <label
            class="label"
            for="pageStructure.video"
            title="pageStructure.video"
            >Display Videos [NOT IN USE]</label
          >
          <v-select
            id="pageStructure.video"
            item-text="name"
            item-value="value"
            :items="pageStructureOptions"
            v-model="updatedData.playerProfileData.pageStructure.video"
            solo
            flat
            @change="updateData()"
          >
          </v-select> -->
                </v-flex>
                <v-flex mt-4>
                    <p>
                        <i>Using the options you can choose which elements to display, and
                            their colour theme when applicable.</i>
                    </p>
                    <!-- <p><i>If you choose to display the biography, sponsors and social panels, these should use the same theme style.</i></p> -->
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="displayHeadshot"
                        title="displayHeadshot"
                    >Image Style</label>
                    <v-select
                        id="displayHeadshot"
                        item-text="name"
                        item-value="value"
                        :items="[
                            { name: 'Use a hero image', value: 'hero' },
                            { name: 'Use a player headshot', value: 'headshot' },
                            { name: 'No Image', value: 'blank' },
                        ]"
                        v-model="updatedData.playerProfileData.displayHeadshot"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>Select the image style for your player page.</i></p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex mr-2>
                    <ImageUploader
                        v-if="updatedData.playerProfileData.displayHeadshot === 'hero'"
                        :title="'Web Profile Hero Image'"
                        :widthOptionEnabled="false"
                        :mediaLibraryID="updatedData.playerProfileData.profileImage"
                        @update="updateImage($event, 'profileImage')"
                        :desc="'<p>For use in the player profile header on web.</p><p>Recommended ratio: 5:2 / width: 1600px | height: 640px</p><p>If populated the advanced hero style will display.</p>'"
                    />
                    <ImageUploader
                        v-if="updatedData.playerProfileData.displayHeadshot === 'headshot'"
                        mb-3
                        :title="'Player Headshot'"
                        :widthOptionEnabled="false"
                        :mediaLibraryID="updatedData.playerProfileData.playerHeadshot"
                        @update="updateImage($event, 'playerHeadshot')"
                        :desc="'<p>This image may also be used around the site</p><p>Recommended size: width: 400px | height: 400px</p><p>*Important* Centre the subjects head within the image</p>'"
                    />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex mr-2>
                    <ImageUploader
                        :title="'Web Profile Hero Background Image'"
                        :widthOptionEnabled="false"
                        :mediaLibraryID="updatedData.playerProfileData.playerProfileBackground
                            "
                        @update="updateImage($event, 'playerProfileBackground')"
                        :desc="'<p>This will be used as a background texture behind the web profile image (if present) or headshot.</p><p>Recommended ratio: 5:1 / width: 1600px | height: 320px</p><p>This will override any image set in the web settings for this element.</p>'"
                    />
                </v-flex>
            </v-layout>
            <!-- <v-layout row>
      <v-flex mr-5 class="half">
        <label class="label" for="displayHeadshot" title="displayHeadshot">Display Headshot</label>
        <v-select
          id="displayHeadshot"
          item-text="name"
          item-value="value"
          :items="[
            {name: 'Yes, display headshot next to player info', value: true},
            {name: 'No, just displayer player image in the hero', value: false}
          ]"
          v-model="updatedData.playerProfileData.displayHeadshot"
          solo
          flat
          @change="updateData()">
        </v-select>
      </v-flex>
      <v-flex mt-4 class="half">
        <p><i>If you use a Web profile image you may also wish to display the player headshot next to the player information.</i></p>
      </v-flex>
    </v-layout> -->
        </section>

        <template v-if="!isCustom">
            <div class="subheading-wrapper">
                <h3
                    class="subheading"
                    :class="visible === 'section-opta'
                        ? 'subheading__open'
                        : 'subheading__closed'
                        "
                    @click="togglePanel('section-opta')"
                    ref="section-opta"
                >
                    Opta data
                </h3>
                <v-icon
                    class="status-icon"
                    v-if="visible === 'section-opta'"
                    color="accent"
                    right
                    @click="togglePanel('section-publish')"
                >remove_circle_outline</v-icon>
                <v-icon
                    class="status-icon"
                    v-else
                    color="accent"
                    right
                    @click="togglePanel('section-opta')"
                >add_circle_outline</v-icon>
            </div>
            <!-- <h3 class="subheading" @click="togglePanel('section-opta')" ref="section-opta">Opta data</h3> -->
            <section
                class="section-container section-opta"
                v-show="visible === 'section-opta'"
            >
                <div class="player__panel">
                    <v-layout
                        align-center
                        class="player__panel-details"
                    >
                        <div class="flex md5 mr1">
                            <table class="opta__table">
                                <tr>
                                    <th class="opta__table-heading">First name</th>
                                    <td>{{ this.updatedData.firstName }}</td>
                                </tr>

                                <tr>
                                    <th class="opta__table-heading">Middle name</th>
                                    <td>{{ this.updatedData.middleName }}</td>
                                </tr>

                                <tr>
                                    <th class="opta__table-heading">Surname</th>
                                    <td>{{ this.updatedData.surname }}</td>
                                </tr>

                                <tr>
                                    <th class="opta__table-heading">Known name</th>
                                    <td>{{ this.updatedData.knownName }}</td>
                                </tr>

                                <tr>
                                    <th class="opta__table-heading">Shirt number</th>
                                    <td>{{ this.updatedData.shirtNumber }}</td>
                                </tr>

                                <tr>
                                    <th class="opta__table-heading">Position</th>
                                    <td>{{ this.updatedData.position }}</td>
                                </tr>

                                <tr>
                                    <th class="opta__table-heading">Real Position</th>
                                    <td>{{ this.updatedData.realPosition }}</td>
                                </tr>

                                <tr>
                                    <th class="opta__table-heading">Join Date</th>
                                    <td>{{ this.updatedData.joinDate }}</td>
                                </tr>

                                <tr>
                                    <th class="opta__table-heading">Loan</th>
                                    <td>{{ this.updatedData.loan }}</td>
                                </tr>

                                <tr>
                                    <th class="opta__table-heading">On Loan from</th>
                                    <td>{{ this.updatedData.onLoanFrom }}</td>
                                </tr>

                                <tr>
                                    <th class="opta__table-heading">DOB</th>
                                    <td>{{ this.updatedData.dob }}</td>
                                </tr>

                                <tr>
                                    <th class="opta__table-heading">Country</th>
                                    <td>{{ this.updatedData.country }}</td>
                                </tr>

                                <tr>
                                    <th class="opta__table-heading">Height</th>
                                    <td>{{ this.updatedData.height }}</td>
                                </tr>

                                <tr>
                                    <th class="opta__table-heading">Weight</th>
                                    <td>{{ this.updatedData.weight }}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="player__panel-opta flex md4 mr1">
                            <img
                                src="@/assets/partner-assets/opta.png"
                                width="120px"
                                alt="Opta"
                            />
                        </div>
                        <!-- <div class="flex sm12 md3">
            <v-checkbox v-model="updatedData.optaShowStats" label="Stats enabled" class="player__panel-opta-cb" @change="updateData()"></v-checkbox>
          </div> -->
                    </v-layout>
                </div>
            </section>
        </template>

        <div class="subheading-wrapper">
            <h3
                class="subheading"
                :class="visible === 'section-player'
                    ? 'subheading__open'
                    : 'subheading__closed'
                    "
                @click="togglePanel('section-player')"
                ref="section-opta"
            >
                Player Details
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-player'"
                color="accent"
                right
                @click="togglePanel('section-player')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-player')"
            >add_circle_outline</v-icon>
        </div>
        <!-- <h3 class="subheading" @click="togglePanel('section-player')" ref="section-player">Player Details</h3> -->
        <section
            class="section-container section-player"
            v-show="visible === 'section-player'"
        >
            <v-layout>
                <p class="flex md4">Team: {{ teamID }} | Is Custom: {{ isCustom }}</p>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="firstName_Custom"
                        title="firstName_Custom"
                    >First name</label>
                    <v-text-field
                        id="firstName_Custom"
                        v-model="updatedData.firstName_Custom"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="middleName_Custom"
                        title="middleName_Custom"
                    >Middle Name</label>
                    <v-text-field
                        id="middleName_Custom"
                        v-model="updatedData.middleName_Custom"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>Not in use</i></p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="surname_Custom"
                        title="surname_Custom"
                    >Surname</label>
                    <v-text-field
                        id="surname_Custom"
                        v-model="updatedData.surname_Custom"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="knownName_Custom"
                        title="knownName_Custom"
                    >Known Name</label>
                    <v-text-field
                        id="knownName_Custom"
                        v-model="updatedData.knownName_Custom"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>Caution: Known Name should be used only for players who use a
                            single name (e.g. Pele). In most cases do not use this.</i>
                    </p>
                    <p><i>Known Name replaces First Name and Surname.</i></p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                class="section-divider"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="playerSlug"
                        title="playerSlug"
                    >Player Slug</label>
                    <v-text-field
                        id="playerSlug"
                        v-model="updatedData.playerSlug"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>This is for the url</p>
                    <p>
                        <i>Please do not use accented characters for slugs as your page may
                            not build or share correctly</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="position_Custom"
                        title="position_Custom"
                    >Position</label>
                    <v-select
                        id="position"
                        :items="positions"
                        v-model="updatedData.position_Custom"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                v-if="!modules.default.LFCExclusive"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="realPosition_Custom"
                        title="realPosition_Custom"
                    >Real Position</label>
                    <v-text-field
                        id="realPosition_Custom"
                        v-model="updatedData.realPosition_Custom"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                v-if="!modules.default.LFCExclusive"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="squadCaptain"
                        title="playerProfileData.squadCaptain"
                    >Squad Captain</label>

                    <v-select
                        id="squadCaptain"
                        item-text="name"
                        item-value="id"
                        :items="[
                            { name: 'This is the captain', id: true },
                            { name: 'Not the captain', id: false },
                        ]"
                        v-model="updatedData.playerProfileData.squadCaptain"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This can apply a label to the player in some locations. Turning
                            this on does not remove it from other players.</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="shirtNumber_Custom"
                        title="shirtNumber_Custom"
                    >Shirt Number</label>
                    <v-text-field
                        id="shirtNumber_Custom"
                        v-model="updatedData.shirtNumber_Custom"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                v-if="!modules.default.LFCExclusive"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="clubIdNumber"
                        title="playerProfileData.clubIdNumber"
                    >Club ID Number</label>
                    <v-text-field
                        id="clubIdNumber"
                        v-model="updatedData.playerProfileData.clubIdNumber"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This is an optional field in case your club assigns a club ID, or
                            heritage number to a player</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                class="section-divider"
                v-if="!modules.default.LFCExclusive"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="debut"
                        title="playerProfileData.debut"
                    >Debut</label>
                    <v-text-field
                        id="debut"
                        v-model="updatedData.playerProfileData.debut"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                v-if="!modules.default.LFCExclusive"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="dob_Custom"
                        title="dob_Custom"
                    >Date of Birth</label>
                    <v-text-field
                        id="dob_Custom"
                        v-model="updatedData.dob_Custom"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                v-if="!modules.default.LFCExclusive"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="country_Custom"
                        title="country_Custom"
                    >Country</label>
                    <v-text-field
                        id="country_Custom"
                        v-model="updatedData.country_Custom"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                v-if="!modules.default.LFCExclusive"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="dob_Custom"
                        title="dob_Custom"
                    >Custom Flag Code (<a
                            href="https://www.iban.com/country-codes"
                            target="_blank"
                        >Alpha-2 Code</a>)</label>
                    <v-text-field
                        id="dob_Custom"
                        v-model="updatedData.customAlpha2Code"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This overrides the displayed flag of the player. Must be
                            uppercase</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                v-if="!modules.default.LFCExclusive"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="customHeight"
                        title="playerProfileData.customHeight"
                    >Height</label>
                    <v-text-field
                        id="customHeight"
                        v-model="updatedData.playerProfileData.customHeight"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                v-if="!modules.default.LFCExclusive"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="customWeight"
                        title="playerProfileData.customWeight"
                    >Weight</label>
                    <v-text-field
                        id="customWeight"
                        v-model="updatedData.playerProfileData.customWeight"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                v-if="!modules.default.LFCExclusive"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="joinDate_Custom"
                        title="joinDate_Custom"
                    >Join Date</label>
                    <v-text-field
                        id="joinDate_Custom"
                        v-model="updatedData.joinDate_Custom"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>
        </section>

        <div
            v-if="!modules.default.LFCExclusive"
            class="subheading-wrapper"
        >
            <h3
                class="subheading"
                :class="visible === 'section-bio' ? 'subheading__open' : 'subheading__closed'
                    "
                @click="togglePanel('section-bio')"
                ref="section-bio"
            >
                Biography
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-bio'"
                color="accent"
                right
                @click="togglePanel('section-bio')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-bio')"
            >add_circle_outline</v-icon>
        </div>
        <!-- <h3 class="subheading" @click="togglePanel('section-bio')" ref="section-bio">Biography</h3> -->
        <section
            class="section-container section-bio"
            v-show="visible === 'section-bio'"
        >
            <v-layout row>
                <v-flex mb-4>
                    <label
                        class="label"
                        for="biography"
                        title="playerProfileData.biography"
                    >Biography</label>

                    <tinymce-editor
                        id="biography"
                        v-model="updatedData.playerProfileData.biography"
                        @onChange="updateData()"
                        api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
                        :init="{
                            height: '500px',
                            menubar: false,
                            relative_urls: false,
                            plugins: 'link paste wordcount hr textpattern code help',
                            toolbar:
                                'undo redo | styleselect | bold italic | \
                                                                                                                                                                                                                                                                                                                                                                                                                                      link blockquote | \
                                                                                                                                                                                                                                                                                                                                                                                                                                      removeformat | \ code | \ help',
                            help_tabs: [
                                {
                                    name: 'embedding', // new tab called custom1
                                    title: 'Embedding',
                                    items: [
                                        {
                                            type: 'htmlpanel',
                                            html: `<p>The code section is enabled to allow for custom html entry and embedding of some content.</p>
                    <p>Please take note that not all embeds will work. Generally only iframe ones which do not require a script tag loading will work in this way</p>`,
                                        },
                                    ],
                                },
                            ],
                            resize: false,
                            textpattern_patterns: [
                                {
                                    start: '//button',
                                    replacement: '<a class=\'button \'>Button here</a>',
                                },
                            ],
                            link_class_list: [
                                { title: 'None', value: '' },
                                {
                                    title: 'Primary on Dark',
                                    value: 'button button--primary-dark',
                                },
                                {
                                    title: 'Primary on Light',
                                    value: 'button button--primary-light',
                                },
                                { title: 'Ghost on Dark', value: 'button button--ghost-dark' },
                                {
                                    title: 'Ghost on Light',
                                    value: 'button button--ghost-light',
                                },
                                { title: 'Label on Dark', value: 'button button--label-dark' },
                                {
                                    title: 'Label on Light',
                                    value: 'button button--label-light',
                                },
                            ],
                            style_formats: [
                                {
                                    title: 'Headers',
                                    items: [
                                        //{title: 'Header 1', format: 'h1'},
                                        { title: 'Header 2', format: 'h2' },
                                        { title: 'Header 3', format: 'h3' },
                                        { title: 'Header 4', format: 'h4' },
                                        { title: 'Header 5', format: 'h5' },
                                        { title: 'Header 6', format: 'h6' },
                                    ],
                                },
                                {
                                    title: 'Inline',
                                    items: [
                                        { title: 'Bold', icon: 'bold', format: 'bold' },
                                        { title: 'Italic', icon: 'italic', format: 'italic' },
                                        {
                                            title: 'Underline',
                                            icon: 'underline',
                                            format: 'underline',
                                        },
                                        //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                                        //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                                        //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                                        // {title: 'Code', icon: 'code', format: 'code'}
                                    ],
                                },
                                {
                                    title: 'Blocks',
                                    items: [
                                        { title: 'Paragraph', format: 'p' },
                                        { title: 'Blockquote', format: 'blockquote' },
                                        //{title: 'Div', format: 'div'},
                                        //{title: 'Pre', format: 'pre'}
                                    ],
                                },
                                {
                                    title: 'Alignment',
                                    items: [
                                        { title: 'Left', icon: 'alignleft', format: 'alignleft' },
                                        {
                                            title: 'Center',
                                            icon: 'aligncenter',
                                            format: 'aligncenter',
                                        },
                                        {
                                            title: 'Right',
                                            icon: 'alignright',
                                            format: 'alignright',
                                        },
                                        //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
                                    ],
                                },
                                { title: 'Small', selector: 'p', classes: 'small' },
                            ],
                            style_formats_merge: false,
                            block_formats:
                                'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote',
                        }"
                    ></tinymce-editor>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex mr-2>
                    <ImageUploader
                        :title="'Bio Image'"
                        :widthOptionEnabled="false"
                        :mediaLibraryID="updatedData.playerProfileData.bioImage"
                        @update="updateImage($event, 'bioImage')"
                        :desc="'<p>Will display in the player biography.</p><p>Recommended ratio: 1:1</p>'"
                    />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="quote"
                        title="playerProfileData.quote"
                    >Quote</label>
                    <v-textarea
                        id="quote"
                        v-model="updatedData.playerProfileData.quote"
                        solo
                        flat
                        @change="updateData()"
                    ></v-textarea>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="quotee"
                        title="playerProfileData.quotee"
                    >Quote Source</label>
                    <v-text-field
                        id="onLoanFrom_Custom"
                        v-model="updatedData.playerProfileData.quotee"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="quoteSize"
                        title="updatedData.playerProfileData.quoteSize"
                    >Quote Size</label>
                    <v-select
                        id="quoteSize"
                        item-text="name"
                        item-value="value"
                        :items="[
                            { name: 'Small', value: 'small' },
                            { name: 'Medium', value: 'medium' },
                            { name: 'Large', value: 'large' },
                        ]"
                        v-model="updatedData.playerProfileData.quoteSize"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>If you add to the biography with a quote or an image you can
                            choose the order of the columns (desktop).</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="bioLayout"
                        title="updatedData.playerProfileData.bioLayout"
                    >Bio Layout</label>
                    <v-select
                        id="displayHeadshot"
                        item-text="name"
                        item-value="value"
                        :items="[
                            {
                                name: 'Bio on the left, quote & image on the right',
                                value: 'bioLeft',
                            },
                            {
                                name: 'Bio on the right, quote & image on the left',
                                value: 'bioRight',
                            },
                        ]"
                        v-model="updatedData.playerProfileData.bioLayout"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>If you add to the biography with a quote or an image you can
                            choose the order of the columns (desktop).</i>
                    </p>
                </v-flex>
            </v-layout>
        </section>

        <div
            v-if="!modules.default.LFCExclusive"
            class="subheading-wrapper"
        >
            <h3
                class="subheading"
                :class="visible === 'section-social'
                    ? 'subheading__open'
                    : 'subheading__closed'
                    "
                @click="togglePanel('section-social')"
                ref="section-social"
            >
                Social Media
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-social'"
                color="accent"
                right
                @click="togglePanel('section-social')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-social')"
            >add_circle_outline</v-icon>
        </div>
        <!-- <h3 class="subheading" @click="togglePanel('section-social')" ref="section-social">Social Media</h3> -->
        <section
            class="section-container section-social"
            v-show="visible === 'section-social'"
        >
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="twitter"
                        title="playerProfileData.twitter"
                    >X</label>
                    <v-text-field
                        id="twitter"
                        v-model="updatedData.playerProfileData.twitter"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>Please include the full url e.g. https://twitter.com/FIFAcom</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="threads"
                        title="playerProfileData.threads"
                    >Threads</label>
                    <v-text-field
                        id="threads"
                        v-model="updatedData.playerProfileData.threads"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>Please include the full url e.g. https://www.threads.net/@fifa</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="instagram"
                        title="playerProfileData.instagram"
                    >Instagram</label>
                    <v-text-field
                        id="instagram"
                        v-model="updatedData.playerProfileData.instagram"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>Please include the full url e.g. https://instagram.com/FIFAcom</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="tiktok"
                        title="playerProfileData.tiktok"
                    >TikTok</label>
                    <v-text-field
                        id="tiktok"
                        v-model="updatedData.playerProfileData.tiktok"
                        solo
                        flat
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>Please include the full url e.g. https://tiktok.com/FIFAcom</i>
                    </p>
                </v-flex>
            </v-layout>
        </section>

        <template v-if="betaFeatures.default.PlayerProfileStats">
            <div class="subheading-wrapper">
                <h3
                    class="subheading"
                    :class="visible === 'section-stats'
                        ? 'subheading__open'
                        : 'subheading__closed'
                        "
                    @click="togglePanel('section-stats')"
                    ref="section-stats"
                >
                    Statistics
                </h3>
                <v-icon
                    class="status-icon"
                    v-if="visible === 'section-stats'"
                    color="accent"
                    right
                    @click="togglePanel('section-stats')"
                >remove_circle_outline</v-icon>
                <v-icon
                    class="status-icon"
                    v-else
                    color="accent"
                    right
                    @click="togglePanel('section-stats')"
                >add_circle_outline</v-icon>
            </div>
            <!-- <h3 class="subheading" @click="togglePanel('section-stats')" ref="section-stats">Statistics</h3> -->
            <section
                class="section-container section-stats"
                v-show="visible === 'section-stats'"
            >
                <template v-if="!isCustom">
                    <player-statistics
                        v-for="(stat, index) in updatedData.playerProfileData.stats"
                        class="repeater-row"
                        :key="index"
                        :statistic="stat"
                        :availableStats="updatedData.playerStats"
                        @updatedPlayerStats="updatePlayerStats($event, index)"
                    />
                </template>
                <p v-else>Player Stats are not available for custom players</p>
            </section>
        </template>

        <div
            v-if="!modules.default.LFCExclusive"
            class="subheading-wrapper"
        >
            <h3
                class="subheading"
                :class="visible === 'section-sponsors'
                    ? 'subheading__open'
                    : 'subheading__closed'
                    "
                @click="togglePanel('section-sponsors')"
                ref="section-sponsors"
            >
                Player Sponsors
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-sponsors'"
                color="accent"
                right
                @click="togglePanel('section-sponsors')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-sponsors')"
            >add_circle_outline</v-icon>
        </div>
        <!-- <h3 class="subheading" @click="togglePanel('section-sponsors')" ref="section-sponsors">Player Sponsors</h3> -->
        <section
            class="section-container section-sponsors"
            v-show="visible === 'section-sponsors'"
        >
            <template v-for="(sponsor, index) in updatedData.playerProfileData.sponsorsArray">
                <div
                    :key="index"
                    class="repeater-row"
                >
                    <v-layout row>
                        <v-flex mt-2>
                            <label
                                class="label"
                                for="updatedData.playerProfileData.sponsorsArray.sponsorImage"
                                title="playerProfileData.sponsorsArray.sponsorImage"
                            >Sponsor {{ index + 1 }}</label>
                            <ImageUploader
                                :title="'Sponsor Image'"
                                :widthOptionEnabled="false"
                                :mediaLibraryID="updatedData.playerProfileData.sponsorsArray[index]
                                    .sponsorImage
                                    "
                                @update="updateImage($event, 'sponsorImage', `${index}`)"
                                :desc="'<p>This will display below the players hero image.</p><p>Recommended height: 64px</p>'"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <label
                                class="label"
                                for="sponsorUrl"
                                title="playerProfileData.sponsorUrl"
                            >URL</label>
                            <v-text-field
                                id="sponsorUrl"
                                v-model="updatedData.playerProfileData.sponsorsArray[index].sponsorUrl
                                    "
                                solo
                                flat
                                @change="updateData()"
                            ></v-text-field>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p>
                                <i>If provided will allow click through to the URL in a new
                                    tab.</i>
                            </p>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <label
                                class="label"
                                for="sponsorText"
                                title="playerProfileData.sponsorText"
                            >Alt Text</label>
                            <v-text-field
                                id="sponsorText"
                                v-model="updatedData.playerProfileData.sponsorsArray[index].sponsorText
                                    "
                                solo
                                flat
                                @change="updateData()"
                            ></v-text-field>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p><i>This will be used as alt-text for the image.</i></p>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <v-btn @click="
                                deletePlayerSponsor(
                                    updatedData.playerProfileData.sponsorsArray[index]
                                )
                                ">Delete this sponsor</v-btn>
                        </v-flex>
                    </v-layout>
                </div>
            </template>
            <v-btn @click="addPlayerSponsor()">Add New Sponsor</v-btn>
        </section>

        <div
            v-if="!modules.default.LFCExclusive"
            class="subheading-wrapper"
        >
            <h3
                class="subheading"
                :class="visible === 'section-history'
                    ? 'subheading__open'
                    : 'subheading__closed'
                    "
                @click="togglePanel('section-history')"
                ref="section-history"
            >
                Career History
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-history'"
                color="accent"
                right
                @click="togglePanel('section-history')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-history')"
            >add_circle_outline</v-icon>
        </div>
        <!-- <h3 class="subheading" @click="togglePanel('section-history')" ref="section-history">Career History</h3> -->
        <section
            class="section-container section-history"
            v-show="visible === 'section-history'"
        >
            <template v-for="(item, index) in updatedData.playerProfileData.careerHistory">
                <div
                    :key="index"
                    class="repeater-row"
                >
                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <label
                                class="label"
                                for="dateString"
                                title="playerProfileData.careerHistory[index].dateString"
                            >Dates</label>
                            <v-text-field
                                id="dateString"
                                v-model="updatedData.playerProfileData.careerHistory[index].dateString
                                    "
                                solo
                                flat
                                @change="updateData()"
                            ></v-text-field>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p>
                                <i>Add the dates your player was at the club. Reccomended format
                                    'MM/YYYY - MM/YYYY'</i>
                            </p>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex mt-2>
                            <label
                                class="label"
                                for=""
                                title="playerProfileData.careerHistory[index].clubCrest"
                            >Club {{ index + 1 }}</label>
                            <ImageUploader
                                :title="'Club Crest'"
                                :widthOptionEnabled="false"
                                :mediaLibraryID="updatedData.playerProfileData.careerHistory[index].clubCrest
                                    "
                                @update="updateImage($event, 'clubCrest', `${index}`)"
                                :desc="'<p>This will display in the career history table.</p><p>Recommended size: width: 64px | height: 64px</p>'"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <label
                                class="label"
                                for="clubName"
                                title="playerProfileData.careerHistory[index].clubName"
                            >Club Name</label>
                            <v-text-field
                                id="clubName"
                                v-model="updatedData.playerProfileData.careerHistory[index].clubName
                                    "
                                solo
                                flat
                                @change="updateData()"
                            ></v-text-field>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p><i></i></p>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <label
                                class="label"
                                for="appearances"
                                title="playerProfileData.careerHistory[index].appearances"
                            >Appearances</label>
                            <v-text-field
                                id="appearances"
                                v-model.number="updatedData.playerProfileData.careerHistory[index].appearances
                                    "
                                solo
                                flat
                                @change="updateData()"
                            ></v-text-field>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <v-btn
                                class="error"
                                @click="
                                    deleteHistoryItem(
                                        updatedData.playerProfileData.careerHistory[index]
                                    )
                                    "
                            >Delete this item</v-btn>
                        </v-flex>
                    </v-layout>
                    <!-- <v-layout row>
          <v-flex mr-5 class="half">
             <v-btn @click="deleteHistoryItem(updatedData.playerProfileData.careerHistory[index])">Delete this item</v-btn>
          </v-flex>
        </v-layout> -->
                </div>
            </template>
            <v-btn
                class="accent"
                @click="addHistoryItem()"
            >Add New Item</v-btn>
        </section>

        <div
            v-if="!modules.default.LFCExclusive"
            class="subheading-wrapper"
        >
            <h3
                class="subheading"
                :class="visible === 'section-awards'
                    ? 'subheading__open'
                    : 'subheading__closed'
                    "
                @click="togglePanel('section-awards')"
                ref="section-awards"
            >
                Career Awards
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-awards'"
                color="accent"
                right
                @click="togglePanel('section-awards')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-awards')"
            >add_circle_outline</v-icon>
        </div>
        <!-- <h3 class="subheading" @click="togglePanel('section-awards')" ref="section-awards">Career Awards</h3> -->
        <section
            class="section-container section-awards"
            v-show="visible === 'section-awards'"
        >
            <template v-for="(item, index) in updatedData.playerProfileData.careerAwards">
                <div
                    :key="index"
                    class="repeater-row"
                >
                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <label
                                class="label"
                                for="dateString"
                                title="playerProfileData.careerAwards[index].dateString"
                            >Dates</label>
                            <v-text-field
                                id="dateString"
                                v-model="updatedData.playerProfileData.careerAwards[index].dateString
                                    "
                                solo
                                flat
                                @change="updateData()"
                            ></v-text-field>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p>
                                <i>Add the date the player received the award. Reccomended
                                    format 'MM/YYYY'</i>
                            </p>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex mt-2>
                            <label
                                class="label"
                                for=""
                                title="playerProfileData.careerAwards[index].clubCrest"
                            >Club {{ index + 1 }}</label>
                            <ImageUploader
                                :title="'Club Crest'"
                                :widthOptionEnabled="false"
                                :mediaLibraryID="updatedData.playerProfileData.careerAwards[index].clubCrest
                                    "
                                @update="updateImage($event, 'clubCrestAward', `${index}`)"
                                :desc="'<p>This will display in the career awards table.</p><p>Recommended size: width: 64px | height: 64px</p>'"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <label
                                class="label"
                                for="clubName"
                                title="playerProfileData.careerAwards[index].clubName"
                            >Club Name</label>
                            <v-text-field
                                id="clubName"
                                v-model="updatedData.playerProfileData.careerAwards[index].clubName
                                    "
                                solo
                                flat
                                @change="updateData()"
                            ></v-text-field>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <p><i></i></p>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex
                            mr-5
                            class="half"
                        >
                            <label
                                class="label"
                                for="awardName"
                                title="playerProfileData.careerAwards[index].awardName"
                            >Award Name</label>
                            <v-text-field
                                id="awardName"
                                v-model="updatedData.playerProfileData.careerAwards[index].awardName
                                    "
                                solo
                                flat
                                @change="updateData()"
                            ></v-text-field>
                        </v-flex>
                        <v-flex
                            mt-4
                            class="half"
                        >
                            <v-btn
                                class="error"
                                @click="
                                    deleteAwardItem(
                                        updatedData.playerProfileData.careerAwards[index]
                                    )
                                    "
                            >Delete this item</v-btn>
                        </v-flex>
                    </v-layout>
                    <!-- <v-layout row>
          <v-flex mr-5 class="half">
             <v-btn class="error" @click="deleteAwardItem(updatedData.playerProfileData.careerAwards[index])">Delete this item</v-btn>
          </v-flex>
        </v-layout> -->
                </div>
            </template>
            <v-btn
                class="accent"
                @click="addAwardItem()"
            >Add New Item</v-btn>
        </section>

        <div
            v-if="!modules.default.LFCExclusive"
            class="subheading-wrapper"
        >
            <h3
                class="subheading"
                :class="visible === 'section-loan' ? 'subheading__open' : 'subheading__closed'
                    "
                @click="togglePanel('section-loan')"
                ref="section-loan"
            >
                Loaned Player
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-loan'"
                color="accent"
                right
                @click="togglePanel('section-loan')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-loan')"
            >add_circle_outline</v-icon>
        </div>
        <!-- <h3 class="subheading" @click="togglePanel('section-loan')" ref="section-loan">Loaned Player</h3> -->
        <section
            class="section-container section-loan"
            v-show="visible === 'section-loan'"
        >
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="loan_Custom"
                        title="loan_Custom"
                    >Loaned in</label>
                    <v-select
                        id="loan_Custom"
                        item-text="name"
                        item-value="id"
                        :items="[
                            { name: 'Yes, this player is loaned in', id: true },
                            { name: 'No, this player is not loaned in', id: false },
                        ]"
                        v-model="updatedData.loan_Custom"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>

            <template v-if="updatedData.loan_Custom">
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="onLoanFrom_Custom"
                            title="onLoanFrom_Custom"
                        >On Loan From</label>
                        <v-text-field
                            id="onLoanFrom_Custom"
                            v-model="updatedData.onLoanFrom_Custom"
                            solo
                            flat
                            @change="updateData()"
                        ></v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i>-</i></p>
                    </v-flex>
                </v-layout>
            </template>
        </section>

        <div class="subheading-wrapper">
            <h3
                class="subheading"
                :class="visible === 'section-media'
                    ? 'subheading__open'
                    : 'subheading__closed'
                    "
                @click="togglePanel('section-media')"
                ref="section-media"
            >
                Media
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-media'"
                color="accent"
                right
                @click="togglePanel('section-media')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-media')"
            >add_circle_outline</v-icon>
        </div>
        <!-- <h3 class="subheading" @click="togglePanel('section-media')" ref="section-media">Media</h3> -->
        <section
            class="section-container section-media"
            v-show="visible === 'section-media'"
        >
            <!-- <v-layout row>
      <v-flex mr-5 class="half">
        <label class="label" for="advancedHeaderStyle" title="playerProfileData.advancedHeaderStyle">Advanced Header Style</label>

        <v-select
          id="advancedHeaderStyle"
          item-text="name"
          item-value="id"
          :items="[
            {name: 'Yes, use image led player profiles', id: true},
            {name: 'No, use the headshot', id: false}
          ]"
          v-model="updatedData.playerProfileData.advancedHeaderStyle"
          solo
          flat
          @change="updateData()">
        </v-select>
      </v-flex>
      <v-flex mt-4 class="half">
        <p><i>This turns on the advanced image led Player Profiles. If turned off we will use the headshot image.</i></p>
      </v-flex>
    </v-layout> -->

            <v-layout row>
                <v-flex mr-2>
                    <ImageUploader
                        mb-3
                        :title="'App Profile Hero Image'"
                        :widthOptionEnabled="false"
                        :mediaLibraryID="updatedData.playerProfileData.appProfileImage"
                        @update="updateImage($event, 'appProfileImage')"
                        :desc="'<p>For use only within the player profile on the app.</p><p>Recommended size: width: 600px | height: 900px</p>'"
                    />
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex mr-2>
                    <ImageUploader
                        mb-3
                        :title="'Squad Image'"
                        :widthOptionEnabled="false"
                        :mediaLibraryID="updatedData.playerProfileData.squadImage"
                        @update="updateImage($event, 'squadImage')"
                        :desc="'<p>For display on the squads page</p><p>Recommended size: width: 720px | height: 768px</p>'"
                    />
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex mr-2>
                    <ImageUploader
                        mb-3
                        :title="'Player Headshot'"
                        :widthOptionEnabled="false"
                        :mediaLibraryID="updatedData.playerProfileData.playerHeadshot"
                        @update="updateImage($event, 'playerHeadshot')"
                        :desc="'<p>For use around the site</p><p>Recommended size: width: 400px | height: 400px</p><p>*Important* Centre the subjects head within the image</p>'"
                    />
                </v-flex>
            </v-layout>
        </section>

        <div
            v-if="!modules.default.LFCExclusive"
            class="subheading-wrapper"
        >
            <h3
                class="subheading"
                :class="visible === 'section-gallery'
                    ? 'subheading__open'
                    : 'subheading__closed'
                    "
                @click="togglePanel('section-gallery')"
                ref="section-gallery"
            >
                Gallery
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-gallery'"
                color="accent"
                right
                @click="togglePanel('section-gallery')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-gallery')"
            >add_circle_outline</v-icon>
        </div>
        <!-- <h3 class="subheading" @click="togglePanel('section-gallery')" ref="section-gallery">Gallery</h3> -->
        <section
            class="section-container section-gallery"
            v-show="visible === 'section-gallery'"
        >
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <ImageGalleryWidget
                        @imageArrayUploaded="updateGallery($event)"
                        :playerProfileData="updatedData.playerProfileData.gallery"
                    >
                        <v-btn
                            class="player-gallery"
                            color="accent"
                            type="button"
                        >
                            <template v-if="updatedData.playerProfileData.gallery">
                                Manage Gallery Images - ({{
                                    updatedData.playerProfileData.gallery.length
                                }}
                                items)
                            </template>
                            <template v-else> Add Gallery Images </template>
                        </v-btn>
                    </ImageGalleryWidget>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>Select the images to display in the player gallery.</i></p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="playerProfileData.desktopGalleryOption"
                        title="playerProfileData.desktopGalleryOption"
                    >Desktop Gallery Cover</label>
                    <v-select
                        id="playerProfileData.desktopGalleryOption"
                        item-text="name"
                        item-value="value"
                        :items="galleryStyleOptions"
                        v-model="updatedData.playerProfileData.desktopGalleryOption"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <gallery-option
                        :location="'desktop'"
                        :option="updatedData.playerProfileData.desktopGalleryOption"
                    />
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="playerProfileData.mobileGalleryOption"
                        title="playerProfileData.mobileGalleryOption"
                    >Mobile Gallery Cover</label>
                    <v-select
                        id="playerProfileData.mobileGalleryOption"
                        item-text="name"
                        item-value="value"
                        :items="galleryStyleOptions"
                        v-model="updatedData.playerProfileData.mobileGalleryOption"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <gallery-option
                        :location="'mobile'"
                        :option="updatedData.playerProfileData.mobileGalleryOption"
                    />
                </v-flex>
            </v-layout>
        </section>

        <div
            v-if="!modules.default.LFCExclusive"
            class="subheading-wrapper"
        >
            <h3
                class="subheading"
                :class="visible === 'section-retail'
                    ? 'subheading__open'
                    : 'subheading__closed'
                    "
                @click="togglePanel('section-retail')"
                ref="section-retail"
            >
                Retail
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-retail'"
                color="accent"
                right
                @click="togglePanel('section-retail')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-retail')"
            >add_circle_outline</v-icon>
        </div>
        <!-- <h3 class="subheading" @click="togglePanel('section-retail')" ref="section-retail">Retail</h3> -->
        <section
            class="section-container section-retail"
            v-show="visible === 'section-retail'"
        >
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="primaryRetailLink"
                    >Retail Link (primary)</label>
                    <v-text-field
                        id="primaryRetailLink"
                        placeholder="https://"
                        solo
                        flat
                        v-model="updatedData.playerProfileData.primaryRetailLink"
                        @change="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i></i></p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="shirtType"
                    >Display Product</label>
                    <!-- <v-select
          id="shirtType"
          :items="['', 'Home', 'Away', 'Goalkeeper', 'Custom']"
          v-model="updatedData.playerProfileData.shirtType"
          solo
          flat
          @change="updateRetailImage()">
        </v-select> -->
                    <v-select
                        id="shirtType"
                        :items="['', 'Home', 'Away', 'Goalkeeper','Third']"
                        v-model="updatedData.playerProfileData.shirtType"
                        solo
                        flat
                        @change="updateRetailImage()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <!-- <p><i>The player retail widget will display the kit that is set in the web settings unless you upload a custom kit for the player.</i></p> -->
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <ImageUploader
                        v-if="updatedData.playerProfileData.shirtType === 'Custom'"
                        mb-3
                        :title="'Custom Retail Image'"
                        :widthOptionEnabled="false"
                        :mediaLibraryID="updatedData.playerProfileData.customShirtMediaLibraryID
                            "
                        @update="updateImage($event, 'customShirt')"
                        :desc="'<p>Recommended size: width: 400px | height: 400px</p><p>.png with transparency</p>'"
                    />
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i></i></p>
                </v-flex>
            </v-layout>
        </section>

        <div
            v-if="!modules.default.LFCExclusive"
            class="subheading-wrapper"
        >
            <h3
                class="subheading"
                :class="visible === 'section-news' ? 'subheading__open' : 'subheading__closed'
                    "
                @click="togglePanel('section-news')"
                ref="section-news"
            >
                News
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-news'"
                color="accent"
                right
                @click="togglePanel('section-news')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-news')"
            >add_circle_outline</v-icon>
        </div>
        <!-- <h3 class="subheading" @click="togglePanel('section-news')" ref="section-news">News</h3> -->
        <section
            class="section-container section-news"
            v-show="visible === 'section-news'"
        >
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="playerProfileData.newsStyle"
                        title="playerProfileData.newsStyle"
                    >News Panel Style</label>
                    <v-select
                        id="playerProfileData.newsStyle"
                        item-text="name"
                        item-value="value"
                        :items="newsOptions"
                        v-model="updatedData.playerProfileData.newsStyle"
                        solo
                        flat
                        @change="updateData()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>-</i></p>
                </v-flex>
            </v-layout>
        </section>
        <div class="subheading-wrapper">
            <h3
                class="subheading"
                :class="visible === 'section-delete' ? 'subheading__open' : 'subheading__closed'
                    "
                @click="togglePanel('section-delete')"
                ref="section-delete"
            >
                Delete Player
            </h3>
            <v-icon
                class="status-icon"
                v-if="visible === 'section-delete'"
                color="accent"
                right
                @click="togglePanel('section-delete')"
            >remove_circle_outline</v-icon>
            <v-icon
                class="status-icon"
                v-else
                color="accent"
                right
                @click="togglePanel('section-delete')"
            >add_circle_outline</v-icon>
        </div>
        <section
            class="section-container section-delete"
            v-show="visible === 'section-delete'"
        >
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <confirmation-modal
                        @confirmed="deletePlayer($route.params.playerID)"
                        class="ad-item__modal"
                        title="Delete Player"
                        text="This action is permanent. Are you sure you want to delete this player?"
                    >
                        <v-btn
                            class="accent"
                            color="error"
                        >Delete Player</v-btn>
                    </confirmation-modal>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >

                    <p><i>This action is permanent.</i></p>
                </v-flex>
            </v-layout>
        </section>

        <StickyFooter
            v-if="isNewPlayer"
            :errorMessage="errorMessage"
            :status="status"
            :buttonText="'Add New Player'"
            @buttonClicked="addNewPlayer()"
        />
        <StickyFooter
            v-else
            :errorMessage="errorMessage"
            :status="status"
            :buttonText="'Save Player'"
            @buttonClicked="savePlayer()"
        />
    </section>
</template>

<script>
import axios from "axios";
import Editor from "@tinymce/tinymce-vue";
import GalleryOption from "@/components/Library/GalleryOption/GalleryOption";
import ImageUploader from "@/components/Library/ImageUploader";
import ImageGalleryWidget from "@/components/Widgets/ImageGalleryWidget";
import Nationalities from "@/components/Library/Data/NationalitiesData";
import PlayerStatistics from "@/components/Squads/PlayerStatistics";
import SquadPositions from "@/components/Library/Data/SquadPositionsData";
import StickyFooter from "@/components/Library/StickyFooter";
import ConfirmationModal from "@/components/Library/GlobalModals/ConfirmationModal";

export default {
    name: "SquadManagement",

    components: {
        "tinymce-editor": Editor,
        GalleryOption,
        ImageGalleryWidget,
        ImageUploader,
        PlayerStatistics,
        StickyFooter,
        ConfirmationModal
    },

    props: {
        data: {
            type: Object,
            required: false,
        },
    },

    created() {
        this.modules = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");
        this.betaFeatures = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/beta.js");
        this.defaultSettings = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/defaultSettings.js");
    },

    data: () => {
        return {
            modules: null,
            betaFeatures: null,
            api: `${process.env.VUE_APP_BASEURL}/players`,
            dataReceived: false,
            updatedData: {},
            Nationalities,
            SquadPositions,
            status: "saved",
            errorMessage: "",
            dateModalDob: null,
            dateModalDoj: null,
            readonly: true,
            isCustom: false,
            dataType: null,
            teamID: null,
            seasonID: null,
            isNewPlayer: false,
            positions: [],
            visible: "section-publish",
            pageStructureOptions: [
                { name: "Hide", value: "hide" },
                { name: "Light", value: "light" },
                { name: "Dark", value: "dark" },
                { name: "Club", value: "club" },
            ],
            newsOptions: [
                { name: "Show as carousel", value: "carousel" },
                { name: "Show as grid", value: "slider" },
            ],
            galleryStyleOptions: [
                { name: "2 images", value: 2 },
                { name: "3 images", value: 3 },
                { name: "4 images", value: 4 },
                { name: "5 images", value: 5 },
            ],
            defaultSettings: null,
        };
    },

    mounted() {
        console.log("dataType", this.dataType);
        if (this.$route.params.playerType === "custom") {
            this.isCustom = true;
        } else {
            this.isCustom = false;
        }

        if (process.env.VUE_APP_SPORT === "football") {
            this.positions = [
                "Goalkeeper",
                "Defender",
                "Midfielder",
                "Forward",
                "Staff",
            ];
        } else if (process.env.VUE_APP_SPORT === "rugby-league") {
            this.positions = [
                "Back Row",
                "Half Back",
                "Hooker",
                "Outside Back",
                "Prop",
                "Utility",
                "Staff",
            ];
        }

        this.teamID = this.$route.params.teamID;
        this.seasonID = this.$route.params.seasonID;

        this.updatedData = {
            published: 0,
            enableProfilePage: true,
            optaShowStats: null,
            playerID: null,
            firstName_Custom: null,
            middleName_Custom: null,
            surname_Custom: null,
            shirtNumber_Custom: null,
            position_Custom: null,
            realPosition: null,
            dob_Custom: null,
            country_Custom: null,
            customAlpha2Code: null,
            loan_Custom: null,
            onLoanFrom_Custom: null,
            joinDate_Custom: null,
            voiceBiography: null,
            productIdHome: null,
            productIdAway: null,
            playerSlug: null,
            playerProfileData: {
                hasVideo: false,
                // advancedHeaderStyle: false,
                profileImage: null,
                appProfileImage: null,
                squadImage: null,
                playerHeadshot: null,
                playerProfileBackground: null,
                displayHeadshot: null,
                instagram: null,
                threads: null,
                twitter: null,
                tiktok: null,
                biography: null,
                quote: null,
                quotee: null,
                quoteSize: null,
                bioLayout: null,
                bioImage: null,
                bioImageKey: null,
                primaryRetailLink: null,
                shirtType: null,
                customShirtImageKey: null,
                customShirtMediaLibraryID: null,
                // clubIdNumber: null,
                squadCaptain: null,
                sponsorsArray: [],
                gallery: [],
                // debut: null,
                stats: [],
                careerHistory: [],
                careerAwards: [],
                pageStructure: {
                    bio: null,
                    social: null,
                    sponsors: null,
                    stats: null,
                    history: null,
                    awards: null,
                    gallery: null,
                    news: null,
                    retail: null,
                    video: null,
                },
                newsStyle: null,
            },
        };

        if (this.$route.params.playerID === "createplayer") {
            this.dataLoaded = true;
            this.isNewPlayer = true;
            this.dataReceived = true;
            this.status = "needsSaving";
            this.$store.commit("completeLoading");
        } else {
            this.fetchPlayer();
            this.saveDisabled = true;
        }
    },

    methods: {
        updateGallery(payload) {
            console.log("Update Gallery", payload);
            this.updatedData.playerProfileData.gallery = payload.gallery;
            this.updateData();
        },

        updateData() {
            this.$emit("update", this.updatedData);
            this.status = "needsSaving";
            console.log(this.updatedData);
        },

        updateRetailImage() {
            if (this.updatedData.playerProfileData.shirtType != "Custom") {
                this.updatedData.playerProfileData.customShirtImageKey = null;
                this.updatedData.playerProfileData.customShirtMediaLibraryID = null;
            }
            this.updateData();
        },

        fetchPlayer() {
            this.$store.commit("startLoading");

            let fetchEndpoint = `${this.api}/opta/getsingle?seasonID=${this.seasonID}&teamID=${this.teamID}&playerID=${this.$route.params.playerID}`;

            if (this.isCustom) {
                fetchEndpoint = `${this.api}/custom/getsingle?seasonID=${this.seasonID}&teamID=${this.teamID}&playerID=${this.$route.params.playerID}`;
            }

            console.log("fetchEndpoint", fetchEndpoint);

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${fetchEndpoint}`, { headers: { Authorization: jwt } })
                    .then((response) => {
                        console.log("Fetch player response 1: ", response);

                        const tempData = response.data.body;

                        if (!tempData.published) {
                            tempData.published = 0;
                        }

                        const mergedPlayerProfileData = {
                            hasVideo: false,
                            // advancedHeaderStyle: false,
                            profileImage: null,
                            squadImage: null,
                            playerHeadshot: null,
                            playerProfileBackground: null,
                            displayHeadshot: null,
                            appProfileImage: null,
                            primaryRetailLink: null,
                            shirtType: null,
                            customShirtImageKey: null,
                            customShirtMediaLibraryID: null,
                            clubIdNumber: null,
                            squadCaptain: null,
                            instagram: null,
                            twitter: null,
                            threads: null,
                            tiktok: null,
                            biography: null,
                            bioImage: null,
                            bioImageKey: null,
                            quote: null,
                            quotee: null,
                            quoteSize: null,
                            bioLayout: null,
                            sponsorsArray: [],
                            debut: null,
                            stats: [
                                { displayType: "", data: {} },
                                { displayType: "", data: {} },
                                { displayType: "", data: {} },
                            ],
                            careerHistory: [],
                            careerAwards: [],
                            pageStructure: {
                                bio: null,
                                social: null,
                                sponsors: null,
                                stats: null,
                                history: null,
                                awards: null,
                                gallery: null,
                                news: null,
                                retail: null,
                                video: null,
                            },
                            newsStyle: null,
                            // pageStructure: {},
                            ...tempData.playerProfileData,
                        };

                        // if (tempData.playerProfileData) {
                        //   if (tempData.playerProfileData.facts) {

                        //     mergedPlayerProfileData.facts = {
                        //       ...tempData.playerProfileData.facts
                        //     }
                        //   }
                        // }

                        console.log("mergedPlayerProfileData: ", mergedPlayerProfileData);
                        console.log(
                            "playerProfileData not yet merged: ",
                            tempData.playerProfileData
                        );

                        tempData.playerProfileData = mergedPlayerProfileData;

                        console.log(
                            "playerProfileData merged: ",
                            tempData.playerProfileData
                        );

                        this.updatedData = tempData;

                        this.dataReceived = true;

                        this.$store.commit("completeLoading");
                    })
                    .catch((e) => {
                        console.error("Problem retrieving player", e);
                        this.$store.commit("completeLoading");
                    });
            });
        },

        addNewPlayer() {
            this.status = "saving";
            this.$store.commit("startLoading");

            const saveEndpoint = `${this.api}/custom/add?seasonID=${this.seasonID}&teamID=${this.teamID}`;

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "POST",
                    url: `${saveEndpoint}`,
                    headers: { Authorization: jwt },
                    data: {
                        published: this.updatedData.published,
                        enableProfilePage: this.updatedData.enableProfilePage,
                        firstName_Custom:
                            this.updatedData.firstName_Custom != ""
                                ? this.updatedData.firstName_Custom
                                : null,
                        knownName_Custom:
                            this.updatedData.knownName_Custom != ""
                                ? this.updatedData.knownName_Custom
                                : null,
                        middleName_Custom:
                            this.updatedData.middleName_Custom != ""
                                ? this.updatedData.middleName_Custom
                                : null,
                        surname_Custom:
                            this.updatedData.surname_Custom != ""
                                ? this.updatedData.surname_Custom
                                : null,
                        position_Custom: this.updatedData.position_Custom,
                        realPosition_Custom:
                            this.updatedData.realPosition_Custom != ""
                                ? this.updatedData.realPosition_Custom
                                : null,
                        shirtNumber_Custom:
                            this.updatedData.shirtNumber_Custom != ""
                                ? this.updatedData.shirtNumber_Custom
                                : null,
                        joinDate_Custom:
                            this.updatedData.joinDate_Custom != ""
                                ? this.updatedData.joinDate_Custom
                                : null,
                        loan_Custom: this.updatedData.loan_Custom,
                        onLoanFrom_Custom:
                            this.updatedData.onLoanFrom_Custom != ""
                                ? this.updatedData.onLoanFrom_Custom
                                : null,
                        dob_Custom:
                            this.updatedData.dob_Custom != ""
                                ? this.updatedData.dob_Custom
                                : null,
                        country_Custom:
                            this.updatedData.country_Custom != ""
                                ? this.updatedData.country_Custom
                                : null,
                        customAlpha2Code:
                            this.updatedData.customAlpha2Code != ""
                                ? this.updatedData.customAlpha2Code
                                : null,
                        showStats: false,
                        playerProfileData: this.updatedData.playerProfileData,
                        primaryRetailLink:
                            this.updatedData.primaryRetailLink != ""
                                ? this.updatedData.primaryRetailLink
                                : null,
                        playerSlug:
                            this.updatedData.playerSlug != ""
                                ? this.updatedData.playerSlug
                                : null,
                    },
                })
                    .then((response) => {
                        console.log("Saved player:", response);
                        this.$store.commit("completeLoading");
                        this.isNewPlayer = false;
                        this.$router.push({
                            path: `/team-management/player/${response.data.playerID}`,
                        });
                        this.status = "saved";
                    })
                    .catch((e) => {
                        console.error("Problem saving player", e);
                        this.$store.commit("completeLoading");
                        this.status = "needsSaving";
                        this.errorMessage = "Problem saving player";
                    });
            });
        },

        savePlayer() {
            this.status = "saving";
            this.$store.commit("startLoading");

            let saveEndpoint = `${this.api}/opta/updatesingle?seasonID=${this.seasonID}&teamID=${this.teamID}&playerID=${this.$route.params.playerID}`;

            let showStats = false;
            if (!this.isCustom) {
                showStats = this.updatedData.optaShowStats;
            }

            // if (this.updatedData.playerProfileData.pageStructure.length === 0) {
            //   this.updatedData.playerProfileData.pageStructure = {}
            // }

            if (this.updatedData.playerProfileData.twitter === "") {
                this.updatedData.playerProfileData.twitter = null;
            }

            if (this.updatedData.playerProfileData.threads === "") {
                this.updatedData.playerProfileData.threads = null;
            }

            if (this.updatedData.playerProfileData.instagram === "") {
                this.updatedData.playerProfileData.instagram = null;
            }

            if (this.updatedData.playerProfileData.tiktok === "") {
                this.updatedData.playerProfileData.tiktok = null;
            }

            if (this.updatedData.playerProfileData.biography === "") {
                this.updatedData.playerProfileData.biography = null;
            }

            if (this.updatedData.playerProfileData.quote === "") {
                this.updatedData.playerProfileData.quote = null;
            }

            if (this.updatedData.playerProfileData.quotee === "") {
                this.updatedData.playerProfileData.quotee = null;
            }

            if (this.updatedData.playerProfileData.quoteSize === "") {
                this.updatedData.playerProfileData.quoteSize = null;
            }

            if (this.updatedData.playerProfileData.bioLayout === "") {
                this.updatedData.playerProfileData.bioLayout = null;
            }

            if (this.updatedData.playerProfileData.debut === "") {
                this.updatedData.playerProfileData.debut = null;
            }

            if (this.updatedData.playerProfileData.primaryRetailLink === "") {
                this.updatedData.playerProfileData.primaryRetailLink = null;
            }

            if (this.updatedData.playerProfileData.profileImage === "") {
                this.updatedData.playerProfileData.profileImage = null;
            }

            if (this.updatedData.playerProfileData.playerProfileBackground === "") {
                this.updatedData.playerProfileData.playerProfileBackground = null;
            }

            if (this.updatedData.playerProfileData.displayHeadshot === "") {
                this.updatedData.playerProfileData.displayHeadshot = null;
            }

            if (this.updatedData.playerProfileData.squadImage === "") {
                this.updatedData.playerProfileData.squadImage = null;
            }

            if (this.updatedData.playerProfileData.playerHeadshot === "") {
                this.updatedData.playerProfileData.playerHeadshot = null;
            }

            if (this.updatedData.playerProfileData.appProfileImage === "") {
                this.updatedData.playerProfileData.appProfileImage = null;
            }

            if (this.isCustom) {
                saveEndpoint = `${this.api}/custom/updatesingle?seasonID=${this.seasonID}&teamID=${this.teamID}&playerID=${this.$route.params.playerID}`;
            }

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "POST",
                    url: `${saveEndpoint}`,
                    headers: { Authorization: jwt },
                    data: {
                        playerID: this.$route.params.playerID,
                        published: this.updatedData.published,
                        enableProfilePage: this.updatedData.enableProfilePage,
                        firstName_Custom:
                            this.updatedData.firstName_Custom != ""
                                ? this.updatedData.firstName_Custom
                                : null,
                        knownName_Custom:
                            this.updatedData.knownName_Custom != ""
                                ? this.updatedData.knownName_Custom
                                : null,
                        middleName_Custom:
                            this.updatedData.middleName_Custom != ""
                                ? this.updatedData.middleName_Custom
                                : null,
                        surname_Custom:
                            this.updatedData.surname_Custom != ""
                                ? this.updatedData.surname_Custom
                                : null,
                        position_Custom: this.updatedData.position_Custom,
                        realPosition_Custom:
                            this.updatedData.realPosition_Custom != ""
                                ? this.updatedData.realPosition_Custom
                                : null,
                        shirtNumber_Custom:
                            this.updatedData.shirtNumber_Custom != ""
                                ? this.updatedData.shirtNumber_Custom
                                : null,
                        joinDate_Custom:
                            this.updatedData.joinDate_Custom != ""
                                ? this.updatedData.joinDate_Custom
                                : null,
                        loan_Custom: this.updatedData.loan_Custom,
                        onLoanFrom_Custom:
                            this.updatedData.onLoanFrom_Custom != ""
                                ? this.updatedData.onLoanFrom_Custom
                                : null,
                        dob_Custom:
                            this.updatedData.dob_Custom != ""
                                ? this.updatedData.dob_Custom
                                : null,
                        country_Custom:
                            this.updatedData.country_Custom != ""
                                ? this.updatedData.country_Custom
                                : null,
                        customAlpha2Code:
                            this.updatedData.customAlpha2Code != ""
                                ? this.updatedData.customAlpha2Code
                                : null,
                        showStats: showStats == true ? 1 : 0,
                        playerProfileData: this.updatedData.playerProfileData,
                        primaryRetailLink:
                            this.updatedData.primaryRetailLink != ""
                                ? this.updatedData.primaryRetailLink
                                : null,
                        shirtType: this.updatedData.shirtType,
                        playerSlug:
                            this.updatedData.playerSlug != ""
                                ? this.updatedData.playerSlug
                                : null,
                    },
                })
                    .then((response) => {
                        console.log("Saved player:", response);
                        this.$store.commit("completeLoading");
                        this.status = "saved";
                        // fetch to ensure that the slug is not a duplicate
                        this.fetchPlayer();
                    })
                    .catch((e) => {
                        console.error("Problem saving player", e);
                        this.$store.commit("completeLoading");
                        this.status = "needsSaving";
                        this.errorMessage = "Problem saving player";
                    });
            });
        },

        updateImage(payload, src, key) {
            console.log("Update image:", payload);
            // const arrayKey = key
            switch (src) {
                case "profileImage":
                    this.updatedData.playerProfileData.profileImage =
                        payload.mediaLibraryID;
                    break;
                case "playerProfileBackground":
                    this.updatedData.playerProfileData.playerProfileBackground =
                        payload.mediaLibraryID;
                    this.updatedData.playerProfileData.playerProfileBackgroundKey =
                        payload.imageKey;
                    break;
                case "appProfileImage":
                    this.updatedData.playerProfileData.appProfileImage =
                        payload.mediaLibraryID;
                    this.updatedData.playerProfileData.appProfileImageKey =
                        payload.imageKey;
                    break;
                case "squadImage":
                    this.updatedData.playerProfileData.squadImage =
                        payload.mediaLibraryID;
                    break;
                case "playerHeadshot":
                    this.updatedData.playerProfileData.playerHeadshot =
                        payload.mediaLibraryID;
                    break;
                // case 'productHomeImage':
                //   this.updatedData.productIdHomeImageSrc = payload.mediaLibraryID
                //   break;
                // case 'productAwayImage':
                //   this.updatedData.productIdAwayImageSrc = payload.mediaLibraryID
                //   break;
                case "customShirt":
                    this.updatedData.playerProfileData.customShirtImageKey =
                        payload.imageKey;
                    this.updatedData.playerProfileData.customShirtMediaLibraryID =
                        payload.mediaLibraryID;
                    break;
                case "bioImage":
                    this.updatedData.playerProfileData.bioImageKey = payload.imageKey;
                    this.updatedData.playerProfileData.bioImage = payload.mediaLibraryID;
                    break;
                case "sponsorImage":
                    console.log("updating image", payload, src, key);
                    this.updatedData.playerProfileData.sponsorsArray[
                        key
                    ].sponsorImageKey = payload.imageKey;
                    this.updatedData.playerProfileData.sponsorsArray[key].sponsorImage =
                        payload.mediaLibraryID;
                    break;
                case "clubCrest":
                    console.log("updating image", payload, src, key);
                    this.updatedData.playerProfileData.careerHistory[key].clubCrestKey =
                        payload.imageKey;
                    this.updatedData.playerProfileData.careerHistory[key].clubCrest =
                        payload.mediaLibraryID;
                    break;
                case "clubCrestAward":
                    console.log("updating image", payload, src, key);
                    this.updatedData.playerProfileData.careerAwards[key].clubCrestKey =
                        payload.imageKey;
                    this.updatedData.playerProfileData.careerAwards[key].clubCrest =
                        payload.mediaLibraryID;
                    break;
            }
            this.updateData();
        },

        updatePlayerStats(stat, index) {
            // console.warn('updating in the parent component', stat, 'index', index)
            this.updatedData.playerProfileData.stats[index] = stat;
            this.updateData();
        },

        addPlayerSponsor() {
            this.updatedData.playerProfileData.sponsorsArray.push({
                sponsorImage: null,
                sponsorImageKey: null,
                sponsorText: null,
                sponsorUrl: null,
            });
        },

        deletePlayerSponsor(sponsor) {
            var position =
                this.updatedData.playerProfileData.sponsorsArray.indexOf(sponsor);
            this.updatedData.playerProfileData.sponsorsArray.splice(position, 1);
        },

        addHistoryItem() {
            this.updatedData.playerProfileData.careerHistory.push({
                dateString: null,
                clubCrest: null,
                clubCrestKey: null,
                clubName: null,
                appearances: null,
            });
        },

        deleteHistoryItem(item) {
            var position =
                this.updatedData.playerProfileData.careerHistory.indexOf(item);
            this.updatedData.playerProfileData.careerHistory.splice(position, 1);
        },

        addAwardItem() {
            this.updatedData.playerProfileData.careerAwards.push({
                dateString: null,
                clubCrest: null,
                clubCrestKey: null,
                clubName: null,
                awardName: null,
            });
        },

        deleteAwardItem(item) {
            var position =
                this.updatedData.playerProfileData.careerAwards.indexOf(item);
            this.updatedData.playerProfileData.careerAwards.splice(position, 1);
        },

        togglePanel(panel) {
            if (panel === this.visible) {
                this.visible = "";
            } else {
                this.visible = panel;
            }
        },

        editPlayer(playerID, playerType) {
            this.$store.commit("startLoading");
            this.$router.push({
                // path: `/player/${playerID}`,
                name: "Player Editor",
                params: {
                    playerID: playerID,
                    playerType: playerType,
                    teamID: this.teamID,
                    seasonID: this.seasonID,
                },
            });
        },

        applyDefaultLayout() {
            this.updatedData.playerProfileData.displayHeadshot =
                this.defaultSettings.default.playerPageDefaultLayout.displayHeadshot; // 'hero' | 'headshot'
            this.updatedData.playerProfileData.mobileGalleryOption =
                this.defaultSettings.default.playerPageDefaultLayout.mobileGalleryOption; // 2 | 3 | 4 | 5
            this.updatedData.playerProfileData.desktopGalleryOption =
                this.defaultSettings.default.playerPageDefaultLayout.desktopGalleryOption; // 2 | 3 | 4 | 5
            this.updatedData.playerProfileData.quoteSize =
                this.defaultSettings.default.playerPageDefaultLayout.quoteSize;
            this.updatedData.playerProfileData.newsStyle =
                this.defaultSettings.default.playerPageDefaultLayout.newsStyle;
            this.updatedData.playerProfileData.pageStructure =
                this.defaultSettings.default.playerPageDefaultLayout.pageStructure;
            this.status = "needsSaving";
        },

        deletePlayer(playerID) {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "DELETE",
                    url: `${process.env.VUE_APP_TEAMS_API}/player/${playerID}?teamID=${this.teamID}&seasonID=${this.seasonID}`,
                    headers: { Authorization: jwt },
                })
                    .then(() => {
                        this.$router.push("/team-management/squads")
                    })
                    .catch((e) => {
                        window.alert("An Error has occurred");
                        console.error("Problem deleting player", e);
                    })
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.new-page-alert {
    align-items: center;
}

.subheading-wrapper {
    position: relative;
}

.subheading {
    &__closed {
        border-bottom: 1px solid $lightgrey;
        padding-bottom: 12px;
    }

    &:hover {
        cursor: pointer;
    }
}

.status-icon {
    position: absolute;
    top: 0;
    right: 0;
}

.section-container {
    border-radius: 0 0 4px 4px;
}

.half {
    width: 50%;
}

.section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dfe3e9;
}

.break {
    margin: 16px 0 32px;
    opacity: 0.3;
}

.checkbox-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    &__input {
        margin: auto 0 auto 0;
        width: auto;
        flex: none;
        padding: 0;
    }

    &__label {
        padding-top: 1px;
    }
}

.opta {
    &__table-heading {
        padding: 4px 16px 4px 0;
        text-align: left;
    }
}

.player {
    &__checkbox {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    &__toggle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &__panel {
        border: 1px solid $lightgrey;
        background-color: $white;
        padding: 20px;
        margin-bottom: 20px;

        &-details {
            flex-direction: column;

            @media (min-width: 960px) {
                flex-direction: row;
            }
        }

        &-img {
            img {
                width: 100%;
            }
        }

        &-opta {
            &-cb {
                label {
                    margin-bottom: 0;
                }
            }
        }

        &--column {
            flex-direction: column;
            width: 100%;
        }
    }
}

.input {
    &__textfield {
        &--basic {
            margin-top: 0;
        }
    }
}

.note {
    padding: 10px;
    margin: 0 10px 30px;
    font-size: 13px;
    font-style: italic;
    background-color: #f4f6d2;
    border: 1px solid #d6d8b0;
    opacity: 0;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    transform: translateX(-30px);
}

.has-note:hover .note {
    opacity: 0.8;
    transform: translateX(0);
}

.repeater-row {
    padding: 4px 0;
    border-bottom: 2px solid $bluegrey;
}

.repeater-row:last-child {
    border-bottom: none;
}

.notice {
    background-color: #f4f6d2;
    padding: 8px 16px;
    border: 1px solid #d6d8b0;
    border-radius: 8px;
}
</style>
