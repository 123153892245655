<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <p class="widget-info">This widget gives you a custom carousel that allows you to build items with a custom image, background, text and link.</p>

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()">Use this widget</v-btn>
    </template>

    <div class="widget-fieldset" v-else>

    <label v-if="!addCard && !editCard" class="label">Title</label>
      <v-text-field
        v-if="!addCard && !editCard"
        id="widgetTitle"
        v-model="updatedData.widgetData.title"
        solo
        flat
      ></v-text-field>

    <label v-if="!addCard && !editCard" class="label">Style</label>
      <v-select
        v-if="!addCard && !editCard"
        id="widgetStyle"
        :items="styleOptions"
        solo
        flat
        v-model="updatedData.widgetData.style">
      </v-select>

    <label v-if="!addCard && !editCard" class="label">Button URL</label>
      <v-text-field
        v-if="!addCard && !editCard"
        id="widgetButtonURL"
        solo
        flat
        v-model="updatedData.widgetData.buttonUrl">
      </v-text-field>

    <label v-if="!addCard && !editCard" class="label">Button Text</label>
      <v-text-field
        v-if="!addCard && !editCard"
        id="widgetButtonText"
        solo
        flat
        v-model="updatedData.widgetData.buttonText">
      </v-text-field>

    <v-btn
    v-if="!editCard"
      color="accent"
      outline
      flat
      @click="addCard = true; editCard = true;"
    >
      Add Item
    </v-btn>

    <template v-if="!addCard">
    <div v-for="(card, index) in updatedData.widgetData.cardData" :key="card.index">
      <custom-carousel-item
      v-if="isRowVisible(index)"
      :heading="card.heading"
      :link="card.link"
      :number="card.number"
      :player="card.player"
      :layout="card.layout"
      :color="card.color"
      :imageID="card.cardImageSettings.imageID"
      :imageIDKey="card.cardImageSettings.imageIDKey"
      :imageHoverID="card.cardImageSettings.imageHoverID"
      :imageHover="card.imageHover"
      :searchTerm="card.searchTerm"
      @deleteItem="deleteRow(index)"
      @hideOthers="hideRows($event, index)"
      @saveRow="rowUpdated($event, index)"
      />
    </div>
    </template>

    <custom-carousel-item-form
    v-if="addCard"
    @saveRow="addNewCardItem($event)"
    @cancelEdit="cancelNewCardItem()" />

      <!-- <pre>
        {{updatedData}}
      </pre> -->
    </div>
  </div>
</template>

<script>
import ImageUploader from "@/components/Library/ImageUploader.vue"
import CustomCarouselItem from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/CustomCarouselItem"
import CustomCarouselItemForm from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/CustomCarouselItemForm"

export default {
  name: "CustomCarousel",

  components: {
    ImageUploader,
    CustomCarouselItem,
    CustomCarouselItemForm
  },

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent()
      },
      deep: true
    }
  },

  props: {
    editData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    widgetTitle: null,
    addCard: null,
    editCard: null,
    cardHeading: null,
    cardNumber: null,
    isPlayerCard: null,
    cardLayout: null,
    cardColorHex: null,
    isBackgroundHover: null,
    cardLink: null,
    cardSearchTerm: null,
    updatedData: {
      title: null,
      style: null,
      buttonUrl: null,
      buttonText: null
    },
    onlyVisibleRow: null,
    cardImageSettings: {
      cardImageID: null,
      cardImageIDKey: null,
      cardImageHoverID: null,
      cardImageHoverIDKey: null,
    },
    styleOptions: [{text: "Light Mode", value: "light"}, {text: "Dark Mode", value: "dark"},{text: "Club Mode", value: "club"}],
  }),

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.editData))
    // console.log('Carousel data here', this.updatedData)
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "CustomCarousel"
      this.updatedData.widgetName = "Custom Carousel"
      this.updatedData.widgetID = this.$uuid.v1()
      this.updatedData.widgetData = {
        carouselTitle: "Custom Carousel",
        carouselDescription: "Custom players carousel",
        cardData:[

        ]
      }
      this.$emit("initialised")
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData)
      // console.log('UpdatedContent')
    },

    addNewCardItem(payload) {
      console.log("adding new card item", payload)
      const newListItem = {
        id: this.$uuid.v1(),
        heading: payload.heading,
        number: payload.number,
        player: payload.player,
        layout: payload.layout,
        color: payload.color,
        imageID: payload.imageID,
        imageHoverID: payload.imageHoverID,
        imageHover: payload.imageHover,
        link: payload.link,
        searchTerm: payload.searchTerm,
        cardImageSettings: {
          imageID: payload.cardImageSettings.imageID,
          imageIDKey: payload.cardImageSettings.imageIDKey,
          imageHoverID: payload.cardImageSettings.imageHoverID,
          imageHoverIDKey: payload.cardImageSettings.imageHoverIDKey
        }
      }
      // console.log('UPDATED DATA', this.updatedData)

      this.updatedData.widgetData.cardData.push(JSON.parse(JSON.stringify(newListItem)))
      this.updatedData.widgetData.carouselTitle = this.widgetTitle
      // console.log('new card item', newListItem)

      this.newListItem = {
        id: null,
        heading: null,
        number: null,
        player: null,
        layout: null,
        color: null,
        imageHover: null,
        link: null,
        searchTerm: null,
        cardImageSettings: {
          imageID: null,
          imageIDKey: null,
          imageHoverID: null,
          imageHoverIDKey: null,
        }
      }

      this.addCard = false
      this.editCard = false
    },

    // updateImage(payload,) {
    //   console.log('image payload', payload)
    //   // this.cardImageID = payload.mediaLibraryID
    //   // this.cardImageHoverID = payload.mediaLibraryID

    //   this.cardImageSettings[target] = payload.mediaLibraryID
    //   this.cardImageSettings[`${target}Key`] = payload.imageKey
    // },

    deleteRow(rowIndex) {
      if (confirm("Are you sure you want to delete this list item")) {
        this.updatedData.widgetData.cardData.splice(rowIndex, 1);
      }
    },

    cancelNewCardItem() {
      this.newListItem = {
        id: null,
        heading: null,
        number: null,
        player: null,
        layout: null,
        color: null,
        imageID: null,
        imageIDKey: null,
        imageIDHoverID: null,
        imageHoverID: null,
        imageHover: null,
        link: null,
        searchTerm: null
      }  
      this.addCard = false
      this.editCard = false
    },

    hideRows(hide, rowIndex) {
      this.editCard = hide

      if (hide) {
        this.onlyVisibleRow = rowIndex
      } else {
        this.onlyVisibleRow = null
      }
    },

    isRowVisible(rowIndex) {
      if (this.editCard) {
        if (rowIndex === this.onlyVisibleRow) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    rowUpdated(payload, index) {
      console.log("rowUpdated", index, payload)
      if (payload.heading) {
        this.updatedData.widgetData.cardData[index].heading = payload.heading
      } else {
        this.updatedData.widgetData.cardData[index].heading = null
      }

      if (payload.number) {
        this.updatedData.widgetData.cardData[index].number = payload.number
      } else {
        this.updatedData.widgetData.cardData[index].number = null
      }

      if (payload.player) {
        this.updatedData.widgetData.cardData[index].player = payload.player
      } else {
        this.updatedData.widgetData.cardData[index].player = null
      }

      if (payload.layout) {
        this.updatedData.widgetData.cardData[index].layout = payload.layout
      } else {
        this.updatedData.widgetData.cardData[index].layout = null
      }

      if (payload.color) {
        this.updatedData.widgetData.cardData[index].color = payload.color
      } else {
        this.updatedData.widgetData.cardData[index].color = null
      }

      if (payload.cardImageSettings.imageID) {
        this.updatedData.widgetData.cardData[index].cardImageSettings.imageID = payload.cardImageSettings.imageID
      } else {
        this.updatedData.widgetData.cardData[index].cardImageSettings.imageID = null
      }

      if (payload.cardImageSettings.imageIDKey) {
        this.updatedData.widgetData.cardData[index].cardImageSettings.imageIDKey = payload.cardImageSettings.imageIDKey
      } else {
        this.updatedData.widgetData.cardData[index].cardImageSettings.imageIDKey = null
      }

      if (payload.cardImageSettings.imageHoverID) {
        this.updatedData.widgetData.cardData[index].cardImageSettings.imageHoverID = payload.cardImageSettings.imageHoverID
      } else {
        this.updatedData.widgetData.cardData[index].cardImageSettings.imageHoverID = null
      }

      if (payload.cardImageSettings.imageHoverIDKey) {
        this.updatedData.widgetData.cardData[index].cardImageSettings.imageHoverIDKey = payload.cardImageSettings.imageHoverIDKey
      } else {
        this.updatedData.widgetData.cardData[index].cardImageSettings.imageHoverIDKey = null
      }

      if (payload.imageHover) {
        this.updatedData.widgetData.cardData[index].cardImageSettings.imageHover = payload.imageHover
      } else {
        this.updatedData.widgetData.cardData[index].imageHover = null
      }

      if (payload.link) {
        this.updatedData.widgetData.cardData[index].link = payload.link
      } else {
        this.updatedData.widgetData.cardData[index].link = null
      }

      if (payload.searchTerm) {
        this.updatedData.widgetData.cardData[index].searchTerm = payload.searchTerm
      } else {
        this.updatedData.widgetData.cardData[index].searchTerm = null
      }
    },
  }
}
</script>

<style scoped lang='scss'>

</style>
