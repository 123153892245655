<template>
  <section>
    <!-- <template v-if="!labData">
      <v-btn @click="initialiseData()">Initialise Data</v-btn>
    </template> -->

    <div class="advert-slots">
      <section v-for="(slot) in labData" :key="slot.id" class="advert-slots__item">
        <div class="advert-slots__header">
          <h2 class="advert-slots__heading subheading">{{ slot.name }}</h2>

          <v-btn @click="openAdvertEditor(slot.id)">Add advert</v-btn>
        </div>

        <div class="advert-slots__items">
          <article v-for="advert in slot.adverts" :key="advert.id" class="advert">
            <p v-if="!advert.enabled" class="advert__status">Draft</p>
            <h3 class="advert__title">{{ advert.advertName }}</h3>
            <p class="advert__meta">Advert Ref: {{ advert.advertRef }}</p>
            <p class="advert__meta">Campaign Ref: {{ advert.campaignRef }}</p>
            <p class="advert__meta">Start: {{ advert.timings.startUTC }}</p>
            <p class="advert__meta">End: {{ advert.timings.endUTC }}</p>
            <p class="advert__meta">Channel: {{ advert.channel || 'All' }}</p>
            <img :src="`${imageDomain}/${advert.images.square.imageKey}`" class="advert__image">

            <div class="advert__actions">
              <v-btn @click="openAdvertEditor(slot.id, advert)">Edit advert</v-btn>
              <button class="advert__delete" @click="deleteAdvert(slot.id, advert)"><v-icon>delete</v-icon></button>
            </div>
          </article>
        </div>
      </section>
    </div>

    <edit-advert
      v-if="advertEditorOpen"
      :advertData="advertEditorData"
      @addAdvert="addAdvert($event)"
      @editAdvert="editAdvert($event)"
      @closeAdvert="advertEditorOpen = false">
    </edit-advert>

    <StickyFooter :errorMessage="errorMessage" :status="status" :buttonText="'Save'" @buttonClicked="postData()" />
  </section>
</template>

<script>
import axios from "axios"
import EditAdvert from "@/components/Experiments/AdvertisingModuleV1/EditAdvert"
import MiniHeader from "@/components/Library/MiniHeader"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
  name: "AdvertisingModuleV1Manager",

  components: {
    EditAdvert,
    MiniHeader,
    StickyFooter
  },

  data:() => ({
    status: "saved",
    errorMessage: "",
    imageDomain: process.env.VUE_APP_IMAGEURL,
    advertEditorOpen: false,
    advertEditorSlotID: "",
    advertEditorData: null,
    labData: null
  }),

  mounted() {
    this.fetchData()
  },

  methods: {
    initialiseData() {
      this.labData = {
        aggregatedFeed: {
          id: "aggregatedFeed",
          name: "Aggregated Feed",
          adverts: [] 
        },
        footerSlot1: {
          id: "footerSlot1",
          name: "Footer Slot 1",
          adverts: []
        },
        footerSlot2: {
          id: "footerSlot2",
          name: "Footer Slot 2",
          adverts: []
        },
        footerSlot3: {
          id: "footerSlot3",
          name: "Footer Slot 3",
          adverts: []
        },
        homepageTop: {
          id: "homepageTop",
          name: "Homepage Top",
          adverts: []
        },
        homepageBottom: {
          id: "homepageBottom",
          name: "Homepage Bottom",
          adverts: []
        },
        matches: {
          id: "matches",
          name: "Matches",
          adverts: []
        },
        news: {
          id: "news",
          name: "News",
          adverts: []
        }
      }

      this.status = "needsSaving";
    },

    fetchData() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_EXPERIMENTSAPI}/data`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("Response: ", response);
          this.labData = response.data.body
          this.$store.commit("completeLoading");
        }).catch((error) => {
          console.error("Lab error", error)
          this.$store.commit("completeLoading");
        })
      })
    },

    postData() {
      this.status = "saving";

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: `${process.env.VUE_APP_EXPERIMENTSAPI}/data`,
          headers: { "Authorization": jwt },
          data: this.labData
        })
        .then(response => {
          console.log("Saved homepage:", response);
          this.$store.commit("completeLoading");
          this.status = "saved";
        })
        .catch(e => {
          console.error("Problem saving homepage", e);
          this.$store.commit("completeLoading");
          this.status = "needsSaving";
          this.errorMessage = "Problem saving homepage";
        });
      })
    },

    openAdvertEditor(slotID, advertData) {
      this.advertEditorSlotID = slotID
      this.advertEditorData = advertData
      this.advertEditorOpen = true
    },

    addAdvert(payload) {
      console.log("updateAdvert", this.advertEditorSlotID, payload)

      this.labData[this.advertEditorSlotID].adverts.push(payload)

      this.advertEditorOpen = false

      this.status = "needsSaving";
    },

    editAdvert(payload) {
      console.log("updateAdvert", this.advertEditorSlotID, payload)

      let index = 0
      for (const advert of this.labData[this.advertEditorSlotID].adverts) {
        console.log("Advert", advert)
        if (payload.id === advert.id) {
          console.log("Match")
          this.labData[this.advertEditorSlotID].adverts[index] = payload
          break;
        }
        index++
      }

      this.advertEditorOpen = false

      this.status = "needsSaving";
    },

    deleteAdvert(slotID, payload) {
      this.advertEditorSlotID = slotID
      if (confirm("Are you sure you want to delete this item?")) {
        let index = 0
        for (const advert of this.labData[this.advertEditorSlotID].adverts) {
          console.log("Advert", advert)
          if (payload.id === advert.id) {
            console.log("Match")
            this.labData[this.advertEditorSlotID].adverts.splice(index, 1)
            break;
          }
          index++
        }
        this.status = "needsSaving";
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .advert-slots {
    margin: 32px 0 0;

    &__item {
      padding: 20px;
      margin: 0 0 16px;
      border: 1px solid #67247c;
      border-radius: 6px;
      background-color: white;
    }

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__heading {
      margin: 0;
    }

    &__items {
      margin: 32px -16px 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  .advert {
    margin: 0 16px 32px 16px;
    padding: 16px;
    width: calc(33.33% - 32px);
    background: #fafafa;
    border: 1px solid #ebebeb;
    border-radius: 6px;

    &__image {
      margin: 16px 0 0;
      max-width: 300px;
      width: 100%;
    }

    &__title {

    }

    &__meta {
      color: #666;
      font-size: 12px;
      opacity: 0.7;
      margin: 3px 0 0;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    &__delete {
      background-color: transparent;
      padding: 0;
      border: 0;
    }

    &__status {
      display: inline-block;
      padding: 3px 12px;
      margin: 0 8px 8px 0;
      font-size: 10px;
      color: #fff;
      background-color: #E6E8F1;
      border-radius: 4px;

      &--draft {
        background-color: color(draftHighlight);
      }

      &--scheduled {
        background-color: color(newHighlight);
      }

      &--active {
        background-color: #5fad41;
      }

      &--expired {
        background-color: color(hiddenHighlight);
      }
    }
  }

</style>
