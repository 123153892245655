<template>
    <section class="section-container">
        <v-layout>
            <v-flex
                mr-5
                class="half"
            >
                <label
                    class="label"
                    for="updatedData.heroType"
                    title="updatedData.heroType"
                >Hero type</label>
                <v-select
                    id="updatedData.heroType"
                    :items="heroTypeOptions"
                    solo
                    flat
                    v-model="updatedData.heroType"
                    @input="changeHeroType()"
                >
                </v-select>
            </v-flex>
            <v-flex
                mt-4
                class="half"
            >
                <p>
                    <i>
                        <template v-if="updatedData.heroType === 'generic'">The generic hero has a title and background image.</template>
                        <template v-else-if="updatedData.heroType === 'simple'">The simple hero has your brand logo, some body copy and a
                            background image.</template>
                        <template v-else-if="updatedData.heroType === 'carousel'">The Carousel Hero puts video on demand content at the
                            forefront
                            of your feed. It allows a carousel to be part of the header along
                            with the brand and a body text section. This is an enhanced
                            version of the Simple Hero.</template>
                        <template v-else-if="updatedData.heroType === 'nextLiveMatch'">The Next Live Match Hero puts the next live match video
                            content
                            front and centre. It automatically also displays a carousel of
                            related videos.</template>
                        <template v-else-if="updatedData.heroType === 'singleVideo'">The Single Video Hero lets you choose up to 5 videos in a
                            carousel that only shows one video at a time.</template>
                        <template v-else-if="updatedData.heroType === 'cinematic'">The Cinematic Hero lets you choose up to 5 videos, which are
                            featured one at a time.</template>
                        <template v-else-if="updatedData.heroType === 'multipurpose'">The Multipurpose Hero lets you choose up to 5 videos,
                            which are
                            featured one at a time.</template>
                    </i>
                </p>
            </v-flex>
        </v-layout>

        <template v-if="['generic', 'multipurpose'].includes(updatedData.heroType)">
            <v-layout>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="updatedData.content.title"
                        title="updatedData.content.title"
                    >Title</label>
                    <v-text-field
                        id="updatedData.content.title"
                        solo
                        flat
                        v-model="updatedData.content.title"
                    >
                    </v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i></i></p>
                </v-flex>
            </v-layout>
        </template>

        <template v-if="updatedData.heroType == 'multipurpose'">
            <v-layout>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="updatedData.content.subtitle"
                        title="updatedData.content.subtitle"
                    >Subtitle</label>
                    <v-text-field
                        id="updatedData.content.subtitle"
                        solo
                        flat
                        v-model="updatedData.content.subtitle"
                    >
                    </v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i></i></p>
                </v-flex>
            </v-layout>
        </template>

        <v-layout v-if="updatedData.heroType !== 'cinematic' &&
            !(updatedData.heroType == 'singleVideo' && $route.query.isApp == 'true')
            ">
            <!-- <v-flex mr-3> -->
            <ImageUploader
                :title="'Background Image'"
                :widthOptionEnabled="false"
                :mediaLibraryID="updatedData.content.backgroundImageID"
                @update="updateImage($event)"
                :desc="'<p>Background image that goes behind some heroes. Landscape images preferred</p>'"
            />
            <!-- </v-flex> -->
        </v-layout>

        <v-layout v-if="checkRenderConditions('heroMode')">
            <v-flex
                mr-5
                class="half"
            >
                <label
                    class="label"
                    for="updatedData.content.heroMode"
                    title="updatedData.content.heroMode"
                >Style</label>
                <v-select
                    id="updatedData.content.heroMode"
                    :items="defineAvailableStyles()"
                    solo
                    flat
                    v-model="updatedData.content.heroMode"
                >
                </v-select>
            </v-flex>
            <v-flex
                mt-4
                class="half"
            >
                <p>
                    <i>This changes text styles on the page to suit whether your
                        background image is light or dark.</i>
                </p>
            </v-flex>
        </v-layout>

        <v-layout v-if="checkRenderConditions('heroModeMultipurpose')">
            <v-flex
                mr-5
                class="half"
            >
                <label
                    class="label"
                    for="updatedData.content.heroMode"
                    title="updatedData.content.heroMode"
                >Style</label>
                <v-select
                    id="updatedData.content.heroMode"
                    :items="heroStyleOptionsPlus"
                    solo
                    flat
                    v-model="updatedData.content.heroMode"
                >
                </v-select>
            </v-flex>
            <v-flex
                mt-4
                class="half"
            >
                <p>
                    <i>This changes part of the background of the mobile hero to a
                        specific colour. It also alters the text colour styles on the page
                        to suit whether your background image is light or dark/club.</i>
                </p>
            </v-flex>
        </v-layout>

        <template v-if="!['generic', 'singleVideo', 'cinematic', 'multipurpose'].includes(
            updatedData.heroType
        )
            ">
            <template v-if="$route.query.isApp !== 'true'">
                <v-layout>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="updatedData.content.body"
                            title="updatedData.content.body"
                        >Description</label>
                        <v-textarea
                            id="updatedData.content.body"
                            solo
                            flat
                            v-model="updatedData.content.body"
                        >
                        </v-textarea>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i>Allows HTML</i></p>
                    </v-flex>
                </v-layout>
            </template>
            <div v-else>
                <v-layout>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="updatedData.content.header"
                            title="updatedData.content.header"
                        >Header</label>
                        <v-text-field
                            id="updatedData.content.header"
                            v-model="updatedData.content.header"
                            solo
                            flat
                        ></v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i>Header for the app version</i></p>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="updatedData.content.subheader"
                            title="updatedData.content.subheader"
                        >Subheader</label>
                        <v-text-field
                            id="updatedData.content.subheader"
                            v-model="updatedData.content.subheader"
                            solo
                            flat
                        ></v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i>Subheader for the app version</i></p>
                    </v-flex>
                </v-layout>
            </div>
        </template>

        <div
            class="carousel-widget"
            v-if="updatedData.heroType === 'carousel'"
        >
            <v-layout>
                <label
                    class="label"
                    for="updatedData.content.body"
                    title="updatedData.content.body"
                >Carousel Settings</label>
                <v-flex
                    mr-5
                    class="half"
                >
                    <video-carousel
                        :editData="carouselWidgetData"
                        @updatedWidget="addCarouselData($event)"
                        :allowTitle="true"
                        :allowStyle="true"
                    />
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>Use team and category filters or build a custom search to return
                            the content you want.</i>
                    </p>
                </v-flex>
            </v-layout>
        </div>

        <template v-if="updatedData.heroType === 'nextLiveMatch' &&
            modules.default.StreamAMGIntegration
            ">
            <v-layout>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="updatedData.content.mainCtaUrl"
                        title="updatedData.content.mainCtaText"
                    >Main CTA Text</label>
                    <v-text-field
                        id="updatedData.content.mainCtaUrl"
                        solo
                        flat
                        v-model="updatedData.content.mainCtaText"
                    >
                    </v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This is the text for the main CTA in the hero. Additional 'Watch
                            Live' / 'Listen Live' CTAs will be automatically surfaced if/when
                            a live stream is available.</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="updatedData.content.mainCtaUrl"
                        title="updatedData.content.mainCtaUrl"
                    >Main CTA Url</label>
                    <v-text-field
                        id="updatedData.content.mainCtaUrl"
                        solo
                        flat
                        v-model="updatedData.content.mainCtaUrl"
                    >
                    </v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>This is the destination for the main CTA in the hero.</i></p>
                </v-flex>
            </v-layout>

            <v-layout>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="updatedData.content.mainCtaType"
                        title="updatedData.content.mainCtaType"
                    >Main CTA Type</label>
                    <v-select
                        id="updatedData.content.mainCtaType"
                        :items="heroButtonOptions"
                        solo
                        flat
                        v-model="updatedData.content.mainCtaType"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>Choose your button type.</i></p>
                </v-flex>
            </v-layout>

            <v-layout v-if="modules.default.StreamAMGIntegration">
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="updatedData.content.body"
                        title="updatedData.content.body"
                    >Secondary Content Display</label>
                    <query-builder
                        @updateQuery="addCarouselData($event)"
                        :queryData="queryData"
                    />
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <!-- <p><i>Only related videos are currently supported with this hero type</i></p> -->
                    <p>
                        <i>Use team and category filters or build a custom search to return
                            the content you want.</i>
                    </p>
                </v-flex>
            </v-layout>
        </template>

        <div
            class="featured-videos"
            v-if="['singleVideo', 'cinematic', 'multipurpose'].includes(
                updatedData.heroType
            )
                "
        >
            <label class="label">Featured Videos settings</label>

            <template v-if="['singleVideo', 'cinematic'].includes(updatedData.heroType)">
                <label>Automatically switch between hero videos</label>
                <v-select
                    v-model="featuredVideosData.hasAutoPlay"
                    item-text="name"
                    item-value="value"
                    :items="[
                        {
                            name: 'Yes, the displayed video will be switched every few seconds',
                            value: true,
                        },
                        {
                            name: 'No, the user must manually click a button or swipe to see the next video',
                            value: false,
                        },
                    ]"
                    solo
                    flat
                />
                <template v-if="featuredVideosData.hasAutoPlay">
                    <label>Seconds between swapping videos</label>
                    <v-text-field
                        v-model="featuredVideosData.featuredVideosAutoplayTime"
                        solo
                        flat
                        type="number"
                        min="1"
                    />
                </template>
            </template>

            <v-layout v-if="updatedData.heroType == 'multipurpose'">
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="updatedData.content.multipurposeSubtype"
                        title="updatedData.content.multipurposeSubtype"
                    >Multipurpose hero subtype</label>
                    <v-select
                        id="updatedData.content.heroMode.multipurposeSubtype"
                        :items="multipurposeHeroOptions"
                        solo
                        flat
                        v-model="updatedData.content.multipurposeSubtype"
                        @input="handleMultipurposeSubtype()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>Selecting a subtype allows you to auto select videos from a
                            player or custom tag. Selecting a player will also show the
                            player's image, name and number on the hero, instead of the
                            title.</i>
                    </p>
                </v-flex>
            </v-layout>

            <div v-if="heroData.heroType == 'multipurpose' &&
                updatedData.content.multipurposeSubtype == 'player'
                ">
                <p v-if="!(playerData && playerData.length)">Loading...</p>
                <template v-else>
                    <label class="label">Select a Player to auto fetch related videos</label>
                    <v-tabs v-model="tab">
                        <template v-for="data in playerData">
                            <v-tab :key="data.id">{{ data.teamName }}</v-tab>
                            <v-tab-item
                                :key="data.id"
                                px-0
                                class="player-tab-item"
                            >
                                <span
                                    @click="selectPlayer(player, data)"
                                    class="player-pill"
                                    :class="{
                                        'player-pill--active':
                                            player.playerID == updatedData.content.multipurposePlayer,
                                    }"
                                    v-for="player in data.players"
                                    :key="player.id"
                                >{{
                                    player.knownName
                                    ? player.knownName
                                    : `${player.firstName} ${player.surname}`
                                }}</span>
                            </v-tab-item>
                        </template>
                    </v-tabs>
                    <div></div>
                </template>
            </div>

            <div v-if="heroData.heroType == 'multipurpose' &&
                updatedData.content.multipurposeSubtype == 'tag'
                ">
                <p v-if="!(categories && categories.length)">Loading...</p>
                <v-layout v-else>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label class="label">Category</label>
                        <v-select
                            v-model="updatedData.content.multipurposeTag"
                            item-text="attributes.name"
                            item-value="attributes.name"
                            :items="categories"
                            solo
                            flat
                            @change="autoFetchVideos('category')"
                        />
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Change this to auto fetch videos for the chosen category.</i>
                        </p>
                    </v-flex>
                </v-layout>
            </div>

            <p v-if="autoFetchText &&
                heroData.heroType == 'multipurpose' &&
                updatedData.content.multipurposeSubtype == 'player'
                ">
                {{ autoFetchText }}
            </p>

            <template v-if="updatedData.heroType !== 'multipurpose' ||
                updatedData.content.multipurposeSubtype
                ">
                <div>
                    <draggable
                        v-if="featuredVideosData.selectedFeaturedVideos"
                        v-model="featuredVideosData.selectedFeaturedVideos"
                        :options="{
                            ghostClass: 'ghost',
                            dragClass: 'sortable-drag',
                        }"
                        @start="drag = true"
                        @end="(drag = false), updatedHeroData()"
                    >
                        <transition-group
                            name="list-complete"
                            class="featured__items"
                        >
                            <div
                                v-for="(
                  item, index
                ) in featuredVideosData.selectedFeaturedVideos"
                                class="video-listing-item-container"
                                :key="`position-d--${item.data.id}`"
                            >
                                <video-listing-item
                                    class="video-listing-item"
                                    :item="item.data"
                                    :customThumbnail="item.customThumbnail"
                                    :alternativeMode="'customImage'"
                                    :imageData="item.image"
                                    :hasPositionSelector="true"
                                    @removed="removeVideo(item.data.id)"
                                    @useCustomThumbnail="useCustomThumbnail(item.data.id, $event)"
                                    @setCustomThumbnail="setCustomThumbnail(item.data.id, $event)"
                                />
                                <position-picker
                                    v-if="updatedData.heroType == 'cinematic' &&
                                        $route.query.isApp !== 'true'
                                        "
                                    class="position-picker"
                                    :item="item"
                                    :data="[
                                        {
                                            displayName: 'Mobile position',
                                            value:
                                                featuredVideosData.selectedFeaturedVideos[index]
                                                    .positionMobile || 'center center',
                                            width: '30%',
                                            padding: '100%',
                                        },
                                        {
                                            displayName: 'Desktop position',
                                            value:
                                                featuredVideosData.selectedFeaturedVideos[index]
                                                    .positionDesktop || 'center center',
                                            width: '80%',
                                            padding: '40%',
                                        },
                                    ]"
                                    @positionSelected="definePosition($event, index)"
                                />
                            </div>
                        </transition-group>
                    </draggable>
                </div>

                <v-dialog
                    v-model="isVideoSelectorOpen"
                    width="1400"
                    lazy
                    class="fixture-details"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="custom-button"
                            color="accent"
                            type="button"
                            v-bind="attrs"
                            @on="on"
                            @click="isVideoSelectorOpen = true"
                        >Add video</v-btn>
                    </template>

                    <slot slot="activator"></slot>
                    <v-card
                        v-if="isVideoSelectorOpen"
                        class="card"
                    >
                        <video-listing
                            :alternativeMode="'selection'"
                            @selected="addVideo($event)"
                            @unselected="removeVideo($event, true)"
                            :videoSelectionLimit="5"
                            :selectedVideos="videoIdList"
                        />
                    </v-card>
                </v-dialog>
            </template>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import ImageUploader from "@/components/Library/ImageUploader";
import VideoCarousel from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/VideoCarousel.vue";
import QueryBuilder from "@/components/VideosManager/Data/QueryBuilder.vue";
import VideoListing from "@/components/Streamline/VideoListing.vue";
import VideoListingItem from "@/components/Streamline/VideoListingItem.vue";
import PositionPicker from "@/components/Library/PositionPicker.vue";

export default {
    name: "HeroEditor",

    components: {
        ImageUploader,
        VideoCarousel,
        QueryBuilder,
        VideoListing,
        VideoListingItem,
        PositionPicker,
        draggable,
    },

    props: {
        heroData: {
            required: false,
        },
    },

    data: () => ({
        tab: 0,
        modules: null,
        isVideoSelectorOpen: false,
        currentHero: null,
        updatedData: {
            heroType: null,
            content: {
                heroMode: null,
                heroDescription: null,
                backgroundImageKey: null,
                backgroundImageID: null,
                buttonType: null,
                style: null,
                destinationUrl: null,
                title: null,
                subtitle: null,
                destinationLinkText: null,
                widgetData: null,
                multipurposeSubtype: null,
                multipurposePlayer: null,
                multipurposeTeam: null,
                multipurposeTag: null,
                body: "",
                heading: "",
                subheading: "",
                secondaryContentType: "relatedVideos",
            },
        },
        heroTypeOptions: [
            { text: "Generic", value: "generic" },
            { text: "Simple", value: "simple" },
            { text: "Carousel", value: "carousel" },
            { text: "Next Live Match", value: "nextLiveMatch" },
        ],
        heroStyleOptions: [
            { text: "Light Mode", value: "light" },
            { text: "Dark Mode", value: "dark" },
        ],
        heroStyleOptionsPlus: [
            { text: "Light Mode", value: "light" },
            { text: "Dark Mode", value: "dark" },
            { text: "Club Mode", value: "club" },
        ],
        heroButtonOptions: [
            "",
            "primary-light",
            "primary-dark",
            "ghost-light",
            "ghost-dark",
            "label-light",
            "label-dark",
        ],
        secondaryContentOptions: [
            { text: "Related Videos", value: "relatedVideos", selected: true },
        ],
        multipurposeHeroOptions: [
            { text: "Custom Hero", value: "custom" },
            { text: "Player Hero", value: "player" },
            { text: "Custom Tag Hero", value: "tag" },
        ],
        carouselWidgetData: {
            widgetType: "VideoCarousel",
            widgetName: "Video Carousel",
            widgetID: "0001",
            widgetData: {
                title: null,
                style: null,
                query: {
                    queryType: null,
                    queryScope: null,
                    category: null,
                    team: null,
                    searchString: null,
                },
                buttonStyle: null,
                destinationUrl: null,
                destinationLinkText: null,
            },
        },
        // Options for the next live match hero
        queryData: {
            queryType: null,
            queryScope: null,
            category: null,
            team: null,
            searchString: null,
        },
        // Options for the single video and cinematic heroes
        featuredVideosData: {
            hasAutoPlay: true,
            featuredVideosAutoplayTime: "5",
            selectedFeaturedVideos: [],
        },
        videoIdList: [],
        playersApi: `${process.env.VUE_APP_BASEURL}`,
        playerData: [],
        categories: [],
        categoriesApi: `${process.env.VUE_APP_VIDEOS_API}/categories`,
        autoFetchText: "",
    }),

    created() {
        if (["LFC"].includes(process.env.VUE_APP_CLUB_NAME)) {
            this.heroTypeOptions = [
                { text: "Generic", value: "generic" },
                { text: "Simple", value: "simple" },
                // {text: 'Carousel', value: 'carousel'},
                // { text: "Next Live Match", value: "nextLiveMatch" },
                { text: "Single Video", value: "singleVideo" },
                { text: "Cinematic", value: "cinematic" },
                { text: "Multipurpose", value: "multipurpose" },
            ];
            if (this.$route.query.isApp !== "true") {
                this.heroTypeOptions.push({
                    text: "Next Live Match",
                    value: "nextLiveMatch",
                });
            }
            this.handleMultipurposeSubtype();
        } else if (["Videos POC"].includes(process.env.VUE_APP_CLUB_NAME)) {
            this.heroTypeOptions = [
                { text: "Generic", value: "generic" },
                { text: "Simple", value: "simple" },
                { text: "Carousel", value: "carousel" },
                { text: "Next Live Match", value: "nextLiveMatch" },
                { text: "Single Video", value: "singleVideo" },
                { text: "Cinematic", value: "cinematic" },
                { text: "Multipurpose", value: "multipurpose" },
            ];
            this.handleMultipurposeSubtype();
        } else {
            if (this.$route.query.isApp == "true") {
                this.heroTypeOptions = [
                    // { text: "Generic", value: "generic" },
                    // { text: "Simple", value: "simple" },
                    // { text: "Carousel", value: "carousel" },
                    // { text: "Next Live Match", value: "nextLiveMatch" },
                    { text: "Single Video", value: "singleVideo" },
                    { text: "Cinematic", value: "cinematic" },
                    { text: "Multipurpose", value: "multipurpose" },
                ];
            } else {
                this.heroTypeOptions = [
                    { text: "Generic", value: "generic" },
                    { text: "Simple", value: "simple" },
                    { text: "Carousel", value: "carousel" },
                    { text: "Next Live Match", value: "nextLiveMatch" },
                    // { text: "Single Video", value: "singleVideo" },
                    // { text: "Cinematic", value: "cinematic" },
                    // { text: "Multipurpose", value: "multipurpose" },
                ];
            }
        }

        this.modules = require("../../../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");
    },

    mounted() {
        if (this.heroData.heroType) {
            this.updatedData = JSON.parse(JSON.stringify(this.heroData));
        }
        if (
            this.heroData.heroType === "carousel" ||
            this.heroData.heroType === "nextLiveMatch"
        ) {
            this.carouselWidgetData.widgetData = JSON.parse(
                JSON.stringify(this.heroData.content.widgetData)
            );
            this.queryData = JSON.parse(
                JSON.stringify(
                    this.heroData.content.widgetData.query ||
                    this.heroData.content.widgetData
                )
            );
        }
        if (
            ["singleVideo", "cinematic", "multipurpose"].includes(
                this.heroData.heroType
            )
        ) {
            if (
                this.heroData.content.widgetData &&
                this.heroData.content.widgetData.selectedFeaturedVideos
            ) {
                this.featuredVideosData = JSON.parse(
                    JSON.stringify(this.heroData.content.widgetData)
                );
            }
        }
        this.currentHero = this.heroData.heroType;

        if (this.heroData.heroType == "multipurpose") {
            this.handleMultipurposeSubtype();
        }
    },

    watch: {
        updatedData: {
            handler: function () {
                this.updatedHeroData();
            },
            deep: true,
        },
        featuredVideosData: {
            handler: function () {
                this.addFeaturedData();
                this.updateVideoIdList();
            },
            deep: true,
        },
        currentHero() {
            if (
                ["singleVideo", "cinematic", "multipurpose"].includes(
                    this.updatedData.heroType
                )
            ) {
                if (
                    !(
                        this.updatedData.content.widgetData &&
                        this.updatedData.content.widgetData.selectedFeaturedVideos
                    )
                ) {
                    if (this.featuredVideosData.selectedFeaturedVideos) {
                        if (this.featuredVideosData.selectedFeaturedVideos.length) {
                            this.updatedData.content.widgetData = JSON.parse(
                                JSON.stringify(this.featuredVideosData)
                            );
                        } else {
                            this.featuredVideosData = {
                                hasAutoPlay: true,
                                featuredVideosAutoplayTime: "5",
                                selectedFeaturedVideos: [],
                            };
                        }
                    } else {
                        this.featuredVideosData = {
                            hasAutoPlay: true,
                            featuredVideosAutoplayTime: "5",
                            selectedFeaturedVideos: [],
                        };
                    }
                }
            }

            if (this.heroData.heroType == "multipurpose") {
                this.handleMultipurposeSubtype();
            }
        },
    },

    methods: {
        updatedHeroData() {
            this.$emit("updatedData", this.updatedData);
        },

        updateImage(payload) {
            this.updatedData.content.backgroundImageID = payload.mediaLibraryID;
            this.updatedData.content.backgroundImageKey = payload.imageKey;
        },

        addCarouselData(carouselData) {
            // console.warn('updating the carousel')
            this.updatedData.content.widgetData = JSON.parse(
                JSON.stringify(carouselData.widgetData || carouselData)
            );
        },

        addFeaturedData() {
            this.updatedData.content.widgetData = JSON.parse(
                JSON.stringify(this.featuredVideosData)
            );
        },

        addVideo(video) {
            if (this.featuredVideosData.selectedFeaturedVideos.length < 5) {
                this.featuredVideosData.selectedFeaturedVideos.push({
                    data: video,
                    image: { mediaLibraryID: null },
                    customThumbnail: false,
                });
            }
        },

        removeVideo(id, bypass) {
            if (bypass || confirm("Are you sure you want to remove this item?")) {
                this.featuredVideosData.selectedFeaturedVideos =
                    this.featuredVideosData.selectedFeaturedVideos.filter(
                        (element) => element.data.id !== id
                    );
            }
        },

        updateVideoIdList() {
            if (this.featuredVideosData.selectedFeaturedVideos) {
                this.videoIdList = this.featuredVideosData.selectedFeaturedVideos.map(
                    (video) => {
                        return video.data.id;
                    }
                );
            }
        },

        useCustomThumbnail(id, value) {
            const index = this.featuredVideosData.selectedFeaturedVideos.findIndex(
                (video) => {
                    return video.data.id == id;
                }
            );
            this.featuredVideosData.selectedFeaturedVideos[index].customThumbnail =
                value;
        },

        setCustomThumbnail(id, image) {
            const index = this.featuredVideosData.selectedFeaturedVideos.findIndex(
                (video) => {
                    return video.data.id == id;
                }
            );
            this.featuredVideosData.selectedFeaturedVideos[index].image = image;
        },

        handleMultipurposeSubtype() {
            if (this.updatedData.content.multipurposeSubtype == "player") {
                this.getTeams();
            } else if (this.updatedData.content.multipurposeSubtype == "tag") {
                this.getCategories();
            }
        },

        changeHeroType() {
            this.currentHero = this.updatedData.heroType;
        },

        definePosition(pos, index) {
            pos.forEach((element) => {
                if (element.displayName == "Desktop position") {
                    this.$set(
                        this.featuredVideosData.selectedFeaturedVideos[index],
                        "positionDesktop",
                        element.value
                    );
                } else {
                    this.$set(
                        this.featuredVideosData.selectedFeaturedVideos[index],
                        "positionMobile",
                        element.value
                    );
                }
            });

            this.$forceUpdate();
        },

        async getTeams() {
            this.$getCurrentUserJwToken().subscribe(async (jwt) => {
                const teamRes = await axios.get(
                    `${this.playersApi}/fixtures/opta/filters`,
                    { headers: { Authorization: jwt } }
                );
                console.log("getTeams response", teamRes);
                const teamBody = teamRes.data.body;

                const teamData = [];

                teamBody.forEach((element) => {
                    teamData.push({
                        id: element.teamID,
                        teamName: element.teamName,
                    });
                });
                this.getPlayers(teamData);
            });
        },

        async getPlayers(teamData) {
            const that = this;

            const playerData = [];

            this.playerData = await new Promise((res, rej) => {
                teamData.forEach(async (element, index) => {
                    that.$getCurrentUserJwToken().subscribe(async (jwt) => {
                        const playerRes = await axios.get(
                            `${that.playersApi}/squads/opta?teamID=${element.id}`,
                            { headers: { Authorization: jwt } }
                        );
                        console.log("player res", playerRes);

                        let players = [];

                        if (playerRes.data && playerRes.data.body) {
                            players = Object.values(playerRes.data.body).flat();
                        }

                        players = players.filter((element) => element.published);

                        if (playerRes.data) {
                            playerData.push({
                                id: element.id,
                                teamName: element.teamName,
                                players,
                            });
                        } else {
                            playerData.push(undefined);
                        }

                        if (playerData.length == teamData.length) {
                            playerData.sort((a, b) => {
                                return (
                                    teamData.findIndex((team) => team.id === a.id) -
                                    teamData.findIndex((team) => team.id === b.id)
                                );
                            });

                            this.playerData = playerData.filter((team) => team != undefined);
                        }
                    });
                });
            });
        },

        defineAvailableStyles() {
            if (
                this.modules.default.LFCExclusive &&
                (this.heroData.heroType !== "generic" ||
                    (this.heroData.heroType == "generic" &&
                        this.$route.query.isApp !== "true")) &&
                this.heroData.heroType != "nextLiveMatch"
            ) {
                return this.heroStyleOptionsPlus;
            } else {
                return this.heroStyleOptions;
            }
        },

        getCategories() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.categoriesApi}`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log("retrieved CATEGORIES settings:", response.data.data);
                        this.categories = response.data.data;
                    })
                    .catch((e) => {
                        console.error("Problem getting CATEGORIES settings", e);
                    });
            });
        },

        autoFetchVideos(type, player) {
            this.autoFetchText = "Loading...";
            let filterQuery;

            if (type == "player") {
                filterQuery = `&q=(title:"${player.firstName} ${player.surname}","${player.surname
                    }"${player.knownName ? `,"${player.knownName}"` : ""
                    } OR tagData.searchTags:"${player.firstName} ${player.surname}","${player.surname
                    }"${player.knownName ? `,"${player.knownName}"` : ""
                    } OR tagData.tags:"${player.firstName} ${player.surname}","${player.surname
                    }"${player.knownName ? `,"${player.knownName}"` : ""
                    })&sort=publicationData.createdAt:desc`;
            } else if (type == "category") {
                filterQuery = `&q=(tagData.categories:"${this.updatedData.content.multipurposeTag}")&sort=publicationData.createdAt:desc`;
            }

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(
                        `${process.env.VUE_APP_STREAMLINEAPI}/search?page[size]=5${filterQuery}&sort=publicationData.createdAt:desc`,
                        { headers: { Authorization: jwt } }
                    )
                    .then((response) => {
                        console.log("Video fetch response:", response);

                        this.featuredVideosData.selectedFeaturedVideos =
                            response.data.data.map((obj) => {
                                return {
                                    data: obj,
                                    image: { mediaLibraryID: null },
                                    customThumbnail: false,
                                };
                            });

                        if (response.data.data.length) {
                            this.autoFetchText = "Videos fetched";
                            setTimeout(() => {
                                this.autoFetchText = "";
                            }, 4000);
                        } else {
                            this.autoFetchText = "No matching videos found";
                            setTimeout(() => {
                                this.autoFetchText = "";
                            }, 4000);
                        }
                    })
                    .catch((e) => {
                        this.autoFetchText = "Error fetching videos";
                        setTimeout(() => {
                            this.autoFetchText = "";
                        }, 4000);
                        console.error("Problem retrieving videos", e);
                        this.videosLoaded = true;
                    });
            });
        },

        selectPlayer(player, team) {
            if (this.updatedData.content.multipurposePlayer == player.playerID) {
                this.updatedData.content.multipurposeTeam = "";
                this.updatedData.content.multipurposePlayer = "";
            } else {
                this.updatedData.content.multipurposeTeam = team.id;
                this.updatedData.content.multipurposePlayer = player.playerID;
                this.autoFetchVideos("player", player);
            }
            this.$forceUpdate();
        },

        checkRenderConditions(property) {
            // Render conditions for other elements should slowly be moved into here.
            // Please add a comment for every rule as an easy way to easily keep track of changes
            switch (property) {
                case "heroMode":
                    // Most fields have style. Cinematic has style only on app and not desktop. Multipurpose has a different set of styles which is not this
                    if (
                        !["cinematic", "multipurpose"].includes(
                            this.updatedData.heroType
                        ) ||
                        (!["multipurpose"].includes(this.updatedData.heroType) &&
                            this.$route.query.isApp == "true")
                    ) {
                        // Apps don't have style enabled
                        if (this.$route.query.isApp == "true") {
                            return false;
                        } else {
                            return true;
                        }
                    }
                    break;

                case "heroModeMultipurpose":
                    // Multipurpose hero has 3 styles, but not on Apps
                    if (this.updatedData.heroType == "multipurpose") {
                        if (this.$route.query.isApp == "true") {
                            return false;
                        } else {
                            return true;
                        }
                    }
                    break;
                default:
                    break;
            }
        },
    },
};
</script>

<style scoped lang='scss'>
.half {
    padding: 10px;
    width: 50%;
}

.carousel-widget,
.featured-videos {
    background: #e6e8f1;
    margin: 16px -35px -55px;
    padding: 36px;
    overflow: hidden;
}

ul {
    padding-left: 0;
    margin-bottom: 24px;
}

.video-item {
    /deep/ .v-expansion-panel__body {
        padding: 0 24px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }
}

.custom-button {
    margin-left: 0;
}

.video-listing-item-container {
    &:not(:first-of-type) {
        margin-top: 24px;
    }
}

.video-listing-item {
    cursor: grab;
    margin-bottom: 0;
    margin-top: 0;
}

.position-picker {
    background: #fff;
    margin-bottom: 0;
    padding-top: 4px;
    padding-left: 4px;
    padding-bottom: 7px;
    position: relative;
    bottom: 16px;
    border-left: 1px solid #dfe3e9;
    border-right: 1px solid #dfe3e9;
    border-bottom: 1px solid #dfe3e9;
    border-radius: 0 0 4px 4px;
}

.player-pill {
    background: #24002f;
    color: white;
    border-radius: 30px;
    margin-right: 10px;
    margin-bottom: 4px;
    padding: 2px 6px;
    display: inline-block;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background: lighten(#24002f, 25%);
    }

    &--active {
        background: lighten(#67247c, 15%);

        &:hover {
            background: lighten(#67247c, 15%);
        }
    }
}

.player-tab-item {
    padding: 20px;
}

.ghost {
    opacity: 0;
}

.sortable-drag {
    transition: 0;
}
</style>
