<template>
  <div class="squad-tagger">
    <v-autocomplete
      id="squad-tagger"
      outline
      v-model="selected"
      :items="optionsData"
      item-value="name"
      item-text="name"
      no-data-text="loading..."
      multiple
      solo
      flat
      clearable
      dense
      chips
      deletable-chips
      @change="$emit('selected', selected)"
      >
    </v-autocomplete>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "SquadTagger",

  props: {
    values: {
      type: Array,
      required: false
    }
  },

  data:() => ({
    optionsData: [],
    selected: null
  }),

  mounted() {
    this.selected = JSON.parse(JSON.stringify(this.values))
    this.getSquads()
  },

  methods: {
    getSquads() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_BASEURL}/fixtures/opta/filters`, { "headers": { "Authorization": jwt }})
        .then(response => {
          const optaData = response.data.body;
          this.optionsData = optaData.map(item => {
            return {
              "id": item.teamID,
              "name": item.teamName,
              "seasons": item.seasons
            }
          });
        })
        .catch(e => {
          console.error("Problem retrieving fixtures", e);
        });
      })
    },
  }
}
</script>

<style scoped lang="scss">
  .squad-tagger {

  }
</style>
