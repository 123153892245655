export default {
  data: [
    {
      sectionName: "Homepage mini promo",
      sectionDesc: "",
      model: "minipromo",
      fields: [
        {
          label: "Label",
          model: "minipromo_promolabel",
          type: "Text"
        },
        {
          label: "Promo text",
          model: "minipromo_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "minipromo_promolink",
          type: "Text"
        },
        {
          label: "Promo image (100px x 100px)",
          model: "minipromo_promoimage",
          type: "Image"
        }
      ]
    }
  ]
}
