export default {
  data: [
    {
      sectionName: "Homepage promo - left",
      sectionDesc:
        "This is for the left hand side (top on smaller screens) promotional on the homepage",
      model: "promo_left",
      fields: [
        {
          label: "Title",
          model: "promo_left_promotitle",
          type: "Text",
        },
        {
          label: "Description",
          model: "promo_left_promotext",
          type: "Text",
        },
        {
          label: "Promo link",
          model: "promo_left_promolink",
          type: "Text",
        },
        {
          label: "Promo link text",
          model: "promo_left_promolinktext",
          type: "Text",
        },
        {
          label: "Promo image",
          model: "promo_left_promoimage",
          type: "Image",
        },
      ],
    },
    {
      sectionName: "Homepage promo - right",
      sectionDesc:
        "This is for the right hand side (bottom on smaller screens) promotional area on the homepage",
      model: "promo_right",
      fields: [
        {
          label: "Title",
          model: "promo_right_promotitle",
          type: "Text",
        },
        {
          label: "Description",
          model: "promo_right_promotext",
          type: "Text",
        },
        {
          label: "Promo link",
          model: "promo_right_promolink",
          type: "Text",
        },
        {
          label: "Promo link text",
          model: "promo_right_promolinktext",
          type: "Text",
        },
        {
          label: "Promo image",
          model: "promo_right_promoimage",
          type: "Image",
        },
      ],
    },
    {
      sectionName: "Product Carousel",
      sectionDesc: "This section can be used to advertise your kit promotions",
      model: "product_carousel",
      fields: [
        {
          label: "Enable Carousel",
          model: "product_carousel_enable",
          type: "Checkbox",
        },
        {
          label: "Heading",
          model: "product_carousel_heading",
          type: "Text",
        },
        {
          label: "Main CTA Link",
          model: "product_carousel_ctaLink",
          type: "Text",
        },

        {
          label: "Main CTA Link Text",
          model: "product_carousel_ctaText",
          type: "Text",
        },
        {
          label: "Label - Slot 1",
          model: "product_carousel_label_one",
          type: "Text",
        },
        {
          label: "Link text - Slot 1",
          model: "product_carousel_linkText_one",
          type: "Text",
        },
        {
          label: "Link - Slot 1",
          model: "product_carousel_link_one",
          type: "Text",
        },
        {
          label: "Product Image (400px x 400px with padding) - Slot 1",
          model: "product_carousel_image_one",
          type: "Image",
        },
        {
          label: "Label - Slot 2",
          model: "product_carousel_label_two",
          type: "Text",
        },
        {
          label: "Link text - Slot 2",
          model: "product_carousel_linkText_two",
          type: "Text",
        },
        {
          label: "Link - Slot 2",
          model: "product_carousel_link_two",
          type: "Text",
        },
        {
          label: "Product Image (400px x 400px with padding) - Slot 2",
          model: "product_carousel_image_two",
          type: "Image",
        },
        {
          label: "Label - Slot 3",
          model: "product_carousel_label_three",
          type: "Text",
        },
        {
          label: "Link text - Slot 3",
          model: "product_carousel_linkText_three",
          type: "Text",
        },
        {
          label: "Link - Slot 3",
          model: "product_carousel_link_three",
          type: "Text",
        },
        {
          label: "Product Image (400px x 400px with padding) - Slot 3",
          model: "product_carousel_image_three",
          type: "Image",
        },
        {
          label: "Label - Slot 4",
          model: "product_carousel_label_four",
          type: "Text",
        },
        {
          label: "Link text - Slot 4",
          model: "product_carousel_linkText_four",
          type: "Text",
        },
        {
          label: "Link - Slot 4",
          model: "product_carousel_link_four",
          type: "Text",
        },
        {
          label: "Product Image (400px x 400px with padding) - Slot 4",
          model: "product_carousel_image_four",
          type: "Image",
        },
      ],
    },
  ],
};
