<template>
  <v-dialog
    class="list-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>
      <v-form enctype="multipart/form-data" ref="listForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            class="widget__heading"
          >
            List
          </v-card-title>

          <v-card-text>
            <label v-if="!editRow" class="label" for="list-title">List Title</label>
            <v-text-field 
              v-if="!editRow"
              id="list-title"
              v-model="updatedData.listTitle"
              placeholder="List Title"
              solo
              flat
              validate-on-blur
            ></v-text-field>

            <label v-if="!editRow" class="label" for="list-description">List Description</label>
            <v-text-field
              v-if="!editRow"
              id="list-description"
              v-model="updatedData.listDescription"
              placeholder="List Description"
              solo
              flat
              validate-on-blur
            ></v-text-field>

            <h1 v-if="!editRow">LIST ITEMS</h1>

            <draggable v-model="updatedData.listData" v-if="!addRow"
              :options="{
              ghostClass: 'ghost',
              dragClass: 'sortable-drag'
              }" @start="drag=true" @end="drag=false" >
              <transition-group name="list-complete">
                <template v-for="(item, index) in updatedData.listData">
                  <list-widget-item
                    v-if="isRowVisible(index)"
                    :index="index" 
                    :key="item.id" 
                    :title="item.title" 
                    :content="item.content" 
                    :buttonText="item.buttonText"
                    :buttonLink="item.buttonLink"
                    :buttonType="item.buttonType"
                    :buttonIcon="item.buttonIcon"
                    :buttonIconPosition="item.buttonIconPosition"
                    :buttonNewTab="item.buttonNewTab"
                    :imageID="item.imageID"
                    :imageAlignRight="item.imageAlignRight"
                    @deleteItem="deleteRow(index)"
                    @hideOthers="hideRows($event, index)"
                    @saveRow="rowUpdated($event, index)"/>
                </template>
              </transition-group>
            </draggable>

            <v-btn v-if="!editRow"
              color="accent"
              outline
              flat
              @click="addRow = true; editRow = true"
            >
              Add Item
            </v-btn>

            <div v-if="addRow">
              <list-widget-item-form 
                :formHeading="`Add List Item`"
                @saveRow="addNewListItem($event)"
                @cancelEdit="cancelNewListRow()"
              />
            </div>
        
          </v-card-text>

          <v-card-actions v-if="!editRow">
            <v-spacer></v-spacer>
            <v-btn
              color="accent"
              outline
              flat
              @click="validate()"
            >
              Post
            </v-btn>
          </v-card-actions>
        
        </v-card>
      </v-form>
  </v-dialog>

</template>

<script>
import draggable from "vuedraggable"
import ListWidgetItem from "@/components/Widgets/ListWidgetComponents/ListWidgetItem"
import ListWidgetItemForm from "@/components/Widgets/ListWidgetComponents/ListWidgetItemForm"

export default {
  name: "ListWidgetV2",

  components: {
    draggable,
    ListWidgetItem,
    ListWidgetItemForm
  },

  props: {
    widgetData: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    dialog: false,
    valid: false,
    rules: {
      required: value => !!value || "Required."
    },
    addRow: false,
    editRow: false,
    updatedData: {
      listTitle: null,
      listDescription: null,
      listData: [
      ]
    },
    newListItem: {
      id: null,
      title: null,
      content: null
    },
    onlyVisibleRow: null,
  }),

  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
    }
  },

  methods: {
    validate() {
      this.errorMessage = ""
      if (this.$refs.listForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },

    updateData() {
      console.log("Update data:", this.updatedData);

      if (this.updatedData.listTitle === "") {
        this.updatedData.listTitle = null
      }
      if (this.updatedData.listDescription === "") {
        this.updatedData.listDescription = null
      }

      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
      
    },

    // List Item Methods
    addNewListItem(payload) {
      console.log("adding new list item", payload)
      const newListItem = {
        id: this.$uuid.v1(),
        title: payload.title,
        content: payload.content,
        buttonText: payload.buttonText,
        buttonLink: payload.buttonLink,
        buttonType: payload.buttonType,
        buttonIcon: payload.buttonIcon,
        buttonIconPosition: payload.buttonIconPosition,
        buttonNewTab: payload.buttonNewTab,
        imageID: payload.imageID,
        imageAlignRight: payload.imageAlignRight
      }
      console.log("new list item", newListItem)
      this.updatedData.listData.push(JSON.parse(JSON.stringify(newListItem)))

      this.newListItem = {
        id: null,
        title: null,
        content: null,
        buttonText: null,
        buttonLink: null,
        buttonType: null,
        buttonIcon: null,
        buttonIconPosition: null,
        buttonNewTab: null,
        imageID: null,
        imageAlignRight: null
      }

      this.addRow = false
      this.editRow = false
    },

    isRowVisible(rowIndex) {
      if (this.editRow) {
        if (rowIndex === this.onlyVisibleRow) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    hideRows(hide, rowIndex) {
      this.editRow = hide

      if (hide) {
        this.onlyVisibleRow = rowIndex
      } else {
        this.onlyVisibleRow = null
      }
    },

    deleteRow(rowIndex) {
      if (confirm("Are you sure you want to delete this list item")) {
        this.updatedData.listData.splice(rowIndex, 1);
      }
    },

    rowUpdated(payload, index) {
      console.log("rowUpdated", index, payload)
      if (payload.title) {
        this.updatedData.listData[index].title = payload.title
      } else {
        this.updatedData.listData[index].title = null
      }

      if (payload.content) {
        this.updatedData.listData[index].content = payload.content
      } else {
        this.updatedData.listData[index].content = null
      }

      if (payload.imageID) {
        this.updatedData.listData[index].imageID = payload.imageID
      } else {
        this.updatedData.listData[index].imageID = null
      }

      if (payload.imageAlignRight) {
        this.updatedData.listData[index].imageAlignRight = payload.imageAlignRight
      } else {
        this.updatedData.listData[index].imageAlignRight = null
      }

      if (payload.buttonText) {
        this.updatedData.listData[index].buttonText = payload.buttonText
      } else {
        this.updatedData.listData[index].buttonText = null
      }

      if (payload.buttonLink) {
        this.updatedData.listData[index].buttonLink = payload.buttonLink
      } else {
        this.updatedData.listData[index].buttonLink = null
      }

      if (payload.buttonType) {
        this.updatedData.listData[index].buttonType = payload.buttonType
      } else {
        this.updatedData.listData[index].buttonType = null
      }

      if (payload.buttonIcon) {
        this.updatedData.listData[index].buttonIcon = payload.buttonIcon
      } else {
        this.updatedData.listData[index].buttonIcon = null
      }

      if (payload.buttonIconPosition) {
        this.updatedData.listData[index].buttonIconPosition = payload.buttonIconPosition
      } else {
        this.updatedData.listData[index].buttonIconPosition = null
      }

      if (payload.buttonNewTab) {
        this.updatedData.listData[index].buttonNewTab = payload.buttonNewTab
      } else {
        this.updatedData.listData[index].buttonNewTab = null
      }
    },

    cancelNewListRow() {
      this.newListItem = {
        id: null,
        title: null,
        content: null,
        buttonText: null,
        buttonLink: null,
        buttonType: null,
        buttonIcon: null,
        buttonIconPosition: null,
        buttonNewTab: null,
        imageID: null,
        imageAlignRight: null
      }  
      this.addRow = false
      this.editRow = false
    }
  }
}
</script>

<style scoped lang='scss'>

//Draggable Stuff
  .list-complete-enter, .list-complete-leave-to {
    opacity: 0;
  }

  .list-complete-leave-active {
    position: absolute;
    width: 100%;
  }

</style>
