<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <p class="widget-info">
        This widget allows you to build a carousel displaying selected players.
      </p>

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()"
        >Use this widget</v-btn
      >
    </template>

    <div class="widget-fieldset" v-else>
      <label class="label">Title</label>
      <v-text-field
        id="widgetData.title"
        solo
        flat
        v-model="updatedData.widgetData.title"
      >
      </v-text-field>
      <template v-if="this.$route.query.isApp !== 'true'">
        <label class="label">Style</label>
        <v-select
          id="widgetData.style"
          :items="styleOptions"
          solo
          flat
          v-model="updatedData.widgetData.style"
        >
        </v-select>

        <label class="label">Button URL</label>
        <v-text-field
          id="widgetData.buttonURL"
          solo
          flat
          v-model="updatedData.widgetData.buttonUrl"
        >
        </v-text-field>

        <label class="label">Button Text</label>
        <v-text-field
          id="widgetData.buttonText"
          solo
          flat
          v-model="updatedData.widgetData.buttonText"
        >
        </v-text-field>
      </template>
      <template v-if="playerData.length && updatedData.widgetData">
        <label class="label"
          >Player List
          <span :class="{ visible: showError }" class="error-message"
            >Only up to 12 players may be chosen</span
          ></label
        >
        <v-tabs v-model="tab">
          <template v-for="data in playerData">
            <v-tab :key="data.id">{{ data.teamName }}</v-tab>
            <v-tab-item :key="data.id" px-0 class="main-body">
              <div v-for="(value, key) in data.players" :key="key">
                <h5 class="position">{{ key }}</h5>
                <div class="items">
                  <template v-if="filterPublishedPlayer(value).length > 0">
                    <span
                      v-for="player in filterPublishedPlayer(value)"
                      @click="
                        togglePlayer(
                          player.playerID,
                          player.knownName
                            ? player.knownName
                            : player.firstName + ' ' + player.surname,
                          data.id
                        )
                      "
                      class="player-item"
                      :class="{
                        'player-item--active':
                          updatedData.widgetData.players.includes(
                            player.playerID
                          ),
                      }"
                      :key="player.playerID"
                    >
                      {{
                        player.knownName
                          ? player.knownName
                          : player.firstName + " " + player.surname
                      }}
                    </span>
                  </template>
                  <template v-else>
                    There are no published players in this group
                  </template>
                </div>
              </div>
            </v-tab-item>
          </template>
        </v-tabs>
        <div></div>
        <label class="label">Selected Players</label>
        <div
          v-if="updatedData.widgetData.players.length"
          class="selected-players"
        >
          <span
            v-for="(playerName, index) in updatedData.widgetData.playerNames"
            @click="
              togglePlayer(
                updatedData.widgetData.players[index],
                playerName,
                updatedData.widgetData.team
              )
            "
            class="player-item player-item--active"
            :key="playerName"
          >
            {{ playerName }}
          </span>
        </div>
        <p v-else>No player selected</p>
      </template>
      <p v-else>Loading...</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PlayerCarousel",

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent();
      },
      deep: true,
    },
  },

  props: {
    editData: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    tab: 0,
    api: `${process.env.VUE_APP_BASEURL}`,
    styleOptions: [
      { text: "Light Mode", value: "light" },
      { text: "Dark Mode", value: "dark" },
      { text: "Club Mode", value: "club" },
    ],
    playerData: [],
    updatedData: {
      title: null,
      style: null,
      buttonUrl: null,
      buttonText: null,
      team: "",
      players: [],
      playerNames: [],
    },
    showError: false,
  }),

  async created() {
    await this.getTeams();
  },

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.editData));
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "PlayerCarousel";
      this.updatedData.widgetName = "Player Carousel";
      this.updatedData.widgetID = this.$uuid.v1();
      this.updatedData.widgetData = {
        style: null, // Background colour - light || dark || club
        title: null,
        buttonUrl: null,
        buttonText: null,
        team: "",
        players: [],
        playerNames: [],
      };
      this.$emit("initialised");
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData);
    },

    async getTeams() {
      this.$getCurrentUserJwToken().subscribe(async (jwt) => {
        const teamRes = await axios.get(`${this.api}/fixtures/opta/filters`, {
          headers: { Authorization: jwt },
        });
        console.log("getTeams response", teamRes);
        const teamBody = teamRes.data.body;

        const teamData = [];

        teamBody.forEach((element) => {
          teamData.push({
            id: element.teamID,
            teamName: element.teamName,
          });
        });
        this.getPlayers(teamData);
      });
    },

    async getPlayers(teamData) {
      const that = this;

      const playerData = [];

      this.playerData = await new Promise((res, rej) => {
        teamData.forEach(async (element, index) => {
          that.$getCurrentUserJwToken().subscribe(async (jwt) => {
            const playerRes = await axios.get(
              `${that.api}/squads/opta?teamID=${element.id}`,
              { headers: { Authorization: jwt } }
            );

            if (playerRes.data == null) {
              playerData.push(undefined);
            } else {
              playerData.push({
                id: element.id,
                teamName: element.teamName,
                players: playerRes.data.body,
              });
            }

            if (teamData.length == playerData.length) {
              const data = playerData
                .filter(function (element) {
                  return element !== undefined;
                })
                .sort((a, b) => {
                  if (
                    teamData.indexOf(teamData.filter((t) => t.id == a.id)[0]) >
                    teamData.indexOf(teamData.filter((t) => t.id == b.id)[0])
                  ) {
                    return 1;
                  }
                  if (
                    teamData.indexOf(teamData.filter((t) => t.id == a.id)[0]) <
                    teamData.indexOf(teamData.filter((t) => t.id == b.id)[0])
                  ) {
                    return -1;
                  }
                  return 0;
                });
              res(data);
            }
          });
        });
      });

      // teamData.forEach((element, index) => {
      //   that.$getCurrentUserJwToken().subscribe((jwt) => {
      //   axios
      //     .get(`${that.api}/squads/opta?teamID=${element.id}`, { 'headers': { 'Authorization': jwt }})
      //     .then(response => {
      //       console.log(`getPlayers response`, response);

      //         playerData[index] = {
      //           id: element.id,
      //           teamName: element.teamName,
      //           players: response.data.body
      //         }

      //         if (teamData.length == playerData.length) {
      //           this.playerData = playerData.filter(function(element) {
      //             return element !== undefined;
      //           });

      //           setTimeout(() => {
      //             this.renderReady = true
      //           }, 100);
      //         }
      //     })
      //     .catch(e => {
      //       console.log('Unable to load players:', e);
      //     })
      //   })
      // });
    },

    filterPublishedPlayer(unfilteredArray) {
      return unfilteredArray.filter((player) => player.published);
    },

    togglePlayer(id, name, team) {
      if (team != this.updatedData.widgetData.team) {
        this.updatedData.widgetData.team = team;
        this.updatedData.widgetData.players = [];
        this.updatedData.widgetData.playerNames = [];
      }
      this.showError = false;
      const index = this.updatedData.widgetData.players.indexOf(id);
      if (index > -1) {
        this.updatedData.widgetData.players.splice(index, 1);
        this.updatedData.widgetData.playerNames.splice(index, 1);
      } else {
        if (this.updatedData.widgetData.players.length >= 12) {
          this.showError = true;
          return;
        }
        this.updatedData.widgetData.players.push(id);
        this.updatedData.widgetData.playerNames.push(name);
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.main-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 20px;
}

.position {
  text-transform: uppercase;
  border-bottom: 3px solid #24002f;
  margin-bottom: 4px;
  display: inline-block;
}

.items {
  margin-bottom: 8px;
}

.player-item {
  background: #24002f;
  color: white;
  border-radius: 30px;
  margin-right: 10px;
  margin-bottom: 4px;
  padding: 2px 6px;
  display: inline-block;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: lighten(#24002f, 25%);
  }
  &--active {
    background: lighten(#67247c, 15%);
    &:hover {
      background: lighten(#67247c, 15%);
    }
  }
}

.selected-players {
  margin-bottom: 20px;
}

.error-message {
  margin-left: 8px;
  color: #d61244;
  opacity: 0;
  transition: 0.5s;
}

.visible {
  opacity: 1;
}
</style>
