export default {
  data: [
    // {
    //   sectionName: 'Homepage mini promo',
    //   sectionDesc: '',
    //   model: 'minipromo',
    //   fields: [
    //     {
    //       label: 'Label',
    //       model: 'minipromo_promolabel',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo text',
    //       model: 'minipromo_promotext',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo link',
    //       model: 'minipromo_promolink',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo image (100px x 100px)',
    //       model: 'minipromo_promoimage',
    //       type: 'Image'
    //     }
    //   ]
    // },
    {
      sectionName: "Video Banner",
      sectionDesc: "",
      model: "videopromo",
      fields: [
        {
          label: "Enable Video Promo",
          model: "videopromo_enabled",
          type: "Checkbox"
        }
      ]
    },
    {
      sectionName: "Product Carousel",
      sectionDesc: "This section can be used to advertise your kit promotions",
      model: "product_carousel",
      fields: [
        {
          label: "Enable Carousel",
          model: "product_carousel_enable",
          type: "Checkbox"
        },
        {
          label: "Heading",
          model: "product_carousel_heading",
          type: "Text"
        },
        {
          label: "Main CTA Link",
          model: "product_carousel_ctaLink",
          type: "Text"
        },

        {
          label: "Main CTA Link Text",
          model: "product_carousel_ctaText",
          type: "Text"
        },
        {
          label: "Label - Slot 1",
          model: "product_carousel_label_one",
          type: "Text"
        },
        {
          label: "Link text - Slot 1",
          model: "product_carousel_linkText_one",
          type: "Text"
        },
        {
          label: "Link - Slot 1",
          model: "product_carousel_link_one",
          type: "Text"
        },
        {
          label: "Product Image (400px x 400px with padding) - Slot 1",
          model: "product_carousel_image_one",
          type: "Image"
        },
        {
          label: "Label - Slot 2",
          model: "product_carousel_label_two",
          type: "Text"
        },
        {
          label: "Link text - Slot 2",
          model: "product_carousel_linkText_two",
          type: "Text"
        },
        {
          label: "Link - Slot 2",
          model: "product_carousel_link_two",
          type: "Text"
        },
        {
          label: "Product Image (400px x 400px with padding) - Slot 2",
          model: "product_carousel_image_two",
          type: "Image"
        },
        {
          label: "Label - Slot 3",
          model: "product_carousel_label_three",
          type: "Text"
        },
        {
          label: "Link text - Slot 3",
          model: "product_carousel_linkText_three",
          type: "Text"
        },
        {
          label: "Link - Slot 3",
          model: "product_carousel_link_three",
          type: "Text"
        },
        {
          label: "Product Image (400px x 400px with padding) - Slot 3",
          model: "product_carousel_image_three",
          type: "Image"
        },
        {
          label: "Label - Slot 4",
          model: "product_carousel_label_four",
          type: "Text"
        },
        {
          label: "Link text - Slot 4",
          model: "product_carousel_linkText_four",
          type: "Text"
        },
        {
          label: "Link - Slot 4",
          model: "product_carousel_link_four",
          type: "Text"
        },
        {
          label: "Product Image (400px x 400px with padding) - Slot 4",
          model: "product_carousel_image_four",
          type: "Image"
        }
      ]
    }
  ]
}
