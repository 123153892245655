<template>
  <section>
    <div class="filters">

      <div>
        <p class="filters__label">From:</p>
        <datetime
          class="filters__input"
          v-model="fromDate"
          type="date"
          format="yyyy-MM-dd"
          :max-datetime="todaysDate"></datetime>
      </div>

      <div>
        <p class="filters__label">To:</p>
        <datetime
          class="filters__input"
          v-model="toDate"
          type="date"
          format="yyyy-MM-dd"
          :max-datetime="todaysDate"></datetime>
      </div>

      <v-btn
        class="filters__button"
        color="accent"
        outline
        flat
        @click="fetchData()"
      >
        Update dates
      </v-btn>
    </div>

    <div class="filters">
  
      <div>
        <v-select
          class="filters__raw-toggle"
          v-model="dataView"
          item-text='name'
          item-value='value'
          :items="[
            { name: 'View table data', value: 'table' },
            { name: 'View raw data', value: 'raw' },
          ]"
          solo
          flat />
      </div>
    </div>

    <!-- <pre v-if="tableData">
      {{ tableData }}
    </pre> -->

    <inline-loader v-if="loading" />

    <template v-else-if="analyticsData">
      <table v-if="dataView === 'raw'" class="analytics-table">
        <thead>
          <th>Name</th>
          <th>Advert Ref</th>
          <th>Campaign Ref</th>
          <!-- <th>Period</th> -->
          <th>Action</th>
          <th>Count</th>
        </thead>

        <tbody>
          <tr v-for="(row, index) in analyticsData[0].Items" :key="index">
            <td>{{ row.advertname }}</td>
            <td>{{ row.advertref }}</td>
            <td>{{ row.campaignref }}</td>
            <!-- <td>{{ row.month }}/{{ row.year }}</td> -->
            <td>{{ row.action }}</td>
            <td>{{ row.count }}</td>
          </tr>
        </tbody>
      </table>

      <template v-else>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            v-model="selected"
            :headers="tableHeaders"
            :items="tableData"
            item-key="id"
            :search="search"
            select-all
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td>
                <v-checkbox
                  v-model="props.selected"
                  primary
                  hide-details
                ></v-checkbox>
              </td>
              <td>{{ props.item.advertname }}</td>
              <td>{{ props.item.advertref }}</td>
              <td>{{ props.item.campaignref }}</td>
              <!-- <td class="text-xs-right">{{ props.item.period }}</td> -->
              <td class="text-xs-right">{{ Number(props.item.advertImpression).toLocaleString() }}</td>
              <td class="text-xs-right">{{ Number(props.item.advertClick).toLocaleString() }}</td>
              <td class="text-xs-right">{{ props.item.ctr }}%</td>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning">
                Your search for "{{ search }}" found no results.
              </v-alert>
            </template>

            <template v-slot:footer>
              <tr >
                <td class="analytics__total"></td>
                <td class="text-xs-left analytics__total" colspan="3">Total ( {{ selected.length || '0' }} selected items)*</td>
                <td class="text-xs-right analytics__total">{{ Number(sumField('advertImpression')).toLocaleString() }}</td>
                <td class="text-xs-right analytics__total">{{ Number(sumField('advertClick')).toLocaleString() }}</td>
                <td class="text-xs-right analytics__total">{{ averageCTR() }}%</td>
              </tr>
            </template>
          </v-data-table>

          <div class="analytics__footer">
            <p class="analytics__note">*Total CTR uses Total Impressions and Total Views</p>

            <v-btn
              class="analytics__copy"
              color="accent"
              :small="true"
              outline
              flat
              @click="outputSelected()"
            >
              Copy selected
            </v-btn>
          </div>
        </v-card>
      </template>

      <!-- <p class="analytics__updated">Last updated: {{ analyticsData.timestamp }}</p> -->
    </template>
  </section>
</template>

<script>
import axios from "axios"
import moment from "moment"
import InlineLoader from "@/components/Library/InlineLoader"

export default {
  name: "AdvertisingModuleV1Analytics",

  components: {
    InlineLoader
  },

  data:() => ({
    loading: true,
    analyticsData: null,
    formattedAnalytics: null,
    // period: 'monthly',
    dataView: "table",
    tableData: null,
    search: "",
    selected: [],
    tablePeriod: "2021-12",
    fromDate: moment().utc().subtract(1, "day").format(),
    toDate: moment().utc().format(),
    todaysDate: moment().utc().format(),
    tableHeaders: [
      {
        text: "Advert Name",
        align: "left",
        value: "advertname"
      },
      { text: "Advert Ref", value: "advertref" },
      { text: "Campaign Ref", value: "campaignref" },
      // { text: 'Period', align: 'right', value: 'period' },
      { text: "Views", align: "right", value: "advertImpression" },
      { text: "Clicks", align: "right", value: "advertClick" },
      { text: "CTR%", align: "right", value: "ctr" }
    ],
  }),

  mounted() {
    this.fetchData()
  },

  methods: {
    outputSelected() {
      console.log(this.selected)

      navigator.permissions.query({name: "clipboard-write"}).then(result => {
        if (result.state == "granted" || result.state == "prompt") {
          /* write to the clipboard now */

          navigator.clipboard.writeText(JSON.stringify(this.selected, null, 2)).then(function() {
            /* clipboard successfully set */
            console.log("clipboard successfully set")
            alert("Copied to clipboard")
          }, function() {
            /* clipboard write failed */
            console.log("clipboard write failed")
            alert("Copying failed")
          });
        }
      });
    },

    fetchData() {
      this.loading = true
      const fromDate = `${moment(this.fromDate).format("YYYY-MM-DD")} 00:00:00.000`
      const toDate = `${moment(this.toDate).format("YYYY-MM-DD")} 23:59:59.999`

      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_LOGGING}/analytics/advertising/query/?fromDate=${fromDate}&toDate=${toDate}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("Analytics Response: ", response);
          this.analyticsData = response.data.response

          this.createTableAnalytics()

          this.$store.commit("completeLoading");
        }).catch((error) => {
          console.error("Lab error", error)
          this.$store.commit("completeLoading");
        })
      })
    },

    sumField(key) {
      // sum data in given key (property)
      let total = 0
      const sum = this.selected.reduce((accumulator, currentValue) => {
        return (total += +currentValue[key])
      }, 0)
      return sum
    },

    averageCTR() {
      if (this.selected.length) {
        return (((Number(this.sumField("advertClick")) / Number(this.sumField("advertImpression"))) + Number.EPSILON) * 100).toFixed(2)
      } else {
        return 0
      }
    },

    createTableRowName(row) {
      return `${row.advertname}, ${row.campaignref}, ${row.advertref}`
    },

    createTableAnalytics() {
      const tableData = {}
      const selectedPeriod = this.tablePeriod
      for (const row of this.analyticsData[0].Items) {
        // if (selectedPeriod === `${row.year}-${row.month}`) {
          if (tableData.hasOwnProperty(this.createTableRowName(row))) {
            // tableData[row.advertname][row.action] = row.count
            // tableData[row.advertname].ctr = Math.round(((Number(tableData[row.advertname].advertClick) / Number(tableData[row.advertname].advertImpression)) + Number.EPSILON) * 100) / 100


            if (tableData[this.createTableRowName(row)].hasOwnProperty(row.action)) {
              tableData[this.createTableRowName(row)][row.action] = Number(tableData[this.createTableRowName(row)][row.action]) + Number(row.count)
            } else {
              tableData[this.createTableRowName(row)][row.action] = row.count
            }

            tableData[this.createTableRowName(row)].ctr = (((Number(tableData[this.createTableRowName(row)].advertClick) / Number(tableData[this.createTableRowName(row)].advertImpression)) + Number.EPSILON) * 100).toFixed(2)
          } else {
            tableData[this.createTableRowName(row)] = {
              id: this.$uuid.v1(),
              advertname: row.advertname,
              advertClick: 0,
              advertImpression: 0,
              advertref: row.advertref,
              campaignref: row.campaignref,
              // period: `${row.year}-${row.month}`,
            }

            tableData[this.createTableRowName(row)][row.action] = row.count
          }
        // }
      }

      console.log("tableData", tableData)

      this.tableData = Object.values(tableData)

      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
  .filters {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 0 0 16px;

    &__label {
      margin: 0 0 4px;
      font-size: 12px;
      font-weight: 300;
    }

    &__raw-toggle {
      display: inline-block;
    }

    &__input {
      margin: 0 8px 0 0;
    }

    &__button {
      margin: 0 8px;
    }
  }

  .analytics {
    &__updated {
      margin: 16px 0;
      font-size: 12px;
      opacity: 0.5;
    }

    &__note {
      padding: 8px;
      margin: 16px 0;
      font-size: 12px;
      opacity: 0.5;
    }

    &__total {
      font-weight: 500;
      background: rgba(209, 216, 245, 0.3)
    }

    &__footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .analytics-table {
    width: 100%;
    border-collapse: collapse;

    th {
      padding: 8px 0;
      font-size: 12px;
      text-align: left;
      border-bottom: 1px solid rgba(209, 216, 245, 0.6);
    }

    td {
      padding: 8px 0;
      font-size: 12px;
      vertical-align: text-top;
      border-bottom: 1px solid rgba(209, 216, 245, 0.6);
    }

    &__last-cell {
      padding: 0;
      text-align: right !important;

      > span {
        display: inline-block;
        width: 25%;
        text-align: right;
      }
    }

    &__advert-name {
      padding: 0 0 4px;
      margin: 0;
      font-size: 12px;
    }

    &__advert-ref {
      padding: 0 0 4px;
      margin: 0;
      font-size: 10px;
      opacity: 0.6;
    }
  }

  .inner-table {
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 8px 0;
      vertical-align: text-top;
      text-align: right;
      border-bottom: 0;
      background: rgba(209, 216, 245, 0.3)
    }
  }

  .table-tag-cell {
    width: 25%;
  }

  .table-tag {
    padding: 3px 5px;
    font-size: 11px;
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
</style>
