<template>
  <v-flex xs12>
    <v-flex class="promo__editor-promos">
      <div>
        Name: {{promoData.name}}
      </div>
      <div>
        Title: {{promoData.title}}
      </div>
      <div>
        Content: {{promoData.content}}
      </div>
      <div>
        Link: {{promoData.link}}
      </div>
      <div>
        Call to action: {{promoData.cta}}
      </div>
      <div class="layout justify-end">
        <v-btn
          color="accent"
          outline
          @click="deletePromoItemDialog = true"
        >
          Delete
        </v-btn>
        <v-btn
          color="accent"
          depressed
          @click=" editPromoDialog='true' "
        >
          Edit
        </v-btn>
      </div>
    </v-flex>
    <template>
      <v-layout row justify-center>
        <v-dialog v-model="deletePromoItemDialog" persistent max-width="360">
          <v-card>
            <v-card-title class="headline">Delete Promotion</v-card-title>
            <v-card-text>Do you wish to delete <strong>{{promoData.name}}</strong> from the promotion catergory listing?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed @click="deletePromoItemDialog = false">Cancel</v-btn>
              <v-btn depressed color="error" @click="deletePromoItemDialog  = false, deletePromoItem(promoData.id)">DELETE</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>
    <template>
      <v-layout row justify-center>
        <v-dialog v-model="editPromoDialog" width="580" lazy>
          <v-form v-if="editPromoForm" enctype="multipart/form-data" ref="editPromoForm" v-model="editPromoFormValid" lazy-validation>
            <v-card>
              <v-card-title
                class="widget__heading"
              >
                Edit promo
              </v-card-title>

              <v-card-text>
                <label class="label" for="name">Name</label>
                <v-text-field
                  id="Name"
                  v-model="promoData.name"
                  placeholder="Name"
                  solo
                  flat
                  required
                  :rules="[rules.required]"
                  validate-on-blur
                  label="Name"
                ></v-text-field>

                <label class="label" for="title">Title</label>
                <v-text-field
                  id="widget-field"
                  v-model="promoData.title"
                  placeholder="Title"
                  solo
                  flat
                  validate-on-blur
                  label="Title"
                ></v-text-field>

                <label class="label" for="content">Content</label>
                <v-text-field
                  id="content"
                  v-model="promoData.content"
                  placeholder="Content"
                  solo
                  flat
                  validate-on-blur
                  label="Content"
                ></v-text-field>

                <label class="label" for="link">Link</label>
                <v-text-field
                  id="link"
                  v-model="promoData.link"
                  placeholder="Link"
                  solo
                  flat
                  required
                  :rules="[rules.required]"
                  validate-on-blur
                  label="Link"
                ></v-text-field>

                <label class="label" for="link">Link or button text</label>
                <v-text-field
                  id="link"
                  v-model="promoData.cta"
                  placeholder="Link"
                  solo
                  flat
                  required
                  :rules="[rules.required]"
                  validate-on-blur
                  label="Call to action"
                ></v-text-field>

                <div class="uploader">
                  <h4 class="uploader__heading">Add Image</h4>
                  <img v-if="previewImage" class="single-image__preview single-image__preview--center" :src="previewImage" />
                  <div class="uploader__ctas">
                    <v-btn
                      color="accent"
                      type="button"
                      depressed>
                      <label for="file-upload" class="uploader__upload-button"><v-icon small>add</v-icon> Choose image</label>
                    </v-btn>
                    <input class="uploader__upload-input" id="file-upload" :rules="[rules.required]" ref="fileUpload" type="file" @change="convertImage($event)" accept="image/*"/>
                  </div>
                </div>
                <div v-if="errorMessage" class="error--text">
                  {{errorMessage}}
                </div>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="accent"
                    outline
                    @click="editPromoDialog=false, previewImage = null"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="accent"
                    depressed
                    @click="validateEditPromo()"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-form>

          <v-form v-if="imageUploadForm" enctype="multipart/form-data" ref="imageUploadForm" v-model="imageUploadFormValid" lazy-validation>
            <v-card>
              <v-card-title
                class="widget__heading"
              >
                Image
              </v-card-title>

              <v-card-text>

                <section class="single-image" v-if="previewImage">

                  <img class="single-image__preview" :src="previewImage" />

                  <section class="edit">
                    <label class="label" for="title">Title</label>
                    <v-text-field
                      id="title"
                      v-model="imageData.name"
                      solo
                      flat
                      required
                      :rules="[rules.required]"
                      validate-on-blur
                    ></v-text-field>

                    <label class="label" for="caption">Caption</label>
                    <v-text-field
                      id="caption"
                      v-model="imageData.description"
                      solo
                      flat
                      :rules="[rules.required]"
                      validate-on-blur
                    ></v-text-field>

                    <label class="label" for="tags">Tags</label>

                    <div class="tag-manager">
                      <v-text-field
                        class="tag-manager__input"
                        id="tags"
                        v-model="tagString"
                        solo
                        flat
                        :hide-details="true"
                        @keyup.enter.native="tagged(tagString)"
                        placeholder="Hit enter to add tags"
                      ></v-text-field>
                      <template v-if="imageData.tags" v-for="(tag, index) in imageData.tags">
                        <v-chip class="tag-manager__tag" color="accent" text-color="white" :v-model="tag.id">{{tag.name}}<span class="tag-manager__close" @click="removeTag(index)">X</span></v-chip>
                      </template>
                    </div>
                    </section>
                </section>

              </v-card-text>

              <v-card-actions v-if="previewImage">
                <v-spacer></v-spacer>
                <v-btn
                  color="accent"
                  outline
                  flat
                  @click="validateImage();"
                >
                  Add image
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>

      </v-dialog>
    </v-layout>
  </template>


  </v-flex>
</template>

<script>
import axios from "axios"
export default {
  name: "PromoEditorRow",

  props: {
    promoData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    rules: {
      required: value => !!value || "Required."
    },
    editPromoDialog: false,
    editPromoForm: true,
    editPromoFormValid: false,
    previewImage: false,
    updatedPromoData: {
      id: null,
      name: null,
      title: null,
      content: null,
      link: null,
      cta: null,
      mediaLibraryID: null
    },
    deletePromoItemDialog: false,
    imageUploadForm: false,
    imageUploadFormValid: false,
    tagString: "",
    imageData: {
      image: "",
      name: "",
      description: "",
      tags: [],
      width: null
    },
    errorMessage: ""
  }),

  mounted() {
    console.log("Promo Editor Row mounted");
    if(this.promoData) {
      this.updatedPromoData = this.promoData;
      if(this.promoData.mediaLibraryID) {
        this.getImage(this.promoData.mediaLibraryID);
      }
    }
  },

  methods: {
    deletePromoItem(id) {
      this.previewImage = null;
      this.$emit("deletePromoItem", id);
    },
    savePromoItem() {
      this.$emit("savePromoItem", this.updatedPromoData);
    },
    convertImage($event) {
      const file = $event.target.files[0]
      const reader = new FileReader();

      reader.addEventListener("load", evt => {
        this.previewImage = reader.result;

        console.log("reader", reader);

        const base64DataOnly = reader.result.split(",")[1].toString();

        this.imageData = {
          image: base64DataOnly,
          name: "",
          description: "",
          tags: []
        }
      });
      this.imageUploadForm = true;
      this.editPromoForm = false;
      if (file) {
        reader.readAsDataURL(file);
      }
    },

    uploadImage() {
      console.log("Upload image: ", this.imageData);
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/media/upload`,
          headers: { "Authorization": jwt },
          data: this.imageData
        })
        .then(response => {
          console.log("Saved image: ", response);
          this.imageUploadForm = false;
          this.editPromoForm = true;
          this.updatedPromoData.mediaLibraryID = response.data.body.mediaLibraryID;
          this.$emit("imageUploaded", this.updatedPromoData.mediaLibraryID);
        })
        .catch(e => {
          console.error("Problem saving image: ", e);
        });
      })
    },
    tagged(item){
      console.log("Adding tag:", item);
      const tag = {
        id: item.replace(/\s/g,""),
        name: item
      }
      this.imageData.tags.push(tag);
      this.tagString = "";
      console.log(this.imageData.tags);
    },
    removeTag(index) {
      this.imageData.tags.splice(index, 1);
    },
    validateImage() {
      this.errorMessage = ""
      if (this.$refs.imageUploadForm.validate()) {
        console.log("Validation passed");
        this.uploadImage();
      }
    },
    validateEditPromo() {
      this.errorMessage = ""
      if (this.$refs.editPromoForm.validate() && this.updatedPromoData.mediaLibraryID) {
        console.log("Validation passed");
        this.editPromoDialog = false;
        this.savePromoItem();
      } else {
        this.errorMessage = "Certain entries are invalid and an image is required"
      }
    },
    getImage(id){
      console.log("Loading...", id);
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/media/${id}`,
          headers:  { "Authorization": jwt }
        })
        .then(response => {
          console.log(response);
          this.previewImage = response.data.body.location;
        })
        .catch(e => {
          console.error("Error editing image:", e);
        });
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .promo {
    &__editor {
      &-promos {
        padding: 20px;
        margin-bottom: 20px;
        border: 1px solid $lightPurple;
        background-color: rgba($lightergrey, 0.3);
        border-radius: 3px;
      }
    }
  }

  .single-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 30px;
    border-top: 1px solid #ebebeb;

    &__preview {
      margin: 10px 40px 0 30px;
      max-height: 80px;
      max-width: 80px;
      &--center {
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: 15px;
      }
    }
  }

  .preview-list {
    border-bottom: 1px solid #DFE3E9;
  }

  .preview {
    display: block;
    margin: 0;
    padding: 15px 0 10px;
    border-top: 1px solid #DFE3E9;

    /deep/ .v-list__tile {
      padding: 0;
    }

    &__label {
      padding: 0;
      margin: 0 0 10px;
      font-size: 10px;
    }

    &__image {
      max-height: 56px;
      max-width: 56px;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    &__action {
      display: block;
      margin: 0 0 0 30px;
      cursor: pointer;
    }
  }
    .uploader {
      margin: 0 0 40px 0;

      &__heading {
        margin: 0 0 20px;
        color: #24002F;
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-weight: 500;
      }

      &__upload-input {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        white-space: nowrap;
        width: 1px;
      }

      &__upload-button {
        cursor: pointer;
      }

      &__ctas {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
</style>
