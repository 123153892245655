<template>
  <section class="page-management main-body" :class="{'is-dragging': isDragging}">

    <div class="beta-notice">
      <p><strong>Update:</strong> We are developing a new page management system to make it easier to move pages around. This is still in development so has some missing features, but you can toggle between the two at the moment.</p>
      <v-btn
        color="accent"
        outline
        flat
        @click="togglePageManagement()"
      >
        Swap to New Page Management System
      </v-btn>
    </div>


    <div class="pages">
      <MiniHeader :heading="'Pages'" :buttonText="'Add Page'" :hasButton="true" @buttonClicked="addPage()"/>

      <draggable
        v-model="pagesList"
        :options="{
          group: 'menu',
          ghostClass: 'ghost',
          dragClass: 'sortable-drag',
          emptyInsertThreshold: 10,
          filter: '.child-menu, .draggable-item__actions, .draggable-item__delete'
        }"
        @start="startDragging"
        @end="endDragging">

          <transition-group
            name="list-complete"
            tag="ol">
            <li
            v-for="(level1Item) in pagesList"
            :key="level1Item.postID" class="draggable-item">

            <DraggablePageRow
              :name="level1Item.postTitle"
              :template="level1Item.pageTemplate"
              :status="level1Item.postStatus"
              :slug="level1Item.postType === 'internalLink' ? level1Item.linkSlug : level1Item.fullPath"
              :allowChildCreation="true"
              :postType="level1Item.postType"
              :childrenCount="childrenCount(level1Item.children)"
              @editItem="editItem(level1Item.postID)"
              @duplicateItem="duplicateItem(level1Item.postID)"
              @deleteItem="deleteItem(level1Item.postID)"
              @closeChild="closeChildMenu(level1Item.postID)"
              @expandChild="expandChildMenu(level1Item.postID)"/>

              <draggable
                v-show="checkMenuVisibility(level1Item.postID)"
                class="draggable-target"
                v-model="level1Item.children"
                :options="{
                  group: 'menu',
                  ghostClass: 'ghost',
                  dragClass: 'sortable-drag',
                  emptyInsertThreshold: 10,
                  filter: '.draggable-item__actions, .draggable-item__delete'
                }"
                @start="startDragging"
                @end="endDragging">

                <transition-group
                  name="list-complete"
                  tag="ol"
                  class="child-menu"
                  :class="{'is-empty': childrenCount(level1Item.children) === 0}">
                  <li
                    v-for="(level2Item) in level1Item.children"
                    :key="level2Item.postID" class="draggable-item">

                      <DraggablePageRow
                        :name="level2Item.postTitle"
                        :template="level2Item.pageTemplate"
                        :status="level2Item.postStatus"
                        :slug="level2Item.fullPath"
                        :allowChildCreation="true"
                        :childrenCount="childrenCount(level2Item.children)"
                        @editItem="editItem(level2Item.postID)"
                        @duplicateItem="duplicateItem(level2Item.postID)"
                        @deleteItem="deleteItem(level2Item.postID)"
                        @closeChild="closeChildMenu(level2Item.postID)"
                        @expandChild="expandChildMenu(level2Item.postID)"/>

                      <draggable
                        v-show="checkMenuVisibility(level2Item.postID)"
                        v-model="level2Item.children"
                        class="draggable-target"
                        :options="{
                          group: 'menu',
                          ghostClass: 'ghost',
                          dragClass: 'sortable-drag',
                          emptyInsertThreshold: 10,
                          filter: '.draggable-item__actions, .draggable-item__delete'
                        }"
                        @start="startDragging"
                        @end="endDragging">

                        <transition-group
                          name="list-complete"
                          tag="ol"
                          class="child-menu"
                          :class="{'is-empty': childrenCount(level2Item.children) === 0}">
                          <li
                            v-for="(level3Item) in level2Item.children"
                            :key="level3Item.postID" class="draggable-item">

                            <DraggablePageRow
                              :name="level3Item.postTitle"
                              :template="level3Item.pageTemplate"
                              :status="level3Item.postStatus"
                              :slug="level3Item.fullPath"
                              :allowChildCreation="false"
                              @editItem="editItem(level3Item.postID)"
                              @duplicateItem="duplicateItem(level3Item.postID)"
                              @deleteItem="deleteItem(level3Item.postID)"/>

                          </li>
                        </transition-group>
                      </draggable>

                  </li>
                </transition-group>
              </draggable>

            </li>
          </transition-group>

      </draggable>

    </div>

    <StickyFooter :errorMessage="errorMessage" :status="status" :buttonText="'Save Pages'" @buttonClicked="savePages()" />

  </section>
</template>

<script>
import axios from "axios"
import draggable from "vuedraggable"
import MiniHeader from "@/components/Library/MiniHeader"
import StickyFooter from "@/components/Library/StickyFooter"
import DraggablePageRow from "@/components/Pages/DraggablePageRow"

export default {
  name: "PageManagement",

  components: {
    draggable,
    MiniHeader,
    StickyFooter,
    DraggablePageRow
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/pages`,
    status: "saved",
    errorMessage: "",
    childMenuDragging: false,
    isDragging: false,
    hiddenMenus: [],
    visibleMenus: [],
    pagesList: []
  }),

  mounted() {
    console.log("Page Management mounted");
    this.fetchPages();
  },

  methods: {
    togglePageManagement() {
      this.$router.push("/pages")
    },

    fetchPages(doSave = false) {
      this.$store.commit("startLoading");
      this.errorMessage = "";

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(this.api, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("Fetch Pages: ", response);
          this.pagesList = response.data.body;

          if (doSave) {
            this.savePages()
          } else {
            this.status = "saved";
            this.$store.commit("completeLoading");
          }
        })
        .catch(e => {
          console.error("Problem retrieving pages", e);
          this.errorMessage = "Problem retrieving pages";
          this.$store.commit("completeLoading");
        });
      })
    },

    savePages() {
      this.status = "saving";
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/updateorder`,
          headers: { "Authorization": jwt },
          data: this.pagesList
        })
        .then(response => {
          console.log(response);
          this.status = "saved";

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem saving menu", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem saving pages";

          this.$store.commit("completeLoading");
        });
      })
    },

    addPage() {
      this.status = "saving";
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/add`,
          headers:  { "Authorization": jwt }
        })
        .then(response => {
          console.log("Add page response:", response);

          this.fetchPages(true);
        })
        .catch(e => {
          console.error("Problem adding page", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem adding page";

          this.$store.commit("completeLoading");
        });
      })
    },

    duplicateItem(postID) {
      this.status = "saving";
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/duplicate/${postID}`,
          headers:  { "Authorization": jwt }
        })
        .then(response => {
          console.log("Duplicate item response:", response);
          this.fetchPages();
        })
        .catch(e => {
          console.error("Problem duplicating item", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem duplicating item";

          this.$store.commit("completeLoading");
        });
      })
    },

    editItem(postID) {
      this.$router.push({ path: `/pages/${postID}` })
    },

    deleteItem(postID) {
      if (this.status === "needsSaving") {
        alert("You are unable to delete until the page structure is saved")
      } else {
        this.status = "saving";
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios.delete(`${process.env.VUE_APP_BASEURL}/pages/${postID}`, { "headers": { "Authorization": jwt }})
          .then(() => {
            console.log("Deleting page: ", postID);
            this.fetchPages();
            this.status = "saved";

          }).catch(e => {
            console.error("Problem saving menu: ", e);
            this.status = "needsSaving";
            this.errorMessage = "Failed to delete page";

            this.$store.commit("completeLoading");
          });
        })
      }
    },

    outOfSync() {
      this.status = "needsSaving";
    },

    startDragging() {
      console.log("Start dragging");
      this.isDragging = true;

      this.outOfSync();
    },

    endDragging() {
      console.log("End dragging:", this.pagesList);
      this.isDragging = false;
    },

    expandChildMenu(itemID) {
      // this.hiddenMenus.splice(this.hiddenMenus.indexOf(itemID), 1);

      this.visibleMenus.push(itemID);
    },

    closeChildMenu(itemID) {
      // this.hiddenMenus.push(itemID);

      this.visibleMenus.splice(this.visibleMenus.indexOf(itemID), 1);
    },

    checkMenuVisibility(itemID) {
      // if (!this.hiddenMenus.includes(itemID)) {
      //   return true
      // } else {
      //   return false
      // }
      
      if (this.visibleMenus.includes(itemID)) {
        return true
      } else {
        return false
      }
    },

    childrenCount(childList) {
      if (childList) {
        return childList.length
      }
    }
  }
}
</script>

<style lang="scss">
  // Placeholder drag item
  .ghost {
    .draggable-item__row {
      background-color: rgba(103, 36, 124, 0.2);
    }

    .child-menu {
      background-color: limegreen;
      display: none !important;
    }
  }
</style>

<style lang="scss" scoped>
  .list-complete-enter, .list-complete-leave-to {
    opacity: 0;
  }

  .list-complete-leave-active {
    position: absolute;
    width: calc(100% - (var(--body-gutter) * 2));
  }

  .draggable-item {
    transition: all 0.5s, opacity 0.2s;
  }

  .child-menu {
    margin: 0 0 0 80px;
    padding: 0;
    transition: padding 0.2s;
  }

  .is-dragging .draggable-target {
    display: block !important;
  }

  .is-dragging .child-menu.is-empty {
    position: relative;
    min-height: 40px;
    border: 1px dashed #ccc;
    margin: -15px 10px 10px 80px;
    border-top: 0;
    border-radius: 0 0 6px 6px;
    text-align: center;

    &:before {
      content: 'Drop here to nest';
      position: absolute;
      top: 8px;
      right: 0;
      left: 0;
      opacity: 0.5;
      font-size: 12px;
    }
  }

  // Item being dragged
  .sortable-chosen {
    /deep/ .draggable-item__row {
      margin: 0;
      border: 2px solid #67247C;
      box-shadow: 0 4px 4px 0 rgba(0,0,0,0.1);
    }

    .child-menu {
      display: none !important;
    }
  }

  .status {
    margin: 0 10px 0 0;
    font-size: 13px;
    opacity: 0.5;
  }
</style>
