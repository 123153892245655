export default {
  data: [
    {
      sectionName: "Hero Video",
      sectionDesc: "Control the video hero on your homepage",
      model: "homepageVideoHero",
      fields: [
        {
          label: "Heading",
          model: "homepageVideoHero__heading",
          type: "Text"
        }
      ]
    },
    {
      sectionName: "Video Player One",
      sectionDesc: "Set the heading and video for the first video player on your homepage",
      model: "homepageVideoPlayerOne",
      fields: [
        {
          label: "Heading",
          model: "homepageVideoPlayerOne__heading",
          type: "Text"
        },
        {
          label: "Video Link",
          model: "homepageVideoPlayerOne__link",
          type: "Text"
        }
      ]
    },
    {
      sectionName: "Video Player Two",
      sectionDesc: "Set the heading and video for the second video player on your homepage",
      model: "homepageVideoPlayerTwo",
      fields: [
        {
          label: "Heading",
          model: "homepageVideoPlayerTwo__heading",
          type: "Text"
        },
        {
          label: "Video Link",
          model: "homepageVideoPlayerTwo__link",
          type: "Text"
        }
      ]
    },

    {
      sectionName: "Homepage Form",
      sectionDesc: "Set the heading for the form embed at the bottom of your homepage",
      model: "homepageForm",
      fields: [
        {
          label: "Heading",
          model: "homepageForm__heading",
          type: "Text"
        }
      ]
    }
  ]
}
