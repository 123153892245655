export default {
  data: [
    {
      sectionName: "Homepage Promo 1",
      sectionDesc:
        "This section defines the content of the first promo card on the homepage",
      model: "promo1",
      fields: [
        {
          label: "Title",
          model: "promo_title",
          type: "Text",
        },
        {
          label: "Description (optional)",
          model: "promo_description",
          type: "Text",
        },
        {
          label: "Button Text (optional)",
          model: "promo_button",
          type: "Text",
        },
        {
          label: "Link",
          model: "promo_link",
          type: "Text",
        },
        {
          label: "Promo image (16 x 9 ratio)",
          model: "promo_image",
          type: "Image",
        },
      ],
    },
    {
      sectionName: "Homepage Promo 2",
      sectionDesc:
        "This section defines the content of the second promo card on the homepage",
      model: "promo2",
      fields: [
        {
          label: "Title",
          model: "promo_title",
          type: "Text",
        },
        {
          label: "Description (optional)",
          model: "promo_description",
          type: "Text",
        },
        {
          label: "Button Text (optional)",
          model: "promo_button",
          type: "Text",
        },
        {
          label: "Link",
          model: "promo_link",
          type: "Text",
        },
        {
          label: "Promo image (16 x 9 ratio)",
          model: "promo_image",
          type: "Image",
        },
      ],
    },
    {
      sectionName: "App Promo 1",
      sectionDesc:
        "This section defines the first promo that will be shown on the app. Please use the full url path.",
      model: "appPromo1",
      fields: [
        {
          label: "Title",
          model: "promo_title",
          type: "Text",
        },
        {
          label: "Description",
          model: "promo_description",
          type: "Text",
        },
        {
          label: "Button Text",
          model: "promo_button",
          type: "Text",
        },
        {
          label: "Link",
          model: "promo_link",
          type: "Text",
        },
        {
          label: "Promo Image (16 x 9 ratio)",
          model: "promo_image",
          type: "Image",
        },
      ],
    },
    {
      sectionName: "App Promo 2",
      sectionDesc:
        "This section defines the second promo that will be shown on the app. Please use the full url path.",
      model: "appPromo2",
      fields: [
        {
          label: "Title",
          model: "promo_title",
          type: "Text",
        },
        {
          label: "Description",
          model: "promo_description",
          type: "Text",
        },
        {
          label: "Button Text",
          model: "promo_button",
          type: "Text",
        },
        {
          label: "Link",
          model: "promo_link",
          type: "Text",
        },
        {
          label: "Promo Image (16 x 9 ratio)",
          model: "promo_image",
          type: "Image",
        },
      ],
    },
  ],
};
