<template>
  <div class="chart">
    <h3 class="subheading">Last 10 days</h3>

    <inline-loader v-if="!load" />

    <vue-bar-graph
      v-else
      :points="get10DayArray('pageviews', 'date')"
      :show-y-axis="true"
      :show-x-axis="true"
      :width="getElementWidth()"
      :height="300"
      :show-values="true"
      :barColor="'#67247c'"
    />
  </div>
</template>

<script>
import moment from "moment"
import VueBarGraph from "vue-bar-graph";
import InlineLoader from "@/components/Library/InlineLoader"

export default {
  name: "TimelineBar",

  components: {
    VueBarGraph,
    InlineLoader
  },

  props: {
    timeline: {
      type: Array,
      required: true
    }
  },

  data:() => ({
    load: false
  }),

  mounted() {
    console.log("getDateArray", this.get10DayArray("pageviews", "date"))

    setTimeout(() => {
      this.load = true
      
    }, 2000);
  },

  methods: {
    getElementWidth() {
      const box = document.querySelector(".chart");
      return box.offsetWidth;
    },

    getArray(value) {
      return this.timeline.map(item => {
        return item[value]
      })
    },

    get10DayArray(value, date) {
      const full10dayArray = this.timeline.map((item, index) => {
        if (index === 0 || index === this.timeline.length - 1) {
          return {label: moment(item[date]).format("Do MMM"), value: item[value]}
        } else {
          return {label: "", value: item[value]}
        }
      })

      if (this.timeline.length < 30) {
        console.log("Array is: ", this.timeline.length)

        for (let i = 0; i < (10 - this.timeline.length); i++) {
          full10dayArray.push(
            {label: "", value: ""}
          )
        }
      }

      return full10dayArray
    }
  }
}
</script>

<style scoped lang="scss">
  .chart {
    margin: 30px 0;

    /deep/ svg {
      width: 100% !important;
    }

    /deep/ rect + text {
      fill: white !important;
    }
  }
</style>
