var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"fixtures-list-widget",attrs:{"width":"580","lazy":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._t("default",null,{"slot":"activator"}),_c('v-card',[_c('v-card-title',{staticClass:"widget__heading"},[_vm._v(" Fixtures ")]),_c('v-card-text',[(!_vm.addFixtureForm)?_c('v-card-text',{staticClass:"fixtures-list-widget__add",on:{"click":function($event){_vm.clearFixtureForm(), (_vm.addFixtureForm = true)}}},[_c('v-icon',{staticClass:"fixtures-list-widget__icon",attrs:{"color":"#fff"}},[_vm._v("add")]),_c('span',{staticClass:"fixtures-list-widget__text"},[_vm._v("Add fixture")])],1):_vm._e(),(_vm.addFixtureForm)?_c('v-form',{ref:"fixtureWidgetForm",attrs:{"enctype":"multipart/form-data","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.client == 'efl')?_c('match-picker-efl',{on:{"updated":function($event){return _vm.setMatchEfl($event)}}}):_c('match-picker',{on:{"updated":function($event){return _vm.setMatch($event)}}}),_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){_vm.addFixtureForm = false}}},[_vm._v(" Cancel ")]),(!_vm.singleFixtureListData.guid)?_c('v-btn',{attrs:{"color":"accent","depressed":"","disabled":!_vm.singleFixtureListData.matchID || !_vm.allowAdd},on:{"click":function($event){return _vm.validate()}}},[_vm._v(" Add fixture ")]):_vm._e(),(_vm.singleFixtureListData.guid)?_c('v-btn',{attrs:{"color":"accent","depressed":"","disabled":!_vm.matches.length},on:{"click":function($event){return _vm.validate(_vm.singleFixtureListData.guid)}}},[_vm._v(" Update fixture ")]):_vm._e()],1):_vm._e()],1),(_vm.multiFixtureListData.length)?_c('v-card-text',[_c('draggable',{attrs:{"options":{
          ghostClass: 'ghost',
          dragClass: 'sortable-drag',
        }},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.multiFixtureListData),callback:function ($$v) {_vm.multiFixtureListData=$$v},expression:"multiFixtureListData"}},[_c('transition-group',{staticClass:"fixtures-list-widget__fixtures",attrs:{"name":"list-complete"}},_vm._l((_vm.multiFixtureListData),function(fixture,key){return _c('div',{key:fixture.guid,staticClass:"fixtures-list-widget__fixtures-item draggable-item"},[_c('div',{staticClass:"fixtures-list-widget__fixtures-item-left"},[(fixture.matchDetails[0].matchHomeCrest)?_c('div',{staticClass:"fixtures-list-widget__fixtures-item-crest",style:(`background-image:url('${
                  fixture.matchDetails[0].matchHomeCrest.startsWith('http')
                    ? fixture.matchDetails[0].matchHomeCrest
                    : `${_vm.imagePath}/${fixture.matchDetails[0].matchHomeCrest}`
                }')`)}):_vm._e(),_c('ul',{staticClass:"fixtures-list-widget__fixtures-item-details"},[_c('li',[_vm._v(" "+_vm._s(fixture.matchDetails[0].matchLabel)+" ")]),_c('li',[_vm._v(" "+_vm._s(fixture.matchDetails[0].seasonName)+" ")])])]),_c('div',{staticClass:"fixtures-list-widget__fixtures-item-action"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteFixtureItem(key)}}},[_c('v-icon',[_vm._v("delete")])],1)])])}),0)],1)],1):_vm._e(),(_vm.multiFixtureListData.length)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){_vm.postFixtures(), (_vm.dialog = false)}}},[_vm._v(" Post ")])],1):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }