<script>
export default [{
    "optaId": 52686,
    "name": "Chang Hyde",
    "position": "Defensive Midfielder",
    "squadNumber": 19
  },
  {
    "optaId": 52632,
    "name": "Forbes Copeland",
    "position": "Central Midfielder",
    "squadNumber": 12
  },
  {
    "optaId": 45067,
    "name": "Copeland Landry",
    "position": "Goalkeeper",
    "squadNumber": 32
  },
  {
    "optaId": 48525,
    "name": "Shepherd Matthews",
    "position": "Forward",
    "squadNumber": 4
  },
  {
    "optaId": 37448,
    "name": "Norton Fox",
    "position": "Forward",
    "squadNumber": 34
  },
  {
    "optaId": 57669,
    "name": "Bright Lott",
    "position": "Goalkeeper",
    "squadNumber": 39
  },
  {
    "optaId": 29751,
    "name": "Jackson Dickson",
    "position": "Attacking Midfielder",
    "squadNumber": 25
  },
  {
    "optaId": 51112,
    "name": "Sears Gonzales",
    "position": "Forward",
    "squadNumber": 16
  },
  {
    "optaId": 20994,
    "name": "Pollard Bryant",
    "position": "Forward",
    "squadNumber": 10
  },
  {
    "optaId": 60091,
    "name": "Santiago Phelps",
    "position": "Central Midfielder",
    "squadNumber": 24
  },
  {
    "optaId": 61858,
    "name": "Roach Guerrero",
    "position": "Centeral Defender",
    "squadNumber": 32
  },
  {
    "optaId": 33347,
    "name": "Noel Fleming",
    "position": "Attacking Midfielder",
    "squadNumber": 4
  },
  {
    "optaId": 61543,
    "name": "Lowe Vaughn",
    "position": "Wingback",
    "squadNumber": 13
  },
  {
    "optaId": 65915,
    "name": "Clarke Jacobson",
    "position": "Goalkeeper",
    "squadNumber": 21
  },
  {
    "optaId": 16787,
    "name": "Steele Brooks",
    "position": "Centeral Defender",
    "squadNumber": 38
  },
  {
    "optaId": 52174,
    "name": "Byers Gibson",
    "position": "Goalkeeper",
    "squadNumber": 21
  },
  {
    "optaId": 48241,
    "name": "Cline Levy",
    "position": "Goalkeeper",
    "squadNumber": 12
  },
  {
    "optaId": 53603,
    "name": "Gutierrez Burke",
    "position": "Wingback",
    "squadNumber": 2
  },
  {
    "optaId": 69672,
    "name": "Gibbs Hebert",
    "position": "Forward",
    "squadNumber": 12
  },
  {
    "optaId": 13555,
    "name": "Parker Russo",
    "position": "Central Midfielder",
    "squadNumber": 8
  },
  {
    "optaId": 14928,
    "name": "Gamble Todd",
    "position": "Central Midfielder",
    "squadNumber": 16
  },
  {
    "optaId": 27351,
    "name": "Mercer Best",
    "position": "Centeral Defender",
    "squadNumber": 31
  },
  {
    "optaId": 20976,
    "name": "Jimenez Everett",
    "position": "Attacking Midfielder",
    "squadNumber": 12
  },
  {
    "optaId": 28483,
    "name": "Mathews Lindsey",
    "position": "Wingback",
    "squadNumber": 37
  },
  {
    "optaId": 2211,
    "name": "Campos Perry",
    "position": "Goalkeeper",
    "squadNumber": 4
  },
  {
    "optaId": 34922,
    "name": "Medina Oliver",
    "position": "Goalkeeper",
    "squadNumber": 37
  },
  {
    "optaId": 47133,
    "name": "Clemons Page",
    "position": "Centeral Defender",
    "squadNumber": 27
  },
  {
    "optaId": 22502,
    "name": "Ashley Chaney",
    "position": "Forward",
    "squadNumber": 31
  },
  {
    "optaId": 44738,
    "name": "Dale Garrison",
    "position": "Wingback",
    "squadNumber": 31
  },
  {
    "optaId": 3597,
    "name": "Avila Glenn",
    "position": "Wingback",
    "squadNumber": 10
  },
  {
    "optaId": 61940,
    "name": "Simpson Fernandez",
    "position": "Goalkeeper",
    "squadNumber": 18
  },
  {
    "optaId": 57314,
    "name": "Mullins Colon",
    "position": "Defensive Midfielder",
    "squadNumber": 39
  }
]
</script>
