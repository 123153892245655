<template>
    <v-flex
        v-if="data.length > 0"
        class="listings"
    >
        <div class="listings__header">
            <ul
                v-if="headers.length > 0"
                class="listings__header-items cols"
                :class="columns"
            >
                <li
                    class="listings__header-item"
                    v-for="(header, index) in headers"
                    :key="index"
                >
                    {{ header }}
                </li>
                <li class="listings__header-item">
                    Actions
                </li>
            </ul>
        </div>
        <div
            class="listings__data"
            v-for="(list, index) in data"
            :key="index"
        >
            <ul
                class="listings__data-items cols"
                :class="columns"
            >
                <li
                    class="listings__data-item"
                    v-for="(item, key) in list"
                    :key="key"
                >
                    {{ item }}
                </li>
                <li class="listings__data-item">
                    <span class="listings__header-item">Actions:</span>
                    <button
                        class="news-item__action"
                        type="button"
                        @click="deleteItem(list[dataKey])"
                    >
                        <v-icon>delete</v-icon>
                    </button>
                    <button
                        class="news-item__action"
                        type="button"
                        @click="editItem(list[dataKey])"
                    >
                        <v-icon>edit</v-icon>
                    </button>
                </li>
            </ul>
        </div>
    </v-flex>
</template>

<script>
export default {
    name: "Listings",

    props: {
        data: {
            type: Array,
            required: true
        },
        columns: {
            type: String,
            required: true
        },
        headers: {
            type: Array,
            required: false
        },
        dataKey: {
            type: String,
            required: true
        }
    },

    components: {},

    data: () => ({
    }),

    methods: {
        editItem(itemID) {
            // this.$store.commit('startLoading');
            this.$router.push({ path: `/player/${itemID}` })
        },

        deleteItem(itemID) {
            console.log(itemID)
        }
    }

}
</script>

<style lang="scss">
%grid-100 {
    @media (min-width: 768px) {
        display: grid;
    }

    padding: 0;
    width: 100%;
}

.cols {
    @extend %grid-100;

    &-5 {
        grid-template-columns: 10% 30% 25% 25% 10%;
    }
}

.listings {
    width: 100%;

    &__title {
        padding-left: 20px;
    }

    &__data,
    &__header {
        width: 100%;
        padding: 25px 20px;
    }

    &__header {
        display: none;

        @media (min-width: 768px) {
            display: block;
        }

        &-item {
            font-weight: 500;
        }
    }

    &__data {
        border: 1px solid $lightgrey;
        margin: 2.5%;
        width: 95%;
        display: inline-block;

        @media (min-width: 768px) {
            border-left: 0;
            border-right: 0;
            border-bottom: 0;
        }

        @media (min-width: 640px) {
            width: 45%;
        }

        @media (min-width: 768px) {
            width: 100%;
            margin: 0;
        }

        .listings__header-item {
            display: inline-block;
            margin-right: 10px;

            @media (min-width: 768px) {
                display: none;
            }
        }

        &-item {
            font-size: 1rem;
            font-weight: lighter;

            @media (max-width: 767px) {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            &s {
                padding-left: 0;
            }
        }
    }
}
</style>
