<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <p class="widget-info">This widget allows you to display the Live TV Schedule.</p>

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()">Use this widget</v-btn>
    </template>

    <div class="widget-fieldset" v-else>
      <label class="label">Style</label>
      <v-select
        id="widgetData.style"
        :items="styleOptions"
        solo
        flat
        v-model="updatedData.widgetData.style">
      </v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "MatchesCarousel",

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent()
      },
      deep: true
    }
  },

  props: {
    editData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    styleOptions: [{text: "Light Mode", value: "light"}, {text: "Dark Mode", value: "dark"}, {text: "Club Mode", value: "club"}],
    teamData: [],
    updatedData: {
      style: "light",
    },
  }),

  created() {
    this.getTeams()
  },

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.editData))
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "TvSchedule"
      this.updatedData.widgetName = "TV Schedule"
      this.updatedData.widgetID = this.$uuid.v1()
      this.updatedData.widgetData = {
        style: "light", // Background colour - light || dark || club - currently light only for this component
      }
      this.$emit("initialised")
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData)
    },
  }
}
</script>

<style scoped lang='scss'>

</style>
