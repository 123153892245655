<template>
  <v-dialog class="league-table-widget" v-model="dialog" width="580" lazy>
    <slot slot="activator"></slot>

    <v-card>
      <v-card-title class="widget__heading"> League Table </v-card-title>

      <v-card-text>
        <v-form
          enctype="multipart/form-data"
          v-model="valid"
          ref="leagueTableForm"
          lazy-validation
        >
          <label class="label" for="squad">Select Squad</label>
          <v-select
            :items="squads"
            item-text="name"
            item-value="id"
            id="squad"
            v-model="leagueData.teamID"
            outline
            validate-on-blur
            label="Select Squad"
            @change="
              seasons = [];
              leagueData.seasonID = null;
              competitions = [];
              matches = [];
              getSeasons($event);
            "
          ></v-select>

          <label class="label" for="season">Select Season</label>
          <v-select
            :items="seasons"
            item-text="seasonName"
            item-value="seasonID"
            id="season"
            :disabled="!leagueData.teamID"
            v-model="leagueData.seasonID"
            outline
            validate-on-blur
            label="Select Season"
            @change="
              competitions = [];
              leagueData.competitionID = null;
              getCompetitions($event);
            "
          ></v-select>

          <label class="label" for="season">Select Competition</label>
          <v-select
            :items="competitions"
            item-text="competitionName"
            item-value="competitionID"
            id="season"
            :disabled="!leagueData.seasonID"
            v-model="leagueData.competitionID"
            outline
            validate-on-blur
            label="Select Competition"
            @change="selectCompetition($event)"
          ></v-select>

          <label class="label" for="league-date">League Date</label>
          <v-dialog
            ref="dateDialogLeague"
            v-model="dateDialogLeague"
            :return-value.sync="leagueData.filterDate"
            persistent
            lazy
            full-width
            width="290px"
          >
            <v-text-field
              id="league-date"
              validate-on-blur
              slot="activator"
              :rules="[rules.required]"
              v-model="leagueData.filterDate"
              prepend-inner-icon="event"
              solo
              flat
              readonly
            ></v-text-field>
            <v-date-picker v-model="leagueData.filterDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dateDialogLeague = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.dateDialogLeague.save(leagueData.filterDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>

          <v-btn color="accent" outline flat @click="dialog = false">
            Cancel
          </v-btn>

          <v-btn color="accent" depressed @click="validate()">
            Add league table
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import axios from "axios";
export default {
  name: "LeagueTableWidget",
  props: {
    widgetData: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    apiLeague: `${process.env.VUE_APP_BASEURL}/league-tables`,
    apiOptaFilters: `${process.env.VUE_APP_BASEURL}/fixtures/opta/filters`,
    dialog: false,
    dateDialogLeague: false,
    valid: false,
    fixturesConfig: null,
    squads: [],
    seasons: [],
    competitions: [],
    rules: {
      required: (value) => !!value || "Required.",
    },
    leagueData: {
      teamID: null,
      teamName: null,
      seasonID: null,
      seasonName: null,
      competitionID: null,
      competitionFormat: null,
      filterDate: null,
    },
  }),
  mounted() {
    console.log("League table widget mounted");
    if (this.widgetData) {
      this.leagueData = JSON.parse(JSON.stringify(this.widgetData));
    }
    this.$store.commit("startLoading");
    this.$getCurrentUserJwToken().subscribe((jwt) => {
      axios
        .get(this.apiOptaFilters, { headers: { Authorization: jwt } })
        .then((response) => {
          console.log("Opta filter response: ", response);
          this.fixturesConfig = response.data.body;
          this.squads = this.fixturesConfig.map((item) => {
            return { name: item.teamName, id: item.teamID };
          });
          this.$store.commit("completeLoading");
          if (this.leagueData.teamID) {
            this.getSeasons(this.leagueData.teamID);
          }
          if (this.leagueData.seasonID) {
            this.getCompetitions(this.leagueData.seasonID);
          }
        })
        .catch((e) => {
          console.error("Problem retrieving squads", e);
          this.$store.commit("completeLoading");
        });
    });
  },
  methods: {
    getSeasons(e) {
      this.leagueData.teamID = e;
      const season = this.fixturesConfig.filter(
        (item) => item.teamID === this.leagueData.teamID
      );

      if (season[0].seasons) {
        this.seasons = season[0].seasons;
        console.log(this.seasons);
      } else {
        this.seasons = "No season data has been found";
        console.log("No season data has been found");
      }
    },
    getCompetitions(e) {
      this.leagueData.seasonID = e;
      const competition = this.seasons.filter(
        (item) => item.seasonID === this.leagueData.seasonID
      );
      this.leagueData.seasonName = competition[0].seasonName;
      console.log("Season name -", this.leagueData.seasonName);
      if (competition[0].competitions) {
        this.competitions = competition[0].competitions;
      } else {
        this.competitions = "No competition data has been found";
        console.log("No competition data has been found");
      }
    },
    selectCompetition(e) {
      const competition = this.competitions.filter(
        (item) => item.competitionID === e
      );
      this.leagueData.competitionName = competition[0].competitionName;
      this.leagueData.competitionFormat = competition[0].competitionFormat;
      console.log("Competition format -", this.leagueData.competitionFormat);
    },
    validate() {
      this.errorMessage = "";
      if (this.$refs.leagueTableForm.validate()) {
        console.log("Validation passed");
        console.log(this.leagueData);
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required";
      }
    },
    updateData() {
      console.log("Update data:", this.leagueData);
      this.$emit("updatedData", this.leagueData);
      this.dialog = false;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>
