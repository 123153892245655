export default {
    data: [
        {
            sectionName: "Secondary News Slot",
            sectionDesc: 'These fields control which news appear in the secondary news slot on the homepage. If empty, it will use the latest news instead. Use the news slug field, which is everything after "/news/" in your url".',
            model: "secondaryNews",
            fields: [
                {
                    label: "News Slug - Slot 1",
                    model: "slot1",
                    type: "Text"
                },
                {
                    label: "News Slug - Slot 2",
                    model: "slot2",
                    type: "Text"
                },
                {
                    label: "News Slug - Slot 3",
                    model: "slot3",
                    type: "Text"
                },
            ]
        },
        // {
        //   sectionName: 'Homepage mini promo',
        //   sectionDesc: '',
        //   model: 'minipromo',
        //   fields: [
        //     {
        //       label: 'Label',
        //       model: 'minipromo_promolabel',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Promo text',
        //       model: 'minipromo_promotext',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Promo link',
        //       model: 'minipromo_promolink',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Promo image (100px x 100px)',
        //       model: 'minipromo_promoimage',
        //       type: 'Image'
        //     }
        //   ]
        // },
        // {
        //   sectionName: 'Video Banner',
        //   sectionDesc: '',
        //   model: 'videopromo',
        //   fields: [
        //     {
        //       label: 'Enable Video Promo',
        //       model: 'videopromo_enabled',
        //       type: 'Checkbox'
        //     }
        //   ]
        // },
        {
            sectionName: "Photo Gallery",
            sectionDesc: "This section defines the content of the photo gallery on the homepage, which displays up to 5 elements. The Carousel renders if all general data is present and at least one slot has all data",
            model: "gallery",
            fields: [

                {
                    label: "Title",
                    model: "gallery_title",
                    type: "Text"
                },
                {
                    label: "Description 1",
                    model: "gallery_description1",
                    type: "LongText"
                },
                {
                    label: "Description 2",
                    model: "gallery_description2",
                    type: "LongText"
                },
                {
                    label: "Button Text",
                    model: "gallery_button_text",
                    type: "Text"
                },
                {
                    label: "Button Link",
                    model: "gallery_link",
                    type: "Text"
                },
                {
                    label: "Logo",
                    model: "gallery_logo",
                    type: "Image"
                },
                {
                    label: "Title - Slot 1",
                    model: "gallery_element1_title",
                    type: "Text"
                },

                {
                    label: "Link - Slot 1",
                    model: "gallery_element1_link",
                    type: "Text"
                },
                {
                    label: "Image - Slot 1 (square)",
                    model: "gallery_element1_image",
                    type: "Image"
                },
                {
                    label: "Title - Slot 2",
                    model: "gallery_element2_title",
                    type: "Text"
                },
                {
                    label: "Link - Slot 2",
                    model: "gallery_element2_link",
                    type: "Text"
                },
                {
                    label: "Image - Slot 2 (square)",
                    model: "gallery_element2_image",
                    type: "Image"
                },
                {
                    label: "Title - Slot 3",
                    model: "gallery_element3_title",
                    type: "Text"
                },
                {
                    label: "Link - Slot 3",
                    model: "gallery_element3_link",
                    type: "Text"
                },
                {
                    label: "Image - Slot 3 (square)",
                    model: "gallery_element3_image",
                    type: "Image"
                },
                {
                    label: "Title - Slot 4",
                    model: "gallery_element4_title",
                    type: "Text"
                },
                {
                    label: "Link - Slot 4",
                    model: "gallery_element4_link",
                    type: "Text"
                },
                {
                    label: "Image - Slot 4 (square)",
                    model: "gallery_element4_image",
                    type: "Image"
                },
                {
                    label: "Title - Slot 5",
                    model: "gallery_element5_title",
                    type: "Text"
                },
                {
                    label: "Link - Slot 5",
                    model: "gallery_element5_link",
                    type: "Text"
                },
                {
                    label: "Image - Slot 5 (square)",
                    model: "gallery_element5_image",
                    type: "Image"
                },

            ]
        },
        {
            sectionName: "Homepage Promo 1",
            sectionDesc: "This section defines the content of the first promo card on the homepage",
            model: "promo1",
            fields: [
                {
                    label: "Title",
                    model: "promo_title",
                    type: "Text"
                },
                {
                    label: "Description (optional)",
                    model: "promo_description",
                    type: "Text"
                },
                {
                    label: "Button Text (optional)",
                    model: "promo_button",
                    type: "Text"
                },
                {
                    label: "Link",
                    model: "promo_link",
                    type: "Text"
                },
                {
                    label: "Promo image (16 x 9 ratio)",
                    model: "promo_image",
                    type: "Image"
                }
            ]
        },
        {
            sectionName: "Homepage Promo 2",
            sectionDesc: "This section defines the content of the second promo card on the homepage",
            model: "promo2",
            fields: [
                {
                    label: "Title",
                    model: "promo_title",
                    type: "Text"
                },
                {
                    label: "Description (optional)",
                    model: "promo_description",
                    type: "Text"
                },
                {
                    label: "Button Text (optional)",
                    model: "promo_button",
                    type: "Text"
                },
                {
                    label: "Link",
                    model: "promo_link",
                    type: "Text"
                },
                {
                    label: "Promo image (16 x 9 ratio)",
                    model: "promo_image",
                    type: "Image"
                }
            ]
        },
        {
            sectionName: "Homepage Promo 3",
            sectionDesc: "This section defines the content of the third promo card on the homepage",
            model: "promo3",
            fields: [
                {
                    label: "Title",
                    model: "promo_title",
                    type: "Text"
                },
                {
                    label: "Description (optional)",
                    model: "promo_description",
                    type: "Text"
                },
                {
                    label: "Button Text (optional)",
                    model: "promo_button",
                    type: "Text"
                },
                {
                    label: "Link",
                    model: "promo_link",
                    type: "Text"
                },
                {
                    label: "Promo image (16 x 9 ratio)",
                    model: "promo_image",
                    type: "Image"
                }
            ]
        },
        // {
        //   sectionName: 'Product Carousel',
        //   sectionDesc: 'This section can be used to advertise your kit promotions',
        //   model: 'product_carousel',
        //   fields: [
        //     {
        //       label: 'Enable Carousel',
        //       model: 'product_carousel_enable',
        //       type: 'Checkbox'
        //     },
        //     {
        //       label: 'Heading',
        //       model: 'product_carousel_heading',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Main CTA Link',
        //       model: 'product_carousel_ctaLink',
        //       type: 'Text'
        //     },

        //     {
        //       label: 'Main CTA Link Text',
        //       model: 'product_carousel_ctaText',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Label - Slot 1',
        //       model: 'product_carousel_label_one',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Link text - Slot 1',
        //       model: 'product_carousel_linkText_one',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Link - Slot 1',
        //       model: 'product_carousel_link_one',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Product Image (400px x 400px with padding) - Slot 1',
        //       model: 'product_carousel_image_one',
        //       type: 'Image'
        //     },
        //     {
        //       label: 'Label - Slot 2',
        //       model: 'product_carousel_label_two',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Link text - Slot 2',
        //       model: 'product_carousel_linkText_two',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Link - Slot 2',
        //       model: 'product_carousel_link_two',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Product Image (400px x 400px with padding) - Slot 2',
        //       model: 'product_carousel_image_two',
        //       type: 'Image'
        //     },
        //     {
        //       label: 'Label - Slot 3',
        //       model: 'product_carousel_label_three',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Link text - Slot 3',
        //       model: 'product_carousel_linkText_three',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Link - Slot 3',
        //       model: 'product_carousel_link_three',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Product Image (400px x 400px with padding) - Slot 3',
        //       model: 'product_carousel_image_three',
        //       type: 'Image'
        //     },
        //     {
        //       label: 'Label - Slot 4',
        //       model: 'product_carousel_label_four',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Link text - Slot 4',
        //       model: 'product_carousel_linkText_four',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Link - Slot 4',
        //       model: 'product_carousel_link_four',
        //       type: 'Text'
        //     },
        //     {
        //       label: 'Product Image (400px x 400px with padding) - Slot 4',
        //       model: 'product_carousel_image_four',
        //       type: 'Image'
        //     }
        //   ]
        // }
    ]
}
