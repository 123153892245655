<template>

  <div v-if="responseDetails">
    <!-- {{responseDetails}} -->
    <p>I'm the details</p>
  </div>

</template>

<script>
export default {
  name: FormResponseDetail,

  // props: {
  //   responseID: {
  //     type: String,
  //     required: true
  //   }
  // },

  data: () => ({
    responseDetails: null
  }),

  // mounted() {
  //   this.gotoForm()
  // },

  // methods: {
  //   gotoForm() {
  //     this.$store.commit('startLoading');
  //     this.$getCurrentUserJwToken().subscribe((jwt) => {
  //       axios
  //       .get(`${process.env.VUE_APP_FORMAPI}/completedforms/getsingle?postedDataID=${this.responseID}`, { 'headers': { 'Authorization': jwt }}) 
  //       .then(response => {
  //         console.log('getting single', response, 'requestURL',  `${process.env.VUE_APP_FORMAPI}/completedforms/getsingle?postedDataID=${this.responseID}`, 'Authorization', jwt )
  //         this.$store.commit('completeLoading')
  //         this.responseDetails = response
  //       })
  //        .catch(e => {
  //         console.error('Problem retrieving response', e);
  //         this.$store.commit('completeLoading');
  //       })
  //     })
  //   }
  // }
}
</script>

<style scoped lang='scss'>

</style>
