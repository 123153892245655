<template>
  <v-dialog
    class="form-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>
      <v-form enctype="multipart/form-data" ref="singleFieldForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            class="widget__heading"
          >
            Form Select
          </v-card-title>

          <v-card-text>
            <p class="widget__description">Select a form</p>

            <label class="label" for="form-select">Forms</label>
            <v-select
              id="form-select"
              solo
              flat
              required
              :rules="[rules.required]"
              v-model="updatedData.content.form"
              :items="forms"
              item-text="name"
              item-value="id"
              return-object
              validate-on-blur
              @change="selectedForm()"
            ></v-select>

            <template v-if="selectedFormData">
              <label class="label" for="form-select">Send to</label>
              <v-select
                id="form-select"
                solo
                flat
                required
                :rules="[rules.required]"
                v-model="updatedData.content.sendToID"
                :items="selectedFormData[0].formMeta.sendToOptions"
                item-text="name"
                item-value="id"
                validate-on-blur
              ></v-select>
            </template>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="accent"
              outline
              flat
              @click="validate()"
            >
              Post
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "FormWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/formlayouts?pageSize=100&pageNumber=1`,
    dialog: false,
    valid: false,
    rules: {
      required: value => !!value || "Required."
    },
    forms: [],
    allFormData: null,
    selectedFormData: null,
    updatedData: {
      content: {
        sendToID: null,
        form: {
          name: null,
          id: null
        }
      },
    }
  }),

  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
    }

    this.fetchFormList()
  },

  methods: {
    fetchFormList() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(this.api, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("Forms response", response);
          const formData = response.data.body;

          this.allFormData = response.data.body;

          this.forms = formData.map(item => {
            return {
              name: item.name_admin,
              id: item.formID
            }
          });

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving forms", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    selectedForm() {
      console.log("Selected form");

      this.selectedFormData = this.allFormData.filter(item => {
        return item.formID === this.updatedData.content.form.id
      })
    },

    updateData() {
      console.log("Update data:", this.updatedData);

      if (!this.updatedData.content.sendTo) {
        this.updatedData.content.sendTo = null
      }

      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
    },

    validate() {
      this.errorMessage = ""
      if (this.$refs.singleFieldForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .widget {
    &__description {
      margin: 0 0 20px;
      color: #7B6B80;
      font-size: 14px;
    }
  }
</style>
