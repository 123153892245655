<template>
  <v-dialog
    class="text-block-widget"
    v-model="dialog"
    lazy
    persistent
    scrollable
    fullscreen
  >

    <slot slot="activator"></slot>

      <v-card v-if="dialog">
        <v-card-title
          class="widget__heading"
        >
          Text block
        </v-card-title>

        <v-card-text>
          <label class="label" for="text-block-content">Content</label>

          <div v-if="includeDouble" class="text-box__double">
            <label class="label">Double Text Block</label>
              <v-checkbox
              v-model="updatedData.toggleDouble"
              >
              </v-checkbox>
          </div>

          <div v-if="includeDouble" class="text-block__image">
            <ImageUploader :title="'Section background image'" :widthOptionEnabled="false" :mediaLibraryID="updatedData.mediaLibraryID" :imageKey="updatedData.imageKey" @update="updateImage($event)"/>
          </div>

          <div class="editor-container">
            <div class="editor-container__left" :class="updatedData.toggleDouble ? 'double' : 'single'">
              <label v-if="updatedData.toggleDouble && includeDouble" class="label">Column Left</label>
              <tinymce-editor v-if="tinymceSettings" v-model="updatedData.content"
                @onBeforeSetContent="tableWrapper"
                api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
                :init="tinymceSettings"></tinymce-editor>
            </div>

            <div class="editor-container__right">
            <label v-if="updatedData.toggleDouble && includeDouble" class="label text-box__right">Column Right</label>
            <tinymce-editor v-if="updatedData.toggleDouble && tinymceSettings && includeDouble" v-model="updatedData.contentDouble"
              @onBeforeSetContent="tableWrapper"
              api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
              :init="tinymceSettings"></tinymce-editor>
            </div>
          </div>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outline
            flat
            @click="cancel();"
          >
            Cancel
          </v-btn>

          <v-btn
            color="accent"
            outline
            flat
            @click="updateData(); dialog = false;"
          >
            Post
          </v-btn>
        </v-card-actions>
      </v-card>

  </v-dialog>
</template>

<script>
import Vue from "vue"
import Editor from "@tinymce/tinymce-vue";
import ImageUploader from "@/components/Library/ImageUploader"

export default {
  name: "TextBlockWidget",

  components: {
    "tinymce-editor": Editor,
    ImageUploader
  },

  props: {
    widgetData: {
      type: Object,
      required: false
    },
    includeDouble: {
      type: Boolean,
      required: false
    },
    customFont: {
      type: Boolean,
      required: false
    },
    availableFonts: {
      type: String,
      required: false
    }
  },

  data: () => ({
    brandColor: process.env.VUE_APP_BRAND_COLOR,
    dialog: false,
    tinymceSettings: null,
    editorUpdating: false,
    updatedData: {
      content: null,
      contentDouble: null,
      toggleDouble: null,
      mediaLibraryID: null,
      imageKey: null,
    }
  }),

  created() {
    console.log("CREATED", this.customFont);
    const settings = {
              height: "calc(90vh - 200px)",
              menubar: false,
              relative_urls: false,
              plugins: "table link paste wordcount hr lists textpattern code",
              // toolbar:
              //   'undo redo | styleselect | fontselect | bold italic forecolor | \
              //   link blockquote table | \
              //   alignleft aligncenter alignright | \
              //   hr | \
              //   bullist numlist | removeformat | \ code',
              resize: false,
              custom_colors: false,
              color_map: [
                "212121", "Black",
                "aaaaaa", "Gray",
                "FFFFFF", "White",
                this.brandColor, "Brand"
              ],
              //font_formats: 'Canela=Canela-Medium; GT-Pressura-Regular=GT-Pressura-Regular',
              //font_formats: '',
              style_formats: [
                {title: "Headers", items: [
                  //{title: 'Header 1', format: 'h1'},
                  {title: "Header 2", format: "h2"},
                  {title: "Header 3", format: "h3"},
                  {title: "Header 4", format: "h4"},
                  {title: "Header 5", format: "h5"},
                  //{title: 'Header 6', format: 'h6'}
                ]},
                {title: "Inline", items: [
                  {title: "Bold", icon: "bold", format: "bold"},
                  {title: "Italic", icon: "italic", format: "italic"},
                  {title: "Underline", icon: "underline", format: "underline"},
                  //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                  //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                  //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                  //{title: 'Code', icon: 'code', format: 'code'}
                ]},
                {title: "Blocks", items: [
                  {title: "Paragraph", format: "p"},
                  {title: "Blockquote", format: "blockquote"},
                  //{title: 'Div', format: 'div'},
                  //{title: 'Pre', format: 'pre'}
                ]},
                {title: "Alignment", items: [
                  {title: "Left", icon: "alignleft", format: "alignleft"},
                  {title: "Center", icon: "aligncenter", format: "aligncenter"},
                  {title: "Right", icon: "alignright", format: "alignright"},
                  //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
                ]},
                {title: "Small", selector: "p", classes: "small"}
              ],
              style_formats_merge: false,
              textpattern_patterns: [
                {start: "//button", replacement: "<a class='button '>Button here</a>"}
              ],
              link_class_list: [
                {title: "None", value: ""},
                {title: "Primary on Dark", value: "button button--primary-dark"},
                {title: "Primary on Light", value: "button button--primary-light"},
                {title: "Ghost on Dark", value: "button button--ghost-dark"},
                {title: "Ghost on Light", value: "button button--ghost-light"},
                {title: "Label on Dark", value: "button button--label-dark"},
                {title: "Label on Light", value: "button button--label-light"},
              ],
              block_formats: "Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote"
            }
            if (this.customFont) {
              // settings.font_formats = `Canela=Canela; GT-Pressura-Regular=GT-Pressura-Regular`,
              settings.toolbar = "undo redo | styleselect | fontselect | bold italic forecolor | \
                link blockquote table | \
                alignleft aligncenter alignright | \
                hr | \
                bullist numlist | removeformat | \ code"
            } else { 
              settings.toolbar = "undo redo | styleselect | bold italic forecolor | \
                link blockquote table | \
                alignleft aligncenter alignright | \
                hr | \
                bullist numlist | removeformat | \ code"
            }
            if (this.availableFonts) {
              settings.font_formats = this.availableFonts
            }
            this.tinymceSettings = JSON.parse(JSON.stringify(settings))
  },
  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
    }
  },

  methods: {
    updateData() {
      if (this.updatedData.content === "") {
        this.updatedData.content = null,
        this.updatedData.contentDouble = null,
        this.updatedData.toggleDouble = null,
        this.updatedData.mediaLibraryID = null,
        this.updatedData.imageKey = null
      }
      if (this.updatedData.toggleDouble == false) {
        this.updatedData.contentDouble = null
      }
      console.log("Update data:", this.updatedData);
      this.$emit("updatedData", this.updatedData);

    },

    updateImage(payload) {
      console.log("updateImage", payload)
      this.updatedData.mediaLibraryID = payload.mediaLibraryID
      this.updatedData.imageKey = payload.imageKey
    },

    cancel() {
      console.log("Cancel");
      console.log("this.widgetData", this.widgetData);

      this.dialog = false;

      if (this.widgetData) {
        this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
      } else if (this.widgetData === undefined) {
        this.updatedData = {}
      }
    },
    tableWrapper(event, editor) {
      // console.log('beforeSetContent:' + event.content)
      if (event.content.startsWith("<table")) {
        event.content = "<div class=\"table-wrapper\">" + event.content + "</div><p></p>"
      }
    },
    updateToggle() {
      this.toggleDouble = !this.toggleDouble
      console.log("updateToggle:", this.toggleDouble)
    }
  }
}
</script>

<style>

</style>

<style lang="scss" scoped>
  .editor-container {
    display: flex;

    // &__left {
    //   width: 100%;
    // }

    &__right {
      margin-left: 20px;
    }
  }

  .is-updating {
    opacity: 0.4;
  }

  .text-box {
    &__double {
      display: flex;
      align-items: center;
      width: 160px;
      flex-direction: row-reverse;
    }
  }

  .double {
    width: 50%;
  }

  .single {
    width: 100%;
  }
</style>
