<template>
  <section class="form-builder">
    <v-btn
      v-if="!adding"
      type="button"
      class="add-field-button"
      @click="addingStuff()"
      >Add New Field</v-btn
    >
    <field-constructor
      v-if="adding"
      :existingField="editExistingField"
      @updatingForm="addField($event)"
      @editingForm="editField($event)"
      @cancelling="cancelField()"
      :replyEmailId="replyEmailId"
    />
  </section>
</template>

<script>
import FieldConstructor from "@/components/Forms/FieldConstructor";
export default {
  name: "FormBuilder",

  components: {
    FieldConstructor,
  },

  props: {
    form: {
      type: Array,
      required: false,
    },
    existingField: {
      type: Object,
      required: false,
    },
    replyEmailId: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    adding: false,
    editableForm: [],
    editExistingField: null,
  }),

  watch: {
    existingField: function (val) {
      if (val) {
        this.editExistingField = this.existingField;
        console.log("existingFieldExists in form builder", this.existingField);
        this.adding = true;
      }
    },
  },

  mounted() {
    if (this.form) {
      this.editableForm = this.form;
    } else {
      this.editableForm = [];
    }
  },

  methods: {
    addField(newField) {
      this.$emit("cancelEdit");
      this.editExistingField = null;
      console.log("addField Method triggered: FormBuilder", newField);
      this.editableForm.push(newField);
      this.adding = false;

      console.log(
        "FormBuilder: Add Field Method | Emitting formUpdating",
        this.editableForm
      );
      this.$emit("formUpdating", this.editableForm);
    },
    editField(field) {
      console.log("editting???", field);
      var filteredAry = this.editableForm.filter(function (e) {
        return e !== field.model;
      });
      // this.editableForm.splice(index, 1, field)
      this.adding = false;
      this.$emit("formUpdating", this.editableForm);
      this.editExistingField = null;
    },
    addingStuff() {
      console.log("Adding, but at what cost.");
      this.adding = true;
      this.editExistingField = null;
    },
    cancelField() {
      this.adding = false;
      this.editExistingField = null;
      this.$emit("cancelEdit");
    },
    // stopEdit() {
    //   this.adding = false;
    //   console.log("cancelled edit");
    //   this.editExistingField = null;
    // },
  },
};
</script>

<style scoped lang='scss'>
.form-builder {
  margin-top: 4px;
  padding: 16px 8px 0 0;
}

.add-field-button {
  background-color: #67247c !important;
  color: #fff;
  margin: 0;
}
</style>
