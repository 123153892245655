<template>
  <v-dialog class="map-widget" v-model="dialog" width="580" lazy>
    <slot slot="activator"></slot>

    <v-card>
      <v-stepper v-model="stepperCount">
        <v-stepper-header class="stepper-header">
          <v-stepper-step color="accent" :complete="stepperCount > 1" step="1"
            >Map</v-stepper-step
          >

          <v-divider></v-divider>

          <v-stepper-step color="accent" :complete="stepperCount > 2" step="2"
            >Style</v-stepper-step
          >

          <v-divider></v-divider>

          <v-stepper-step color="accent" :complete="stepperCount > 3" step="3"
            >Summary</v-stepper-step
          >
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <div class="stepper-wrapper">
              <label class="label">Map</label>
              <v-card class="mb-5" flat color="white lighten-1" height="200px">
                <p>Select the Map you wish to use.</p>
                <v-layout>
                  <v-flex>
                    <v-select
                      v-if="selectedMapsArrayTitles < 1"
                      :items="unselectedMaps"
                      item-text="attributes.title"
                      @change="($evt) => (selectedMap = $evt)"
                    >
                    </v-select>
                    <v-btn
                      v-if="
                        mapsArray &&
                        selectedMapsArrayTitles < 1 &&
                        selectedMap &&
                        mapsArray.filter(
                          (m) => m.attributes.title == selectedMap
                        ).length
                      "
                      class="accent"
                      type="button"
                      @click="
                        updateSelectedMapsArray(
                          mapsArray.filter(
                            (m) => m.attributes.title == selectedMap
                          )[0]
                        )
                      "
                      >Add</v-btn
                    >
                  </v-flex>
                </v-layout>

                <!-- <div class="selected-map-container">
                  <div v-for="map in selectedMapsArrayTitles" :key="map.title">
                    <div class="selected-map-wrapper">
                      <p class="selected-map">{{ map.title }}</p>
                      <a
                        class="selected-map--delete"
                        @click="deleteSelectedMapTitles(map.id)"
                        >Remove</a
                      >
                    </div>
                  </div>
                </div> -->
                <div class="selected-map-container">
                  <div v-for="map in selectedMapsArrayTitles" :key="map.id">
                    <div class="selected-map-wrapper">
                      <p v-if="map.title" class="selected-map">
                        {{ map.title }}
                      </p>
                      <p v-else class="selected-map">{{ map }}</p>
                      <a
                        class="selected-map--delete"
                        @click="deleteSelectedMapTitles(map.id)"
                        >Remove</a
                      >
                    </div>
                  </div>

                  <div v-for="map in selectedMapArray" :key="map.index">
                    <p class="selected-map">{{ map }}</p>
                  </div>
                </div>
              </v-card>
              <div class="stepper-help">
                <p>
                  Can't find what you're after? All Maps are created in the
                  <a @click="pushToMapDirectory" class="stepper-help-link"
                    >Map Directory</a
                  >
                </p>
              </div>
              <div class="buttons-wrapper">
                <v-btn
                  class="button-cancel"
                  color="lightgrey"
                  @click="closeMapDirectoryWidget"
                >
                  Cancel
                </v-btn>

                <div class="buttons-continue">
                  <v-btn color="accent" @click="stepperCount = 2"> Next </v-btn>
                </div>
              </div>
            </div>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div class="stepper-wrapper">
              <label class="label">Style</label>

              <v-card class="mb-5" flat color="white lighten-1" height="200px">
                <p>
                  Select the colour style that will be used for the information
                  cards on your map.
                </p>

                <div class="selected-style-container">
                  <div class="selected-item-wrapper">
                    <p>Light</p>
                    <img
                      class="selected-item-icon"
                      src="../../assets/map-directory/styleOptions/style--light.png"
                      alt=""
                    />
                    <v-checkbox v-model="mapStyleLight"> </v-checkbox>
                  </div>

                  <div class="selected-item-wrapper">
                    <p>Dark</p>
                    <img
                      class="selected-item-icon"
                      src="../../assets/map-directory/styleOptions/style--dark.png"
                      alt=""
                    />
                    <v-checkbox v-model="mapStyleDark"></v-checkbox>
                  </div>

                  <div class="selected-item-wrapper">
                    <p>Club</p>
                    <img
                      class="selected-item-icon"
                      src="../../assets/map-directory/styleOptions/style--club.png"
                      alt=""
                    />
                    <v-checkbox v-model="mapStyleClub"></v-checkbox>
                  </div>
                </div>
              </v-card>

              <div class="stepper-help">
                <p>
                  Can't find what you're after? All Maps are created in the
                  <a @click="pushToMapDirectory" class="stepper-help-link"
                    >Map Directory</a
                  >
                </p>
              </div>

              <div class="buttons-wrapper">
                <v-btn
                  class="button-cancel"
                  color="lightgrey"
                  @click="stepperCount = 1"
                >
                  Back
                </v-btn>

                <div class="buttons-continue">
                  <v-btn color="accent" @click="stepperCount = 3"> Next </v-btn>
                </div>
              </div>
            </div>
          </v-stepper-content>

          <v-stepper-content step="3">
            <div class="stepper-wrapper">
              <label class="label">Your Map</label>

              <v-card class="mb-5" flat color="white lighten-1" height="200px">
                <div class="summary-container">
                  <div class="summary-item-wrapper">
                    <p class="label-light">Map</p>
                    <p v-if="!selectedMap" class="summary-heading">
                      Please selected a map on the first slide.
                    </p>
                    <p class="summary-heading">{{ selectedMap }}</p>
                  </div>

                  <div class="summary-item-wrapper">
                    <p class="label-light">Style</p>
                    <p v-if="!styleSelected" class="summary-heading">
                      Please select a style in the previous slide.
                    </p>
                    <img
                      v-if="updatedData.mapStyleOption === 'light'"
                      class="selected-item-icon"
                      src="../../assets/map-directory/styleOptions/style--light.png"
                      alt=""
                    />
                    <img
                      v-if="updatedData.mapStyleOption === 'dark'"
                      class="selected-item-icon"
                      src="../../assets/map-directory/styleOptions/style--dark.png"
                      alt=""
                    />
                    <img
                      v-if="updatedData.mapStyleOption === 'club'"
                      class="selected-item-icon"
                      src="../../assets/map-directory/styleOptions/style--club.png"
                      alt=""
                    />
                  </div>
                </div>
              </v-card>

              <div class="stepper-help">
                <p>
                  Can't find what you're after? All Maps are created in the
                  <a @click="pushToMapDirectory" class="stepper-help-link"
                    >Map Directory</a
                  >
                </p>
              </div>

              <div class="buttons-wrapper">
                <v-btn
                  class="button-cancel"
                  color="lightgrey"
                  @click="stepperCount = 2"
                >
                  Back
                </v-btn>

                <div class="buttons-continue">
                  <v-btn color="accent" @click="updateData()"> Complete </v-btn>
                </div>
              </div>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "MapDirectoryWidget",

  data: () => ({
    api: process.env.VUE_APP_MAPDIRECTORYAPI,
    dialog: false,
    stepperCount: 0,
    updatedData: {
      mapTitle: null,
      mapId: null,
      mapStyleOption: null,
    },
    selectedMapArray: [],
    mapsArray: [],
    selectedMap: null,
    styleSelected: null,
    selectedMapsArrayTitles: [],
    selectedMapsID: [],
    mapStyleLight: false,
    mapStyleDark: false,
    mapStyleClub: false,
  }),

  props: {
    widgetData: {
      type: Object,
      required: false,
    },
  },

  watch: {
    mapStyleLight(newValue) {
      if (newValue) {
        this.mapStyleDark = false;
        this.mapStyleClub = false;
        this.styleSelected = true;
        this.updatedData.mapStyleOption = "light";
      }
    },

    mapStyleDark(newValue) {
      if (newValue) {
        this.mapStyleLight = false;
        this.mapStyleClub = false;
        this.styleSelected = true;
        this.updatedData.mapStyleOption = "dark";
      }
    },

    mapStyleClub(newValue) {
      if (newValue) {
        this.mapStyleDark = false;
        this.mapStyleLight = false;
        this.styleSelected = true;
        this.updatedData.mapStyleOption = "club";
      }
    },
  },

  computed: {
    unselectedMaps() {
      if (!this.mapsArray) {
        return [];
      }

      return this.mapsArray.filter(
        (map) => !this.selectedMapsID.includes(map.id)
      );
    },
  },

  created() {
    this.getMapData();
  },

  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData));
      console.log("map widget data", this.widgetData);
      this.selectedMapsArrayTitles.push(
        JSON.parse(JSON.stringify(this.widgetData.mapTitle))
      );
      this.selectedMap = JSON.parse(JSON.stringify(this.widgetData.mapTitle));

      if (this.widgetData.mapStyleOption === "light") {
        this.mapStyleLight = true;
        this.mapStyleDark = false;
        this.mapStyleClub = false;
        this.styleSelected = true;
        this.updatedData.mapStyleOption = "light";
      } else if (this.widgetData.mapStyleOption === "dark") {
        this.mapStyleLight = false;
        this.mapStyleDark = true;
        this.mapStyleClub = false;
        this.styleSelected = true;
        this.updatedData.mapStyleOption = "dark";
      } else if (this.widgetData.mapStyleOption === "club") {
        this.mapStyleLight = false;
        this.mapStyleDark = false;
        this.mapStyleClub = true;
        this.styleSelected = true;
        this.updatedData.mapStyleOption = "club";
      }
    }
  },

  methods: {
    updateData() {
      console.log("Update data:", this.updatedData);

      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
    },

    pushToMapDirectory() {
      this.$router.push({ path: "/map-directory" });
    },

    closeMapDirectoryWidget() {
      this.dialog = false;
    },

    deleteSelectedMapTitles(id) {
      const deleteIndex = this.selectedMapsArrayTitles.findIndex(
        (obj) => obj.id === id
      );
      this.selectedMapsArrayTitles.splice(deleteIndex, 1);

      const deleteIndexID = this.selectedMapsID.findIndex((obj) => obj === id);
      this.selectedMapsID.splice(deleteIndexID, 1);

      if (this.selectedMap && this.selectedMap.mapId === id) {
        this.selectedMap = null;
      }
    },

    updateSelectedMapsArray(payload) {
      this.selectedMapsArrayTitles.push({
        id: payload.id,
        title: payload.attributes.title,
      });
      this.selectedMapsID.push(payload.id);
      this.updatedData.mapTitle = payload.attributes.title;
      this.updatedData.mapId = payload.id;
    },

    getMapData() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/maps`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            this.mapsArray = response.data.data;

            if (this.mapsArray && response.data.data.attributes.maps) {
              this.selectedMapsArrayTitles =
                response.data.data.attributes.maps.map((c) => {
                  const map = this.mapsArray.find((fm) => fm.id == m);
                  return map ? { id: map.id, title: map.attributes.title } : "";
                });
            }

            console.log("Map Widget Selector Data", this.mapsArray);
            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem receiving map data", e);
            this.$store.commit("completeLoading");
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  &__description {
    margin: 0 0 20px;
    color: #7b6b80;
    font-size: 14px;
  }
}

.stepper {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 90px;
  }

  &-header {
    box-shadow: none;
  }

  &-help {
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    color: #a8aab7;
  }

  &-help-link {
    font-size: 14px;
    font-weight: 300;
    color: #9641b1;
  }
}

.selected-map {
  width: 150px;
  height: 30px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #aecbe1;
  color: white;

  &-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &--delete {
    font-size: 12px;
    text-transform: uppercase;
    color: #d61244;
  }
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 5px 16px;
  width: 100%;
}

.selected {
  &-style-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }

  &-item-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-item-icon {
    height: 70px;
    width: 100px;
  }
}

.summary {
  &-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    margin: 20px;
  }

  &-heading {
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0 0 0;
    text-align: center;
  }
}
</style>