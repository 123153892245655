<template>
  <v-dialog
    class="player-stats-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
          Player Statistics
        </v-card-title>

        <v-card-text>

        <v-form enctype="multipart/form-data" v-model="valid" ref="playerStatsForm" lazy-validation>

          <label class="label" for="squad">Select Squad</label>
          <v-select
          :items="squads"
          item-text="name"
          item-value="id"
          id="squad"
          v-model="playerStatsData.squadID"
          outline
          validate-on-blur
          label="Select Squad"
          required
          :rules="[rules.required]"
          @change="
            playerStatsData.playerID = null,
            playerStatsData.statTypeID = null,
            playerStatsData.statPeriodID = null,
            players = [],
            getPlayers($event)
          "
          ></v-select>

          <label class="label" for="squad">Select Player</label>
          <v-select
          :items="players"
          item-text="name"
          item-value="id"
          id="squad"
          v-model="playerStatsData.playerID"
          :disabled="!players.length"
          outline
          validate-on-blur
          label="Select Player"
          required
          :rules="[rules.required]"
          @change="setPlayer($event)"
          ></v-select>

          <label class="label" for="squad">Select Stat Type</label>
          <v-select
          :items="statType"
          item-text="name"
          item-value="id"
          id="squad"
          v-model="playerStatsData.statTypeID"
          :disabled="!playerStatsData.playerID"
          outline
          validate-on-blur
          label="Select Stat Type"
          required
          :rules="[rules.required]"
          @change="setStat($event)"
          ></v-select>

          <label class="label" for="squad">Select Stat Period</label>
          <v-select
          :items="statPeriod"
          item-text="name"
          item-value="id"
          id="squad"
          v-model="playerStatsData.statPeriodID"
          :disabled="!playerStatsData.statTypeID"
          outline
          validate-on-blur
          label="Select Stat Period"
          required
          :rules="[rules.required]"
          @change="setPeriod($event)"
          ></v-select>

          <v-btn
            color="accent"
            outline
            flat
            @click="dialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="accent"
            depressed
            :disabled="!playerStatsData.squadID || !playerStatsData.playerID || !playerStatsData.statTypeID || !playerStatsData.statPeriodID"
            @click="validate()"
          >
            <div v-if="widgetData">
              Update player statistics
            </div>
            <div v-else>
                Add player statistics
            </div>
          </v-btn>

        </v-form>

        </v-card-text>
      </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "PlayerStatsWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/fixtures`,
    apiPlayers: `${process.env.VUE_APP_BASEURL}/players/opta`,
    dialog: false,
    valid: false,
    squads: [],
    players: [],
    statType: [
      "Attacking",
      "Midfield",
      "Defense"
    ],
    statPeriod: [
      "Current season",
      "Last season",
      "Last match",
      "Career"
    ],
    rules: {
      required: value => !!value || "Required."
    },
    playerStatsData: {
      squadID: null,
      squadName: null,
      playerID: null,
      playerName: null,
      statTypeID: null,
      statTypeName: null,
      statPeriodID: null,
      statPeriodName: null
    }
  }),


  mounted() {
    console.log("Player stats widget mounted");

    if (this.widgetData) {
      this.playerStatsData = JSON.parse(JSON.stringify(this.widgetData));
    }
    this.$store.commit("startLoading");
    axios
    .get(`${this.api}/opta/filters`)
    .then(response => {
      console.log("Response: ", response);
      this.squadsData = response.data.body;
      this.squads = this.squadsData.map(item => {
        return {
          id: item.teamID,
          name: item.teamName
        }
      });
      this.$store.commit("completeLoading");
      if(this.widgetData) {
        this.getPlayers(this.playerStatsData.squadID)
      }
    })
    .catch(e => {
      console.error("Problem retrieving squads", e);
      this.$store.commit("completeLoading");
    });
  },

  methods: {
    getPlayers(e) {
      const squad = this.squads.filter(item => {
        if(item.id == e) {
          return item;
        }
      });
      this.playerStatsData.squadName = squad[0].name;
      console.log(this.playerStatsData);
      this.$store.commit("startLoading");
      axios
      .get(this.apiPlayers)
      .then(response => {
        console.log("Response: ", response);
        this.playersData = response.data.body;

        this.players = this.playersData.map(item => {
          return {
            name: item.firstName + " " + item.surname,
            id: item.playerID
          }
        });
        console.log("Players: ", this.players);
        this.$store.commit("completeLoading");
      })
      .catch(e => {
        console.error("Problem retrieving players", e);
        this.$store.commit("completeLoading");
      });
    },
    updateData() {
      console.log("Update data:", this.playerStatsData);
      this.dialog = false;
      this.$emit("updatedData", this.playerStatsData);
    },
    validate() {
      this.errorMessage = ""
      if (this.$refs.playerStatsForm.validate()) {
        console.log("Validation passed");
        console.log(this.playerStatsData);
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },
    setPlayer(e) {
      const player = this.players.filter(item => {
        if(item.id == e) {
          return item;
        }
      });
      this.playerStatsData.playerName = player[0].name;
    },
    setStat(e) {
      this.playerStatsData.statTypeName = e;
    },
    setPeriod(e) {
      this.playerStatsData.statPeriodName = e;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
