<template>
  <section class="field-constructor">
    <div v-if="!selectedType">
      <h3>Please select a field type</h3>
      <v-select
        class="field-type-select"
        :items="fieldTypes"
        item-text="name"
        item-value="value"
        v-model="selectedType"
        @change="setFieldType(selectedType)"
      ></v-select>
    </div>

    <template v-if="addingField">
      <div v-if="field && field.type === 'textArea'">
        <v-form v-model="valid" ref="fieldDetails">
          <v-layout row>
            <h2>Text Area Field Builder</h2>
          </v-layout>
          <v-layout row>
            <v-flex mr-2>
              <label>Add a label</label>
              <v-text-field
                v-model="field.label"
                solo
                flat
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>
            </v-flex>
            <v-flex ml-2>
              <label>Add a placeholder</label>
              <v-text-field
                v-model="field.placeholder"
                solo
                flat
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex mr-2>
              <label>Help Text</label>
              <v-text-field v-model="field.helpText" solo flat></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex mr-2>
              <label>Max Length?</label>
              <v-text-field
                v-model="field.fieldOptions.max"
                solo
                flat
              ></v-text-field>
            </v-flex>
            <v-flex ml-2>
              <label>Number Of Rows</label>
              <v-text-field
                v-model="field.fieldOptions.rows"
                solo
                flat
              ></v-text-field>
            </v-flex>
            <v-flex ml-2>
              <label>Required?</label>
              <v-checkbox v-model="field.fieldOptions.required"> </v-checkbox>
            </v-flex>
          </v-layout>
        </v-form>
      </div>

      <div v-if="field && field.type === 'select'">
        <v-form v-model="valid" ref="fieldDetails">
          <v-layout row>
            <h2>Select Field Builder</h2>
          </v-layout>
          <v-layout row>
            <v-flex mr-2 grow>
              <label>Add a label</label>
              <v-text-field
                v-model="field.label"
                solo
                flat
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>
            </v-flex>
            <v-flex ml-2 shrink>
              <label>Required?</label>
              <v-checkbox v-model="field.fieldOptions.required"> </v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex mr-2>
              <label>Help Text</label>
              <v-textarea
                v-model="field.helpText"
                solo
                flat
                rows="2"
              ></v-textarea>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex mr-2>
              <template v-if="field.values.length">
                <ul class="field-option-list">
                  <h3>Current Options</h3>
                  <li
                    class="field-option-item"
                    v-for="(value, index) in field.values"
                    :key="index"
                  >
                    <span><b>Name:</b> {{ value.name }}</span>
                    <span><b>Value: </b>{{ value.value }}</span>
                    <button
                      class="field-option-delete"
                      type="button"
                      @click="deleteOption(value)"
                    >
                      <v-icon>delete</v-icon>
                    </button>
                  </li>
                </ul>
              </template>
              <template v-else>
                <p>A select field requires additional options</p>
                <v-btn
                  class="add-field-option"
                  type="button"
                  v-if="!field.values.length && !addingValue"
                  @click="addSelectValue()"
                  >Add an option</v-btn
                >
              </template>
              <v-btn
                class="add-field-option"
                type="button"
                v-if="field.values.length && !addingValue"
                @click="addSelectValue()"
                >Add a new option</v-btn
              >

              <template v-if="addingValue">
                <v-layout row class="add-option-row">
                  <v-flex mr-2>
                    <label>Option Name</label>
                    <v-text-field v-model="optionName" solo flat></v-text-field>
                  </v-flex>
                  <v-flex ml-2>
                    <label>Option Value</label>
                    <v-text-field
                      v-model="optionValue"
                      solo
                      flat
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex ml-2>
                    <v-btn class="add-option" @click="addOption()"
                      >Add to Select Options</v-btn
                    >
                  </v-flex>
                </v-layout>
              </template>
            </v-flex>
          </v-layout>
        </v-form>
      </div>

      <div v-if="field && field.type === 'checkbox'">
        <v-form v-model="valid" ref="fieldDetails">
          <v-layout row>
            <h2>Checkbox Field Builder</h2>
          </v-layout>
          <v-layout row>
            <v-flex mr-2 grow>
              <label>Add a label</label>
              <v-text-field
                v-model="field.label"
                solo
                flat
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>
            </v-flex>
            <v-flex ml-2 shrink>
              <label>Required?</label>
              <v-checkbox v-model="field.fieldOptions.required"> </v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex mr-2>
              <label>Add a description</label>
              <v-textarea
                v-model="field.description"
                solo
                flat
                rows="2"
              ></v-textarea>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex mr-2>
              <label>Help Text</label>
              <v-textarea
                v-model="field.helpText"
                solo
                flat
                rows="2"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-form>
      </div>

      <div v-if="field && field.type === 'input'">
        <v-form v-model="valid" ref="fieldDetails">
          <v-layout row>
            <h2>Input Field Builder</h2>
          </v-layout>
          <v-layout row>
            <v-flex mr-2 grow>
              <label>Add a label</label>
              <v-text-field
                v-model="field.label"
                solo
                flat
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>
            </v-flex>
            <v-flex ml-2 shrink>
              <label>Required?</label>
              <v-checkbox v-model="field.fieldOptions.required"> </v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex mr-2>
              <label>Help Text</label>
              <v-textarea
                v-model="field.helpText"
                solo
                flat
                rows="2"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-form>
      </div>

      <div v-if="field && field.type === 'emailAddress'">
        <v-form v-model="valid" ref="fieldDetails">
          <v-layout row>
            <h2>Email Address Field Builder</h2>
          </v-layout>
          <v-layout row>
            <v-flex mr-2 grow>
              <label>Add a label</label>
              <v-text-field
                v-model="field.label"
                solo
                flat
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>
            </v-flex>
            <v-flex ml-2 shrink>
              <label>Required?</label>
              <v-checkbox v-model="field.fieldOptions.required"></v-checkbox>
            </v-flex>
            <v-flex
              v-if="!replyEmailId || replyEmailId == field.id"
              ml-2
              shrink
            >
              <label>Set as reply e-mail?</label>
              <v-checkbox v-model="field.fieldOptions.replyEmail"> </v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex mr-2>
              <label>Help Text</label>
              <v-textarea
                v-model="field.helpText"
                solo
                flat
                rows="2"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-form>
      </div>

      <div v-if="field && field.type === 'upload'">
        <v-form v-model="valid" ref="fieldDetails">
          <v-layout row>
            <h2>Upload Field Builder</h2>
          </v-layout>
          <v-layout row>
            <v-flex mr-2 grow>
              <label>Add a label</label>
              <v-text-field
                v-model="field.label"
                solo
                flat
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>
            </v-flex>
            <v-flex ml-2 shrink>
              <label>Required?</label>
              <v-checkbox v-model="field.fieldOptions.required"> </v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex mr-2>
              <label>Help Text</label>
              <v-textarea
                v-model="field.helpText"
                solo
                flat
                rows="2"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-form>
      </div>

      <div v-if="field && field.type === 'radios'">
        <v-form v-model="valid" ref="fieldDetails">
          <v-layout row>
            <h2>Radio Buttons Field Builder</h2>
          </v-layout>
          <v-layout row>
            <v-flex mr-2 grow>
              <label>Add a label</label>
              <v-text-field
                v-model="field.label"
                solo
                flat
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>
            </v-flex>
            <v-flex ml-2 shrink>
              <label>Required?</label>
              <v-checkbox v-model="field.fieldOptions.required"></v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex mr-2>
              <label>Help Text</label>
              <v-textarea
                v-model="field.helpText"
                solo
                flat
                rows="2"
              ></v-textarea>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex mr-2>
              <template v-if="field.values.length">
                <ul class="field-option-list">
                  <h3>Current Options</h3>
                  <li
                    class="field-option-item"
                    v-for="(value, index) in field.values"
                    :key="index"
                  >
                    <span><b>Name:</b> {{ value.name }}</span>
                    <span><b>Value: </b>{{ value.value }}</span>
                    <button
                      class="field-option-delete"
                      type="button"
                      @click="deleteOption(value)"
                    >
                      <v-icon>delete</v-icon>
                    </button>
                  </li>
                </ul>
              </template>
              <template v-else>
                <p>A radio field requires additional options</p>
                <v-btn
                  class="add-field-option"
                  type="button"
                  @click="addSelectValue()"
                  >Add an option</v-btn
                >
              </template>
              <v-btn
                class="add-field-option"
                type="button"
                v-if="!addingValue && field.values.length"
                @click="addSelectValue()"
                >Add a new option</v-btn
              >
              <template v-if="addingValue">
                <v-layout row class="add-option-row">
                  <v-flex mr-2>
                    <label>Option Name</label>
                    <v-text-field v-model="optionName" solo flat></v-text-field>
                  </v-flex>
                  <v-flex ml-2>
                    <label>Option Value</label>
                    <v-text-field
                      v-model="optionValue"
                      solo
                      flat
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex mr-2>
                    <v-btn
                      class="add-option"
                      :disabled="!optionName || !optionValue"
                      @click="addOption()"
                      >Add to Radio Options</v-btn
                    >
                  </v-flex>
                </v-layout>
              </template>
            </v-flex>
          </v-layout>
        </v-form>
      </div>

      <div v-if="field && field.type === 'bodyText'">
        <v-form v-model="valid" ref="fieldDetails">
          <v-layout row>
            <h2>Text Builder</h2>
          </v-layout>
          <v-layout row>
            <v-flex mr-2>
              <label>Add your text</label>
              <v-textarea
                v-model="field.content"
                solo
                flat
                :rules="[rules.required]"
                validate-on-blur
                rows="7"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-form>
      </div>

      <div class="button-wrapper">
        <v-btn
          v-if="!existingField"
          class="add-field"
          type="button"
          @click="saveField()"
          >Add Field to form</v-btn
        >
        <v-btn
          v-else
          class="add-field"
          type="button"
          @click="updateField(field)"
          >Update Field</v-btn
        >
        <v-btn class="cancel-field" type="button" @click="cancel()"
          >Cancel</v-btn
        >
      </div>
    </template>
  </section>
</template>

<script>
export default {
  name: "FieldConstructor",

  props: {
    existingField: {
      type: Object,
      required: false,
    },
    replyEmailId: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    valid: false,
    addingField: false,
    selectedType: "",
    fieldTypes: [
      { name: "Body Text", value: "bodyText" },
      { name: "Checkbox", value: "checkbox" },
      { name: "Email Address", value: "emailAddress" },
      { name: "File upload", value: "upload" },
      { name: "Input", value: "input" },
      { name: "Radio Buttons", value: "radios" },
      { name: "Select", value: "select" },
      { name: "Text Input", value: "textArea" },
    ],
    field: null,
    addingValue: false,
    optionName: "",
    optionValue: "",
    rules: {
      required: (value) => !!value || "Required.",
    },
    editExistingField: null,
  }),

  // watch: {
  //   existingField: function(val) {
  //     if (val) {
  //       // this.existingField = this.existingField
  //       console.log('existingFieldExists in field constructor: watched', this.existingField)
  //       this.editExistingField = this.existingField
  //       this.setFieldType(this.editExistingField.type)
  //       this.selectedType = this.editExistingField.type
  //     }
  //   }
  // },

  mounted() {
    if (this.existingField) {
      console.log(
        "Existing Data in field constructor: mounted",
        this.existingField
      );
      this.setFieldType(this.existingField.type);
      this.selectedType = this.existingField.type;
      this.field = this.existingField;
    } else {
      console.log("New Data");
      this.selectedType = "";
    }
  },

  methods: {
    setFieldType(type) {
      this.addingField = true;
      console.log("what field type???", type);
      if (type === "textArea") {
        this.field = {
          id: this.$uuid.v1(),
          type: "textArea",
          label: "",
          placeholder: "",
          helpText: "",
          fieldOptions: {
            max: "",
            rows: "",
            required: "",
          },
        };
      } else if (type === "select") {
        this.field = {
          id: this.$uuid.v1(),
          type: "select",
          label: "",
          helpText: "",
          fieldOptions: {
            required: "",
          },
          values: [],
        };
      } else if (type === "checkbox") {
        this.field = {
          id: this.$uuid.v1(),
          type: "checkbox",
          label: "",
          description: "",
          helpText: "",
          fieldOptions: {
            required: false,
          },
        };
      } else if (type === "input") {
        this.field = {
          id: this.$uuid.v1(),
          type: "input",
          label: "",
          helpText: "",
          fieldOptions: {
            required: false,
          },
        };
      } else if (type === "emailAddress") {
        this.field = {
          id: this.$uuid.v1(),
          type: "emailAddress",
          label: "Email Address",
          helpText: "",
          fieldOptions: {
            required: false,
            replyEmail: false,
          },
        };
      } else if (type === "radios") {
        this.field = {
          id: this.$uuid.v1(),
          type: "radios",
          label: "",
          helpText: "",
          fieldOptions: {
            required: false,
          },
          values: [],
        };
      } else if (type === "upload") {
        this.field = {
          id: this.$uuid.v1(),
          type: "upload",
          label: "",
          helpText: "",
          fieldOptions: {
            required: false,
          },
        };
      } else if (type === "bodyText") {
        this.field = {
          id: this.$uuid.v1(),
          type: "bodyText",
          content: "",
        };
      } else {
        console.error("Field Type not found");
      }
    },

    addSelectValue() {
      this.addingValue = true;
    },

    saveField() {
      if (this.$refs.fieldDetails.validate()) {
        if (this.field.type != "bodyText") {
          this.field.model =
            this.field.type +
            "_" +
            this.field.label.replace(/ /g, "-") +
            "_" +
            this.$uuid.v1();
        } else {
          this.field.model =
            this.field.type +
            "_" +
            this.field.content.replace(/ /g, "-") +
            "_" +
            this.$uuid.v1();
        }

        this.$emit("updatingForm", this.field);
        this.addingField = false;
        this.selectedType = false;
        this.existingField = null;
      } else {
        console.error("Certain entries are invalid or required");
        //TODO Emit errormessage and savestatus to sticky footer
      }
    },

    updateField(index) {
      if (this.$refs.fieldDetails.validate()) {
        this.$emit("editingForm", index, this.field);
        this.addingField = false;
        this.selectedType = "";
      }
    },

    cancel() {
      console.warn("cancelling");
      if (this.editExistingField) {
        this.editExistingField = null;
        this.$emit("cancelling");
        this.selectedType = "";
        this.field = null;
        console.warn("cancel existing", this.field);
      } else {
        this.field = null;
        this.$emit("cancelling");
        this.selectedType = "";
        console.warn("cancel new", this.field);
      }
    },

    addOption() {
      this.field.values.push({
        name: this.optionName,
        value: this.optionValue,
      });
      this.addingValue = false;
      this.optionName = "";
      this.optionValue = "";
    },

    deleteOption(option) {
      this.field.values.splice(this.field.values.indexOf(option), 1);
    },
  },
};
</script>

<style scoped lang='scss'>
.field-constructor {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 16px;
}

.field-type-select {
  width: fit-content;
}

.adding-option {
  margin-top: 24px;
}

.field-option-list {
  padding-left: 0;
}

.field-option-item {
  display: flex;
  justify-content: space-between;

  &:nth-child(even) {
    background-color: #e6e8f1;
  }

  &:nth-child(odd) {
    background-color: #f0f1f5;
  }
}

.add-field-option {
  margin-left: 0;
}

.add-option-row {
  margin-top: 16px;
}

.add-option {
  margin: 24px 0 0 0;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
}

.add-field {
  margin: 24px 0;
  color: #fff;
  background-color: #67247c !important;
}

.cancel-field {
  margin: 24px 0;
  color: #fff;
  background-color: #f44c4c !important;
}
</style>
