var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page-management main-body",class:{'is-dragging': _vm.isDragging}},[_c('div',{staticClass:"beta-notice"},[_vm._m(0),_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){return _vm.togglePageManagement()}}},[_vm._v(" Swap to New Page Management System ")])],1),_c('div',{staticClass:"pages"},[_c('MiniHeader',{attrs:{"heading":'Pages',"buttonText":'Add Page',"hasButton":true},on:{"buttonClicked":function($event){return _vm.addPage()}}}),_c('draggable',{attrs:{"options":{
        group: 'menu',
        ghostClass: 'ghost',
        dragClass: 'sortable-drag',
        emptyInsertThreshold: 10,
        filter: '.child-menu, .draggable-item__actions, .draggable-item__delete'
      }},on:{"start":_vm.startDragging,"end":_vm.endDragging},model:{value:(_vm.pagesList),callback:function ($$v) {_vm.pagesList=$$v},expression:"pagesList"}},[_c('transition-group',{attrs:{"name":"list-complete","tag":"ol"}},_vm._l((_vm.pagesList),function(level1Item){return _c('li',{key:level1Item.postID,staticClass:"draggable-item"},[_c('DraggablePageRow',{attrs:{"name":level1Item.postTitle,"template":level1Item.pageTemplate,"status":level1Item.postStatus,"slug":level1Item.postType === 'internalLink' ? level1Item.linkSlug : level1Item.fullPath,"allowChildCreation":true,"postType":level1Item.postType,"childrenCount":_vm.childrenCount(level1Item.children)},on:{"editItem":function($event){return _vm.editItem(level1Item.postID)},"duplicateItem":function($event){return _vm.duplicateItem(level1Item.postID)},"deleteItem":function($event){return _vm.deleteItem(level1Item.postID)},"closeChild":function($event){return _vm.closeChildMenu(level1Item.postID)},"expandChild":function($event){return _vm.expandChildMenu(level1Item.postID)}}}),_c('draggable',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkMenuVisibility(level1Item.postID)),expression:"checkMenuVisibility(level1Item.postID)"}],staticClass:"draggable-target",attrs:{"options":{
                group: 'menu',
                ghostClass: 'ghost',
                dragClass: 'sortable-drag',
                emptyInsertThreshold: 10,
                filter: '.draggable-item__actions, .draggable-item__delete'
              }},on:{"start":_vm.startDragging,"end":_vm.endDragging},model:{value:(level1Item.children),callback:function ($$v) {_vm.$set(level1Item, "children", $$v)},expression:"level1Item.children"}},[_c('transition-group',{staticClass:"child-menu",class:{'is-empty': _vm.childrenCount(level1Item.children) === 0},attrs:{"name":"list-complete","tag":"ol"}},_vm._l((level1Item.children),function(level2Item){return _c('li',{key:level2Item.postID,staticClass:"draggable-item"},[_c('DraggablePageRow',{attrs:{"name":level2Item.postTitle,"template":level2Item.pageTemplate,"status":level2Item.postStatus,"slug":level2Item.fullPath,"allowChildCreation":true,"childrenCount":_vm.childrenCount(level2Item.children)},on:{"editItem":function($event){return _vm.editItem(level2Item.postID)},"duplicateItem":function($event){return _vm.duplicateItem(level2Item.postID)},"deleteItem":function($event){return _vm.deleteItem(level2Item.postID)},"closeChild":function($event){return _vm.closeChildMenu(level2Item.postID)},"expandChild":function($event){return _vm.expandChildMenu(level2Item.postID)}}}),_c('draggable',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkMenuVisibility(level2Item.postID)),expression:"checkMenuVisibility(level2Item.postID)"}],staticClass:"draggable-target",attrs:{"options":{
                        group: 'menu',
                        ghostClass: 'ghost',
                        dragClass: 'sortable-drag',
                        emptyInsertThreshold: 10,
                        filter: '.draggable-item__actions, .draggable-item__delete'
                      }},on:{"start":_vm.startDragging,"end":_vm.endDragging},model:{value:(level2Item.children),callback:function ($$v) {_vm.$set(level2Item, "children", $$v)},expression:"level2Item.children"}},[_c('transition-group',{staticClass:"child-menu",class:{'is-empty': _vm.childrenCount(level2Item.children) === 0},attrs:{"name":"list-complete","tag":"ol"}},_vm._l((level2Item.children),function(level3Item){return _c('li',{key:level3Item.postID,staticClass:"draggable-item"},[_c('DraggablePageRow',{attrs:{"name":level3Item.postTitle,"template":level3Item.pageTemplate,"status":level3Item.postStatus,"slug":level3Item.fullPath,"allowChildCreation":false},on:{"editItem":function($event){return _vm.editItem(level3Item.postID)},"duplicateItem":function($event){return _vm.duplicateItem(level3Item.postID)},"deleteItem":function($event){return _vm.deleteItem(level3Item.postID)}}})],1)}),0)],1)],1)}),0)],1)],1)}),0)],1)],1),_c('StickyFooter',{attrs:{"errorMessage":_vm.errorMessage,"status":_vm.status,"buttonText":'Save Pages'},on:{"buttonClicked":function($event){return _vm.savePages()}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Update:")]),_vm._v(" We are developing a new page management system to make it easier to move pages around. This is still in development so has some missing features, but you can toggle between the two at the moment.")])
}]

export { render, staticRenderFns }