export default {
  data: [
    {
      sectionName: "Homepage Banner",
      sectionDesc: "This appears below the hero on your homepage",
      model: "homepageBanner",
      fields: [
        {
          label: "Title",
          model: "homepageBanner__title",
          type: "Text"
        },
        {
          label: "Description",
          model: "homepageBanner__description",
          type: "Text"
        },
        {
          label: "CTA URL",
          model: "homepageBanner_link",
          type: "Text"
        },
        {
          label: "CTA Text",
          model: "homepageBanner_linkText",
          type: "Text"
        },
        {
          label: "Open in New Tab?",
          model: "homepageBanner_newTab",
          type: "Checkbox"
        },
        {
          label: "Image",
          model: "homepageBanner_image",
          type: "Image"
        },
        {
          label: "Display",
          model: "homepageBanner_display",
          type: "Checkbox"
        },
      ]
    },
    {
      sectionName: "Homepage Hero",
      sectionDesc: "Add the details for each slide here",
      model: "homepageHero",
      fields: [
        {
          label: "Slide 1 - Title",
          model: "homepageHero__title1",
          type: "Text"
        },
        {
          label: "Slide 1 - Description",
          model: "homepageHero__description1",
          type: "Text"
        },
        {
          label: "Slide 1 - Button URL",
          model: "homepageHero_link1",
          type: "Text"
        },
        {
          label: "Slide 1 - Button Text",
          model: "homepageHero_linkText1",
          type: "Text"
        },
        {
          label: "Slide 1 - Image",
          model: "homepageHero_image1",
          type: "Image"
        },
        {
          label: "Slide 2 - Title",
          model: "homepageHero__title2",
          type: "Text"
        },
        {
          label: "Slide 2 - Description",
          model: "homepageHero__description2",
          type: "Text"
        },
        {
          label: "Slide 2 - Button URL",
          model: "homepageHero_link2",
          type: "Text"
        },
        {
          label: "Slide 2 - Button Text",
          model: "homepageHero_linkText2",
          type: "Text"
        },
        {
          label: "Slide 2 - Image",
          model: "homepageHero_image2",
          type: "Image"
        },
        {
          label: "Slide 3 - Title",
          model: "homepageHero__title3",
          type: "Text"
        },
        {
          label: "Slide 3 - Description",
          model: "homepageHero__description3",
          type: "Text"
        },
        {
          label: "Slide 3 - Button URL",
          model: "homepageHero_link3",
          type: "Text"
        },
        {
          label: "Slide 3 - Button Text",
          model: "homepageHero_linkText3",
          type: "Text"
        },
        {
          label: "Slide 3 - Image",
          model: "homepageHero_image3",
          type: "Image"
        },
      ]
    }
  ]
}
