export default {
  aggregatedFeed: {
    showAllNews: 0
  },
  allowUpsellInApp: false,
  pageTypes: [
    {value: "internal", text: "Website Page"},
    // {value: 'external', text: 'External Page'},
    {value: "homepage", text: "Homepage"},
  ],
  platformSettings: {
    showWeb: true,
    showApp: false
  },
  defaultNewsCardStyle: "Stack",
  playerManagement: "V1",
  customDataFields: {
    customDataFieldOneName: "",
    customDataFieldOneDescription: "",
    customDataFieldTwoName: "",
    customDataFieldTwoDescription: "",
    customDataFieldThreeName: "",
    customDataFieldThreeDescription: "",
  },
  customTags: []
}
