<template>
  <section class="video-preview section-container">
    <h3 class="label">Video Preview</h3>

    <div class="video-preview__content">

      <div class="video-preview__platform">
        <v-img
          v-if="data.platform === 'streamAMG'"
          :src="require('../../assets/partner-logos/streamamg.png')"
          :alt="data.platform"
          contain
          width="120"
        ></v-img>
      </div>

      <img :src="data.thumbnail" class="video-preview__image" />

      <div class="video-preview__meta">
        <p class="video-preview__meta-data"><span class="video-preview__meta-label">Duration:</span> {{ data.duration }}</p>
        <p class="video-preview__meta-data"><span class="video-preview__meta-label">Published:</span> {{ data.publishedDate | moment('DD/MM/YYYY') }}</p>
        <p class="video-preview__meta-data"><span class="video-preview__meta-label">Tags:</span></p>
        <ul class="tag-manager">
          <li class="tag-manager__tag" v-for="(tag, index) in data.tags" :key="index">{{ tag }}</li>
        </ul>

        <a class="video-preview__link" :href="data.videoSource">Watch this video in your streaming site</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "VideoPreview",

  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .video-preview {
    position: relative;
    margin: 0 0 50px;

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 20px 0 0;
    }

    &__platform {
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 1;
    }

    &__image {
      margin: 0 30px 0 0;
      width: 290px;
      border-radius: 4px;
    }

    &__meta {
      flex-grow: 1;
    }

    &__meta-data {
      color: #7B6B80;
      font-size: 14px;
      font-weight: 400;
    }

    &__meta-label {
      color: #24002F;
    }

    &__link {
      color: #67247c;
    }
  }

  .tag-manager {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    &__tag {
      padding: 4px 15px;
      margin: 0 5px 15px 0;
      color: #fff;
      background-color: #B391BD;
      border-radius: 4px;
    }
  }
</style>
