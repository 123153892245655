var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"accordion-widget",attrs:{"width":"580","lazy":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._t("default",null,{"slot":"activator"}),_c('v-form',{ref:"accordionForm",attrs:{"enctype":"multipart/form-data","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',{staticClass:"widget__heading"},[_vm._v(" Accordion ")]),_c('v-card-text',[_c('label',{staticClass:"label",attrs:{"for":"accordion-title"}},[_vm._v("Accordion Title")]),_c('v-text-field',{attrs:{"id":"accordion-title","placeholder":"Accordion Title","solo":"","flat":"","validate-on-blur":""},model:{value:(_vm.updatedData.accordionTitle),callback:function ($$v) {_vm.$set(_vm.updatedData, "accordionTitle", $$v)},expression:"updatedData.accordionTitle"}}),_c('label',{staticClass:"label",attrs:{"for":"accordion-description"}},[_vm._v("Accordion Description")]),_c('tinymce-editor',{attrs:{"api-key":"rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca","init":{
            height: '180px',
            menubar: false,
            relative_urls: false,
            plugins: 'link paste wordcount hr lists textpattern',
            toolbar:
              'undo redo | styleselect | bold italic | \
              link | \
              hr | \
              bullist numlist | removeformat',
            resize: false,
            textpattern_patterns: [
              {
                start: '//button',
                replacement: '<a class=\'button \'>Button here</a>',
              },
            ],
            link_class_list: [
              { title: 'None', value: '' },
              {
                title: 'Primary on Dark',
                value: 'button button--primary-dark',
              },
              {
                title: 'Primary on Light',
                value: 'button button--primary-light',
              },
              { title: 'Ghost on Dark', value: 'button button--ghost-dark' },
              {
                title: 'Ghost on Light',
                value: 'button button--ghost-light',
              },
              { title: 'Label on Dark', value: 'button button--label-dark' },
              {
                title: 'Label on Light',
                value: 'button button--label-light',
              },
            ],
            style_formats: [
              {
                title: 'Headers',
                items: [
                  //{title: 'Header 1', format: 'h1'},
                  { title: 'Header 2', format: 'h2' },
                  { title: 'Header 3', format: 'h3' },
                  { title: 'Header 4', format: 'h4' },
                  { title: 'Header 5', format: 'h5' },
                  { title: 'Header 6', format: 'h6' },
                ],
              },
              {
                title: 'Inline',
                items: [
                  { title: 'Bold', icon: 'bold', format: 'bold' },
                  { title: 'Italic', icon: 'italic', format: 'italic' },
                  {
                    title: 'Underline',
                    icon: 'underline',
                    format: 'underline',
                  },
                  //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                  //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                  //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                  //{title: 'Code', icon: 'code', format: 'code'}
                ],
              },
              {
                title: 'Blocks',
                items: [
                  { title: 'Paragraph', format: 'p' },
                  { title: 'Blockquote', format: 'blockquote' },
                  //{title: 'Div', format: 'div'},
                  //{title: 'Pre', format: 'pre'}
                ],
              },
              {
                title: 'Alignment',
                items: [
                  { title: 'Left', icon: 'alignleft', format: 'alignleft' },
                  {
                    title: 'Center',
                    icon: 'aligncenter',
                    format: 'aligncenter',
                  },
                  {
                    title: 'Right',
                    icon: 'alignright',
                    format: 'alignright',
                  },
                  //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
                ],
              },
              { title: 'Small', selector: 'p', classes: 'small' },
            ],
            style_formats_merge: false,
            block_formats:
              'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote',
          }},model:{value:(_vm.updatedData.accordionDescription),callback:function ($$v) {_vm.$set(_vm.updatedData, "accordionDescription", $$v)},expression:"updatedData.accordionDescription"}}),_c('draggable',{attrs:{"options":{
            ghostClass: 'ghost',
            dragClass: 'sortable-drag',
          }},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.updatedData.content),callback:function ($$v) {_vm.$set(_vm.updatedData, "content", $$v)},expression:"updatedData.content"}},[_c('transition-group',{attrs:{"name":"list-complete"}},_vm._l((_vm.updatedData.content),function(item,index){return _c('WidgetAccordionItem',{key:item.id,attrs:{"index":index,"title":item.title,"body":item.body},on:{"deleteItem":function($event){return _vm.deleteRow(index)},"saveRow":function($event){return _vm.rowUpdated($event, index)}}})}),1)],1),(!_vm.addRow)?_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){_vm.addRow = true}}},[_vm._v(" Add Item ")]):_vm._e(),(_vm.addRow)?_c('div',[_c('widget-accordion-form',{attrs:{"formHeading":`Add Item`},on:{"saveRow":function($event){return _vm.addNewItem($event)},"cancelEdit":function($event){return _vm.cancelNewRow()}}})],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){return _vm.validate()}}},[_vm._v(" Post ")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }