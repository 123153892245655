<template>
    <v-dialog
        class="fixtures-list-widget"
        v-model="dialog"
        width="580"
        lazy
    >
        <slot slot="activator"></slot>

        <v-card>
            <v-card-title class="widget__heading"> Latest Results </v-card-title>

            <v-card-text>
                <v-form
                    enctype="multipart/form-data"
                    ref="fixtureWidgetForm"
                    lazy-validation
                >
                    <v-text-field
                        id="title"
                        v-model="resultsListData.title"
                        persistent-hint
                        label="Section Title"
                        required
                        class="mb-3"
                    ></v-text-field>

                    <match-picker-efl
                        :teamMandatory="false"
                        :includeMatch="false"
                        :competitionOnly="true"
                        :currentSeason="true"
                        @updated="setMatchEfl($event)"
                    />

                    <v-text-field
                        id="number"
                        v-model="resultsListData.results"
                        persistent-hint
                        label="Number of results to display"
                        required
                        type="number"
                        class="mb-3"
                    ></v-text-field>

                    <v-btn
                        color="accent"
                        outline
                        flat
                        @click="dialog = false"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        :disabled="!resultsListData.season"
                        color="accent"
                        depressed
                        @click="validate()"
                    >
                        Add results
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
import MatchPickerEfl from "../Library/MatchPickerEfl.vue";

export default {
    name: "MatchResultsWidgetEfl",

    components: {
        draggable,
        MatchPickerEfl,
    },

    props: {
        widgetData: {
            type: Object,
            required: false,
        },
    },

    data: () => ({
        dialog: false,
        resultsListData: {
            title: '',
            results: "12",
            squad: null,
            season: null,
            squadName: null,
            competition: null,
            competitionName: null,
        },
    }),

    mounted() {
        if (this.widgetData) {
            this.resultListData = JSON.parse(
                JSON.stringify(this.widgetData)
            );
        }
    },

    watch: {
        dialog() {
            if (this.dialog && this.widgetData) {
                this.resultsListData = JSON.parse(JSON.stringify(this.widgetData));
            }
        }
    },

    methods: {
        setMatchEfl(payload) {
            console.log("payload", payload);
            this.resultsListData.season = payload.seasonID
            this.resultsListData.competition = payload.competitionID
            this.resultsListData.competitionName = payload.competitionName
        },

        validate() {
            this.$emit("updatedData", this.resultsListData);
            this.dialog = false;
        },
    },
};
</script>

<style
    lang="scss"
    scoped
>
.draggable-item {
    transition: all 0.5s, opacity 0.2s;
}

.fixtures-list-widget {
    &__add {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &__icon {
        background-color: $purple;
        border-radius: 50rem;
        padding: 10px;
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
    }

    &__text {
        color: $lightPurple;
        padding: 10px;
    }

    &__fixtures {
        &-item {
            width: 100%;
            display: flex;
            align-items: center;
            border-top: 1px solid $lightgrey;
            padding: 20px;
            justify-content: space-between;

            &-details {
                padding-left: 20px;

                li {
                    color: $grey;
                    font-size: 0.9rem;
                    margin-bottom: 5px;
                }
            }

            &-crest {
                width: 64px;
                height: 64px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &:last-child {
                border-bottom: 1px solid $lightgrey;
            }

            &-action {
                display: flex;
            }

            &-left {
                display: flex;
                flex-flow: row;
            }
        }
    }
}
</style>
