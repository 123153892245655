<template>
  <article class="stream">
    <div class="stream__content">
      <div class="stream__title-row">
        <div v-if="stream.attributes.assetData.status === 'idle'" class="status status--idle">Idle</div>
        <div v-else-if="stream.attributes.assetData.status === 'disabled'" class="status status--disabled">Disabled</div>
        <div v-else-if="stream.attributes.assetData.status === 'active'" class="status status--active">Active</div>
        <div v-else class="status">{{ stream.attributes.assetData.status }}</div>

        <v-icon class="stream__type-icon" v-if="stream.attributes.assetData.audioOnly === true">volume_up</v-icon>
        <v-icon class="stream__type-icon" v-else>videocam</v-icon>

        <h2 class="stream__subheading subheading">{{ stream.attributes.title }}</h2>
      </div>

      <div class="stream__entitlements">
        <span v-for="entitlement in stream.attributes.accessData.entitlements" :key="entitlement" class="stream__entitlements-item">{{ entitlement }}</span>

        <span v-if="!stream.attributes.accessData.entitlements.length" class="stream__entitlements-item--error">No entitlement set</span>
      </div>

      <div class="mini-info__reveal">
        <v-switch
          v-model="revealInfo"
          :label="`Reveal Details`"
        ></v-switch>
      </div>
      <div class="mini-info" v-if="revealInfo">
        <div class="mini-info__row">
          <p class="mini-info__label">Preview</p>
          <p v-if="revealInfo" class="mini-info__value">
            <a :href="previewLink()" target="_blank">{{ previewLink() }}</a>
          </p>
          <p v-else class="mini-info__value">*****</p>
        </div>
        <div class="mini-info__row">
          <p class="mini-info__label">Asset ID</p>
          <p v-if="revealInfo" class="mini-info__value">{{ stream.id }}</p>
          <p v-else class="mini-info__value">*****</p>
        </div>
        <div class="mini-info__row">
          <p class="mini-info__label">Stream Type</p>
          <p v-if="revealInfo" class="mini-info__value">{{ stream.attributes.assetData.audioOnly ? 'Audio Only' : 'Video/Audio'}}</p>
          <p v-else class="mini-info__value">*****</p>
        </div>
        <div class="mini-info__row">
          <p class="mini-info__label">Playback Policy</p>
          <template v-if="stream.attributes.assetData.playbackIds">
            <p v-if="revealInfo" class="mini-info__value">{{ stream.attributes.assetData.playbackIds[0].policy }}</p>
            <p v-else class="mini-info__value">*****</p>
          </template>
          <p v-else class="mini-info__value">No Playback Policy</p>
        </div>
        <div class="mini-info__row">
          <p class="mini-info__label">RTMP URL</p>
          <p v-if="revealInfo" class="mini-info__value">rtmps://global-live.mux.com:443/app</p>
          <p v-else class="mini-info__value">*****</p>
        </div>
        <div class="mini-info__row">
          <p class="mini-info__label">Stream Key</p>
          <p v-if="revealInfo" class="mini-info__value">{{ stream.attributes.assetData.streamKey }}</p>
          <p v-else class="mini-info__value">*****</p>
        </div>
      </div>
    </div>

    <v-btn :to="`/streamline/live/${stream.id}`" class="stream__edit">Edit</v-btn>
  </article>
</template>

<script>
export default {
  name: "LiveStreamingListingItem",

  props: {
    stream: {
      type: Object,
      required: true
    }
  },

  data:() => ({
    revealInfo: false,
  }),

  methods: {
    previewLink() {
      return `https://${process.env.VUE_APP_PREVIEW_LINK}/live/player/${this.stream.id}`
    }
  }
}
</script>

<style scoped lang="scss">
  .stream {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 140px;
    margin: 16px 0 48px;
    padding: 32px 32px 0;
    background-color: #fff;
    border: 1px solid #dfe3e9;
    border-radius: 4px;

    &__content {
      width: 100%;
    }

    &__title-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-right: 100px;
    }

    &__type-icon {
      margin-right: 4px;
      color: #67247C;
      opacity: 0.7;
    }

    &__illustration {
      position: absolute;
      top: -17px;
      right: 10px;
      width: 160px;
    }

    &__subheading {
      margin: 0;
    }

    &__edit {
      position: absolute;
      right: 26px;
      top: 21px;
    }

    &__entitlements {
      padding-top: 16px;
    }

    &__entitlements-item {
      margin: 0 4px 0 0;
      padding: 2px 3px 3px;
      color: #67247C;
      font-size: 10px;
      background: #e5e5e5;
      border-radius: 4px;
    }

    &__entitlements-item--error {
      margin: 0 4px 0 0;
      padding: 2px 3px 3px;
      color: #fff;
      font-size: 10px;
      background: #ff5252;
      border-radius: 4px;
    }
  }

  .mini-info {
    margin: 0 auto 0;
    padding: 0 0 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &__reveal {
      margin-top: 10px;
      border-top: 1px solid #ebebeb;
    }

    &__row {
      display: block;
      padding: 4px;
      width: 100%;
      background-color: #FFFFFF;
      border-top: 1px solid #DFE3E9;
      border-right: 1px solid #DFE3E9;
      border-left: 1px solid #DFE3E9;

      &:first-child {
        border-radius: 4px 4px 0 0;
      }

      &:last-child {
        border-radius: 0 0 4px 4px;
        border-bottom: 1px solid #DFE3E9;
      }
    }

    &__label {
      padding: 0;
      margin: 0;
      color: #b1b4b9;
      font-size: 9px;
      font-weight: 500;
    }

    &__value {
      padding: 0;
      margin: 0;
      // color: #DFE3E9;
      font-family: monospace;
      font-size: 13px;
      font-weight: 400;
    }
  }

  .status {
    display: inline-block;
    padding: 3px 12px;
    margin: 0 8px 0 0;
    font-size: 10px;
    color: #fff;
    background-color: #ccc;
    border-radius: 4px;
    opacity: 1;

    &.status--idle {
      background-color: color(draftHighlight);
    }

    &.status--disabled {
      background-color: color(hiddenHighlight);
    }

    &.status--active {
      background-color: #77a64b;
    }
  }
</style>
