export default {
  data: [
    {
      sectionName: "Homepage promo - left",
      sectionDesc: "This is for the left hand side (top on smaller screens) promotional area on the homepage",
      model: "promo_left",
      fields: [
        {
          label: "Title",
          model: "promo_left_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_left_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_left_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_left_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_left_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Homepage promo - right",
      sectionDesc: "This is for the right hand side (bottom on smaller screens) promotional area on the homepage",
      model: "promo_right",
      fields: [
        {
          label: "Title",
          model: "promo_right_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_right_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_right_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_right_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_right_promoimage",
          type: "Image"
        }
      ]
    },
  ]
}
