<!--
  @TODO:
    - Use real data
-->

<template>
  <section class="video-management main-body">

    <div class="video">
      <MiniHeader :heading="'Videos'" :hasButton="false" />

      <ol v-if="videoList.length > 0" class="video-list">
        <li class="video-item" v-for="item in videoList" :key="item.ID">
          <div class="video-item__info">
            <time class="video-item__date" :datetime="item.publishedDateTime">{{ item.publishedDateTime | moment('DD/MM/YY - kk:mm') }}</time>

            <h2 class="video-item__title" v-if="item.pageName">{{ item.pageName }}</h2>
            <h2 class="video-item__title" v-else>Title not set</h2>
          </div>

          <div class="video-item__actions">
            <button class="video-item__action" type="button" @click="editItem(item.ID)">
              <v-icon>edit</v-icon>
            </button>
          </div>
        </li>
      </ol>

      <p v-else>No videos yet</p>
    </div>
  </section>
</template>

<script>
import axios from "axios"
import MiniHeader from "@/components/Library/MiniHeader"

export default {
  name: "VideoManagement",

  components: {
    MiniHeader
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/videos`,
    videoList: [
      {
        ID: 213214124,
        pageName: "Test Video 1",
        publishedDateTime: "2019-03-18T16:22:02"
      },
      {
        ID: 43894375,
        pageName: "Test Video 2",
        publishedDateTime: "2019-02-11T17:03:33"
      }
    ]
  }),

  mounted() {
    //this.fetchVideos();
  },

  methods: {
    fetchVideos() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(this.api, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("response: ", response);
          this.videoList = response.data.body;

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving videos", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    editItem(itemID) {
      this.$store.commit("startLoading");
      this.$router.push({ path: `/videos/${itemID}` })
    }
  }
}
</script>

<style lang="scss" scoped>
  .video-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .video-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;


    &__info {
      flex-grow: 1;
    }

    &__date {
      display: block;
      margin: 0 0 8px;
      color: #B8B8B8;
      font-size: 13px;
      font-weight: 300;
    }

    &__title {
      color: #7B6B80;
      font-size: 16px;
      font-weight: 400;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    &__action {
      margin: 0 0 0 20px;
    }
  }
</style>
