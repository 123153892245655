<template>
    <v-dialog
        class="lineup-widget"
        v-model="dialog"
        width="580"
        lazy
    >
        <slot slot="activator"></slot>
        <v-form
            enctype="multipart/form-data"
            ref="singleFieldForm"
            v-model="valid"
            lazy-validation
        >
            <v-card>
                <v-card-title class="widget__heading"> Lineup </v-card-title>

                <v-card-text>
                    <match-picker-efl
                        v-if="client == 'efl'"
                        :includeDetailedMatch="true"
                        @updated="saveDetails($event)"
                    />
                    <match-picker
                        v-else
                        @updated="saveDetails($event)"
                    />
                    <p
                        v-if="error"
                        class="error-message"
                    >
                        {{ error }}
                    </p>

                    <div
                        v-if="client !== 'efl'"
                        class="spacer"
                    >
                        <label>Teams to display</label>
                        <v-select
                            v-model="updatedData.content.displayTeam"
                            item-text="name"
                            item-value="value"
                            :items="[
                                { name: 'Both', value: 'both' },
                                { name: 'Club', value: 'club' },
                                { name: 'Opposition', value: 'opposition' },
                            ]"
                            solo
                            flat
                            required
                            @change="status = 'needsSaving'"
                        />
                    </div>

                    <div>
                        <label>Style</label>
                        <v-select
                            v-model="updatedData.content.displayStyle"
                            item-text="name"
                            item-value="value"
                            :items="[
                                { name: 'Inline', value: '' },
                                { name: 'Inline: Subtle Crest', value: 'inline-subtle-crest' },
                            ]"
                            solo
                            flat
                            required
                            @change="status = 'needsSaving'"
                        />
                    </div>

                    <label>Home team starters</label>
                    <v-text-field
                        v-model="updatedData.content.homeTeamStarters"
                        solo
                        flat
                    ></v-text-field>

                    <label>Home team subs</label>
                    <v-text-field
                        v-model="updatedData.content.homeTeamSubs"
                        solo
                        flat
                    ></v-text-field>

                    <label>Away team starters</label>
                    <v-text-field
                        v-model="updatedData.content.awayTeamStarters"
                        solo
                        flat
                    ></v-text-field>

                    <label>Away team subs</label>
                    <v-text-field
                        v-model="updatedData.content.awayTeamSubs"
                        solo
                        flat
                    ></v-text-field>

                    <p v-if="updatedData.content.snapshotUTC">
                        Snapshot taken at
                        {{ updatedData.content.snapshotUTC | moment("kk:mm (Z) DD/MM/YY") }}
                    </p>

                    <p v-else>
                        This widget will take a snapshot of the data <i>now</i>. It will not
                        update, unless you update this widget.
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="accent"
                        outline
                        flat
                        @click="validate()"
                    > Post </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import axios from "axios";
import MatchPicker from "@/components/Library/MatchPicker";
import MatchPickerEfl from "@/components/Library/MatchPickerEfl";
import moment from "moment";

export default {
    name: "FootballBasicLineupWidget",

    components: {
        MatchPicker,
        MatchPickerEfl,
    },

    props: {
        widgetData: {
            type: Object,
            required: false,
        },
    },

    data: () => ({
        multiClubMatchesApi: process.env.VUE_APP_MULTICLUBMATCHESAPI,
        client: process.env.VUE_APP_CLUB_ID,
        dialog: false,
        valid: false,
        rules: {
            required: (value) => !!value || "Required.",
        },
        error: null,
        updatedData: {
            content: {
                snapshotUTC: "",
                displayStyle: "",
                displayTeam: "both",
                matchID: "",
                seasonID: "",
                teamID: "",
                isHome: null,
                homeTeamStarters: "",
                homeTeamSubs: "",
                homeTeamCrest: "",
                shortHomeTeamName: "",
                awayTeamStarters: "",
                awayTeamSubs: "",
                awayTeamCrest: "",
                shortAwayTeamName: "",
                homeTeamCrests: null,
                awayTeamCrests: null,
            },
        },
    }),

    mounted() {
        if (this.widgetData) {
            this.updatedData = JSON.parse(JSON.stringify(this.widgetData));
        }
    },

    methods: {
        saveDetails(payload) {
            console.log("Payload", payload);
            this.updatedData.content.matchID = payload.matchID;
            this.updatedData.content.seasonID = payload.seasonID;
            this.updatedData.content.teamID = payload.teamID;

            if (this.client == "efl") {
                this.getMatchEfl(payload.matchDetails.attributes);
            } else {
                this.getMatch();
            }
        },

        buildLineupString(data, teamIndex, isStarters) {
            const lineup = data.teamData[teamIndex].teamPlayers
                .filter((player) => {
                    if (isStarters) {
                        return player.status === "Start";
                    } else {
                        return player.status != "Start";
                    }
                })
                .map((player) => {
                    let data;
                    if (player.knownName) {
                        data = player.knownName;
                    } else {
                        data = player.surname;
                    }

                    if (player.substitution) {
                        if (player.substitution[0].subOnKnownName) {
                            data = `${data} (${player.substitution[0].subOnKnownName} ${player.substitution[0].time}')`;
                        } else {
                            data = `${data} (${player.substitution[0].subOnSurname} ${player.substitution[0].time}')`;
                        }
                    }

                    return data;
                })
                .join(", ");

            return lineup;
        },

        getMatch() {
            axios
                .get(
                    `${process.env.VUE_APP_WEBAPI}/fixtures/opta/getsingle?seasonID=${this.updatedData.content.seasonID}&teamID=${this.updatedData.content.teamID}&matchID=${this.updatedData.content.matchID}`
                )
                .then((response) => {
                    if (response.data.success) {
                        this.updatedData.content.snapshotUTC = moment().utc().format();

                        const data = response.data.body;

                        this.updatedData.content.isHome = data.homeOrAway === "Home";

                        this.updatedData.content.homeTeamStarters = this.buildLineupString(
                            data,
                            0,
                            true
                        );

                        this.updatedData.content.homeTeamSubs = this.buildLineupString(
                            data,
                            0,
                            false
                        );

                        this.updatedData.content.awayTeamStarters = this.buildLineupString(
                            data,
                            1,
                            true
                        );

                        this.updatedData.content.awayTeamSubs = this.buildLineupString(
                            data,
                            1,
                            false
                        );

                        this.updatedData.content.shortHomeTeamName =
                            data.teamData[0].shortTeamName;
                        this.updatedData.content.shortAwayTeamName =
                            data.teamData[1].shortTeamName;

                        if (data.teamData[0].teamCrest_Custom) {
                            this.updatedData.content.homeTeamCrest =
                                data.teamData[0].teamCrest_Custom.key;
                        } else if (data.teamData[0].teamCrest) {
                            this.updatedData.content.homeTeamCrest =
                                data.teamData[0].teamCrest;
                        } else {
                            return "";
                        }

                        if (data.teamData[0].teamCrests) {
                            this.updatedData.content.homeTeamCrests =
                                data.teamData[0].teamCrests;
                        }

                        if (data.teamData[1].teamCrest_Custom) {
                            this.updatedData.content.awayTeamCrest =
                                data.teamData[1].teamCrest_Custom.key;
                        } else if (data.teamData[1].teamCrest) {
                            this.updatedData.content.awayTeamCrest =
                                data.teamData[1].teamCrest;
                        } else {
                            return "";
                        }

                        if (data.teamData[1].teamCrests) {
                            this.updatedData.content.awayTeamCrests =
                                data.teamData[1].teamCrests;
                        }
                    }
                })
                .catch((e) => {
                    console.error("Problem retrieving match", e);
                });
        },

        buildEflLineupString(data, teamID, isStarters) {
            const lineup = data
                .filter((player) => {
                    return player.teamID === teamID;
                })
                .filter((player) => {
                    if (isStarters) {
                        return player.playerStatus === "Start";
                    } else {
                        return player.playerStatus != "Start";
                    }
                })
                .map((player) => {
                    let data;
                    if (player.playerName && player.playerName.knownName) {
                        data = player.playerName.knownName;
                    } else if (player.playerName) {
                        data = player.playerName.lastName;
                    }

                    // Substitution data for EFL doesn't seem to be available atm. The code below is the one used for a generic team so it would be replace with whatever the logic is for EFL substitutions
                    // if (player.substitution) {
                    //   if (player.substitution[0].subOnKnownName) {
                    //     data = `${data} (${player.substitution[0].subOnKnownName} ${player.substitution[0].time}')`;
                    //   } else {
                    //     data = `${data} (${player.substitution[0].subOnSurname} ${player.substitution[0].time}')`;
                    //   }
                    // }

                    return data;
                })
                .join(", ");

            return lineup;
        },

        getMatchEfl(data) {
            if (!(data && data.matchPlayers && data.matchPlayers.length)) {
                this.error =
                    "An error has occurred. No player data is available for this match.";
                return;
            }

            this.updatedData.content.homeTeamStarters = this.buildEflLineupString(
                data.matchPlayers,
                data.homeTeamID,
                true
            );

            this.updatedData.content.homeTeamSubs = this.buildEflLineupString(
                data.matchPlayers,
                data.homeTeamID,
                false
            );

            this.updatedData.content.awayTeamStarters = this.buildEflLineupString(
                data.matchPlayers,
                data.awayTeamID,
                true
            );

            this.updatedData.content.awayTeamSubs = this.buildEflLineupString(
                data.matchPlayers,
                data.awayTeamID,
                false
            );

            this.updatedData.content.shortHomeTeamName =
                data.homeTeam && data.homeTeam.displayTeamName;
            this.updatedData.content.shortAwayTeamName =
                data.awayTeam && data.awayTeam.displayTeamName;

            this.updatedData.content.homeTeamCrest =
                data.homeTeam && data.homeTeam.crestURL;

            this.updatedData.content.awayTeamCrest =
                data.awayTeam && data.awayTeam.crestURL;
        },

        updateData() {
            console.log("Update data:", this.updatedData);
            this.$emit("updatedData", this.updatedData);
            this.dialog = false;
        },

        validate() {
            this.errorMessage = "";
            if (this.$refs.singleFieldForm.validate()) {
                console.log("Validation passed");
                this.updateData();
            } else {
                this.errorMessage = "Certain entries are invalid or required";
            }
        },
    },
};
</script>

<style lang="scss" scoped>.widget {
    &__description {
        margin: 0 0 20px;
        color: #7b6b80;
        font-size: 14px;
    }
}

.spacer {
    margin-top: 16px;
}

.error-message {
    text-align: center;
    color: #f42323;
}
</style>
