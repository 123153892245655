<template>
  <div class="main-body">
    <MiniHeader v-if="alternativeMode !== 'selection'" :heading="''" :buttonText="'Create Calendar Item'" :hasButton="true" @buttonClicked="createItem()" />

    <div class="calendar-listing__filters">
      <v-select
        id="publish-state"
        class="calendar-listing__filter"
        :items="[
          {
            name: 'All',
            value: ''
          },
          {
            name: 'Published',
            value: 'published'
          },
          {
            name: 'Draft',
            value: 'draft'
          }
        ]"
        item-value="value"
        item-text="name"
        v-model="filteringBy.publishState"
        label="Publish state"
      ></v-select>

      <v-select
        id="type"
        class="calendar-listing__filter"
        :items="typeFilters"
        item-value="value"
        item-text="name"
        v-model="filteringBy.type"
        label="Type"
      ></v-select>

      <v-select
        id="schedule"
        class="calendar-listing__filter"
        :items="[
          {
            name: 'All',
            value: ''
          },
          {
            name: 'Upcoming',
            value: 'upcoming'
          },
          {
            name: 'Past',
            value: 'past'
          }
        ]"
        item-value="value"
        item-text="name"
        v-model="filteringBy.schedule"
        label="Schedule"
      ></v-select>
    </div>

    <ol class="calendar-listing__list">
      <li v-for="item in listing" :key="item.id" class="calendar-listing-item" :class="`calendar-listing-item--${item.attributes.type}`">
        <template v-if="item.attributes.imageData.landscape">
          <img v-if="item.attributes.imageData.landscape.imageKey" :src="`${imageDomain}/${item.attributes.imageData.landscape.imageKey}`" class="calendar-listing-item__image" />
        </template>

        <div class="calendar-listing-item__inner">
          <div class="calendar-listing-item__statuses">
            <p v-if="item.attributes.published" class="status status--published">Published</p>
            <p v-else class="status status--draft">Draft</p>
          </div>

          <h2 class="calendar-listing-item__subheading subheading">{{ item.attributes.title }}</h2>
          <p class="calendar-listing-item__label">Start: {{ item.attributes.startAt | moment('DD/MM/YY kk:mm (Z)') }}</p>
          <p class="calendar-listing-item__id" :class="`label label--${item.attributes.type}`">Type: {{ item.attributes.type }} {{ item.attributes.type == 'streamline' && item.attributes.streamlineData ? ` - ${item.attributes.streamlineData.streamType}` : '' }}</p>
        </div>
        <v-btn v-if="item.attributes.type !== 'match' && alternativeMode !== 'selection'" :to="`/calendar/${item.id}`">Edit</v-btn>
        <v-btn v-if="alternativeMode == 'selection' && item.id !== selectedItem" color="accent" @click="$emit('selected', item)">Select</v-btn>
        <v-btn v-if="alternativeMode == 'selection' && item.id == selectedItem" color="accent" outline @click="$emit('unselected')">Selected</v-btn>
      </li>
    </ol>
    <div class="pagination">
        <v-pagination
          v-model="pageNumber"
          :length="pageCount"
          :total-visible="4"
          circle
          color="accent">
        </v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import MiniHeader from "@/components/Library/MiniHeader"

export default {
  name: "CalendarListing",

  props: {
    alternativeMode: {
      type: String,
      required: false,
    },
    selectedItem: {
      type: String,
      required: false,
    }
  },

  components: {
    MiniHeader
  },

  watch: {
    pageNumber() {
      this.fetchItems()
    },
    filteringBy: {
      handler() {
        this.pageNumber = 1;
        this.fetchItems()
      },
      deep: true
    },
  },

  data:() => ({
    modules: null,
    imageDomain: process.env.VUE_APP_IMAGEURL,
    listing: [],
    filteringBy: {
      publishState: "",
      type: "",
      schedule: "upcoming",
    },
    pageNumber: 1,
    pageSize: 10,
    pageCount: null,
    typeFilters: [
      { name: "All", value: "" },
      { name: "Match", value: "match" },
      { name: "Event", value: "event" }
    ]
  }),

  created() {
    this.modules = require("@/config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
    
    if (this.modules.default.Streamline) {
      this.typeFilters.push(
        {
          name: "Streamline - Match",
          value: "streamline - match"
        },
        {
          name: "Streamline - Other",
          value: "streamline - other"
        }
      )
    }
  },

  mounted() {
    this.fetchItems()
  },

  methods: {
    createItem() {
      this.$router.push({ path: "/calendar/create-item" })
    },

    fetchItems() {
      let scheduleFilter = ""
      switch (this.filteringBy.schedule) {
        case "upcoming":
        scheduleFilter = `&filter[finishAt,gte,string]=${this.$moment.utc().format()}`
          break;
        case "past":
        scheduleFilter = `&filter[finishAt,lt,string]=${this.$moment.utc().format()}`
          break;
        default:
          break;
      }

      let publishFilter = ""
      switch (this.filteringBy.publishState) {
        case "published":
        publishFilter = "&filter[published,eq,number]=1"
          break;
        case "draft":
        publishFilter = "&filter[published,eq,number]=0"
          break;
        default:
          break;
      }

      let typeFilter = ""
      switch (this.filteringBy.type) {
        case "event":
        typeFilter = "&filter[type,eq,string]=event"
          break;
        case "match":
        typeFilter = "&filter[type,eq,string]=match"
          break;
        case "streamline - match":
        typeFilter = "&filter[streamlineData.streamType,eq,string]=match"
          break;
        case "streamline - other":
        typeFilter = "&filter[streamlineData.streamType,eq,string]=other"
          break;
        default:
          break;
      }

      console.log(`${process.env.VUE_APP_CALENDARAPI}/calendar?page[size]=${this.pageSize}&page[number]=${this.pageNumber}&sort=startAt${scheduleFilter}${publishFilter}${typeFilter}`)
      
      
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_CALENDARAPI}/calendar?page[size]=${this.pageSize}&page[number]=${this.pageNumber}&sort=startAt${scheduleFilter}${publishFilter}${typeFilter}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("calendar response", response)
          this.listing = response.data.data
          // This line is now handled in the frontend, at the end of the filterList function
          this.pageCount = response.data.meta.totalPages

        })
        .catch(e => {
          console.error("Problem retrieving calendar", e);
        });
      })
    },

    filterList() {
      let list = JSON.parse(JSON.stringify(this.listing))
      if (typeof this.filteringBy.publishState == "number") {
        list = JSON.parse(JSON.stringify(list.filter(
          element => element.attributes.published == this.filteringBy.publishState
        )))
      }
      
      if (this.filteringBy.type) {
        list = JSON.parse(JSON.stringify(list.filter(
          element => {
            if (element.attributes.type == "streamline" && element.attributes.streamlineData) {

              if (this.filteringBy.type == "streamline - match") {
                return element.attributes.streamlineData.streamType == "match"
              } else if (this.filteringBy.type == "streamline - other") {
                return element.attributes.streamlineData.streamType == "other"
              }

            } else {
              return element.attributes.type == this.filteringBy.type
            }
          }
        )))
      }
      this.filteredListing = JSON.parse(JSON.stringify(list));
    }
  }
}
</script>

<style scoped lang="scss">
  .calendar-listing {
    &__filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &__filter {
      margin: 0 16px 0 0;
      width: calc(50% - 16px);
    }

    &__list {
      padding: 0;
      margin: 20px 0 0;
    }
  }

  .calendar-listing-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 140px;
    margin: 16px 0 48px;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #dfe3e9;
    border-top: 4px solid #67247c;
    border-radius: 6px;
    &--event {
      border-top: 4px solid #C1922A;
    }
    &--match {
      border-top: 4px solid color(draftHighlight);

    }
    &--streamline {
      border-top: 4px solid #67247c;
    }

    &__image {
      max-width: 160px;
      width: 100%;
      margin-right: 32px;
    }

    &__inner {
      width: 100%;
    }

    &__id {
      margin: 0;
      font-size: 11px;
      opacity: 0.8;
      word-break: break-all;
    }

    &__subheading {
      margin: 0;
      padding: 5px 0 0;
    }

    &__label {
      margin: 4px 0;
      padding: 0;
      font-size: 12px;
      word-break: break-all;
    }

    &__statuses {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  .status {
    display: inline-block;
    padding: 3px 12px;
    margin: 0 8px 8px 0;
    font-size: 10px;
    color: #fff;
    background-color: #ccc;
    border-radius: 4px;

    &.status--draft {
      background-color: color(draftHighlight);
    }

    &.status--published {
      background-color: #67247c;
    }
  }

  .label {
    &--event {
      color: #C1922A;
    }
    &--match {
      color: color(draftHighlight);

    }
    &--streamline {
      color: #67247c;
    }
  }

</style>
