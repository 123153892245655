<template>
  <section>
    <div class="menu-name">
      <template v-if="editName">
        <v-text-field
          v-model="navigationName"
          placeholder="Navigation Name"
          solo
          flat
          required
        ></v-text-field>

        <v-btn
          color="accent"
          type="button"
          depressed
          @click="saveName()"
        >
        Done
      </v-btn>
      </template>

      <template v-else>
        <button class="menu-name__display" type="button" @click="allowEditName()">
          <v-icon small>edit</v-icon>
          <h3 class="menu-name__heading">{{ navigationName }}</h3>
        </button>
      </template>
    </div>

    <section class="edit-menu">
      <draggable
        v-model="navigation"
        :options="{
          group: 'menu',
          ghostClass: 'ghost',
          dragClass: 'sortable-drag',
        }">

          <transition-group name="list-complete" tag="ol">
            <li
            v-for="(level1Item, level1Index) in navigation"
            :key="level1Item.id" class="draggable-item">

            <DraggableMenuRow
              :key="level1Item.id"
              :name="level1Item.name"
              :slug="level1Item.slug"
              :allowChildCreation="true"
              @updateItem="updateItem($event, level1Index)"
              @deleteItem="deleteItem(level1Index)"
              @addChild="addChildItem(level1Index)"/>

              <draggable
                v-if="level1Item.menu"
                class="child-menu"
                :class="{'is-dragging': childMenuDragging}"
                v-model="level1Item.menu"
                :options="{
                  group: 'menu',
                  ghostClass: 'ghost',
                  dragClass: 'sortable-drag',
                }"
                @start="childMenuChosen"
                @end="childMenuUnchosen">

                <transition-group name="list-complete" tag="ol">
                  <li
                    v-for="(level2Item, level2Index) in level1Item.menu"
                    :key="level2Item.id" class="draggable-item">

                      <DraggableMenuRow
                        :name="level2Item.name"
                        :slug="level2Item.slug"
                        :allowChildCreation="true"
                        @updateItem="updateItem($event, level1Index, level2Index)"
                        @deleteItem="deleteItem(level1Index, level2Index)"
                        @addChild="addChildItem(level1Index, level2Index)"/>

                      <draggable
                        v-if="level2Item.menu"
                        class="child-menu"
                        :class="{'is-dragging': childMenuDragging}"
                        v-model="level2Item.menu"
                        :options="{
                          group: 'menu',
                          ghostClass: 'ghost',
                          dragClass: 'sortable-drag',
                        }"
                        @start="childMenuChosen"
                        @end="childMenuUnchosen">

                        <transition-group name="list-complete" tag="ol">
                          <li
                            v-for="(level3Item, level3Index) in level2Item.menu"
                            :key="level3Item.id" class="draggable-item">

                            <DraggableMenuRow
                              :name="level3Item.name"
                              :slug="level3Item.slug"
                              :allowChildCreation="false"
                              @updateItem="updateItem($event, level1Index, level2Index, level3Index)"
                              @deleteItem="deleteItem(level1Index, level2Index, level3Index)"/>

                          </li>
                        </transition-group>
                      </draggable>

                  </li>
                </transition-group>
              </draggable>

            </li>
          </transition-group>

      </draggable>

      <div class="bottom-right-button">
        <v-btn
          color="accent"
          type="button"
          depressed
          @click="addMenuItem()"
        >
          <v-icon small>add</v-icon>
          Add Menu Item
        </v-btn>
      </div>
    </section>

    <StickyFooter :errorMessage="errorMessage" :status="status" :buttonText="'Save Page'" @buttonClicked="saveMenu()" />

  </section>
</template>

<script>
import axios from "axios"
import draggable from "vuedraggable"
import DraggableMenuRow from "@/components/Navigation/DraggableMenuRow"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
  name: "NavigationSingle",

  components: {
    draggable,
    DraggableMenuRow,
    StickyFooter
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/navigations`,
    status: "saved",
    errorMessage: "",
    editName: false,
    childMenuDragging: false,
    navigationName: "",
    navigation: []
  }),

  updated() {
    console.log("this.navigation", this.navigation)
  },

  mounted() {
    console.log("Navigation page mounted", this.$route);
    this.errorMessage = "";

    this.$store.commit("startLoading");

    if (this.$route.name === "Create") {
      this.navigationName = "Example Menu";
      this.navigation = [
        {
          id: this.$uuid.v1(),
          name: "Menu item",
          slug: "/"
        }
      ]
    } else {

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}/${this.$route.params.id}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("Fetch: ", response);
          this.navigationName = response.data.body.name;
          this.navigation = response.data.body.menu;

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving menu", e);
          this.$store.commit("completeLoading");
          this.errorMessage = "Problem retrieving menu";
        });
      })

    }
  },

  methods: {
    addMenuItem() {
      this.status = "needsSaving";
  
      this.navigation.push({
        id: this.$uuid.v1(),
        name: "Menu item",
        slug: "/"
      })
    },

    updateItem($event, level1Index, level2Index, level3Index) {
      console.log(`Going to update item'  ${level1Index} ${level2Index} ${level3Index}`);

      const updateNav = this.navigation;

      if (level3Index != undefined) {
        updateNav[level1Index].menu[level2Index].menu[level3Index].name = $event.pageName;
        updateNav[level1Index].menu[level2Index].menu[level3Index].slug = $event.pageSlug;
      } else if (level2Index != undefined) {
        updateNav[level1Index].menu[level2Index].name = $event.pageName;
        updateNav[level1Index].menu[level2Index].slug = $event.pageSlug;
      } else if (level1Index != undefined) {
        updateNav[level1Index].name = $event.pageName;
        updateNav[level1Index].slug = $event.pageSlug;
      }
      this.navigation = updateNav;

      setTimeout(() => {
        this.status = "needsSaving";
      }, 300);
    },

    deleteItem(level1Index, level2Index, level3Index) {

      console.log("delete: ", level1Index, " ", level2Index, " ", level3Index);

      this.status = "needsSaving";

      if (level3Index != undefined) {
        console.log("deleteItem 1: ", level3Index);
        this.navigation[level1Index].menu[level2Index].menu.splice(level3Index, 1);
      } else if (level2Index != undefined) {
        console.log("deleteItem 2: ", level2Index);
        this.navigation[level1Index].menu.splice(level2Index, 1);
      } else if (level1Index != undefined) {
        console.log("deleteItem 3: ", level1Index);
        this.navigation.splice(level1Index, 1);
      }

      setTimeout(() => {
        this.status = "needsSaving";
      }, 300);
    },

    allowEditName() {
      this.editName = true;
    },

    saveName() {
      this.editName = false;
      this.status = "needsSaving";
    },

    saveMenu() {
      this.status = "saving";
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}`,
          headers: { "Authorization": jwt },
          data: {
            ...this.$route.params.id && {navigationID: this.$route.params.id,},
            name: this.navigationName,
            menu: this.navigation
          }
        })
        .then(response => {
          console.log("Saved menu:", response);
          const guid = response.data.guid;

          if (guid) {
            this.$router.push({ path: `/navigation/${response.data.guid}` })
          }
          this.status = "saved";
        })
        .catch(e => {
          console.error("Problem saving menu", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem saving menu";
        });
      })
    },

    childMenuChosen() {
      console.log("Child menu dragging");
      this.childMenuDragging = true;
    },

    childMenuUnchosen() {
      console.log("Child menu stopped dragging");
      this.childMenuDragging = false;
    },

    addChildItem(level1Index, level2Index) {
      this.status = "needsSaving";

      if (level2Index != undefined) {
        const nav = this.navigation[level1Index].menu[level2Index];

        console.log("nav", nav);

        if (nav.menu) {
          nav.menu.push({
            id: this.$uuid.v1(),
            name: `${nav.name} - subitem`,
            slug: `${nav.slug}/subitem`
          });
        } else {
          nav.menu = [{
            id: this.$uuid.v1(),
            name: `${nav.name} - subitem`,
            slug: `${nav.slug}/subitem`
          }];
        }

      } else if (level1Index != undefined) {
        const nav = this.navigation[level1Index];

        console.log("nav", nav);

        if (nav.menu) {
          nav.menu.push({
            id: this.$uuid.v1(),
            name: `${nav.name} - subitem`,
            slug: `${nav.slug}/subitem`
          });
        } else {
          nav.menu = [{
            id: this.$uuid.v1(),
            name: `${nav.name} - subitem`,
            slug: `${nav.slug}/subitem`
          }];
        }
      }

      setTimeout(() => {
        this.status = "needsSaving";
      }, 300);

      console.log("Updated nav: ", this.navigation);
    }
  }
}
</script>

<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .2s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .list-complete-enter, .list-complete-leave-to {
    opacity: 0;
  }

  .list-complete-leave-active {
    position: absolute;
    width: calc(100% - (var(--body-gutter) * 2));
  }

  .draggable-item {
    transition: all 0.5s, opacity 0.2s;
  }

  .ghost /deep/ .draggable-item__row {
    background-color: rgba(103, 36, 124, 0.2);
  }

  .sortable-chosen /deep/ .draggable-item__row {
    border: 2px solid #67247C;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.1);
  }

  .edit-menu {
    padding: 15px;
    width: 100%;
    background-color: #efefef;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .menu-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 15px;

    &__display {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &__heading {
      margin: 0 0 0 10px;
      color: #354052;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .child-menu {
    margin: 0 0 0 80px;
    padding: 0;
    transition: padding 0.2s;

    &.is-dragging {
      padding: 5px 15px;
      background-color: rgba(197, 188, 188, 0.3);
      border: 1px dashed #ccc;
    }
  }
</style>

