<template>
  <section class="page-content">
    <v-tabs v-model="active">

      <v-tab :ripple="{ class: 'accent--text' }">Live Streaming</v-tab>
      <v-tab-item px-0 class="main-body">
        <live-streaming-listing v-if="active === 0" />
      </v-tab-item>

      <v-tab :ripple="{ class: 'accent--text' }">Ondemand Media</v-tab>
      <v-tab-item px-0 class="main-body">
        <video-listing v-if="active === 1" />
      </v-tab-item>

      <v-tab :ripple="{ class: 'accent--text' }">Packages</v-tab>
      <v-tab-item px-0 class="main-body">
        <packages-listing v-if="active === 2" />
      </v-tab-item>

      <v-tab :ripple="{ class: 'accent--text' }">Entitlements</v-tab>
      <v-tab-item px-0 class="main-body">
        <entitlements-manager v-if="active === 3" />
      </v-tab-item>

      <v-tab v-show="moduleConfig.default.StreamlineAdverts" :ripple="{ class: 'accent--text' }">Advertising</v-tab>
      <v-tab-item v-show="moduleConfig.default.StreamlineAdverts" px-0 class="main-body">
        <template v-if="active === 4">
          <streamline-advertising-manager v-if="moduleConfig.default.StreamlineAdverts" />
          <p v-else>This module has not been enabled</p>
        </template>
      </v-tab-item>

      <v-tab :ripple="{ class: 'accent--text' }">Admin Tools</v-tab>
      <v-tab-item px-0 class="main-body">
        <template v-if="active === 5">
          <georestriction-profiles-manager />
          <emergency-bypass />
        </template>
      </v-tab-item>
    </v-tabs>
  </section>
</template>

<script>
import VideoListing from "@/components/Streamline/VideoListing"
import LiveStreamingListing from "@/components/Streamline/LiveStreamingListing"
import PackagesListing from "@/components/Streamline/PackagesListing"
import EmergencyBypass from "@/components/Streamline/Tools/EmergencyBypass"
import EntitlementsManager from "@/components/Streamline/EntitlementsManager"
import StreamlineAdvertisingManager from "@/components/Streamline/StreamlineAdvertisingManager"
import GeorestrictionProfilesManager from "@/components/Streamline/Tools/GeorestrictionProfiles/GeorestrictionProfilesManager"

export default {
  name: "VideoManagement",

  components: {
    VideoListing,
    LiveStreamingListing,
    PackagesListing,
    EmergencyBypass,
    StreamlineAdvertisingManager,
    EntitlementsManager,
    GeorestrictionProfilesManager
  },

  data:() => ({
    active: null,
    moduleConfig: null
  }),

  created() {
    this.moduleConfig = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
  },
}
</script>

<style scoped lang="scss">

</style>
