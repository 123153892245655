<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <p class="widget-info">
        This widget displays a list of all your published categories in a
        carousel, with an optional link button.
      </p>

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()"
        >Use this widget</v-btn
      >
    </template>

    <div class="widget-fieldset" v-else>
      <p class="warning-text">
        Category card content is controlled from the Global Settings, inside
        <v-btn
          :to="'/video-management/config?tab=2'"
          tag="a"
          text
          outline
          small
          flat
          class="accent"
          >Categories</v-btn
        ><br />Please use this section to edit Text and Images.
      </p>

      <label class="label">Title</label>
      <v-text-field
        id="widgetData.title"
        solo
        flat
        v-model="updatedData.widgetData.title"
      >
      </v-text-field>

      <template v-if="$route.query.isApp !== 'true'">
        <label class="label">Style</label>
        <v-select
          id="widgetData.style"
          :items="styleOptions"
          solo
          flat
          v-model="updatedData.widgetData.style"
        >
        </v-select>

        <v-layout row v-if="modules.default.LFCExclusive">
          <v-flex mr-2>
            <ImageUploader
              :title="'Background image (optional)'"
              :widthOptionEnabled="false"
              :mediaLibraryID="
                updatedData.widgetData.imageData
                  ? updatedData.widgetData.imageData.mediaLibraryID
                  : ''
              "
              @update="updateImage($event)"
            />
          </v-flex>
        </v-layout>

        <label class="label">Button URL</label>
        <v-text-field
          id="widgetData.destinationUrl"
          solo
          flat
          v-model="updatedData.widgetData.destinationUrl"
        >
        </v-text-field>

        <label class="label">Button Text</label>
        <v-text-field
          id="widgetData.destinationLinkText"
          solo
          flat
          v-model="updatedData.widgetData.destinationLinkText"
        >
        </v-text-field>

        <template v-if="modules.default.LFCExclusive">
          <label class="label"
            >Categories (if none is chosen, all will be displayed)</label
          >
          <template
            v-if="
              categories &&
              categories.length &&
              updatedData.widgetData &&
              updatedData.widgetData.categories
            "
          >
            <p
              @click="handleSelection(category)"
              class="category"
              :class="{
                'category--active':
                  updatedData.widgetData.categoriesID.includes(category.id),
              }"
              v-for="category in categories"
              :key="category.id"
            >
              {{ category.attributes.name }}
            </p>

            <div class="selected-categories">
              <label class="label"
                >Selected categories (drag and drop to reorder)</label
              >
              <template
                v-if="
                  updatedData.widgetData.categories &&
                  updatedData.widgetData.categories.length
                "
              >
                <draggable
                  v-model="updatedData.widgetData.categories"
                  :options="{
                    ghostClass: 'ghost',
                    dragClass: 'sortable-drag',
                  }"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <transition-group
                    name="list-complete"
                    class="featured__items"
                  >
                    <p
                      class="category category--selected"
                      v-for="category in updatedData.widgetData.categories"
                      :key="category.id"
                    >
                      {{ category.attributes.title }}
                      <v-icon
                        @click="handleSelection(category)"
                        class="remove-icon"
                        color="accent"
                        right
                        >remove_circle</v-icon
                      >
                    </p>
                  </transition-group>
                </draggable>
              </template>
              <p v-else>No Categories selected</p>
            </div>
          </template>
          <p v-else>Loading...</p>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ImageUploader from "@/components/Library/ImageUploader";
import draggable from "vuedraggable";

export default {
  name: "VideoCategories",

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent();
      },
      deep: true,
    },
  },

  props: {
    editData: {
      type: Object,
      required: true,
    },
  },

  components: {
    ImageUploader,
    draggable,
  },

  data: () => ({
    styleOptions: [
      { text: "Light Mode", value: "light" },
      { text: "Dark Mode", value: "dark" },
    ],
    updatedData: {
      title: null,
      style: null,
      imageData: null,
      destinationUrl: null,
      destinationLinkText: null,
      categories: [],
      categoriesID: [],
    },
    api: process.env.VUE_APP_VIDEOS_API,
    modules: null,
    categories: [],
  }),

  mounted() {
    this.modules = require("../../../../../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
    if (["LFC"].includes(process.env.VUE_APP_NAME)) {
      this.styleOptions.push({ text: "Club Mode", value: "club" });
    }

    this.updatedData = JSON.parse(JSON.stringify(this.editData));
    this.getCategories();
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "CategoryCarousel";
      this.updatedData.widgetName = "Categories";
      this.updatedData.widgetID = this.$uuid.v1();
      this.updatedData.widgetData = {
        title: null,
        style: null,
        imageData: null,
        destinationUrl: null,
        destinationLinkText: null,
        categories: [],
        categoriesID: [],
      };
      this.$emit("initialised");
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData);
    },

    updateImage(image) {
      this.updatedData.widgetData.imageData = image;
    },

    getCategories() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/categories`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log("retrieved CATEGORIES settings:", response.data.data);
            this.categories = response.data.data;
          })
          .catch((e) => {
            console.error("Problem getting CATEGORIES settings", e);
          });
      });
    },

    handleSelection(category) {
      const updatedCategories = {
        id: category.id,
        attributes: {
          title: category.attributes.name,
        },
      };

      const index = this.updatedData.widgetData.categoriesID.indexOf(
        category.id
      );

      if (index !== -1) {
        this.updatedData.widgetData.categories.splice(index, 1);
        this.updatedData.widgetData.categoriesID.splice(index, 1);
      } else {
        this.updatedData.widgetData.categories.push(updatedCategories);
        this.updatedData.widgetData.categoriesID.push(updatedCategories.id);
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.warning-text {
  background-color: #f4f6d2;
  padding: 8px 16px;
  border: 1px solid #d6d8b0;
  border-radius: 8px;
  margin-bottom: 16px;
}

.category {
  background: #fff;
  padding: 4px;
  border-radius: 4px;
  border: 2px solid #fff;
  cursor: pointer;
  margin-bottom: 8px;

  &--active {
    border: 2px solid #67247c;
  }

  &--selected {
    display: flex;
    justify-content: space-between;
    cursor: move;
  }
}

.selected-categories {
  margin-top: 26px;
  margin-bottom: 26px;
}
</style>
