export default {
  data: [
    {
      sectionName: "App - Top Stories",
      sectionDesc: "Recommended image sizes for this advertising slot are 3:1 (1200px by 400px) or 6:1 (1200px by 200px)",
      model: "advert_topstories",
      fields: [
        {
          label: "Enable this advert",
          model: "topstories_enabled",
          type: "Checkbox"
        },
        {
          label: "Link",
          model: "topstories_link",
          type: "Text"
        },
        {
          label: "Advert",
          model: "topstories_image",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "App - Favourite Players",
      sectionDesc: "Recommended image sizes for this advertising slot are 3:1 (1200px by 400px) or 6:1 (1200px by 200px)",
      model: "advert_favouriteplayers",
      fields: [
        {
          label: "Enable this advert",
          model: "favouriteplayers_enabled",
          type: "Checkbox"
        },
        {
          label: "Link",
          model: "favouriteplayers_link",
          type: "Text"
        },
        {
          label: "Advert",
          model: "favouriteplayers_image",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "App - Everton TV",
      sectionDesc: "Recommended image sizes for this advertising slot are 3:1 (1200px by 400px) or 6:1 (1200px by 200px)",
      model: "advert_evertontv",
      fields: [
        {
          label: "Enable this advert",
          model: "evertontv_enabled",
          type: "Checkbox"
        },
        {
          label: "Link",
          model: "evertontv_link",
          type: "Text"
        },
        {
          label: "Advert",
          model: "evertontv_image",
          type: "Image"
        }
      ]
    }
  ]
}
