export default {
    footerSponsors: true,

    loadingScreen_App: true,
    homepageNews_App: true,
    newsListing_App: true,
    matchCentre_FirstTeam_App: true,
    fixturesPage_FirstTeam_App: true,
    playerOfTheMatch_App: true,
    scorePredictor_App: true,

    homepageNews_Web: true,
    newsListing_Web: true,
    matchCentre_FirstTeam_Web: true,
    fixturesPage_FirstTeam_Web: true,
    playerOfTheMatch_Web: true,
}
