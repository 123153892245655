<script>
export default [{
    "optaId": 38806,
    "name": "Janice Witt",
    "position": "Goalkeeper",
    "squadNumber": 24
  },
  {
    "optaId": 58607,
    "name": "Helga Delacruz",
    "position": "Forward",
    "squadNumber": 39
  },
  {
    "optaId": 4347,
    "name": "Angie Acevedo",
    "position": "Wingback",
    "squadNumber": 27
  },
  {
    "optaId": 67892,
    "name": "Madelyn Hammond",
    "position": "Winger",
    "squadNumber": 18
  },
  {
    "optaId": 54787,
    "name": "Estela Kaufman",
    "position": "Goalkeeper",
    "squadNumber": 21
  },
  {
    "optaId": 58866,
    "name": "Dorothy Pittman",
    "position": "Defensive Midfielder",
    "squadNumber": 1
  },
  {
    "optaId": 16859,
    "name": "Sofia Green",
    "position": "Goalkeeper",
    "squadNumber": 31
  },
  {
    "optaId": 56464,
    "name": "Beverley Norton",
    "position": "Winger",
    "squadNumber": 15
  },
  {
    "optaId": 41456,
    "name": "Shelly Carter",
    "position": "Centeral Defender",
    "squadNumber": 9
  },
  {
    "optaId": 19986,
    "name": "Gabriela Mclaughlin",
    "position": "Defensive Midfielder",
    "squadNumber": 21
  },
  {
    "optaId": 54048,
    "name": "Tanya Garner",
    "position": "Goalkeeper",
    "squadNumber": 36
  },
  {
    "optaId": 54901,
    "name": "Dale Coleman",
    "position": "Forward",
    "squadNumber": 28
  },
  {
    "optaId": 55408,
    "name": "Susanne Beasley",
    "position": "Defensive Midfielder",
    "squadNumber": 16
  },
  {
    "optaId": 42126,
    "name": "Rhonda Gutierrez",
    "position": "Winger",
    "squadNumber": 10
  },
  {
    "optaId": 18125,
    "name": "Pam Valentine",
    "position": "Central Midfielder",
    "squadNumber": 31
  },
  {
    "optaId": 33660,
    "name": "Ladonna Talley",
    "position": "Central Midfielder",
    "squadNumber": 21
  },
  {
    "optaId": 30517,
    "name": "Amparo Murray",
    "position": "Central Midfielder",
    "squadNumber": 39
  },
  {
    "optaId": 10850,
    "name": "Beverly Carney",
    "position": "Centeral Defender",
    "squadNumber": 35
  },
  {
    "optaId": 53992,
    "name": "Lesley Hurst",
    "position": "Attacking Midfielder",
    "squadNumber": 40
  },
  {
    "optaId": 16604,
    "name": "Alyssa Gentry",
    "position": "Wingback",
    "squadNumber": 23
  },
  {
    "optaId": 27602,
    "name": "Evelyn Gillespie",
    "position": "Winger",
    "squadNumber": 8
  },
  {
    "optaId": 54378,
    "name": "Pansy Wright",
    "position": "Wingback",
    "squadNumber": 18
  },
  {
    "optaId": 77751,
    "name": "Carole Perkins",
    "position": "Defensive Midfielder",
    "squadNumber": 1
  },
  {
    "optaId": 10065,
    "name": "Carlene Meyer",
    "position": "Forward",
    "squadNumber": 32
  },
  {
    "optaId": 40238,
    "name": "Concepcion Burris",
    "position": "Winger",
    "squadNumber": 32
  }
]
</script>
