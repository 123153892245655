export default {
  data: [
    // {
    //   sectionName: 'Homepage highlight - left',
    //   sectionDesc: 'This highlight will only be used when there is no "next match" data',
    //   model: 'highlight_left',
    //   fields: [
    //     {
    //       label: 'Title',
    //       model: 'highlight_left_title',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Text',
    //       model: 'highlight_left_text',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Link',
    //       model: 'highlight_left_link',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Link text',
    //       model: 'highlight_left_linktext',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Image',
    //       model: 'highlight_left_image',
    //       type: 'Image'
    //     }
    //   ]
    // },
    // {
    //   sectionName: 'Homepage highlight - centre',
    //   sectionDesc: '',
    //   model: 'highlight_center',
    //   fields: [
    //     {
    //       label: 'Title',
    //       model: 'highlight_center_title',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Text',
    //       model: 'highlight_center_text',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Link',
    //       model: 'highlight_center_link',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Link text',
    //       model: 'highlight_center_linktext',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Image',
    //       model: 'highlight_center_image',
    //       type: 'Image'
    //     }
    //   ]
    // },
    // {
    //   sectionName: 'Homepage highlight - right',
    //   sectionDesc: '',
    //   model: 'highlight_right',
    //   fields: [
    //     {
    //       label: 'Title',
    //       model: 'highlight_right_title',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Text',
    //       model: 'highlight_right_text',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Link',
    //       model: 'highlight_right_link',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Link text',
    //       model: 'highlight_right_linktext',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Image',
    //       model: 'highlight_right_image',
    //       type: 'Image'
    //     }
    //   ]
    // },
    // {
    //   sectionName: 'Homepage promo - left',
    //   sectionDesc: 'This is for the left hand side (top on smaller screens) promotional area on the homepage',
    //   model: 'promo_left',
    //   fields: [
    //     {
    //       label: 'Title',
    //       model: 'promo_left_promotitle',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Description',
    //       model: 'promo_left_promotext',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo link',
    //       model: 'promo_left_promolink',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo link text',
    //       model: 'promo_left_promolinktext',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo image',
    //       model: 'promo_left_promoimage',
    //       type: 'Image'
    //     }
    //   ]
    // },
    // {
    //   sectionName: 'Homepage promo - right',
    //   sectionDesc: 'This is for the right hand side (bottom on smaller screens) promotional area on the homepage',
    //   model: 'promo_right',
    //   fields: [
    //     {
    //       label: 'Title',
    //       model: 'promo_right_promotitle',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Description',
    //       model: 'promo_right_promotext',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo link',
    //       model: 'promo_right_promolink',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo link text',
    //       model: 'promo_right_promolinktext',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo image',
    //       model: 'promo_right_promoimage',
    //       type: 'Image'
    //     }
    //   ]
    // },
    // {
    //   sectionName: 'Homepage store promo - left',
    //   sectionDesc: 'This is for the left hand side (top on smaller screens) store promotional area on the homepage',
    //   model: 'store_promo_left',
    //   fields: [
    //     {
    //       label: 'Promo link',
    //       model: 'store_promo_left_promolink',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo link text',
    //       model: 'store_promo_left_promolinktext',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo image',
    //       model: 'store_promo_left_promoimage',
    //       type: 'Image'
    //     }
    //   ]
    // },
    // {
    //   sectionName: 'Homepage store promo - right',
    //   sectionDesc: 'This is for the right hand side (bottom on smaller screens) store promotional area on the homepage',
    //   model: 'store_promo_right',
    //   fields: [
    //     {
    //       label: 'Title',
    //       model: 'store_promo_right_promotitle',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Description',
    //       model: 'store_promo_right_promotext',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo link',
    //       model: 'store_promo_right_promolink',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo link text',
    //       model: 'store_promo_right_promolinktext',
    //       type: 'Text'
    //     },
    //     {
    //       label: 'Promo image - kits',
    //       model: 'store_promo_right_promoimage',
    //       type: 'Image'
    //     }
    //   ]
    // },
  ]
}
