<template>
  <v-form>
    <v-flex>
      <label class="label">Query Type</label>
      <v-select
        :items="queryTypes"
        v-model="query.queryType"
        flat
        solo
        @change="handleQueryType()"
      ></v-select>

      <!-- <template v-if="query.queryType == 'section'">
        <label>Query Scope</label>
        <v-text-field v-model="query.queryScope" validate-on-blur placeholder="" solo flat></v-text-field>
      </template> -->

      <!-- <template v-else-if="query.queryType == 'search'"> -->
      <template v-if="query.queryType == 'search'">
        <template v-if="tidyCategories.length">
          <label class="label">Category</label>
          <v-select
            v-if="categories"
            :items="tidyCategories"
            v-model="query.category"
            flat
            solo
            clearable
          ></v-select>
        </template>
        <p v-else-if="categoriesLoading">Loading...</p>

        <template v-if="teams">
          <label class="label">Team</label>
          <v-select
            v-if="teams"
            :items="teams"
            item-text="teamName"
            v-model="query.team"
            flat
            solo
            clearable
          ></v-select>
        </template>
        <p v-else>Loading...</p>

        <label class="label">Search String</label>
        <v-text-field
          v-model="query.searchString"
          placeholder=""
          solo
          flat
        ></v-text-field>
        <!-- </template> -->
      </template>
      <template v-else-if="query.queryType == 'playlist'">
        <p v-if="!playlists.length">Loading...</p>

        <template v-else>
          <label class="label">Playlist</label>
          <v-select
            :items="playlists"
            v-model="query.playlist"
            item-text="attributes.title"
            item-value="id"
            flat
            solo
            clearable
          ></v-select
        ></template>
      </template>
    </v-flex>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  name: "QueryBuilder",

  props: {
    queryData: {
      type: Object,
      required: false,
    },
    additionalQueryTypes: {
      type: Array,
      required: false,
    },
  },

  data: () => ({
    query: {
      queryType: null, // search || section
      queryScope: null, // only used when using queryType section
      category: null, // only used when using queryType search
      team: null, // only used when using queryType search
      searchString: null, // only used when using queryType search
      playlist: null, // only used when using queryType playlist
    },
    queryTypes: [
      { text: "Search", value: "search" },
      // {text: 'Section', value: 'section'}
    ],
    categories: null,
    tidyCategories: [],
    teams: null,
    api: process.env.VUE_APP_VIDEOS_API,
    playlistApi: `${process.env.VUE_APP_STREAMLINEAPI}/playlists`,
    playlists: [],
    categoriesLoading: false,
  }),

  created() {
    this.getCategories();
    this.getTeams();
  },

  mounted() {
    if (this.additionalQueryTypes && this.additionalQueryTypes.length) {
      if (this.additionalQueryTypes.includes("playlist")) {
        this.queryTypes.push({ text: "Playlist", value: "playlist" });
      }
    }

    if (this.queryData) {
      const { queryType, category, team, searchString, queryScope, playlist } =
        JSON.parse(JSON.stringify(this.queryData));
      this.query = {
        queryType,
        category,
        team,
        searchString,
        queryScope,
        playlist,
      };
    }

    this.handleQueryType();
  },

  watch: {
    query: {
      handler: function () {
        if (this.query.category) {
          this.query.category = this.query.category.split("=").pop();
        }
        this.$emit("updateQuery", this.query);
      },
      deep: true,
    },
  },

  methods: {
    getCategories() {
      this.categoriesLoading = true;
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/categories`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            this.categoriesLoading = false;
            console.log("retrieved CATEGORIES settings:", response.data.data);
            this.categories = response.data.data;

            for (const category of this.categories) {
              this.tidyCategories.push(category.attributes.name);
            }
          })
          .catch((e) => {
            this.categoriesLoading = false;
            console.error("Problem getting CATEGORIES settings", e);
            this.status = "needsSaving";
          });
      });
    },

    getTeams() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${process.env.VUE_APP_BASEURL}/fixtures/opta/filters`, {
            headers: { Authorization: jwt },
          })
          .then((response) => {
            console.log("getTeams: ", response);
            this.teams = response.data.body;

            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem retrieving fixtures", e);
            this.$store.commit("completeLoading");
          });
      });
    },

    handleQueryType() {
      if (this.query.queryType == "playlist") {
        this.fetchPlaylists();
      }
    },

    fetchPlaylists() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.playlistApi}`,
          headers: { "Content-Type": "application/json", Authorization: jwt },
        })
          .then((response) => {
            console.log("Playlist response:", response);
            this.playlists = response.data.data;
          })
          .catch((e) => {
            console.error("Problem fetching playlist", e);
          });
      });
    },
  },
};
</script>

<style scoped lang='scss'>
</style>
