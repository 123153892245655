<template>
    <v-dialog
        v-model="dialog"
        width="580"
        lazy
    >
        <slot slot="activator"></slot>

        <v-card>
            <div class="widget-list__header">
                <button
                    v-if="widgetList !== 'main'"
                    class="widget-list__back-button"
                    type="button"
                    @click="backToMain()"
                >
                    <v-icon
                        color="#67247C"
                        small
                    >arrow_back_ios</v-icon>
                    Back
                </button>
            </div>

            <div class="widget-list">
                <!-- Main Widget List -->
                <template v-if="widgetList === 'main'">
                    <!-- Key Content Widgets -->
                    <div class="main-widgets">
                        <template v-if="widgetConfig.default.TextBlockWidget.enabled">
                            <TextBlockWidget
                                :includeDouble="widgetConfig.default.TextBlockWidget.includeDouble
                                    "
                                :customFont="widgetConfig.default.TextBlockWidget.customFont"
                                :availableFonts="widgetConfig.default.TextBlockWidget.availableFonts
                                    "
                                class="widget-list__item"
                                @updatedData="
                                    addWidget($event, 'Text Block', 'TextBlockWidget')
                                    "
                            >
                                <span class="widget-list__button">
                                    <v-icon
                                        color="#67247C"
                                        class="widget-list__icon widget-list__icon--custom widget-list__icon--text"
                                    ></v-icon>
                                    Text Block
                                </span>
                            </TextBlockWidget>
                        </template>

                        <template v-if="widgetConfig.default.ImageWidget.enabled">
                            <ImageWidget
                                @imageUploaded="addImage($event, 'Image', 'ImageWidget')"
                                class="widget-list__item"
                            >
                                <span class="widget-list__button">
                                    <v-icon
                                        color="#67247C"
                                        class="widget-list__icon widget-list__icon--custom widget-list__icon--photo"
                                    ></v-icon>
                                    Image
                                </span>
                            </ImageWidget>
                        </template>

                        <template v-if="widgetConfig.default.subMenus.Video">
                            <div class="widget-list__item">
                                <button
                                    class="widget-list__button"
                                    type="button"
                                    @click="openSubComponents('video')"
                                >
                                    <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--video"></v-icon>
                                    Video
                                </button>
                            </div>
                        </template>
                    </div>
                    <!-- /Key Content Widgets -->

                    <template v-if="widgetConfig.default.AccordionWidget.enabled">
                        <AccordionWidget
                            class="widget-list__item"
                            @updatedData="
                                addAccordion($event, 'Accordion', 'AccordionWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--list_alt"></v-icon>
                                Accordion
                            </span>
                        </AccordionWidget>
                    </template>

                    <template v-if="widgetConfig.default.CalendarWidget &&
                        widgetConfig.default.CalendarWidget.enabled
                        ">
                        <div class="widget-list__item">
                            <button
                                class="widget-list__button"
                                type="button"
                                @click="openSubComponents('calendar')"
                            >
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--calendar"></v-icon>
                                Calendar
                            </button>
                        </div>
                    </template>

                    <template v-if="widgetConfig.default.AdvertisingWidget &&
                        widgetConfig.default.AdvertisingWidget.enabled
                        ">
                        <AdvertisingWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'Advertising', 'AdvertisingWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <icon-selector
                                    class="header-icon widget-list__icon"
                                    icon="Slot"
                                />
                                Advertising
                            </span>
                        </AdvertisingWidget>
                    </template>

                    <template v-if="widgetConfig.default.BlogWidget && widgetConfig.default.BlogWidget.enabled">
                        <BlogWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'Blog', 'BlogWidget')"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom">feed</v-icon>
                                Blog
                            </span>
                        </BlogWidget>
                    </template>

                    <template v-if="widgetConfig.default.ContactWidget.enabled">
                        <ContactWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'Contact', 'ContactWidget')"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--envelope"></v-icon>
                                Contact
                            </span>
                        </ContactWidget>
                    </template>

                    <template v-if="widgetConfig.default.CtaWidget.enabled">
                        <CtaWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'Call To Action', 'CtaWidget')"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--promo"></v-icon>
                                Call To Action
                            </span>
                        </CtaWidget>
                    </template>
                    <template v-if="widgetConfig.default.DocWidget &&
                        widgetConfig.default.DocWidget.enabled
                        ">
                        <DocWidget
                            @updatedData="
                                addWidget($event, 'Documentation Widget', 'DocWidget')
                                "
                            class="widget-list__item"
                        >
                            <span class="widget-list__button">
                                <v-icon
                                    color="#67247C"
                                    class="widget-list__icon widget-list__icon--custom widget-list__icon--file"
                                >description
                                </v-icon>
                                Documentation
                            </span>
                        </DocWidget>
                    </template>

                    <template v-if="widgetConfig.default.FileDownloadWidget &&
                        widgetConfig.default.FileDownloadWidget.enabled
                        ">
                        <FileDownloadWidget
                            @fileUploaded="
                                addWidget($event, 'File Download', 'FileDownloadWidget')
                                "
                            class="widget-list__item"
                        >
                            <span class="widget-list__button">
                                <v-icon
                                    color="#67247C"
                                    class="widget-list__icon widget-list__icon--custom widget-list__icon--file"
                                ></v-icon>
                                File
                            </span>
                        </FileDownloadWidget>
                    </template>

                    <template v-if="widgetConfig.default.subMenus.Fixtures">
                        <div class="widget-list__item">
                            <button
                                class="widget-list__button"
                                type="button"
                                @click="openSubComponents('fixtures')"
                            >
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--pitch"></v-icon>
                                Fixtures/Results
                            </button>
                        </div>
                    </template>

                    <div
                        class="widget-list__item"
                        v-if="widgetConfig.default.FormWidget.enabled ||
                            widgetConfig.default.GoogleFormsWidget.enabled ||
                            widgetConfig.default.FormAssemblyWidget.enabled
                            "
                    >
                        <button
                            class="widget-list__button"
                            type="button"
                            @click="openSubComponents('forms')"
                        >
                            <v-icon class="widget-list__icon">alternate_email</v-icon>
                            Forms
                        </button>
                    </div>

                    <!-- <template v-if="widgetConfig.default.FormWidget && moduleConfig.default.Forms">
              <FormWidget
                class="widget-list__item"
                @updatedData="addWidget($event, 'Form', 'FormWidget')"
                >
                <span class="widget-list__button">
                  <v-icon class="widget-list__icon">alternate_email</v-icon>
                  Forms
                </span>
              </FormWidget>
            </template> -->

                    <template v-if="widgetConfig.default.HorizontalLineWidget.enabled">
                        <HorizontalLineWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'Horizontal Line', 'HorizontalLineWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--line"></v-icon>
                                Horizontal Line
                            </span>
                        </HorizontalLineWidget>
                    </template>

                    <template v-if="widgetConfig.default.ImageGalleryWidget.enabled">
                        <ImageGalleryWidget
                            @imageArrayUploaded="
                                addImages($event, 'Image', 'ImageGalleryWidget')
                                "
                            class="widget-list__item"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--gallery"></v-icon>
                                Gallery
                            </span>
                        </ImageGalleryWidget>
                    </template>

                    <template v-if="widgetConfig.default.ImageGalleryWidgetV2 &&
                        widgetConfig.default.ImageGalleryWidgetV2.enabled
                        ">
                        <ImageGalleryWidget
                            @imageArrayUploaded="
                                addImages($event, 'Image', 'ImageGalleryWidget')
                                "
                            class="widget-list__item"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--gallery"></v-icon>
                                Gallery 2
                            </span>
                        </ImageGalleryWidget>
                    </template>

                    <template v-if="widgetConfig.default.ListWidgetV2.enabled">
                        <ListWidgetV2
                            @updatedData="addList($event, 'List', 'ListWidgetV2')"
                            class="widget-list__item"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--list"></v-icon>
                                List
                            </span>
                        </ListWidgetV2>
                    </template>

                    <template v-if="widgetConfig.default.subMenus.News">
                        <div class="widget-list__item">
                            <button
                                class="widget-list__button"
                                type="button"
                                @click="openSubComponents('news')"
                            >
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--news"></v-icon>
                                News
                            </button>
                        </div>
                    </template>

                    <!-- <template v-if="widgetConfig.default.NewsCategoryWidget.enabled">
              <NewsCategoryWidget
                class="widget-list__item"
                @updatedData="addWidget($event, 'News Category', 'NewsCategoryWidget')"
                >
                <span class="widget-list__button">
                  <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--news"></v-icon>
                  News Category Widget
                </span>
              </NewsCategoryWidget>
            </template> -->

                    <template v-if="widgetConfig.default.subMenus.Organisations">
                        <div class="widget-list__item">
                            <button
                                class="widget-list__button"
                                type="button"
                                @click="openSubComponents('organisations')"
                            >
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--organisations"></v-icon>
                                Organisations
                            </button>
                        </div>
                    </template>

                    <template v-if="widgetConfig.default.MapDirectoryWidget &&
                        widgetConfig.default.MapDirectoryWidget.enabled
                        ">
                        <MapDirectoryWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'Map Directory', 'SupportersClubWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--organisations"></v-icon>
                                Map
                            </span>
                        </MapDirectoryWidget>
                    </template>

                    <template v-if="widgetConfig.default.PlayerOfTheMatchWidget.enabled &&
                        moduleConfig.default.PlayerVoting
                        ">
                        <PlayerOfTheMatchWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget(
                                    $event,
                                    'Player of the match',
                                    'PlayerOfTheMatchWidget'
                                )
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon">person</v-icon>
                                Player of the match
                            </span>
                        </PlayerOfTheMatchWidget>
                    </template>

                    <template v-if="widgetConfig.default.subMenus.Promotions">
                        <div class="widget-list__item">
                            <button
                                class="widget-list__button"
                                type="button"
                                @click="openSubComponents('promotions')"
                            >
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--promo"></v-icon>
                                Promotions
                            </button>
                        </div>
                    </template>

                    <template v-if="widgetConfig.default.subMenus.Gallery">
                        <div class="widget-list__item">
                            <button
                                class="widget-list__button"
                                type="button"
                                @click="openSubComponents('gallery')"
                            >
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--gallery"></v-icon>
                                Gallery
                            </button>
                        </div>
                    </template>

                    <template v-if="widgetConfig.default.QuoteWidget.enabled">
                        <QuoteWidget
                            class="widget-list__item"
                            :widgetName="'Quote'"
                            @updatedData="addWidget($event, 'Quote', 'QuoteWidget')"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--quote"></v-icon>
                                Quote
                            </span>
                        </QuoteWidget>
                    </template>

                    <template v-if="widgetConfig.default.SharePriceWidget.enabled">
                        <SharePriceWidget
                            class="widget-list__item"
                            @updatedData="addBasicWidget('Share Price', 'SharePriceWidget')"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--custom-code"></v-icon>
                                Share Price
                            </span>
                        </SharePriceWidget>
                    </template>

                    <template v-if="widgetConfig.default.StripeProductWidget.enabled">
                        <StripeProductWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'Stripe Product', 'StripeProductWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--custom-code"></v-icon>
                                Stripe Product
                            </span>
                        </StripeProductWidget>
                    </template>

                    <template v-if="widgetConfig.default.SubscriptionsCTAWidget.enabled">
                        <SubscriptionsCTAWidget
                            class="widget-list__item"
                            @updatedData="
                                addBasicWidget('Subscriptions CTA', 'SubscriptionsCTAWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--subscriptions"></v-icon>
                                Subscriptions CTA
                            </span>
                        </SubscriptionsCTAWidget>
                    </template>

                    <template v-if="widgetConfig.default.subMenus.ThirdParty">
                        <div class="widget-list__item">
                            <button
                                class="widget-list__button"
                                type="button"
                                @click="openSubComponents('third-party')"
                            >
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--social-group"></v-icon>
                                Third Party
                            </button>
                        </div>
                    </template>

                    <template v-if="widgetConfig.default.subMenus.Football">
                        <div class="widget-list__item">
                            <button
                                class="widget-list__button"
                                type="button"
                                @click="openSubComponents('football')"
                            >
                                <v-icon class="widget-list__icon widget-list__icon--custom">sports_soccer</v-icon>
                                Matches
                            </button>
                        </div>
                    </template>

                    <template v-if="widgetConfig.default.UniqueWidget &&
                        widgetConfig.default.UniqueWidget.enabled
                        ">
                        <UniqueWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'Unique', 'UniqueWidget')"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon">star</v-icon>
                                Unique Widget
                            </span>
                        </UniqueWidget>
                    </template>

                    <!-- <template v-if="widgetConfig.default.UniqueWidget"> -->
                    <!-- <TopPlayerStatsWidget
                class="widget-list__item"
                @updatedData="addWidget($event, 'Top Player Stats', 'TopPlayerStatsWidget')">

                <span class="widget-list__button">
                  <v-icon class="widget-list__icon">analytics</v-icon>
                  Top Player Stats Widget
                </span>
              </TopPlayerStatsWidget> -->
                    <!-- </template> -->

                    <!-- Deprecated -->
                    <!-- <ListWidget @listUpload="addList($event, 'List', 'ListWidget')" class="widget-list__item">
              <span class="widget-list__button">
                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--list"></v-icon>
                List
              </span>
            </ListWidget> -->

                    <!-- <div class="widget-list__item">
              <button class="widget-list__button" type="button" @click="openSubComponents('player')">
                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--player"></v-icon>
                Player
              </button>
            </div> -->

                    <template v-if="widgetConfig.default.LeagueTableWidget &&
                        widgetConfig.default.LeagueTableWidget.enabled
                        ">
                        <LeagueTableWidgetEfl
                            v-if="client == 'efl'"
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'League Table', 'LeagueTableWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <img
                                    class="widget-list__icon"
                                    src="../../assets/trophy.svg"
                                />
                                League Table
                            </span>
                        </LeagueTableWidgetEfl>
                        <LeagueTableWidget
                            v-else
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'League Table', 'LeagueTableWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <img
                                    class="widget-list__icon"
                                    src="../../assets/trophy.svg"
                                />
                                League Table
                            </span>
                        </LeagueTableWidget>
                    </template>

                    <!-- <TriviaWidget
              class="widget-list__item"
              @updatedData="addWidget($event, 'Trivia', 'TriviaWidget')"
              >
              <span class="widget-list__button">
                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--trivia"></v-icon>
                Trivia
              </span>
            </TriviaWidget> -->

                    <!-- <SingleFieldWidget
              class="widget-list__item"
              @updatedData="addWidget($event, 'Custom Embed', 'custom-embed')"
              :widgetName="'Custom Embed'"
              :widgetLabel="'Embed Code'"
              :widgetDescription="'This allows you to upload 3rd party embedded widgets. Proceed with caution if you do not know the author of the script'">

              <span class="widget-list__button">
                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--custom-code"></v-icon>
                Custom
              </span>
            </SingleFieldWidget> -->
                </template>
                <!-- End Of Main Widget List -->

                <!-- Forms List -->
                <template v-else-if="widgetList === 'forms'">
                    <template v-if="widgetConfig.default.FormWidget.enabled">
                        <FormWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'Form', 'FormWidget')"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon">alternate_email</v-icon>
                                Forms
                            </span>
                        </FormWidget>
                    </template>

                    <template v-if="widgetConfig.default.GoogleFormsWidget.enabled">
                        <SingleFieldWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'Google Form', 'GoogleFormWidget')
                                "
                            :widgetName="'Google Form'"
                            :widgetLabel="'Google Form Embed Code'"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--google-form"></v-icon>
                                Google Form
                            </span>
                        </SingleFieldWidget>
                    </template>

                    <template v-if="widgetConfig.default.FormAssemblyWidget.enabled">
                        <FormAssemblyWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'FormAssembly', 'FormAssemblyWidget')
                                "
                        >
                            <!-- :widgetName="'FormAssembly'"
                :widgetLabel="'FormAssembly Embed Code'"> -->

                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--formassembly"></v-icon>
                                FormAssembly
                            </span>
                        </FormAssemblyWidget>
                    </template>
                </template>
                <!-- End of Forms List -->

                <!-- Fixtures List -->
                <template v-else-if="widgetList === 'fixtures'">
                    <template v-if="widgetConfig.default.FixturesListWidget.enabled">
                        <FixturesListWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'Fixtures List', 'FixturesListWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--fixtures"></v-icon>
                                Fixtures
                            </span>
                        </FixturesListWidget>
                    </template>

                    <template v-if="widgetConfig.default.UpcomingFixturesWidget.enabled">
                        <UpcomingFixturesWidgetEfl
                            v-if="client == 'efl'"
                            class="widget-list__item"
                            :widgetName="'Upcoming Fixtures'"
                            @updatedData="
                                addWidget($event, 'Upcoming Fixtures', 'UpcomingFixturesWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--upcoming-fixtures"></v-icon>
                                Upcoming Fixtures
                            </span>
                        </UpcomingFixturesWidgetEfl>
                        <UpcomingFixturesWidget
                            v-else
                            class="widget-list__item"
                            :widgetName="'Upcoming Fixtures'"
                            @updatedData="
                                addWidget($event, 'Upcoming Fixtures', 'UpcomingFixturesWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--upcoming-fixtures"></v-icon>
                                Upcoming Fixtures
                            </span>
                        </UpcomingFixturesWidget>
                    </template>

                    <template v-if="widgetConfig.default.AllFixturesWidget.enabled">
                        <AllFixturesWidgetEfl
                            v-if="client == 'efl'"
                            class="widget-list__item"
                            :widgetName="'All Fixtures'"
                            @updatedData="
                                addWidget($event, 'All Fixtures', 'AllFixturesWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--list"></v-icon>
                                All Fixtures
                            </span>
                        </AllFixturesWidgetEfl>
                        <AllFixturesWidget
                            v-else
                            class="widget-list__item"
                            :widgetName="'All Fixtures'"
                            @updatedData="
                                addWidget($event, 'All Fixtures', 'AllFixturesWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--list"></v-icon>
                                All Fixtures
                            </span>
                        </AllFixturesWidget>
                        <template v-if="widgetConfig.default.MatchResultsWidget && widgetConfig.default.MatchResultsWidget.enabled">
                            <MatchResultsWidgetEfl
                                v-if="client == 'efl'"
                                class="widget-list__item"
                                :widgetName="'All Fixtures'"
                                @updatedData="
                                    addWidget($event, 'Results', 'ResultsWidget')
                                    "
                            >

                                <span class="widget-list__button">
                                    <v-icon class="widget-list__icon">scoreboard</v-icon>
                                    Latest Results
                                </span>
                            </MatchResultsWidgetEfl>
                        </template>
                    </template>

                    <!-- <PreviousMeetingsWidget
              class="widget-list__item"
              @updatedData="addWidget($event, 'Previous Meetings', 'PreviousMeetingsWidget')"
            >
              <span class="widget-list__button">
                <div class="widget-list__icon widget-list__icon--custom widget-list__icon--meetings"></div>
                Previous meetings
              </span>
            </PreviousMeetingsWidget> -->
                </template>
                <!-- End of Fixtures List -->

                <!-- Organisations List -->
                <template v-else-if="widgetList === 'organisations'">
                    <template v-if="widgetConfig.default.OrganisationsWidget.enabled">
                        <OrganisationsWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'Organisations', 'OrganisationsWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--organisations"></v-icon>
                                Organisations
                            </span>
                        </OrganisationsWidget>
                    </template>

                    <template v-if="widgetConfig.default.OrganisationDetail.enabled">
                        <OrganisationDetailWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget(
                                    $event,
                                    'Organisation Detail',
                                    'OrganisationDetailWidget'
                                )
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--organisation-detail"></v-icon>
                                Organisation Detail
                            </span>
                        </OrganisationDetailWidget>
                    </template>
                </template>
                <!-- End Of Organisations List -->

                <!-- Player List -->
                <template v-else-if="widgetList === 'player'">
                    <template v-if="widgetConfig.default.PlayerStatsWidget.enabled">
                        <PlayerStatsWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'Player Statistics', 'PlayerStatsWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--stats"></v-icon>
                                Player Statistics
                            </span>
                        </PlayerStatsWidget>
                    </template>

                    <template v-if="widgetConfig.default.PlayerQuoteWidget.enabled">
                        <PlayerQuoteWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'Player Quote', 'PlayerQuoteWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--quote"></v-icon>
                                Player Quote
                            </span>
                        </PlayerQuoteWidget>
                    </template>
                </template>
                <!-- End Of Player List -->

                <!-- Promotions List -->
                <template v-else-if="widgetList === 'promotions'">
                    <template v-if="widgetConfig.default.ClubPromoWidget.enabled">
                        <ClubPromoWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'Club Promotions', 'ClubPromoWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon
                                    class="widget-list__icon widget-list__icon--custom widget-list__icon--crop_landscape">crop_landscape</v-icon>
                                Standard Promotion
                            </span>
                        </ClubPromoWidget>
                    </template>
                </template>
                <!-- End of promotions List -->

                <!-- Third Party List -->
                <template v-else-if="widgetList === 'third-party'">
                    <template v-if="widgetConfig.default.GoogleMapsWidget &&
                        widgetConfig.default.GoogleMapsWidget.enabled
                        ">
                        <SingleFieldWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'Google Maps', 'GoogleMapsWidget')
                                "
                            :widgetName="'Google Maps'"
                            :widgetLabel="'Google Maps Embed Code'"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--map"></v-icon>
                                Google Maps
                            </span>
                        </SingleFieldWidget>
                    </template>

                    <template v-if="widgetConfig.default.InstagramWidget.enabled">
                        <SingleFieldWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'Instagram', 'InstagramWidget')"
                            :widgetName="'Instagram'"
                            :widgetLabel="'Instagram Post Embed Code'"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--instagram"></v-icon>
                                Instagram
                            </span>
                        </SingleFieldWidget>
                    </template>

                    <template v-if="widgetConfig.default.MatterportWidget.enabled">
                        <SingleFieldWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'Matterport', 'MatterportWidget')"
                            :widgetName="'Matterport'"
                            :widgetLabel="'Matterport Embed Code'"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--360"></v-icon>
                                Matterport
                            </span>
                        </SingleFieldWidget>
                    </template>

                    <template v-if="widgetConfig.default.RiddleWidget.enabled">
                        <SingleFieldWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'Riddle', 'RiddleWidget')"
                            :widgetName="'Riddle'"
                            :widgetLabel="'Riddle Embed Code'"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--sporcle"></v-icon>
                                Riddle
                            </span>
                        </SingleFieldWidget>
                    </template>

                    <template v-if="widgetConfig.default.SoundcloudWidget.enabled">
                        <SingleFieldWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'Soundcloud', 'SoundcloudWidget')"
                            :widgetName="'Soundcloud'"
                            :widgetLabel="'Soundcloud Embed Code'"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--soundcloud"></v-icon>
                                Soundcloud
                            </span>
                        </SingleFieldWidget>
                    </template>

                    <template v-if="widgetConfig.default.SporcleWidget.enabled">
                        <SingleFieldWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'Sporcle', 'SporcleWidget')"
                            :widgetName="'Sporcle'"
                            :widgetLabel="'Sporcle Embed Code'"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--sporcle"></v-icon>
                                Sporcle
                            </span>
                        </SingleFieldWidget>
                    </template>

                    <template v-if="widgetConfig.default.ThirdPartyWidget.enabled">
                        <SingleFieldWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'Third Party Widget', 'ThirdPartyWidget')
                                "
                            :widgetName="'Third Party Widget'"
                            :widgetLabel="'Third Party Widget Embed Code'"
                            :widgetDescription="'This allows you to upload 3rd party embedded widgets. Proceed with caution if you do not know the author of the script. Not all 3rd party scripts will work.'"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--custom-code"></v-icon>
                                Third Party (V1)
                            </span>
                        </SingleFieldWidget>
                    </template>

                    <template v-if="widgetConfig.default.ThirdPartyV2Widget.enabled">
                        <SingleFieldWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget(
                                    $event,
                                    'Third Party Widget (V2)',
                                    'ThirdPartyV2Widget'
                                )
                                "
                            :widgetName="'Third Party Widget (V2)'"
                            :widgetLabel="'Third Party Widget Embed Code'"
                            :widgetDescription="'This allows you to upload 3rd party embedded widgets. Proceed with caution if you do not know the author of the script. Not all 3rd party scripts will work.'"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--custom-code"></v-icon>
                                Third Party (V2)
                            </span>
                        </SingleFieldWidget>
                    </template>

                    <template v-if="widgetConfig.default.TweetWidget.enabled">
                        <SingleFieldWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'Twitter', 'TweetWidget')"
                            :widgetName="'Twitter'"
                            :widgetLabel="'Tweet Embed Code'"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--twitter"></v-icon>
                                Tweet
                            </span>
                        </SingleFieldWidget>
                    </template>

                    <!-- <SingleFieldWidget
              class="widget-list__item"
              @updatedData="addWidget($event, 'Buzzsprout', 'BuzzsproutWidget')"
              :widgetName="'Buzzsprout'"
              :widgetLabel="'Buzzsprout Embed Code'">

              <span class="widget-list__button">
                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--buzzsprout"></v-icon>
                Buzzsprout
              </span>
            </SingleFieldWidget> -->
                </template>
                <!-- End of Third Party List -->

                <!-- Video List -->
                <template v-else-if="widgetList === 'video'">
                    <template v-if="widgetConfig.default.ClubTVWidget.enabled">
                        <MultiFieldWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'Club TV', 'ClubTVWidget')"
                            :widgetName="'Club TV'"
                            :fieldElements="[
                                { label: 'Title', name: 'title' },
                                { label: 'Club TV Video ID', name: 'videoID' },
                                {
                                    label: 'Is this video a live stream?',
                                    name: 'isLive',
                                    type: 'checkbox',
                                },
                                {
                                    label: 'Hide Behind Login?',
                                    name: 'hiddenVideo',
                                    type: 'checkbox',
                                },
                            ]"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--club-tv"></v-icon>
                                Club TV
                            </span>
                        </MultiFieldWidget>
                    </template>

                    <template v-if="widgetConfig.default.StreamlineEmbedWidget &&
                        widgetConfig.default.StreamlineEmbedWidget.enabled
                        ">
                        <StreamlineEmbedWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'Streamline Embed', 'StreamlineEmbedWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--club-tv"></v-icon>
                                Streamline Embed
                            </span>
                        </StreamlineEmbedWidget>
                    </template>

                    <template v-if="widgetConfig.default.YoutubeWidgetV2.enabled">
                        <MultiFieldWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'YouTube', 'YoutubeWidgetV2')"
                            :widgetName="'YouTube'"
                            :fieldElements="[
                                { label: 'Title', name: 'title', type: 'single' },
                                { label: 'YouTube ID', name: 'videoID', type: 'single' },
                                {
                                    label: 'Hide Behind Login?',
                                    name: 'hiddenVideo',
                                    type: 'checkbox',
                                },
                            ]"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--youtube"></v-icon>
                                YouTube
                            </span>
                        </MultiFieldWidget>
                    </template>

                    <template v-if="widgetConfig.default.VimeoWidget.enabled">
                        <MultiFieldWidget
                            class="widget-list__item"
                            @updatedData="addWidget($event, 'Vimeo', 'VimeoWidget')"
                            :widgetName="'Vimeo'"
                            :fieldElements="[
                                { label: 'Title', name: 'title', type: 'single' },
                                { label: 'Vimeo ID', name: 'videoID', type: 'single' },
                                {
                                    label: 'Hide Behind Login?',
                                    name: 'hiddenVideo',
                                    type: 'checkbox',
                                },
                            ]"
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--vimeo"></v-icon>
                                Vimeo
                            </span>
                        </MultiFieldWidget>
                    </template>

                    <!-- Youtube widget deprecated -->
                    <!-- <MultiFieldWidget
              class="widget-list__item"
              @updatedData="addWidget($event, 'Youtube', 'YoutubeWidget')"
              :widgetName="'Youtube'"
              :fieldElements="[
                {'label': 'Title', 'name': 'title', 'type': 'single'},
                {'label': 'Youtube Embed', 'name': 'videoEmbed', 'type': 'multi'}
              ]">
              <span class="widget-list__button">
                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--youtube"></v-icon>
                Youtube
              </span>
            </MultiFieldWidget> -->
                </template>
                <!-- End of Video List -->

                <template v-else-if="widgetList === 'football'">
                    <template v-if="widgetConfig.default.FootballBasicLineupWidget.enabled">
                        <FootballBasicLineupWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget(
                                    $event,
                                    'Football Basic Lineup',
                                    'FootballBasicLineupWidget'
                                )
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon">people_alt</v-icon>
                                Lineup
                            </span>
                        </FootballBasicLineupWidget>
                    </template>

                    <template v-if="widgetConfig.default.FootballBasicMatchStatsWidget.enabled">
                        <FootballBasicMatchStatsWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget(
                                    $event,
                                    'Football Basic Match Stats',
                                    'FootballBasicMatchStatsWidget'
                                )
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon">leaderboard</v-icon>
                                Match Stats
                            </span>
                        </FootballBasicMatchStatsWidget>
                    </template>
                    <template v-if="widgetConfig.default.CompetitionStatsWidget && widgetConfig.default.CompetitionStatsWidget.enabled">
                        <CompetitionStatsWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget(
                                    $event,
                                    'Competition Stats',
                                    'CompetitionStatsWidget'
                                )
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon">leaderboard</v-icon>
                                Competition Stats
                            </span>
                        </CompetitionStatsWidget>
                    </template>
                </template>

                <template v-else-if="widgetList === 'news'">
                    <template v-if="widgetConfig.default.NewsCategoryWidget.enabled">
                        <NewsCategoryWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'News Category', 'NewsCategoryWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--news-stacker"></v-icon>
                                News Stacker
                            </span>
                        </NewsCategoryWidget>
                    </template>
                    <template v-if="widgetConfig.default.NewsCarouselWidget.enabled">
                        <NewsCarouselWidget
                            class="widget-list__item"
                            @updatedData="
                                addWidget($event, 'News Carousel', 'NewsCarouselWidget')
                                "
                        >
                            <span class="widget-list__button">
                                <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--news-carousel"></v-icon>
                                News Carousel
                            </span>
                        </NewsCarouselWidget>
                    </template>
                </template>

                <template v-else-if="widgetList === 'calendar'">
                    <CalendarWidget
                        class="widget-list__item"
                        @updatedData="
                            addWidget($event, 'Events Calendar', 'EventsCalendarWidget')
                            "
                    >
                        <span class="widget-list__button">
                            <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--news-carousel"></v-icon>
                            Calendar Carousel
                        </span>
                    </CalendarWidget>

                    <SingleCalendarItemWidget
                        class="widget-list__item"
                        @updatedData="
                            addWidget(
                                $event,
                                'Single Calendar Item',
                                'SingleCalendarItemWidget'
                            )
                            "
                    >
                        <span class="widget-list__button">
                            <v-icon class="widget-list__icon widget-list__icon--custom widget-list__icon--calendar"></v-icon>
                            Single Calendar Item
                        </span>
                    </SingleCalendarItemWidget>
                </template>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import AccordionWidget from "@/components/Widgets/AccordionWidget";
import AdvertisingWidget from "@/components/Widgets/AdvertisingWidget";
import AllFixturesWidget from "@/components/Widgets/AllFixturesWidget";
import AllFixturesWidgetEfl from "@/components/Widgets/AllFixturesWidgetEfl";
import BlogWidget from "@/components/Widgets/BlogWidget";
import CalendarWidget from "@/components/Widgets/CalendarWidget";
import ClubPromoWidget from "@/components/Widgets/ClubPromoWidget";
import CompetitionStatsWidget from "@/components/Widgets/CompetitionStatsWidget";
import ContactWidget from "@/components/Widgets/ContactWidget";
import CtaWidget from "@/components/Widgets/CtaWidget";
import FootballBasicLineupWidget from "@/components/Widgets/FootballBasicLineupWidget";
import FootballBasicMatchStatsWidget from "@/components/Widgets/FootballBasicMatchStatsWidget";
import FormAssemblyWidget from "@/components/Widgets/FormAssemblyWidget";
import FormWidget from "@/components/Widgets/FormWidget";
import FileDownloadWidget from "@/components/Widgets/FileDownloadWidget";
import FixturesListWidget from "@/components/Widgets/FixturesListWidget";
import HorizontalLineWidget from "@/components/Widgets/HorizontalLineWidget";
import ImageWidget from "@/components/Widgets/ImageWidget";
import ImageGalleryWidget from "@/components/Widgets/ImageGalleryWidget";
import LeagueTableWidget from "@/components/Widgets/LeagueTableWidget";
import LeagueTableWidgetEfl from "@/components/Widgets/LeagueTableWidgetEfl";
import ListWidgetV2 from "@/components/Widgets/ListWidgetV2";
import MultiFieldWidget from "@/components/Widgets/MultiFieldWidget";
import MapDirectoryWidget from "@/components/Widgets/MapDirectoryWidget";
import MatchResultsWidgetEfl from "@/components/Widgets/MatchResultsWidgetEfl";
import NewsCategoryWidget from "@/components/Widgets/NewsCategoryWidget";
import NewsCarouselWidget from "@/components/Widgets/NewsCarouselWidget";
import OrganisationsWidget from "@/components/Widgets/OrganisationsWidget";
import OrganisationDetailWidget from "@/components/Widgets/OrganisationDetailWidget";
import PlayerOfTheMatchWidget from "@/components/Widgets/PlayerOfTheMatchWidget";
import PlayerQuoteWidget from "@/components/Widgets/PlayerQuoteWidget";
import PlayerStatsWidget from "@/components/Widgets/PlayerStatsWidget";
import PreviousMeetingsWidget from "@/components/Widgets/PreviousMeetingsWidget";
import QuoteWidget from "@/components/Widgets/QuoteWidget";
import SharePriceWidget from "@/components/Widgets/SharePriceWidget";
import SingleCalendarItemWidget from "@/components/Widgets/SingleCalendarItemWidget";
import SingleFieldWidget from "@/components/Widgets/SingleFieldWidget";
import StreamlineEmbedWidget from "@/components/Widgets/StreamlineEmbedWidget";
import StripeProductWidget from "@/components/Widgets/StripeProductWidget";
import SubscriptionsCTAWidget from "@/components/Widgets/SubscriptionsCTAWidget";
import TextBlockWidget from "@/components/Widgets/TextBlockWidget";
import TopPlayerStatsWidget from "@/components/Widgets/TopPlayerStatsWidget";
import TriviaWidget from "@/components/Widgets/TriviaWidget";
import UpcomingFixturesWidget from "@/components/Widgets/UpcomingFixturesWidget";
import UpcomingFixturesWidgetEfl from "@/components/Widgets/UpcomingFixturesWidgetEfl";
import UniqueWidget from "@/components/Widgets/UniqueWidget";
import IconSelector from "@/components/Library/IconSelector";
import DocWidget from "@/components/Widgets/DocWidget";
export default {
    name: "WidgetSelector",

    components: {
        AccordionWidget,
        AdvertisingWidget,
        AllFixturesWidget,
        AllFixturesWidgetEfl,
        BlogWidget,
        CalendarWidget,
        CompetitionStatsWidget,
        ContactWidget,
        ClubPromoWidget,
        CtaWidget,
        FileDownloadWidget,
        FixturesListWidget,
        FootballBasicLineupWidget,
        FootballBasicMatchStatsWidget,
        FormAssemblyWidget,
        FormWidget,
        HorizontalLineWidget,
        IconSelector,
        ImageWidget,
        ImageGalleryWidget,
        LeagueTableWidget,
        LeagueTableWidgetEfl,
        ListWidgetV2,
        MatchResultsWidgetEfl,
        MultiFieldWidget,
        MapDirectoryWidget,
        NewsCategoryWidget,
        NewsCarouselWidget,
        OrganisationsWidget,
        OrganisationDetailWidget,
        PlayerOfTheMatchWidget,
        PlayerQuoteWidget,
        PlayerStatsWidget,
        PreviousMeetingsWidget,
        QuoteWidget,
        SingleCalendarItemWidget,
        SingleFieldWidget,
        SharePriceWidget,
        StreamlineEmbedWidget,
        StripeProductWidget,
        SubscriptionsCTAWidget,
        TextBlockWidget,
        TopPlayerStatsWidget,
        TriviaWidget,
        UniqueWidget,
        UpcomingFixturesWidget,
        UpcomingFixturesWidgetEfl,
        DocWidget
    },

    data() {
        return {
            dialog: false,
            widgetList: "main",
            widgetConfig: null,
            moduleConfig: null,
            client: process.env.VUE_APP_CLUB_ID,
        };
    },

    created() {
        this.widgetConfig = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/widgets.js");
        this.moduleConfig = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");
    },

    watch: {
        dialog: function (value) {
            if (value === false) {
                this.widgetList = "main";
            }
        },
    },

    methods: {
        addWidget(payload, widgetName, widgetType) {
            console.log("addWidget payload: ", payload);
            const data = {
                widgetName: widgetName,
                widgetType: widgetType,
                widgetData: payload,
            };
            this.$emit("widgetAdded", data);
            this.dialog = false;
        },

        addBasicWidget(widgetName, widgetType) {
            console.log("addBasicWidget");
            const data = {
                widgetName: widgetName,
                widgetType: widgetType,
            };
            this.$emit("widgetAdded", data);
            this.dialog = false;
        },

        addImage(payload, widgetName, widgetType) {
            console.log("addImage payload: ", payload);
            const data = {
                widgetName: widgetName,
                widgetType: widgetType,
                widgetData: {
                    image: payload,
                },
            };
            this.$emit("widgetAdded", data);
            this.dialog = false;
        },

        addFile(payload, widgetName, widgetType) {
            console.log("addFile payload: ", payload);
            const data = {
                widgetName: widgetName,
                widgetType: widgetType,
                widgetData: {
                    image: payload,
                },
            };
            this.$emit("widgetAdded", data);
            this.dialog = false;
        },

        addImages(payload, widgetName, widgetType) {
            console.log("Adding images", payload);

            const data = {
                widgetName: widgetName,
                widgetType: widgetType,
                widgetData: { gallery: [] },
            };
            payload.gallery.forEach((image) => {
                data.widgetData.gallery.push({
                    mediaLibraryID: image.mediaLibraryID,
                });
            });
            console.log("image array", data);
            this.$emit("widgetAdded", data);
        },

        addList(payload, widgetName, widgetType) {
            console.log("Adding list", payload);
            const data = {
                widgetName: widgetName,
                widgetType: widgetType,
                widgetData: payload,
            };
            this.$emit("widgetAdded", data);
            this.dialog = false;
        },

        addAccordion(payload, widgetName, widgetType) {
            console.log("Add accordion", payload);
            const data = {
                widgetName: widgetName,
                widgetType: widgetType,
                widgetData: payload,
            };
            this.$emit("widgetAdded", data);
            this.dialog = false;
        },

        openSubComponents(type) {
            this.widgetList = type;
        },

        backToMain() {
            this.widgetList = "main";
        },
    },
};
</script>

<style lang="scss" scoped>
.widget-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    min-height: 470px;
    width: 100%;

    &__item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 20px 40px;
        width: 25%;
    }

    &__button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        color: #67247c;
        text-align: center;
        white-space: nowrap;
        outline: 0;
    }

    &__icon {
        margin: 0 0 5px;
        color: #67247c;
        fill: #67247c;
    }

    &__header {
        padding: 10px;
        height: 40px;
    }

    &__back-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        color: #67247c;
        text-align: center;
        white-space: nowrap;
        outline: 0;
    }

    &__icon {
        &--custom {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
        }

        &--club-tv {
            background-image: url("../../assets/video-camera.svg");
        }

        &--youtube {
            background-image: url("../../assets/youtube.svg");
        }

        &--stats {
            background-image: url("../../assets/statistics.svg");
        }

        &--instagram {
            background-image: url("../../assets/instagram.svg");
        }

        &--twitter {
            background-image: url("../../assets/x.svg");
        }

        &--social-group {
            background-image: url("../../assets/social-group.svg");
        }

        &--pitch {
            background-image: url("../../assets/pitch.svg");
        }

        &--fixtures {
            background-image: url("../../assets/fixtures.svg");
        }

        &--player {
            background-image: url("../../assets/player.svg");
        }

        &--upcoming-fixtures {
            background-image: url("../../assets/upcoming-fixtures.svg");
        }

        &--quote {
            background-image: url("../../assets/quote.svg");
        }

        &--gallery {
            background-image: url("../../assets/gallery.svg");
        }

        &--trivia {
            background-image: url("../../assets/trivia.svg");
        }

        &--list {
            background-image: url("../../assets/list.svg");
        }

        &--video {
            background-image: url("../../assets/video.svg");
        }

        &--custom-code {
            background-image: url("../../assets/custom.svg");
        }

        &--promo {
            background-image: url("../../assets/promotions.svg");
        }

        &--club {
            background-image: url("../../assets/club.svg");
        }

        &--pitch {
            background-image: url("../../assets/pitch.svg");
        }

        &--meetings {
            background-image: url("../../assets/meetings.svg");
        }

        &--text {
            background-image: url("../../assets/text-block.svg");
        }

        &--photo {
            background-image: url("../../assets/image.svg");
        }

        &--file {
            background-image: url("../../assets/file.svg");
        }

        &--envelope {
            background-image: url("../../assets/envelope.svg");
        }

        &--sporcle {
            background-image: url("../../assets/trivia.svg");
        }

        &--line {
            background-image: url("../../assets/vertical-align.svg");
        }

        &--buzzsprout {
            background-image: url("../../assets/buzzsprout.svg");
        }

        &--soundcloud {
            background-image: url("../../assets/soundcloud.svg");
        }

        &--map {
            background-image: url("../../assets/map.svg");
        }

        &--360 {
            background-image: url("../../assets/360.svg");
        }

        &--subscriptions {
            background-image: url("../../assets/subscriptions.svg");
        }

        &--vimeo {
            background-image: url("../../assets/vimeo.svg");
        }

        &--list_alt {
            background-image: url("../../assets/list_alt-24px.svg");
        }

        &--news {
            background-image: url("../../assets/news.svg");
        }

        &--news-stacker {
            background-image: url("../../assets/news--stacker.svg");
        }

        &--news-carousel {
            background-image: url("../../assets/news--carousel.svg");
        }

        &--formassembly {
            background-image: url("../../assets/formassembly.svg");
        }

        &--google-form {
            background-image: url("../../assets/google-forms.svg");
        }

        &--organisations {
            background-image: url("../../assets/multi-location.svg");
        }

        &--organisation-detail {
            background-image: url("../../assets/location.svg");
        }

        &--calendar {
            background-image: url("../../assets/calendar.svg");
        }

        &--crop_landscape {
            font-size: 28px;
        }
    }
}

.main-widgets {
    display: flex;
    flex-basis: 100%;
    justify-content: space-evenly;
    border-bottom: 1px solid #67247c;
    margin: 0 16px;
}
</style>
