export default {
    "AdditionalPageTypes": true,
    "Admin": true,
    "Advertising": false,
    "AggregatedContentFeed": false,
    "AllowCustomFields": false,
    "AppConfig": true,
    "AppEcommerceManager": false,
    "AppScorePredictor": false,
    "AppSocialManager": false,
    "AppTicketingManager": false,
    "BreakingNews": false,
    "BuildManager": true,
    "Calendar": true,
    "CustomCommentary": true,
    "CustomLineups": true,
    "CustomTables": true,
    "CustomTags": true,
    "Dashboard": true,
    "Fixtures": true,
    "Forms": false,
    "HomepageManager": false,
    "HomepageNewsPromo": true,
    "HomepagePlayerHighlight": true,
    "Lab": false,
    "LFCExclusive": true,
    "MatchdayTakeover": false,
    "Media": true,
    "MFA": true,
    "News": false,
    "OptaIntegration": true,
    "Organisations": false,
    "OverlayPromos": false,
    "Pages": true,
    "PlatformOptions": true,
    "PlayerVoting": false,
    "PreventEntitlementEmails": true,
    "Profile": true,
    "RelatedContentTagging": true,
    "RewardsManager": false,
    "RewardPoints": false,
    "Rover": false,
    "Settings": false,
    "SiteConfig": true,
    "Sponsors": false,
    "Squads": true,
    "SsoUsers": false,
    "StreamAMGIntegration": false,
    "Streamline": true,
    "StreamlineAdverts": false,
    "StreamlinePlaylists": true,
    "StreamlinePromoCodes": true,
    "TeamCrestManager": true,
    "Videos": true,
    "Voice": false,
    "WebStats": true
}
