<template>

  <v-dialog
      class="organisations-widget"
      v-model="dialog"
      width="580"
      lazy
    >

    <slot slot="activator"></slot>
      <v-form enctype="multipart/form-data" ref="singleFieldForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            class="widget__heading"
          >
            Organisations Widget
          </v-card-title>

          <v-card-text>
            <p class="widget__description">Allows you to display a list of organisations</p>

            <label class="label" for="category">Organisation Type</label>
            <v-select
              id="category"
              outline
              :rules="[rules.required]"
              v-model="updatedData.organisationType"
              :items="organisationTypes"
              item-text="name"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
              multiple
              chips
              return-object
            ></v-select>

            <label class="label" for="country">Filter Countries</label>
            <p class="widget__help-text">Limit the origanisations displayed to the following countries.</p>
            <v-autocomplete
              id="country"
              outline
              v-model="updatedData.filteredCountries"
              :items="countriesList"
              item-value="alpha2Code"
              item-text="enShortName"
              no-data-text="loading..."
              multiple
              solo
              flat
              clearable
              dense
              chips
              >
            </v-autocomplete>

            <label class="label" for="region">Region</label>
            <v-text-field type="text" id="region" solo flat v-model="updatedData.region">
            </v-text-field>

            <label class="label" for="type">Display Type</label>
            <v-select
              id="type"
              outline
              :rules="[rules.required]"
              v-model="updatedData.displayType"
              item-text="name"
              item-value="id"
              :items="[
                {name: 'Map Only', id: 'mapOnly'},
                {name: 'List Only', id: 'listOnly'}
              ]"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
              return-object
            ></v-select>

            <label class="label" for="type">Filter Type</label>
            <v-select
              id="type"
              outline
              :rules="[rules.required]"
              v-model="updatedData.filterType"
              item-text="name"
              item-value="id"
              :items="[
                {name: 'Simple', id: 'simple'}
              ]"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
              return-object
            ></v-select>

            <template v-if="updatedData.displayType">

              <template v-if="updatedData.displayType.id === 'listOnly'">
                <label class="label" for="use-pagination">Use pagination?</label>
                <v-select
                  id="use-pagination"
                  outline
                  v-model="updatedData.usePagination"
                  item-text="name"
                  item-value="id"
                  :items="[
                    {name: 'Yes, paginate the results', id: true},
                    {name: 'No, display all results', id: false}
                  ]"
                  solo
                  flat
                  class="text-field--lableless"
                  validate-on-blur
                ></v-select>

                <!-- <label class="label" for="sort-by-alpha">Show results in alphabetical order?</label>
                <v-select
                  id="sort-by-alpha"
                  outline
                  v-model="updatedData.sortByAlpha"
                  item-text="name"
                  item-value="id"
                  :items="[
                    {name: 'Yes, alphabetise results', id: true},
                    {name: 'No, use saved order', id: false}
                  ]"
                  solo
                  flat
                  class="text-field--lableless"
                  validate-on-blur
                ></v-select> -->
              </template>

              <template v-else-if="updatedData.displayType.id === 'mapOnly'">
                <label class="label" for="custom-position">Custom Map Position</label>
                <v-select
                  id="custom-position"
                  outline
                  v-model="updatedData.mapData.customMapPosition"
                  item-text="name"
                  item-value="id"
                  :items="[
                    {name: 'Default map position', id: false},
                    {name: 'Custom map position', id: true}
                  ]"
                  solo
                  flat
                  class="text-field--lableless"
                  @change="updateCustomMapPosition()"
                  validate-on-blur
                ></v-select>

                <template v-if="updatedData.mapData.customMapPosition">
                  <label class="label" for="latitude">Latitude</label>
                  <v-text-field type="number" id="latitude" placeholder="53.3899497" solo flat v-model="updatedData.mapData.centerPoint.lat">
                  </v-text-field>

                  <label class="label" for="longitude">Longitude</label>
                  <v-text-field type="number" id="longitude" placeholder="-2.5943178" solo flat v-model="updatedData.mapData.centerPoint.lng">
                  </v-text-field>

                  <label class="label" for="zoom-level">Zoom Level</label>
                  <v-text-field id="zoom-level" type="number" placeholder="7" solo flat v-model="updatedData.mapData.zoomLevel">
                  </v-text-field>
                </template>
              </template>
            </template>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="accent"
              outline
              flat
              @click="validate()"
            >
              Post
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

  </v-dialog>

</template>

<script>
import axios from "axios"

export default {
  name: "OrganisationsWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    dialog: false,
    valid: false,
    rules: {
      required: value => !!value || "Required."
    },
    updatedData: {
      organisationType: null,
      filteredCountries: [],
      region: null,
      displayType: null,
      usePagination: false,
      sortByAlpha: true,
      mapData: {
        customMapPosition: false,
        centerPoint: {
          lat: null,
          lng: null
        },
        zoomLevel: null
      }
    },
    organisationTypes: [],
    countriesList: [],
    countriesLoaded: false,
  }),

  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
    }

    this.getOrganisationTypes()
    this.getCountries()
  },

  methods: {
    getOrganisationTypes() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_ORGANISATIONAPI}/organisationtypes`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          response.data.body.Items.forEach(item => {
            this.organisationTypes.push(item)
          })
        })
        .catch(e => {
          console.error("Problem receiving orgtypes", e)
          this.$store.commit("completeLoading")
        })
      })
    },

    getCountries() {
      this.countriesLoaded = false
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_COUNTRIESAPI}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          this.countriesList = response.data
          this.countriesLoaded = true
        })
        .catch(e => {
          console.error("Problem receiving countries", e)
        })
      })
    },

    updateCustomMapPosition() {
      if (!this.updatedData.mapData.customMapPosition) {
        this.updatedData.mapData.centerPoint = {
          lat: null,
          lng: null
        }
        this.updatedData.mapData.zoomLevel = null
      }
    },

    updateData() {
      this.updatedData.mapData.centerPoint.lat = parseFloat(this.updatedData.mapData.centerPoint.lat)
      this.updatedData.mapData.centerPoint.lng = parseFloat(this.updatedData.mapData.centerPoint.lng)
      this.updatedData.mapData.zoomLevel = parseFloat(this.updatedData.mapData.zoomLevel)

      console.log("Update data:", this.updatedData);

      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
    },

    validate() {

      if (this.updatedData.organisationType === "") {
        this.updatedData.organisationType = null
      }

      if (this.updatedData.displayType === "") {
        this.updatedData.displayType = null
      }

      this.errorMessage = ""
      if (this.$refs.singleFieldForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    }
  }
}
</script>

<style scoped lang='scss'>
.widget {
    &__description {
      margin: 0 0 20px;
      color: #7B6B80;
      font-size: 14px;
    }
    &__help-text {
      margin: 0 0 8px;
      color: #7B6B80;
      font-size: 14px;
    }
  }
</style>
