<template>
    <v-dialog
        class="matchstats-widget"
        v-model="dialog"
        width="580"
        lazy
    >
        <slot slot="activator"></slot>
        <v-form
            enctype="multipart/form-data"
            ref="singleFieldForm"
            v-model="valid"
            lazy-validation
        >
            <v-card>
                <v-card-title class="widget__heading"> Competition Stats </v-card-title>

                <v-card-text>
                    <match-picker-efl
                        v-if="client == 'efl'"
                        @updated="saveDetailsEfl($event)"
                        :competitionOnly="true"
                        :data="updatedData"
                        :dialog="dialog"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="accent"
                        outline
                        flat
                        @click="validate()"
                    > Post </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import MatchPicker from "@/components/Library/MatchPicker";
import MatchPickerEfl from "@/components/Library/MatchPickerEfl";

export default {
    name: "CompetitionStatsWidget",

    components: {
        MatchPicker,
        MatchPickerEfl,
    },

    props: {
        widgetData: {
            type: Object,
            required: false,
        },
    },

    data: () => ({
        client: process.env.VUE_APP_CLUB_ID,
        dialog: false,
        valid: false,
        rules: {
            required: (value) => !!value || "Required.",
        },
        updatedData: {
            seasonID: "",
            competitionID: "",
            competitionName: "",
        },
    }),

    mounted() {
        if (this.widgetData) {
            this.updatedData = JSON.parse(JSON.stringify(this.widgetData));
        }
    },

    watch: {
        dialog() {
            if (this.dialog && this.widgetData) {
                this.updatedData = JSON.parse(JSON.stringify(this.widgetData));
            }
        }
    },

    methods: {
        saveDetailsEfl(payload) {
            this.updatedData.seasonID = payload.seasonID;
            this.updatedData.competitionID = payload.competitionID;
            this.updatedData.competitionName = payload.competitionName;
        },

        updateData() {
            console.log("Update data:", this.updatedData);
            this.$emit("updatedData", this.updatedData);
            this.dialog = false;
        },

        validate() {
            this.errorMessage = "";
            if (this.$refs.singleFieldForm.validate()) {
                console.log("Validation passed");
                this.updateData();
            } else {
                this.errorMessage = "Certain entries are invalid or required";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.widget {
    &__description {
        margin: 0 0 20px;
        color: #7b6b80;
        font-size: 14px;
    }
}

.spacer {
    margin-top: 16px;
}
</style>
