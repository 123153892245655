<template>
  <section class="fixtures">
    <v-tabs v-model="active">
      <v-tab
        v-for="(team, index) in fixturesConfig"
        :key="index"
        :ripple="{ class: 'accent--text' }"
        >{{ team.teamName }}</v-tab
      >

      <v-tab-item
        v-for="(team, index) in fixturesConfig"
        :key="index"
        px-0
        class="main-body"
      >
        <FixturesList :data="team" :isActive="active === index" />
      </v-tab-item>
    </v-tabs>
  </section>
</template>

<script>
import axios from "axios";
import FixturesList from "@/components/Fixtures/FixturesList";

export default {
  name: "FixturesManager",

  components: {
    FixturesList,
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/fixtures`,
    active: null,
    fixturesConfig: [],
  }),

  mounted() {
    // console.log("Fixtures page mounted");
    this.fetchFixturesConfig();
  },

  methods: {
    fetchFixturesConfig() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${this.api}/opta/filters`, { headers: { Authorization: jwt } })
          .then((response) => {
            // console.log('fetchFixturesConfig: ', response);
            this.fixturesConfig = response.data.body;

            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem retrieving fixtures", e);
            this.$store.commit("completeLoading");
          });
      });
    },
  },
};
</script>
