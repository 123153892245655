export default {
  data: [
    {
      sectionName: "Promo Banner",
      sectionDesc: "",
      model: "promoBanner",
      fields: [
        {
          label: "Link",
          model: "promoBanner_link",
          type: "Text"
        },
        {
          label: "Upload Desktop Image",
          model: "promoBanner_desktopImage",
          type: "Image"
        },
        {
          label: "Upload Mobile Image",
          model: "promoBanner_mobileImage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Homepage promo - top left",
      sectionDesc: "This is for the top left hand side (top on smaller screens) promotional area on the homepage",
      model: "promo_left",
      fields: [
        {
          label: "Title",
          model: "promo_left_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_left_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_left_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_left_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_left_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Homepage promo - top right",
      sectionDesc: "This is for the top right hand side (2nd image in column on smaller screens) promotional area on the homepage",
      model: "promo_right",
      fields: [
        {
          label: "Title",
          model: "promo_right_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_right_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_right_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_right_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_right_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Homepage promo - bottom left",
      sectionDesc: "This is for the bottom left hand side (3rd image in column on smaller screens) promotional area on the homepage",
      model: "promo_bottom_left",
      fields: [
        {
          label: "Title",
          model: "promo_bottom_left_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_bottom_left_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_bottom_left_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_bottom_left_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_bottom_left_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Homepage promo - bottom right",
      sectionDesc: "This is for the bottom right hand side (bottom of the column on smaller screens) promotional area on the homepage",
      model: "promo_bottom_right",
      fields: [
        {
          label: "Title",
          model: "promo_bottom_right_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_bottom_right_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_bottom_right_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_bottom_right_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_bottom_right_promoimage",
          type: "Image"
        }
      ]
    },
  ]
}
