<template>
<v-dialog v-model="dialogListWidget" width="580" lazy>

  <!-- DEPRECATED USE V2 -->

  <slot slot="activator"></slot>

  <v-form class="listwidget">
    <v-card>
      <v-card-title class="widget__heading">
        List
      </v-card-title>

      <draggable v-model="listItems" :options="{
          ghostClass: 'ghost',
          dragClass: 'sortable-drag'
        }" @start="drag=true" @end="drag=false" >
        <transition-group name="list-complete">
          <WidgetListItem v-for="(item, index) in listItems" :index="index" :key="item.guid" :guid="item.guid" :title="item.title" :text="item.text" :link="item.link" :mediaLibraryID="item.mediaLibraryID" @removeItem="deleteListItem" @updateListItem="updateListItem"></WidgetListItem>
        </transition-group>
      </draggable>

      <v-card-text class="listwidget__add" @click="listWidgetAdd = true">
        <v-icon color="#fff" class="listwidget__icon">add</v-icon> <span class="listwidget__text">Add list item</span>
      </v-card-text>

      <v-layout v-if="listWidgetAdd" class="listwidget__item-edit">
        <v-form v-model="valid" ref="dialogListWidgetForm" lazy-validation>

          <ImageUploader 
            :title="'Upload new image'"
            :widthOptionEnabled="false"
            :mediaLibraryID="listItem.imageID"
            @update="updateImage($event)" /> 

          <label class="label" for="title">Title</label>
          <v-text-field v-model="listItem.title" id="title" solo flat required :rules="[rules.required]" validate-on-blur class="listwidget__item-edit-input"></v-text-field>
          <label class="label" for="text">Text</label>
          <v-text-field v-model="listItem.text" id="text" solo flat required :rules="[rules.required]" validate-on-blur class="listwidget__item-edit-input"></v-text-field>
          <label class="label" for="link">Link</label>
          <v-text-field v-model="listItem.link" id="link" solo flat required :rules="[rules.required]" validate-on-blur class="listwidget__item-edit-input"></v-text-field>
          <div v-if="errorMessage" class="error--text error-message">
            {{errorMessage}}
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="flex align-center">
              <v-btn color="grey" outline flat @click="listWidgetAdd = null">
                Cancel
              </v-btn>
              <v-btn color="accent" outline flat previewImage=null @click="validate()">
                Add
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-layout>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" outline flat @click="reloadWidgetData(), dialogListWidget = null">
          Cancel
        </v-btn>
        <v-btn color="accent" depressed previewImage=null @click="addListToPageBuilder()">
          Save list
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</v-dialog>
</template>

<script>
import axios from "axios"
import draggable from "vuedraggable"
import WidgetListItem from "@/components/Library/WidgetListItem"
import ImageUploader from "@/components/Library/ImageUploader.vue"

export default {
  name: "ListWidget",

  components: {
    WidgetListItem,
    draggable,
    ImageUploader
  },

  props: {
    widgetData: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    id: null,
    listItems: [],
    dialogListWidget: false,
    listWidgetAdd: false,
    errorMessage: null,
    valid: false,
    listItem: {
      guid: null,
      title: null,
      text: null,
      link: null,
      imageID: null
    },
    rules: {
      required: value => !!value || "Required.",
      imageRequired: fileUpload => !!fileUpload || "Required."
    }
  }),

  mounted() {
    console.log("List widget mounted");
    this.reloadWidgetData();
  },
  methods: {
    updateImage(payload) {
      console.log("Update image:", payload);

      this.listItem.imageID = payload.mediaLibraryID
    },

    reloadWidgetData() {
      if (this.widgetData) {
        this.listItems = JSON.parse(JSON.stringify(this.widgetData.list))
      }
    },
    addListToPageBuilder() {
      const payload = {list: this.listItems};
      console.log("Saving...", this.listItems);
      this.dialogListWidget = false;
      this.$emit("listUpload", payload)
    },
    deleteListItem(index) {
      if(this.listItems.length > 1) {
        this.listItems.splice(index, 1);
      }
      else {
        this.listItems.length = null;
      }
      this.$set(this.listItems, this.listItems);
    },
    updateListItem(value, index) {
        index = (index === null || undefined) ? this.listItems.length : index;
        console.log("List updated - value:", value, "At index:", index);

        this.$set(this.listItems, index, value);
        this.listWidgetAdd = false;
        this.listItem = {
          guid: null,
          title: null,
          text: null,
          link: null,
          imageID: null
        };
        console.log(this.listItems);
    },
    addListItem() {
      this.listItem.guid = this.$uuid.v1();
      this.updateListItem(this.listItem, this.listItems.length);
    },
    
    validate() {
      this.errorMessage = "";
      // if(!this.listItem.mediaLibraryID) {
      if(!this.listItem.imageID) {
        this.errorMessage = "Please upload an image"
        return false
      }
      else if (this.$refs.dialogListWidgetForm.validate()) {
        console.log("Validation passed");
        this.addListItem();
      }
      else {
        this.errorMessage = "Certain entries are invalid or required"
        return false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.listwidget {
    &__add {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    &__icon {
        background-color: $purple;
        border-radius: 50rem;
        padding: 10px;
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
    }
    &__text {
        color: $lightPurple;
        padding: 10px;
    }
}
</style>
