<template>
    <section>
        <div class="section-header">
            <div class="title-container">
                <icon-selector
                    class="header-icon"
                    icon="Slot"
                />
                <h2 class="section-title">Slots</h2>
            </div>
            <slot-modal
                :adsData="adverts"
                @slotEdited="$emit('fetchSlots')"
                v-if="adverts"
            >
                <v-btn
                    v-if="isDevUser"
                    class="add-button"
                    accent
                    type="button"
                    color="accent"
                    depressed
                ><v-icon medium>add</v-icon>Create New Slot</v-btn>
            </slot-modal>
        </div>

        <p class="section-description">
            Slots are pre-defined advert sections on Gamechanger. Slots host Adverts
            and are a guide to where the ads will be surfaced. A slot will display the
            first ad to meet the display criteria, E.G., it has not expired or
            contains all the necessary data.
        </p>
        <p
            v-if="error"
            class="error-message"
        >{{ error }}</p>
        <p v-if="loading">Loading...</p>
        <template v-else>
            <v-expansion-panel
                expand
                v-model="panels"
                class="accordion"
            >
                <v-expansion-panel-content class="accordion__item accordion--populated">
                    <div
                        class="accordion__title"
                        slot="header"
                    >Populated Slots</div>
                    <template v-if="renderPopulatedSlots().length">
                        <advertising2-slot
                            v-for="slot in renderPopulatedSlots()"
                            :key="slot.id"
                            :slotData="slot"
                            :adverts="adverts"
                            @fetchSlots="$emit('fetchSlots')"
                        />
                    </template>
                    <p v-else>There are no populated slots</p>
                </v-expansion-panel-content>

                <v-expansion-panel-content class="accordion__item">
                    <div
                        class="accordion__title has-margin"
                        slot="header"
                    >
                        Unpopulated Slots
                    </div>
                    <template v-if="renderUnpopulatedSlots().length">
                        <advertising2-slot
                            v-for="slot in renderUnpopulatedSlots()"
                            :key="slot.id"
                            :slotData="slot"
                            :adverts="adverts"
                            @fetchSlots="$emit('fetchSlots')"
                        />
                    </template>
                    <p v-else>There are no unpopulated slots</p>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </template>
    </section>
</template>

<script>
import Advertising2Slot from './Advertising2Slot.vue'
import IconSelector from "@/components/Library/IconSelector";
import SlotModal from "./SlotModal";
import { devUsers } from '@/plugins/permissions'

export default {
    name: "Advertising2Slots",

    props: {
        adverts: {
            type: Array,
            required: false,
        },
        slots: {
            type: Array,
            required: false,
        },
        loading: {
            type: Boolean,
            default: true
        }
    },

    components: {
        SlotModal,
        IconSelector,
        Advertising2Slot
    },

    data: () => ({
        dialog: false,
        error: "",
        panels: [1, 1],
        isDevUser: false,
    }),

    created() {
        if (devUsers && devUsers.includes(this.$store.state.user.email)) {
            this.isDevUser = true;
        }
    },

    watch: {
        slots: {
            handler() {
                if (this.slots && this.slots.length) {
                    this.panels = [1, 1]
                }
            },
            deep: true,
        },
    },

    methods: {
        renderPopulatedSlots() {
            return this.slots.filter((element) => {
                if (
                    element.attributes &&
                    element.attributes.adverts &&
                    element.attributes.adverts.length
                ) {
                    return element;
                }
            });
        },

        renderUnpopulatedSlots() {
            return this.slots.filter((element) => {
                if (
                    !(
                        element.attributes &&
                        element.attributes.adverts &&
                        element.attributes.adverts.length
                    )
                ) {
                    return element;
                }
            });
        },
    },
};
</script>

<style scoped lang='scss'>
.section-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.header-icon {
    margin-right: 15px;
    color: #67247c;
}

.title-container {
    display: flex;
    align-items: center;
}

.section-title {
    color: #67247c;
    font-size: 20px;
    font-weight: 500;
    margin-right: 10px;
}

.add-button {
    font-weight: 500;
    font-size: 14px;
    text-transform: none;
    margin: 0;

    i {
        font-weight: bold;
        margin-right: 10px;
    }
}

.section-description {
    max-width: 648px;
    color: #516173;
    font-size: 14px;
    margin-bottom: 24px;
}

.card-title {
    font-size: 20px;
    color: #24002f;
    margin-bottom: 15px;
}

.modal-buttons {
    display: flex;
    justify-content: center;
    box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.16);
}

.card-title {
    margin-bottom: 0;
}

.data-area {
    padding-left: 16px;
    padding-right: 16px;
}

.label {
    font-size: 12px;
    font-weight: 500;
    color: #24002f;
}

.accordion {
    box-shadow: none;

    &--populated {
        :deep(.accordion__content) {
            padding-top: 12px;
        }

        :deep(.accordion__content-title) {
            margin-bottom: 0;
        }
    }

    &__item {
        background-color: transparent !important;
        border-top: none !important;
    }

    :deep(.v-expansion-panel__header) {
        padding: 0;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        padding-top: 24px;
        padding-bottom: 24px;
        font-weight: 500;
        font-size: 14px;
        color: #516173;
    }




    &__button {
        margin: 0;
        border-radius: 4px;
        min-width: 200px;
        height: 40px;

        :deep(.v-btn__content) {
            justify-content: space-between;
        }
    }

    &__icon {
        font-weight: bold;
    }
}

.has-margin {
    margin-bottom: 20px;
}
</style>
