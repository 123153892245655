<template>
    <section class="player">
        <v-form
            v-model="valid"
            ref="playerForm"
            lazy-validation
        >
            <v-layout class="main-body">
                <v-flex>
                    <h2 class="subheading">User details</h2>
                    <div class="player__panel">
                        <v-flex
                            align-center
                            class="player__panel-details"
                        >
                            <v-layout>
                                <div class="flex sm12 md5">
                                    <label
                                        class="label"
                                        for="firstName"
                                    >Email</label>
                                    <v-text-field
                                        id="username"
                                        :rules="emailRules"
                                        validate-on-blur
                                        placeholder=""
                                        solo
                                        flat
                                        v-model="updatedData.username"
                                        autocomplete="email"
                                        @change="updateData()"
                                    ></v-text-field>
                                </div>
                                <div class="flex sm12 md5 offset-md2">
                                </div>
                            </v-layout>
                            <v-layout>
                                <div class="flex sm12 md5">
                                    <label
                                        class="label"
                                        for="firstName"
                                    >First name</label>
                                    <v-text-field
                                        id="firstName"
                                        :rules="[rules.required]"
                                        validate-on-blur
                                        placeholder="First name"
                                        solo
                                        flat
                                        v-model="updatedData.firstName"
                                        autocomplete="given-name"
                                        @change="updateData()"
                                    ></v-text-field>
                                </div>
                                <div class="flex sm12 md5 offset-md2">
                                    <label
                                        class="label"
                                        for="lastName"
                                    >Last name</label>
                                    <v-text-field
                                        id="lastName"
                                        :rules="[rules.required]"
                                        validate-on-blur
                                        placeholder="Last name"
                                        solo
                                        flat
                                        v-model="updatedData.lastName"
                                        autocomplete="family-name"
                                        @change="updateData()"
                                    ></v-text-field>
                                </div>
                            </v-layout>
                        </v-flex>
                    </div>

                    <!-- <h2 class="subheading">Temporary password</h2>
                    <div class="player__panel">
                        <v-flex
                            align-center
                            class="player__panel-details"
                        >
                            <v-layout>
                                <div class="flex sm12 md5">
                                    <label
                                        class="label"
                                        for="firstName"
                                    >Password</label>
                                    <v-text-field
                                        id="tempPassword"
                                        :rules="passRules"
                                        validate-on-blur
                                        placeholder="Temp password"
                                        solo
                                        flat
                                        v-model="updatedData.tempPassword"
                                        @change="updateData()"
                                    ></v-text-field>
                                </div>
                                <div class="flex sm12 md5 offset-md2">
                                    This password will be sent to the user via email and will be required for their first login, at which
                                    point they will then set their own.
                                </div>
                            </v-layout>
                        </v-flex>
                    </div> -->
                </v-flex>
            </v-layout>
        </v-form>
        <StickyFooter
            :errorMessage="errorMessage"
            :status="status"
            :buttonText="'Create User'"
            @buttonClicked="create()"
        />
    </section>
</template>

<script>
import axios from "axios"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
    name: "SquadManagement",

    components: {
        StickyFooter
    },

    props: {
        data: {
            type: Object,
            required: false
        }
    },

    data: () => {
        return {
            api: `${process.env.VUE_APP_SSO_COGNITO_API}`,
            updatedData: {},
            status: "saved",
            errorMessage: "",
            dateModalDob: null,
            dateModalDoj: null,
            valid: false,
            saveDisabled: true,
            rules: {
                required: value => !!value || "Required."
                // groups: value => !!this.updatedData.isContent || 'Required.'
            },
            emailRules: [
                value => !!value || "Email address is required",
                value => /.+@.+/.test(value) || "Email must be valid"
            ],
            passRules: [
                value => !!value || "Password is required",
                value => (value && value.length >= 8) || "Password must be at least 8 characters",
                value => /.*[a-z].*/.test(value) || "Password must contain at least one lower case letter",
                value => /.*[A-Z].*/.test(value) || "Password must contain at least one upper case letter",
                value => /\d/.test(value) || "Password must contain at least one number",
                value => /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value) || "Password must contain at least one special character (e.g. ^ $ * . ! @ # % &)"
            ],
        }
    },

    mounted() {
        this.updatedData = {
            username: null,
            firstName: null,
            lastName: null,
            tempPassword: null
        }

        // this.generateTempPassword()

        this.dataLoaded = true
        this.status = "needsSaving"
        this.$store.commit("completeLoading")

    },

    methods: {
        updateData() {
            this.$emit("update", this.updatedData)
            console.log(this.updatedData);
        },
        create() {
            this.errorMessage = ""
            if (this.$refs.playerForm.validate()) {
                console.log("Validation passed");
                this.addUser();
            } else {
                this.errorMessage = "Certain entries are invalid or required"
            }
        },
        addUser() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                var data = {
                    given_name: this.updatedData.firstName,
                    family_name: this.updatedData.lastName,
                    email: this.updatedData.username,
                    email_verified: "false",
                    // password: this.updatedData.tempPassword,
                }
                axios({
                    method: "POST",
                    url: `${this.api}`,
                    headers: { "Authorization": jwt },
                    data: {
                        data: {
                            type: "user",
                            attributes: data
                        }
                    }
                })
                    .then(response => {
                        console.log("Saved SSO User:", response);
                        console.log("USER ADDED", response.data)
                        this.status = "saved";
                        if (response.status === 201) {
                            var userData = response.data.data
                            this.$router.push({
                                path: `/users/edit/${userData.attributes.sub}`
                            })
                        }
                    })
                    .catch(e => {
                        console.error("Problem adding SSO user", e);
                        this.status = "needsSaving";
                        this.errorMessage = "Problem adding SSO user";
                    });
            })
        },
        // generateTempPassword() {
        //     var alphaChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        //     var alphaLen = 10;
        //     var alphaString = Array(alphaLen).fill(alphaChars).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join("");
        //     var numChars = "1234567890";
        //     var numLen = 3;
        //     var numString = Array(numLen).fill(numChars).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join("");
        //     var otherChars = "!@£$%^&*()";
        //     var otherLen = 3;
        //     var otherString = Array(otherLen).fill(otherChars).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join("");
        //     var unshuffledString = alphaString + numString + otherString;
        //     this.updatedData.tempPassword = unshuffledString.split("").sort(function () { return 0.5 - Math.random() }).join("");
        // }
    }
}
</script>

<style
    lang="scss"
    scoped
>
.player {
    &__panel {
        border: 1px solid $lightgrey;
        background-color: $white;
        padding: 20px;
        margin-bottom: 20px;

        &-details {
            flex-direction: column;

            @media (min-width: 960px) {
                flex-direction: row;
            }
        }

        &-img {
            img {
                width: 100%;
            }
        }

        &-opta {
            &-cb {
                label {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.input {
    &__textfield {
        &--basic {
            margin-top: 0;
        }
    }
}
</style>
