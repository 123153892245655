<template>
    <section class="options">
        <landing-page-card
            v-for="(option, index) in managementOptions"
            :key="index"
            class="options-card"
            :heading="option.heading"
            :description="option.description"
            :buttonText="option.buttonText"
            :buttonLink="option.buttonLink"
            :image="option.image"
            :label="option.label"
        />
    </section>
</template>

<script>
import LandingPageCard from "@/components/Library/LandingPageCard";

export default {
    name: "TeamManagement",

    components: {
        LandingPageCard,
    },

    data: () => ({
        managementOptions: [
            {
                heading: "Squad Management",
                description:
                    "*This will take you to the manage squad section where you can set information for specific players.",
                buttonText: "Manage Squads",
                image: `assets/landing-page-options/teams_squads.png`,
                buttonLink: "/team-management/squads",
            },
            {
                heading: "Fixture Management",
                description:
                    "*This will take you to the manage fixtures section where you can set desired fixture data..",
                buttonText: "Manage Fixtures",
                image: "assets/landing-page-options/teams_fixtures.png",
                buttonLink: "/team-management/fixtures",
            },
            {
                heading: "Team Crest Management",
                description:
                    "*This will take you to the manage team crests section where you can set desired crests for specific areas.",
                buttonText: "Manage Crests",
                image: "assets/landing-page-options/teams_crests.png",
                buttonLink: "/team-management/team-crests",
            },
            {
                heading: "Competition Crest Management",
                description:
                    "*This will take you to the manage team crests section where you can set desired crests for specific areas.",
                image: `assets/landing-page-options/teams_competition.png`,
                // buttonText: 'Manage Competitions',
                buttonLink: "",
                label: "Coming Soon",
            },
        ],
    }),
};
</script>

<style scoped lang='scss'>
.options {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 24px 0;
}
</style>
