<template>

  <v-dialog
    lazy
    scrollable
    v-model="dialog"
    persistent
    fullscreen>

    <slot slot="activator"></slot>

    <v-card class="section-container">
      <v-card-title v-if="creatingRow" class="widget__heading">Add Row</v-card-title>
      <v-card-title v-else class="widget__heading">Edit Row</v-card-title>

      <v-card-text>
        <label class="label" for="rowTitle" title="rowTitle">Title</label>
        <v-text-field id="rowTitle" solo flat v-model="updatedData.rowTitle">
        </v-text-field>

        <label class="label" for="rowTargetUsers" title="rowTargetUsers">Target Users</label>
        <v-select
          id="rowTargetUsers"
          outline
          v-model="updatedData.rowTargetUsers"
          item-text="name"
          item-value="id"
          :items="[
            {name: 'All', id: ''},
            {name: 'Active users', id: 'active'},
            {name: 'User not logged in', id: 'not-logged-in'},
            {name: 'User requires activating', id: 'requires-activating'}
          ]"
          solo
          flat
        ></v-select>

        <label class="label" for="rowStyle" title="rowStyle">Row Style</label>
        <v-select
          id="rowStyle"
          outline
          v-model="updatedData.rowStyle"
          item-text="name"
          item-value="id"
          :items="[
            {name: 'Light mode', id: 'light'},
            {name: 'Dark mode', id: 'dark'},
          ]"
          solo
          flat
        ></v-select>

        <label class="label" for="rowMoreText" title="rowMoreText">More Button Text</label>
        <v-text-field id="rowMoreText" solo flat v-model="updatedData.rowMoreText">
        </v-text-field>

        <label class="label" for="rowMoreURL" title="rowMoreURL">More Button URL</label>
        <v-text-field id="rowMoreURL" solo flat v-model="updatedData.rowMoreURL" placeholder="https://">
        </v-text-field>

        <label class="label" for="rowType" title="rowType">Row Type</label>
        <v-select
          id="rowType"
          outline
          v-model="updatedData.rowType"
          item-text="name"
          item-value="id"
          :items="[
            {name: 'Carousel', id: 'carousel'},
            {name: 'List', id: 'list'},
          ]"
          solo
          flat
        ></v-select>

        <div class="list-wrap">
          <draggable
            v-if="updatedData.rowData"
            v-model="updatedData.rowData"
            :options="{
              ghostClass: 'ghost',
              dragClass: 'sortable-drag'
            }"
            @start="drag=true"
            @end="drag=false; status = 'needsSaving';">

            <transition-group name="list-complete" class="list">

              <div v-for="(item, index) in updatedData.rowData" class="list__item draggable-item" :key="item.id">
                <div class="featured__item-content">
                  <v-icon color="accent" class="draggable-item__handle-icon list__item-handle">open_with</v-icon>

                  <div
                    class="list__item-remove"
                    @click="removeItem(index)">
                    <v-icon color="accent">remove_circle</v-icon>
                  </div>

                  <ImageUploader :title="'Image'" :widthOptionEnabled="false" :mediaLibraryID="item.mediaLibraryID" @update="updateImage($event, index)"/>
                  
                  <label class="label" for="body" title="body">Body</label>
                  <v-textarea id="body" solo flat v-model="item.body">
                  </v-textarea>

                  <label class="label" for="url" title="url">URL</label>
                  <v-text-field id="url" solo flat v-model="item.url">
                  </v-text-field>
                </div>
              </div>

            </transition-group>
          </draggable>

          <v-btn @click="addItem()" color="accent" class="list__add-item">Add inner item</v-btn>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          flat
          class=""
          @click="cancelRow();"
        >
          Cancel
        </v-btn>

        <v-btn
          v-if="creatingRow"
          flat
          class="submit-button accent"
          @click="addRow();"
        >
          Add
        </v-btn>

        <v-btn
          v-else
          flat
          class="submit-button accent"
          @click="editRow();"
        >
          Edit
        </v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import draggable from "vuedraggable"
import ImageUploader from "@/components/Library/ImageUploader"

export default {
  name: "EditRewardsRow",

  components: {
    draggable,
    ImageUploader
  },

  props: {
    rowData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    dialog: true,
    creatingRow: true,
    updatedData: {
      rowID: null,
      rowTargetUsers: "", // empty = show all / not-logged-in / active / requires-activating
      rowTitle: "",
      rowStyle: "light",
      rowMoreText: "", // optional
      rowMoreURL: "", // optional
      rowType: "",
      rowData: []
    }
  }),

  mounted() {
    console.log("Mounted", this.rowData);
    if (this.rowData) {
      console.log("Editing row")
      this.creatingRow = false
      this.updatedData = JSON.parse(JSON.stringify(this.rowData))
    } else {
      console.log("New row")
      this.creatingRow = true
    }
  },
  
  methods: {
    addRow() {
      console.log("Add ow", this.updatedData)
      this.updatedData.rowID = this.$uuid.v1()
      this.$emit("addRow", this.updatedData);
    },

    editRow() {
      console.log("editRow", this.updatedData)
      this.$emit("editRow", this.updatedData);
    },

    cancelRow() {
      this.$emit("closeRow");
    },

    addItem() {
      console.log("Add item")
      this.updatedData.rowData.push({
        id: this.$uuid.v1(),
        imageKey: "",
        mediaLibraryID: "",
        body: "",
        url: "",
      })
    },

    removeItem(index) {
      this.updatedData.rowData.splice(index, 1);
    },

    updateImage(payload, index) {
      console.log("updateImage", payload)
      this.updatedData.rowData[index].mediaLibraryID = payload.mediaLibraryID
      this.updatedData.rowData[index].imageKey = payload.imageKey
    },
  }
}
</script>

<style scoped lang="scss">

.section-container {
  margin: 0;
  padding: 0 32px 16px;
}

.section-divider {
  padding-top: 16px;
}

.submit-button {
  margin: 0;
  margin-left: auto;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
}

.list-wrap {
  margin: 0;
  padding: 32px 0;
  border: 1px solid rgba($purple, 0.7);
  background-color: rgba($purple, 0.1);
  border-radius: 3px;
}

  .draggable-item {
    transition: all 0.5s, opacity 0.2s;

    &__handle-icon {
      cursor: pointer;
      padding-right: 20px;
    }
  }

.list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  &__add-item {
    margin: 16px 32px 0;
  }

  &__item {
    position: relative;
    padding: 16px;
    margin: 0 32px 32px;
    width: calc(33.33% - 64px);
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 6px;
  }

  &__item-handle {
    position: absolute;
    top: -15px;
    left: -15px;
    background: #fafafa;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 3px;
  }

  &__item-remove {
    position: absolute;
    top: -15px;
    right: -15px;
    background: #fafafa;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 3px;
  }
}

</style>
