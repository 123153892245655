<template>
  <section>
    <h3 class="subheading">Page Details</h3>
    <section class="section-container">
      <build-notice
        v-if="updatedData.postType === 'external'"
        :mode="'full'"
        class="build-notice"
      />

      <p class="page-info" v-if="updatedData.postType === 'internalLink'">
        This allows you to duplicate pages in any generated navigation on your
        website. The page can be named differently but allow you to link to the
        same page via different places in the content hierarchy.<br />The page
        WILL NOT be generated in this position.
      </p>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="page-name">Page Title</label>
          <v-text-field
            id="page-name"
            v-model="updatedData.postTitle"
            solo
            flat
            required
            @focus="pageTitleFocus = true"
            @keyup.native="createSlug()"
            @change="
              updateData();
              populateSlug();
            "
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>*Required - Page title</i></p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="modules.default.PagesAltTitles">
        <v-flex mr-5 class="half">
          <label class="label" for="page-link-name"
            >Menu Link Alternative Title</label
          >
          <v-text-field
            id="page-link-name"
            v-model="updatedData.menuPostTitle"
            solo
            flat
            @focus="pageTitleFocus = true"
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >If filled, this field determines how the title displays on the
              menu</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="showPageTypePicker">
        <v-flex mr-5 class="half">
          <label class="label" for="post-type">Post Type</label>
          <v-select
            id="page-type"
            v-bind:items="pageTypeOptions"
            v-model="updatedData.postType"
            solo
            flat
            required
            @change="
              updatePageType();
              updateData();
            "
          ></v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>The page type defaults to a standard internal website page</i>
          </p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="updatedData.postType === 'internalLink'">
        <v-flex mr-5 class="half">
          <label class="label" for="internal-link">Page Link</label>
          <page-selector
            class="internal-page-selector"
            @pageSelected="setInternalLink($event)"
            @pageUrl="setInternalPageSlug($event)"
          >
            <v-btn
              class="image-editor__image-upload"
              color="accent"
              type="button"
              depressed
            >
              <v-icon small>add</v-icon>
              Choose a page
            </v-btn>
          </page-selector>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Select the internal page that you want to link to.</i></p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="updatedData.postType === 'external'">
        <v-flex mr-5 class="half">
          <label class="label" for="external-url">URL</label>
          <v-text-field
            id="external-url"
            v-model="updatedData.externalUrl"
            solo
            flat
            required
            @change="updatePageType()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>The page type defaults to a standard internal website page</i>
          </p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label
            v-if="updatedData.postType != 'internalLink'"
            class="label"
            for="page-slug"
            >Slug</label
          >
          <v-text-field
            v-if="updatedData.postType != 'internalLink'"
            :disabled="
              updatedData.postType === 'homepage' ||
              updatedData.postType === 'external' ||
              updatedData.postType === 'internalLink'
            "
            id="page-slug"
            v-model="updatedData.postSlug"
            solo
            flat
            required
            @focus="pageSlugFocus = true"
            @keyup.native="formatURL()"
            @change="formatURL()"
          ></v-text-field>

          <p v-if="updatedData.postType != 'external'" class="data-note">
            <span v-if="updatedData.postType != 'internalLink'"
              >Full path*: /</span
            >
            <span v-else>Links to*: /</span>{{ updatedData.fullPath }}
          </p>
        </v-flex>
        <v-flex mt-4 class="half" v-if="updatedData.postType != 'internalLink'">
          <p><i>*Required - Page slug</i></p>

          <p v-if="updatedData.postType === 'homepage'">
            <i
              >The slug is automatically generated when setting your post type
              to a 'homepage'.</i
            >
          </p>
          <p v-else-if="updatedData.postType === 'external'">
            <i
              >The slug is automatically generated when setting your post type
              to 'External'.</i
            >
          </p>
          <p v-else-if="updatedData.postType === 'internalLink'">
            <i>This will be the slug for the page you are linking to.</i>
          </p>

          <p v-if="updatedData.postType != 'external'">
            <i
              >Note: Full paths are only updated upon saving the website's page
              hierarchy.</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="updatedData.postType === 'external'">
        <v-flex mr-5 class="half">
          <label class="label" for="new-tab">Open in new tab?</label>
          <v-select
            id="new-tab"
            v-bind:items="newTabOptions"
            v-model="updatedData.newTab"
            solo
            flat
            required
            @change="updatePageType()"
          ></v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Determine whether the user should remain in the current browser
              tab or directed to a new one.</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="page-summary">Summary</label>
          <v-textarea
            id="page-summary"
            v-model="updatedData.postSummary"
            solo
            flat
            @change="updateData()"
          ></v-textarea>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This is used on the summary cards and should be kept to a
              minimum</i
            >
          </p>
        </v-flex>
      </v-layout>
    </section>

    <template v-if="updatedData.postType != 'homepage'">
      <h3 class="subheading">Images</h3>
      <section class="section-container">
        <template
          v-if="
            updatedData.postType === 'internal' ||
            updatedData.postType === undefined
          "
        >
          <v-layout row class="section-divider">
            <v-flex mr-5 class="half">
              <v-checkbox
                v-model="updatedData.showHeroImages"
                :label="`Show Hero Images`"
                @change="updateData()"
              ></v-checkbox>
            </v-flex>
            <v-flex mt-4 class="half">
              <p><i>Show hero images</i></p>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-2>
              <ImageUploader
                :title="'Hero Image - Large/Desktop'"
                :widthOptionEnabled="false"
                :mediaLibraryID="updatedData.mediaLibraryID"
                @update="updateImage($event, 'mediaLibraryID')"
                :desc="'<p>Recommended sizes:</p><p>Deep: 1920x1080 or highest res 16:9 image available</p> <p>Shallow: 1920x480</p><p>Used for Social Sharing</p>'"
              />
            </v-flex>
          </v-layout>

          <v-layout row class="section-divider">
            <v-flex mr-5 mt-3 class="half">
              <label class="label" for="hero-style--large"
                >Hero Style - Large/Desktop</label
              >
              <v-select
                id="hero-style--large"
                v-bind:items="heroStyleOptions"
                v-model="updatedData.heroStyleLarge"
                solo
                flat
                @change="updateData()"
              >
              </v-select>
            </v-flex>
            <v-flex mt-4 class="half">
              <p><i>Hero style - large</i></p>
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex mr-2>
              <ImageUploader
                :title="'Hero Image - Small/Mobile'"
                :widthOptionEnabled="false"
                :mediaLibraryID="updatedData.heroSmallMediaLibraryID"
                @update="updateImage($event, 'heroSmallMediaLibraryID')"
                :desc="'<p>Recommended sizes:</p><p>Deep: 1160x1280 8:9 Image</p><p>Shallow: 1920x1080 or highest res 16:9 image available</p>'"
              />
            </v-flex>
          </v-layout>

          <v-layout row class="section-divider">
            <v-flex mr-5 mt-3 class="half">
              <label class="label" for="hero-style--small"
                >Hero Style - Small/Mobile</label
              >
              <v-select
                id="hero-style--small"
                v-bind:items="heroStyleOptions"
                v-model="updatedData.heroStyleSmall"
                solo
                flat
                @change="updateData()"
              >
              </v-select>
            </v-flex>
            <v-flex mt-4 class="half">
              <p><i>Hero style - small</i></p>
            </v-flex>
          </v-layout>
        </template>

        <v-layout row>
          <v-flex mr-2>
            <ImageUploader
              :title="'Listing Image'"
              :widthOptionEnabled="false"
              :mediaLibraryID="updatedData.listingMediaLibraryID"
              @update="updateImage($event, 'listingMediaLibraryID')"
              :desc="'<p>Recommended size:</p> <p>1600x900</p><p>If no listing image is provided then the main hero image will act as a fallback</p>'"
            />
          </v-flex>
        </v-layout>
      </section>
    </template>

    <h3 class="subheading">Style</h3>
    <section class="section-container">
      <v-layout
        row
        v-if="
          updatedData.postType === 'internal' ||
          updatedData.postType === undefined
        "
      >
        <v-flex mr-5 class="half">
          <label class="label" for="page-template">Page Template</label>
          <v-select
            id="page-template"
            v-bind:items="pageTemplateOptions"
            v-model="updatedData.pageTemplate"
            solo
            flat
            @change="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Page Template</i></p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="availableTags">
        <v-flex mr-5 class="half">
          <label class="label" for="custom-category">Custom Tag</label>
          <v-select
            id="custom-category"
            v-bind:items="availableTags"
            item-text="name"
            item-value="value"
            v-model="updatedData.customTag"
            solo
            flat
            @change="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Custom tags</i></p>
        </v-flex>
      </v-layout>
    </section>

    <template v-if="modules.default.AllowCustomFields">
      <h3 class="subheading">Custom Fields</h3>
      <section class="section-container">
        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="custom-field-1">{{
              customDataFieldLabels.customDataFieldOneName || "Custom field 1"
            }}</label>
            <v-text-field
              id="custom-field-1"
              v-model="updatedData.customDataField1"
              solo
              flat
              @change="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i>{{
                customDataFieldLabels.customDataFieldOneDescription ||
                "Custom field 1"
              }}</i>
            </p>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="custom-field-2">{{
              customDataFieldLabels.customDataFieldTwoName || "Custom field 2"
            }}</label>
            <v-text-field
              id="custom-field-2"
              item-text="name"
              item-value="value"
              v-model="updatedData.customDataField2"
              solo
              flat
              @change="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i>{{
                customDataFieldLabels.customDataFieldTwoDescription ||
                "Custom field 2"
              }}</i>
            </p>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex mr-5 class="half">
            <label class="label" for="custom-field-3">{{
              customDataFieldLabels.customDataFieldThreeName || "Custom field 3"
            }}</label>
            <v-text-field
              id="custom-field-3"
              v-bind:items="availableTags"
              item-text="name"
              item-value="value"
              v-model="updatedData.customDataField3"
              solo
              flat
              @change="updateData()"
            >
            </v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p>
              <i>{{
                customDataFieldLabels.customDataFieldThreeDescription ||
                "Custom field 3"
              }}</i>
            </p>
          </v-flex>
        </v-layout>
      </section>
    </template>

    <h3 class="subheading">Publishing</h3>
    <section class="section-container">
      <v-layout
        row
        class="section-divider"
        v-if="
          updatedData.postType === 'internal' ||
          updatedData.postType === undefined
        "
      >
        <v-flex mr-5 class="half">
          <label class="label" for="enabled">Child targeted page?</label>
          <v-select
            id="enabled"
            outline
            v-model="updatedData.childFriendly"
            item-text="name"
            item-value="id"
            :items="[
              { name: 'Yes, remove inappropriate adverts', id: true },
              { name: 'No, leave all adverts as they are', id: false },
            ]"
            solo
            flat
            @change="updateData()"
          ></v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            If your content is targeted towards children, turn
            <b>Child Friendly on</b> to remove gambling adverts etc
          </p>
        </v-flex>
      </v-layout>

      <v-layout
        row
        class="section-divider"
        v-if="
          updatedData.postType === 'internal' ||
          updatedData.postType === undefined
        "
      >
        <v-flex mr-5 class="half">
          <label class="label" for="enabled">Disable Advertising</label>
          <v-select
            id="enabled"
            outline
            v-model="updatedData.disableAdvertising"
            item-text="name"
            item-value="id"
            :items="[
              { name: 'Yes, remove all adverts', id: true },
              { name: 'No, leave all adverts as they are', id: false },
            ]"
            solo
            flat
            @change="updateData()"
          ></v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>Remove all advertising from the website version of this post</i>
          </p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="page-status">Page Status</label>
          <v-select
            id="page-status"
            v-bind:items="pageStatusOptions"
            v-model="updatedData.postStatus"
            solo
            flat
            @change="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Page status</i></p>
        </v-flex>
      </v-layout>
    </section>

    <SavedBy :saveData="saveData" />
  </section>
</template>

<script>
import axios from "axios";
import SavedBy from "@/components/Library/SavedBy";
import ImageUploader from "@/components/Library/ImageUploader";
import BuildNotice from "@/components/Library/BuildNotice/BuildNotice";
import PageSelector from "@/components/Library/PageSelector";

export default {
  name: "PageConfiguration",

  components: {
    SavedBy,
    ImageUploader,
    BuildNotice,
    PageSelector,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    saveData: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      pageTemplateOptions: [],
      customDataField1: "",
      customDataField2: "",
      customDataField3: "",
      customDataFieldLabels: "",
      heroStyleOptions: ["Deep", "Shallow"],
      pageStatusOptions: ["Published", "Draft", "Hidden"],
      newTabOptions: [
        { text: "Yes, open in new tab", value: true },
        { text: "No, remain in current tab", value: false },
      ],
      dateModal: false,
      timeModal: false,
      originalSlug: null,
      tempPostSlug: null,
      pageTitleFocus: false,
      pageSlugFocus: false,
      updatedData: {},
      betaFeatures: null,
      modules: null,
      customTags: null,
      availableTags: null,
      showPageTypePicker: false,
      pageTypeOptions: null,
    };
  },

  created() {
    this.betaFeatures = require("../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/beta.js");
    this.modules = require("../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
    this.defaultSettings = require("../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/defaultSettings.js");
    this.customDataFieldLabels = this.defaultSettings.default.customDataFields;
  },

  mounted() {
    if (this.betaFeatures.default.HubPages) {
      this.pageTemplateOptions = ["Default", "Landing", "Hub"];
    } else {
      this.pageTemplateOptions = ["Default", "Landing"];
    }

    if (this.modules.default.CustomTags) {
      this.availableTags = this.defaultSettings.default.customTags;
    } else {
      this.availableTags = null;
    }

    if (this.modules.default.AdditionalPageTypes) {
      this.showPageTypePicker = true;
      this.pageTypeOptions = this.defaultSettings.default.pageTypes;
    }

    this.originalSlug = this.data.postSlug;
    this.updatedData = {
      postID: this.data.postID,
      postTitle: this.data.postTitle,
      menuPostTitle: this.data.menuPostTitle,
      postStatus: this.data.postStatus,
      postType: this.data.postType,
      pageTemplate: this.data.pageTemplate,
      heroStyleLarge: this.data.heroStyleLarge,
      heroStyleSmall: this.data.heroStyleSmall,
      showHeroImages: this.data.showHeroImages,
      postSummary: this.data.postSummary,
      postSlug: this.data.postSlug,
      fullPath: this.data.fullPath,
      linkSlug: this.data.linkSlug,
      publishedDate: this.data.publishedDate,
      publishedTime: this.data.publishedTime,
      mediaLibraryID: this.data.mediaLibraryID,
      heroSmallMediaLibraryID: this.data.heroSmallMediaLibraryID,
      listingMediaLibraryID: this.data.listingMediaLibraryID,
      childFriendly: this.data.childFriendly,
      disableAdvertising: this.data.disableAdvertising,
      customTag: this.data.customTag,
      externalUrl: this.data.externalUrl,
      newTab: this.data.newTab,
      customDataField1: this.data.customDataField1,
      customDataField2: this.data.customDataField2,
      customDataField3: this.data.customDataField3,
    };
  },

  methods: {
    updateData() {
      this.$emit("update", this.updatedData);
    },

    updatePageType() {
      if (this.updatedData.postType === "homepage") {
        this.checkHomepageExists()
          .then(() => {
            // Dont allow setting
            alert("Homepage already exists. Setting back to default");

            this.updatedData.postType = "internal";
            this.updateData();
          })
          .catch((response) => {
            console.log("checkHomepageExists", response);
            // Change to homepage

            this.updatedData.postSlug = "homepage";
            this.updateData();
          });
      } else if (this.updatedData.postType === "external") {
        this.updatedData.postSlug = this.$uuid.v1();
        this.updateData();
      } else if (this.updatedData.postType === "internalLink") {
        this.updatedData.postSlug = this.$uuid.v1();
        this.updateData();
      } else {
        if (this.updatedData.postSlug === "homepage") {
          this.updatedData.postSlug = "";
        }
      }
    },

    checkHomepageExists() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_WEBAPI}/pages/byfullpath?fullPath=homepage`
          )
          .then((response) => {
            console.log("check homepage: ", response);
            resolve("Success");
          })
          .catch((e) => {
            console.error("Problem checking homepage: ", e);
            reject("Fail");
          });
      });
    },

    createSlug() {
      this.tempPostSlug = "";
      if (!this.updatedData.postSlug) {
        //if a slug was not set when the interface was loaded, we let the title control it's generation
        this.tempPostSlug = this.updatedData.postTitle.replace(" ", "-");
        this.tempPostSlug = this.tempPostSlug
          .replace(/[^a-zA-Z0-9-_]/g, "")
          .toLowerCase();
      }
    },

    populateSlug() {
      if (!this.updatedData.postSlug) {
        this.updatedData.postSlug = this.tempPostSlug;
      }
    },

    formatURL() {
      this.manualSlugEdit = true;
      this.updatedData.postSlug = this.updatedData.postSlug
        .replace(/[^a-zA-Z0-9-_]/g, "")
        .toLowerCase();
      this.updateData();
    },

    updateImage(payload, target) {
      console.log("Update image:", payload, target);

      this.updatedData[target] = payload.mediaLibraryID;

      this.updateData();
    },

    setInternalLink(id) {
      console.log("setting internal link", id);
    },
    setInternalPageSlug(slug) {
      console.log("setting page slug", slug);
      // this.updatedData.fullPath = slug
      // this.updatedData.postSlug = slug
      this.updatedData.linkSlug = slug;
    },
  },
};
</script>

<style lang="scss" scoped>
.narrow-column {
  max-width: 380px;
  width: 100%;
}

.disclaimer {
  font-size: 0.7em;
  font-weight: 400;
}

.images-wrap {
  padding: 8px 16px;
  margin-bottom: 16px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ebebeb;
}

.half {
  width: 50%;
}

.section-divider {
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dfe3e9;
}

.data-note {
  padding: 4px;
  margin: -8px 0 32px;
  font-size: 12px;
  color: #fff;
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.build-notice {
  margin-bottom: 16px;
}

.page-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f6d2;
  padding: 8px 16px;
  border: 1px solid #d6d8b0;
  border-radius: 8px;

  &__warning {
    margin-bottom: 0;
  }
}

.internal-page-selector {
  margin-bottom: 16px;
}
</style>
