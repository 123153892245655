<template>
    <section>
        <h2>Add Content</h2>

        <div class="content-options">
            <div class="content-type">
                <!-- <label class="label">Select Content Type</label> -->

                <v-select
                    :items="contentTypes"
                    v-model="newContentBlock.contentType"
                    label="Select Content Type"
                ></v-select>
            </div>

            <section
                class="new-content"
                v-if="newContentBlock.contentType"
            >
                <v-form
                    v-model="valid"
                    ref="formDetails"
                    lazy-validation
                    class="full-width-block"
                >
                    <template v-if="newContentBlock.contentType === 'twitterTemp'">
                        <!-- <h2>Adding a twitter block...</h2> -->

                        <v-textarea
                            :rules="[rules.required]"
                            label="Text (html)"
                            v-model="newContentBlock.content.html"
                            validate-on-blur
                        />

                        <v-text-field
                            :rules="[rules.required]"
                            placeholder="https://x.com/"
                            label="External URL"
                            v-model="newContentBlock.content.externalUrl"
                            validate-on-blur
                        />

                        <v-text-field
                            :rules="[rules.required]"
                            placeholder="@username"
                            label="X Handle"
                            v-model="newContentBlock.content.handle"
                            validate-on-blur
                        />

                        <ImageUploader
                            :title="'Image'"
                            :desc="'Content feed images should be 1:1 ratio, and is recommended to be set to a single width block.'"
                            :widthOptionEnabled="false"
                            :mediaLibraryID="newContentBlock.content.mediaLibraryID"
                            @update="updateImage($event)"
                        />
                    </template>

                    <template v-if="newContentBlock.contentType === 'instagramTemp'">
                        <!-- <h2>Adding an instagram block...</h2> -->

                        <v-text-field
                            :rules="[rules.required]"
                            placeholder="https://www.instagram.com/"
                            label="External URL"
                            v-model="newContentBlock.content.externalUrl"
                            validate-on-blur
                        />

                        <v-text-field
                            :rules="[rules.required]"
                            placeholder="@username"
                            label="Instagram Handle"
                            v-model="newContentBlock.content.handle"
                            validate-on-blur
                        />

                        <ImageUploader
                            :title="'Image'"
                            :desc="'Content feed images should be 1:1 ratio, and is recommended to be set to a single width block.'"
                            :widthOptionEnabled="false"
                            :mediaLibraryID="newContentBlock.content.mediaLibraryID"
                            @update="updateImage($event)"
                        />
                    </template>

                    <template v-if="newContentBlock.contentType === 'match'">
                        <!-- <h2>Adding a match...</h2> -->

                        <content-feed-match-picker
                            :content="newContentBlock.content"
                            @updated="updateMatch($event)"
                        />

                        <div class="item match-width">
                            <v-select
                                label="Width in feed"
                                :items="widthOptions"
                                :item-name="widthOptions.text"
                                v-model="newContentBlock.width"
                            ></v-select>
                        </div>
                    </template>

                    <template v-if="newContentBlock.contentType === 'streamVid'">
                        <!-- <h2>Adding a stream video...</h2> -->
                        <div class="field-wrapper">
                            <div
                                class="item"
                                v-if="newContentBlock.content"
                            >
                                <v-text-field
                                    :rules="[rules.required]"
                                    label="Add Stream ID"
                                    v-model="newContentBlock.content.streamID"
                                    validate-on-blur
                                />
                            </div>
                            <div class="item">
                                <v-text-field
                                    :rules="[rules.required]"
                                    label="Video Title"
                                    v-model="newContentBlock.content.title"
                                    validate-on-blur
                                />
                            </div>
                            <div class="item">
                                <v-select
                                    label="Width in feed"
                                    :items="widthOptions"
                                    :item-name="name"
                                    v-model="newContentBlock.width"
                                ></v-select>
                            </div>
                        </div>

                        <ImageUploader
                            :title="'Stream image override'"
                            :desc="'Content feed images should be 1:1 ratio, and is recommended to be set to a single width block.'"
                            :widthOptionEnabled="false"
                            :mediaLibraryID="newContentBlock.content.mediaLibraryID"
                            @update="updateImage($event)"
                        />
                    </template>

                    <template v-if="newContentBlock.contentType === 'streamlineVid'">
                        <!-- <h2>Adding a streamline video...</h2> -->
                        <div class="field-wrapper">
                            <div
                                class="item"
                                v-if="newContentBlock.content"
                            >
                                <v-text-field
                                    :rules="[rules.required]"
                                    label="Add Streamline ID"
                                    v-model="newContentBlock.content.streamID"
                                    validate-on-blur
                                />
                            </div>
                            <div class="item">
                                <v-text-field
                                    :rules="[rules.required]"
                                    label="Video Title"
                                    v-model="newContentBlock.content.title"
                                    validate-on-blur
                                />
                            </div>
                            <div class="item">
                                <v-select
                                    label="Width in feed"
                                    :items="widthOptions"
                                    :item-name="name"
                                    v-model="newContentBlock.width"
                                ></v-select>
                            </div>
                        </div>

                        <p
                            v-if="imageError"
                            class="error-message"
                        >Image Required.</p>
                        <ImageUploader
                            :title="'Image'"
                            :desc="'Content feed images should be 1:1 ratio, and is recommended to be set to a single width block.'"
                            :widthOptionEnabled="false"
                            :mediaLibraryID="newContentBlock.content.mediaLibraryID"
                            @update="updateImage($event)"
                        />
                    </template>
                    <template v-if="newContentBlock.contentType === 'socialMediaEmbed'">
                        <v-select
                            class="full-width-block"
                            label="Type"
                            :items="socialMediaOptions"
                            item-text="name"
                            item-value="value"
                            v-model="newContentBlock.content.socialMediaType"
                        ></v-select>
                        <v-textarea
                            class="full-width-block"
                            :rules="[rules.required]"
                            label="Embed Text (html)"
                            v-model="newContentBlock.content.html"
                            validate-on-blur
                        />
                    </template>

                </v-form>

                <v-btn @click="addContent()">Add To Feed</v-btn>
            </section>
        </div>

        <h2>Preview</h2>

        <content-feed-preview />
    </section>
</template>

<script>
import ImageUploader from "@/components/Library/ImageUploader";
import ContentFeedPreview from "../../components/ContentFeed/ContentFeedPreview";
import ContentFeedMatchPicker from "../../components/ContentFeed/ContentFeedMatchPicker";

export default {
    name: "ContentFeedContent",

    components: {
        ImageUploader,
        ContentFeedPreview,
        ContentFeedMatchPicker,
    },

    props: {
        data: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        betaFeatures: null,
        modules: null,
        imageError: false,
        newContentBlock: {
            contentType: null,
            content: {},
        },
        rules: {
            required: (value) => !!value || "Required.",
        },
        widthOptions: [
            { text: "Single", value: "single" },
            { text: "Double", value: "double" },
        ],
        contentTypes: [],
        socialMediaOptions: [
            { name: 'Twitter / X', value: 'twitter' },
            { name: 'Instagram', value: 'instagram' },
            { name: 'Facebook', value: 'facebook' },
            { name: 'Tik Tok', value: 'tiktok' },
            { name: 'Custom', value: 'custom' },
        ]
    }),

    created() {
        this.betaFeatures = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/beta.js");
        this.modules = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");
    },

    mounted() {
        this.contentTypes.push({ text: "Match", value: "match" });

        if (this.modules.default.Streamline) {
            this.contentTypes.push({
                text: "Streamline Video",
                value: "streamlineVid",
            });
        } else {
            this.contentTypes.push({ text: "Stream Video", value: "streamVid" });
        }

        if (this.modules.default.SocialContentFeed) {
            this.contentTypes.push(
                { text: 'Social Media Embed (App only)', value: 'socialMediaEmbed' },
            )
        }

        if (this.betaFeatures.default.TempSocialFeed)
            this.contentTypes.push(
                { text: "Instagram [Temp]", value: "instagramTemp" },
                { text: "X [Temp]", value: "twitterTemp" }
            );
    },

    methods: {
        addContent() {
            this.imageError = false;
            if (this.$refs.formDetails.validate()) {
                if (this.newContentBlock.contentType == "streamlineVid") {
                    if (
                        this.newContentBlock.mediaLibraryID &&
                        this.newContentBlock.imageKey
                    ) {
                        this.$emit("addingPost", this.newContentBlock);
                        this.newContentBlock = {
                            contentType: null,
                            content: {},
                        };
                    } else {
                        this.imageError = true;
                    }
                } else {
                    // console.log("what is being emitted on Add???", this.newContentBlock);
                    this.$emit("addingPost", this.newContentBlock);
                    this.newContentBlock = {
                        contentType: null,
                        content: {},
                    };
                }
            } else {
                this.$emit("error");
            }
        },

        updateMatch(matchData) {
            console.log("new content block status", this.newContentBlock, matchData);
            this.newContentBlock.content = matchData;

            console.error("have I set the match data", this.newContentBlock);
        },

        updateImage(payload) {
            this.imageError = false;
            this.newContentBlock.mediaLibraryID = payload.mediaLibraryID;
            this.newContentBlock.imageKey = payload.imageKey;
        },
    },
};
</script>

<style scoped lang='scss'>
section {
    padding: 20px;
    margin: 0 0 40px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.content-options {
    display: flex;
    flex-direction: column;
}

.new-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 16px;
    background-color: #fafafa;
}

.add-content {
    flex-grow: 0;
}

.field-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 8px;
    margin-bottom: 16px;
}

.item {
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
    padding-right: 16px;

    // &:nth-child(odd) {
    //   padding-right: 16px;
    // }
}

.match-width {
    max-width: 33%;
}

.error-message {
    color: rgb(255, 82, 82);
}

.full-width-block {
    width: 100%;
}
</style>
