<script>
export default [{
    "optaId": 40431,
    "name": "Estes Morin",
    "position": "Wingback",
    "squadNumber": 25
  },
  {
    "optaId": 44568,
    "name": "Gonzales Lester",
    "position": "Attacking Midfielder",
    "squadNumber": 10
  },
  {
    "optaId": 28559,
    "name": "Beck Fitzpatrick",
    "position": "Central Midfielder",
    "squadNumber": 23
  },
  {
    "optaId": 15798,
    "name": "Knapp Valdez",
    "position": "Winger",
    "squadNumber": 15
  },
  {
    "optaId": 68523,
    "name": "Emerson Hart",
    "position": "Attacking Midfielder",
    "squadNumber": 36
  },
  {
    "optaId": 22616,
    "name": "Kelly Perez",
    "position": "Goalkeeper",
    "squadNumber": 9
  },
  {
    "optaId": 59374,
    "name": "Ramsey Booker",
    "position": "Central Midfielder",
    "squadNumber": 33
  },
  {
    "optaId": 1513,
    "name": "Dickson Suarez",
    "position": "Forward",
    "squadNumber": 3
  },
  {
    "optaId": 33591,
    "name": "Daniels Wade",
    "position": "Goalkeeper",
    "squadNumber": 10
  },
  {
    "optaId": 13512,
    "name": "Sosa Charles",
    "position": "Defensive Midfielder",
    "squadNumber": 26
  },
  {
    "optaId": 48694,
    "name": "Willis Park",
    "position": "Central Midfielder",
    "squadNumber": 39
  },
  {
    "optaId": 2601,
    "name": "Pittman Guzman",
    "position": "Goalkeeper",
    "squadNumber": 21
  },
  {
    "optaId": 40732,
    "name": "Nunez Perry",
    "position": "Wingback",
    "squadNumber": 9
  },
  {
    "optaId": 46765,
    "name": "Pearson Gonzalez",
    "position": "Wingback",
    "squadNumber": 34
  },
  {
    "optaId": 43293,
    "name": "Jennings Whitney",
    "position": "Goalkeeper",
    "squadNumber": 32
  },
  {
    "optaId": 29762,
    "name": "Dejesus Levy",
    "position": "Winger",
    "squadNumber": 14
  },
  {
    "optaId": 52804,
    "name": "Mckay Bonner",
    "position": "Defensive Midfielder",
    "squadNumber": 33
  },
  {
    "optaId": 76879,
    "name": "Wiggins Wilson",
    "position": "Goalkeeper",
    "squadNumber": 7
  },
  {
    "optaId": 2873,
    "name": "May William",
    "position": "Goalkeeper",
    "squadNumber": 39
  },
  {
    "optaId": 49027,
    "name": "Patrick Brady",
    "position": "Wingback",
    "squadNumber": 26
  },
  {
    "optaId": 52614,
    "name": "Gardner Villarreal",
    "position": "Defensive Midfielder",
    "squadNumber": 40
  },
  {
    "optaId": 76905,
    "name": "Floyd Crawford",
    "position": "Defensive Midfielder",
    "squadNumber": 6
  },
  {
    "optaId": 3793,
    "name": "Reese Dorsey",
    "position": "Forward",
    "squadNumber": 22
  },
  {
    "optaId": 15516,
    "name": "Clemons James",
    "position": "Forward",
    "squadNumber": 35
  },
  {
    "optaId": 77071,
    "name": "Wise Clements",
    "position": "Winger",
    "squadNumber": 13
  },
  {
    "optaId": 19840,
    "name": "Elliott Benson",
    "position": "Attacking Midfielder",
    "squadNumber": 19
  },
  {
    "optaId": 32395,
    "name": "Howard Rivas",
    "position": "Centeral Defender",
    "squadNumber": 17
  },
  {
    "optaId": 17690,
    "name": "Price Gray",
    "position": "Wingback",
    "squadNumber": 7
  },
  {
    "optaId": 26512,
    "name": "Manning Hull",
    "position": "Defensive Midfielder",
    "squadNumber": 31
  },
  {
    "optaId": 64743,
    "name": "Berg Meadows",
    "position": "Wingback",
    "squadNumber": 1
  },
  {
    "optaId": 9225,
    "name": "Bright Wright",
    "position": "Centeral Defender",
    "squadNumber": 38
  },
  {
    "optaId": 8478,
    "name": "Paul Barron",
    "position": "Centeral Defender",
    "squadNumber": 31
  },
  {
    "optaId": 72918,
    "name": "Ewing Orr",
    "position": "Defensive Midfielder",
    "squadNumber": 3
  },
  {
    "optaId": 72424,
    "name": "Carpenter Bradley",
    "position": "Forward",
    "squadNumber": 10
  },
  {
    "optaId": 24184,
    "name": "Stanley Witt",
    "position": "Winger",
    "squadNumber": 32
  },
  {
    "optaId": 60904,
    "name": "Bass Bailey",
    "position": "Winger",
    "squadNumber": 5
  },
  {
    "optaId": 10977,
    "name": "Wagner Jacobs",
    "position": "Winger",
    "squadNumber": 24
  },
  {
    "optaId": 22798,
    "name": "Ellison Dunn",
    "position": "Wingback",
    "squadNumber": 14
  },
  {
    "optaId": 40602,
    "name": "Bonner Clay",
    "position": "Wingback",
    "squadNumber": 19
  }
]
</script>
