<template>
  <v-tabs v-model="active">

    <v-tab :ripple="{ class: 'accent--text' }">Advert Manager</v-tab>

    <v-tab-item
      px-0
      class="main-body">

      <p class="note">This module is in an alpha release state and may change. Please raise any issues in freshdesk.</p>

      <advertising-module-v1-manager />

    </v-tab-item>

    <v-tab :ripple="{ class: 'accent--text' }">Analytics</v-tab>

    <v-tab-item
      px-0
      class="main-body">

      <p class="note">This module is in an alpha release state and may change. Please raise any issues in freshdesk.</p>

      <advertising-module-v1-analytics v-if="active === 1" />

    </v-tab-item>

  </v-tabs>
</template>

<script>
import AdvertisingModuleV1Analytics from "@/components/Experiments/AdvertisingModuleV1/AdvertisingModuleV1Analytics"
import AdvertisingModuleV1Manager from "@/components/Experiments/AdvertisingModuleV1/AdvertisingModuleV1Manager"

export default {
  name: "AdvertisingModuleV1Dashboard",

  components: {
    AdvertisingModuleV1Analytics,
    AdvertisingModuleV1Manager
  },

  data:() => ({
    active: null
  }),
}
</script>

<style scoped lang="scss">
  .note {
    padding: 10px;
    margin: 0 0 30px;
    width: 100%;
    font-size: 13px;
    font-style: italic;
    background-color: #f4f6d2;
    border: 1px solid #d6d8b0;
  }
</style>
