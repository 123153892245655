<template>
  <v-list-tile class="row-bold">
    <v-list-tile-action class="row-handle">
    </v-list-tile-action>

    <v-list-tile-action class="row-checkbox">
      <v-checkbox
        disabled
      ></v-checkbox>
    </v-list-tile-action>

    <v-list-tile-content class="row-status">
      <v-list-tile-sub-title>Status</v-list-tile-sub-title>
    </v-list-tile-content>

    <v-list-tile-content class="row-image">
      <v-list-tile-sub-title>Image</v-list-tile-sub-title>
    </v-list-tile-content>

    <v-list-tile-content class="row-link">
      <v-list-tile-sub-title>Link</v-list-tile-sub-title>
    </v-list-tile-content>

    <v-list-tile-content class="row-file">
      <v-list-tile-sub-title>File Type</v-list-tile-sub-title>
    </v-list-tile-content>

    <v-list-tile-action>
      <v-list-tile-sub-title>Actions</v-list-tile-sub-title>
    </v-list-tile-action>

  </v-list-tile>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  /deep/ .v-list__tile {
    padding: 0;
  }

  .row-bold /deep/ .v-list__tile__sub-title {
    font-weight: bold;
  }
  .row-checkbox {
    flex-grow: 0;
    min-width: 0;
    width: 45px;
  }
  .row-status {
    flex-grow: 0;
    min-width: 0;
    width: 130px;
  }
  .row-image {
    flex-grow: 0;
    min-width: 0;
    width: 200px;
  }
  .row-link {
    flex-grow: 1;
  }
  .row-file {
    flex-grow: 0;
    min-width: 0;
    width: 130px;
  }
  .row-actions--double {
    margin-left: 35px;
    flex-grow: 0;
    min-width: 0;
    width: 80px;
    text-align: right;
  }
</style>
