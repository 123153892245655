var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.slotData && _vm.slotData.attributes)?_c('v-card',{staticClass:"accordion__content"},[_c('div',{staticClass:"accordion__top has-margin"},[_c('h2',{staticClass:"accordion__content-title"},[_vm._v(" "+_vm._s(_vm.slotData.attributes.slotTitle)+" ")]),_c('div',{staticClass:"accordion__content-options"},[(_vm.adverts && (_vm.slotData.attributes.adverts && _vm.slotData.attributes.adverts.length))?_c('slot-modal',{attrs:{"slotID":_vm.slotData.id,"slotTitle":_vm.slotData.attributes.slotTitle,"adsData":_vm.adverts,"selected":_vm.slotData.attributes.adverts,"sortable":_vm.sortable},on:{"slotEdited":function($event){return _vm.$emit('fetchSlots')}}},[_c('v-btn',{staticClass:"accordion__button",attrs:{"accent":"","type":"button","color":"accent","depressed":""}},[_vm._v(" Manage Slot "),_c('v-icon',{staticClass:"accordion__icon"},[_vm._v("add")])],1)],1):_vm._e()],1)]),(_vm.adverts && (_vm.slotData.attributes.adverts && !_vm.slotData.attributes.adverts.length))?_c('slot-modal',{attrs:{"slotID":_vm.slotData.id,"slotTitle":_vm.slotData.attributes.slotTitle,"adsData":_vm.adverts,"selected":_vm.slotData.attributes.adverts,"sortable":_vm.sortable},on:{"slotEdited":function($event){return _vm.$emit('fetchSlots')}}},[_c('v-btn',{staticClass:"accordion__button",attrs:{"accent":"","type":"button","color":"accent","depressed":""}},[_vm._v(" Manage Slot "),_c('v-icon',{staticClass:"accordion__icon"},[_vm._v("add")])],1)],1):_vm._e(),(_vm.slotData.relationships &&
        _vm.slotData.relationships.adverts &&
        _vm.slotData.relationships.adverts.length
        )?_c('div',{staticClass:"ad-item__ads"},[_vm._l((_vm.slotData.relationships.adverts),function(ad){return [_c('div',{key:ad.id,staticClass:"ad-item__ad",class:[
        { 'is-disabled': _vm.checkDateValidity(ad) },
        { 'is-incomplete': _vm.checkDataValidity(ad) },
    ]},[(ad.attributes)?[_c('div',{staticClass:"ad-item__ad-top mb-14"},[_c('h6',{staticClass:"ad-item__ad-title"},[_vm._v(" "+_vm._s(ad.attributes.advertTitle ? ad.attributes.advertTitle.length > 35 ? ad.attributes.advertTitle.substring(0, 35) + "..." : ad.attributes.advertTitle : "No URL defined")+" ")])]),_c('p',{staticClass:"ad-item__ad-date"},[_vm._v(" Start: "),(ad.attributes.advertData.publicationData.startAt
        )?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(ad.attributes.advertData.publicationData.startAt,"DD/MM/YYYY (HH:mm:ss)"))+" ")]):_c('span',[_vm._v("-")])]),_c('p',{staticClass:"ad-item__ad-date"},[_vm._v(" End: "),(ad.attributes.advertData.publicationData.finishAt
        )?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(ad.attributes.advertData.publicationData.finishAt,"DD/MM/YYYY (HH:mm:ss)"))+" ")]):_c('span',[_vm._v("-")])]),_c('p',{staticClass:"ad-item__child-friendly"},[_vm._v(" Child Friendly: "+_vm._s(ad.attributes.childFriendly ? 'Yes' : 'No')+" ")])]:_c('p',{staticClass:"mb-0"},[_vm._v(" Error: this advert might have been deleted ")])],2)]})],2):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }