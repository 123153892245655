var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"top-player-stats-widget",attrs:{"width":"580","lazy":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._t("default",null,{"slot":"activator"}),_c('v-card',[_c('v-card-title',{staticClass:"widget__heading"},[_vm._v(" Top Player Stats ")]),_c('v-card-text',[_c('v-form',{ref:"topPlayerStatsForm",attrs:{"enctype":"multipart/form-data","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('section',[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.autofill()}}},[_vm._v("Autofill")]),(_vm.autofillData)?[_c('table',[_c('thead',[_c('th',[_vm._v("Player")]),_c('th',[_vm._v(" "+_vm._s(_vm.$store.state.insights.selectedPlayers.sortedBy)+" ")]),_c('th',[_c('v-select',{attrs:{"item-text":"name","item-value":"value","items":[
                        { name: 'Touches', value: 'touches' },
                        { name: 'Passes', value: 'total_pass' },
                        { name: 'Duels Won', value: 'duel_won' },
                        { name: 'Fouls', value: 'fouls' }
                      ],"solo":"","flat":"","required":""},model:{value:(_vm.secondaryStatID),callback:function ($$v) {_vm.secondaryStatID=$$v},expression:"secondaryStatID"}})],1),_c('th',[_c('v-select',{attrs:{"item-text":"name","item-value":"value","items":[
                        { name: 'Touches', value: 'touches' },
                        { name: 'Passes', value: 'total_pass' },
                        { name: 'Duels Won', value: 'duel_won' },
                        { name: 'Fouls', value: 'fouls' }
                      ],"solo":"","flat":"","required":""},model:{value:(_vm.tertiaryStatID),callback:function ($$v) {_vm.tertiaryStatID=$$v},expression:"tertiaryStatID"}})],1)]),_c('tbody',_vm._l((_vm.$store.state.insights.selectedPlayers.players),function(player){return _c('tr',{key:player.playerID},[_c('td',[_vm._v(_vm._s(player.firstName)+" "+_vm._s(player.surname))]),_c('td',[_vm._v(_vm._s(player.playerStats[_vm.$store.state.insights.selectedPlayers.sortedBy]))]),_c('td',[_vm._v(_vm._s(player.playerStats[_vm.secondaryStatID]))]),_c('td',[_vm._v(_vm._s(player.playerStats[_vm.tertiaryStatID]))])])}),0)])]:_vm._e(),_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"accent","depressed":""},on:{"click":function($event){return _vm.validate()}}},[_vm._v(" Add Stats ")])],2)])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }