<template>
    <div class="widget-details">
        <template v-if="!updatedData.widgetType">
            <p class="widget-info">This widget allows you to build a carousel displaying selected playlists.</p>

            <v-btn
                class="accent"
                v-if="!updatedData.widgetType"
                @click="initialise()"
            >Use this widget</v-btn>
        </template>

        <div
            class="widget-fieldset"
            v-else
        >
            <label class="label">Title</label>
            <v-text-field
                id="widgetData.title"
                solo
                flat
                v-model="updatedData.widgetData.title"
            >
            </v-text-field>

            <label class="label">Style</label>
            <v-select
                id="widgetData.style"
                :items="styleOptions"
                solo
                flat
                v-model="updatedData.widgetData.style"
            >
            </v-select>

            <label class="label">Button URL</label>
            <v-text-field
                id="widgetData.buttonURL"
                solo
                flat
                v-model="updatedData.widgetData.buttonUrl"
            >
            </v-text-field>

            <label class="label">Button Text</label>
            <v-text-field
                id="widgetData.buttonText"
                solo
                flat
                v-model="updatedData.widgetData.buttonText"
            >
            </v-text-field>

            <label class="label">Playlists</label>
            <template v-if="playlists && playlists.length && updatedData.widgetData && updatedData.widgetData.playlistsID">

                <p
                    @click="handleSelection(playlist)"
                    class="playlist"
                    :class="{ 'playlist--active': updatedData.widgetData.playlistsID.includes(playlist.id) }"
                    v-for="playlist in playlists"
                    :key="playlist.id"
                >{{ playlist.attributes.title }}</p>

                <div class="selected-playlists">
                    <label class="label">Selected Playlists (drag and drop to reorder)</label>
                    <template v-if="updatedData.widgetData.playlists && updatedData.widgetData.playlists.length">
                        <draggable
                            v-model="updatedData.widgetData.playlists"
                            :options="{
                                ghostClass: 'ghost',
                                dragClass: 'sortable-drag'
                            }"
                            @start="drag = true"
                            @end="drag = false"
                        >

                            <transition-group
                                name="list-complete"
                                class="featured__items"
                            >
                                <p
                                    class="playlist playlist--selected"
                                    v-for="playlist in updatedData.widgetData.playlists"
                                    :key="playlist.id"
                                >{{ playlist.attributes.title }}
                                    <v-icon
                                        @click="handleSelection(playlist)"
                                        class="remove-icon"
                                        color="accent"
                                        right
                                    >remove_circle</v-icon>
                                </p>
                            </transition-group>
                        </draggable>
                    </template>
                    <p v-else>No playlists selected</p>
                </div>
            </template>
            <p v-else>Loading...</p>

        </div>
    </div>
</template>

<script>
import axios from "axios"
import draggable from "vuedraggable"

export default {
    name: "PlaylistCarousel",

    watch: {
        updatedData: {
            handler: function () {
                this.updatedContent()
            },
            deep: true
        }
    },

    props: {
        editData: {
            type: Object,
            required: true
        }
    },

    components: {
        draggable
    },

    data: () => ({
        api: `${process.env.VUE_APP_STREAMLINEAPI}/playlists`,
        styleOptions: [{ text: "Light Mode", value: "light" }, { text: "Dark Mode", value: "dark" }, { text: "Club Mode", value: "club" }],
        updatedData: {
            title: null,
            style: null,
            buttonUrl: null,
            buttonText: null,
            playlists: [],
            playlistsID: []
        },
        playlists: []
    }),

    async created() {
        await this.fetchPlaylists()
    },

    mounted() {
        this.updatedData = JSON.parse(JSON.stringify(this.editData))
    },

    methods: {
        initialise() {
            this.updatedData.widgetType = "PlaylistCarousel"
            this.updatedData.widgetName = "Playlist Carousel"
            this.updatedData.widgetID = this.$uuid.v1()
            this.updatedData.widgetData = {
                style: null, // Background colour - light || dark || club
                buttonUrl: null,
                buttonText: null,
                playlists: [],
                playlistsID: []
            }
            this.$emit("initialised")
        },

        updatedContent() {
            this.$emit("updatedWidget", this.updatedData)
        },

        async fetchPlaylists() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.api}`,
                    headers: { "Content-Type": "application/json", Authorization: jwt }
                })
                    .then(response => {
                        console.log("Playlist response:", response);
                        this.playlists = response.data.data;
                        this.loading = false;
                    })
                    .catch(e => {
                        console.error("Problem fetching playlist", e);
                    });
            })
        },

        handleSelection(playlist) {
            const updatedPlaylist = {
                id: playlist.id,
                attributes: {
                    title: playlist.attributes.title
                }
            }

            const index = this.updatedData.widgetData.playlistsID.indexOf(playlist.id);

            if (index !== -1) {
                this.updatedData.widgetData.playlists.splice(index, 1);
                this.updatedData.widgetData.playlistsID.splice(index, 1);
            } else {
                this.updatedData.widgetData.playlists.push(updatedPlaylist)
                this.updatedData.widgetData.playlistsID.push(updatedPlaylist.id)
            }
        }
    }
}
</script>

<style scoped lang='scss'>
.main-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 20px;
}

.position {
    text-transform: uppercase;
    border-bottom: 3px solid #24002F;
    margin-bottom: 4px;
    display: inline-block;
}

.items {
    margin-bottom: 8px;
}

.playlist {
    background: #fff;
    padding: 4px;
    border-radius: 4px;
    border: 2px solid #fff;
    cursor: pointer;
    margin-bottom: 8px;

    &--active {
        border: 2px solid #67247C;
    }

    &--selected {
        display: flex;
        justify-content: space-between;
        cursor: move;
    }
}

.selected-playlists {
    margin-top: 26px;
    margin-bottom: 26px;
}

.remove-icon {
    cursor: pointer;
}
</style>
