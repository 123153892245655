<template>
  <v-dialog
    class="player-quote-widget"
    v-model="dialogPlayerQuoteWidgetForm"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

    <v-form enctype="multipart/form-data" v-model="valid" ref="playerQuoteWidgetForm" lazy-validation>
      <v-card>
        <v-card-title
          class="widget__heading"
        >
          Player Quote
        </v-card-title>

        <v-card-text>
          <label class="label" for="team">Select Squad</label>
          <v-select
          :items="teams"
          id="team"
          v-model="playerQuoteData.team"
          outline
          required
          :rules="[rules.required]"
          validate-on-blur
          @change="getPlayers($event), playerQuoteData.player = null"
          label="Please select a team"
        ></v-select>

        <div v-if="playerQuoteData.customPlayer">
          <label class="label" for="customPlayerName">Custom player</label>
          <v-text-field
            v-model="playerQuoteData.player"
            id="customPlayerName"
            solo
            flat
            required
            @change="updatePlayer($event)"
            :rules="[rules.required]"
            validate-on-blur
            label="Please enter a player name">
          </v-text-field>
        </div>

        <div v-else>
          <label class="label" for="player">Select Player</label>
          <v-select
            id="player"
            :items="players"
            :disabled="!playerQuoteData.team || !players.length || playerQuoteData.customPlayer"
            v-model="playerQuoteData.player"
            outline
            required
            :rules="[rules.required]"
            validate-on-blur
            :label="playerLabelMsg"
          ></v-select>
        </div>

        <v-checkbox class="player-quote-widget__custom-player" v-model="playerQuoteData.customPlayer" label="Custom player"></v-checkbox>

        <label class="label" for="quote">Quote</label>
        <v-text-field
          :disabled="!playerQuoteData.team || !playerQuoteData.player"
          v-model="playerQuoteData.quote"
          id="quote"
          solo
          flat
          required
          :rules="[rules.required]"
          validate-on-blur>
        </v-text-field>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="accent"
            outline
            flat
            @click="validate()"
          >
            Post
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "PlayerQuoteWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    }
  },


  data: () => ({
    dialogPlayerQuoteWidgetForm: false,
    valid: false,
    teams: ["First Team", "U23s", "Ladies"],
    players: [],
    playerQuoteData: {
      team: null,
      player: null,
      customPlayer: false,
      quote: ""
    },
    rules: {
      required: value => !!value || "Required."
    }
  }),

  mounted() {
    console.log("PlayerQuoteWidget mounted");
    if (this.widgetData) {
      this.playerQuoteData = JSON.parse(JSON.stringify(this.widgetData));
      this.getPlayers(this.playerQuoteData.team);
    }
  },

  computed: {
    playerLabelMsg: function() {
      if(this.players.length === 0) {
        return "There are no available players in the current selected squad";
      }
      else {
        return "Please select a player";
      }
    }
  },

  methods: {
    getPlayers(e) {
      this.players = [];
      this.playerQuoteData.team = e;
      console.log(this.playerQuoteData.team);
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${process.env.VUE_APP_BASEURL}/players/opta`, { "headers": { "Authorization": jwt }})
          .then(response => {
            console.log(response);
            response.data.body.forEach(player => {
              if(player.squad == e) {
                const name = player.firstName + " " + player.lastName;
                this.players.push(name);
              }
            });
          })
          .catch(e => {
            console.log("Unable to load players:", e);
          })
      })
    },
    updatePlayer(e) {
      this.playerQuoteData.player = e;
    },
    updateData() {
      console.log("Update data:", this.playerQuoteData);
      this.$emit("updatedData", this.playerQuoteData);
    },
    validate() {
      this.errorMessage = ""
      if (this.$refs.playerQuoteWidgetForm.validate()) {
        console.log("Validation passed");
        this.updateData();
        this.dialogPlayerQuoteWidgetForm = false;
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .player-quote-widget {
    &__custom-player {
      margin-top: 0;
    }
  }
</style>
