<template>
  <v-dialog class="club-promo-widget" v-model="dialog" width="580" lazy>
    <slot slot="activator"></slot>

    <v-card>
      <v-card-title class="widget__heading"> Standard Promotion </v-card-title>

      <v-card-text>
        <v-form
          enctype="multipart/form-data"
          v-model="valid"
          ref="clubPromoForm"
          lazy-validation
        >
          <section>
            <label class="label" for="promoType">Promo Type</label>
            <v-select
              id="promoType"
              :items="['stack', 'overlay', 'centre']"
              v-model="clubPromoData.promoType"
              solo
              flat
              :rules="[rules.required]"
              validate-on-blur
            ></v-select>

            <ImageUploader
              :title="'Promotion Image'"
              :widthOptionEnabled="false"
              :mediaLibraryID="clubPromoData.imageID"
              @update="updateImage($event)"
            />

            <label class="label" for="title">Title</label>
            <v-text-field
              id="title"
              outline
              v-model="clubPromoData.title"
              solo
              flat
              class="text-field--lableless"
            ></v-text-field>

            <label class="label" for="description">Description</label>
            <v-text-field
              id="description"
              outline
              v-model="clubPromoData.description"
              solo
              flat
              class="text-field--lableless"
            ></v-text-field>

            <label class="label" for="buttonText">Button Text</label>
            <v-text-field
              id="buttonText"
              outline
              v-model="clubPromoData.buttonText"
              solo
              flat
              class="text-field--lableless"
            ></v-text-field>

            <label class="label" for="buttonLink">Button Link</label>
            <v-text-field
              id="buttonLink"
              outline
              v-model="clubPromoData.buttonLink"
              solo
              flat
              class="text-field--lableless"
            ></v-text-field>

            <label class="label" for="buttonType">Button Type</label>
            <v-select
              id="buttonType"
              :items="[
                '',
                'primary-light',
                'primary-dark',
                'ghost-light',
                'ghost-dark',
                'label-light',
                'label-dark',
              ]"
              v-model="clubPromoData.buttonType"
              solo
              flat
            ></v-select>

            <v-checkbox v-model="clubPromoData.newTab" label="Open In New Tab?">
            </v-checkbox>

            <v-btn color="accent" outline flat @click="dialog = false">
              Cancel
            </v-btn>

            <v-btn
              color="accent"
              depressed
              :disabled="!clubPromoData.imageID || !clubPromoData.promoType"
              @click="validate()"
            >
              <span v-if="widgetData">Update promotion</span>
              <span v-else>Add promotion</span>
            </v-btn>
          </section>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import ImageUploader from "@/components/Library/ImageUploader.vue";

export default {
  name: "ClubPromoWidget",

  components: {
    ImageUploader,
  },

  props: {
    widgetData: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}`,
    dialog: false,
    valid: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    clubPromoData: {
      promoType: null,
      imageID: null,
      title: null,
      description: null,
      buttonText: null,
      buttonLink: null,
      buttonType: null,
      newTab: null,
    },
  }),

  mounted() {
    console.log("Club Promo widget mounted", this.widgetData);

    if (this.widgetData) {
      this.clubPromoData = JSON.parse(JSON.stringify(this.widgetData));
    }
  },

  methods: {
    updateImage(payload) {
      console.log("Update image:", payload);

      this.clubPromoData.imageID = payload.mediaLibraryID;
    },

    validate() {
      this.errorMessage = "";
      if (this.$refs.clubPromoForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required";
      }
    },

    updateData() {
      console.log("Update data:", this.clubPromoData);
      if (this.clubPromoData.title === "") {
        this.clubPromoData.title = null;
      }
      if (this.clubPromoData.description === "") {
        this.clubPromoData.description = null;
      }
      if (this.clubPromoData.buttonText === "") {
        this.clubPromoData.buttonText = null;
      }
      if (this.clubPromoData.buttonLink === "") {
        this.clubPromoData.buttonLink = null;
      }
      if (this.clubPromoData.buttonType === "") {
        this.clubPromoData.buttonType = null;
      }
      this.$emit("updatedData", this.clubPromoData);
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.edit {
  margin: 0 10px 20px 0;
  width: 100%;
}
</style>
