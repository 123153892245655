<template>
    <div class="main-body">
        <div v-if="!configsLoaded">Loading</div>

        <form v-else>
            <section
                v-if="!modules.default.LFCExclusive"
                class="section-container"
            >
                <h3 class="subheading mt-0">Third Party</h3>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="ecalLink"
                            title="ecalLink"
                        >eCal link</label>
                        <v-text-field
                            id="ecalLink"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.ecalLink"
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Optional ecal link for matches screen. A license with eCal may
                                be required</i>
                        </p>
                    </v-flex>
                </v-layout>
            </section>

            <section
                v-if="!modules.default.LFCExclusive"
                class="section-container"
            >
                <h3 class="subheading mt-0">Notifications</h3>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="notificationsTitle"
                        >Notification Screen Title</label>
                        <v-text-field
                            id="notificationsTitle"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.notificationsTitle"
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i>Set a title for the notifications screen.</i></p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="notificationsDescription"
                        >Notification Screen Description</label>
                        <v-text-field
                            id="notificationsDescription"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.notificationsDescription"
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i>Set a description for the notifications screen.</i></p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="notificationsTopicMatchEventsDesc"
                        >Match Events Description</label>
                        <v-text-field
                            id="notificationsTopicMatchEventsDesc"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.notificationsTopicMatchEventsDesc"
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Short description for the match events notification section.</i>
                        </p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="notificationsTopicMatchEventsSubScreenTitle"
                        >Match Events Sub-Screen Title</label>
                        <v-text-field
                            id="notificationsTopicMatchEventsDesc"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.notificationsTopicMatchEventsSubScreenTitle
                                "
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i>Short description for the match events sub screen.</i></p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="notificationsTopicMatchEventsSubScreenDesc"
                        >Match Events Sub-Screen Description</label>
                        <v-text-field
                            id="notificationsTopicMatchEventsSubScreenDesc"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.notificationsTopicMatchEventsSubScreenDesc
                                "
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i>Short description for the match events sub screen.</i></p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="notificationsTopicBreakingNewsDesc"
                        >Breaking News Description</label>
                        <v-text-field
                            id="notificationsTopicBreakingNewsDesc"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.notificationsTopicBreakingNewsDesc"
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Set a title for the breaking news notifications section.</i>
                        </p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="notificationsTopicImportantUpdatesDesc"
                        >Important updates Description</label>
                        <v-text-field
                            id="notificationsTopicImportantUpdatesDesc"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.notificationsTopicImportantUpdatesDesc"
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Set a description for the important updates notification
                                section.</i>
                        </p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="notificationsTopicOffersDesc"
                        >Offers Description</label>
                        <v-text-field
                            id="notificationsTopicOffersDesc"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.notificationsTopicOffersDesc"
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i>Short description for the offers notification section.</i></p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="notificationsTopicTicketsDesc"
                        >Tickets Description</label>
                        <v-text-field
                            id="notificationsTopicTicketsDesc"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.notificationsTopicTicketsDesc"
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Short description for the ticket information notification
                                section.</i>
                        </p>
                    </v-flex>
                </v-layout>
            </section>

            <section
                v-if="!modules.default.LFCExclusive"
                class="section-container"
            >
                <h3 class="subheading mt-0">Matchday Takeover</h3>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <ImageUploader
                            mb-3
                            :title="'Background Image - Mobile'"
                            :widthOptionEnabled="false"
                            :mediaLibraryID="configSettings.matchdayMobileBackground"
                            @update="
                                updateImage($event, 'matchdayMobileBackground'), updateData()
                                "
                        />
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Add a default background image for the matchday takeover. If a
                                featured image is set in the fixture this will override the
                                background.</i>
                        </p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <ImageUploader
                            mb-3
                            :title="'Background Image - Tablet'"
                            :widthOptionEnabled="false"
                            :mediaLibraryID="configSettings.matchdayTabletBackground"
                            @update="
                                updateImage($event, 'matchdayTabletBackground'), updateData()
                                "
                        />
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Add a default background image for the matchday takeover. If no
                                image is set it will default to the mobile image and may not
                                display as expected. If a featured image is set in the fixture
                                this will override the background.</i>
                        </p>
                    </v-flex>
                </v-layout>
            </section>

            <section class="section-container">
                <h3 class="subheading mt-0">Match Centre</h3>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <ImageUploader
                            mb-3
                            :title="'Home Background'"
                            :widthOptionEnabled="false"
                            :mediaLibraryID="configSettings.matchCentreHomeBackground"
                            @update="
                                updateImage($event, 'matchCentreHomeBackground'), updateData()
                                "
                        />
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Add a background image for the match centre for home
                                fixtures.</i>
                        </p>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <ImageUploader
                            mb-3
                            :title="'Away Background'"
                            :widthOptionEnabled="false"
                            :mediaLibraryID="configSettings.matchCentreAwayBackground"
                            @update="
                                updateImage($event, 'matchCentreAwayBackground'), updateData()
                                "
                        />
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Add a background image for the match centre for away
                                fixtures.</i>
                        </p>
                    </v-flex>
                </v-layout>
            </section>

            <section
                v-if="modules.default.AppStadiumFinder && configSettings.stadiumFinder"
                class="section-container"
            >
                <h3 class="subheading mt-0">Stadium Finder</h3>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="notificationsTopicTicketsDesc"
                        >Stadium Name</label>
                        <v-text-field
                            id="stadiumFinderTitle"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.stadiumFinder.title"
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Define the displayed title of your stadium.</i>
                        </p>
                    </v-flex>
                </v-layout>

                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="stadiumFinderAddress"
                        >Stadium Address</label>
                        <v-text-field
                            id="stadiumFinderAddress"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.stadiumFinder.address"
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Define the displayed address of your stadium.</i>
                        </p>
                    </v-flex>
                </v-layout>

                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <ImageUploader
                            mb-3
                            :title="'Stadium Image'"
                            :widthOptionEnabled="false"
                            :mediaLibraryID="configSettings.stadiumFinder.image.mediaLibraryID
                                "
                            @update="
                                (configSettings.stadiumFinder.image = $event), updateData()
                                "
                        />
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Define the displayed image for the stadium.</i>
                        </p>
                    </v-flex>
                </v-layout>

                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="stadiumFinderMap"
                        >Google maps link with coordinates
                            <span
                                class="error-message"
                                v-if="!configSettings.stadiumFinder.map.link.match(mapsRegex) &&
                                    configSettings.stadiumFinder.map.link
                                    "
                            >
                                - The provided link does not contain coordinates</span></label>
                        <v-text-field
                            id="stadiumFinderMap"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.stadiumFinder.map.link"
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>A Google Maps Link to the Stadium that includes coordinates.</i>
                        </p>
                    </v-flex>
                </v-layout>

                <h3 class="subheading mt-4">Transports Data</h3>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="stadiumFinderTransportTitle"
                        >Transport Section Title</label>
                        <v-text-field
                            id="stadiumFinderTransportTitle"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.stadiumFinder.transportData.title"
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Define the displayed title of the transport section.</i>
                        </p>
                    </v-flex>
                </v-layout>

                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <ImageUploader
                            mb-3
                            :title="'Transport Image'"
                            :widthOptionEnabled="false"
                            :mediaLibraryID="configSettings.stadiumFinder.transportData.image.mediaLibraryID
                                "
                            @update="
                                (configSettings.stadiumFinder.transportData.image = $event),
                                updateData()
                                "
                        />
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Optional image for the transports section.</i>
                        </p>
                    </v-flex>
                </v-layout>

                <data-groups
                    class="data-group"
                    title="Transports"
                    groupTitle="transport"
                    :data="configSettings.stadiumFinder.transportData.dataItems"
                    :groupSchema="transportGroupSchema"
                    :itemSchema="transportItemSchema"
                    @addData="addData('transportData', $event)"
                />

                <h3 class="subheading mt-4">Stadium Data</h3>
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="stadiumFinderStadiumTitle"
                        >Stadium Section Title</label>
                        <v-text-field
                            id="stadiumFinderStadiumTitle"
                            type="text"
                            placeholder=""
                            solo
                            flat
                            v-model="configSettings.stadiumFinder.stadiumData.title"
                            @input="updateData()"
                        >
                        </v-text-field>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Define the displayed title of the stadium section.</i>
                        </p>
                    </v-flex>
                </v-layout>

                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <ImageUploader
                            mb-3
                            :title="'Stadium Section Image'"
                            :widthOptionEnabled="false"
                            :mediaLibraryID="configSettings.stadiumFinder.stadiumData.image.mediaLibraryID
                                "
                            @update="
                                (configSettings.stadiumFinder.stadiumData.image = $event),
                                updateData()
                                "
                        />
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Optional image for the stadium section.</i>
                        </p>
                    </v-flex>
                </v-layout>

                <data-groups
                    class="data-group"
                    title="Stadium data"
                    :data="configSettings.stadiumFinder.stadiumData.dataItems"
                    :groupSchema="stadiumGroupSchema"
                    :itemSchema="stadiumItemSchema"
                    @addData="addData('stadiumData', $event)"
                />
            </section>

            <StickyFooter
                :errorMessage="errorMessage"
                :status="status"
                :buttonText="'Save App Settings'"
                @buttonClicked="saveConfig()"
            />
        </form>
    </div>
</template>

<script>
import axios from "axios";
import ImageUploader from "@/components/Library/ImageUploader";
import StickyFooter from "@/components/Library/StickyFooter";
import DataGroups from "@/components/Library/DataGroups/DataGroups.vue";

export default {
    name: "AppSettings",

    components: {
        ImageUploader,
        StickyFooter,
        DataGroups,
    },

    created() {
        this.modules = require("../../config/features/" + process.env.VUE_APP_CLUB_ID + "/modules.js")
    },

    mounted() {
        this.getConfigs();
    },

    data: () => ({
        status: "saved",
        errorMessage: "",
        configsLoaded: false,
        modules: null,
        mapsRegex: /[@](.*)[,](.*)[,]/g,
        configSettings: {
            //Third Party
            ecalLink: "",
            //Notifications
            notificationsTitle: "",
            notificationsDescription: "",
            notificationsTopicMatchEventsDesc: "",
            notificationsTopicBreakingNewsDesc: "",
            notificationsTopicImportantUpdatesDesc: "",
            notificationsTopicOffersDesc: "",
            notificationsTopicTicketsDesc: "",
            //Matchday Takeover
            matchdayMobileBackground: "",
            matchdayMobileBackgroundKey: "",
            matchdayTabletBackground: "",
            matchdayTabletBackgroundKey: "",
            // Match Centre
            matchCentreHomeBackground: "",
            matchCentreHomeBackgroundKey: "",
            matchCentreAwayBackground: "",
            matchCentreAwayBackgroundKey: "",
            // Stadium Finder
            stadiumFinder: {
                title: "",
                address: "",
                image: {
                    imageKey: "",
                },
                map: {
                    link: "",
                    lat: "",
                    long: "",
                },
                transportData: {
                    title: "",
                    image: {
                        imageKey: "",
                    },
                    data: [],
                },
                stadiumData: {
                    title: "",
                    image: {
                        imageKey: "",
                    },
                    data: [],
                },
            },
        },
        transportGroupSchema: {},
        transportItemSchema: {
            title: "",
            description: "",
            published: false,
        },
        stadiumGroupSchema: {},
        stadiumItemSchema: {
            title: "",
            description: "",
            published: false,
        },
    }),

    methods: {
        getConfigs() {
            this.$store.commit("startLoading");
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${process.env.VUE_APP_CLIENTSETTINGSAPI}/get?settingID=app`, {
                        headers: { Authorization: jwt },
                    })
                    .then((response) => {
                        console.log("app response", response);
                        if (response.data.body) {
                            this.configSettings = response.data.body.settings
                                ? response.data.body.settings
                                : {};
                        }
                        this.configsLoaded = true;
                        this.$store.commit("completeLoading");
                        this.initialiseStadiumFinder();
                    })
                    .catch((e) => {
                        console.error("Problem retrieving configs", e);
                        this.$store.commit("completeLoading");
                    });
            });
        },

        saveConfig() {
            this.errorMessage = "";
            this.status = "saving";

            if (
                this.configSettings.stadiumFinder &&
                this.configSettings.stadiumFinder.map &&
                this.configSettings.stadiumFinder.map.link
            ) {
                const coordinates = this.mapsRegex.exec(
                    this.configSettings.stadiumFinder.map.link
                );
                if (coordinates && coordinates.length) {
                    this.configSettings.stadiumFinder.map.lat = coordinates[1];
                    this.configSettings.stadiumFinder.map.long = coordinates[2];
                }
            }

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: `${process.env.VUE_APP_CLIENTSETTINGSAPI}/insert-update`,
                    headers: { Authorization: jwt },
                    data: {
                        settingID: "app",
                        settings: this.configSettings,
                    },
                })
                    .then((response) => {
                        console.log("saved configs", response);
                        this.status = "saved";
                    })
                    .catch((e) => {
                        console.error("Problem saving website configuration", e);
                        this.status = "needsSaving";
                        this.errorMessage = "Problem saving website configuration";
                    });
            });
        },

        updateData() {
            this.status = "needsSaving";
            //currently doing nothing as I haven't added a JSON option
        },

        updateImage(payload, target) {
            this.configSettings[target] = payload.mediaLibraryID;
            this.configSettings[`${target}Key`] = payload.imageKey;
            this.configJSON = JSON.stringify(this.configSettings, undefined, 4);
        },

        addData(property, data) {
            this.configSettings.stadiumFinder[property].dataItems = data;
        },

        initialiseStadiumFinder() {
            if (
                this.modules.default.AppStadiumFinder &&
                !this.configSettings.stadiumFinder
            ) {
                this.configSettings.stadiumFinder = {
                    title: "",
                    address: "",
                    image: {
                        imageKey: "",
                    },
                    map: {
                        link: "",
                        lat: "",
                        long: "",
                    },
                    transportData: {
                        title: "",
                        image: {
                            imageKey: "",
                        },
                        dataItems: [],
                    },
                    stadiumData: {
                        title: "",
                        image: {
                            imageKey: "",
                        },
                        dataItems: [],
                    },
                };
            }
        },
    },
};
</script>

<style scoped lang='scss'>
//Lazy copy of web settings

.half {
    width: 50%;
}

.section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dfe3e9;
}

.input {
    &__textfield {
        &--basic {
            margin-top: 0;
        }
    }
}

.tiny-mce-block {
    margin-bottom: 16px;
}

.quick-link-wrapper {
    padding: 16px;
    font-style: italic;
    background-color: color(warning);
    color: #000;
    border-radius: 8px;
    border: 1px solid color(newHighlightBorder);

    &:hover {
        cursor: pointer;
    }
}

.faded {
    opacity: 0.5;
}

.build-notice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    background-color: #f4f6d2;
    padding: 8px 16px;
    border: 1px solid #d6d8b0;
    border-radius: 8px;

    &__warning {
        margin-bottom: 0;
    }
}

.advanced-button {
    margin-bottom: 30px;
}

.v-select.v-text-field--enclosed {
    /deep/ .v-select__selections {
        padding: 0 !important;
    }
}

.error-message {
    color: red;
}

.data-group {
    margin-top: 10px;
}
</style>
