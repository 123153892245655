<template>
  <v-dialog
    class="file-download-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

    <v-form enctype="multipart/form-data" ref="fileDownloadWidgetForm" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title
          class="widget__heading"
        >
          File Download
        </v-card-title>

        <v-card-text>

          <div class="uploader" v-if="fileDownloadData.image === ''">
            <h4 class="uploader__heading">Add File</h4>

            <div class="uploader__ctas">

              <v-btn
                color="accent"
                type="button"
                depressed>
                <label for="file-upload" class="uploader__upload-button"><v-icon small>add</v-icon> Choose file</label>
              </v-btn>
              <input class="uploader__upload-input" id="file-upload" ref="fileUpload" type="file" @change="convertFile($event)" accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>

            </div>
          </div>

          <p v-else class="success">
            File added (but not saved)
          </p>

          <section class="single-file" v-if="previewFile">

            <section class="edit">
              
              <label class="label" for="title">Document Title</label>
              <v-text-field
                id="title"
                v-model="fileDownloadData.name"
                solo
                flat
                required
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>

              <label class="label" for="description">Document Description (optional)</label>
              <v-text-field
                id="description"
                v-model="fileDownloadData.description"
                solo
                flat


              ></v-text-field>

              </section>

          </section>

        </v-card-text>

        <v-card-actions v-if="previewFile">
          <v-spacer></v-spacer>
          <v-btn
            outline
            flat
            @click="cancel();"
          >
            Cancel
          </v-btn>
          <v-btn
            color="accent"
            outline
            flat
            @click="validate();"
          >
            Post
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "FileDownloadWidget",

  props: {
    widthOptionEnabled: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    dialog: false,
    valid: false,
    maxFiles: 1,
    previewFile: null,
    tagString: "",
    updatedData: {
      title: "",
      description: null,
      fileType: "",
      mediaLibraryID: "",
      adminFilePreview: ""
    },
    fileDownloadData: {
      image: "",
      name: "",
      description: null,
      tags: [],
    },
    rules: {
      required: value => !!value || "Required."
    }
  }),

  mounted() {
    console.log("file-download-widget Mounted");
  },

  watch: {
    tagString: function(val) {
      if (val.includes(",")) {
        const trimmedVal = val.slice(0, -1);
        this.fileDownloadData.tags.push(trimmedVal);
        this.tagString = "";
      }
    }
  },

  methods: {
    convertFile($event) {
      const file = $event.target.files[0]
      const reader = new FileReader();

      reader.addEventListener("load", evt => {
        this.previewFile = reader.result;

        console.log("reader", reader);

        const base64DataOnly = reader.result.split(",")[1].toString();

        this.fileDownloadData = {
          image: base64DataOnly,
          name: "",
          description: null,
          tags: []
        }
      });

      if (file) {
        reader.readAsDataURL(file);
      }
    },

    cancel() {
      this.previewFile = null
      this.fileDownloadData = {
        image: "",
        name: "",
        description: null,
        tags: []
      }
      this.dialog = false
    },

    uploadFile() {
      console.log("Upload file: ", this.fileDownloadData);
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/media/upload`,
          headers: { "Authorization": jwt },
          data: this.fileDownloadData
        })
        .then(response => {
          console.log("Saved file: ", response);

          this.updatedData = {
            mediaLibraryID: response.data.body.mediaLibraryID,
            fileType: response.data.body.fileType,
            title: this.fileDownloadData.name,
            description: this.fileDownloadData.description,
            adminFilePreview: response.data.body.location
          }

          this.$emit("fileUploaded", 
            this.updatedData
          );
        })
        .catch(e => {
          console.error("Problem saving file: ", e);
        });
      })
    },
    tagged(item){
      console.log("Adding tag:", item);
      const tag = {
        id: item.replace(/\s/g,""),
        name: item
      }
      this.fileDownloadData.tags.push(tag);
      this.tagString = "";
      console.log(this.fileDownloadData.tags);
    },
    removeTag(index) {
      this.fileDownloadData.tags.splice(index, 1);
    },
    validate() {
      this.errorMessage = ""
      if (this.$refs.fileDownloadWidgetForm.validate()) {
        console.log("Validation passed");
        this.dialog = false;
        this.uploadFile();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .single-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 30px;
    border-top: 1px solid #ebebeb;

    &__preview {
      margin: 10px 40px 0 30px;
      max-height: 80px;
      max-width: 80px;
    }
  }

  .preview-list {
    border-bottom: 1px solid #DFE3E9;
  }

  .preview {
    display: block;
    margin: 0;
    padding: 15px 0 10px;
    border-top: 1px solid #DFE3E9;

    /deep/ .v-list__tile {
      padding: 0;
    }

    &__label {
      padding: 0;
      margin: 0 0 10px;
      font-size: 10px;
    }

    &__file {
      max-height: 56px;
      max-width: 56px;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    &__action {
      display: block;
      margin: 0 0 0 30px;
      cursor: pointer;
    }
  }

  .uploader {
    margin: 0 0 40px 0;

    &__heading {
      margin: 0 0 20px;
      color: #24002F;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 500;
    }

    &__upload-input {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      white-space: nowrap;
      width: 1px;
    }

    &__upload-button {
      cursor: pointer;
    }

    &__ctas {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .edit {
    margin: 0 10px 20px 0;
    width: 100%;
  }

  .tag-manager {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    &__tag {
      padding: 4px 15px;
      margin: 15px 5px 0 0;
      color: #fff;
      background-color: #B391BD;
      border-radius: 4px;
      cursor: pointer;
    }

    &__input {
      width: 100%;
    }
  }

  .file-download-widget {
    &__width {
      margin: 10px 0 30px;
      display: flex;
      justify-content: space-between;
      &-item {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        + label {
            cursor: pointer;
            text-align: center;
            &:before {
                content: '';
                width: 64px;
                height: 56px;
                display: block;
                cursor: pointer;
                margin: auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                opacity: 0.5;
            }
            span {
              font-size: 0.75rem;
              color: $purple;
              margin-top: 5px;
              display: block;
              border-radius: 100px;
              padding: 3px 6px;
            }
        }
        &:checked {
          + label{
            &:before {
                opacity: 1.0;
            }
            span {
              background-color: #67247c;
              color: white;
            }
          }
        }
        &--standard {
          + label:before {
            background-image: url('../../assets/standard.svg');
          }
        }
        &--wide {
          + label:before {
            background-image: url('../../assets/wide.svg');
          }
        }
        &--full {
          + label:before {
            background-image: url('../../assets/full-width.svg');
          }
        }
      }
    }
  }

</style>
