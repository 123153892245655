<template>

  <v-dialog
    class="quote-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

    <v-card>
      <v-card-title class="widget__heading">Quote</v-card-title>

      <v-card-text>
        <v-form enctype="multipart/form-data" v-model="valid" ref="quoteForm" lazy-validation>
          <label class="label" for="text">Quote Text</label>
          <v-textarea
            id="text"
            outline
            v-model="quoteData.text"
            solo
            flat
            :rules="[rules.required]"
            class="text-field--lableless"
            validate-on-blur
          ></v-textarea>

          <ImageUploader title="Profile Image (optional)" :widthOptionEnabled="false" :mediaLibraryID="quoteData.imageID" @update="updateImage('profileImage', $event)" />

          <label class="label" for="fullName">Full Name (optional)</label>
          <v-text-field
            id="fullName"
            outline
            v-model="quoteData.fullName"
            solo
            flat
            class="text-field--lableless"
            validate-on-blur
          ></v-text-field>

          <label class="label" for="jobTitle">Title (optional)</label>
          <v-text-field
            id="jobTitle"
            outline
            v-model="quoteData.jobTitle"
            solo
            flat
            class="text-field--lableless"
            validate-on-blur
          ></v-text-field>

          <label class="label" for="quoteStyle">Quote Style</label>
          <v-select
            id="quoteStyle"
            v-model="quoteData.style"
            item-text='name'
            item-value='value'
            :items="[
              { name: 'Default', value: 'default' },
              { name: 'Background Image', value: 'backgroundImage' }
            ]"
            solo
            flat
            required />

          <ImageUploader v-if="quoteData.style == 'backgroundImage'" title="Background Image" :widthOptionEnabled="false" :mediaLibraryID="quoteData.backgroundImageID" @update="updateImage('backgroundImage', $event)" />

          <v-btn
            color="accent"
            outline
            flat
            @click="dialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="accent"
            depressed
            :disabled="!quoteData.text || (quoteData.style == 'backgroundImage' && !quoteData.backgroundImageID)"
            @click="validate()"
          >
            <span v-if="widgetData">Update quote</span>
            <span v-else>Add quote</span>
          </v-btn>

        </v-form>
      </v-card-text>
    </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"
import ImageUploader from "@/components/Library/ImageUploader.vue"

export default {
  name: "QuoteWidget",

  components: {
    ImageUploader
  },

  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}`,
    dialog: false,
    valid: false,
    rules: {
      required: value => !!value || "Required."
    },
    quoteData: {
      text: null,
      imageID: null,
      jobTitle: null,
      fullName: null,
      style: "default",
      backgroundImageID: null
    },
  }),

  mounted() {
    console.log("Quote widget mounted");

    if (this.widgetData) {
      this.quoteData = JSON.parse(JSON.stringify(this.widgetData));
    }
  },

  methods: {
    updateImage(type, payload) {
      console.log("Update image:", payload);

      if (type == "profileImage") {
        this.quoteData.imageID = payload.mediaLibraryID
      } else if (type == "backgroundImage") {
        this.quoteData.backgroundImageID = payload.mediaLibraryID
      }
    },

    validate() {
      this.errorMessage = ""
      if (this.$refs.quoteForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },

    updateData() {
      console.log("Update data:", this.quoteData);
      this.$emit("updatedData", this.quoteData);
      this.dialog = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit {
    margin: 0 10px 20px 0;
    width: 100%;
  }
</style>
