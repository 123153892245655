<template>
  <section>
    <MiniHeader :heading="`${data.teamName} fixtures`" :buttonText="'Add Fixture'" :hasButton="true" @buttonClicked="addFixture()"/>

    <template v-if="dataLoaded">
      <section class="fixture-filter">
        <v-select
          class="fixture-filter__select fixture-filter__select--first"
          :items="data.seasons"
          item-text="seasonName"
          item-value="seasonID"
          v-model="selectedSeason"
          return-object
          solo
          flat>
        </v-select>

        <v-select
          v-if="this.selectedSeason.competitions[0].competitionID"
          class="fixture-filter__select"
          :items="availableCompetitions"
          item-text="competitionName"
          item-value="competitionID"
          v-model="selectedCompetitionID"
          solo
          flat>
        </v-select>
      </section>
    </template>

    <section v-if="fixturesLoaded && fixtures.length" class="fixtures">
      <h2 class="subheading">Fixtures ({{ fixtures.length }})</h2>

      <table class="gc-listing">
        <thead class="gc-listing__headers">
          <th class="gc-listing__header-item">ID</th>
          <th class="gc-listing__header-item">Home</th>
          <th class="gc-listing__header-item">Away</th>
          <th class="gc-listing__header-item">Competition</th>
          <th class="gc-listing__header-item">Date/Time</th>
          <th class="gc-listing__header-item">Published</th>
          <th class="gc-listing__header-item">Actions</th>
        </thead>

        <tbody>
          <tr class="gc-listing__row" :class="{'is-completed': fixture.period === 'FullTime'}" v-for="fixture in fixtures" :key="fixture.matchID">
            <td class="gc-listing__row-item gc-listing__row-item--id">{{ fixture.matchID }}</td>
            <template v-if="fixture.teamData">
              <td class="gc-listing__row-item">{{ fixture.teamData[0].teamName }}</td>
              <td class="gc-listing__row-item">{{ fixture.teamData[1].teamName }}</td>
            </template>
            <td class="gc-listing__row-item">{{ fixture.competitionName }}</td>
            <td class="gc-listing__row-item">{{ fixture.kickOffUTC | moment(('DD/MM/YY @ kk:mm Z')) }}</td>
            <td class="gc-listing__row-item">
              <v-checkbox
                class="gc-listing__row-checkbox"
                v-model="fixture.published"
                disabled
              ></v-checkbox>
            </td>
            <td class="gc-listing__row-item">
              <v-icon @click="editFixture(fixture.matchID, fixture.isCustom ? 'custom' : 'opta')">edit</v-icon>
            </td>
          </tr>
        </tbody>
      </table>

    </section>
  </section>
</template>

<script>
import axios from "axios"
import MiniHeader from "@/components/Library/MiniHeader"
import SelfHelp from "@/components/Library/SelfHelp"

export default {
  name: "FixturesList",

  components: {
    MiniHeader,
    SelfHelp
  },

  props: {
    data: {
      required: true,
      type: Object
    },
    isActive: {
      required: true,
      type: Boolean
    }
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/fixtures`,
    pageSize: 10,
    pageNumber: 1,
    pageCount: 0,
    dataLoaded: false,
    fixturesLoaded: false,
    selectedSeason: "",
    selectedCompetitionID: "",
    availableCompetitions: [],
    fixtures: [],
    customFixtures: []
  }),

  watch: {
    pageNumber(value, oldValue) {
      if (this.isActive && oldValue) {
        this.fixturesLoaded = false;
        this.updateFixtures()
      }
    },
    selectedSeason(value, oldValue) {
      if (this.isActive && oldValue) {
        this.updateFixtures()
      }
    },
    selectedCompetitionID() {
      if (this.isActive) {
        this.updateFixtures()
      }
    },
    isActive(value) {
      if (value === true && !this.dataLoaded) {
        this.$store.commit("startLoading");
        this.fetchFixtures();
      }
    }
  },

  mounted() {
    this.selectedSeason = this.data.seasons[0]

    this.createAllCompetitionsValue()

    this.updateFixtures()
  },

  methods: {
    fetchFixtures() {
      console.log("Fetch fixtures");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}/opta?clientMatches=true&teamID=${this.data.teamID}&seasonID=${this.selectedSeason.seasonID}${this.isAllCompetitions()}&pageSize=100`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("Fetch fixtures response: ", response);
          this.fixtures = response.data.body;
          this.pageCount = Math.ceil(response.data.totalDataCount / this.pageSize);
          this.$store.commit("completeLoading");
          this.dataLoaded = true
          this.fixturesLoaded = true
        })
        .catch(e => {
          console.error("Problem retrieving fixtures", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    updateFixtures() {
      this.$store.commit("startLoading");

      this.fetchFixtures();
    },

    addFixture() {
      this.$router.push({ name: "Fixture Editor", params: {matchID: "create", teamID: this.data.teamID, seasonID: this.selectedSeason.seasonID, dataType: "custom" } })
    },

    editFixture(matchID, dataType) {
      this.$store.commit("startLoading");
      this.$router.push({ name: "Fixture Editor", params: {matchID, teamID: this.data.teamID, seasonID: this.selectedSeason.seasonID, dataType} })
    },

    createAllCompetitionsValue() {
      this.availableCompetitions = JSON.parse(JSON.stringify(this.selectedSeason.competitions))

      this.availableCompetitions.unshift({
        competitionID: -1,
        competitionName: "All Competitions"
      })

      this.selectedCompetitionID = this.availableCompetitions[0].competitionID
    },

    isAllCompetitions() {
      if (this.selectedCompetitionID === -1) {
        return ""
      } else {
        return `&competitionID=${this.selectedCompetitionID}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .fixtures {
    margin: 40px 0;
  }

  .error-row {
    padding: 30px 10px;
    color: red;
    border-bottom: 1px solid rgba(209, 216, 245, 0.6);
    opacity: 0.7;
  }

  .fixture-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 0;

    &__select {
      max-width: calc(50% - 40px);
      min-width: calc(50% - 40px);

      &--first {
        margin: 0 40px 0 0;
      }
    }
  }

  .is-completed {
    opacity: 0.7;

    & + tr:not(.is-completed) > td {
      border-top: 8px solid;
    }

    &:hover {
      opacity: 1;
    }
  }
</style>
