<template>
  <v-dialog
    class="trivia-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
        Trivia
        </v-card-title>

        <v-card-text>

        <v-form enctype="multipart/form-data" v-model="valid" ref="triviaForm" lazy-validation>

          <section v-if="!previewImage">
            <label class="label" for="title">Title</label>
            <v-text-field
              id="title"
              outline
              v-model="triviaData.title"
              solo
              flat
              :rules="[rules.required]"
              class="text-field--lableless"
              validate-on-blur
            ></v-text-field>

            <label class="label" for="text">Trivia Text</label>
            <v-textarea
              id="text"
              outline
              v-model="triviaData.text"
              solo
              flat
              :rules="[rules.required]"
              class="text-field--lableless"
              validate-on-blur
            ></v-textarea>

            <v-layout v-if="backgroundImage" align-center justify-center>
              <img class="single-image__preview single-image__preview--border" :src="backgroundImage" />
            </v-layout>

            <div class="uploader__ctas">
              <label class="label" for="file-upload">Background image (optional)</label>
              <v-btn
                color="accent"
                type="button"
                depressed>
                <label for="file-upload" class="uploader__upload-button">
                  <v-icon small>add</v-icon>
                  <span v-if="backgroundImage">Replace image</span>
                  <span v-else>Choose image</span>
                </label>
              </v-btn>
              <input class="uploader__upload-input" id="file-upload" ref="fileUpload" type="file" @change="convertImage($event)" accept="image/*"/>

            </div>

            <v-btn
              color="accent"
              outline
              flat
              @click="dialog = false"
            >
              Cancel
            </v-btn>

            <v-btn
              color="accent"
              depressed
              :disabled="!triviaData.title || !triviaData.text"
              @click="validate()"
            >
              <span v-if="widgetData">Update trivia</span>
              <span v-else>Add trivia</span>
            </v-btn>
          </section>

        </v-form>

        <v-form enctype="multipart/form-data" v-model="validImage" ref="imageForm" lazy-validation v-if="previewImage">

          <section class="single-image">

            <img class="single-image__preview" :src="previewImage" />

            <section class="edit">
              <label class="label" for="title">Title</label>
              <v-text-field
                id="title"
                v-model="imageData.name"
                solo
                flat
                required
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>

              <label class="label" for="caption">Caption</label>
              <v-text-field
                id="caption"
                v-model="imageData.description"
                solo
                flat
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>

              <label class="label" for="tags">Tags</label>

              <div class="tag-manager">
                <v-text-field
                  class="tag-manager__input"
                  id="tags"
                  v-model="tagString"
                  solo
                  flat
                  :hide-details="true"
                  @keyup.enter.native="tagged(tagString)"
                  placeholder="Hit enter to add tags"
                ></v-text-field>
                <template v-if="imageData.tags" v-for="(tag, index) in imageData.tags">
                  <v-chip class="tag-manager__tag" color="accent" text-color="white" :v-model="tag.id">{{tag.name}}<span class="tag-manager__close" @click="removeTag(index)">X</span></v-chip>
                </template>
              </div>

              <v-card-actions v-if="previewImage">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  outline
                  flat
                  previewImage = null
                  @click="previewImage = null"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="accent"
                  outline
                  flat
                  previewImage = null
                  @click="validateImage()"
                >
                  Post
                </v-btn>
              </v-card-actions>
            </section>

          </section>
        </v-form>

        </v-card-text>
      </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "TriviaWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}`,
    dialog: false,
    valid: false,
    validImage: false,
    previewImage: null,
    backgroundImage: null,
    rules: {
      required: value => !!value || "Required."
    },
    triviaData: {
      title: null,
      text: null,
      imageID: null
    },
    imageData: {
      image: "",
      name: "",
      description: "",
      tags: [],
      fileSize: null
    },
    tagString: []
  }),

  mounted() {
    console.log("Trivia widget mounted");

    if (this.widgetData) {
      this.triviaData = JSON.parse(JSON.stringify(this.widgetData));
      if(this.triviaData.imageID) {
        this.fetchImage(this.triviaData.imageID);
      }
    }
  },

  methods: {
    validate() {
      this.errorMessage = ""
      if (this.$refs.triviaForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },
    validateImage() {
      this.errorMessage = ""
      if (this.$refs.imageForm.validate()) {
        console.log("Validation passed");
        this.uploadImage();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },
    convertImage($event, single) {
      const file = single || $event.target.files[0]
      const reader = new FileReader();

      reader.addEventListener("load", evt => {
        this.previewImage = reader.result;
        console.log("reader", reader);

        const base64DataOnly = reader.result.split(",")[1].toString();

        this.imageData = {
          image: base64DataOnly,
          name: "",
          description: "",
          tags: [],
          fileSize: file.size,
          previewImage: this.previewImage
        }
      });

      if (file) {
        reader.readAsDataURL(file);
      }
    },

    uploadImage() {
      console.log("Upload image: ", this.imageData);
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: (this.mediaLibraryID) ? `${process.env.VUE_APP_BASEURL}/media/upload/${this.mediaLibraryID}` : `${process.env.VUE_APP_BASEURL}/media/upload/`,
          headers: { "Authorization": jwt },
          data: this.imageData
        })
        .then(response => {
          console.log("Saved image: ", response);
          this.previewImage = null;
          this.triviaData.imageID = response.data.body.mediaLibraryID;
          this.backgroundImage = response.data.body.location;
          this.$emit("imageUploaded", response.data.body.mediaLibraryID);
        })
        .catch(e => {
          console.error("Problem saving image: ", e);
        });
      })
    },
    tagged(item){
      console.log("Adding tag:", item);
      const tag = {
        id: item.replace(/\s/g,""),
        name: item
      }
      this.imageData.tags.push(tag);
      this.tagString = "";
      console.log(this.imageData.tags);
    },
    removeTag(index) {
      this.imageData.tags.splice(index, 1);
      console.log("Removed tag", this.imageData.tags);
    },
    updateData() {
      console.log("Update data:", this.triviaData);
      this.$emit("updatedData", this.triviaData);
      this.dialog = false;
    },
    fetchImage(mediaLibraryID) {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url:`${process.env.VUE_APP_BASEURL}/media/${mediaLibraryID}`,
          headers: { "Authorization": jwt },
        }).then(response => {
          console.log(response);
          this.backgroundImage = response.data.body.location;
        })
        .catch(e => {
          console.error("Error fetching image:", e);
        });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .single-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 30px;
    border-top: 1px solid #ebebeb;

    &__preview {
      margin: 10px 40px;
      max-height: 80px;
      max-width: 80px;
      &--border {
        border: 2px solid $lightgrey;
      }
    }
  }
  .uploader {
    margin: 0 0 40px 0;

    &__heading {
      margin: 0 0 20px;
      color: #24002F;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 500;
    }

    &__upload-input {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      white-space: nowrap;
      width: 1px;
    }

    &__upload-button {
      cursor: pointer;
    }

    &__ctas {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 30px;
      align-items: center;
    }
  }
  .edit {
    margin: 0 10px 20px 0;
    width: 100%;
  }
</style>
