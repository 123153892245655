<template>
  <v-dialog
    class="fixtures-list-widget"
    v-model="dialog"
    width="1400"
    lazy
  >
    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
          Calendar
        </v-card-title>

        <v-card-text>

        <v-form enctype="multipart/form-data" ref="calendarForm">
          <div>
            <v-dialog   
              v-model="isCalendarSelectorOpen"
              width="1400"
              lazy 
              class="fixture-details">

              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  class="custom-button"
                  type="button"
                  v-bind="attrs" @on="on"
                  @click="isCalendarSelectorOpen = true">Choose Item</v-btn>
              </template>

              <slot slot="activator"></slot>
              <v-card v-if="isCalendarSelectorOpen" class="card">
                <calendar-listing 
                :alternativeMode="'selection'" 
                @selected="addCalendarItem($event)" 
                @unselected="removeCalendarItem(true)"
                :selectedItem="calendarItemData ? calendarItemData.id : null" />
              </v-card>
              
            </v-dialog>
          </div>

          <template v-if="calendarItemData">
            <li class="calendar-listing-item" :class="`calendar-listing-item--${calendarItemData.attributes.type}`">
              <template v-if="calendarItemData.attributes.imageData.landscape">
                <img v-if="calendarItemData.attributes.imageData.landscape.imageKey" :src="`${imageDomain}/${calendarItemData.attributes.imageData.landscape.imageKey}`" class="calendar-listing-item__image" />
              </template>

              <div class="calendar-listing-item__inner">
                <div class="calendar-listing-item__statuses">
                  <p v-if="calendarItemData.attributes.published" class="status status--published">Published</p>
                  <p v-else class="status status--draft">Draft</p>
                </div>

                <h2 class="calendar-listing-item__subheading subheading">{{ calendarItemData.attributes.title }}</h2>
                <p class="calendar-listing-item__label">Start: {{ calendarItemData.attributes.startAt | moment('DD/MM/YY kk:mm (Z)') }}</p>
                <p class="calendar-listing-item__id" :class="`label label--${calendarItemData.attributes.type}`">Type: {{ calendarItemData.attributes.type }} {{ calendarItemData.attributes.type == 'streamline' && calendarItemData.attributes.streamlineData ? ` - ${calendarItemData.attributes.streamlineData.streamType}` : '' }}</p>
              </div>
              <v-btn color="error" @click="removeCalendarItem()">Remove</v-btn>
            </li>
          </template>

          <v-btn
            color="accent"
            outline
            flat
            @click="dialog = false"
          >
            Cancel
          </v-btn>

          <v-btn 
            color="accent"
            depressed
            @click="postCalendarItem()"
          >
            Add calendar item
          </v-btn>

        </v-form>

        </v-card-text>

      </v-card>

  </v-dialog>
</template>

<script>
import calendarListing from "@/components/Calendar/CalendarListing"

export default {
  name: "SingleCalendarItemWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },

  components: {
    calendarListing
  },

  data: () => ({
    modules: null,
    dialog: false,
    isCalendarSelectorOpen: false,
    imageDomain: process.env.VUE_APP_IMAGEURL,
    calendarItemData: null,
  }),

  created() {
    this.modules = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")

    if (this.widgetData) {
      this.calendarItemData = this.widgetData.data
    }
  },

  methods: {
    addCalendarItem(data) {
      this.calendarItemData = data
      this.isCalendarSelectorOpen = false
    },

    removeCalendarItem(bypass) {
      if (bypass || confirm("Are you sure you want to remove this item?")) {
        this.calendarItemData = null
      }
    },
    
    postCalendarItem() {
      this.$emit("updatedData", {data: this.calendarItemData, id: this.calendarItemData ? this.calendarItemData.id : null});
      this.dialog = false
    },
  }
}
</script>

<style lang="scss" scoped>
.calendar-listing-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 140px;
    margin: 16px 0 48px;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #dfe3e9;
    border-top: 4px solid #67247c;
    border-radius: 6px;
    &--event {
      border-top: 4px solid #C1922A;
    }
    &--match {
      border-top: 4px solid color(draftHighlight);

    }
    &--streamline {
      border-top: 4px solid #67247c;
    }

    &__image {
      max-width: 160px;
      width: 100%;
      margin-right: 32px;
    }

    &__inner {
      width: 100%;
    }

    &__id {
      margin: 0;
      font-size: 11px;
      opacity: 0.8;
      word-break: break-all;
    }

    &__subheading {
      margin: 0;
      padding: 5px 0 0;
    }

    &__label {
      margin: 4px 0;
      padding: 0;
      font-size: 12px;
      word-break: break-all;
    }

    &__statuses {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  .status {
    display: inline-block;
    padding: 3px 12px;
    margin: 0 8px 8px 0;
    font-size: 10px;
    color: #fff;
    background-color: #ccc;
    border-radius: 4px;

    &.status--draft {
      background-color: color(draftHighlight);
    }

    &.status--published {
      background-color: #67247c;
    }
  }

  .label {
    &--event {
      color: #C1922A;
    }
    &--match {
      color: color(draftHighlight);

    }
    &--streamline {
      color: #67247c;
    }
  }
</style>
