export default {
  data: [
    {
      sectionName: "Sponsor banner",
      sectionDesc: "",
      model: "sponsorBanner",
      fields: [
        {
          label: "Upload Desktop Image",
          model: "sponsorBanner_desktopImage",
          type: "Image"
        },
        {
          label: "Upload Mobile Image",
          model: "sponsorBanner_mobileImage",
          type: "Image"
        },
        {
          label: "Destination URL",
          model: "sponsorBanner_link",
          type: "Text"
        },
        {
          label: "Open in New Tab?",
          model: "sponsorBanner_newTab",
          type: "Checkbox"
        }
      ]
    },
    {
      sectionName: "Custom Promo",
      sectionDesc: "This promo will appear when there is no \"Next Match\" Information",
      model: "customPromo",
      fields: [
        {
          label: "Section Title",
          model: "customPromo_title",
          type: "Text"
        },
        {
          label: "Upload Image",
          model: "customPromo_image",
          type: "Image"
        },
        {
          label: "Destination URL",
          model: "customPromo_link",
          type: "Text"
        },
        {
          label: "Button Text",
          model: "customPromo_text",
          type: "Text"
        },
        {
          label: "Open in New Tab?",
          model: "customPromo_newTab",
          type: "Checkbox"
        }
      ]
    },
    {
      sectionName: "Internal Banner",
      sectionDesc: "",
      model: "internalBanner",
      fields: [
        {
          label: "Upload Desktop Image",
          model: "internalBanner_desktopImage",
          type: "Image"
        },
        {
          label: "Upload Mobile Image",
          model: "internalBanner_mobileImage",
          type: "Image"
        },
        {
          label: "Destination URL",
          model: "internalBanner_link",
          type: "Text"
        },
        {
          label: "Open in New Tab?",
          model: "internalBanner_newTab",
          type: "Checkbox"
        }
      ]
    },
    {
      sectionName: "Internal Advert",
      sectionDesc: "",
      model: "internalAdvert",
      fields: [
        {
          label: "Upload Image",
          model: "internalAdvert_image",
          type: "Image"
        },
        {
          label: "Destination URL",
          model: "internalAdvert_link",
          type: "Text"
        },
        {
          label: "Open in New Tab?",
          model: "internalAdvert_newTab",
          type: "Checkbox"
        }
      ]
    },
    {
      sectionName: "Tours Advert",
      sectionDesc: "",
      model: "toursAdvert",
      fields: [
        {
          label: "Upload Image",
          model: "toursAdvert_image",
          type: "Image"
        },
        {
          label: "Destination URL",
          model: "toursAdvert_link",
          type: "Text"
        },
        {
          label: "Open in New Tab?",
          model: "toursAdvert_newTab",
          type: "Checkbox"
        }
      ]
    },
    {
      sectionName: "Footer Advertising",
      sectionDesc: "Upload the images to appear in the footer slot on your website",
      model: "adModule",
      fields: [
        {
          label: "Advert slot 1 - Upload Image",
          model: "adModule1_image",
          type: "Image"
        },
        {
          label: "Advert slot 1 - Destination URL",
          model: "adModule1_link",
          type: "Text"
        },
        {
          label: "Advert slot 1 - Open in New Tab?",
          model: "adModule1_newTab",
          type: "Checkbox"
        },
        {
          label: "Advert slot 1 - Advert Unique ID",
          model: "adModule1_dataID",
          type: "Text"
        },
        {
          label: "Advert slot 2 - Upload Image",
          model: "adModule2_image",
          type: "Image"
        },
        {
          label: "Advert slot 2 - Destination URL",
          model: "adModule2_link",
          type: "Text"
        },
        {
          label: "Advert slot 2 - Open in New Tab?",
          model: "adModule2_newTab",
          type: "Checkbox"
        },
        {
          label: "Advert slot 2 - Advert Unique ID - No spaces",
          model: "adModule2_dataID",
          type: "Text"
        },
        {
          label: "Advert slot 3 - Upload Image",
          model: "adModule3_image",
          type: "Image"
        },
        {
          label: "Advert slot 3 - Destination URL",
          model: "adModule3_link",
          type: "Text"
        },
        {
          label: "Advert slot 3 - Open in New Tab?",
          model: "adModule3_newTab",
          type: "Checkbox"
        },
        {
          label: "Advert slot 3 - Advert Unique ID - No spaces",
          model: "adModule3_dataID",
          type: "Text"
        }
      ]
    },
    {
      sectionName: "Celtic TV Promo",
      sectionDesc: "",
      model: "celticTV",
      fields: [
        {
          label: "Section Heading",
          model: "celticTV_Heading",
          type: "Text"
        },
        {
          label: "Vimeo ID",
          model: "celticTV_videoID",
          type: "Text"
        },
        {
          label: "Description Title",
          model: "celticTV_title",
          type: "Text"
        },
        {
          label: "Description Content - allows html tags",
          model: "celticTV_description",
          type: "Text"
        },
        {
          label: "CTA URL",
          model: "celticTV_link",
          type: "Text"
        },
        {
          label: "CTA Text",
          model: "celticTV_linkText",
          type: "Text"
        },
        {
          label: "Open in New Tab?",
          model: "celticTV_newTab",
          type: "Checkbox"
        },
      ]
    },
    {
      sectionName: "Club Honours",
      sectionDesc: "Update the values in the 'Club Honours' feature block",
      model: "celticHonours",
      fields: [
        {
          label: "League Champions",
          model: "celticHonours_leagueChampions",
          type: "Text"
        },
        {
          label: "Scottish Cup Winners",
          model: "celticHonours_scottishCup",
          type: "Text"
        },
        {
          label: "League Cup Winners",
          model: "celticHonours_leagueCup",
          type: "Text"
        },
        {
          label: "European Cup Winners",
          model: "celticHonours_europeanCup",
          type: "Text"
        }
      ]
    },
    {
      sectionName: "Temporary adverts - News page, below featured news",
      sectionDesc: "Temporary advert section while new advertisement features are being built",
      model: "tempAdvert1",
      fields: [
        {
          label: "Promo link",
          model: "promo_link",
          type: "Text"
        },
        {
          label: "Image alt text",
          model: "promo_alt",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_image",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Temporary adverts - At the end of a news article",
      sectionDesc: "Temporary advert section while new advertisement features are being built",
      model: "tempAdvert2",
      fields: [
        {
          label: "Promo link",
          model: "promo_link",
          type: "Text"
        },
        {
          label: "Image alt text",
          model: "promo_alt",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_image",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Temporary adverts - Player profile for Sorare",
      sectionDesc: "Temporary advert section while new advertisement features are being built",
      model: "tempAdvert3",
      fields: [
        {
          label: "Promo link",
          model: "promo_link",
          type: "Text"
        },
        {
          label: "Image alt text",
          model: "promo_alt",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_image",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Temporary adverts - Fixtures, below next match",
      sectionDesc: "Temporary advert section while new advertisement features are being built",
      model: "tempAdvert4",
      fields: [
        {
          label: "Promo link",
          model: "promo_link",
          type: "Text"
        },
        {
          label: "Image alt text",
          model: "promo_alt",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_image",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Temporary adverts - Next match widget",
      sectionDesc: "Temporary advert section while new advertisement features are being built",
      model: "tempAdvert5",
      fields: [
        {
          label: "Promo link",
          model: "promo_link",
          type: "Text"
        },
        {
          label: "Image alt text",
          model: "promo_alt",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_image",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Temporary adverts - Homepage takeover",
      sectionDesc: "Temporary advert section while new advertisement features are being built",
      model: "tempAdvert6",
      fields: [
        {
          label: "Promo link",
          model: "promo_link",
          type: "Text"
        },
        {
          label: "Image alt text",
          model: "promo_alt",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_image",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Video Carousel",
      sectionDesc: "Section for Youtube videos linked in a carousel",
      model: "video_data",
      fields: [
        {
          label: "Section title",
          model: "title",
          type: "Text"
        },
        {
          label: "Main button text",
          model: "buttonText",
          type: "Text"
        },
        {
          label: "Main button URL",
          model: "buttonUrl",
          type: "Text"
        }
      ]
    },
    {
      sectionName: "Video 1",
      sectionDesc: "Data for a video in the carousel",
      model: "video1",
      fields: [
        {
          label: "Video title",
          model: "title",
          type: "Text"
        },
        {
          label: "Video URL",
          model: "videoUrl",
          type: "Text"
        },
        {
          label: "Video image",
          model: "image",
          type: "Image"
        },
      ]
    },
    {
      sectionName: "Video 2",
      sectionDesc: "Data for a video in the carousel",
      model: "video2",
      fields: [
        {
          label: "Video title",
          model: "title",
          type: "Text"
        },
        {
          label: "Video URL",
          model: "videoUrl",
          type: "Text"
        },
        {
          label: "Video image",
          model: "image",
          type: "Image"
        },
      ]
    },
    {
      sectionName: "Video 3",
      sectionDesc: "Data for a video in the carousel",
      model: "video3",
      fields: [
        {
          label: "Video title",
          model: "title",
          type: "Text"
        },
        {
          label: "Video URL",
          model: "videoUrl",
          type: "Text"
        },
        {
          label: "Video image",
          model: "image",
          type: "Image"
        },
      ]
    },
    {
      sectionName: "Video 4",
      sectionDesc: "Data for a video in the carousel",
      model: "video4",
      fields: [
        {
          label: "Video title",
          model: "title",
          type: "Text"
        },
        {
          label: "Video URL",
          model: "videoUrl",
          type: "Text"
        },
        {
          label: "Video image",
          model: "image",
          type: "Image"
        },
      ]
    },
    {
      sectionName: "Video 5",
      sectionDesc: "Data for a video in the carousel",
      model: "video5",
      fields: [
        {
          label: "Video title",
          model: "title",
          type: "Text"
        },
        {
          label: "Video URL",
          model: "videoUrl",
          type: "Text"
        },
        {
          label: "Video image",
          model: "image",
          type: "Image"
        },
      ]
    },
    {
      sectionName: "Video 6",
      sectionDesc: "Data for a video in the carousel",
      model: "video6",
      fields: [
        {
          label: "Video title",
          model: "title",
          type: "Text"
        },
        {
          label: "Video URL",
          model: "videoUrl",
          type: "Text"
        },
        {
          label: "Video image",
          model: "image",
          type: "Image"
        },
      ]
    },
    {
      sectionName: "Video 7",
      sectionDesc: "Data for a video in the carousel",
      model: "video7",
      fields: [
        {
          label: "Video title",
          model: "title",
          type: "Text"
        },
        {
          label: "Video URL",
          model: "videoUrl",
          type: "Text"
        },
        {
          label: "Video image",
          model: "image",
          type: "Image"
        },
      ]
    },
    {
      sectionName: "Video 8",
      sectionDesc: "Data for a video in the carousel",
      model: "video8",
      fields: [
        {
          label: "Video title",
          model: "title",
          type: "Text"
        },
        {
          label: "Video URL",
          model: "videoUrl",
          type: "Text"
        },
        {
          label: "Video image",
          model: "image",
          type: "Image"
        },
      ]
    },
    {
      sectionName: "Video 9",
      sectionDesc: "Data for a video in the carousel",
      model: "video9",
      fields: [
        {
          label: "Video title",
          model: "title",
          type: "Text"
        },
        {
          label: "Video URL",
          model: "videoUrl",
          type: "Text"
        },
        {
          label: "Video image",
          model: "image",
          type: "Image"
        },
      ]
    },
    {
      sectionName: "Video 10",
      sectionDesc: "Data for a video in the carousel",
      model: "video10",
      fields: [
        {
          label: "Video title",
          model: "title",
          type: "Text"
        },
        {
          label: "Video URL",
          model: "videoUrl",
          type: "Text"
        },
        {
          label: "Video image",
          model: "image",
          type: "Image"
        },
      ]
    },
    {
      sectionName: "Video 11",
      sectionDesc: "Data for a video in the carousel",
      model: "video11",
      fields: [
        {
          label: "Video title",
          model: "title",
          type: "Text"
        },
        {
          label: "Video URL",
          model: "videoUrl",
          type: "Text"
        },
        {
          label: "Video image",
          model: "image",
          type: "Image"
        },
      ]
    },
    {
      sectionName: "Video 12",
      sectionDesc: "Data for a video in the carousel",
      model: "video12",
      fields: [
        {
          label: "Video title",
          model: "title",
          type: "Text"
        },
        {
          label: "Video URL",
          model: "videoUrl",
          type: "Text"
        },
        {
          label: "Video image",
          model: "image",
          type: "Image"
        },
      ]
    },
  ]
}
