<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <p class="widget-info">This widget allows you to display all available StreamAMG passes.  You can 'feature' multiple passes to bring them to the front of the list.  If you choose 'Render Inline HTML' any style customisations in the stream data will pull through onto the pass.</p>

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()">Use this widget</v-btn>
    </template>

    <div class="widget-fieldset" v-else>

      <label class="label">Title</label>
      <v-text-field
        id="widgetData.title"
        solo
        flat
        v-model="updatedData.widgetData.title">
      </v-text-field>

      <label class="label">Description</label>
      <v-text-field
        id="widgetData.description"
        solo
        flat
        v-model="updatedData.widgetData.description">
      </v-text-field>

      <label class="label">Style</label>
      <v-select
        id="widgetData.style"
        :items="styleOptions"
        solo
        flat
        v-model="updatedData.widgetData.style">
      </v-select>

      <label class="label">Use Inline Styles?</label>
      <v-select
        id="widgetData.style"
        :items="renderOptions"
        solo
        flat
        v-model="updatedData.widgetData.renderInlineHtml">
      </v-select>

      <label class="label">Highlight Passes?</label>
      <v-text-field
        id="widgetData.description"
        solo
        flat
        v-model="highlightPassString"
        @input="setHighlightPasses()">
      </v-text-field>

    </div>
  </div>
</template>

<script>
export default {
  name: "StreamAmgPasses",

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent()
      },
      deep: true
    }
  },

  props: {
    editData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    styleOptions: [{text: "Light Mode", value: "light"}, {text: "Dark Mode", value: "dark"}],
    renderOptions: [{text: "Yes, render inline HTML", value: true}, {text: "No, use standard card", value: false}],
    highlightPassString: "",
    updatedData: {
      title: null,
      style: null,
      description: null,
      renderInlineHtml: false,
      highlightedPassId: [],
    }
  }),

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.editData))
    this.highlightPassString = this.editData.widgetData.highlightedPassId.join()
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "StreamAmgPasses"
      this.updatedData.widgetName = "StreamAmg Passes"
      this.updatedData.widgetID = this.$uuid.v1()
      this.updatedData.widgetData = {
        title: null,
        style: null,
        description: null,
        renderInlineHtml: false,
        highlightedPassId: [],
      }
      this.$emit("initialised")
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData)
    },

    setHighlightPasses() {
      this.updatedData.widgetData.highlightedPassId = this.highlightPassString.split(",")
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
