<template>
  <v-dialog
    class="single-field-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>
      <v-form enctype="multipart/form-data" ref="singleFieldForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            class="widget__heading"
          >
            {{ widgetName }}
          </v-card-title>

          <v-card-text>

            <p v-if="widgetDescription" class="widget__description">{{ widgetDescription }}</p>

            <label class="label" for="widget-field">{{ widgetLabel }}</label>
            <v-textarea
              id="widget-field"
              v-model="updatedData.content"
              placeholder="Value"
              solo
              flat
              required
              :rules="[rules.required]"
              validate-on-blur
            ></v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="accent"
              outline
              flat
              @click="validate()"
            >
              Post
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

  </v-dialog>
</template>

<script>
export default {
  name: "SingleFieldWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    },
    widgetName: {
      type: String,
      required: true
    },
    widgetLabel: {
      type: String,
      required: true
    },
    widgetDescription: {
      type: String,
      required: false
    },
  },

  data: () => ({
    dialog: false,
    valid: false,
    rules: {
      required: value => !!value || "Required."
    },
    updatedData: {
      content: ""
    }
  }),

  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
    }
  },

  methods: {
    updateData() {
      console.log("Update data:", this.updatedData);
      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
    },
    validate() {
      this.errorMessage = ""
      if (this.$refs.singleFieldForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .widget {
    &__description {
      margin: 0 0 20px;
      color: #7B6B80;
      font-size: 14px;
    }
  }
</style>
