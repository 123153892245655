
import GenericListing from './GenericListing.vue'
import { defineComponent } from "vue";

export default defineComponent({
    name: "GenericChildManager",

    components: {
        GenericListing
    },

    props: {
        childData: {
            type: Object,
            required: true,
        },
        parentID: {
            type: String,
            required: true,
        }
    },

    setup() {

        return {
        }
    },
})
