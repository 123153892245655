<template>
    <v-dialog
        class="upcoming-fixtures-widget"
        v-model="dialog"
        width="580"
        lazy
    >
        <slot slot="activator"></slot>

        <v-card>
            <v-card-title class="widget__heading"> League Table </v-card-title>

            <v-card-text>
                <v-form
                    enctype="multipart/form-data"
                    ref="upcomingFixturesForm"
                    lazy-validation
                >
                    <v-checkbox
                        label="Show Full Table"
                        primary
                        v-model="leagueTableData.showFullTable"
                    />

                    <template v-if="!leagueTableData.showFullTable">
                        <label
                            class="label"
                            for="totalTeams"
                        >Teams displayed above and below</label>
                        <v-text-field
                            id="totalTeams"
                            v-model="leagueTableData.totalTeams"
                            persistent-hint
                            label="Number of Teams displayed"
                            required
                            type="number"
                            class="mb-3"
                        ></v-text-field>
                    </template>

                    <match-picker-efl
                        :includeMatch="false"
                        @updated="setMatchEfl($event)"
                        :competitionOnly="leagueTableData.showFullTable"
                    />

                    <v-btn
                        color="accent"
                        outline
                        flat
                        @click="dialog = false"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        color="accent"
                        :disabled="!leagueTableData.competition"
                        depressed
                        @click="updateData()"
                    >
                        <div v-if="widgetData">Update League Table</div>
                        <div v-else>Add League Table</div>
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import MatchPickerEfl from "../Library/MatchPickerEfl.vue";

export default {
    name: "LeagueTableWidget",

    props: {
        widgetData: {
            type: Object,
            required: false,
        },
    },

    components: { MatchPickerEfl },

    data: () => ({
        multiClubMatchesApi: process.env.VUE_APP_MULTICLUBMATCHESAPI,
        dialog: false,
        competitions: [],
        teams: [],
        leagueTableData: {
            showFullTable: false,
            totalTeams: "3",
            squad: null,
            season: null,
            squadName: null,
            competition: null,
            competitionName: null,
        },
    }),

    mounted() {
        console.log("League Table widget mounted");

        if (this.widgetData) {
            this.leagueTableData = JSON.parse(JSON.stringify(this.widgetData));
        }
    },

    methods: {
        setMatchEfl(payload) {
            console.log("Set EFL Match", payload);

            this.leagueTableData.squad = payload.teamID;
            this.leagueTableData.season = payload.seasonID;
            this.leagueTableData.squadName = payload.teamName;
            this.leagueTableData.competition = payload.competitionID;
            this.leagueTableData.competitionName = payload.competitionName;
        },

        updateData() {
            console.log("Update data:", this.leagueTableData);
            this.$emit("updatedData", this.leagueTableData);
            this.dialog = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.upcoming-fixture-widget {
    margin: 10px 0 30px;
    display: flex;
    justify-content: space-evenly;

    &__item {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        +label {
            cursor: pointer;
            text-align: center;

            &:before {
                content: "";
                width: 64px;
                height: 56px;
                display: block;
                cursor: pointer;
                margin: auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                opacity: 0.5;
            }

            span {
                font-size: 1rem;
                color: $purple;
                margin-top: 5px;
                display: block;
                border-radius: 100px;
                padding: 3px 6px;
            }
        }

        &:checked {
            +label {
                &:before {
                    opacity: 1;
                }

                span {
                    background-color: #67247c;
                    color: white;
                }
            }
        }

        &--one {
            +label:before {
                background-image: url("../../assets/upcoming-fixtures-1.svg");
            }
        }

        &--two {
            +label:before {
                background-image: url("../../assets/upcoming-fixtures-2.svg");
            }
        }

        &--three {
            +label:before {
                background-image: url("../../assets/upcoming-fixtures-3.svg");
            }
        }

        &--four {
            +label:before {
                background-image: url("../../assets/upcoming-fixtures-4.svg");
            }
        }

        &--five {
            +label:before {
                background-image: url("../../assets/upcoming-fixtures-5.svg");
            }
        }
    }
}
</style>
