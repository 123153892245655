export default {
	"AdditionalPageTypes": true,
	"Admin": true,
	"Advertising": true,
	"AggregatedContentFeed": true,
	"AllowCustomFields": true,
	"AppConfig": true,
	"AppEcommerceManager": true,
	"AppScorePredictor": true,
	"AppSocialManager": true,
	"AppTicketingManager": true,
	"BreakingNews": true,
	"BuildManager": true,
	"Calendar": true,
	"CustomCommentary": true,
	"CustomLineups": true,
	"CustomTags": true,
	"CustomTables": true,
	"Dashboard": true,
	"Fixtures": true,
	"Forms": true,
	"HomepageManager": true,
	"HomepageNewsPromo": true,
	"HomepagePlayerHighlight": true,
	"Lab": true,
	"LFCExclusive": true,
	"MatchdayTakeover": true,
	"Media": true,
	"News": true,
	"OptaIntegration": true,
	"Organisations": true,
	"OverlayPromos": true,
	"Pages": true,
	"PlatformOptions": true,
	"PlayerVoting": true,
	"PreventEntitlementEmails": false,
	"Profile": true,
	"RelatedContentTagging": true,
	"RewardsManager": true,
	"RewardPoints": true,
	"Rover": true,
	"Settings": false,
	"SiteConfig": true,
	"Sponsors": true,
	"Squads": true,
	"SsoUsers": true,
	"StreamAMGIntegration": false,
	"Streamline": true,
	"StreamlineAdverts": true,
	"StreamlinePromoCodes": true,
	"TeamCrestManager": true,
	"Videos": true,
	"Voice": true,
	"WebStats": true
}
