<template>
<div class="listwidget__item draggable-item">
  <div class="listwidget__item-content">
    <h4 v-if="listWidgetEdit" class="listwidget__heading">Edit item</h4>
    <div class="listwidget__item-content-summary">
      <div class="listwidget__item-content-image" :style="`background-image:url('${this.imageSrc}')`"></div>

      <template v-if="listWidgetEdit">
        <div class="uploader__upload">
          <v-btn
            color="accent"
            type="button"
            depressed>
            <label for="'file-upload_'+id" class="uploader__upload-button"><v-icon small>add</v-icon> Upload new image</label>
          </v-btn>
          <input class="uploader__upload-input" id="'file-upload_'+id" ref="fileUpload" type="file" @change="convertImage($event)" accept="image/*"/>
        </div>
      </template>

      <ul v-if="!listWidgetEdit" class="listwidget__item-content-details">
        <li><strong>Title:</strong> {{ this.title }}</li>
        <li><strong>Text:</strong> {{ this.text }}</li>
        <li><strong>Link:</strong> {{ this.link }}</li>
      </ul>
      <div v-if="!listWidgetEdit" class="listwidget__item-content-action">
        <button class="listwidget__item-content-action" type="button" @click="listWidgetEdit = true">
          <v-icon>edit</v-icon>
        </button>
        <button class="listwidget__item-content-action" type="button" @click="removeItem()">
          <v-icon>delete</v-icon>
        </button>
      </div>
    </div>
  </div>
  <v-layout v-if="listWidgetEdit" class="listwidget__item-edit">
    <v-form v-model="valid" ref="dialogListItemWidgetForm" lazy-validation>
      <label class="label" :for="'title_'+id">Title</label>
      <v-text-field v-model="listItem.title" :id="'title_'+id" solo flat required :rules="[rules.required]" validate-on-blur class="listwidget__item-edit-input"></v-text-field>
      <label class="label" :for="'text_'+id">Text</label>
      <v-text-field v-model="listItem.text" :id="'text_'+id" solo flat required :rules="[rules.required]" validate-on-blur class="listwidget__item-edit-input"></v-text-field>
      <label class="label" :for="'link_'+id">Link</label>
      <v-text-field v-model="listItem.link" :id="'link_'+id" solo flat required :rules="[rules.required]" validate-on-blur class="listwidget__item-edit-input"></v-text-field>
      <div v-if="errorMessage" class="error--text error-message">
        {{errorMessage}}
      </div>
      <v-card-actions align-center>
        <v-spacer></v-spacer>
        <div class="flex align-center">
          <v-btn color="grey" outline flat @click="listWidgetEdit = null">
            Cancel
          </v-btn>
          <v-btn color="accent" outline flat previewImage=null @click="validate()">
            Update
          </v-btn>
        </div>
      </v-card-actions>
    </v-form>
  </v-layout>
</div>
</template>

<script>
import axios from "axios"

export default {
  name: "WidgetListItem",

  components: {},

  props: {
    guid: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    mediaLibraryID: {
      type: String,
      required: false
    }
  },

  data: function() {
    return {
      id: null,
      dialogListItemWidgetForm: false,
      valid: false,
      listWidgetEdit: false,
      imageSrc: null,
      listItem: {
        guid: null,
        title: this.title,
        text: this.text,
        link: this.link,
        mediaLibraryID: this.mediaLibraryID
      },
      rules: {
        required: value => !!value || "Required."
      },
      imageData: {},
      errorMessage: null
    }
  },

  mounted() {
    console.log("List item widget mounted");
    this.id = this._uid;
    this.listItem.guid = this.guid;
    this.fetchImage(this.mediaLibraryID)
  },
  methods: {
    fetchImage(mediaLibraryID) {
      if(mediaLibraryID) {
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
              method: "GET",
              url: `${process.env.VUE_APP_BASEURL}/media/${mediaLibraryID}`,
              headers: { "Authorization": jwt },
            }).then(response => {
              console.log(response);
              this.imageSrc = response.data.body.location;
            })
            .catch(e => {
              console.error("Error fetching image:", e);
            });
        })
      }
    },
    removeItem() {
      this.$emit("removeItem", this.index);
    },
    updateListItem() {
      console.log(this.listItem, this.index);
      this.$emit("updateListItem", this.listItem, this.index);
      this.listWidgetEdit = false;
    },
    convertImage($event, single) {
      const file = single || $event.target.files[0]
      const reader = new FileReader();

      reader.addEventListener("load", evt => {
        this.previewImage = reader.result;
        console.log("reader", reader);

        const base64DataOnly = reader.result.split(",")[1].toString();

        this.imageData = {
          image: base64DataOnly,
          name: "foo",
          description: "ddd",
          tags: [],
          fileSize: file.size,
        }

        this.uploadImage();
      });
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    uploadImage() {
      console.log("Upload image: ", this.imageData);
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/media/upload`,
          headers:{ "Authorization": jwt },
          data: this.imageData
        })
        .then(response => {
          console.log("Saved image: ", response);
          this.imageSrc = response.data.body.location;
          this.listItem.mediaLibraryID = response.data.body.mediaLibraryID;
        })
        .catch(e => {
          console.error("Problem saving image: ", e);
        });
      })
    },
    validate() {
      this.errorMessage = "";
      if (this.$refs.dialogListItemWidgetForm.validate()) {
        console.log("Validation passed");
        this.updateListItem();
      }
      else {
        this.errorMessage = "Certain entries are invalid or required"
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.draggable-item {
    transition: all 0.5s, opacity 0.2s;
}
</style>
