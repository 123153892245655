export default {
  aggregatedFeed: {
    showAllNews: 1
  },
  allowUpsellInApp: false,
  pageTypes: [
    {value: "internal", text: "Website Page"},
    {value: "external", text: "External Page"},
    {value: "internalLink", text: "Internal Page Link"},
    // {value: 'homepage', text: 'Homepage'},
  ],
  platformSettings: {
    showWeb: true,
    showApp: true
  },
  defaultNewsCardStyle: "Stack",
  playerManagement: "V1",
  customDataFields: {
    customDataFieldOneName: "",
    customDataFieldOneDescription: "",
    customDataFieldTwoName: "",
    customDataFieldTwoDescription: "",
    customDataFieldThreeName: "",
    customDataFieldThreeDescription: "",
  },
  customTags: []
}