<template>
  <v-dialog
    class="subscriptions-cta-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
        Subscriptions CTA
        </v-card-title>

        <v-card-text>

        <v-form enctype="multipart/form-data">

          <section>

            <v-btn
              color="accent"
              outline
              flat
              @click="dialog = false"
            >
              Cancel
            </v-btn>

            <v-btn
              color="accent"
              depressed
              @click="updateData()"
            >
              Add CTAs
            </v-btn>
          </section>

        </v-form>

        </v-card-text>
      </v-card>

  </v-dialog>
</template>

<script>

export default {
  name: "SubscriptionsCTAWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    dialog: false
  }),

  mounted() {
    console.log("Subscriptions CTA widget mounted");
  },

  methods: {
    updateData() {
      console.log("Subscriptions CTA data:");
      this.$emit("updatedData");
      this.dialog = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit {
    margin: 0 10px 20px 0;
    width: 100%;
  }
</style>
