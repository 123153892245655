<template>

  <div v-if="!editable" class="accordion-row">
    
    <div class="accordion-col accordion-col__1">
      <p><strong>Title: </strong>{{ title }}</p>
      <p><strong>Body: </strong>{{ body }}</p>
    </div>
    
    <div class="accordion-col accordion-col__2">
      <v-btn
        color="accent"
        outline
        flat
        @click="deleteItem()"
      >
        Delete Item
      </v-btn>  
      
      <v-btn
        light
        outline
        flat
        @click="editRow(index)"
      >
        Edit Item
      </v-btn>
    </div>
  </div>

  <div v-else>
    <widget-accordion-form :title="title" :body="body" :edit="true" @saveRow="saveRow($event)" @cancelEdit="cancelEdit()" :formHeading="`Edit Item`"/>
  </div>

</template>

<script>
import WidgetAccordionForm from "@/components/Library/WidgetAccordionForm"

export default {
  name: "WidgetAccordionItem",

  components: {
    WidgetAccordionForm
  },

  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    editable: false
  }),

  methods: {
    deleteItem() {
      this.$emit("deleteItem");
    },

    editRow(rowIndex) {
      this.editable = true
    },

    saveRow(payload) {
      this.$emit("saveRow", payload);
      this.editable = false
    },

    cancelEdit() {
      this.editable = false
    }
  }
}
</script>

<style scoped lang='scss'>

.accordion-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #eee6f1;
  border-radius: 4px;
  padding: 8px;
  margin: 8px;
  transition: all 0.5s, opacity 0.2s;

  &:hover {
    cursor: pointer;
  }
}

.accordion-col {
  display: flex;
  flex-direction: column;
  
  &__1 {
    width: 70%;
  }
  &__2 {
    width: 30%;
  }
}

</style>
