<template>
  <section class="options">
    <landing-page-card v-for="(option, index) in pageOptions" :key="index"
      v-show="!option.streamlineOnly || moduleConfig.default.Streamline"
      class="options-card" 
      :heading="option.heading"
      :description="option.description"
      :buttonText="option.buttonText"
      :buttonLink="option.buttonLink"
      :image="option.image"
      :label="option.label" />
  </section>
</template>

<script>
import LandingPageCard from "@/components/Library/LandingPageCard"

export default {
  name: "VideosManagement",

  components: {
    LandingPageCard
  },

  data: () => ({
    moduleConfig: null,
    superUser: true,
    pageOptions: [
      {
        heading: "Manage Content",
        description: "This section allows you to manage your key video page content.",
        buttonText: "Manage Content",
        image: "assets/landing-page-options/videos_content.png",
        buttonLink: "/video-management/content",
        streamlineOnly: false
      },
      {
        heading: "Manage Library",
        description: "This will take you to the asset management and control for StreamLine.",
        buttonText: "Manage Library",
        image: "assets/landing-page-options/videos_library.png",
        buttonLink: "/streamline",
        streamlineOnly: true
      },
      {
        heading: "Manage Global Settings",
        description: "This section holds key integration and brand information for videos within Gamechanger.",
        buttonText: "Manage Config",
        image: "assets/landing-page-options/videos_global-settings.png",
        buttonLink: "/video-management/config",
        streamlineOnly: false
      },
      {
        heading: "Manage Memberships",
        description: "This section holds your available memberships data for videos within Gamechanger.",
        buttonText: "Manage Memberships",
        image: "assets/landing-page-options/videos_memberships.png",
        buttonLink: "/video-management/memberships",
        streamlineOnly: false
      }
    ]
  }),

  created() {
    this.moduleConfig = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
  },

  mounted() {
    if (this.$store.state.user.email === "chrisreynolds@urbanzoo.io" || this.$store.state.user.email === "adamhughes@urbanzoo.io") {
      this.superUser = true
    }
  }
}
</script>

<style scoped lang='scss'>
.options {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 24px 0;
}

.accent {
  width: 200px;
}
</style>
