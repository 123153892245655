<template>
  <div class="promo__editor">
    <v-layout class="main-body column">
      <v-flex xs12>
        <MiniHeader
          :heading="'Edit Promo'"
          :buttonText="'Add Promo'"
          :hasButton="true"
          @buttonClicked="newPromo()"
        />
      </v-flex>
      <v-flex xs12>
        <PromoEditorRow
          v-for="(promo, index) in promoCatData.promos" :key="index"
          :promoData="promo"
          @deletePromoItem="deletePromoItem"
          @savePromoItem="savePromoItem"
        ></PromoEditorRow>
      </v-flex>
    </v-layout>

    <template>
      <v-dialog
        class="newPromoData"
        v-model="newPromoDataDialog"
        width="580"
        lazy
      >
        <v-form v-if="newPromoDataForm" enctype="multipart/form-data" ref="newPromoDataForm" v-model="newPromoDataFormValid" lazy-validation>
          <v-card>
            <v-card-title
              class="widget__heading"
            >
              Add new promo
            </v-card-title>

            <v-card-text>
              <label class="label" for="name">Name</label>
              <v-text-field
                id="Name"
                v-model="newPromoData.name"
                placeholder="Name"
                solo
                flat
                required
                :rules="[rules.required]"
                validate-on-blur
                label="Name"
              ></v-text-field>

              <label class="label" for="title">Title</label>
              <v-text-field
                id="widget-field"
                v-model="newPromoData.title"
                placeholder="Title"
                solo
                flat
                validate-on-blur
                label="Title"
              ></v-text-field>

              <label class="label" for="content">Content</label>
              <v-text-field
                id="content"
                v-model="newPromoData.content"
                placeholder="Content"
                solo
                flat
                validate-on-blur
                label="Content"
              ></v-text-field>

              <label class="label" for="link">Link</label>
              <v-text-field
                id="link"
                v-model="newPromoData.link"
                placeholder="Link"
                solo
                flat
                :rules="[rules.required]"
                validate-on-blur
                label="Link"
              ></v-text-field>

              <label class="label" for="link">Link or button text</label>
              <v-text-field
                id="link"
                v-model="newPromoData.cta"
                placeholder="Link"
                solo
                flat
                required
                :rules="[rules.required]"
                validate-on-blur
                label="Call to action"
              ></v-text-field>

              <div class="uploader">
                <h4 class="uploader__heading">Add Image</h4>
                <img class="single-image__preview single-image__preview--center" :src="previewImage" />
                <div class="uploader__ctas">
                  <v-btn
                    color="accent"
                    type="button"
                    depressed>
                    <label for="file-upload" class="uploader__upload-button"><v-icon small>add</v-icon> Choose image</label>
                  </v-btn>
                  <input class="uploader__upload-input" id="file-upload" ref="fileUpload" type="file" @change="convertImage($event)" accept="image/*"/>
                </div>
              </div>
              <div v-if="errorMessage" class="error--text">
                {{errorMessage}}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                outline
                flat
                @click="newPromoDataDialog=false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="accent"
                depressed
                @click="validateNewPromo();"
              >
                Add
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>

        <v-form v-if="imageUploadForm" enctype="multipart/form-data" ref="imageUploadForm" v-model="imageUploadFormValid" lazy-validation>
          <v-card>
            <v-card-title
              class="widget__heading"
            >
              Image
            </v-card-title>

            <v-card-text>

              <section class="single-image" v-if="previewImage">

                <img class="single-image__preview" :src="previewImage" />

                <section class="edit">
                  <label class="label" for="title">Title</label>
                  <v-text-field
                    id="title"
                    v-model="imageData.name"
                    solo
                    flat
                    required
                    :rules="[rules.required]"
                    validate-on-blur
                  ></v-text-field>

                  <label class="label" for="caption">Caption</label>
                  <v-text-field
                    id="caption"
                    v-model="imageData.description"
                    solo
                    flat
                    :rules="[rules.required]"
                    validate-on-blur
                  ></v-text-field>

                  <label class="label" for="tags">Tags</label>

                  <div class="tag-manager">
                    <v-text-field
                      class="tag-manager__input"
                      id="tags"
                      v-model="tagString"
                      solo
                      flat
                      :hide-details="true"
                      @keyup.enter.native="tagged(tagString)"
                      placeholder="Hit enter to add tags"
                    ></v-text-field>
                    <template v-if="imageData.tags" v-for="(tag, index) in imageData.tags">
                      <v-chip class="tag-manager__tag" color="accent" text-color="white" :v-model="tag.id">{{tag.name}}<span class="tag-manager__close" @click="removeTag(index)">X</span></v-chip>
                    </template>
                  </div>
                  </section>
              </section>

            </v-card-text>

            <v-card-actions v-if="previewImage">
              <v-spacer></v-spacer>
              <v-btn
                color="accent"
                outline
                flat
                @click="validateImage();"
              >
                Add image
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </template>
  </div>
</template>
<script>
import axios from "axios"
import MiniHeader from "@/components/Library/MiniHeader"
import PromoEditorRow from "@/components/Promos/PromoEditorRow"

export default {
  name: "PromoEditor",

  components: {
    MiniHeader,
    PromoEditorRow
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/promocategories`,
    storageData: null,
    promoCatData: {
      promos: []
    },
    newPromoDataDialog: null,
    imageUploadForm: null,
    newPromoDataFormValid: false,
    imageUploadFormValid: false,
    editPromo: false,
    status: "saved",
    errorMessage: "",
    rules: {
      required: value => !!value || "Required."
    },
    newPromoData: {
      id: null,
      name: null,
      title: null,
      content: null,
      link: null,
      cta: null,
      mediaLibraryID: null
    },
    newPromoDataForm: true,
    previewImage: null,
    tagString: "",
    imageData: {
      image: "",
      name: "",
      description: "",
      tags: [],
      width: null
    },
  }),

  mounted() {
    console.log("Promo Editor mounted");
    this.getPromos();
  },

  methods: {
    getPromos() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/${this.$route.params.id}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log(response);
          const data = response.data.body;
          this.promoCatData = data[0];
        })
        .catch(e => {
          console.error("Error loading promos:", e);
        });
        this.$store.commit("completeLoading");
      })
    },
    deletePromoItem(id) {
      console.log("Delete...", id);
      this.$store.commit("startLoading");
      const promos = this.promoCatData.promos.filter(item => {
        if(item.id != id) {
          return item;
        }
      });
      this.promoCatData.promos = promos;
      this.$store.commit("completeLoading");
      this.savePromoItem();
    },

    savePromoItem() {
      console.log("Saving promo");
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: this.api,
          headers: { "Authorization": jwt },
          data: this.promoCatData
        })
        .then(response => {
          console.log("Saved promo category: ", response);
        })
        .catch(e => {
          console.error("Problem promo category: ", e);
        });
        this.$store.commit("completeLoading");
      })
    },

    addPromo() {
      console.log("Adding promo...");
      this.$store.commit("startLoading");
      this.newPromoData.id = this.$uuid.v1();
      this.promoCatData.promos.push(this.newPromoData);
      this.newPromoDataDialog = false;
      this.$store.commit("completeLoading");
      this.savePromoItem();
    },

    validateNewPromo() {
      this.errorMessage = ""
      if (this.$refs.newPromoDataForm.validate() && this.newPromoData.mediaLibraryID) {
        console.log("Validation passed");
        this.imageUploadForm = false;
        this.addPromo();
      } else {
        this.errorMessage = "Certain entries are invalid and an image is required"
      }
    },

    nameUpdate() {
      this.status = "needsSaving";
    },

    convertImage($event) {
      const file = $event.target.files[0]
      const reader = new FileReader();

      reader.addEventListener("load", evt => {
        this.previewImage = reader.result;

        console.log("reader", reader);

        const base64DataOnly = reader.result.split(",")[1].toString();

        this.imageData = {
          image: base64DataOnly,
          name: "",
          description: "",
          tags: []
        }
      });
      this.newPromoDataForm = false;
      this.imageUploadForm = true;
      if (file) {
        reader.readAsDataURL(file);
      }
    },

    uploadImage() {
      console.log("Upload image: ", this.imageData);
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/media/upload`,
          headers: { "Authorization": jwt },
          data: this.imageData
        })
        .then(response => {
          console.log("Saved image: ", response);
          this.newPromoDataForm = true;
          this.imageUploadForm = false;
          this.newPromoData.mediaLibraryID = response.data.body.mediaLibraryID;
          this.$emit("imageUploaded", this.newPromoData.mediaLibraryID);
        })
        .catch(e => {
          console.error("Problem saving image: ", e);
        });
      })
    },
    tagged(item){
      console.log("Adding tag:", item);
      const tag = {
        id: item.replace(/\s/g,""),
        name: item
      }
      this.imageData.tags.push(tag);
      this.tagString = "";
      console.log(this.imageData.tags);
    },
    removeTag(index) {
      this.imageData.tags.splice(index, 1);
    },
    validateImage() {
      this.errorMessage = ""
      if (this.$refs.imageUploadForm.validate()) {
        console.log("Validation passed");
        this.uploadImage();
      }
    },
    newPromo() {
      this.newPromoDataDialog = true;
      this.newPromoData = {
        id: null,
        name: null,
        title: null,
        content: null,
        link: null,
        mediaLibraryID: null
      },
      this.previewImage = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.single-image {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 30px;
  border-top: 1px solid #ebebeb;

  &__preview {
    margin: 10px 40px 0 30px;
    max-height: 80px;
    max-width: 80px;
    &--center {
      margin-left: auto;
      margin-right: auto;
      display: block;
      margin-bottom: 15px;
    }
  }
}

.preview-list {
  border-bottom: 1px solid #DFE3E9;
}

.preview {
  display: block;
  margin: 0;
  padding: 15px 0 10px;
  border-top: 1px solid #DFE3E9;

  /deep/ .v-list__tile {
    padding: 0;
  }

  &__label {
    padding: 0;
    margin: 0 0 10px;
    font-size: 10px;
  }

  &__image {
    max-height: 56px;
    max-width: 56px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  &__action {
    display: block;
    margin: 0 0 0 30px;
    cursor: pointer;
  }
}
  .uploader {
    margin: 0 0 40px 0;

    &__heading {
      margin: 0 0 20px;
      color: #24002F;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 500;
    }

    &__upload-input {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      white-space: nowrap;
      width: 1px;
    }

    &__upload-button {
      cursor: pointer;
    }

    &__ctas {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
</style>
