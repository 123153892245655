var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget-details"},[(!_vm.updatedData.widgetType)?[_c('p',{staticClass:"widget-info"},[_vm._v("This widget gives you a rich text editor to create blocks of content in your feed.")]),(!_vm.updatedData.widgetType)?_c('v-btn',{staticClass:"accent",on:{"click":function($event){return _vm.initialise()}}},[_vm._v("Use this widget")]):_vm._e()]:_c('div',{staticClass:"widget-fieldset"},[_c('label',{staticClass:"label"},[_vm._v("Title")]),_c('v-text-field',{attrs:{"id":"widgetData.title","solo":"","flat":""},model:{value:(_vm.updatedData.widgetData.title),callback:function ($$v) {_vm.$set(_vm.updatedData.widgetData, "title", $$v)},expression:"updatedData.widgetData.title"}}),(_vm.$route.query.isApp !== 'true')?[_c('label',{staticClass:"label"},[_vm._v("Style")]),_c('v-select',{attrs:{"id":"widgetData.style","items":_vm.styleOptions,"solo":"","flat":""},model:{value:(_vm.updatedData.widgetData.style),callback:function ($$v) {_vm.$set(_vm.updatedData.widgetData, "style", $$v)},expression:"updatedData.widgetData.style"}})]:_vm._e(),_c('tinymce-editor',{attrs:{"api-key":"rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca","init":{
      height: '300px',
      menubar: false,
      relative_urls: false,
      plugins: 'table link paste wordcount hr lists textpattern code',
      toolbar: _vm.$route.query.isApp !== 'true' ?
        'undo redo | styleselect | bold italic forecolor | \
        link blockquote table | \
        alignleft aligncenter alignright | \
        hr | \
        bullist numlist | removeformat | \ code' : '',
      resize: false,
      custom_colors: false,
      color_map: [
        '212121', 'Black',
        'aaaaaa', 'Gray',
        'FFFFFF', 'White'
      ],
      style_formats: [
        {title: 'Headers', items: [
          //{title: 'Header 1', format: 'h1'},
          {title: 'Header 2', format: 'h2'},
          {title: 'Header 3', format: 'h3'},
          {title: 'Header 4', format: 'h4'},
          {title: 'Header 5', format: 'h5'},
          {title: 'Header 6', format: 'h6'}
        ]},
        {title: 'Inline', items: [
          {title: 'Bold', icon: 'bold', format: 'bold'},
          {title: 'Italic', icon: 'italic', format: 'italic'},
          {title: 'Underline', icon: 'underline', format: 'underline'},
          //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
          //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
          //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
          //{title: 'Code', icon: 'code', format: 'code'}
        ]},
        {title: 'Blocks', items: [
          {title: 'Paragraph', format: 'p'},
          {title: 'Blockquote', format: 'blockquote'},
          //{title: 'Div', format: 'div'},
          //{title: 'Pre', format: 'pre'}
        ]},
        {title: 'Alignment', items: [
          {title: 'Left', icon: 'alignleft', format: 'alignleft'},
          {title: 'Center', icon: 'aligncenter', format: 'aligncenter'},
          {title: 'Right', icon: 'alignright', format: 'alignright'},
          //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
        ]},
        {title: 'Small', selector: 'p', classes: 'small'}
      ],
      style_formats_merge: false,
      textpattern_patterns: [
        {start: '//button', replacement: '<a class=\'button \'>Button here</a>'}
      ],
      link_class_list: [
        {title: 'None', value: ''},
        {title: 'Primary on Dark', value: 'button button--primary-dark'},
        {title: 'Primary on Light', value: 'button button--primary-light'},
        {title: 'Ghost on Dark', value: 'button button--ghost-dark'},
        {title: 'Ghost on Light', value: 'button button--ghost-light'},
        {title: 'Label on Dark', value: 'button button--label-dark'},
        {title: 'Label on Light', value: 'button button--label-light'},
      ],
      block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote'
    }},model:{value:(_vm.updatedData.widgetData.wysiwyg),callback:function ($$v) {_vm.$set(_vm.updatedData.widgetData, "wysiwyg", $$v)},expression:"updatedData.widgetData.wysiwyg"}})],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }