<template>
  <v-dialog
    class="horizontal-line-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
        Horizontal Line
        </v-card-title>

        <v-card-text>

        <v-form enctype="multipart/form-data" v-model="valid" ref="lineForm" lazy-validation>

          <section>

            <label class="label" for="style">Style</label>
            <v-select
              id="style"
              v-model="lineData.style"
              :items="lineOptions"
              :rules="[rules.required]"
              outline
            ></v-select>

            <v-btn
              color="accent"
              outline
              flat
              @click="dialog = false"
            >
              Cancel
            </v-btn>

            <v-btn
              color="accent"
              depressed
              @click="validate()"
            >
              Add Line
            </v-btn>
          </section>

        </v-form>

        </v-card-text>
      </v-card>

  </v-dialog>
</template>

<script>

export default {
  name: "HorizontalLineWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    valid: false,
    dialog: false,
    lineData: {
      style: "colour"
    },
    lineOptions: ["Basic", "Colour", "Greyscale"],
    rules: {
      required: value => !!value || "Required."
    },
  }),

  mounted() {
    console.log("Horizontal line widget mounted");

    if (this.data) {
      this.lineData = JSON.parse(JSON.stringify(this.data));
    }

  },

  methods: {
    validate() {
      this.errorMessage = ""
      if (this.$refs.lineForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },

    updateData() {
      console.log("Update horizontal line data:");
      this.$emit("updatedData", this.lineData);
      this.dialog = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit {
    margin: 0 10px 20px 0;
    width: 100%;
  }
</style>
