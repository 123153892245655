<template>
  <div class="gallery-options gallery-options__desktop" v-if="location === 'desktop'">
    <template v-if="option === 2">
      <svg width="auto" height="auto" viewBox="0 0 77 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Desktop 2</title>
        <g id="Player-Profile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Gallery-Variations" transform="translate(-80.000000, -2008.000000)" fill="currentColor">
            <path d="M128,2008 L128,2035 L80,2035 L80,2008 L128,2008 Z M157,2008 L157,2035 L130,2035 L130,2008 L157,2008 Z" id="Desktop-2"></path>
          </g>
        </g>
      </svg>
    </template>
    <template v-if="option === 3">
      <svg width="auto" height="auto" viewBox="0 0 77 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Desktop 3</title>
        <g id="Player-Profile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Gallery-Variations" transform="translate(-80.000000, -1398.000000)" fill="currentColor">
            <path d="M104.332421,1398 L104.332421,1425 L80,1425 L80,1398 L104.332421,1398 Z M130.666211,1398 L130.666211,1425 L106.333789,1425 L106.333789,1398 L130.666211,1398 Z M157,1398 L157,1425 L132.667579,1425 L132.667579,1398 L157,1398 Z" id="Desktop-3"></path>
          </g>
        </g>
      </svg>
    </template>
    <template v-if="option === 4">
      <svg width="auto" height="auto" viewBox="0 0 77 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Desktop 4</title>
        <g id="Player-Profile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Gallery-Variations" transform="translate(-80.000000, -769.000000)" fill="currentColor">
            <path d="M131,769 L131,796 L80,796 L80,769 L131,769 Z M144,784 L144,796 L133,796 L133,784 L144,784 Z M157,784 L157,796 L146,796 L146,784 L157,784 Z M157,769 L157,782 L133,782 L133,769 L157,769 Z" id="Desktop-4"></path>
          </g>
        </g>
      </svg> 
    </template>
    <template v-if="option === 5">
      <svg width="auto" height="auto" viewBox="0 0 77 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Desktop 5</title>
        <g id="Player-Profile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Gallery-Variations" transform="translate(-80.000000, -140.000000)" fill="currentColor">
            <path d="M131,140 L131,167 L80,167 L80,140 L131,140 Z M144,154.5 L144,167 L133,167 L133,154.5 L144,154.5 Z M157,154.5 L157,167 L146,167 L146,154.5 L157,154.5 Z M144,140 L144,152.5 L133,152.5 L133,140 L144,140 Z M157,140 L157,152.5 L146,152.5 L146,140 L157,140 Z" id="Desktop-5"></path>
          </g>
        </g>
      </svg>
    </template>
  </div>

  <div class="gallery-options gallery-options__mobile" v-else-if="location === 'mobile'">
    <template v-if="option === 2">
      <svg width="auto" height="auto" viewBox="0 0 44 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Mobile 2</title>
        <g id="Player-Profile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Gallery-Variations" transform="translate(-1451.000000, -2003.000000)" fill="currentColor">
            <path d="M1495,2025 L1495,2040.88889 L1451,2040.88889 L1451,2025 L1495,2025 Z M1495,2003 L1495,2022.55556 L1451,2022.55556 L1451,2003 L1495,2003 Z" id="Mobile-2"></path>
          </g>
        </g>
      </svg>
    </template>
    <template v-if="option === 3">
      <svg width="auto" height="auto" viewBox="0 0 44 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Mobile 3</title>
        <g id="Player-Profile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Gallery-Variations" transform="translate(-1451.000000, -1374.000000)" fill="currentColor">
            <path d="M1471.77778,1396 L1471.77778,1411.88889 L1451,1411.88889 L1451,1396 L1471.77778,1396 Z M1495,1396 L1495,1411.88889 L1474.22222,1411.88889 L1474.22222,1396 L1495,1396 Z M1495,1374 L1495,1393.55556 L1451,1393.55556 L1451,1374 L1495,1374 Z" id="Mobile-3"></path>
          </g>
        </g>
      </svg>
    </template>
    <template v-if="option === 4">
      <svg width="auto" height="auto" viewBox="0 0 44 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Mobile 4</title>
        <g id="Player-Profile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Gallery-Variations" transform="translate(-1451.000000, -769.000000)" fill="currentColor">
            <path d="M1463.83333,791 L1463.83333,800.777778 L1451,800.777778 L1451,791 L1463.83333,791 Z M1479.11111,791 L1479.11111,800.777778 L1466.27778,800.777778 L1466.27778,791 L1479.11111,791 Z M1494.38889,791 L1494.38889,800.777778 L1481.55556,800.777778 L1481.55556,791 L1494.38889,791 Z M1495,769 L1495,788.555556 L1451,788.555556 L1451,769 L1495,769 Z" id="Mobile-4"></path>
          </g>
        </g>
      </svg>
    </template>
    <template v-if="option === 5">
      <svg width="auto" height="auto" viewBox="0 0 44 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Mobile 5</title>
        <g id="Player-Profile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Gallery-Variations" transform="translate(-1451.000000, -135.000000)" fill="currentColor">
            <path d="M1463.83333,157 L1463.83333,166.777778 L1451,166.777778 L1451,157 L1463.83333,157 Z M1479.11111,157 L1479.11111,166.777778 L1466.27778,166.777778 L1466.27778,157 L1479.11111,157 Z M1494.38889,157 L1494.38889,166.777778 L1481.55556,166.777778 L1481.55556,157 L1494.38889,157 Z M1471.77778,135 L1471.77778,154.555556 L1451,154.555556 L1451,135 L1471.77778,135 Z M1495,135 L1495,154.555556 L1474.22222,154.555556 L1474.22222,135 L1495,135 Z" id="Mobile-5"></path>
          </g>
        </g>
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  name: "GalleryOption",

  props: {
    location: {
      type: String,
      required: true
    },
    option: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped lang='scss'>

  .gallery-options {
    color: $lightgrey;
    border-radius: 4px;
    width: fit-content;
    overflow: hidden;

    &__desktop {
      height: 48px;
    }

    &__mobile {
      height: 72px;
    }
  }

</style>
