import { render, staticRenderFns } from "./FolderRename.vue?vue&type=template&id=01273801&scoped=true"
import script from "./FolderRename.vue?vue&type=script&lang=js"
export * from "./FolderRename.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01273801",
  null
  
)

export default component.exports