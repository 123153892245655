<template>
  <p v-if="!updatedData" class="loading">loading...</p>

  <section class="page-builder" v-else>
    <widget-manager :rowData="emptyRow" class="new-row" @cancel="cancelNewRow(0)" @saveNew="updateRow($event, 0)">
      <span @click="addNewRow(0)"><v-icon>add</v-icon> Add Row</span>
    </widget-manager>
    <template v-for="(row, index) in updatedData">
      <div class="row__card" :key="index">
        <div class="row__card--drag">
          <button v-if="index != 0" class="row__card--drag-up" type="button" @click="moveRow('up', index)">
            <v-icon>keyboard_arrow_up</v-icon>
          </button>
          <button v-if="index != updatedData.length - 1" class="row__card--drag-down" type="button" @click="moveRow('down', index)">
            <v-icon>keyboard_arrow_down</v-icon>
          </button>
        </div>
        <div class="row__card--details">
          <template v-if="row.rowData.widgetType">
            <p class="row__card--name">{{row.rowData.widgetName + (row.rowData.widgetData.title ? ` - ${row.rowData.widgetData.title}` : row.rowData.widgetData.customSubtitle ? ` - ${row.rowData.widgetData.customSubtitle}` : '')}}</p>
            <p class="row__card--style">{{row.rowData.widgetData.style ? row.rowData.widgetData.style : 'Style not defined'}}</p>
          </template>
          <template v-else>
            <p class="row__card--name">New</p>
            <p class="row__card--style">waiting for details... </p>
          </template>
        </div>
        <div class="row__card--actions">
          <widget-manager :rowData="row.rowData" @click="editWidget(index)" @updatedWidget="updateWidgetData($event, index)" @cancelUpdate="cancelUpdateRow($event, index)" @save="updateRow($event, index)">
            <button class="row__card--edit">
              Edit
            </button>
          </widget-manager>
          <button class="row__card--delete" @click="deleteWidget(index)">
            Delete
          </button>
        </div>
      </div>

      <widget-manager :rowData="emptyRow" class="new-row" :key="row.rowID" @cancel="cancelNewRow(index +1)" @cancelUpdate="cancelUpdateRow($event, index +1)" @saveNew="updateRow($event, index+1)">
        <span @click="addNewRow(index +1)"><v-icon>add</v-icon> Add Row</span>
      </widget-manager>

    </template>

  </section>

</template>

<script>
import WidgetManager from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/WidgetManager.vue"

export default {
  components: { 
    WidgetManager
  },

  name: "RowEditor",

  props: {
    contentData: {
      required: true
    }
  },

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.contentData))
  },

  data: () => ({
    updatedData: [],
    emptyRow: {
      widgetName: null,
      widgetData: {},
      widgetID: null,
      widgetType: null 
    }
  }),

  methods: {
    updatedContentData() {
      this.$emit("updatedData", this.updatedData)
    },

    moveRow(direction, index) {
      var tempArray = this.updatedData.splice(index, 1)[0]
      if (direction === "up") {
        this.updatedData.splice(index - 1, 0, tempArray)
      } else {
        this.updatedData.splice(index + 1, 0, tempArray)
      }
      this.updatedContentData()
    },

    deleteWidget(index) {
      console.log("deleteWidget", index)
      if (window.confirm("Are you sure you want to delete this content row?  This is irreversible")) {
        this.updatedData.splice(index, 1)
        this.$emit("updatedData", this.updatedData)
      }
    },

    addNewRow(index) {
      console.log("adding new at ", index)
      var rowData = { 
        rowData: {
          "widgetName": "",
          "widgetData": {},
          "widgetID": "",
          "widgetType\"": ""
        },
        rowID: this.$uuid.v1()
      }
      this.updatedData.splice(index, 0, rowData)
    },

    cancelNewRow(index) {
      // console.log('new row cancelled do nothing presumably TODO - stop this showing you need to save', index)
      this.updatedData.splice(index, 1)
    },

    cancelUpdateRow(what, index) {
      console.log("TODO - this is cancelUpdateRow() I think I just do nothing ", what, index)
    },

    updateRow(newData, index) {
      // console.log('updateRow| I need to save this data', newData, 'to this index', index)
      this.updatedData[index].rowData = JSON.parse(JSON.stringify(newData))
      this.$emit("updatedData", this.updatedData)
    }
  }
}
</script>

<style scoped lang='scss'>

.new-row {
  position: relative;
  display: flex;
  justify-content: center;
  height: 32px;
  width: 100%;
  margin-bottom: 24px;
  padding: 8px;
  background: #EEF0F3;
  color: #516173;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;

  &:not(:first-of-type) {
    margin-top: 12px;
  }

  i {
    font-size: 12px;
  }
  span {
    position: absolute;
    width: 100%;
    text-align: center;
  }
}

.row {
  &__card {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    background: #fff;
    border: 1px solid #DFE3E9;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 16px;

    &--drag {
      position: absolute;
      top: 0px;
      left: -23px;
      bottom: 0;
      width: 23px;
      padding: 3px;

      i {
        font-size: 16px;
      }
    }

    &--drag-up {
      position: absolute;
      top: 8px;

      &:hover {
        background: #EEF0F3;
        border-radius: 4px;
      }
    }

    &--drag-down {
      position: absolute;
      bottom: 8px;

      &:hover {
        background: #EEF0F3;
        border-radius: 4px;
      }
    }

    &--name {
      color: #67247C;
      font-weight: 500;
      margin: 8px 16px;
    }

    &--style {
      color: #67247C;
      font-weight: 300;
      margin: 0px 16px 8px;
      text-transform: capitalize;
    }

    &--actions {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      margin: auto 16px;
    }

    &--delete {
      margin: 0 0 0 16px;
      padding: 6px 8px;
      color: #7b6b80;
      font-size: 14px;
      font-weight: 500;
      background-color: #f1f4f8;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transition: all 0.2s ease-out;

      &:hover {
        color: #fff;
        background-color: $errorRed;
      }
    }

    &--edit {
      margin: 0;
      padding: 6px 8px;
      color: #7b6b80;
      font-size: 14px;
      font-weight: 500;
      background-color: #f1f4f8;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transition: all 0.2s ease-out;

      &:hover {
        color: #fff;
        background-color: #67247c;
      }
    }
  }
}
</style>
