<template>
  <section>
    <v-alert :value="bypassStreamlineSessionChecks" color="error" icon="warning">
      All checks are being bypassed
    </v-alert>

    <h3 class="subheading">Emergency Bypass</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 mt-4 class="half">
          <v-btn
            v-if="bypassStreamlineSessionChecks"
            type="button"
            depressed
            color="success"
            @click="removeBypass()"
          >
            Reinstate Checks
          </v-btn>

          <v-btn
            v-else
            type="button"
            depressed
            color="warning"
            @click="enableBypass()"
          >
            Enable Bypass
          </v-btn>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Warning:</i> Only use this functionality if there is no other option. This will remove all checks thereby making videos free to any user.</p>
        </v-flex>
      </v-layout>
    </section>
  </section>
</template>

<script>
import axios from "axios"

export default {
  name: "EmergencyBypass",

  data:() => ({
    bypassStreamlineSessionChecks: null,
  }),

  mounted() {
    this.getBypass()
  },

  methods: {
    enableBypass() {
      if (confirm("Are you sure you want to enable the bypass? This removes all checks from videos")) {
        this.saveBypass(1)
      }
    },

    removeBypass() {
      if (confirm("Are you sure you want to enable the bypass? This removes all checks from videos")) {
        this.saveBypass(0)
      }
    },

    getBypass() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_STREAMLINEAPI}/bypassStreamlineSessionChecks`,  { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("getBypass response", response)
          if (response.data) {
            this.bypassStreamlineSessionChecks = response.data.data.attributes[0].bypassStreamlineSessionChecks
          }
        })
        .catch(e => {
          console.error("Problem retrieving bypass", e);
        });
      })
    },

    saveBypass(value) {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/bypassStreamlineSessionChecks`,
          headers: { "Authorization": jwt },
          data: {
            data: {
              attributes: {
                bypassStreamlineSessionChecks: value
              },
              type: "bypassStreamlineSessionChecks"
            }
          }
        })
        .then(response => {
          console.log("Saving bypass:", response);

          this.getBypass()

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem saving bypass", e);
          this.$store.commit("completeLoading");
          this.errorMessage = "Problem saving calendar item";
        });
      })
    },

  }
}
</script>

<style scoped lang="scss">
  .half {
    width: 50%;
  }

  .section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #DFE3E9;
  }
</style>
