<template>
  <section class="hub-builder">

    <MiniHeader :heading="title" :hasButton="false"/>

    <section class="region">
      <h2 class="region__heading">Content Builder</h2>

      <div class="add-row-wrap">
        <button class="add-row" @click="addFirstRow()">Add row <span class="add-row__icon"><v-icon color="#fff">add</v-icon></span></button>
      </div>

      <transition-group name="list-complete" tag="ol">
        <li v-for="(row, index) in updatedData" :key="row.rowID" class="row-wrap">
          <div class="row">

            <div class="row__move-buttons">
              <button v-if="index !== 0" class="row__move-up" type="button" @click="moveRowUp(index)">
                <v-icon small>keyboard_arrow_up</v-icon>
              </button>

              <button v-if="(index + 1) !== updatedData.length" class="row__move-down" type="button" @click="moveRowDown(index)">
                <v-icon small>keyboard_arrow_down</v-icon>
              </button>
            </div>

            <article v-if="row.rowData" class="widget">

              <template>

                <div class="grid-row-title">
                  <span v-if="row.repeaterType === 'stacker'" :class="`grid-list-icon grid-list-icon__${row.repeaterType}`"></span>
                  <span v-else-if="row.repeaterType === 'carousel'" :class="`grid-list-icon grid-list-icon__${row.repeaterType}`"></span>
                  <span v-else :class="`grid-list-icon grid-list-icon__${row.gridType}`"></span>
                  <h2 class="grid-row-title__text">{{ row.rowTitle }}</h2>
                </div>

              <grid-selector 
                @gridAdded="addGrid($event, index)" 
                v-if="row.rowType === 'Empty'"/>

              <div v-else>
                <v-tabs class="row__tabs" v-if="row.rowType != 'Empty'">

                <v-tab>Row Info</v-tab>
                <v-tab-item>
                  <div class="row__info">
                    <div class="row__info--col row__info--col-1">
                      <h3 class="label">Row Title</h3>
                      <v-text-field
                        id="row-title"
                        v-model="row.rowTitle"
                        :rules="[rules.required]"
                        placeholder="Row Title"
                        outline
                        clearable
                        >
                      </v-text-field>
                      <h3 class="label">Row Description</h3>
                      <v-textarea
                        id="row-description"
                        v-model="row.rowDescription"
                        placeholder="Row Description"
                        rows="3"
                        outline
                        clearable
                        @blur="checkForEmpty('rowDescription', index)">
                      </v-textarea>

                      <label class="label">Custom Tag</label>
                      <v-select
                        id="row-custom-category"
                        v-bind:items="availableTags"
                        item-text="name"
                        item-value="value"
                        v-model="row.customTag"
                        solo
                        flat>
                      </v-select>

                    </div>
                    <!-- end of col 1 -->
                    <div class="row__info--col row__info--col-2">
                      <div class="row__info--col-row">
                        <!-- :TODO Add in select options when carousel is available -->
                        <!-- <div class="row__info--radios" v-if="row.gridType === 'repeater'">
                        <h3 class="label">Repeater Type</h3>
                        <v-radio-group
                            v-model="row.repeaterType"
                            dense
                            row>
                            <v-radio v-for="(type, index) in repeaterType" :key="index"
                              :label="`${type.name}`" 
                              :value="`${type.name}`">
                            </v-radio>
                          </v-radio-group>
                        </div> -->

                        <div class="row__info--radios">
                          <h3 class="label">Display Title? </h3>
                          <v-checkbox
                            v-model="row.displayRowTitle"
                          ></v-checkbox>
                        </div>
                        <div class="row__info--radios">
                          <h3 class="label">Add to quick menu? </h3>
                          <v-checkbox
                            v-model="row.addToQuickMenu"
                          ></v-checkbox>
                        </div>
                        <div v-if="row.rowType != 'WidgetRow'" class="row__info--radios">
                        <h3 class="label">Background Style </h3>
                        <v-radio-group
                            v-model="row.rowStyle"
                            dense
                            row>
                            <v-radio v-for="(rowStyleOption, index) in rowStyleOptions" :key="index"
                              :label="rowStyleOption.name"
                              :value="rowStyleOption.name">
                            </v-radio>

                          </v-radio-group>
                        </div>
                      </div>

                      <ImageUploader 
                        v-if="row.rowType != 'WidgetRow'"
                        :title="'Background Image'" 
                        :widthOptionEnabled="false" 
                        :mediaLibraryID="row.rowBackgroundImageID" 
                        @update="updateImage($event, index)" 
                      />

                    </div>
                  <!-- end of col 2 -->
                  </div>
                </v-tab-item>

                <v-tab>Row Content</v-tab>
                <v-tab-item>
                
                <div v-if="row.rowType === 'WidgetRow'">
                  <WidgetSelector
                    v-if="row.rowData[0].widgetType === 'Empty'"
                    class="add-widget"
                    @widgetAdded="addWidget($event, index)"
                  >
                    <v-icon color="#fff" class="add-widget__icon">add</v-icon> <span class="add-widget__text">Add Content</span>
                  </WidgetSelector>

                  <WidgetPreview
                    v-else
                    :widgetName="row.rowData[0].widgetName"
                    :widgetType="row.rowData[0].widgetType"
                    :widgetData="row.rowData[0].widgetData"
                    @edit="editWidget($event, index)"
                    @delete="deleteWidget(index)"
                  >
                  </WidgetPreview>
                </div>

                <div v-else-if="row.rowType === 'ChildRow'">
                  <p>Child pages</p>
                  <ul>
                    <li v-for="(childPage, index) in childPageData" :key="index">
                      <a :href="`${frontendUrl}/${childPage.fullPath}`" target="_blank">{{ childPage.postTitle }}</a>
                    </li>
                  </ul>
                </div>

                <div v-else-if="row.rowType === 'GridRow'">

                    <draggable
                      class="grid-display"
                      tag="section"
                      v-model="row.rowData"
                      :options="{
                        emptyInsertThreshold: 10,
                      }"
                      @start="drag=true" @end="drag=false"
                    >
                        
                        <div v-for="(item, gridIndex) in row.rowData" :key="item.gridItemID"
                        class="grid-display__item"
                        :class="[`grid-display__item-${item.gridItemStyle}`, item.gridItemType === 'Empty' ? 'grid-display__item--empty' : '']">

                          <template v-if="item.gridItemType === 'Empty'">

                            <TextBlockWidget class="widget-list__item" @updatedData="addGridTextBlock($event, index, gridIndex)">
                              <v-btn
                                color="accent"
                                dark
                                type="button"
                                depressed>Add Text</v-btn>
                            </TextBlockWidget>

                            <GridImageWidget
                              class="widget-list__item"
                              @updatedData="addGridImageBlock($event, index, gridIndex)"
                            >
                              <v-btn
                                color="dark"
                                dark
                                type="button"
                                depressed>Add Image</v-btn>
                            </GridImageWidget>

                          </template>

                          <template v-else>
                            <GridPreview
                              v-if="row.rowType === 'GridRow'"
                              
                              :widgetType="item.gridItemType"
                              :widgetData="item.gridItemData"
                              :widgetName="item.gridItemType"
                              @edit="editGridWidget($event, index, gridIndex)"
                              @delete="deleteGridWidget(index, gridIndex)"
                            ></GridPreview>
                          </template>

                        </div>

                      </draggable>
                    
                      <button type="button" v-if="row.gridType === 'repeater'" @click="addRepeater(index)" class="repeater-button">Add Column</button>

                    </div>

                  </v-tab-item>
                </v-tabs>
              </div>
            
            </template>

            </article>

            <button class="row__delete" type="button" @click="deleteRow(index)">
              <v-icon small>delete</v-icon>
            </button>

          </div>

          <div class="add-row-wrap">
            <button @click="addRow(index)" class="add-row">Add row <span class="add-row__icon"><v-icon color="#fff">add</v-icon></span></button>
          </div>
        </li>

      </transition-group>
    </section>

  </section>
</template>

<script>
import axios from "axios"
import MiniHeader from "@/components/Library/MiniHeader"
import WidgetPreview from "@/components/Library/WidgetPreview"
import WidgetSelector from "@/components/Library/WidgetSelector"
import GridSelector from "@/components/Pages/GridSelector"
import ImageUploader from "@/components/Library/ImageUploader"
import TextBlockWidget from "@/components/Widgets/TextBlockWidget"
import GridImageWidget from "@/components/Widgets/GridImageWidget"
import ImageWidget from "@/components/Widgets/ImageWidget"
import GridPreview from "@/components/Pages/GridPreview"
import draggable from "vuedraggable"

export default {
  name: "HubBuilder",

  components: {
    MiniHeader,
    WidgetPreview,
    WidgetSelector,
    GridSelector,
    ImageUploader,
    TextBlockWidget,
    GridImageWidget,
    GridPreview,
    ImageWidget,
    draggable
  },

  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    saveData: {
      type: Object,
      required: true
    },
    template: {
      type: String,
      required: false
    },
    childIds: {
      type: Array,
      required: false
    }
  },

  data: function () {
    return {
      api: `${process.env.VUE_APP_BASEURL}/pages`,
      frontendUrl: `https://${process.env.VUE_APP_SITE_DOMAIN}`,
      childPageData: [],
      updatedData: [],
      rowStyleOptions: [
        {name: "Light"},
        {name: "Dark"}
        ],
      rules: {
        required: value => !!value || "Required",
      },
      repeaterType: [
        {name: "stacker"}, 
        {name: "carousel"}
      ],
      modules: null,
      customTags: null,
      availableTags: null
      
    }
  },

  watch: {
    updatedData() {
      console.log("--- Data updated ---");
      this.$emit("update", this.updatedData)
    }
  },

  created() {
    this.modules = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
    this.defaultSettings = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/defaultSettings.js")
  },

  mounted() {
    console.log("Page Builder mounted with: ", this.data);
    this.updatedData = this.data;

    this.fetchChildPages(this.childIds)

    if (this.modules.default.CustomTags) {
      this.availableTags = this.defaultSettings.default.customTags
    } else {
      this.availableTags = null
    }
  },

  methods: {
    checkForEmpty(key, index) {
      if (this.updatedData[index][key] === "") {
        delete this.updatedData[index][key]
      }
    },

    addFirstRow() {
      const location = this.updatedData;
      const emptyRow = {
        rowID: this.$uuid.v1(),
        rowType: "Empty", 
        rowTitle: "Title",
        displayRowTitle: true,
        rowDescription: false,
        rowStyle: "Light",
        rowBackgroundImageID: false,
        addToQuickMenu: false,
        rowData: [
          {
            widgetID: this.$uuid.v1(),
            widgetType: "Empty"
          }
        ],
        customTag: null
      }
      location.unshift(emptyRow);
      this.$emit("statusChange");
      console.log("addFirstRow", this.updatedData);
    },

    addRow(index) {
      const nextIndex = index + 1;
      const location = this.updatedData;
      const emptyRow = {
        rowID: this.$uuid.v1(),
        rowType: "Empty", 
        rowTitle: "Title",
        displayRowTitle: true,
        rowDescription: false,
        rowStyle: "Light",
        rowBackgroundImageID: false,
        addToQuickMenu: false,
        rowData: [
          {
            widgetID: this.$uuid.v1(),
            widgetType: "Empty"
          }
        ],
        customTag: null
      }
      location.splice(nextIndex, 0, emptyRow);
      this.$emit("statusChange");
      console.log("addRow", this.updatedData);
    },

    deleteRow(index) {
      console.log("Delete row: ", index);

      if (confirm("Are you sure you want to delete this row?")) {
        const location = this.updatedData;

        location.splice(index, 1);
      }

      this.$emit("statusChange");
    },

    moveRowDown(index) {
      console.log("Move row down: ", index);
      const location = this.updatedData;
      const element = this.updatedData[index];

      location.splice(index, 1);
      location.splice((index + 1), 0, element);
    },

    moveRowUp(index) {
      console.log("Move row up: ", index);

      const location = this.updatedData;
      const element = this.updatedData[index];

      location.splice(index, 1);
      location.splice((index - 1), 0, element);
    },

    addGrid(payload, rowIndex) {
      console.log("Add Grid", this.updatedData[rowIndex], payload)

      const row = this.updatedData[rowIndex];
      row.rowType = payload.rowType
      row.gridType = payload.gridType;

      if(row.gridType === "100") {
        row.rowData = [
          {
            gridItemID: this.$uuid.v1(),
            gridItemStyle: "100",
            widgetType: "Empty",
            widgetData: null
          },
        ]
      } else if (row.gridType === "50-50") {
        row.rowData = [
          {
            gridItemID: this.$uuid.v1(),
            gridItemStyle: "50",
            gridItemType: "Empty",
            gridItemData: null
          },
          {
            gridItemID: this.$uuid.v1(),
            gridItemStyle: "50",
            gridItemType: "Empty",
            gridItemData: null
          }
        ]
      } else if (row.gridType === "66-33") {
        row.rowData = [
          {
            gridItemID: this.$uuid.v1(),
            gridItemStyle: "66",
            gridItemType: "Empty",
            gridItemData: null
          },
          {
            gridItemID: this.$uuid.v1(),
            gridItemStyle: "33",
            gridItemType: "Empty",
            gridItemData: null
          }
        ]
      } else if (row.gridType === "33-33-33") {
        row.rowData = [
          {
            gridItemID: this.$uuid.v1(),
            gridItemStyle: "33",
            gridItemType: "Empty",
            gridItemData: null
          },
          {
            gridItemID: this.$uuid.v1(),
            gridItemStyle: "33",
            gridItemType: "Empty",
            gridItemData: null
          },
          {
            gridItemID: this.$uuid.v1(),
            gridItemStyle: "33",
            gridItemType: "Empty",
            gridItemData: null
          }
        ]
      } else if (row.gridType === "50-25-25") {
        row.rowData = [
          {
            gridItemID: this.$uuid.v1(),
            gridItemStyle: "50",
            gridItemType: "Empty",
            gridItemData: null
          },
          {
            gridItemID: this.$uuid.v1(),
            gridItemStyle: "25",
            gridItemType: "Empty",
            gridItemData: null
          },
          {
            gridItemID: this.$uuid.v1(),
            gridItemStyle: "25",
            gridItemType: "Empty",
            gridItemData: null
          }
        ]
      } else if (row.gridType === "repeater") {
        row.rowData = [
          {
            gridItemID: this.$uuid.v1(),
            gridItemStyle: "25",
            gridItemType: "Empty",
            gridItemData: null
          }
        ]
        //:TODO Remove When Carousel is available
        row.repeaterType = "stacker"
      }

      this.$emit("statusChange");
    },

    addRepeater(rowIndex) {
      console.log("addRepeater")

      const row = this.updatedData[rowIndex].rowData;

      row.push(
        {
          gridItemID: this.$uuid.v1(),
          gridItemStyle: "25",
          gridItemType: "Empty",
          gridItemData: null
        }
      )
    },

    addWidget(payload, rowIndex) {
      console.log("Add Widget here", this.updatedData[rowIndex])
      console.log("Add Widget payload", payload)
      const row = this.updatedData[rowIndex].rowData[0];

      row.widgetName = payload.widgetName;
      row.widgetType = payload.widgetType;
      row.widgetData = payload.widgetData;
      this.$emit("statusChange");
    },

    editWidget(payload, rowIndex) {
      console.log("Edit widget:", payload);
      const location = this.updatedData[rowIndex].rowData[0];
      location.widgetData = payload
      this.$emit("statusChange");
    },

    editGridWidget(payload, rowIndex, gridItemIndex) {
      console.log("Edit widget:", payload);
      const location = this.updatedData[rowIndex].rowData[gridItemIndex]

      location.gridItemData = payload;
      this.$emit("statusChange");
    },

    deleteGridWidget(rowIndex, gridItemIndex) {
      console.log("clicking delete grid item widget");

      if (confirm("Are you sure you want to delete this widget?")) {
        const location = this.updatedData[rowIndex].rowData[gridItemIndex]

        location.gridItemType = "Empty";
        location.gridItemData = {};
        this.$emit("statusChange");
      }
    },

    deleteWidget(rowIndex) {
      console.log("Delete widget");

      if (confirm("Are you sure you want to delete this widget?")) {
        const location = this.updatedData[rowIndex].rowData[0];

        location.widgetName = "Widget Removed";
        location.widgetType = "Empty";
        location.widgetData = {};
        this.$emit("statusChange");
      }
    },

    addGridTextBlock(payload, rowIndex, gridItemIndex) {
      console.log("addGridTextBlock", payload)

      const location = this.updatedData[rowIndex].rowData[gridItemIndex]

      location.gridItemType = "TextBlockWidget";
      location.gridItemData = payload;
    },

    addGridImageBlock(payload, rowIndex, gridItemIndex) {
      console.log("addGridImage payload", payload)

      const location = this.updatedData[rowIndex].rowData[gridItemIndex]
      console.log("addGridImage location", location)

      location.gridItemType = "GridImageWidget";
      location.gridItemData = payload;
    },

    //For the background Image on each hub row
    addImage(payload, widgetName, widgetType) {
      console.log("addImage payload: ", payload);
      const data = {
        widgetName: widgetName,
        widgetType: widgetType,
        widgetData: {
          image: payload,
        },
      }
      this.$emit("widgetAdded", data);
      this.dialog = false;
    },

    fetchImage(mediaLibraryID) {
      console.log("Fetch image dfsfds", mediaLibraryID);
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/media/${mediaLibraryID}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Fetch image response:", response);

          this.imageSrc = `${response.data.imageHandlerURL}/${response.data.body.key}`;
        })
        .catch(e => {
          console.error("Problem fetching image", e);
        });
      })
    },

    updateImage(payload, rowIndex) {
      console.log("Update image:", payload);

      const location = this.updatedData[rowIndex]
      console.log("addGridImage location", location)

      location.rowBackgroundImageID = payload.mediaLibraryID
    },

    fetchChildPages(childIds) {
      console.log("fetchChildPages", childIds)

      for (const child in childIds) {
        console.log(`fetchChildPage loop:, ${this.api}/${childIds[child]}`);
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios
          .get(`${this.api}/${childIds[child]}`, { "headers": { "Authorization": jwt }})
          .then(response => {
            console.log("fetchChildPage response: ", response);

            this.childPageData.push({
              postTitle: response.data.body.postTitle,
              fullPath: response.data.body.fullPath,
            })

          })
          .catch(e => {
            console.error("Problem retrieving page: ", e);
          });
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>

  .hub-item-enter, .hub-item-leave-to {
    opacity: 0;
  }

  .hub-item-leave-active {
    position: absolute;
    width: calc(100% - (var(--body-gutter) * 2));
  }

  .draggable-item {
    transition: all 0.5s, opacity 0.2s;
  }

  .header {
    margin: 0 0 40px;
    padding: 40px;
    background-color: #ebebeb;
    border: 1px solid rgba(209,216,245,0.6);
    border-radius: 4px;
  }

  .region {
    padding: 40px;
    background-color: #fff;
    border: 1px solid rgba(209,216,245,0.6);
    border-radius: 4px;

    &__heading {
      margin: 0 0 20px;
      color: #67247C;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .row-wrap {
    transition: all 0.5s, opacity 0.2s;
  }

  .row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 40px;

    &__delete {
      position: absolute;
      top: 50%;
      right: -21px;
      transform: translateY(-50%);
      padding: 3px;
      background-color: #ccc;
      border-radius: 0 4px 4px 0;
      opacity: 0;
      pointer-events: none;
      outline: none;
      transition: opacity 0.3s ease-in-out;
      z-index: 1;
    }

    &__move-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 10px 0 -26px;
      height: 60px;
    }

    &__move-up {
      margin: 0 0 auto;
    }

    &__move-down {
      margin: auto 0 0;
    }

    &__info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      justify-content: space-between;

      &--col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 45%;
      }

      &--col-1 {
        .v-input {
          flex-grow: 0;
          flex-shrink: 0;
        }
      }

      &--col-2 {
        /deep/ label {
          font-weight: 400;
        }
      }

      &--col-row {
        display: flex;
        flex-direction: column;

      }

      &--radios {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #eaeaea;

        h3 {
          margin-right: 16px;
        }
      }

      &--radios:last-child {
        border-bottom: none;
      }

      &--select {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        h3 {
          margin-right: 16px;
        }
      }
    }
  }

  .row:hover .row__delete {
    opacity: 1;
    pointer-events: auto;
  }

  .add-row-wrap {
    width: 100%;
  }

  .add-row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0 0 40px;
    width: 100%;
    color: #959595;

    &:before {
      content: '';
      position: absolute;
      top: 17px;
      right: 120px;
      left: 0;
      border-bottom: 1px dashed #959595;
    }

    &__icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 10px;
      height: 34px;
      width: 34px;
      background-color: #959595;
      border-radius: 2px;
    }
  }

  .add-row:hover {
    color: #67247C;

    .add-row:before {
      border-bottom-color: #B391BD;
    }

    .add-row__icon {
      background-color: #67247C;
    }
  }

  .add-widget {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
    margin: 20px 0 0;
    padding: 30px 0;
    min-height: 240px;
    text-align: center;
    color: #67247C;
    text-align: center;
    background-color: #F9F9F9;
    border: 1px dashed #DFE3E9;
    border-radius: 4px;

    &__text {
      display: block;
      margin: 8px 0 0;
      font-weight: 500;
    }

    &__icon {
      display: block;
      margin: auto;
      height: 51px;
      width: 51px;
      font-size: 40px;
      line-height: 51px;
      background-color: #67247C;
      border-radius: 50%;
    }
  }

  .widget {
    flex-grow: 1;
    flex-basis: 0;
    margin: 0;

    // New
    border: 1px purple solid;
    padding: 8px;

    &:nth-child(3) {
      margin-left: 40px;
    }

    &__edit,
    &__delete {
      margin: 30px 20px;
    }
  }

  .grid-display {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    &__item {
      margin: 0 8px;
      transform: translateZ(0px);
      transition: translate 0.3s ease-out;

      &--empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;
        border: 1px dashed #ccc;
        background-color: #f8f8f8;
      }
    }

    &__item-100 {
      min-width: calc(100% - 16px);
      max-width: calc(100% - 16px);
    }

    &__item-66 {
      min-width: calc(66.66% - 16px);
      max-width: calc(66.66% - 16px);
    }

    &__item-50 {
      min-width: calc(50% - 16px);
      max-width: calc(50% - 16px);
    }

    &__item-33 {
      min-width: calc(33.33% - 16px);
      max-width: calc(33.33% - 16px);
    }

    &__item-25 {
      max-width: calc(25% - 16px);
      min-width: calc(25% - 16px);
      margin-bottom: 50px;
    }

    & > span {
      display: flex;
      flex-direction: row;
    }
  }

  .repeater-button {
    width: 25%;
    padding: 20px;
    border: 1px dotted purple;
  }

  //Tab style Row label
  .grid-row-title {
    position: absolute;
    bottom: 100%;
    left: 0px;
    margin-bottom: -1px;
    padding: 8px;
    border: 1px solid;
    border-bottom-color: #fff;
    display: flex;
    flex-direction: row;

    &__text {
      font-size: 16px;
    }
  }

  // Jarg Styles PLEASE REVIEW

  .widget {
    .v-tabs {
      .v-tabs__bar.theme--light {
        padding-left: none !important;
      }
    } 
  } 

  /deep/ .v-tabs__bar.theme--light {
    padding-left: 0px !important;
  }

  //hmmmmm
  /deep/ .v-tabs__container {
    margin-bottom: 16px;
    border-bottom: 1px solid #ccc;
  }

  .grid-list-icon {
    width: 48px;
    // margin-bottom: 8px; 
    margin-right: 4px;

    &__100 {
      background: transparent url('../../assets/grid-types/100.svg') no-repeat;
      background-size: contain; 
    }

    &__50-50 {
      background: transparent url('../../assets/grid-types/50-50.svg') no-repeat;
      background-size: contain; 
    }

    &__66-33 {
      background: transparent url('../../assets/grid-types/66-33.svg') no-repeat;
      background-size: contain; 
    }

    &__33-33-33 {
      background: transparent url('../../assets/grid-types/33-33-33.svg')no-repeat;
      background-size: contain; 
    }

    &__50-25-25 {
      background: transparent url('../../assets/grid-types/50-25-25.svg') no-repeat;
      background-size: contain; 
    }

    &__carousel {
      background: transparent url('../../assets/grid-types/carousel.svg') no-repeat;
      background-size: contain; 
    }

    &__stacker {
      background: transparent url('../../assets/grid-types/continous.svg') no-repeat;
      background-size: contain; 
    }
    
    &__repeater {
      background: transparent url('../../assets/grid-types/carousel.svg') no-repeat;
      background-size: contain; 
    }
  }

</style>
