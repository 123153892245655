<template>
  <div v-if="session">
    <video
      :id="playerID"
      ref="player"
      class="video-js vjs-16-9"
      controls
      preload="auto"
      width="100%"
      :data-setup="JSON.stringify(setup)"
    >
      <source
        v-if="policy === 'signed'"
        :src="`${playbackID}?token=${session}`"
        type="video/mux"
      />
      <source v-else :src="`${playbackID}`" type="video/mux" />
    </video>

    <!-- <p>Current time: {{ player.currentTime }}</p> -->
    <template v-if="player && duration && clipping">
      <v-range-slider
        :value="range"
        :max="duration"
        min="0"
        step="0.1"
        hide-details
        class="align-center"
        @change="updateRange(range, $event)"
      >
      </v-range-slider>

      <div v-if="!detailsUpdating" class="timings">
        <div class="timings__col timings__col--1">
          <p class="timings__label">Start</p>
          <div class="input-container">
            <div class="input-wrapper">
              <input class="timings__value" v-model="range[0]" />
            </div>
            <span class="seconds-indicator">s</span>
          </div>
        </div>
        <div class="timings__col timings__col--2">
          <p class="timings__label">Duration</p>
          <p class="timings__value">{{ this.player.duration() }}s</p>
        </div>
        <div class="timings__col timings__col--3">
          <p class="timings__label">End</p>
          <div class="input-container">
            <div class="input-wrapper">
              <input class="timings__value" v-model="range[1]" />
            </div>
            <span class="seconds-indicator">s</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
// import videosConfig from '~/config/videosTemp.js'
// import 'videojs-contrib-ads';
// import 'videojs-ima';
// import videojs from '@mux/videojs-kit';
// import 'videojs-offset'
// import videojs from 'video.js';
import "@mux/videojs-kit/dist/index.css";

export default {
  name: "StreamlineVideoPlayer",

  props: {
    entitlements: {
      type: Array,
      required: false,
    },
    googleAdTagUrl: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    playbackID: {
      type: String,
      required: true,
    },
    policy: {
      type: String,
      required: true,
    },
    storyboardToken: {
      type: String,
      required: true,
    },
    videoID: {
      type: String,
      required: true,
    },
    clipping: {
      type: Boolean,
      default: false,
    },
    mediaType: {
      type: String,
      default: "videos",
    },
  },

  data: () => ({
    session: null,
    defaultSettings: null,
    player: null,
    playerID: null,
    setup: {
      controlBar: {
        pictureInPictureToggle: false,
      },
      autoplay: false,
      timelineHoverPreviews: false, // Set false to not automatically create them
      plugins: {
        mux: {
          debug: false,
        },
      },
    },
    range: [0, 1],
    duration: null,
    storyboardData: null,
    detailsUpdating: false,
  }),

  created() {
    this.defaultSettings = require("../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/defaultSettings.js");
    this.playerID = `player--${this._uid}`;
  },

  mounted() {
    this.getSession();
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },

  methods: {
    fetchStoryboardData() {
      if (this.policy === "signed") {
        axios
          .get(
            `https://image.mux.com/${this.playbackID}/storyboard.json?token=${this.storyboardToken}`
          )
          .then((response) => {
            console.log("Storyboard response", response);
            this.storyboardData = response.data;
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        axios
          .get(`https://image.mux.com/${this.playbackID}/storyboard.json`)
          .then((response) => {
            console.log("Storyboard response", response);
            this.storyboardData = response.data;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },

    updateRange(range, $event) {
      this.detailsUpdating = true;
      console.log("updateRange", range, $event);

      if (range[0] === range[1]) {
        alert("Your clip length is invalid");
      } else {
        this.rangeChangeDetection(range, $event);

        this.range[0] = parseFloat($event[0]);
        this.range[1] = parseFloat($event[1]);
      }
      this.player.offset({
        start: parseFloat(this.range[0]),
        end: parseFloat(this.range[1]),
        restart_beginning: false, //Should the video go to the beginning when it ends
      });

      this.detailsUpdating = false;

      this.$emit("clipped", this.range);
    },

    rangeChangeDetection(oldValue, newValue) {
      if (oldValue[0] != newValue[0]) {
        console.log("Start value changed");
        this.player.currentTime(newValue[0]);
      } else {
        console.log("End value changed");
        this.player.currentTime(newValue[1]);
      }
    },

    getSession() {
      console.log("getSession");
      // const userID = '646c8130-2f54-4e70-b4e9-80285a5211d4'
      // const userID = this.defaultSettings.default.videoSessionSSOUser;

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/videos/${
            this.videoID
          }/token${this.mediaType == "streams" ? "?type=live" : ""}`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log("getSession response", response);
            this.session = response.data.data.attributes.token;

            setTimeout(() => {
              this.loadPlayer();
              this.fetchStoryboardData();
            }, 10);
          })
          .catch((e) => {
            console.error(e);
          });
      });
    },

    loadPlayer() {
      this.player = videojs(
        this.$refs.player,
        this.options,
        function onPlayerReady() {
          console.log("onPlayerReady", this);
          console.log("this.player 1", this.player);
        }
      );

      setTimeout(() => {
        this.duration = JSON.parse(JSON.stringify(this.player.duration()));
        this.range = [0, this.duration];
      }, 1000);

      console.log("this.player 2", this.player);
      // setTimeout(() => {
      console.log("this.player 3", this.player);
      if (this.googleAdTagUrl) {
        this.player.ima({
          adTagUrl: this.googleAdTagUrl,
        });
      }
      // }, 1000)
    },
  },
};
</script>

<style lang="scss" scoped>
.timings {
  margin: 16px auto 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &__col {
    display: block;
    padding: 4px;
    width: 100px;
    background-color: #ffffff;
    border: 1px solid #dfe3e9;
    min-height: 45px;

    &--1 {
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }
    &--3 {
      border-radius: 0 4px 4px 0;
      border-left: 0;
    }
  }

  &__label {
    padding: 0;
    margin: 0;
    color: #b1b4b9;
    font-size: 9px;
    font-weight: 500;
  }

  &__value {
    padding: 0;
    margin: 0;
    // color: #DFE3E9;
    font-size: 13px;
    font-weight: 500;
    text-align: right;
  }
}

.input-container {
  display: flex;
  justify-content: flex-start;
  max-width: calc(100% - 5px);
  gap: 2px;

  .input-wrapper {
    display: inline;
    max-width: 100%;
  }
  input {
    width: 100%;
    max-width: 100%;
  }
  .seconds-indicator {
    flex: 1;
  }
}
</style>
