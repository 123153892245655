<template>
  <section class="packages">
    <MiniHeader :heading="''" :buttonText="'Create a Package'" :hasButton="true" @buttonClicked="createPackage()" />

    <inline-loader v-if="loading" />

    <template v-else>
      <packages-listing-item v-for="item in packages" :item="item" :key="item.packageID" />

       <div class="pagination">
        <v-pagination
          v-model="pageNumber"
          :length="pageCount"
          :total-visible="4"
          circle
          color="accent">
        </v-pagination>
    </div>
    </template>
  </section>
</template>

<script>
import axios from "axios"
import moment from "moment"
import InlineLoader from "@/components/Library/InlineLoader"
import MiniHeader from "@/components/Library/MiniHeader"
import PackagesListingItem from "@/components/Streamline/PackagesListingItem"

export default {
  name: "PackagesListing",

  components: {
    InlineLoader,
    MiniHeader,
    PackagesListingItem
  },

  data:() => ({
    loading: false,
    packages: [],
    pageNumber: 1,
    pageSize: 10
  }),

  watch: {
    pageNumber() {
      this.fetchPackages()
    }
  },

  mounted() {
    this.fetchPackages()
  },

  methods: {
    createPackage() {
      console.log("Create Package")
      if (confirm("Are you sure you want to create a new package?")) {
        this.$router.push({ path: "/streamline/package/create-package" })
      }
    },

    fetchPackages() {
      this.loading = true

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/packages?page[size]=${this.pageSize}&page[number]=${this.pageNumber}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Fetch packages:", response);
          this.packages = response.data.data
          this.$store.commit("completeLoading");
          this.loading = false
          this.pageCount = response.data.meta.totalPages;
        })
        .catch(e => {
          console.error("Problem fetching packages", e);
          this.$store.commit("completeLoading");
          this.loading = false
        });
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .packages {

  }
</style>
