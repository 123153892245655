<template>
  <v-dialog v-model="dialog" lazy persistent width="760">
    <slot slot="activator"></slot>
    <div class="widget-manager">
      <div
        v-if="!rowData.widgetID && !widgetChosen"
        :class="['widget-list', activeWidget ? `widget-list--active` : '']"
      >
        <h2 class="subheading">Widgets</h2>
        <ul>
          <template v-for="(widget, index) in widgetList">
            <li
              v-if="
                display(
                  widget.display,
                  widget.showInApp,
                  widget.clientRestriction,
                  widget.clientExclusivity
                )
              "
              :class="
                activeWidget && activeWidget.widgetType === widget.widgetType
                  ? 'active'
                  : ''
              "
              :key="index"
              @click="showWidget(widget)"
            >
              {{ widget.widgetName }}
            </li>
          </template>
        </ul>
      </div>

      <div class="widget-options">
        <div class="widget-content">
          <h3
            v-if="!activeWidget"
            class="subheading widget-content__subheading"
          >
            Choose a widget
          </h3>
          <template v-else>
            <h3 class="subheading widget-content__subheading">
              {{ activeWidget.widgetName }}
            </h3>
            <matches-carousel
              v-if="activeWidget.widgetType === 'MatchesCarousel'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <player-carousel
              v-if="activeWidget.widgetType === 'PlayerCarousel'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <playlist-carousel
              v-if="activeWidget.widgetType === 'PlaylistCarousel'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <spotlight-small
              v-if="activeWidget.widgetType === 'SpotlightSmall'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <custom-carousel
              v-if="activeWidget.widgetType === 'CustomCarousel'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <stream-amg-passes
              v-if="activeWidget.widgetType === 'StreamAmgPasses'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <streamline-calendar
              v-if="activeWidget.widgetType === 'StreamlineCalendar'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <streamline-packages
              v-if="activeWidget.widgetType === 'StreamlinePackages'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <streamplay-feature
              v-if="activeWidget.widgetType === 'StreamplayFeature'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <streamplay-list
              v-if="activeWidget.widgetType === 'StreamplayList'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <tv-schedule
              v-if="activeWidget.widgetType === 'TvSchedule'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <video-carousel
              v-if="activeWidget.widgetType === 'VideoCarousel'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <video-categories
              v-if="activeWidget.widgetType === 'CategoryCarousel'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <video-content
              v-if="activeWidget.widgetType === 'ContentWidget'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <video-faqs
              v-if="activeWidget.widgetType === 'VideoFaqs'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <video-memberships
              v-if="activeWidget.widgetType === 'VideoMemberships'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
            <video-promo
              v-if="activeWidget.widgetType === 'Promo'"
              :editData="rowData"
              @updatedWidget="updateWidgetData($event)"
              @initialised="widgetChosen = true"
            />
          </template>
        </div>

        <div class="actions">
          <button
            v-if="!isNew"
            class="actions__delete video__list-item-actions--edit"
            @click="saveUpdate()"
          >
            <span>Save</span>
          </button>
          <button
            v-else
            class="actions__delete video__list-item-actions--edit"
            @click="saveNew()"
          >
            <span>Save New</span>
          </button>
          <button
            class="actions__cancel video__list-item-actions--delete"
            @click="cancelUpdate()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import axios from "axios";
import CustomCarousel from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/CustomCarousel";
import PlayerCarousel from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/PlayerCarousel";
import PlaylistCarousel from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/PlaylistCarousel";
import MatchesCarousel from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/MatchesCarousel";
import SpotlightSmall from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/SpotlightSmall";
import StreamAmgPasses from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/StreamAmgPasses";
import StreamlineCalendar from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/StreamlineCalendar";
import StreamlinePackages from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/StreamlinePackages";
import StreamplayFeature from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/StreamplayFeature";
import StreamplayList from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/StreamplayList";
import TvSchedule from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/TvSchedule";
import VideoCarousel from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/VideoCarousel";
import VideoCategories from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/VideoCategories";
import VideoContent from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/VideoContent";
import VideoFaqs from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/VideoFaqs";
import VideoMemberships from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/VideoMemberships";
import VideoPromo from "@/components/VideosManager/Data/VideoContentFeeds/WidgetManager/Widgets/VideoPromo";

export default {
  name: "WidgetManager",

  components: {
    CustomCarousel,
    MatchesCarousel,
    PlayerCarousel,
    PlaylistCarousel,
    SpotlightSmall,
    StreamAmgPasses,
    StreamlineCalendar,
    StreamlinePackages,
    StreamplayFeature,
    StreamplayList,
    TvSchedule,
    VideoCarousel,
    VideoCategories,
    VideoContent,
    VideoFaqs,
    VideoMemberships,
    VideoPromo,
  },

  props: {
    rowData: {
      type: Object,
      required: true,
    },
  },

  created() {
    this.modules = require("@/config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
    this.defaultSettings = require("@/config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/defaultSettings.js");

    this.getGlobalSettings();
  },

  data: () => ({
    defaultSettings: null,
    dialog: false,
    widgetList: [
      {
        widgetName: "Categories",
        widgetType: "CategoryCarousel",
        display: "Global",
        showInApp: true,
        clientRestriction: { web: [], app: [] },
        clientExclusivity: { web: [], app: ["LFC"] },
      },
      {
        widgetName: "Content",
        widgetType: "ContentWidget",
        display: "Global",
        showInApp: true,
        clientRestriction: { web: [], app: [] },
        clientExclusivity: { web: [], app: [] },
      },
      {
        widgetName: "Custom Carousel",
        widgetType: "CustomCarousel",
        display: "Global",
        showInApp: false,
        clientRestriction: { web: [], app: [] },
        clientExclusivity: {
          web: ["LFC", "Videos POC"],
          app: ["LFC", "Videos POC"],
        },
      },
      {
        widgetName: "Matches Carousel",
        widgetType: "MatchesCarousel",
        display: "Global",
        showInApp: true,
        clientRestriction: { web: [], app: [] },
        clientExclusivity: {
          web: ["LFC", "Videos POC"],
          app: ["LFC", "Videos POC"],
        },
      },
      {
        widgetName: "Player Carousel",
        widgetType: "PlayerCarousel",
        display: "Global",
        showInApp: true,
        clientRestriction: { web: [], app: [] },
        clientExclusivity: {
          web: ["LFC", "Videos POC"],
          app: ["LFC", "Videos POC"],
        },
      },
      {
        widgetName: "Playlist Carousel",
        widgetType: "PlaylistCarousel",
        display: "Global",
        showInApp: false,
        clientRestriction: { web: [], app: [] },
        clientExclusivity: { web: ["LFC", "Videos POC"], app: [] },
      },
      {
        widgetName: "Promo",
        widgetType: "Promo",
        display: "Global",
        showInApp: true,
        clientRestriction: { web: ["LFC"], app: ["LFC"] },
        clientExclusivity: { web: [], app: [] },
      },
      {
        widgetName: "Streamline Calendar",
        widgetType: "StreamlineCalendar",
        display: "Streamline",
        showInApp: true,
        clientRestriction: { web: [], app: ["LFC"] },
        clientExclusivity: { web: [], app: [] },
      },
      {
        widgetName: "Streamline Packages",
        widgetType: "StreamlinePackages",
        display: "Streamline",
        showInApp: false,
        clientRestriction: { web: ["LFC"], app: ["LFC"] },
        clientExclusivity: { web: [], app: [] },
      },
      {
        widgetName: "Spotlight Small",
        widgetType: "SpotlightSmall",
        display: "Global",
        showInApp: true,
        clientRestriction: { web: [], app: [] },
        clientExclusivity: { web: [], app: [] },
      },
      {
        widgetName: "Streamplay Feature",
        widgetType: "StreamplayFeature",
        display: "StreamAMG",
        showInApp: true,
        clientRestriction: { web: [], app: [] },
        clientExclusivity: { web: [], app: [] },
      },
      {
        widgetName: "StreamAMG Passes",
        widgetType: "StreamAmgPasses",
        display: "StreamAMG",
        showInApp: false,
        clientRestriction: { web: [], app: [] },
        clientExclusivity: { web: [], app: [] },
      },
      {
        widgetName: "Streamplay List",
        widgetType: "StreamplayList",
        display: "StreamAMG",
        showInApp: true,
        clientRestriction: { web: [], app: [] },
        clientExclusivity: { web: [], app: [] },
      },
      {
        widgetName: "TV Schedule",
        widgetType: "TvSchedule",
        display: "Global",
        showInApp: false,
        clientRestriction: { web: [], app: [] },
        clientExclusivity: {
          web: ["LFC", "Videos POC"],
          app: ["LFC", "Videos POC"],
        },
      },
      {
        widgetName: "Video Carousel",
        widgetType: "VideoCarousel",
        display: "Global",
        showInApp: true,
        clientRestriction: { web: [], app: [] },
        clientExclusivity: { web: [], app: [] },
      },
      {
        widgetName: "Video FAQs",
        widgetType: "VideoFaqs",
        display: "Global",
        showInApp: true,
        clientRestriction: { web: ["LFC"], app: ["LFC"] },
        clientExclusivity: { web: [], app: [] },
      },
      {
        widgetName: "Video Memberships",
        widgetType: "VideoMemberships",
        display: "Global",
        showInApp: false,
        clientRestriction: { web: [], app: [] },
        clientExclusivity: { web: [], app: [] },
      },
    ],
    activeWidget: null,
    editedData: null,
    widgetChosen: false,
    isNew: true,
    videosApi: process.env.VUE_APP_VIDEOS_API,
    streamlineApi: process.env.VUE_APP_STREAMLINEAPI,
    modules: null,
    globalSettings: null,
  }),

  watch: {
    dialog: function (value) {
      if (value === true) {
        if (this.rowData) {
          this.activeWidget = JSON.parse(JSON.stringify(this.rowData));
          if (this.rowData.widgetID) {
            this.isNew = false;
          } else {
            this.isNew = true;
          }
        }
      }
    },
  },

  methods: {
    showWidget(key) {
      this.activeWidget = key;
    },

    closeModal() {
      this.activeWidget = null;
      this.dialog = false;
      this.widgetChosen = null;
      if (!this.rowData.widgetID) {
        this.$emit("cancel");
      }
    },

    updateWidgetData(newData) {
      this.editedData = JSON.parse(JSON.stringify(newData));
    },

    cancelUpdate() {
      this.activeWidget = null;
      this.dialog = false;
      this.editedData = null;
      this.widgetChosen = null;
      if (this.isNew) {
        // console.log('cancelling', this.editedData)
        this.$emit("cancel");
      } else {
        // console.log('cancelling update')
        this.$emit("cancelUpdate");
      }
    },

    saveUpdate() {
      this.activeWidget = null;
      this.dialog = false;
      this.widgetChosen = null;
      this.$emit("save", this.editedData);
    },

    saveNew() {
      this.activeWidget = null;
      this.dialog = false;
      this.widgetChosen = null;
      this.$emit("saveNew", this.editedData);
    },

    display(widgetRule, allowInApp, clientRestriction, clientExclusivity) {
      if (this.$route.query.isApp === "true") {
        if (allowInApp) {
          if (
            clientRestriction
              ? clientRestriction.app.includes(process.env.VUE_APP_CLUB_NAME)
              : false
          ) {
            return false;
          } else if (
            clientExclusivity.app.length
              ? !clientExclusivity.app.includes(process.env.VUE_APP_CLUB_NAME)
              : false
          ) {
            return false;
          } else if (widgetRule === "Global") {
            return true;
          } else if (widgetRule === "Streamline") {
            return this.modules.default.Streamline;
          } else if (widgetRule === "StreamAMG") {
            return this.modules.default.StreamAMGIntegration;
          }
        } else {
          if (this.defaultSettings.default.allowUpsellInApp) {
            if (widgetRule === "Streamline") {
              return this.modules.default.Streamline;
            } else if (widgetRule === "StreamAMG") {
              return this.modules.default.StreamAMGIntegration;
            }
          }
        }
      } else {
        if (
          clientRestriction
            ? clientRestriction.web.includes(process.env.VUE_APP_CLUB_NAME)
            : false
        ) {
          return false;
        } else if (
          clientExclusivity.web.length
            ? !clientExclusivity.web.includes(process.env.VUE_APP_CLUB_NAME)
            : false
        ) {
          return false;
        } else if (widgetRule === "Global") {
          return true;
        } else if (widgetRule === "Streamline") {
          return this.modules.default.Streamline;
        } else if (widgetRule === "StreamAMG") {
          return this.modules.default.StreamAMGIntegration;
        }
      }
    },

    getGlobalSettings() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${
            this.modules.default.Streamline
              ? this.streamlineApi
              : this.videosApi
          }/config`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            this.globalSettings = response.data.data.attributes;
          })
          .catch((e) => {
            console.error("Problem getting global settings", e);
            this.errorMessage = "Problem getting global settings";
          });
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.widget-manager {
  background: #fff;
  display: flex;
  margin: auto;

  h2 {
    margin: 0 0 16px;
    padding: 0 16px;
  }
}

.widget-list {
  width: 180px;
  // padding: 16px 8px 24px;
  margin: 16px 0px 24px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 160px;
    font-size: 18px;
  }

  li {
    margin: 0;
    padding: 4px 16px;
    font-size: 14px;
    border-bottom: 1px solid #e6e8f1;
    cursor: pointer;
  }

  li.active {
    font-weight: 500;
    background-color: #e6e8f1;
  }

  // &--active {
  //   width: 90px;

  //   &:hover {
  //     width: 180px;
  //   }
  // }
}

.widget-options {
  width: 100%;
  background: #e6e8f1;
  padding: 16px 24px 24px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-grow: 1;
}

.widget-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: wrap;

  &__subheading {
    margin-top: 0;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.active {
  font-weight: 700;
}
</style>
