<template>
    <section class="main-body">
        <MiniHeader
            :heading="'Adverts'"
            :buttonText="'Add Advert'"
            :hasButton="true"
            @buttonClicked="createAdvert()"
        />

        <h2 class="subheading">Footer Adverts ({{ footerAdverts.length }})</h2>

        <ol class="adverts-list">
            <li
                v-for="advert in footerAdverts"
                :key="advert.advertID"
                class="advert"
            >
                <table>
                    <tr>
                        <th>Title</th>
                        <td>{{ advert.advertName }}</td>
                    </tr>
                    <tr>
                        <th>Campaign</th>
                        <td>{{ advert.advertCampaign }}</td>
                    </tr>
                    <tr>
                        <th>Created</th>
                        <td>{{ advert.created | moment("DD/MM/YY @ kk:mm") }}</td>
                    </tr>
                    <tr>
                        <th>Child Friendly</th>
                        <td>{{ advert.childFriendly }}</td>
                    </tr>
                    <tr>
                        <th>Target URL</th>
                        <td>
                            <a
                                :href="advert.targetUrl"
                                target="_blank"
                                class="advertUrl"
                            >{{
                                advert.targetUrl
                            }}</a>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <img
                                v-if="advert.imageData"
                                :src="`${imageURL}/${advert.imageData.key}`"
                                class="advert__image"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Weighting</th>
                        <td>
                            <v-text-field
                                class="advert__input"
                                v-model="advert.weighting"
                                @change="updateAdvert(advert)"
                            />
                        </td>
                    </tr>
                </table>

                <v-btn @click="deleteAdvert(advert.advertID)">Delete</v-btn>
            </li>
        </ol>

        <AdvertisingAddItem
            v-if="addItemDialog"
            @cancelled="addItemDialog = false"
            @added="
                addAdvert($event);
            addItemDialog = false;
            "
        />
    </section>
</template>

<script>
// SA: With advertising 2, this is now treated as legacy footer advertising in some parts of the code
import axios from "axios";
import MiniHeader from "@/components/Library/MiniHeader";
import AdvertisingAddItem from "@/components/Advertising/AdvertisingAddItem";

export default {
    name: "AdvertisingManagement",

    components: {
        MiniHeader,
        AdvertisingAddItem,
    },

    data: () => ({
        api: `${process.env.VUE_APP_BASEURL}/adverts`,
        imageURL: `${process.env.VUE_APP_IMAGEURL}`,
        footerAdverts: [],
        addItemDialog: false,
        modules: null,
    }),

    created() {
        this.modules = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");
    },

    mounted() {
        console.log("Fixtures page mounted");
        this.fetchFooterAdverts();
    },

    methods: {
        createAdvert() {
            console.log("createAdvert");
            this.addItemDialog = true;
        },

        addAdvert(payload) {
            console.log("Add advert", payload);

            const advertData = payload;

            advertData.area = "footer";
            advertData.created = new Date();

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "POST",
                    url: `${process.env.VUE_APP_BASEURL}/adverts/`,
                    headers: { Authorization: jwt },
                    data: advertData,
                })
                    .then((response) => {
                        console.log("Advert added", response);
                        this.fetchFooterAdverts();
                    })
                    .catch((e) => {
                        console.error("Problem adding advert", e);
                    });
            });
        },

        updateAdvert(advert) {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "POST",
                    url: `${process.env.VUE_APP_BASEURL}/adverts/`,
                    headers: { Authorization: jwt },
                    data: advert,
                })
                    .then((response) => {
                        console.log("Advert updated:", response);
                        this.fetchFooterAdverts();
                    })
                    .catch((e) => {
                        console.error("Problem updating advert", e);
                    });
            });
        },

        deleteAdvert(id) {
            console.log("Deleting advert: ", id);

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "DELETE",
                    url: `${process.env.VUE_APP_BASEURL}/adverts/${id}`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log("Advert deleted:", response);
                        this.fetchFooterAdverts();
                    })
                    .catch((e) => {
                        console.error("Problem deleting advert", e);
                    });
            });
        },

        fetchFooterAdverts() {
            this.$store.commit("startLoading");

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${this.api}/?area=footer`, { headers: { Authorization: jwt } })
                    .then((response) => {
                        console.log("response: ", response);
                        this.footerAdverts = response.data.adverts;

                        this.$store.commit("completeLoading");
                    })
                    .catch((e) => {
                        console.error("Problem retrieving fixtures", e);
                        this.$store.commit("completeLoading");
                    });
            });
        },
    },
};
</script>

<style scoped lang="scss">
.adverts-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid $lightgrey;
    border-radius: 4px;
    background-color: $white;
}

.advert {
    padding: 20px;
    margin: 20px;
    width: calc(25% - 40px);
    border: 1px solid $lightgrey;
    border-radius: 4px;
    background-color: $white;

    th {
        padding: 0 16px 8px 0;
        color: #454545;
        font-weight: 400;
    }

    td {
        padding: 0 0 8px;
        width: 100%;
        font-weight: 500;
    }

    img {
        width: 100%;
    }
}

.advertUrl {
    word-break: break-all;
}
</style>
