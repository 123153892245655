<template>
    <div class="widget-details">
        <template v-if="!updatedData.widgetType">
            <p class="widget-info">The Video Memberships Widget is one of the primary ways of upselling memberships to users. You can select
                different memberships to feature in different widgets.</p>

            <v-btn
                class="accent"
                v-if="!updatedData.widgetType"
                @click="initialise()"
            >Use this widget</v-btn>
        </template>

        <div
            class="widget-fieldset"
            v-else
        >
            <label class="label">Title <span v-if="modules.default.LFCExclusive">(Default text is available if nothing is
                    typed)</span></label>
            <v-text-field
                id="widgetData.title"
                solo
                flat
                v-model="updatedData.widgetData.title"
            >
            </v-text-field>

            <label class="label">Description <span v-if="modules.default.LFCExclusive">(Default text is available if nothing is
                    typed)</span></label>
            <v-text-field
                id="widgetData.description"
                solo
                flat
                v-model="updatedData.widgetData.description"
            >
            </v-text-field>

            <label class="label">Style</label>
            <v-select
                id="widgetData.style"
                :items="styleOptions"
                solo
                flat
                v-model="updatedData.widgetData.style"
            >
            </v-select>

            <label class="label">Featured Memberships</label>
            <!-- <v-text-field
        id="widgetData.featuredMemberships"
        solo
        flat
        v-model="updatedData.widgetData.featuredMemberships">
      </v-text-field> -->

            <v-combobox
                v-if="membershipsLoaded"
                v-model="updatedData.widgetData.featuredMemberships"
                :items="memberships"
                multiple
                chips
                solo
                flat
            ></v-combobox>

            <p v-else>Memberships Loading....</p>

            <template v-if="modules.default.LFCExclusive && updatedData.widgetData.featuredMemberships.length">
                <label class="label">Login Button accompanying text</label>
                <v-text-field
                    id="widgetData.loginButtonText"
                    solo
                    flat
                    placeholder="Already a member?"
                    v-model="updatedData.widgetData.loginButtonText"
                >
                </v-text-field>

                <label class="label">Login Button CTA</label>
                <v-text-field
                    id="widgetData.loginButtonCta"
                    solo
                    flat
                    placeholder="Sign in here"
                    v-model="updatedData.widgetData.loginButtonCta"
                >
                </v-text-field>

                <template v-if="updatedData.widgetData.featuredMemberships[0].text == 'Register'">

                    <label class="label">Registration button CTA</label>
                    <v-text-field
                        id="widgetData.registerButtonCta"
                        solo
                        flat
                        placeholder="Register"
                        v-model="updatedData.widgetData.registerButtonCta"
                    >
                    </v-text-field>

                    <label class="label">Registration button Style</label>
                    <v-select
                        id="widgetData.registerButtonStyle"
                        :items="buttonStyleOptions"
                        solo
                        flat
                        v-model="updatedData.widgetData.registerButtonStyle"
                    >
                    </v-select>
                </template>
                <template v-if="['Subscriber', 'Subscribe'].includes(updatedData.widgetData.featuredMemberships[0].text)">
                    <label class="label">First package subscription button CTA</label>
                    <v-text-field
                        id="widgetData.firstSubCta"
                        solo
                        flat
                        placeholder="Select"
                        v-model="updatedData.widgetData.firstSubCta"
                    >
                    </v-text-field>

                    <label class="label">Second package subscription button CTA</label>
                    <v-text-field
                        id="widgetData.secondSubCta"
                        solo
                        flat
                        placeholder="Select"
                        v-model="updatedData.widgetData.secondSubCta"
                    >
                    </v-text-field>

                    <label class="label">Subscription button Style</label>
                    <v-select
                        id="widgetData.subButtonStyle"
                        :items="buttonStyleOptions"
                        solo
                        flat
                        v-model="updatedData.widgetData.subButtonStyle"
                    >
                    </v-select>
                </template>

            </template>

        </div>
    </div>
</template>

<script>
import axios from "axios"
export default {
    name: "VideoMemberships",

    watch: {
        updatedData: {
            handler: function () {
                this.updatedContent()
            },
            deep: true
        }
    },

    props: {
        editData: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        api: process.env.VUE_APP_VIDEOS_API,
        modules: null,
        styleOptions: [{ text: "Light Mode", value: "light" }, { text: "Dark Mode", value: "dark" }],
        buttonStyleOptions: [{ text: "Light Button", value: "dark" }, { text: "Dark Button", value: "light" }, { text: "Club Button", value: "club" }],
        updatedData: {
            title: null,
            description: null,
            featuredMemberships: [], // Add the memberships you want to promote into this array
            style: null,
            // LFC Only below
            loginButtonCta: null,
            loginButtonText: null,
            // Registration widget
            registerButtonCta: null,
            registerButtonStyle: null,
            // Subscription widget
            firstSubCta: null,
            secondSubCta: null,
            subButtonStyle: null,
        },
        memberships: [],
        membershipsLoaded: false
    }),

    mounted() {
        this.modules = require("../../../../../../config/features/" + process.env.VUE_APP_CLUB_ID + "/modules.js")
        if (this.modules.default.LFCExclusive) {
            this.styleOptions = [{ text: "Light Mode", value: "light" }, { text: "Dark Mode", value: "dark" }, { text: "Club Mode", value: "club" }]
        }

        this.updatedData = JSON.parse(JSON.stringify(this.editData))
        this.fetchMemberships()
    },

    methods: {
        initialise() {
            this.updatedData.widgetType = "VideoMemberships"
            this.updatedData.widgetName = "Video Memberships"
            this.updatedData.widgetID = this.$uuid.v1()
            this.updatedData.widgetData = {
                title: null,
                description: null,
                featuredMemberships: [], // Add the memberships you want to promote into this array
                style: null // Background colour - light || dark
            }
            this.$emit("initialised")
        },

        fetchMemberships() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.api}/memberships`,
                    headers: { "Authorization": jwt }
                })
                    .then(response => {
                        console.log("fetch memberships in the widget", response)
                        for (const membership of response.data.data) {
                            this.memberships.push({
                                text: membership.attributes.title, value: membership.attributes.membershipID
                            })
                        }
                        this.membershipsLoaded = true
                    })
                    .catch(e => {
                        console.error("Problem getting memberships", e);
                    });
            })
        },

        updatedContent() {
            this.$emit("updatedWidget", this.updatedData)
        }
    }
}
</script>

<style scoped lang='scss'></style>
