export default {
    "AdditionalPageTypes": false,
    "Admin": true,
    "Advertising": false,
    "AggregatedContentFeed": true,
    "AllowCustomFields": false,
    "AppConfig": true,
    "AppEcommerceManager": true,
    "AppScorePredictor": false,
    "AppSocialManager": false,
    "AppTicketingManager": true,
    "BreakingNews": false,
    "BuildManager": true,
    "Calendar": true,
    "CustomCommentary": false,
    "CustomLineups": false,
    "CustomTables": false,
    "CustomTags": false,
    "Dashboard": true,
    "Fixtures": true,
    "Forms": false,
    "HomepageManager": true,
    "HomepageNewsPromo": false,
    "HomepagePlayerHighlight": false,
    "Lab": false,
    "MatchdayTakeover": false,
    "Media": true,
    "News": true,
    "OptaIntegration": false,
    "Organisations": false,
    "OverlayPromos": false,
    "Pages": false,
    "PlatformOptions": true,
    "PlayerVoting": false,
    "PreventEntitlementEmails": false,
    "Profile": true,
    "RelatedContentTagging": true,
    "RewardsManager": false,
    "RewardPoints": true,
    "Judo": true,
    "Settings": false,
    "SiteConfig": true,
    "SocialContentFeed": true,
    "Sponsors": true,
    "Squads": true,
    "SsoUsers": false,
    "StreamAMGIntegration": false,
    "Streamline": true,
    "StreamlineAdverts": false,
    "StreamlineFiltersv2": true,
    "StreamlinePlaylists": true,
    "StreamlinePromoCodes": false,
    "Videos": true,
    "Voice": true,
    "WebStats": false
}
