<template>
    <v-dialog
        class="fixtures-list-widget"
        v-model="dialog"
        width="580"
        lazy
    >
        <slot slot="activator"></slot>

        <v-card>
            <v-card-title class="widget__heading">
                Blog
            </v-card-title>

            <v-card-text>

                <v-form
                    enctype="multipart/form-data"
                    ref="calendarForm"
                >

                    <template v-if="blogList.length"> <label
                            class="label"
                            for="blog"
                        >Select a blog to display</label>
                        <v-select
                            :items="blogList"
                            item-text="attributes.name"
                            id="blog"
                            v-model="selectedBlog"
                            outline
                            validate-on-blur
                            return-object
                            label="Select Blog"
                        ></v-select>
                    </template>
                    <p v-else>Loading...</p>
                    <p v-if="error">{{ error }}</p>

                    <v-btn
                        color="accent"
                        outline
                        flat
                        @click="dialog = false"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        :disabled="!selectedBlog"
                        color="accent"
                        depressed
                        @click="postBlog()"
                    >
                        Add Blog
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios"

export default {
    name: "BlogWidget",

    props: {
        widgetData: {
            type: Object,
            required: false
        },
    },

    data: () => ({
        modules: null,
        dialog: false,
        selectedBlog: null,
        error: "",
        blogList: [],
    }),

    created() {
        this.selectedBlog = this.widgetData
        this.fetchBlogs()
    },

    methods: {
        fetchBlogs() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${process.env.VUE_APP_BLOGAPI}`, { "headers": { "Authorization": jwt } })
                    .then(response => {
                        console.log("fetchBlogs: ", response);
                        this.blogList = response.data.data
                    })
                    .catch(e => {
                        console.error("Problem retrieving blogs", e);
                        this.error = "An error has occurred"
                    });
            })
        },

        postBlog() {
            const { id, attributes } = this.selectedBlog

            this.$emit("updatedData", { blogID: id, blogName: attributes.name, blogTitle: attributes.title });
            this.dialog = false
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
