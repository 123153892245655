<template>
    <section class="section">
        <h2 class="section-title">Broadcasters</h2>
        <div>
            <div class="filters">
                <v-layout class="filters__date filters__section">
                    <v-flex>
                        <label class="label">From</label>
                        <div class="date-picker__container">
                            <datetime v-model="from"></datetime>
                        </div>
                    </v-flex>
                    <v-flex>
                        <label class="label">To</label>
                        <div class="date-picker__container">
                            <datetime v-model="to"></datetime>
                        </div>
                    </v-flex>
                </v-layout>
                <v-layout class="filters__section">
                    <v-flex>
                        <v-combobox
                            :disabled="competitionsList.length == 0"
                            :items="competitionsList"
                            item-value="value"
                            item-text="name"
                            v-model="competitionFilter"
                            label="Competitions"
                            no-data-text="loading..."
                            multiple
                            solo
                            flat
                            clearable
                            dense
                            chips
                            deletable-chips
                            class="filters__competitions"
                        >
                        </v-combobox>
                    </v-flex>
                    <v-flex>
                        <v-btn
                            class="filters__button"
                            color="accent"
                            @click="fetchMatches()"
                        >Search</v-btn>
                    </v-flex>

                </v-layout>
            </div>
        </div>
        <p v-if="error">{{ error }}</p>
        <p v-else-if="loading">Loading...</p>
        <template v-else>
            <ul class="list">
                <broadcaster-item
                    v-for="match in matches"
                    :key="match.id"
                    :match="match"
                />
            </ul>
        </template>
        <v-pagination
            v-if="pageCount > 1"
            v-model="pageNumber"
            :length="pageCount"
            :total-visible="7"
            rounded="circle"
            color="accent"
        />
    </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import BroadcasterItem from './BroadcasterItem.vue'

export default {
    name: 'BroadcastersListing',

    components: {
        BroadcasterItem
    },

    data: () => ({
        api: `${process.env.VUE_APP_MULTICLUBMATCHESAPI}`,
        from: moment().format("YYYY-MM-DD"),
        to: moment().add(150, "days").format("YYYY-MM-DD"),
        seasonFilter: null,
        competitionFilter: [],
        matches: [],
        loading: true,
        error: null,
        pageSize: 50,
        pageCount: null,
        pageNumber: 1,
        competitionsList: [],
    }),

    mounted() {
        this.fetchCompetitions()
        this.fetchMatches()
    },

    watch: {
        pageNumber() {
            this.fetchMatches()
        }
    },

    methods: {
        fetchMatches() {
            this.loading = true;
            this.error = null

            const pageSize = `page.size=${this.pageSize}`
            const from = `&from=${this.from}`
            const to = `&to=${this.to}`
            const competition = `${this.competitionFilter.length > 0 ? `&competitionID=${this.competitionFilter.map(item => item.value).join(",")}` : ''}`
            const query = `${pageSize}${from}${to}${competition}&includeBroadcasters=true`

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${this.api}/matches?${query}`, {
                        headers: { Authorization: jwt },
                    })
                    .then((response) => {
                        this.matches = response.data.data
                        this.pageCount = Math.ceil(response.data.meta.totalCount / this.pageSize);
                    }).catch((err) => {
                        if (err.response.status == 404) {
                            this.error = "No matches found with the applied filters"
                        } else {
                            this.error = "An error has occurred"
                        }
                    }).finally(() => {
                        this.loading = false
                    })
            });
        },

        fetchCompetitions() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {

                axios
                    .get(`${this.api}/competitions`, {
                        headers: { Authorization: jwt },
                    })
                    .then((response) => {
                        this.competitionsList = response.data.data.map((comp) => {
                            return {
                                name: comp.attributes.competitionName,
                                value: comp.id
                            }
                        })
                        this.loadingComps = false

                    })
            })
        }
    }
}
</script>

<style scoped lang='scss'>
.section {
    padding: 20px;

}

.section-title {
    color: #67247C;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 20px;
}

.filters {
    background: #fff;
    margin: 0 0 48px;
    padding: 32px 16px 0;
    border: 1px solid #dfe3e9;
    border-radius: 0 0 4px 4px;
    box-shadow: 4px 4px 4px 0 #ebebeb;

    &__section {
        gap: 10px;
    }

    &__date {
        margin-bottom: 20px;
    }

    &__competitions {
        flex: 2 1 auto;
    }

    &__button {
        transform: translateY(4px);
        display: flex;
        margin-left: auto;
    }
}

.list {
    padding: 0;
}
</style>
