export default {
  aggregatedFeed: {
    showAllNews: 1,
  },
  allowUpsellInApp: false,
  pageTypes: [
    { value: "internal", text: "Website Page" },
    // {value: 'external', text: 'External Page'},
    { value: "homepage", text: "Homepage" },
  ],
  platformSettings: {
    showWeb: true,
    showApp: false,
  },
  defaultNewsCardStyle: "Stack",
  playerManagement: "V1",
  customDataFields: {
    customDataFieldOneName: "Event Pages Title",
    customDataFieldOneDescription:
      "This is the title that shows in the card and the hero of an event page. When this field is not empty it defines this page as an event page and uses its specific card and hero styling",
    customDataFieldTwoName: "Event date",
    customDataFieldTwoDescription:
      "This is the date that shows on both the hero and the card for event pages. It will show exactly as typed here, without any modifications",
    customDataFieldThreeName: "",
    customDataFieldThreeDescription: "",
  },
  customTags: [
    {
      name: "Minds",
    },
    {
      name: "Learns",
    },
    {
      name: "Thrives",
    },
    {
      name: "About-Us",
    },
    {
      name: "Support-Us",
    },
    {
      name: "Facilities",
    },
  ],
};
