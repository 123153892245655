<template>
    <section class="documents-management main-body">

        <div class="documents">
            <MiniHeader
                heading="Documents"
                buttonText="Add Document"
                :hasButton="true"
                @buttonClicked="createDocument()"
            />

            <template v-if="documentsModel !== undefined
                &&
                documentsModel.length
                > 0">
                <document-list-item
                    v-for="doc in documentsModel"
                    :key="doc.id"
                    :name="doc.attributes.documentTitle"
                    :id="doc.id"
                    :last-modified="doc.attributes.publicationData.updatedAt"
                    @refresh="getDocs()"
                />
            </template>
            <template v-else-if="error && error.length">{{ error }}</template>
            <p v-else>No documents were found</p>

        </div>

        <div class="pagination">
            <v-pagination
                v-if="pageCount > 1"
                v-model="pageNumber"
                :length="pageCount"
                :total-visible="4"
                circle
                color="accent"
            >
            </v-pagination>
        </div>

        <router-view></router-view>

    </section>
</template>

<script>
import MiniHeader from "@/components/Library/MiniHeader.vue"
import { useDocuments } from "../../composables/useDocuments.ts"
import { ref, defineComponent, watch } from "vue";
import DocumentListItem from "./DocumentListItem.vue"
export default defineComponent({
    name: "DocumentsManagement",
    components: {
        MiniHeader,
        DocumentListItem
    },
    setup() {
        const { fetchDocuments, documentsModel, error, total } = useDocuments();
        const searchString = ref("");
        const pageCount = ref(0);
        const pageSize = ref(10);
        const pageNumber = ref(1);
        const clubsloaded = ref(false);
        fetchDocumentsList()

        function fetchDocumentsList(pageSize, pageNumber, sort) {
            fetchDocuments(pageSize, pageNumber, sort)
        }
        watch(total, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                pageCount.value = Math.ceil(newValue / pageSize.value)
            }
        })
        watch(pageNumber, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                clubsloaded.value = false
                fetchDocumentsList(pageSize.value, newValue, "ascending")
            }
        })

        function createDocument() {
            setTimeout(() => {
                if (window.globalThis.vueinstance) {
                    window.globalThis.vueinstance.$router.push(
                        { path: "/documents/create" }
                    )
                } else if (window.globalThis.$vm) {
                    window.globalThis.$vm.$router.push(
                        { path: "/documents/create" }
                    )
                }
            }, 400)
        }
        return {
            documentsModel,
            searchString,
            pageCount,
            pageSize,
            pageNumber,
            clubsloaded,
            fetchDocumentsList,
            createDocument,
            error
        }
    }
})
</script>

<style scoped lang='scss'>
.search-row {
    display: flex;
    justify-content: flex-end;
}

.label {
    text-align: right;
}

.search-item {
    position: relative;

    &:before {

        content: '';
        position: absolute;
        background: transparent url('../../assets/search.svg') center no-repeat;
        right: 6px;
        top: 6px;
        height: 24px;
        width: 24px;
    }
}

.search-box {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    height: 36px;
    padding: 8px;
}

.search-result {
    border-bottom: 1px solid #67247C;
}

.search-term {
    color: #67247C;
}
</style>
