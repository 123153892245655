<template>
    <v-dialog
        class="multi-field-widget"
        v-model="dialog"
        width="580"
        lazy
    >

        <slot slot="activator"></slot>

        <v-card>
            <v-card-title class="widget__heading">
                {{ widgetName }}
            </v-card-title>

            <v-card-text>
                <p
                    v-if="widgetDescription"
                    class="widget__description"
                >{{ widgetDescription }}</p>
                <div
                    v-for="field in fieldElements"
                    :key="field.label"
                >
                    <label
                        class="label"
                        for="widget-field"
                    >{{ field.label }}</label>

                    <v-textarea
                        v-if="field.type === 'multi'"
                        :id="field.name"
                        v-model="updatedData[field.name]"
                        solo
                        flat
                        required
                    ></v-textarea>

                    <!-- :TODO stop label displaying twice -->
                    <v-checkbox
                        v-if="field.type === 'checkbox'"
                        :id="field.name"
                        v-model="updatedData[field.name]"
                        required
                    ></v-checkbox>

                    <v-text-field
                        v-else
                        :id="field.name"
                        v-model="updatedData[field.name]"
                        solo
                        flat
                        required
                    ></v-text-field>
                </div>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="accent"
                    outline
                    flat
                    @click="updateData(); dialog = false"
                >
                    Post
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>
export default {
    name: "MultiFieldWidget",

    props: {
        widgetData: {
            type: Object,
            required: false
        },
        widgetName: {
            type: String,
            required: true
        },
        widgetDescription: {
            type: String,
            required: false
        },
        fieldElements: {
            type: Array,
            required: true
        }
    },

    data: () => ({
        dialog: false,
        updatedData: {}
    }),

    mounted() {
        console.log("MultiFieldWidget... mounted");
        this.getWidgetData();
        if (this.fieldElements) {
            this.fieldElements.forEach((field) => {
                this.updatedData[field.name] = null;
            })
        }
    },
    watch: {
        dialog: {
            handler(value) {
                if (value) {
                    this.getWidgetData();
                }
            }
        }
    },

    methods: {
        updateData() {
            console.log("Update data:", this.updatedData);

            for (const field in this.updatedData) {
                if (this.updatedData[field] === "") {
                    this.updatedData[field] = null
                }
            }
            this.$emit("updatedData", this.updatedData);
        },
        getWidgetData() {
            if (this.widgetData) {
                this.updatedData = JSON.parse(JSON.stringify(this.widgetData));
                console.log("widgetData :", this.updatedData);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.widget {
    &__description {
        margin: 0 0 20px;
        color: #7B6B80;
        font-size: 14px;
    }
}
</style>
