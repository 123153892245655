<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <p class="widget-info">
        This widget allows you to feature a specific video. The title and
        description will come from video metadata but you can also add an
        optional label.
      </p>

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()"
        >Use this widget</v-btn
      >
    </template>

    <div class="widget-fieldset" v-else>
      <label class="label">Video ID</label>
      <v-text-field
        id="widgetData.videoID"
        solo
        flat
        v-model.trim="updatedData.widgetData.videoID"
      >
      </v-text-field>

      <template v-if="$route.query.isApp !== 'true'">
        <label class="label">Style</label>
        <v-select
          id="widgetData.style"
          :items="styleOptions"
          solo
          flat
          v-model="updatedData.widgetData.style"
        >
        </v-select>
      </template>

      <label class="label">Custom Label</label>
      <v-text-field
        id="widgetData.customSubtitle"
        solo
        flat
        v-model="updatedData.widgetData.customSubtitle"
      >
      </v-text-field>

      <template v-if="$route.query.isApp !== 'true'">
        <v-layout v-if="modules.default.LFCExclusive" row>
          <v-flex mr-2>
            <ImageUploader
              :title="'Background image (optional)'"
              :widthOptionEnabled="false"
              :mediaLibraryID="
                updatedData.widgetData.imageData
                  ? updatedData.widgetData.imageData.mediaLibraryID
                  : ''
              "
              @update="updateImage($event)"
            />
          </v-flex>
        </v-layout>
      </template>
    </div>
  </div>
</template>

<script>
import ImageUploader from "@/components/Library/ImageUploader";

export default {
  name: "SpotlightSmall",

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent();
      },
      deep: true,
    },
  },

  props: {
    editData: {
      type: Object,
      required: true,
    },
  },

  components: {
    ImageUploader,
  },

  data: () => ({
    styleOptions: [
      { text: "Light Mode", value: "light" },
      { text: "Dark Mode", value: "dark" },
    ],
    buttonStyleOptions: [
      "",
      "primary-light",
      "primary-dark",
      "ghost-light",
      "ghost-dark",
      "label-light",
      "label-dark",
    ],
    updatedData: {
      widgetData: {
        style: null,
        videoID: null,
        customSubtitle: null,
        imageData: null,
      },
    },
    modules: null,
  }),

  created() {
    this.modules = require("../../../../../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");

    if (this.modules.default.LFCExclusive) {
      this.styleOptions = [
        { text: "Light Mode", value: "light" },
        { text: "Dark Mode", value: "dark" },
        { text: "Club Mode", value: "club" },
      ];
    }
  },

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.editData));
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "SpotlightSmall";
      this.updatedData.widgetName = "Spotlight Small";
      this.updatedData.widgetID = this.$uuid.v1();
      this.updatedData.widgetData = {
        style: null, // Background colour - light || dark
        videoID: null,
        customSubtitle: null,
        imageData: null,
      };
      this.$emit("initialised");
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData);
    },

    updateImage(image) {
      this.updatedData.widgetData.imageData = image;
    },
  },
};
</script>

<style scoped lang='scss'>
</style>
