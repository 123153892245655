import { render, staticRenderFns } from "./AllFixturesWidget.vue?vue&type=template&id=15c0ea96&scoped=true"
import script from "./AllFixturesWidget.vue?vue&type=script&lang=js"
export * from "./AllFixturesWidget.vue?vue&type=script&lang=js"
import style0 from "./AllFixturesWidget.vue?vue&type=style&index=0&id=15c0ea96&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15c0ea96",
  null
  
)

export default component.exports