<template>
  
  <v-card>

    <div class="grid-list">

      <p class="grid-list__intro">Select the layout for the page row.  You will be able to re-order the columns within the layout, but wil not be able to change the column sizes or layout type.</p> 

      <div class="grid-list__item" @click="addGrid('ChildRow', '100')">
        <span class="grid-list-icon grid-list-icon__carousel"></span>
        <h3>Child pages listing</h3>
        <p>Automatically displays all of this pages direct child pages.</p>
      </div>

      <br>

      <div class="grid-list__item" @click="addGrid('WidgetRow', '100')">
        <span class="grid-list-icon grid-list-icon__100"></span>
        <h3>100% Width (Widgets Only)</h3>
        <p>This is used for placing widgets from standard content pages only.</p>
      </div>

      <br>

      <div class="grid-list__item" @click="addGrid('GridRow', '50-50')">
        <span class="grid-list-icon grid-list-icon__50-50"></span>
        <h3>Half & Half</h3>
        <p>This layout is for splitting content down the middle.</p>
      </div>

      <br>

      <div class="grid-list__item" @click="addGrid('GridRow', '50-25-25')">
        <span class="grid-list-icon grid-list-icon__50-25-25"></span>
        <h3>Half & Quarters</h3>
        <p>This is for splitting content into a large focussed area and 2 smaller areas.</p>
      </div>

      <br>

      <div class="grid-list__item" @click="addGrid('GridRow', '66-33')">
        <span class="grid-list-icon grid-list-icon__66-33"></span>
        <h3>One Third, Two thirds</h3>
        <p>This layout is for prioritising areas with larger focussed content.</p>
      </div>

      <br />

      <div class="grid-list__item" @click="addGrid('GridRow', '33-33-33')">
        <span class="grid-list-icon grid-list-icon__33-33-33"></span>
        <h3>Thirds</h3>
        <p>This layout is for splitting content into 3 equally sized columns.</p>
      </div>

      <br>

      <div class="grid-list__item" @click="addGrid('GridRow', 'repeater')">
        <span class="grid-list-icon grid-list-icon__carousel"></span>
        <h3>Repeater</h3>
        <!-- :TODO Add back in when carousel is available -->
        <!-- <p>This is used for adding 4 or more columns.  If more than 4 columns are added you can choose between carousel and stacker options.</p> -->
        <p>This is used for adding 4 or more columns in a stacked grid.</p>

      </div>

    </div>

  </v-card>

</template>

<script>

export default {
  name: "GridSelector",

  data () {
    return {
      dialog: false,
    }
  },

  methods: {
    addGrid(rowType, gridType) {
      console.log("addGrid: ", rowType, gridType);
      const data = {
        rowType: rowType,
        gridType: gridType
      }
      this.$emit("gridAdded", data);
    }
  }
}
</script>

<style lang="scss" scoped>
  .grid-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    min-height: 470px;
    width: 100%;

    &__intro {
      padding: 8px;
      width: 100%;
    }

    &__item {
      flex-basis: calc(33% - 16px);
      flex-grow: 1;
      padding: 16px;
      margin: 8px;
      box-shadow: 0 8px 8px 0 rgba(0,0,0,0.06);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      min-width: 180px;
      flex-wrap: wrap;

      &:hover {
        background-color: #eaeaea;
        cursor: pointer;
      }
    }

    &__button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      color: #67247C;
      text-align: center;
      white-space: nowrap;
      outline: 0;
    }

    &__icon {
      margin: 0 0 5px;
    }

    &__header {
      padding: 10px;
      height: 40px;
    }

    &__icon {
      &--custom {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
      }
    }
  }

  .grid-list-icon {
    height: 80px;
    margin-bottom: 8px; 

    &__100 {
      background: transparent url('../../assets/grid-types/100.svg') no-repeat;
      background-size: contain; 
    }

    &__50-50 {
      background: transparent url('../../assets/grid-types/50-50.svg') no-repeat;
      background-size: contain; 
    }

    &__66-33 {
      background: transparent url('../../assets/grid-types/66-33.svg') no-repeat;
      background-size: contain; 
    }

    &__33-33-33 {
      background: transparent url('../../assets/grid-types/33-33-33.svg')no-repeat;
      background-size: contain; 
    }

    &__50-25-25 {
      background: transparent url('../../assets/grid-types/50-25-25.svg') no-repeat;
      background-size: contain; 
    }

    &__carousel {
      background: transparent url('../../assets/grid-types/carousel.svg') no-repeat;
      background-size: contain; 
    }
  }
</style>
