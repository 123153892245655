<template>
    <section>
        <h3 class="subheading">Post Details</h3>
        <section class="section-container">
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="post-category"
                    >Category</label>
                    <v-select
                        id="post-category"
                        :items="categories"
                        item-text="newsCategory"
                        item-value="newsCategoryID"
                        v-model="updatedData.postCategory"
                        solo
                        flat
                        @change="updateCategory()"
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>* Required</i></p>
                    <p>
                        <i>Please take note - If your category has a default child friendly
                            advert value, then when swapping to this category the child
                            friendly value below will be updated.</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="post-title"
                    >Post Title</label>
                    <v-text-field
                        id="post-title"
                        v-model="updatedData.postTitle"
                        solo
                        flat
                        required
                        @blur="setSlug(updatedData.postTitle)"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>* Required</i></p>
                </v-flex>
            </v-layout>

            <p
                class="info-notice"
                v-if="updatedData.postSlug || updatedData.postSlugSuffix"
            >
                <template v-if="updatedData.postSlug">
                    Post Path: {{ updatedData.postSlug }}<br />Any changes will be updated
                    on save
                </template>
                <template v-else-if="!updatedData.postSlug && updatedData.postSlugSuffix && !isAltLang
                            ">
                    Post Slug: /{{ updatedData.postSlugSuffix }}
                </template>
                <template v-else-if="!updatedData.postSlug && isAltLang">
                    Post Slug will be generated on save
                </template>
            </p>

            <template v-if="!isAltLang || (isAltLang && updatedData.postSlug)">
                <v-layout
                    row
                    v-if="updatedData.postSlugSuffix || updatedData.postSlug"
                >
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <v-checkbox
                            v-model="editableSlug"
                            label="Edit Post Slug"
                        >
                        </v-checkbox>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i></i></p>
                    </v-flex>
                </v-layout>
            </template>

            <v-layout
                row
                v-if="editableSlug"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="post-slug"
                    >Post Slug</label>
                    <v-text-field
                        id="post-slug"
                        v-model="updatedData.postSlugSuffix"
                        solo
                        flat
                        @input="sanitizeSlug()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>Only uppercase letters, lowercase letters, numbers and hyphens
                            are allowed. You cannot edit the post path.</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="post-author"
                    >Post Author</label>
                    <v-text-field
                        id="post-author"
                        v-model="updatedData.postAuthor"
                        solo
                        flat
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i></i></p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="post-summary"
                    >Post Summary</label>
                    <v-textarea
                        id="post-summary"
                        v-model="updatedData.postSummary"
                        solo
                        flat
                    ></v-textarea>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This is used on the summary cards and should be kept to a
                            minimum</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout
                v-if="client !== 'efl'"
                row
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="post-icon"
                    >Icon / Content type</label>
                    <v-select
                        id="post-icon"
                        :items="icons"
                        item-text="name"
                        item-value="value"
                        v-model="updatedData.postIcon"
                        solo
                        flat
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i></i></p>
                </v-flex>
            </v-layout>
        </section>

        <p
            v-if="isAltLang"
            class="alt-lang-notice"
        >
            This post is marked as being written for the
            <i><u>{{ isAltLang }}</u></i>
            category and should be written in the relevant language
        </p>

        <h3 class="subheading">Images</h3>
        <section class="section-container">
            <v-layout row>
                <v-flex mr-2>
                    <ImageUploader
                        :title="'Hero Image - Large/Desktop'"
                        :widthOptionEnabled="false"
                        :mediaLibraryID="updatedData.mediaLibraryID"
                        @update="updateImage($event, 'mediaLibraryID')"
                        :desc="'<p>Recommended sizes:</p><p>Deep: 1920x1080 or highest res 16:9 image available</p> <p>Shallow: 1920x480</p><p>Used for Social Sharing</p>'"
                    />
                </v-flex>
            </v-layout>

            <v-layout
                row
                class="section-divider"
            >
                <v-flex
                    mr-5
                    mt-3
                    class="half"
                >
                    <label
                        class="label"
                        for="hero-style--large"
                    >Hero Style - Large/Desktop</label>
                    <v-select
                        id="hero-style--large"
                        v-bind:items="heroStyleOptions"
                        v-model="updatedData.heroStyleLarge"
                        solo
                        flat
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>Hero style - large</i></p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex mr-2>
                    <ImageUploader
                        :title="'Hero Image - Small/Mobile'"
                        :widthOptionEnabled="false"
                        :mediaLibraryID="updatedData.heroSmallMediaLibraryID"
                        @update="updateImage($event, 'heroSmallMediaLibraryID')"
                        :desc="'<p>Recommended sizes:</p><p>Deep: 1160x1280 8:9 Image</p><p>Shallow: 1920x1080 or highest res 16:9 image available</p>'"
                    />
                </v-flex>
            </v-layout>

            <v-layout
                row
                class="section-divider"
            >
                <v-flex
                    mr-5
                    mt-3
                    class="half"
                >
                    <label
                        class="label"
                        for="hero-style--small"
                    >Hero Style - Small/Mobile</label>
                    <v-select
                        id="hero-style--small"
                        v-bind:items="heroStyleOptions"
                        v-model="updatedData.heroStyleSmall"
                        solo
                        flat
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>Hero style - small</i></p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex mr-2>
                    <ImageUploader
                        :title="'Listing Image'"
                        :widthOptionEnabled="false"
                        :mediaLibraryID="updatedData.listingMediaLibraryID"
                        @update="updateImage($event, 'listingMediaLibraryID')"
                        :desc="'<p>Recommended size:</p> <p>1600x900</p><p>If no listing image is provided then the main hero image will act as a fallback</p>'"
                    />
                </v-flex>
            </v-layout>
        </section>

        <h3 class="subheading">Style</h3>
        <section class="section-container">
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="page-template"
                    >Card Style</label>
                    <v-select
                        id="page-template"
                        v-bind:items="cardStyleOptions"
                        v-model="updatedData.cardStyle"
                        solo
                        flat
                    >
                    </v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p><i>**New feature**</i></p>
                    <p>
                        <i>In certain components you can decide how the article card
                            renders. This is limited to the news page, category page, and
                            category widget for now.</i>
                    </p>

                    <p>
                        <i>The Cover style is particularly suited to darker images where you
                            don't mind an overlap or some cropping.</i>
                    </p>
                </v-flex>
            </v-layout>
        </section>

        <template v-if="featureConfig.default.AggregatedContentFeed">
            <h3 class="subheading">Aggregated Feed</h3>
            <section class="section-container">
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="feed-status"
                        >Show in aggregated feed</label>
                        <v-select
                            id="feed-status"
                            v-model="updatedData.showInFeed"
                            :items="feedOptions"
                            solo
                            flat
                        >
                        </v-select>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i></i></p>
                    </v-flex>
                </v-layout>

                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="feed-width"
                        >Width in feed</label>
                        <!-- TODO - work out how to do the rules and validation -->
                        <v-select
                            :disabled="updatedData.showInFeed === 0"
                            id="feed-width"
                            v-model="updatedData.feedWidth"
                            :items="feedWidthOptions"
                            :rules="updatedData.showInFeed ? [rules.required] : []"
                            solo
                            flat
                        >
                        </v-select>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p><i></i></p>
                    </v-flex>
                </v-layout>
            </section>
        </template>

        <template v-if="featureConfig.default.BreakingNews">
            <h3 class="subheading">Breaking News</h3>
            <section
                class="section-container"
                v-show="!showBreakingNewsWidget"
            >
                <v-layout row>
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="post-icon"
                        >Set Breaking News</label>
                        <v-select
                            id="post-icon"
                            :items="[
                            { name: 'Yes, set as a breaking news story', value: 1 },
                            { name: 'No, this is not a breaking news story', value: 0 },
                        ]"
                            item-text="name"
                            item-value="value"
                            v-model="updatedData.breakingNews"
                            solo
                            flat
                            :disabled="updatedData.postStatus != 'Published'"
                        >
                        </v-select>
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Only published news stories can be set as breaking. If another
                                post is currently set as breaking you will not be able to set
                                this.</i>
                        </p>
                    </v-flex>
                </v-layout>
                <v-layout
                    row
                    v-if="updatedData.breakingNews === 1"
                >
                    <v-flex
                        mr-5
                        class="half"
                    >
                        <label
                            class="label"
                            for="post-icon"
                        >Breaking Until...</label>
                        <datetime
                            v-model="updatedData.breakingNewsEnd"
                            type="datetime"
                            :min-datetime="todaysDate"
                            :minute-step="minuteStep.breakingNewsEnd"
                            :disabled="updatedData.breakingNews != 1"
                        ></datetime>
                        <!-- <p class="utc-note">This will save as {{ updatedData.breakingNewsEnd }}</p> -->
                    </v-flex>
                    <v-flex
                        mt-4
                        class="half"
                    >
                        <p>
                            <i>Determine how long this news post should be set as breaking
                                for. You can update this value while the story develops.</i>
                        </p>
                    </v-flex>
                </v-layout>
            </section>
            <breaking-news
                v-if="showBreakingNewsWidget"
                :showTitle="false"
            />
        </template>

        <h3 class="subheading">Publishing</h3>
        <section class="section-container">
            <v-layout
                row
                class="section-divider"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="enabled"
                    >Child targeted page?</label>
                    <v-select
                        id="enabled"
                        outline
                        v-model="updatedData.childFriendly"
                        item-text="name"
                        item-value="id"
                        :items="[
                            { name: 'Yes, remove inappropriate adverts', id: true },
                            { name: 'No, leave all adverts as they are', id: false },
                        ]"
                        solo
                        flat
                    ></v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        If your content is targeted towards children, turn
                        <b>Child Friendly on</b> to remove gambling adverts etc
                    </p>
                    <p>
                        <i>If this post is within a category set within a child friendly
                            category then this value will be ignored and the category value
                            will instead be used</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout
                row
                class="section-divider"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="enabled"
                    >Disable Advertising</label>
                    <v-select
                        id="enabled"
                        outline
                        v-model="updatedData.disableAdvertising"
                        item-text="name"
                        item-value="id"
                        :items="[
                            { name: 'Yes, remove all adverts', id: true },
                            { name: 'No, leave all adverts as they are', id: false },
                        ]"
                        solo
                        flat
                    ></v-select>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>Remove all advertising from the website version of this post</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-layout row>
                <v-flex
                    mr-2
                    class="narrow-column"
                >
                    <label
                        class="label"
                        for="post-status"
                    >Post Status</label>
                    <v-select
                        id="post-status"
                        v-bind:items="postStatusOptions"
                        v-model="updatedData.postStatus"
                        solo
                        flat
                    >
                    </v-select>
                </v-flex>
            </v-layout>

            <v-layout
                row
                v-if="updatedData.postStatus === 'Published'"
            >
                <v-flex
                    mr-2
                    class="narrow-column"
                >
                    <label
                        class="label"
                        for="schedule"
                    >Publish</label>
                    <v-select
                        id="schedule"
                        :disabled="livePagePublishedDate !== ''"
                        v-model="updatedData.scheduled"
                        item-text="name"
                        item-value="value"
                        :items="[
                            { name: 'Publish now', value: 0 },
                            { name: 'Schedule for future', value: 1 },
                        ]"
                        solo
                        flat
                        required
                        @change="resetDateTime()"
                    >
                    </v-select>
                </v-flex>

                <v-flex mr-2>
                    <template v-if="updatedData.scheduled">
                        <label
                            class="label"
                            for="post-published-date"
                        >To be published at (local time)</label>
                        <datetime
                            v-model="updatedData.publishedDateTime"
                            type="datetime"
                            :min-datetime="todaysDate"
                        ></datetime>
                        <p class="utc-note">
                            This will save as {{ updatedData.publishedDateTime }}
                        </p>
                    </template>

                    <template v-else>
                        <label
                            class="label"
                            for="post-published-date"
                        >Publish Date (local time)</label>
                        <datetime
                            v-model="updatedData.publishedDateTime"
                            type="datetime"
                            :max-datetime="todaysDate"
                        ></datetime>
                        <p class="utc-note">
                            This will save as {{ updatedData.publishedDateTime }}
                        </p>
                    </template>
                </v-flex>
            </v-layout>

            <v-layout
                row
                v-if="updatedData.postStatus === 'Published'"
            >
                <!-- New shit -->
                <v-flex
                    mr-2
                    class="narrow-column"
                >
                    <label
                        class="label"
                        for="post-unpublish"
                    >Is this story to be automatically unpublished?</label>
                    <v-select
                        id="post-unpublish"
                        :items="unpublishOptions"
                        v-model="updatedData.unschedule"
                        solo
                        flat
                    >
                    </v-select>
                </v-flex>
                <v-flex ml-2>
                    <template v-if="updatedData.unschedule">
                        <label
                            class="label"
                            for="post-unpublishdatetime"
                        >Unpublish Date (local time)</label>
                        <datetime
                            id="post-unpublishdatetime"
                            v-model="updatedData.unscheduleDateTime"
                            type="datetime"
                        ></datetime>
                        <p class="utc-note">
                            This will unpublish at {{ updatedData.unscheduleDateTime }}
                        </p>
                    </template>
                </v-flex>
            </v-layout>
            <v-checkbox
                v-if="featureConfig.default.OneFootballIntegration"
                v-model="updatedData.sendToOneFootball"
                label="Publish to One Football"
            ></v-checkbox>

            <div class="publish-notice">
                <!-- Last date publish -->
                <p
                    v-if="livePagePublishedDate !== ''"
                    class="published-notice__live"
                >
                    This post was published on <br />{{
                            displayNiceUTC(livePagePublishedDate)
                        }}
                    UTC <br />{{ livePagePublishedDate | moment("YYYY-MM-DD HH:mm") }}
                    Local
                </p>

                <!-- Publish messaging -->
                <p v-if="updatedData.postStatus === 'Draft' && livePagePublishedDate !== ''
                            ">
                    This post will be immediately unpublished
                </p>

                <p v-else-if="updatedData.postStatus === 'Draft'">
                    The post is a draft and will not be published upon save
                </p>

                <p v-else-if="updatedData.postStatus === 'Published' &&
                            livePagePublishedDate !== ''
                            ">
                    Saving the post will change the publish date to <br />{{
                            displayNiceUTC(updatedData.publishedDateTime)
                        }}
                    UTC <br />{{
                                updatedData.publishedDateTime | moment("YYYY-MM-DD HH:mm")
                            }}
                    Local
                </p>

                <p v-else-if="updatedData.postStatus === 'Published' && !updatedData.scheduled
                            ">
                    Saving the post will publish it immediately
                </p>

                <p v-else-if="updatedData.postStatus === 'Published' && updatedData.scheduled
                            ">
                    Saving the post will schedule it for publishing on <br />{{
                            displayNiceUTC(updatedData.publishedDateTime)
                        }}
                    UTC <br />{{
                                updatedData.publishedDateTime | moment("YYYY-MM-DD HH:mm")
                            }}
                    Local
                </p>

                <p v-else>
                    If you are seeing this message then please notify Urban Zoo as there
                    is a gap in our publishing messages
                </p>
            </div>
        </section>

        <SavedBy :saveData="saveData" />
    </section>
</template>

<script>
import axios from "axios";
import SavedBy from "@/components/Library/SavedBy";
import ImageUploader from "@/components/Library/ImageUploader";
import moment from "moment";
import BreakingNews from "../Library/BreakingNews.vue";

export default {
    name: "NewsConfiguration",

    components: {
        SavedBy,
        ImageUploader,
        BreakingNews,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
        saveData: {
            type: Object,
            required: true,
        },
        livePagePublishedDate: {
            type: String,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
    },

    watch: {
        updatedData: {
            handler: function () {
                this.updateData();
            },
            deep: true,
        },
    },

    data: function () {
        return {
            postStatusOptions: ["Published", "Draft"],
            unpublishOptions: [
                { text: "No, this article should remain published", value: 0 },
                { text: "Yes, unpublish at...", value: 1 },
            ],
            heroStyleOptions: ["Deep", "Shallow"],
            cardStyleOptions: ["Stack", "Highlight", "Cover"],
            dateModal: false,
            timeModal: false,
            isAltLang: false,
            updatedData: {},
            todaysDate: moment().utc().format(),
            valid: false,
            tagString: "",
            rules: {
                required: (value) => !!value || "Required.",
            },
            icons: [
                {
                    name: "None",
                    value: null,
                },
                {
                    name: "Article",
                    value: "article",
                },
                {
                    name: "Audio",
                    value: "audio",
                },
                {
                    name: "Gallery",
                    value: "gallery",
                },
                {
                    name: "Quiz",
                    value: "quiz",
                },
                {
                    name: "Video",
                    value: "video",
                },
            ],
            feedOptions: [
                { text: "Yes", value: 1 },
                { text: "No", value: 0 },
            ],
            feedWidthOptions: [
                { text: "Single", value: "single" },
                { text: "Double", value: "double" },
            ],
            featureConfig: null,
            minuteStep: {
                breakingNews: 5,
                publishedDateTime: 5,
            },
            showBreakingNewsWidget: false,
            editableSlug: false,
            client: null,
        };
    },

    created() {
        this.client = process.env.VUE_APP_CLUB_ID;
        this.featureConfig = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");
        const defaultSettings = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/defaultSettings.js");

        if (process.env.VUE_APP_SPORT == "football") {
            this.icons.push({
                name: "Match",
                value: "match",
            });
        }

        // Backend need to check if it's ok to do this
        // if (defaultSettings.default.datetimeMinuteStep) {
        //   if (defaultSettings.default.datetimeMinuteStep.newsConfigurationBreakingNews) {
        //     this.minuteStep.breakingNews = defaultSettings.default.datetimeMinuteStep.newsConfigurationBreakingNews
        //   }
        //   if (defaultSettings.default.datetimeMinuteStep.newsConfigurationPublishedDateTime) {
        //     this.minuteStep.publishedDateTime = defaultSettings.default.datetimeMinuteStep.newsConfigurationPublishedDateTime
        //   }
        // }
    },

    mounted() {
        this.updatedData = {
            postTitle: this.data.postTitle,
            postStatus: this.data.postStatus,
            postSummary: this.data.postSummary,
            postSlug: this.data.postSlug,
            postSlugSuffix: this.data.postSlugSuffix,
            postCategory: this.data.postCategory,
            postIcon: this.data.postIcon,
            breakingNews: this.data.breakingNews,
            breakingNewsEnd: this.data.breakingNewsEnd,
            heroStyleLarge: this.data.heroStyleLarge,
            heroStyleSmall: this.data.heroStyleSmall,
            postAuthor: this.data.postAuthor,
            publishedDateTime: this.data.publishedDateTime,
            scheduled: this.data.scheduled,
            unschedule: this.data.unschedule,
            unscheduleDateTime: this.data.unscheduleDateTime,
            mediaLibraryID: this.data.mediaLibraryID,
            heroSmallMediaLibraryID: this.data.heroSmallMediaLibraryID,
            listingMediaLibraryID: this.data.listingMediaLibraryID,
            childFriendly: this.data.childFriendly,
            disableAdvertising: this.data.disableAdvertising,
            showInFeed: this.data.showInFeed,
            feedWidth: this.data.feedWidth,
            cardStyle: this.data.cardStyle,
            sendToOneFootball: this.data.sendToOneFootball,
        };
        this.updateCategory();
        this.fetchBreakingStory();
    },

    methods: {
        sanitizeSlug() {
            this.updatedData.postSlugSuffix = this.updatedData.postSlugSuffix.replace(
                /[^a-zA-Z0-9]/g,
                "-"
            );
            this.updateData();
        },
        setSlug(title) {
            if (!this.updatedData.postSlugSuffix) {
                this.updatedData.postSlugSuffix = title
                    .replace(/[^a-zA-Z0-9]/g, "-")
                    .toLowerCase();
            }
        },
        displayNiceUTC(dateTime) {
            return moment(dateTime).utc().format("YYYY-MM-DD HH:mm");
        },

        resetDateTime() {
            this.updatedData.publishedDateTime = "";
            console.log("resetDateTime", this.updatedData.publishedDateTime);
        },

        utcHelpLink() {
            return `https://savvytime.com/converter/bst-to-utc/`;
        },

        updateImage(payload, target) {
            console.log("Update image:", payload, target);

            this.updatedData[target] = payload.mediaLibraryID;

            this.updateData();
        },

        updateCategory() {
            let isAltLang = false;

            for (const category of this.categories) {
                if (
                    category.languageCode &&
                    category.newsCategoryID === this.updatedData.postCategory
                ) {
                    isAltLang = category.newsCategory;
                    break;
                } else {
                    isAltLang = false;
                }
            }

            this.isAltLang = isAltLang;

            this.$emit("updateCategoryLanguage", isAltLang);

            for (const category of this.categories) {
                if (
                    category.childFriendly &&
                    category.newsCategoryID === this.updatedData.postCategory
                ) {
                    this.updatedData.childFriendly = category.childFriendly;
                    break;
                }
            }
        },

        updateData() {
            this.$emit("update", this.updatedData);
        },

        fetchBreakingStory() {
            // this.$store.commit('startLoading');
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${process.env.VUE_APP_NEWSAPI}/news/breaking`, {
                        headers: { Authorization: jwt },
                    })
                    .then((response) => {
                        if (response.data.body) {
                            if (response.data.body.postSlug != this.updatedData.postSlug) {
                                this.showBreakingNewsWidget = true;
                            } else {
                                this.showBreakingNewsWidget = false;
                            }
                        } else {
                            this.showBreakingNewsWidget = false;
                        }
                    })
                    .catch((e) => {
                        console.error("Problem retrieving breaking news", e);
                        this.$store.commit("completeLoading");
                    });
            });
        },
    },
};
</script>

<style
    lang="scss"
    scoped
>
.half {
    width: 50%;
}

.section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dfe3e9;
}

.data-note {
    padding: 4px;
    margin: -8px 0 32px;
    font-size: 12px;
    color: #fff;
    background-color: #ccc;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.narrow-column {
    max-width: 380px;
    width: 100%;
}

.cant-edit {
    opacity: 0.6;
}

.publish-notice {
    display: block;
    padding: 8px;
    margin: 0;
    width: 100%;
    font-size: 13px;
    text-align: center;
    border: 1px solid #ccc;
    border: 4px;

    p {
        color: #67247c;
    }

    &__last {
        opacity: 0.6;
        font-style: italic;
    }
}

.uploader {
    margin: 0 0 40px 0;

    &__heading {
        margin: 0 0 20px;
        color: #24002f;
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 500;
    }

    &__ctas {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
}

.disclaimer {
    font-size: 0.7em;
    font-weight: 400;
}

.images-wrap {
    padding: 8px 16px;
    margin-bottom: 16px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ebebeb;
}

.alt-lang-notice {
    margin-bottom: 32px;
    padding: 4px 8px;
    color: #000;
    font-size: 12px;
    text-align: center;
    background-color: color(warning);
    border: 1px solid darken(color(warning), 10%);
    z-index: 10;
}

.utc-warning {
    padding: 10px 10px 0;
    margin: 0;
    font-size: 12px;
    background-color: #f4f6d2;
    border: 1px solid #d6d8b0;
}
</style>
