<template>
  <v-dialog
    class="image-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
          Image
        </v-card-title>

        <v-card-text>

          <ImageUploader :title="'Image'" :mediaLibraryID="imageData.mediaLibraryID" :widthOptionEnabled="true" @update="updateImage($event)"/>

          <div v-if="widthOptionEnabled" class="image-widget__width">
            <input v-model="imageData.width" type="radio" class="image-widget__width-item image-widget__width-item--full" name="imageWidth" value="fullWidth" id="fullWidth"><label for="fullWidth"><span>Full width</span></label>
            <input v-model="imageData.width" type="radio" class="image-widget__width-item image-widget__width-item--wide" name="imageWidth" value="wideWidth" id="wideWidth"><label for="wideWidth"><span>Wide width</span></label>
            <input v-model="imageData.width" type="radio" class="image-widget__width-item image-widget__width-item--standard" name="imageWidth" value="standardWidth" id="standardWidth"><label for="standardWidth"><span>Standard width</span></label>
          </div>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="accent"
            outline
            flat
            @click="saveImage();"
          >
            Post
          </v-btn>
        </v-card-actions>
      </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"
import ImageUploader from "@/components/Library/ImageUploader"
import FolderSelector from "@/components/Media/FolderSelector.vue"

export default {
  name: "ImageWidget",

  components: {
    ImageUploader,
    FolderSelector
  },

  props: {
    widthOptionEnabled: {
      type: Boolean,
      default: true
    },
    widgetData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    dialog: false,
    imageData: {
      mediaLibraryID: null,
      width: "standardWidth",
    },
  }),

  mounted() {
    console.log("Image widget mounted", this.widgetData);

    if (this.widgetData) {
      this.imageData = JSON.parse(JSON.stringify(this.widgetData.image));
      console.log("this.imageData set", this.imageData)
    }
  },

  methods: {
    updateImage(payload) {
      console.log("updateImage", payload)
      this.imageData.mediaLibraryID = payload.mediaLibraryID
    },

    saveImage() {
      this.$emit("imageUploaded", this.imageData);
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>

  .single-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 30px;
    border-top: 1px solid #ebebeb;

    &__preview {
      margin: 10px 40px 0 30px;
      max-height: 80px;
      max-width: 80px;
    }
  }

  .select-folder-button {
    margin: 20px 0 0 !important;
  }

  .preview-list {
    border-bottom: 1px solid #DFE3E9;
  }

  .preview {
    display: block;
    margin: 0;
    padding: 15px 0 10px;
    border-top: 1px solid #DFE3E9;

    /deep/ .v-list__tile {
      padding: 0;
    }

    &__label {
      padding: 0;
      margin: 0 0 10px;
      font-size: 10px;
    }

    &__image {
      max-height: 56px;
      max-width: 56px;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    &__action {
      display: block;
      margin: 0 0 0 30px;
      cursor: pointer;
    }
  }

  .uploader {
    margin: 0 0 40px 0;

    &__heading {
      margin: 0 0 20px;
      color: #24002F;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 500;
    }

    &__upload-input {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      white-space: nowrap;
      width: 1px;
    }

    &__upload-button {
      cursor: pointer;
    }

    &__ctas {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .edit {
    margin: 0 10px 20px 0;
    width: 100%;
  }

  .tag-manager {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    &__tag {
      padding: 4px 15px;
      margin: 15px 5px 0 0;
      color: #fff;
      background-color: #B391BD;
      border-radius: 4px;
      cursor: pointer;
    }

    &__input {
      width: 100%;
    }
  }

  .image-widget {
    &__width {
      margin: 10px 0 30px;
      display: flex;
      justify-content: space-between;
      &-item {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        + label {
            cursor: pointer;
            text-align: center;
            &:before {
                content: '';
                width: 64px;
                height: 56px;
                display: block;
                cursor: pointer;
                margin: auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                opacity: 0.5;
            }
            span {
              font-size: 0.75rem;
              color: $purple;
              margin-top: 5px;
              display: block;
              border-radius: 100px;
              padding: 3px 6px;
            }
        }
        &:checked {
          + label{
            &:before {
                opacity: 1.0;
            }
            span {
              background-color: #67247c;
              color: white;
            }
          }
        }
        &--standard {
          + label:before {
            background-image: url('../../assets/standard.svg');
          }
        }
        &--wide {
          + label:before {
            background-image: url('../../assets/wide.svg');
          }
        }
        &--full {
          + label:before {
            background-image: url('../../assets/full-width.svg');
          }
        }
      }
    }
  }

</style>
