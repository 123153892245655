<template>
  <div class="video__list-item" v-if="updatedData.length">
    <div class="video__list-item-info">
      <template v-for="dataProperty in updatedData">
        <template v-if="dataProperty[0] != 'id'">
          <label class="label" :key="dataProperty.id">{{
            dataProperty[0]
          }}</label>
          <v-text-field
            :key="dataProperty.id"
            class="video__list-item-input"
            v-model="dataProperty[1]"
            :disabled="disabled"
            solo
            flat
            :placeholder="dataProperty[0]"
            height="20"
          ></v-text-field>
        </template>
      </template>
      <small class="video__list-item-type">{{ filterData.id }}</small>
    </div>

    <div class="video__list-item-actions">
      <button class="video__list-item-actions--edit" @click="editfilter()">
        Edit
      </button>
      <button class="video__list-item-actions--delete" @click="deletefilter()">
        Delete Filter
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FiltersCardV2",

  props: {
    filterData: {
      type: Object,
      required: true,
    },
  },

  watch: {
    updatedData: {
      handler: function () {
        this.cleanData();
      },
      deep: true,
    },
  },

  created() {
    this.updatedData = Object.entries(this.filterData);
  },

  data: () => ({
    updatedData: null,
    disabled: true,
  }),

  methods: {
    editfilter() {
      this.disabled = !this.disabled;
    },

    deletefilter() {
      var newVar = Object.fromEntries(this.updatedData);
      console.log("deletefilter", newVar);
      this.$emit("deletefilter", newVar);
    },

    cleanData() {
      for (var obj of this.updatedData) {
        console.log("obj", obj[1]);
        if (obj[1] === "") {
          obj[1] = null;
        }
      }
      this.$emit("dataChanged", Object.fromEntries(this.updatedData));
    },
  },
};
</script>

<style scoped lang='scss'>
</style>