<template>
  <section class="image-editor section-container">
    <ImageUploader mb-3 :title="'Leading Image'" :mediaLibraryID="this.data.leadingImageID" @update="updateImage($event)"/>

    <v-flex>
      <label class="label" for="summary">Summary</label>
      <v-textarea
        id="summary"
        v-model="updatedData.summary"
        solo
        flat
        required
        @change="updateData()"
      ></v-textarea>
    </v-flex>
  </section>
</template>

<script>
import axios from "axios"
import ImageUploader from "@/components/Library/ImageUploader"

export default {
  name: "VideoEditor",

  components: {
    ImageUploader
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      updatedData: {},
    }
  },

  mounted() {
    this.updatedData = {
      leadingImageID: this.data.leadingImageID,
      summary: this.data.summary
    }
  },

  methods: {
    updateData() {
      this.$emit("update", this.updatedData)
    },

    updateImage(payload) {
      console.log("Update image:", payload);

      this.updatedData.leadingImageID = payload

      this.updateData()
    }
  }
}
</script>
