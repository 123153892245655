<template>
  <section class="content-feed">
    <v-tabs v-model="active">
      <v-tab :ripple="{ class: 'accent--text' }">Settings</v-tab>

      <v-tab-item px-0 class="main-body">
        <template v-if="!promosReady">
          <p>Aggregated Feed Loading...</p>
        </template>
        <template v-else>
          <content-feed-settings
            :promoData="contentFeedPromos"
            @updatePromo="updatePromos($event)"
            @reloadPromos="getPromos()"
          />
        </template>
      </v-tab-item>

      <v-tab :ripple="{ class: 'accent--text' }">Content</v-tab>

      <v-tab-item px-0 class="main-body">
        <template v-if="feedReady">
          <content-feed-content
            :data="contentFeedData"
            @addingPost="addContent($event)"
            @error="saveError()"
          />
        </template>
        <p v-else>Loading...</p>
      </v-tab-item>
    </v-tabs>
  </section>
</template>

<script>
import axios from "axios";
import ContentFeedSettings from "../../components/ContentFeed/ContentFeedSettings";
import ContentFeedContent from "../../components/ContentFeed/ContentFeedContent";

export default {
  name: "ContentFeedManager",

  components: {
    ContentFeedSettings,
    ContentFeedContent,
  },

  data: () => ({
    feedReady: false,
    promosReady: false,
    contentFeedData: [],
    contentFeedPromos: [],
  }),

  mounted() {
    console.log(
      "Aggregated feed mounted",
      `${process.env.VUE_APP_FEEDAPI}/feed`
    );
    this.$store.commit("startLoading");
    this.getFeed();
    this.getPromos();
  },

  methods: {
    getFeed() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${process.env.VUE_APP_FEEDAPI}/feed?pageSize=100`, {
            headers: { Authorization: jwt },
          })

          .then((response) => {
            console.log("agg. feed received", response.data.body);
            this.contentFeedData = response.data.body;
            this.$store.commit("completeLoading");
            this.feedReady = true;
          })

          .catch((e) => {
            console.error("Problem retrieving feed", e);
            this.$store.commit("completeLoading");
          });
      });
    },
    getPromos() {
      this.$store.commit("startLoading");
      this.promosReady = false;
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${process.env.VUE_APP_FEEDAPI}/promos`, {
            headers: { Authorization: jwt },
          })
          .then((response) => {
            console.log("agg. feed promos received", response.data.body);
            this.contentFeedPromos = response.data.body;
            this.$store.commit("completeLoading");
            this.promosReady = true;
          })
          .catch((e) => {
            console.error("Problem retrieving promos", e);
            this.$store.commit("completeLoading");
            this.promosReady = true;
          });
      });
    },

    addContent(newItem) {
      this.errorMessage = "";

      console.log("adding post", newItem);
      // this.saveStatus = 'saving'
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_FEEDAPI}/feed/add`,
          headers: { Authorization: jwt },
          data: {
            contentType: newItem.contentType,
            content: newItem.content,
            width: newItem.width,
            mediaLibraryID: newItem.mediaLibraryID,
            imageKey: newItem.imageKey,
            publishDateTime: new Date().toISOString(),
          },
        })
          .then((response) => {
            console.log("saved content post", response);
            // this.saveStatus = 'saved'
            this.getFeed();
          })
          .catch((e) => {
            console.error("Problem creating content post", e);
            this.saveStatus = "needsSaving";
            this.errorMessage = "Problem creating post";
          });
      });
    },

    updatePromos(payload) {
      this.promosReady = false;
      this.$store.commit("startLoading");
      this.errorMessage = "";
      this.saveStatus = "saving";
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_FEEDAPI}/promos/update`,
          headers: { Authorization: jwt },
          data: {
            agcPromoID: payload.promo.agcPromoID,
            title: payload.promo.title != "" ? payload.promo.title : null,
            locations:
              payload.promo.locations != ""
                ? Array.isArray(payload.promo.locations)
                  ? payload.promo.locations
                  : payload.promo.locations.split(",").map(Number)
                : [],
            tracking:
              payload.promo.tracking != "" ? payload.promo.tracking : null,
            url: payload.promo.url != "" ? payload.promo.url : null,
            mediaLibraryID: payload.promo.mediaLibraryID,
            hideInApp: payload.promo.hideInApp ? true : false,
          },
        })
          .then((response) => {
            console.log("updated promo:", response);
            this.saveStatus = "saved";
            setTimeout(() => {
              this.getPromos();
            }, 2000);
            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem updating promo", e);
            this.saveStatus = "needsSaving";
            this.errorMessage = "Problem creating post";
            this.$store.commit("completeLoading");
          });
      });
    },

    saveError() {
      console.warn("error adding post");
    },
  },
};
</script>

<style scoped lang='scss'>
</style>
