<template>
    <section class="preview">
        <template v-if="widgetType === 'TextBlockWidget'">
            <div class="preview__header">
                <v-icon
                    large
                    color="#67247C"
                    class="preview__icon"
                >view_headline</v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                v-if="widgetData.content === null"
                class="preview__content"
                :class="{ widgetType }"
            >
                No content added
            </div>
            <div
                v-else
                class="preview__content"
                :class="{ widgetType }"
            >
                {{
                    widgetData.content.substring(0, 300)
                    ? widgetData.content.substring(0, 300)
                    : ""
                }}
                ...
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <TextBlockWidget
                    :widgetData="widgetData"
                    :includeDouble="widgetConfig.default.TextBlockWidget.includeDouble"
                    :customFont="widgetConfig.default.TextBlockWidget.customFont"
                    :availableFonts="widgetConfig.default.TextBlockWidget.availableFonts"
                    @updatedData="editWidget($event)"
                    class="preview__action-button"
                >Edit</TextBlockWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'LegacyImportWidget'">
            <div class="preview__header">
                <v-icon
                    large
                    color="#67247C"
                    class="preview__icon"
                >view_headline</v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                {{
                    widgetData.content.substring(0, 300)
                    ? widgetData.content.substring(0, 300)
                    : ""
                }}
                ...
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <LegacyImportWidget
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                    class="preview__action-button"
                >Edit</LegacyImportWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'ImageWidget'">
            <div
                class="preview__image-wrap"
                :style="`background-image: url('${imageSrc}');`"
            >
                <div class="preview__header">
                    <v-icon
                        large
                        color="#fff"
                        class="preview__icon"
                    >insert_photo</v-icon>
                </div>

                <div class="preview__actions">
                    <v-select
                        v-if="availableChannels"
                        class="preview__channels"
                        :value="widgetChannels"
                        placeholder="All available channels"
                        :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                        item-text="name"
                        item-value="value"
                        :items="availableChannels"
                        multiple
                        chips
                        @change="updateChannels($event)"
                    />

                    <button
                        class="preview__action-button"
                        type="button"
                        @click="deleteWidget()"
                    >
                        Delete
                    </button>
                    <ImageWidget
                        class="preview__action-button"
                        :widgetData="widgetData"
                        @imageUploaded="editImage($event)"
                    >Replace</ImageWidget>
                </div>
            </div>
        </template>

        <template v-else-if="widgetType === 'FileDownloadWidget'">
            <div class="preview__header">
                <v-icon
                    large
                    color="#67247C"
                    class="preview__icon preview__icon--custom preview__icon--file"
                ></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <p>{{ widgetData.title }}</p>
                <p v-if="widgetData.description">{{ widgetData.description }}</p>
                <p>
                    <a
                        :href="widgetData.adminFilePreview"
                        target="_blank"
                    >Preview File</a>
                </p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <FileDownloadWidget
                    class="preview__action-button"
                    @fileUploaded="editWidget($event)"
                >Replace</FileDownloadWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'ImageGalleryWidget'">
            <div class="preview__image-wrap">
                <div
                    class="preview__image-wrap"
                    :style="`background-image: url('${imageSrc}');`"
                >
                    <div class="preview__header">
                        <div class="preview__header-icon preview__header-icon--purple">
                            <v-icon
                                large
                                color="#fff"
                                class="preview__icon preview__icon--custom preview__icon--gallery"
                            ></v-icon><span>{{ widgetData.gallery.length }}</span>
                        </div>
                    </div>

                    <div class="preview__actions">
                        <v-select
                            v-if="availableChannels"
                            class="preview__channels"
                            :value="widgetChannels"
                            placeholder="All available channels"
                            :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                            item-text="name"
                            item-value="value"
                            :items="availableChannels"
                            multiple
                            chips
                            @change="updateChannels($event)"
                        />

                        <button
                            class="preview__action-button"
                            type="button"
                            @click="deleteWidget()"
                        >
                            Delete
                        </button>
                        <ImageGalleryWidget
                            :widgetData="widgetData"
                            class="preview__action-button"
                            @imageArrayUploaded="editImageGallery($event)"
                        >Replace or edit gallery</ImageGalleryWidget>
                    </div>
                </div>
            </div>
        </template>

        <!-- Deprecated Use V2 -->
        <template v-else-if="widgetType === 'ListWidget'">
            <div class="preview__header">
                <div class="preview__header-icon preview__header-icon--purple">
                    <v-icon
                        large
                        color="#fff"
                        class="preview__icon"
                    ></v-icon>
                    <span>{{ widgetData.list.length }}</span>
                </div>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>
            <!-- @TODO: Add each item's list title in the content preview -->
            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                {{ widgetData.list.title }}
            </div>
            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <ListWidget
                    :widgetData="widgetData"
                    class="preview__action-button"
                    @listUpload="editList($event)"
                >Replace or edit list</ListWidget>
            </div>
        </template>

        <!-- V2 -->
        <template v-else-if="widgetType === 'ListWidgetV2'">
            <div class="preview__header">
                <div class="preview__header-icon preview__header-icon--purple">
                    <v-icon
                        large
                        color="#fff"
                        class="preview__icon"
                    ></v-icon>
                    <span>{{ widgetData.listData.length }}</span>
                </div>
                <h4 class="preview__heading">
                    <span v-if="widgetData.listTitle">{{ widgetData.listTitle }} - </span>{{ widgetName }}
                </h4>
            </div>
            <!-- @TODO: Add each item's list title in the content preview -->
            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <h4 v-if="widgetData.listDescription">
                    Description : {{ widgetData.listDescription }}
                </h4>

                <span
                    v-for="(row, index) in widgetData.listData"
                    :key="`row-${index}`"
                >
                    <span v-if="row.title"><strong>{{ row.title }} : </strong></span>
                    <span v-if="row.content">
                        {{
                            row.content.substring(0, 120) ? row.content.substring(0, 120) : ""
                        }}
                        ...</span>
                    <span v-if="row.buttonLink"><strong>Links To - </strong>{{ row.buttonLink }}</span>
                    <hr />
                </span>
            </div>
            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <ListWidgetV2
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editList($event)"
                >
                    Edit list
                </ListWidgetV2>
            </div>
        </template>

        <template v-else-if="widgetType === 'AccordionWidget'">
            <div class="preview__header">
                <div class="preview__header-icon preview__header-icon--purple">
                    <v-icon
                        large
                        color="#fff"
                        class="preview__icon"
                    ></v-icon>
                    <span>{{ widgetData.content.length }}</span>
                </div>
                <h4 class="preview__heading">
                    {{ widgetData.accordionTitle }} - {{ widgetName }}
                </h4>
                <br />
            </div>
            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <h4 v-if="widgetData.accordionDescription">
                    Description : {{ widgetData.accordionDescription }}
                </h4>
                <hr />
                <span
                    v-for="(row, index) in widgetData.content"
                    :key="`row-${index}`"
                ><strong>{{ row.title }} : </strong>
                    {{ row.body ? row.body.substring(0, 120) + "..." : "" }}
                    <hr />
                </span>
            </div>
            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>

                <AccordionWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editAccordion($event)"
                >
                    Edit accordion
                </AccordionWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'AdvertisingWidget'">
            <div class="preview__header">
                <div class="preview__header-icon preview__header-icon--purple">
                    <icon-selector
                        class="header-icon widget-list__icon"
                        icon="Slot"
                    />
                </div>
                <h4 class="preview__heading">
                    {{ widgetName }}
                </h4>
                <br />
            </div>
            <div class="preview__content">
                <p>Advert: {{ widgetData && widgetData.title }}</p>
            </div>
            <div class="preview__actions">
                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>

                <AdvertisingWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editAccordion($event)"
                >
                    Edit Advert
                </AdvertisingWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'SupportersClubWidget'">
            <div class="preview__header">
                <div class="preview-header-wrapper">
                    <p class="label-name">{{ widgetName }}</p>

                    <div class="widget-label-wrapper">
                        <p class="label">Map:</p>
                        <p class="label-content">{{ widgetData.mapTitle }}</p>
                    </div>
                    <div class="widget-label-wrapper">
                        <p class="label">Style:</p>
                        <p class="label-content">{{ widgetData.mapStyleOption }}</p>
                    </div>
                </div>
                <br />
            </div>
            <div
                class="preview__content"
                :class="{ widgetType }"
            ></div>
            <div class="preview__actions">
                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <MapDirectoryWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editMapDirectory($event)"
                >
                    Edit Map Directory
                </MapDirectoryWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'QuoteWidget'">
            <div class="preview__header">
                <div class="preview__header-icon preview__header-icon--transparent">
                    <v-icon
                        large
                        class="preview__icon preview__icon--custom preview__icon--quote"
                    ></v-icon>
                </div>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>
            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                "{{ widgetData.text }}"
                <span v-if="widgetData.fullName"> by {{ widgetData.fullName }} </span>
            </div>
            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <QuoteWidget
                    :widgetData="widgetData"
                    class="preview__action-button"
                    @updatedData="editQuote($event)"
                >Edit quote</QuoteWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'LeagueTableWidget'">
            <div class="preview__header">
                <div class="preview__header-icon preview__header-icon--transparent">
                    <v-icon
                        large
                        class="preview__icon preview__icon--custom preview__icon--trophy"
                    ></v-icon>
                </div>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>
            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <div v-if="widgetData.competitionName">
                    Competition: {{ widgetData.competitionName }}
                </div>
                <div v-if="widgetData.season">Season: {{ widgetData.season }}</div>
                <p v-if="widgetData.showFullTable">Full Table</p>
                <template v-else>
                    <div v-if="widgetData.squadName">Team: {{ widgetData.squadName }}</div>
                    <div v-if="widgetData.totalTeams">
                        Teams displayed above and below: {{ widgetData.totalTeams }}
                    </div>
                </template>
            </div>
            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <LeagueTableWidgetEfl
                    v-if="client == 'efl'"
                    :widgetData="widgetData"
                    class="preview__action-button"
                    @updatedData="editLeagueTable($event)"
                >Edit league table</LeagueTableWidgetEfl>
                <LeagueTableWidget
                    v-else
                    :widgetData="widgetData"
                    class="preview__action-button"
                    @updatedData="editLeagueTable($event)"
                >Edit league table</LeagueTableWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'TweetWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--twitter"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            ></div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <SingleFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'Twitter'"
                    :widgetLabel="'Tweet ID'"
                >Edit</SingleFieldWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'InstagramWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--instagram"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            ></div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <SingleFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'Instagram'"
                    :widgetLabel="'Instagram Post ID'"
                >Edit</SingleFieldWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'SoundcloudWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--soundcloud"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            ></div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <SingleFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'Soundcloud'"
                    :widgetLabel="'Soundcloud Embed'"
                >Edit</SingleFieldWidget>
            </div>
        </template>

        <!-- <template v-else-if="widgetType === 'BuzzsproutWidget'">
      <div class="preview__header">
        <v-icon class="preview__icon preview__icon--custom preview__icon--buzzsprout"></v-icon>
        <h4 class="preview__heading">{{ widgetName }}</h4>
      </div>

      <div class="preview__content" :class="{widgetType}"></div>

      <div class="preview__actions">
        <button class="preview__action-button" type="button" @click="deleteWidget()">Delete</button>
        <SingleFieldWidget
          class="preview__action-button"
          @updatedData="editWidget($event)"
          :widgetData="widgetData"
          :widgetName="'Buzzsprout'"
          :widgetLabel="'Buzzsprout Embed'">Edit</SingleFieldWidget>
      </div>
    </template> -->

        <!-- V1 Youtube Widget has been deprecated in the admin.  Preview has to remain for legacy youtube embeds -->
        <template v-else-if="widgetType === 'YoutubeWidget'">
            <div class="preview__header">
                <v-icon
                    large
                    color="#67247C"
                    class="preview__icon preview__icon--custom preview__icon--youtube"
                ></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                {{ widgetData.title }} {{ widgetData.videoEmbed }}
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <MultiFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'Youtube'"
                    :fieldElements="[
                        { label: 'Title', name: 'title', type: 'single' },
                        { label: 'Youtube Embed', name: 'videoEmbed', type: 'multi' },
                    ]"
                >Edit</MultiFieldWidget>
            </div>
        </template>
        <!-- END -->

        <!-- YoutubeWidgetV2 -->
        <template v-else-if="widgetType === 'YoutubeWidgetV2'">
            <div class="preview__header">
                <v-icon
                    large
                    color="#67247C"
                    class="preview__icon preview__icon--custom preview__icon--youtube"
                ></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <span v-if="widgetData.title">{{ widgetData.title }} | </span>
                <span v-if="widgetData.videoID">{{ widgetData.videoID }} | </span>
                <span v-if="widgetData.hiddenVideo"> Login Required to view</span>
                <span v-else> No Login Required</span>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <MultiFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'YoutubeV2'"
                    :fieldElements="[
                        { label: 'Title', name: 'title', type: 'single' },
                        { label: 'Youtube ID', name: 'videoID', type: 'single' },
                        {
                            label: 'Hide Behind Login?',
                            name: 'hiddenVideo',
                            type: 'checkbox',
                        },
                    ]"
                >Edit</MultiFieldWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'VimeoWidget'">
            <div class="preview__header">
                <v-icon
                    large
                    color="#67247C"
                    class="preview__icon preview__icon--custom preview__icon--vimeo"
                ></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <span v-if="widgetData.title">{{ widgetData.title }} | </span>
                <span v-if="widgetData.videoID">{{ widgetData.videoID }} | </span>
                <span v-if="widgetData.hiddenVideo"> Login Required to view</span>
                <span v-else> No Login Required</span>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <MultiFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'Vimeo'"
                    :fieldElements="[
                        { label: 'Title', name: 'title', type: 'single' },
                        { label: 'Vimeo ID', name: 'videoID', type: 'single' },
                        {
                            label: 'Hide Behind Login?',
                            name: 'hiddenVideo',
                            type: 'checkbox',
                        },
                    ]"
                >Edit</MultiFieldWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'ClubTVWidget'">
            <div class="preview__header">
                <v-icon
                    large
                    color="#67247C"
                    class="preview__icon preview__icon--custom preview__icon--club-tv"
                ></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <p>
                    <b>{{ widgetData.title }}</b>
                </p>
                <p>Video ID: {{ widgetData.videoID }}</p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <MultiFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'Club TV'"
                    :fieldElements="[
                        { label: 'Title', name: 'title' },
                        { label: 'Club TV Video ID', name: 'videoID' },
                    ]"
                >Edit</MultiFieldWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'StreamlineEmbedWidget'">
            <div class="preview__header">
                <v-icon
                    large
                    color="#67247C"
                    class="preview__icon preview__icon--custom preview__icon--club-tv"
                ></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <p>
                    <b><span v-if="widgetData.title">{{ widgetData.title }}</span><span v-else>Streamline Embed</span></b>
                </p>
                <p>Video ID: {{ widgetData.id }}</p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <StreamlineEmbedWidget
                    :widgetData="widgetData"
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                >Edit Video</StreamlineEmbedWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'ThirdPartyWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--custom-code"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            ></div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <SingleFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'Third Party Widget'"
                    :widgetLabel="'Third Party Embed Code'"
                >Edit</SingleFieldWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'ThirdPartyV2Widget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--custom-code"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            ></div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <SingleFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'Third Party Widget (V2)'"
                    :widgetLabel="'Third Party Embed Code'"
                >Edit</SingleFieldWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'PlayerQuoteWidget'">
            <div class="preview__header">
                <v-icon
                    large
                    class="preview__icon preview__icon--custom preview__icon--quote"
                ></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                {{ widgetData.player }}: <em>"{{ widgetData.quote }}"</em>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <PlayerQuoteWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                >Edit</PlayerQuoteWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'FixturesListWidget'">
            <div class="preview__header">
                <div class="preview__header-icon preview__header-icon--purple">
                    <div class="v-icon preview__icon preview__icon--custom preview__icon--fixtures"></div>
                    <div class="preview__header-count">
                        {{ widgetData.fixtures.length }}
                    </div>
                </div>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div class="preview__content">
                <div
                    v-for="(match, matchIndex) in widgetData.fixtures"
                    :key="`match-${matchIndex}`"
                >
                    <div
                        v-for="(item, index) in match.matchDetails"
                        :key="`row-${index}`"
                    >
                        {{ item.matchLabel }}
                    </div>
                </div>
            </div>
            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <FixturesListWidget
                    :widgetData="widgetData"
                    class="preview__action-button"
                    @updatedData="editFixtureList($event)"
                >Replace or edit fixtures</FixturesListWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'UpcomingFixturesWidget'">
            <div class="preview__header">
                <div class="preview__header-icon preview__header-icon--transparent">
                    <v-icon
                        large
                        class="preview__icon preview__icon--custom preview__icon--upcoming-fixture"
                    ></v-icon>
                </div>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>
            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <div v-if="widgetData.nextFixtures">
                    <p>Next upcoming fixtures: {{ widgetData.nextFixtures }}</p>

                    <p v-if="widgetData.competitionName">
                        Competition: {{ widgetData.competitionName }}
                    </p>
                    <p v-if="widgetData.squadName">Team: {{ widgetData.squadName }}</p>
                </div>
                <div v-if="widgetData.homeAwayBoth">
                    Fixture type: {{ widgetData.homeAwayBoth }}
                </div>
                <div v-if="widgetData.teamName">
                    Team selection: {{ widgetData.teamName }}
                </div>
            </div>
            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <UpcomingFixturesWidgetEfl
                    v-if="client == 'efl'"
                    :widgetData="widgetData"
                    class="preview__action-button"
                    @updatedData="editUpcomingFixtures($event)"
                >Edit upcoming fixtures</UpcomingFixturesWidgetEfl>
                <UpcomingFixturesWidget
                    v-else
                    :widgetData="widgetData"
                    class="preview__action-button"
                    @updatedData="editUpcomingFixtures($event)"
                >Edit upcoming fixtures</UpcomingFixturesWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'AllFixturesWidget'">
            <div class="preview__header">
                <div class="preview__header-icon preview__header-icon--transparent">
                    <v-icon
                        large
                        class="preview__icon preview__icon--custom preview__icon--upcoming-fixture"
                    ></v-icon>
                </div>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>
            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <div v-if="widgetData">
                    <template v-if="client == 'efl'">
                        Showing all fixtures for competition
                        {{ widgetData.fixtures.competitionName }}, season
                        {{ widgetData.fixtures.season }}
                        {{
                            widgetData.fixtures.squadName
                            ? `, ${widgetData.fixtures.squadName}`
                            : null
                        }}
                    </template>
                    <template v-else>
                        Showing all fixtures for {{ widgetData.fixtures.squadName }} season
                        {{ widgetData.fixtures.season }}
                    </template>
                </div>
            </div>
            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <AllFixturesWidgetEfl
                    v-if="client == 'efl'"
                    :widgetData="widgetData"
                    class="preview__action-button"
                    @updatedData="editAllFixtures($event)"
                >Edit all fixtures</AllFixturesWidgetEfl>
                <AllFixturesWidget
                    v-else
                    :widgetData="widgetData"
                    class="preview__action-button"
                    @updatedData="editAllFixtures($event)"
                >Edit all fixtures</AllFixturesWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'ResultsWidget'">
            <div class="preview__header">
                <div class="preview__header-icon preview__header-icon--transparent">
                    <v-icon
                        large
                        class="preview__icon preview__icon--custom preview__icon--upcoming-fixture"
                    ></v-icon>
                </div>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>
            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <p>Competition: {{ widgetData.competitionName }}</p>
                <p>Season: {{ widgetData.season }}</p>
                <p>Number of results displayed: {{ widgetData.results }}</p>

            </div>
            <div class="preview__actions">
                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <MatchResultsWidgetEfl
                    v-if="client == 'efl'"
                    :widgetData="widgetData"
                    class="preview__action-button"
                    @updatedData="editAllFixtures($event)"
                >Edit results</MatchResultsWidgetEfl>

            </div>
        </template>

        <template v-else-if="widgetType === 'TriviaWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--trivia"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                {{ widgetData.title }} <br />
                {{ widgetData.text }}
                <p>Background Image</p>
                <img
                    class="preview__image"
                    :src="imageSrc"
                />
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />
                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <TriviaWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                >Edit</TriviaWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'BlogWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom">feed</v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <p>Blog Name: {{ widgetData.blogName }}</p>
                <p>Blog Title: {{ widgetData.blogTitle }}</p>
            </div>

            <div class="preview__actions">
                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <BlogWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                >Edit</BlogWidget>
            </div>
        </template>
        <template v-else-if="widgetType === 'ContactWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--envelope"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <span v-if="widgetData.title">{{ widgetData.title }}<br /></span>
                <span v-if="widgetData.text">{{ widgetData.text }}<br /></span>
                <span v-if="widgetData.text">Number - {{ widgetData.number }}<br /></span>
                <span v-if="widgetData.buttonLink">Email - {{ widgetData.buttonLink }}<br /></span>
                <span v-if="widgetData.disclaimerText">Disclaimer - {{ widgetData.disclaimerText }}</span>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <ContactWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                >Edit</ContactWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'PreviousMeetingsWidget'">
            <div class="preview__header">
                <v-icon
                    large
                    color="#67247C"
                    class="preview__icon preview__icon--custom preview__icon--pitch"
                ></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                Squad: {{ widgetData.teamName }}<br />
                Opposition: {{ widgetData.opponentsName }}
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <PreviousMeetingsWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                >Edit</PreviousMeetingsWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'PlayerStatsWidget'">
            <div class="preview__header">
                <v-icon
                    large
                    color="#67247C"
                    class="preview__icon preview__icon--custom preview__icon--stats"
                ></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                Player: {{ widgetData.playerName }}<br />
                Type of stat: {{ widgetData.statTypeName }}<br />
                Period: {{ widgetData.statPeriodName }}
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <PlayerStatsWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                >Edit</PlayerStatsWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'ClubPromoWidget'">
            <div class="preview__header">
                <v-icon
                    large
                    color="#67247C"
                    class="preview__icon preview__icon--custom preview__icon--promo"
                ></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="widgetType"
            >
                <span v-if="widgetData.title">Title - {{ widgetData.title }}<br /></span><br />
                <span v-if="widgetData.description">Description - {{ widgetData.description }}<br /></span>
                <span v-if="widgetData.buttonText">{{ widgetData.buttonText }}
                    <span v-if="widgetData.buttonLink">
                        - {{ widgetData.buttonLink }}</span></span>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <ClubPromoWidget
                    :widgetData="widgetData"
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                >Edit</ClubPromoWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'SporcleWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--sporcle"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            ></div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <SingleFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'Sporcle'"
                    :widgetLabel="'Sporcle Embed Code'"
                >Edit</SingleFieldWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'RiddleWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--sporcle"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            ></div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <SingleFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'Riddle'"
                    :widgetLabel="'Riddle Embed Code'"
                >Edit</SingleFieldWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'HorizontalLineWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--line"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <p v-if="widgetData"><strong>Style: </strong>{{ widgetData.style }}</p>
                <p v-else><strong>Style: </strong>Basic</p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <HorizontalLineWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >Edit
                </HorizontalLineWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'SharePriceWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--custom-code"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <!-- <div class="preview__content" :class="{widgetType}"></div> -->

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
            </div>
        </template>

        <template v-else-if="widgetType === 'SubscriptionsCTAWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--subscriptions"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <!-- <div class="preview__content" :class="{widgetType}"></div> -->

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
            </div>
        </template>

        <template v-else-if="widgetType === 'GoogleMapsWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--map"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <!-- <div class="preview__content" :class="{widgetType}"></div> -->

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <SingleFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'Google Maps'"
                    :widgetLabel="'Google Maps Embed Code'"
                >Edit</SingleFieldWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'MatterportWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--360"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <!-- <div class="preview__content" :class="{widgetType}"></div> -->

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <SingleFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'Matterport'"
                    :widgetLabel="'Matterport Embed Code'"
                >Edit</SingleFieldWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'UniqueWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon">star</v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <p>ID: {{ widgetData.content }}</p>
                <p>
                    Please contact your account manager at Urban Zoo for amendments to
                    this widget or additional Unique Widgets
                </p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
            </div>
        </template>

        <!-- New CTA Preview Bit -->
        <template v-else-if="widgetType === 'CtaWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--promo"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>
            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <div v-if="widgetData.title || widgetData.description">
                    <strong>{{ widgetData.title }} <br />{{ widgetData.description }}</strong><br />
                    <hr />
                </div>
                {{ widgetData.ctaText }} - {{ widgetData.ctaLink }}
                <hr v-if="widgetData.cta2Text" />
                {{ widgetData.cta2Text }} - {{ widgetData.cta2Link }}
            </div>
            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <CtaWidget
                    :widgetData="widgetData"
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                >Edit</CtaWidget>
            </div>
        </template>
        <!-- /New Cta Preview Bit -->

        <template v-else-if="widgetType === 'FormWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon">alternate_email</v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div class="preview__content">
                <p>{{ widgetData.content.form.name }}</p>
                <p>{{ widgetData.content.form.id }}</p>
                <p>{{ widgetData.content.sendTo }}</p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <FormWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >Edit</FormWidget>
            </div>
        </template>
        <template v-else-if="widgetType === 'DocWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon">description</v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div class="preview__content">
                <p>{{ widgetData.content.doc.name }}</p>
                <p>{{ widgetData.content.doc.id }}</p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <DocWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >Edit</DocWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'GoogleFormWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--google-form"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            ></div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <SingleFieldWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'Google Form'"
                    :widgetLabel="'Google Form Embed Code'"
                >Edit</SingleFieldWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'FormAssemblyWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--formassembly"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            ></div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <FormAssemblyWidget
                    class="preview__action-button"
                    @updatedData="editWidget($event)"
                    :widgetData="widgetData"
                    :widgetName="'FormAssemblyWidget'"
                    :widgetLabel="'FormAssembly Embed Code'"
                >Edit</FormAssemblyWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'PlayerOfTheMatchWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon">person</v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div class="preview__content">
                <p>Displayed title: {{ widgetData.content.title }}</p>
                <p>Season ID: {{ widgetData.content.seasonID }}</p>
                <p>Squad ID: {{ widgetData.content.squadID }}</p>
                <p>Match ID: {{ widgetData.content.matchID }}</p>
                <p>Require login: {{ widgetData.content.requireLogin }}</p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <PlayerOfTheMatchWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >Edit</PlayerOfTheMatchWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'EventsCalendarWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon">calendar_month</v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div class="preview__content">
                <p>Section Title: {{ widgetData.sectionTitle }}</p>
                <p>
                    Teams:
                    <span v-if="!widgetData.teams.length">N/A</span>
                    <template v-else>
                        <span
                            v-for="(team, index) in widgetData.teams"
                            :key="index"
                        ><span v-if="index > 0">, </span>{{ team.teamName }}</span>
                    </template>
                </p>
                <p>
                    Event types:
                    <span
                        v-for="(event, index) in widgetData.eventTypes"
                        :key="index"
                    ><span v-if="index > 0">, </span>{{ event.name }}</span>
                </p>
                <p v-if="modules.default.Streamline">
                    {{
                        widgetData.hasStreamingOnly
                        ? "Show only calendar items with streaming available"
                        : "Show any matching calendar item"
                    }}
                </p>
                <p>
                    {{
                        widgetData.allowUserFilter
                        ? "Allow user to filter events"
                        : "Do not allow user to filter events"
                    }}
                </p>
            </div>

            <div class="preview__actions">
                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <CalendarWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >Edit</CalendarWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'SingleCalendarItemWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon">calendar_month</v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>
            <div class="preview__content">
                <template v-if="widgetData && widgetData.data && widgetData.data.attributes">
                    <p>Title: {{ widgetData.data.attributes.title }}</p>
                    <p v-if="widgetData.data.attributes.description">
                        Description: {{ widgetData.data.attributes.description }}
                    </p>
                    <p>Type: {{ widgetData.data.attributes.type }}</p>
                </template>
                <p v-else>No item selected</p>
            </div>

            <div class="preview__actions">
                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <SingleCalendarItemWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >Edit</SingleCalendarItemWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'FootballBasicLineupWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon">people_alt</v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div class="preview__content">
                <p>
                    Snapshot taken at:
                    {{ widgetData.content.snapshotUTC | moment("kk:mm (Z) DD/MM/YY") }}
                </p>
                <p>Style: {{ widgetData.content.displayStyle }}</p>
                <p>Display Type: {{ widgetData.content.displayTeam }}</p>
                <p>Season ID: {{ widgetData.content.seasonID }}</p>
                <p>Team ID: {{ widgetData.content.teamID }}</p>
                <p>Match ID: {{ widgetData.content.matchID }}</p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <FootballBasicLineupWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >Edit</FootballBasicLineupWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'FootballBasicMatchStatsWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon">leaderboard</v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div class="preview__content">
                <!-- <p>Style: {{ widgetData.content.displayStyle }}</p> -->
                <p>
                    Snapshot taken at:
                    {{ widgetData.content.snapshotUTC | moment("kk:mm (Z) DD/MM/YY") }}
                </p>
                <p v-if="widgetData.content.competitionID">
                    Competition ID: {{ widgetData.content.competitionID }}
                </p>
                <p>Season ID: {{ widgetData.content.seasonID }}</p>
                <p>Team ID: {{ widgetData.content.teamID }}</p>
                <p>Match ID: {{ widgetData.content.matchID }}</p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <FootballBasicMatchStatsWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >Edit</FootballBasicMatchStatsWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'CompetitionStatsWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon">leaderboard</v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div class="preview__content">
                <p>Competition: {{ widgetData.competitionName }}</p>
                <p>Season: {{ widgetData.seasonID }}</p>
            </div>

            <div class="preview__actions">
                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <CompetitionStatsWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >Edit</CompetitionStatsWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'NewsCarouselWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon review__icon preview__icon--custom preview__icon--news-carousel"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div class="preview__content">
                <p><strong>Title: </strong>{{ widgetData.title }}</p>
                <p><strong>Category: </strong>{{ widgetData.category }}</p>
                <!-- <p><strong>Number to display : </strong>{{ widgetData.number }}</p> -->
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <NewsCarouselWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >Edit</NewsCarouselWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'NewsCategoryWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon review__icon preview__icon--custom preview__icon--news-stacker"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div class="preview__content">
                <p><strong>Title: </strong>{{ widgetData.title }}</p>
                <p><strong>Category: </strong>{{ widgetData.category }}</p>
                <p><strong>Number to display : </strong>{{ widgetData.number }}</p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <NewsCategoryWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >Edit</NewsCategoryWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'StripeProductWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon preview__icon--custom preview__icon--custom-code"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div
                class="preview__content"
                :class="{ widgetType }"
            >
                <p>Product Name: {{ widgetData.content.product.name }}</p>
                <p>Product ID: {{ widgetData.content.product.id }}</p>
                <p>Price Preview: {{ widgetData.content.price.createdName }}</p>
                <p>Price ID: {{ widgetData.content.price.id }}</p>
                <p v-if="widgetData.content.buttonText">
                    Button override: {{ widgetData.content.buttonText }}
                </p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>

                <StripeProductWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >
                    Edit
                </StripeProductWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'OrganisationsWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon review__icon preview__icon--custom preview__icon--organisations"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div class="preview__content">
                <p>
                    <strong>Organisation Type(s): </strong>
                    <span
                        class="org-name"
                        v-for="orgtype in widgetData.organisationType"
                        :key="orgtype.OrganisationTypeID"
                    >
                        {{ orgtype.name }}
                    </span>
                </p>
                <p><strong>Region: </strong>{{ widgetData.region }}</p>
                <p><strong>Display Type: </strong>{{ widgetData.displayType.name }}</p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <OrganisationsWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >Edit</OrganisationsWidget>
            </div>
        </template>

        <template v-else-if="widgetType === 'OrganisationDetailWidget'">
            <div class="preview__header">
                <v-icon class="preview__icon review__icon preview__icon--custom preview__icon--organisation-detail"></v-icon>
                <h4 class="preview__heading">{{ widgetName }}</h4>
            </div>

            <div class="preview__content">
                <p>
                    <strong>Title: </strong>
                    <span class="org-name">
                        {{ widgetData.title }}
                    </span>
                </p>
                <p><strong>Organisation: </strong>{{ widgetData.organisation.name }}</p>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
                <OrganisationDetailWidget
                    class="preview__action-button"
                    :widgetData="widgetData"
                    @updatedData="editWidget($event)"
                >Edit</OrganisationDetailWidget>
            </div>

            <!-- {{widgetData}} -->
        </template>

        <template v-else>
            <div class="preview__header">
                <h4 class="preview__heading preview__heading--error">Widget Error</h4>
            </div>

            <div class="preview__actions">
                <v-select
                    v-if="availableChannels"
                    class="preview__channels"
                    :value="widgetChannels"
                    placeholder="All available channels"
                    :label="`Target channels (available on ${displayAvailabilityFlags()})`"
                    item-text="name"
                    item-value="value"
                    :items="availableChannels"
                    multiple
                    chips
                    @change="updateChannels($event)"
                />

                <button
                    class="preview__action-button"
                    type="button"
                    @click="deleteWidget()"
                >
                    Delete
                </button>
            </div>
        </template>
    </section>
</template>

<script>
import axios from "axios";
import AccordionWidget from "@/components/Widgets/AccordionWidget";
import AdvertisingWidget from "@/components/Widgets/AdvertisingWidget";
import AllFixturesWidget from "@/components/Widgets/AllFixturesWidget";
import AllFixturesWidgetEfl from "@/components/Widgets/AllFixturesWidgetEfl";
import BlogWidget from "@/components/Widgets/BlogWidget";
import CalendarWidget from "@/components/Widgets/CalendarWidget";
import CompetitionStatsWidget from "@/components/Widgets/CompetitionStatsWidget";
import ContactWidget from "@/components/Widgets/ContactWidget";
import ClubPromoWidget from "@/components/Widgets/ClubPromoWidget";
import CtaWidget from "@/components/Widgets/CtaWidget";
import FileDownloadWidget from "@/components/Widgets/FileDownloadWidget";
import FixturesListWidget from "@/components/Widgets/FixturesListWidget";
import FootballBasicLineupWidget from "@/components/Widgets/FootballBasicLineupWidget";
import FootballBasicMatchStatsWidget from "@/components/Widgets/FootballBasicMatchStatsWidget";
import FormAssemblyWidget from "@/components/Widgets/FormAssemblyWidget";
import FormWidget from "@/components/Widgets/FormWidget";
import HorizontalLineWidget from "@/components/Widgets/HorizontalLineWidget";
import IconSelector from "@/components/Library/IconSelector";
import ImageWidget from "@/components/Widgets/ImageWidget";
import ImageGalleryWidget from "@/components/Widgets/ImageGalleryWidget";
import LeagueTableWidget from "@/components/Widgets/LeagueTableWidget";
import LeagueTableWidgetEfl from "@/components/Widgets/LeagueTableWidgetEfl";
import LegacyImportWidget from "@/components/Widgets/LegacyImportWidget";
import ListWidget from "@/components/Widgets/ListWidget";
import ListWidgetV2 from "@/components/Widgets/ListWidgetV2";
import MultiFieldWidget from "@/components/Widgets/MultiFieldWidget";
import MapDirectoryWidget from "@/components/Widgets/MapDirectoryWidget";
import MatchResultsWidgetEfl from "@/components/Widgets/MatchResultsWidgetEfl";
import NewsCategoryWidget from "@/components/Widgets/NewsCategoryWidget";
import NewsCarouselWidget from "@/components/Widgets/NewsCarouselWidget";
import OrganisationsWidget from "@/components/Widgets/OrganisationsWidget";
import OrganisationDetailWidget from "@/components/Widgets/OrganisationDetailWidget";
import PlayerOfTheMatchWidget from "@/components/Widgets/PlayerOfTheMatchWidget";
import PlayerQuoteWidget from "@/components/Widgets/PlayerQuoteWidget";
import PlayerStatsWidget from "@/components/Widgets/PlayerStatsWidget";
import PreviousMeetingsWidget from "@/components/Widgets/PreviousMeetingsWidget";
import QuoteWidget from "@/components/Widgets/QuoteWidget";
import SingleCalendarItemWidget from "@/components/Widgets/SingleCalendarItemWidget";
import SingleFieldWidget from "@/components/Widgets/SingleFieldWidget";
import SharePriceWidget from "@/components/Widgets/SharePriceWidget";
import StreamlineEmbedWidget from "@/components/Widgets/StreamlineEmbedWidget";
import StripeProductWidget from "@/components/Widgets/StripeProductWidget";
import TextBlockWidget from "@/components/Widgets/TextBlockWidget";
import TriviaWidget from "@/components/Widgets/TriviaWidget";
import UpcomingFixturesWidget from "@/components/Widgets/UpcomingFixturesWidget";
import UpcomingFixturesWidgetEfl from "@/components/Widgets/UpcomingFixturesWidgetEfl";
import DocWidget from "@/components/Widgets/DocWidget";

export default {
    name: "WidgetPreview",

    components: {
        AccordionWidget,
        AdvertisingWidget,
        AllFixturesWidget,
        AllFixturesWidgetEfl,
        BlogWidget,
        CalendarWidget,
        ClubPromoWidget,
        CompetitionStatsWidget,
        ContactWidget,
        CtaWidget,
        FileDownloadWidget,
        FixturesListWidget,
        FootballBasicLineupWidget,
        FootballBasicMatchStatsWidget,
        FormAssemblyWidget,
        FormWidget,
        HorizontalLineWidget,
        IconSelector,
        ImageWidget,
        ImageGalleryWidget,
        LegacyImportWidget,
        LeagueTableWidget,
        LeagueTableWidgetEfl,
        ListWidget,
        ListWidgetV2,
        MultiFieldWidget,
        MapDirectoryWidget,
        MatchResultsWidgetEfl,
        NewsCarouselWidget,
        NewsCategoryWidget,
        OrganisationsWidget,
        OrganisationDetailWidget,
        PlayerOfTheMatchWidget,
        PlayerQuoteWidget,
        PlayerStatsWidget,
        PreviousMeetingsWidget,
        QuoteWidget,
        SharePriceWidget,
        SingleCalendarItemWidget,
        SingleFieldWidget,
        StreamlineEmbedWidget,
        StripeProductWidget,
        TextBlockWidget,
        TriviaWidget,
        UpcomingFixturesWidget,
        UpcomingFixturesWidgetEfl,
        DocWidget
    },

    props: {
        widgetChannels: {
            type: Array,
            default: function () {
                return [];
            },
        },
        widgetName: {
            type: String,
            required: true,
        },
        widgetType: {
            type: String,
            required: true,
        },
        widgetData: {
            type: Object,
            required: true,
        },
        enableTargeting: {
            type: Boolean,
            required: true,
        },
    },

    data: () => ({
        client: process.env.VUE_APP_CLUB_ID,
        imageURL: process.env.VUE_APP_IMAGEURL,
        availableChannels: null,
        widgetConfig: null,
        updatedData: [],
        imageSrc: "",
        modules: null,
    }),

    created() {
        this.widgetConfig = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/widgets.js");
        this.modules = require("@/config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");
    },

    mounted() {
        console.log("Widget preview mounted: ", this.widgetData);
        if (this.widgetType === "ImageWidget") {
            this.fetchImage(this.widgetData.image.mediaLibraryID);
        } else if (this.widgetType === "ImageGalleryWidget") {
            this.fetchImage(this.widgetData.gallery[0].mediaLibraryID);
        } else if (this.widgetType === "TriviaWidget") {
            this.fetchImage(this.widgetData.imageID);
        }

        if (this.enableTargeting) {
            this.buildAvailabilityArray();
        }
    },

    methods: {
        buildAvailabilityArray() {
            const array = [];

            if (this.widgetConfig.default[this.widgetType].includedInApp) {
                array.push({ name: "App", value: "allow-app" });
            }

            if (this.widgetConfig.default[this.widgetType].includedInRSS) {
                array.push({ name: "RSS", value: "allow-rss" });
            }

            if (this.widgetConfig.default[this.widgetType].includedInWeb) {
                array.push({ name: "Web", value: "allow-web" });
            }

            console.log("Array", array);

            this.availableChannels = array;
        },

        displayAvailabilityFlags() {
            const availableChannels = this.availableChannels.map((item) => {
                return `${item.name}`;
            });

            return availableChannels.join(", ");
        },

        updateChannels($event) {
            this.$emit("updateChannels", $event);
        },

        editWidget(payload) {
            this.$emit("edit", payload);
        },

        deleteWidget() {
            this.$emit("delete");
        },

        editImage(payload) {
            console.log("Edit image: ", payload);
            const image = {
                image: {
                    mediaLibraryID: payload.mediaLibraryID,
                    width: payload.width,
                },
            };
            this.$emit("edit", image);
            console.log(payload.mediaLibraryID);
            this.fetchImage(payload.mediaLibraryID);
        },

        editImageGallery(payload) {
            console.log("Edit image gallery: ", payload);
            this.$emit("edit", payload);

            this.fetchImage(payload.gallery[0].mediaLibraryID);
        },

        editList(payload) {
            console.log("Edit list: ", payload);
            this.$emit("edit", payload);
        },

        editAccordion(payload) {
            console.log("Edit accordion: ", payload);
            this.$emit("edit", payload);
        },

        editAdvertising(payload) {
            console.log("Edit Advertising: ", payload);
        },

        editMapDirectory(payload) {
            console.log("Edit Map Directory: ", payload);
            this.$emit("edit", payload);
        },

        editFixtureList(payload) {
            console.log("Edit fixture list: ", payload);
            this.$emit("edit", payload);
        },

        editQuote(payload) {
            console.log("Edit quote: ", payload);
            this.$emit("edit", payload);
        },

        editLeagueTable(payload) {
            console.log("Edit league table: ", payload);
            this.$emit("edit", payload);
        },

        editUpcomingFixtures(payload) {
            console.log("Edit upcoming fixtures: ", payload);
            this.$emit("edit", payload);
        },

        editAllFixtures(payload) {
            console.log("Edit all fixtures: ", payload);
            this.$emit("edit", payload);
        },

        fetchImage(mediaLibraryID) {
            console.log("Fetch image");
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/media/${mediaLibraryID}`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log("Fetch image response:", response);

                        this.imageSrc = `${this.imageURL}/${response.data.body.key}`;
                    })
                    .catch((e) => {
                        console.error("Problem fetching image", e);
                    });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.preview {
    position: relative;
    padding: 0 0 60px;
    background-color: #eee6f1;
    border-radius: 4px;

    &__image-wrap {
        min-height: 500px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    &__channels {
        display: block;
        margin-right: 60px;
        padding-right: 10px;

        &:after {
            content: "";
            position: absolute;
            top: 15px;
            right: 0;
            height: 39px;
            border-right: 1px solid rgba(255, 255, 255, 0.3);
        }

        /deep/ {
            .v-icon {
                position: relative;
                top: 5px;
                color: #fff;
            }

            .v-label {
                top: 0;
            }

            .theme--light.v-chip {
                background-color: #fff;
            }

            ::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: rgba(255, 255, 255, 0.8) !important;
            }

            ::-moz-placeholder {
                /* Firefox 19+ */
                color: rgba(255, 255, 255, 0.8) !important;
            }

            :-ms-input-placeholder {
                /* IE 10+ */
                color: rgba(255, 255, 255, 0.8) !important;
            }

            :-moz-placeholder {
                /* Firefox 18- */
                color: rgba(255, 255, 255, 0.8) !important;
            }

            .v-chip {
                + ::-webkit-input-placeholder {
                    color: green !important;
                    opacity: 0 !important;
                }

                + ::-moz-placeholder {
                    /* Firefox 19+ */
                    color: green !important;
                    opacity: 0 !important;
                }

                + :-ms-input-placeholder {
                    /* IE 10+ */
                    color: green !important;
                    opacity: 0 !important;
                }

                + :-moz-placeholder {
                    /* Firefox 18- */
                    color: green !important;
                    opacity: 0 !important;
                }
            }
        }
    }

    &__image {
        height: 300px;
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 30px 30px 10px;

        &-icon {
            background-color: $purple;
            padding: 10px;
            display: flex;
            align-items: center;
            color: $white;
            border-radius: 3px;

            i {
                margin: 0 5px;
            }

            &--transparent {
                background-color: transparent;
            }

            &--purple {
                margin-right: 10px;
            }
        }

        &-count {
            padding-left: 10px;
        }
    }

    &__heading {
        color: #67247c;
        font-size: 32px;
        font-weight: 500;

        &--inline {
            padding-left: 20px;
        }

        &--error {
            color: red;
        }
    }

    &__icon {
        margin: 0 10px 0 -10px;
        color: #67247c;
        fill: #67247c;
        font-size: 55px;
    }

    &__content {
        margin: 20px 0 0;
        padding: 0 30px 40px;
        color: #7b6b80;
        font-weight: 300;

        &--sentacecase {
            text-transform: capitalize;
        }
    }

    &__actions {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 30px 0 0;
        padding: 10px 30px;
        height: 45px;
        color: #fff;
        background-color: #67247c;
        border-radius: 0 0 4px 4px;
        opacity: 0.9;
    }

    &__action-button {
        margin: 0 0 0 20px;
    }

    &__icon {
        &--custom {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            width: 32px;
            height: 32px;
        }

        &--quote {
            background-image: url("../../assets/quote.svg");
        }

        &--club-tv {
            background-image: url("../../assets/video-camera.svg");
        }

        &--youtube {
            background-image: url("../../assets/youtube.svg");
        }

        &--instagram {
            background-image: url("../../assets/instagram.svg");
        }

        &--threads {
            background-image: url("../../assets/threads.svg");
        }

        &--twitter {
            background-image: url("../../assets/x.svg");
        }

        &--trophy {
            background-image: url("../../assets/trophy.svg");
        }

        &--fixtures {
            background-image: url("../../assets/pitch-white.svg");
            margin: 0;
        }

        &--upcoming-fixture {
            background-image: url("../../assets/upcoming-fixtures.svg");
        }

        &--gallery {
            background-image: url("../../assets/gallery-white.svg");
        }

        &--trivia {
            background-image: url("../../assets/trivia.svg");
        }

        &--list {
            background-image: url("../../assets/list.svg");
        }

        &--list-white {
            background-image: url("../../assets/list-white.svg");
        }

        &--video {
            background-image: url("../../assets/video.svg");
        }

        &--custom-code {
            background-image: url("../../assets/custom.svg");
        }

        &--promo {
            background-image: url("../../assets/promotions.svg");
        }

        &--club {
            background-image: url("../../assets/club.svg");
        }

        &--pitch {
            background-image: url("../../assets/pitch.svg");
        }

        &--stats {
            background-image: url("../../assets/statistics.svg");
        }

        &--file {
            background-image: url("../../assets/file.svg");
        }

        &--envelope {
            background-image: url("../../assets/envelope.svg");
        }

        &--sporcle {
            background-image: url("../../assets/trivia.svg");
        }

        &--line {
            background-image: url("../../assets/vertical-align.svg");
        }

        &--buzzsprout {
            background-image: url("../../assets/buzzsprout.svg");
        }

        &--soundcloud {
            background-image: url("../../assets/soundcloud.svg");
        }

        &--map {
            background-image: url("../../assets/map.svg");
        }

        &--360 {
            background-image: url("../../assets/360.svg");
        }

        &--subscriptions {
            background-image: url("../../assets/subscriptions.svg");
        }

        &--vimeo {
            background-image: url("../../assets/vimeo.svg");
        }

        &--news {
            background-image: url("../../assets/news.svg");
        }

        &--news-stacker {
            background-image: url("../../assets/news--stacker.svg");
        }

        &--news-carousel {
            background-image: url("../../assets/news--carousel.svg");
        }

        &--formassembly {
            background-image: url("../../assets/formassembly.svg");
        }

        &--google-form {
            background-image: url("../../assets/google-forms.svg");
        }

        &--organisations {
            background-image: url("../../assets/multi-location.svg");
        }

        &--organisation-detail {
            background-image: url("../../assets/location.svg");
        }
    }
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 40px 0 0;

    &__button {
        margin: 0 30px;
    }
}

.org-name {
    border-right: 1px solid #67247c;
    margin: 0 0 0 4px;

    &:last-child {
        border-right: none;
    }
}

.org-name {
    border-right: 1px solid #67247c;
    margin: 0 0 0 4px;

    &:last-child {
        border-right: none;
    }
}

.widget-label-wrapper {
    display: flex;
}

.label {
    color: #757575;
    font-size: 18px;
    font-weight: 500;

    &-name {
        font-size: 24px;
        font-weight: 700;
        color: #6d3a83;
    }

    &-content {
        color: #757575;
        font-size: 18px;
        font-weight: 300;
        margin: 0 10px;
    }
}
</style>
