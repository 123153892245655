<template>
  <v-list-tile class="row">
    <v-list-tile-action class="row-handle">
      <v-icon>open_with</v-icon>
    </v-list-tile-action>

    <v-list-tile-action class="row-checkbox">
      <v-checkbox
        v-model="logoData.active"
        @change="updateLogo()"
      ></v-checkbox>
    </v-list-tile-action>

    <v-list-tile-content class="row-status">
      <v-list-tile-sub-title v-if="logoData.active">Active</v-list-tile-sub-title>
      <v-list-tile-sub-title v-else>Inactive</v-list-tile-sub-title>
    </v-list-tile-content>

    <v-list-tile-content class="row-image">
      <template v-if="imageSrc">
        <ImageWidget @imageUploaded="imageUploaded($event)">
          <v-icon class="row-image__edit">edit</v-icon>
        </ImageWidget>

        <img
          v-if="imageSrc"
          :src="imageSrc"
          contain
          height="50" />
      </template>

      <template v-else>
        <ImageWidget @imageUploaded="imageUploaded($event)">
          <v-btn
            type="button"
            depressed
          >
            <v-icon small>publish</v-icon>
            Add image
          </v-btn>
        </ImageWidget>
      </template>
    </v-list-tile-content>

    <v-list-tile-content class="row-link">
      <v-text-field
        placeholder="http://"
        solo
        flat
        required
        v-model="logoData.link"
        @blur="updateLogo()"
      ></v-text-field>
    </v-list-tile-content>

    <v-list-tile-content class="row-file">
      <v-list-tile-sub-title v-if="fileType">{{ fileType }}</v-list-tile-sub-title>
    </v-list-tile-content>

    <v-list-tile-action>
      <v-icon @click="deleteLogo()">delete</v-icon>
    </v-list-tile-action>

  </v-list-tile>
</template>

<script>
import axios from "axios"
import ImageWidget from "@/components/Widgets/ImageWidget"

export default {
  name: "LogoRow",

  components: {
    ImageWidget
  },

  props: ["rowData"],

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/media`,
    logoData: {
      logoID: "",
      active: false,
      link: "",
      mediaLibraryID: ""
    },
    imageSrc: null,
    fileType: null
  }),

  mounted() {
    this.logoData = this.rowData;
    console.log("Row mounted: ", this.logoData);

    if (this.logoData.mediaLibraryID !== "") {
      this.fetchImage();
    }
  },

  methods: {
    fetchImage() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/${this.logoData.mediaLibraryID}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Fetch image response:", response);

          this.imageSrc = response.data.body.location;
          this.fileType = response.data.body.fileType;
        })
        .catch(e => {
          console.error("Problem fetching image", e);
        });
      })
    },

    imageUploaded(payload) {
      console.log("Image uploaded from logo row:", payload);

      this.logoData.mediaLibraryID = payload;

      this.fetchImage();
    },

    updateLogo() {
      this.$emit("update", this.logoData);
    },

    deleteLogo() {
      this.$emit("delete");
    }
  }
}
</script>

<style lang="scss" scoped>
  .row {
    padding: 25px 0;
    border-bottom: 2px solid rgba(209,216,245,0.6);
    transition: all 0.5s, opacity 0.2s;

    &:first-child {
      border-top: 2px solid rgba(209,216,245,0.6);
    }

    & /deep/ .v-list__tile {
      padding: 0;
    }
  }

  .row-checkbox {
    flex-grow: 0;
    min-width: 0;
    width: 45px;
  }

  .row-status {
    flex-grow: 0;
    min-width: 0;
    width: 130px;
  }

  .row-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 0;
    min-height: 50px;
    min-width: 0;
    width: 200px;

    &__edit {
      margin: 0 10px 0 0;
    }
  }

  .row-link {
    flex-grow: 1;
    padding-right: 60px;

    & /deep/ .v-text-field {
      width: 100%;
    }
  }

  .row-file {
    flex-grow: 0;
    min-width: 0;
    width: 130px;
  }
</style>
