<template>
  <v-dialog
    class="text-block-widget"
    v-model="dialog"
    lazy
    persistent
    scrollable
    fullscreen
  >

    <slot slot="activator"></slot>

      <v-card v-if="dialog">
        <v-card-title
          class="widget__heading"
        >
          Legacy Text block
        </v-card-title>

        <v-card-text>
          <label class="label" for="text-block-content">Content</label>

          <div class="editor-container">
            <tinymce-editor v-model="updatedData.content"
              api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
              :init="{
              height: 'calc(90vh - 200px)',
              menubar: false,
              relative_urls: false,
              plugins: 'table link paste wordcount hr lists',
              toolbar:
                'undo redo | styleselect | bold italic | \
                link blockquote table | \
                hr | \
                bullist numlist | removeformat',
              resize: false,
              style_formats: [
                {title: 'Headers', items: [
                  //{title: 'Header 1', format: 'h1'},
                  {title: 'Header 2', format: 'h2'},
                  {title: 'Header 3', format: 'h3'},
                  {title: 'Header 4', format: 'h4'},
                  {title: 'Header 5', format: 'h5'},
                  {title: 'Header 6', format: 'h6'}
                ]},
                {title: 'Inline', items: [
                  {title: 'Bold', icon: 'bold', format: 'bold'},
                  {title: 'Italic', icon: 'italic', format: 'italic'},
                  {title: 'Underline', icon: 'underline', format: 'underline'},
                  //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                  //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                  //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                  //{title: 'Code', icon: 'code', format: 'code'}
                ]},
                {title: 'Blocks', items: [
                  {title: 'Paragraph', format: 'p'},
                  {title: 'Blockquote', format: 'blockquote'},
                  //{title: 'Div', format: 'div'},
                  //{title: 'Pre', format: 'pre'}
                ]},
                {title: 'Alignment', items: [
                  {title: 'Left', icon: 'alignleft', format: 'alignleft'},
                  {title: 'Center', icon: 'aligncenter', format: 'aligncenter'},
                  {title: 'Right', icon: 'alignright', format: 'alignright'},
                  //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
                ]},
                {title: 'Small', selector: 'p', classes: 'small'}
              ],
              style_formats_merge: false,
              block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote'
            }"></tinymce-editor>
          </div>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outline
            flat
            @click="cancel();"
          >
            Cancel
          </v-btn>

          <v-btn
            color="accent"
            outline
            flat
            @click="updateData(); dialog = false;"
          >
            Post
          </v-btn>
        </v-card-actions>
      </v-card>

  </v-dialog>
</template>

<script>
import Vue from "vue"
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "LegacyImportWidget",

  components: {
    "tinymce-editor": Editor,
  },

  props: {
    widgetData: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    dialog: false,
    editorUpdating: false,
    updatedData: {
      content: ""
    }
  }),
  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
    }
  },

  methods: {
    updateData() {
      console.log("Update data:", this.updatedData);
      this.$emit("updatedData", this.updatedData);
    },

    cancel() {
      console.log("Cancel");
      console.log("this.widgetData", this.widgetData);

      this.dialog = false;

      if (this.widgetData) {
        this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
      } else if (this.widgetData === undefined) {
        this.updatedData = {}
      }

    },
  }
}
</script>

<style>

</style>

<style lang="scss" scoped>
  .is-updating {
    opacity: 0.4;
  }
</style>
