<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <p class="widget-info">
        This widget displays selected Streamline packages.
      </p>

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()"
        >Use this widget</v-btn
      >
    </template>

    <div class="widget-fieldset" v-else>
      <label class="label">Title</label>
      <v-text-field
        id="widgetData.title"
        solo
        flat
        v-model="updatedData.widgetData.title"
      >
      </v-text-field>

      <label class="label">Style</label>
      <v-select
        id="widgetData.style"
        :items="styleOptions"
        solo
        flat
        v-model="updatedData.widgetData.style"
      >
      </v-select>

      <!-- It seems the section below, namely the call to action 1, has not been properly implemented -->
      <template v-if="false">
        <label class="label">Footer Style</label>
        <v-select
          id="widgetData.footerStyle"
          :items="styleOptions"
          solo
          flat
          v-model="updatedData.widgetData.footerStyle"
        >
        </v-select>

        <label class="label">Call to action 1 (voucher/promos): Text</label>
        <v-text-field
          id="widgetData.cta1Text"
          solo
          flat
          v-model="updatedData.widgetData.cta1Text"
        >
        </v-text-field>
        <label class="label"
          >Call to action 1 (voucher/promos): Button Text</label
        >
        <v-text-field
          id="widgetData.cta1ButtonCta"
          solo
          flat
          v-model="updatedData.widgetData.cta1ButtonCta"
        >
        </v-text-field>
        <label class="label"
          >Call to action 1 (voucher/promos): Button URL</label
        >
        <v-text-field
          id="widgetData.cta1ButtonLink"
          solo
          flat
          v-model="updatedData.widgetData.cta1ButtonLink"
        >
        </v-text-field>
        <label class="label">Call to action 2 (links): Text</label>
        <v-text-field
          id="widgetData.cta2Text"
          solo
          flat
          v-model="updatedData.widgetData.cta2Text"
        >
        </v-text-field>
        <label class="label">Call to action 2 (links): Button Text</label>
        <v-text-field
          id="widgetData.cta2ButtonCta"
          solo
          flat
          v-model="updatedData.widgetData.cta2ButtonCta"
        >
        </v-text-field>
        <label class="label">Call to action 2 (links): Button URL</label>
        <v-text-field
          id="widgetData.cta2ButtonLink"
          solo
          flat
          v-model="updatedData.widgetData.cta2ButtonLink"
        >
        </v-text-field>
      </template>

      <label class="label">No match passes message</label>
      <v-text-field
        id="widgetData.noMatchPassesText"
        solo
        flat
        v-model="updatedData.widgetData.noMatchPassesText"
      >
      </v-text-field>

      <label class="label">No subscriptions message</label>
      <v-text-field
        id="widgetData.noSubscriptionsText"
        solo
        flat
        v-model="updatedData.widgetData.noSubscriptionsText"
      >
      </v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "StreamlinePackages",

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent();
      },
      deep: true,
    },
  },

  props: {
    editData: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    styleOptions: [
      { text: "Light Mode", value: "light" },
      { text: "Dark Mode", value: "dark" },
    ],
    updatedData: {
      title: null,
      style: null,
      footerStyle: null,
      cta1Text: null,
      cta1ButtonCta: null,
      cta1ButtonLink: null,
      cta12ext: null,
      cta2ButtonCta: null,
      cta2ButtonLink: null,
      noMatchPassesText: null,
      noSubscriptionsText: null,
    },
  }),

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.editData));
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "StreamlinePackages";
      this.updatedData.widgetName = "Streamline Packages";
      this.updatedData.widgetID = this.$uuid.v1();
      this.updatedData.widgetData = {
        title: null,
        style: null,
        noDataText: null,
        packagesLink: null,
      };
      this.$emit("initialised");
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData);
    },
  },
};
</script>

<style scoped lang='scss'>
</style>
