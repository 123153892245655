<template>
  <section>
    <MiniHeader :heading="heading" :buttonText="'Add Logo'" :hasButton="true" @buttonClicked="addLogo()"/>

    <LogoHeadRow />
    <draggable
      v-model="updatedLogos">

      <transition-group name="list-complete">
        <LogoRow v-for="(row, index) in updatedLogos" :key="row.logoID" :rowData="row" @update="updateLogo($event, index)" @delete="deleteLogo(index)"/>
      </transition-group>

    </draggable>

  </section>
</template>

<script>
import axios from "axios"
import draggable from "vuedraggable"
import MiniHeader from "@/components/Library/MiniHeader"
import StickyFooter from "@/components/Library/StickyFooter"
import LogoRow from "@/components/GlobalSettings/LogoRow"
import LogoHeadRow from "@/components/GlobalSettings/LogoHeadRow"

export default {
  name: "PartnerLogos",

  components: {
    draggable,
    MiniHeader,
    StickyFooter,
    LogoRow,
    LogoHeadRow
  },
  
  props: {
    isHeaderLogos: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/config`,
    heading: "",
    status: "saved",
    errorMessage: "",
    data: {
      seo: {},
      headerLogos: [],
      footerLogos: [],
      clubContact: {}
    },
    updatedLogos: []
  }),

  mounted() {
    this.heading = this.isHeaderLogos ? "Header Partner Logos" : "Footer Partner Logos";

    this.fetchData();
  },

  methods: {
    fetchData() {
      this.errorMessage = "";

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("Fetch:", response);
    
          if (response.data.count > 0) {
            this.data = response.data.body;
          }

          if (this.isHeaderLogos) {
            this.updatedLogos = this.data.headerLogos
          } else {
            this.updatedLogos = this.data.footerLogos
          }
        })
        .catch(e => {
          console.error("Problem retrieving data", e);
          this.errorMessage = "Problem retrieving data";
        });
      })
    },

    logosUpdated() {
      this.status = "needsSaving";
    },

    saveData() {
       this.postData();

        if (this.isHeaderLogos) {
          this.data.headerLogos = this.updatedLogos
        } else {
          this.data.footerLogos = this.updatedLogos
        }
    },

    postData() {
      console.log("Going to post: ", this.data);
      this.status = "saving";
      this.errorMessage = ""
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}`,
          headers:  {"Authorization": jwt },
          data: this.data
        })
        .then(response => {
          console.log("Saved Logos:", response);
          this.status = "saved";
        })
        .catch(e => {
          console.error("Problem saving logos", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem saving logos"
        });
      })
    },

    addLogo() {
      console.log("Add Logo");
      this.logosUpdated();

      this.updatedLogos.unshift({
        logoID: this.$uuid.v1(),
        active: false,
        link: "",
        fileType: "",
        mediaLibraryID: ""
      })

      console.log("this.data", this.data);
    },

    updateLogo(payload, index) {
      this.logosUpdated();
      console.log("Updating logo: ", index, payload);
      this.updatedLogos[index] = payload;
    },

    deleteLogo(index) {
      this.logosUpdated();
      if (confirm("Are you sure you want to delete this item? (This will take effect when you save)")) {
        console.log("Delete Logo");
        this.updatedLogos.splice(index, 1);
      }
    },
  }

}
</script>
