<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <p class="widget-info">This widget adds a promo for EG a Pre Show and displays as a banner on your feed.  We suggest using a png with transparency for the image.</p>

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()">Use this widget</v-btn>
    </template>

    <div class="widget-fieldset" v-else>
      <label class="label">Title</label>
      <v-text-field
        id="widgetData.title"
        solo
        flat
        v-model="updatedData.widgetData.title">
      </v-text-field>

      <label class="label">Description</label>
      <v-text-field
        id="widgetData.title"
        solo
        flat
        v-model="updatedData.widgetData.description">
      </v-text-field>

      <label class="label">Style</label>
      <v-select
        id="widgetData.style"
        :items="styleOptions"
        solo
        flat
        v-model="updatedData.widgetData.style">
      </v-select>

      <label class="label">Button URL</label>
      <v-text-field
        id="widgetData.button"
        solo
        flat
        v-model="updatedData.widgetData.buttonLink">
      </v-text-field>

      <label class="label">Button Text</label>
      <v-text-field
        id="widgetData.buttonText"
        solo
        flat
        v-model="updatedData.widgetData.buttonText">
      </v-text-field>

      <label class="label">Button Style</label>
      <v-select
        id="widgetData.buttonStyle"
        :items="buttonStyleOptions"
        solo
        flat
        v-model="updatedData.widgetData.buttonStyle">
      </v-select>

      <ImageUploader :title="'Image'" :widthOptionEnabled="false" :mediaLibraryID="updatedData.widgetData.mediaLibraryID" @update="updateImage($event)" :desc="'<p>Recommended size TBC</p>'"/>

    </div>

  </div>

</template>

<script>
import ImageUploader from "@/components/Library/ImageUploader.vue"

export default {
  name: "VideoPromo",

  components: { 
    ImageUploader
  },

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent()
      },
      deep: true
    }
  },

  props: {
    editData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    styleOptions: [{text: "Light Mode", value: "light"}, {text: "Dark Mode", value: "dark"}],
    buttonStyleOptions: ["", "primary-light", "primary-dark", "ghost-light", "ghost-dark", "label-light", "label-dark"],
    updatedData: {
      title: null,
      style: null,
      buttonLink: null,
      buttonLinkText: null,
      buttonStyle: null,
      mediaLibraryID: null,
      mediaLibraryKey: null
    }
  }),

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.editData))
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "Promo"
      this.updatedData.widgetName = "Promo"
      this.updatedData.widgetID = this.$uuid.v1()
      this.updatedData.widgetData = {
        title: null,
        style: null,
        buttonLink: null,
        buttonLinkText: null,
        buttonStyle: null,
        mediaLibraryID: null,
        mediaLibraryKey: null
      }
      this.$emit("initialised")
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData)
    },

    updateImage(payload) {
      console.log("updateImage(payload, target)", payload )
      this.updatedData.widgetData.mediaLibraryID = payload.mediaLibraryID
      this.updatedData.widgetData.mediaLibraryKey = payload.imageKey
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
