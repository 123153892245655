<template>
    <section>
        <div class="match-picker">
            <v-select
                v-if="!currentSeason"
                class="item"
                :items="seasons"
                v-model="matchData.seasonID"
                label="Select Season"
                item-value="id"
                item-text="attributes.seasonName"
                :disabled="!seasons.length"
                @change="fetchCompetitions()"
            >
            </v-select>

            <v-select
                class="item"
                :items="competitions"
                v-model="matchData.competitionID"
                label="Select Competition"
                item-value="id"
                item-text="attributes.competitionName"
                :disabled="!competitions.length"
                @change="competitionOnly ? validate() : teamMandatory || allowTeam ? fetchTeams() : validate()"
            >
            </v-select>

            <template v-if="!competitionOnly">
                <v-checkbox
                    v-if="!teamMandatory"
                    id="allowTeam"
                    v-model="allowTeam"
                    @change="(matchData.teamID = null), fetchTeams(), validate()"
                    label="Filter by Team ?"
                />

                <v-select
                    v-if="teamMandatory || allowTeam"
                    class="item"
                    :items="teams"
                    v-model="matchData.teamID"
                    label="Select Team"
                    item-value="attributes.teams.teamID"
                    item-text="attributes.teams.teamName"
                    :disabled="!teams.length"
                    @change="includeMatch ? fetchMatches() : validate()"
                >
                </v-select>
                <v-select
                    v-if="includeMatch"
                    class="item item--match"
                    :items="matches"
                    return-object
                    :item-text="customCompetitionText"
                    v-model="matchData.matchID"
                    label="Select Match"
                    :disabled="!matches.length"
                    @change="includeDetailedMatch ? fetchDetailedMatch() : validate()"
                >
                </v-select>
            </template>
            <p
                v-if="loading || error"
                class="loading"
                :class="{ 'error-message': error }"
            >
                {{ loading ? "Loading..." : error }}
            </p>
        </div>
    </section>
</template>

<script>
import axios from "axios";

export default {
    name: "MatchPickerEfl",

    props: {
        includeMatch: {
            type: Boolean,
            default: true,
        },
        teamMandatory: {
            type: Boolean,
            default: true,
        },
        currentSeason: {
            type: Boolean,
            default: false,
        },
        includeDetailedMatch: {
            type: Boolean,
            default: false,
        },
        competitionOnly: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        matchData: {
            seasonID: null,
            competitionID: null,
            teamID: null,
            teamName: null,
            matchID: null,
            matchDetails: null,
        },
        allowTeam: false,
        seasons: [],
        competitions: [],
        teams: [],
        matches: [],
        fixturesConfig: null,
        matchesLoaded: false,
        multiClubMatchesApi: process.env.VUE_APP_MULTICLUBMATCHESAPI,
        loading: true,
        error: "",
    }),

    mounted() {
        this.fetchSeasons();
    },

    methods: {
        validate() {
            let team;
            if (this.matchData.teamID && (this.teamMandatory || this.allowTeam)) {
                team = this.teams.filter(
                    (item) => item.attributes.teams.teamID === this.matchData.teamID
                );
                this.matchData.teamName = team[0].attributes.teams.teamName;
            }

            const data = {
                seasonID: this.matchData.seasonID,
                competitionID: this.matchData.competitionID,
                matchDetails: this.matchData.matchDetails,
                teamID: null,
                teamName: null,
                competitionName: this.competitions.filter((comp) => {
                    if (comp.id == this.matchData.competitionID) {
                        return true;
                    }
                })[0],
            };

            if (data.competitionName && data.competitionName.attributes) {
                data.competitionName = data.competitionName.attributes.competitionName;
            }

            if (this.allowTeam || this.teamMandatory) {
                data.teamID = this.matchData.teamID;
                data.teamName = this.matchData.teamName;
            }

            if (this.includeMatch) {
                data.matchID = this.matchData.matchID
                    ? this.matchData.matchID.id
                    : null;
                data.matchData = this.matchData.matchID
                    ? this.matchData.matchID.attributes
                    : null;
            }

            this.$emit("updated", data);
        },

        fetchSeasons() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${this.multiClubMatchesApi}/seasons`, {
                        headers: { Authorization: jwt },
                    })
                    .then((response) => {
                        console.log("Get Seasons response: ", response.data);
                        this.seasons = response.data.data;

                        if (
                            this.currentSeason &&
                            response.data.data &&
                            response.data.data.length > 0
                        ) {
                            this.matchData.seasonID = response.data.data[0].id;
                            this.fetchCompetitions();
                        }
                    })
                    .catch((e) => {
                        this.error = "An error occurred. This data may not be available";
                        console.error("Problem retrieving seasons", e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        fetchCompetitions() {
            this.error = "";
            this.loading = true;
            this.competitions = [];
            this.teams = [];
            this.matches = [];
            this.matchData.competitionID = null;
            this.matchData.teamID = null;
            this.matchData.matchID = null;
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${this.multiClubMatchesApi}/competitions`, {
                        headers: { Authorization: jwt },
                    })
                    .then((response) => {
                        console.log("Get Competitions response: ", response.data);
                        this.competitions = response.data.data;
                    })
                    .catch((e) => {
                        this.error = "An error occurred. This data may not be available";
                        console.error("Problem retrieving seasons", e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        fetchTeams() {
            this.error = "";
            this.loading = true;
            this.teams = [];
            this.matches = [];
            this.matchData.teamID = null;
            this.matchData.matchID = null;
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(
                        `${this.multiClubMatchesApi}/teams?competitionID=${this.matchData.competitionID}&seasonID=${this.matchData.seasonID}`,
                        {
                            headers: { Authorization: jwt },
                        }
                    )
                    .then((response) => {
                        console.log("Get Teams response: ", response.data);
                        this.teams = response.data.data;
                    })
                    .catch((e) => {
                        this.error = "An error occurred. This data may not be available";
                        console.error("Problem retrieving seasons", e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        fetchMatches() {
            this.error = "";
            this.loading = true;
            this.matches = [];
            this.matchData.matchID = null;
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(
                        `${this.multiClubMatchesApi
                        }/matches?page.size=${1000}&competitionID=${this.matchData.competitionID
                        }&seasonID=${this.matchData.seasonID}&teamID=${this.matchData.teamID
                        }`,
                        {
                            headers: { Authorization: jwt },
                        }
                    )
                    .then((response) => {
                        console.log("Get Matches response: ", response.data);
                        this.matches = response.data.data;
                    })
                    .catch((e) => {
                        this.error = "An error occurred. This data may not be available";
                        console.error("Problem retrieving seasons", e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        fetchDetailedMatch() {
            this.error = "";
            this.loading = true;
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(
                        `${this.multiClubMatchesApi}/matches/${this.matchData.matchID.id}`,
                        {
                            headers: { Authorization: jwt },
                        }
                    )
                    .then((response) => {
                        console.log("Get Detailed Match response: ", response.data);
                        this.matchData.matchDetails = response.data.data;
                        this.validate();
                    })
                    .catch((e) => {
                        this.error = "An error occurred. This data may not be available";
                        console.error("Problem retrieving seasons", e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        customCompetitionText(item) {
            if (item && item.attributes && item.attributes.homeTeam) {
                return `${item.attributes.homeTeam.name} vs ${item.attributes.awayTeam.name}`;
            }
        },
    },
};
</script>

<style scoped lang='scss'>
.match-picker {
    padding: 0;
    border: 0;
    background-color: transparent;
    margin: 0;
}

.item {}

.loading {
    text-align: center;
}

.error-message {
    color: #f42323;
}
</style>
