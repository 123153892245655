<template>
  <section class="page-content">
    <v-tabs v-model="active">
      <v-tab :ripple="{ class: 'accent--text' }">Configuration</v-tab>
      <v-tab-item px-0 class="main-body">
        <PageConfiguration
          v-if="dataLoaded"
          :data="configData"
          :saveData="saveData"
          @update="updateConfigData($event)"
        />
      </v-tab-item>

      <v-tab
        v-if="!isExternal && !isInternalLink"
        :ripple="{ class: 'accent--text' }"
        >SEO</v-tab
      >
      <v-tab-item px-0 class="main-body">
        <EditSEO
          v-if="dataLoaded"
          :data="seoData"
          :saveData="saveData"
          @update="updateSeoData($event)"
        />
      </v-tab-item>

      <v-tab
        v-if="!isExternal && !isInternalLink"
        :ripple="{ class: 'accent--text' }"
        >Content</v-tab
      >
      <v-tab-item px-0 class="main-body">
        <ContentBuilder
          v-if="dataLoaded && configData.pageTemplate === 'Default'"
          :data="content"
          :title="configData.postTitle"
          :saveData="saveData"
          :template="configData.pageTemplate"
          :enableTargeting="false"
          @update="updateContentData($event)"
          @statusChange="status = 'needsSaving'"
        />

        <section
          class="header"
          v-if="dataLoaded && configData.pageTemplate === 'Landing'"
        >
          <h2 class="subheading">Landing Page</h2>

          <v-flex mt-4>
            <label class="label" for="page-intro">Intro</label>

            <tinymce-editor
              v-model="landingPageIntro"
              api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
              :init="{
                height: '500px',
                menubar: false,
                plugins: 'table link paste wordcount hr lists textpattern',
                toolbar:
                  'undo redo | styleselect | bold italic | \
                link blockquote | \
                hr | \
                bullist numlist | removeformat',
                resize: false,
                textpattern_patterns: [
                  {
                    start: '//button',
                    replacement: '<a class=\'button \'>Button here</a>',
                  },
                ],
                link_class_list: [
                  { title: 'None', value: '' },
                  {
                    title: 'Primary on Dark',
                    value: 'button button--primary-dark',
                  },
                  {
                    title: 'Primary on Light',
                    value: 'button button--primary-light',
                  },
                  {
                    title: 'Ghost on Dark',
                    value: 'button button--ghost-dark',
                  },
                  {
                    title: 'Ghost on Light',
                    value: 'button button--ghost-light',
                  },
                  {
                    title: 'Label on Dark',
                    value: 'button button--label-dark',
                  },
                  {
                    title: 'Label on Light',
                    value: 'button button--label-light',
                  },
                ],
                style_formats: [
                  {
                    title: 'Headers',
                    items: [
                      //{title: 'Header 1', format: 'h1'},
                      { title: 'Header 2', format: 'h2' },
                      { title: 'Header 3', format: 'h3' },
                      { title: 'Header 4', format: 'h4' },
                      { title: 'Header 5', format: 'h5' },
                      { title: 'Header 6', format: 'h6' },
                    ],
                  },
                  {
                    title: 'Inline',
                    items: [
                      { title: 'Bold', icon: 'bold', format: 'bold' },
                      { title: 'Italic', icon: 'italic', format: 'italic' },
                      {
                        title: 'Underline',
                        icon: 'underline',
                        format: 'underline',
                      },
                      //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                      //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                      //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                      //{title: 'Code', icon: 'code', format: 'code'}
                    ],
                  },
                  {
                    title: 'Blocks',
                    items: [
                      { title: 'Paragraph', format: 'p' },
                      { title: 'Blockquote', format: 'blockquote' },
                      //{title: 'Div', format: 'div'},
                      //{title: 'Pre', format: 'pre'}
                    ],
                  },
                  {
                    title: 'Alignment',
                    items: [
                      { title: 'Left', icon: 'alignleft', format: 'alignleft' },
                      {
                        title: 'Center',
                        icon: 'aligncenter',
                        format: 'aligncenter',
                      },
                      {
                        title: 'Right',
                        icon: 'alignright',
                        format: 'alignright',
                      },
                      //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
                    ],
                  },
                  { title: 'Small', selector: 'p', classes: 'small' },
                ],
                style_formats_merge: false,
                block_formats:
                  'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote',
              }"
            ></tinymce-editor>
          </v-flex>
        </section>

        <HubBuilder
          v-if="dataLoaded && configData.pageTemplate === 'Hub'"
          :data="content"
          :childIds="backgroundData.childIds"
          :title="configData.postTitle"
          :saveData="saveData"
          :template="configData.pageTemplate"
          @update="updateContentData($event)"
          @statusChange="status = 'needsSaving'"
        />
      </v-tab-item>

      <template v-if="configData.postType === 'homepage'">
        <v-tab :ripple="{ class: 'accent--text' }">Homepage Manager</v-tab>
        <v-tab-item px-0 class="main-body">
          <p>Homepage manager - not available yet</p>
        </v-tab-item>
      </template>

      <template v-if="advancedMode">
        <v-tab :ripple="{ class: 'accent--text' }">Advanced Mode</v-tab>
        <v-tab-item px-0 class="main-body">
          <h3 class="subheading">JSON content entry</h3>
          <section class="section-container">
            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="config-data">configData*</label>
                <v-textarea
                  id="config-data"
                  v-model="configDataString"
                  solo
                  flat
                  @change="updateAdvanced()"
                ></v-textarea>
              </v-flex>
              <v-flex mt-4 class="half">
                <p><i>*Required - configData object as JSON</i></p>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="seo-data">seoData*</label>
                <v-textarea
                  id="seo-data"
                  v-model="seoDataString"
                  solo
                  flat
                  @change="updateAdvanced()"
                ></v-textarea>
              </v-flex>
              <v-flex mt-4 class="half">
                <p><i>*Required - seoData object as JSON</i></p>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-5 class="half">
                <label class="label" for="content-data">content*</label>
                <v-textarea
                  id="content-data"
                  v-model="contentString"
                  solo
                  flat
                  @change="updateAdvanced()"
                ></v-textarea>
              </v-flex>
              <v-flex mt-4 class="half">
                <p><i>*Required - configData object as JSON</i></p>
              </v-flex>
            </v-layout>
          </section>
        </v-tab-item>
      </template>

      <v-tab
        v-if="
          !isExternal &&
          configData.postStatus === 'Published' &&
          !isInternalLink
        "
        :ripple="{ class: 'accent--text' }"
        >Analytics</v-tab
      >
      <v-tab-item px-0 class="main-body">
        <fathom-page-analytics :path="configData.fullPath" />
      </v-tab-item>
    </v-tabs>

    <StickyFooter
      :errorMessage="errorMessage"
      :status="status"
      :buttonText="'Save Page'"
      @buttonClicked="savePage()"
      :allowPreview="isExternal || isInternalLink ? false : true"
      @previewRequested="previewPost()"
    />
  </section>
</template>

<script>
import axios from "axios";
import ContentBuilder from "@/components/Library/ContentBuilder";
import Editor from "@tinymce/tinymce-vue";
import EditSEO from "@/components/Library/EditSEO";
import FathomPageAnalytics from "@/components/Library/Fathom/FathomPageAnalytics";
import HubBuilder from "@/components/Pages/HubBuilder";
import PageConfiguration from "@/components/Pages/PageConfiguration";
import StickyFooter from "@/components/Library/StickyFooter";
import * as Sentry from "@sentry/browser";

export default {
  name: "PageContent",

  components: {
    "tinymce-editor": Editor,
    ContentBuilder,
    EditSEO,
    FathomPageAnalytics,
    HubBuilder,
    PageConfiguration,
    StickyFooter,
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/pages`,
    preview: `${process.env.VUE_APP_BASEURL}/pages/sendintopreview`,
    active: null,
    dataLoaded: false,
    status: "saved",
    errorMessage: "",
    saveData: {
      author: "AUTHOR NOT SET",
      authorImage: "/favicon-32x32.png",
      lastSave: null,
    },
    advancedMode: false,
    isExternal: false,
    isInternalLink: false,
    configDataString: null,
    seoDataString: null,
    contentString: null,
    landingPageIntro: null,
    configData: {
      postID: null,
      postTitle: null,
      menuPostTitle: null,
      postSummary: undefined,
      postSlug: null,
      postType: "internal",
      fullPath: undefined,
      linkSlug: undefined,
      postStatus: "Draft",
      pageTemplate: "Default",
      heroStyleLarge: "Deep",
      heroStyleSmall: "Deep",
      showHeroImages: false,
      publishedDate: null,
      publishedTime: null,
      mediaLibraryID: null,
      heroSmallMediaLibraryID: null,
      listingMediaLibraryID: null,
      childFriendly: false,
      disableAdvertising: false,
      customTag: null,
      customDataField1: null,
      customDataField2: null,
      customDataField3: null,
    },
    backgroundData: {
      childIds: null,
      fullPath: null,
      nestedLevel: null,
      order: null,
      pageOrder: null,
      parentID: null,
      rootPath: null,
    },
    seoData: {
      metaTitle: null,
      keywords: null,
      description: null,
    },
    content: [],
    publishedDateTime: null,
    tagsData: {
      fixtureTags: [],
      playerTags: [],
      squadTags: [],
    },
  }),

  mounted() {
    console.log("Edit page mounted");
    this.fetchPage();

    const userCreds = this.$store.state.user.email.split("@");
    if (userCreds[1] === "urbanzoo.io") {
      this.advancedMode = true;
    }
  },

  methods: {
    fetchPage() {
      this.errorMessage = "";
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${this.api}/${this.$route.params.id}`, {
            headers: { Authorization: jwt },
          })
          .then((response) => {
            console.log("fetchData response: ", response);

            this.landingPageIntro = response.data.body.landingPageIntro;

            this.configData = {
              postID: response.data.body.postID,
              postTitle: response.data.body.postTitle,
              menuPostTitle: response.data.body.menuPostTitle,
              postSlug: response.data.body.postSlug,
              postType: response.data.body.postType,
              fullPath: response.data.body.fullPath,
              linkSlug: response.data.body.linkSlug,
              postStatus: response.data.body.postStatus,
              postSummary: response.data.body.postSummary,
              pageTemplate: response.data.body.pageTemplate,
              heroStyleLarge: response.data.body.heroStyleLarge,
              heroStyleSmall: response.data.body.heroStyleSmall,
              publishedDate: response.data.body.publishedDate,
              publishedTime: response.data.body.publishedTime,
              mediaLibraryID: response.data.body.mediaLibraryID,
              heroSmallMediaLibraryID:
                response.data.body.heroSmallMediaLibraryID,
              listingMediaLibraryID: response.data.body.listingMediaLibraryID,
              showHeroImages: response.data.body.showHeroImages,
              childFriendly: response.data.body.childFriendly,
              disableAdvertising: response.data.body.disableAdvertising,
              customTag: response.data.body.customTag,
              externalUrl: response.data.body.externalUrl,
              newTab: response.data.body.newTab,
              customDataField1: response.data.body.customDataField1,
              customDataField2: response.data.body.customDataField2,
              customDataField3: response.data.body.customDataField3,
            };

            if (response.data.body.postStatus === "New") {
              this.configData.postStatus = "Draft";
            }

            this.seoData = {
              metaTitle: response.data.body.metaTitle,
              keywords: response.data.body.keywords,
              description: response.data.body.description,
              customSchema: response.data.body.customSchema,
            };

            this.saveData = {
              lastSave: response.data.body.savedTimestamp,
            };

            (this.backgroundData = {
              childIds: response.data.body.childIds,
              fullPath: response.data.body.fullPath,
              nestedLevel: response.data.body.nestedLevel,
              order: response.data.body.order,
              pageOrder: response.data.body.pageOrder,
              parentID: response.data.body.parentID,
              rootPath: response.data.body.rootPath,
            }),
              (this.content = response.data.body.content);

            if (this.content === undefined || this.content === null) {
              this.content = [];
            }

            if (this.configData) {
              this.configDataString = JSON.stringify(this.configData);
            }

            if (this.seoData) {
              this.seoDataString = JSON.stringify(this.seoData);
            }

            if (this.content) {
              this.contentString = JSON.stringify(this.content);
            }

            this.dataLoaded = true;
            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem retrieving page: ", e);
            this.$store.commit("completeLoading");
            this.errorMessage = "Problem retrieving page";
          });
      });
    },

    savePage() {
      console.log("Going to save: ", this.configData.postID);
      this.status = "saving";
      this.errorMessage = "";

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/${this.configData.postID}`,
          headers: { Authorization: jwt },
          data: {
            postID: this.configData.postID,
            postTitle: this.configData.postTitle,
            menuPostTitle: this.configData.menuPostTitle,
            postStatus: this.configData.postStatus,
            postType: this.configData.postType,
            pageTemplate: this.configData.pageTemplate,
            heroStyleLarge: this.configData.heroStyleLarge,
            heroStyleSmall: this.configData.heroStyleSmall,
            postSlug: this.configData.postSlug,
            linkSlug: this.configData.linkSlug,
            externalUrl: this.configData.externalUrl,
            newTab: this.configData.newTab,
            postSummary:
              this.configData.postSummary != ""
                ? this.configData.postSummary
                : null,
            publishedDate:
              this.configData.publishedDate != ""
                ? this.configData.publishedDate
                : null,
            publishedTime:
              this.configData.publishedTime != ""
                ? this.configData.publishedTime
                : null,
            mediaLibraryID:
              this.configData.mediaLibraryID != ""
                ? this.configData.mediaLibraryID
                : null,
            heroSmallMediaLibraryID:
              this.configData.heroSmallMediaLibraryID != ""
                ? this.configData.heroSmallMediaLibraryID
                : null,
            listingMediaLibraryID:
              this.configData.listingMediaLibraryID != ""
                ? this.configData.listingMediaLibraryID
                : null,
            showHeroImages: this.configData.showHeroImages,
            childFriendly: this.configData.childFriendly,
            disableAdvertising: this.configData.disableAdvertising,
            customTag:
              this.configData.customTag != ""
                ? this.configData.customTag
                : null,
            keywords:
              this.seoData.keywords != "" ? this.seoData.keywords : null,
            description:
              this.seoData.description != "" ? this.seoData.description : null,
            metaTitle:
              this.seoData.metaTitle != "" ? this.seoData.metaTitle : null,
            customSchema:
              this.seoData.customSchema != ""
                ? this.seoData.customSchema
                : null,
            landingPageIntro:
              this.landingPageIntro != "" ? this.landingPageIntro : null,
            content: this.content,
            childIds: this.backgroundData.childIds,
            fullPath: this.backgroundData.fullPath,
            nestedLevel: this.backgroundData.nestedLevel,
            order: this.backgroundData.order,
            pageOrder: this.backgroundData.pageOrder,
            parentID: this.backgroundData.parentID,
            rootPath: this.backgroundData.rootPath,
            customDataField1: this.configData.customDataField1,
            customDataField2: this.configData.customDataField2,
            customDataField3: this.configData.customDataField3,
          },
        })
          .then((response) => {
            console.log("Saved Page:", response);

            if (response.data.success) {
              this.status = "saved";
            } else {
              console.error("Problem saving page");
              this.status = "needsSaving";
              this.errorMessage = "Problem saving page [error: Lithium]";
              Sentry.captureMessage("Problem saving page [error: Lithium]");
            }
          })
          .catch((e) => {
            console.error("Problem saving page: ", e);
            this.status = "needsSaving";
            this.errorMessage = "Problem saving page [error: Lithium]";
            Sentry.captureMessage("Problem saving page [error: Lithium]");
          });
      });
    },

    updateConfigData(payload) {
      console.log("updateConfigData: ", payload);
      this.status = "needsSaving";
      this.configData = payload;
      this.configDataString = JSON.stringify(this.configData, null, 4);
      if (payload.postType === "external") {
        this.isExternal = true;
        this.isInternalLink = false;
      } else if (payload.postType === "internalLink") {
        this.isExternal = false;
        this.isInternalLink = true;
      } else {
        this.isExternal = false;
        this.isInternalLink = false;
      }
    },

    updateSeoData(payload) {
      console.log("updateSeoData: ", payload);
      this.status = "needsSaving";
      this.seoData = payload;

      this.seoDataString = JSON.stringify(this.seoData, null, 4);
    },

    updateContentData(payload) {
      console.log("updateContentData: ", payload);
      this.status = "needsSaving";
      this.content = payload;

      this.contentString = JSON.stringify(this.content, null, 4);
    },

    updateAdvanced() {
      console.log("updateAdvanced");
      if (this.configDataString) {
        this.configData = JSON.parse(this.configDataString);
      }

      if (this.seoDataString) {
        this.seoData = JSON.parse(this.seoDataString);
      }

      if (this.contentString) {
        this.content = JSON.parse(this.contentString);
      }

      this.status = "needsSaving";
    },

    previewPost() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.preview}`,
          headers: { Authorization: jwt },
          data: {
            postTitle: this.configData.postTitle,
            menuPostTitle: this.configData.menuPostTitle,
            postStatus: this.configData.postStatus,
            postType: this.configData.postType,
            pageTemplate: this.configData.pageTemplate,
            heroStyleLarge: this.configData.heroStyleLarge,
            heroStyleSmall: this.configData.heroStyleSmall,
            postSlug: this.configData.postSlug,
            postSummary: this.configData.postSummary,
            mediaLibraryID:
              this.configData.mediaLibraryID != ""
                ? this.configData.mediaLibraryID
                : null,
            heroSmallMediaLibraryID:
              this.configData.heroSmallMediaLibraryID != ""
                ? this.configData.heroSmallMediaLibraryID
                : null,
            listingMediaLibraryID:
              this.configData.listingMediaLibraryID != ""
                ? this.configData.listingMediaLibraryID
                : null,
            showHeroImages: this.configData.showHeroImages,
            childFriendly: this.configData.childFriendly,
            disableAdvertising: this.configData.disableAdvertising,
            customTag: this.configData.customTag,
            publishedDate: this.configData.publishedDate,
            publishedTime: this.configData.publishedTime,
            keywords: this.seoData.keywords,
            description: this.seoData.description,
            metaTitle: this.seoData.metaTitle,
            landingPageIntro: this.landingPageIntro,
            content: this.content,
            childIds: this.backgroundData.childIds,
          },
        })
          .then((response) => {
            const previewUrl = `https://${process.env.VUE_APP_PREVIEW_LINK}/preview/${response.data.postID}`;

            console.log(`Previewing at: ${previewUrl}`);

            // Create a dummy input to copy the string array inside it
            var dummy = document.createElement("input");

            // Add it to the document
            document.body.appendChild(dummy);

            // Set its ID
            dummy.setAttribute("id", "dummy_id");

            // Output the array into it
            document.getElementById("dummy_id").value = previewUrl;

            // Select it
            dummy.select();

            // Copy its contents
            document.execCommand("copy");

            // Remove it as its not needed anymore
            document.body.removeChild(dummy);

            this.errorMessage = "Preview url copied to clipboard";

            const win = window.open(`${previewUrl}`, "_blank");
            win.focus();

            setTimeout(() => {
              this.errorMessage = "";
            }, 10000);
          })
          .catch((e) => {
            console.error("Problem previewing page: ", e);
            this.errorMessage = "Problem previewing page";
            Sentry.captureMessage("Problem previewing page [error: Sodium]");
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-weight: 500;
}

.subheading {
  font-weight: 500;
}
</style>