var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"text-block-widget",attrs:{"lazy":"","persistent":"","scrollable":"","fullscreen":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._t("default",null,{"slot":"activator"}),(_vm.dialog)?_c('v-card',[_c('v-card-title',{staticClass:"widget__heading"},[_vm._v(" Legacy Text block ")]),_c('v-card-text',[_c('label',{staticClass:"label",attrs:{"for":"text-block-content"}},[_vm._v("Content")]),_c('div',{staticClass:"editor-container"},[_c('tinymce-editor',{attrs:{"api-key":"rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca","init":{
            height: 'calc(90vh - 200px)',
            menubar: false,
            relative_urls: false,
            plugins: 'table link paste wordcount hr lists',
            toolbar:
              'undo redo | styleselect | bold italic | \
              link blockquote table | \
              hr | \
              bullist numlist | removeformat',
            resize: false,
            style_formats: [
              {title: 'Headers', items: [
                //{title: 'Header 1', format: 'h1'},
                {title: 'Header 2', format: 'h2'},
                {title: 'Header 3', format: 'h3'},
                {title: 'Header 4', format: 'h4'},
                {title: 'Header 5', format: 'h5'},
                {title: 'Header 6', format: 'h6'}
              ]},
              {title: 'Inline', items: [
                {title: 'Bold', icon: 'bold', format: 'bold'},
                {title: 'Italic', icon: 'italic', format: 'italic'},
                {title: 'Underline', icon: 'underline', format: 'underline'},
                //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                //{title: 'Code', icon: 'code', format: 'code'}
              ]},
              {title: 'Blocks', items: [
                {title: 'Paragraph', format: 'p'},
                {title: 'Blockquote', format: 'blockquote'},
                //{title: 'Div', format: 'div'},
                //{title: 'Pre', format: 'pre'}
              ]},
              {title: 'Alignment', items: [
                {title: 'Left', icon: 'alignleft', format: 'alignleft'},
                {title: 'Center', icon: 'aligncenter', format: 'aligncenter'},
                {title: 'Right', icon: 'alignright', format: 'alignright'},
                //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
              ]},
              {title: 'Small', selector: 'p', classes: 'small'}
            ],
            style_formats_merge: false,
            block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote'
          }},model:{value:(_vm.updatedData.content),callback:function ($$v) {_vm.$set(_vm.updatedData, "content", $$v)},expression:"updatedData.content"}})],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","outline":"","flat":""},on:{"click":function($event){return _vm.cancel();}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){_vm.updateData(); _vm.dialog = false;}}},[_vm._v(" Post ")])],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }