<template>
  <div class="fixture-tagger">
    <div class="fixture-tagger__filters">
      <v-select
        class="fixture-tagger__squad-select"
        v-if="filtersData"
        id="squads"
        :items="filtersData"
        item-text="teamName"
        item-value="teamID"
        :disabled="!filtersData"
        v-model="filteringBy.squad"
        label="Squad"
        @change="selectedSquad()"
      ></v-select>

      <v-select
        v-if="filtersData"
        id="season"
        :items="seasons"
        item-text="seasonName"
        item-value="seasonID"
        :disabled="!filteringBy.squad"
        v-model="filteringBy.season"
        label="Season"
        @change="selectedSeason()"
      ></v-select>

      <v-autocomplete
        outline
        :items="filteringBy.fixtures"
        item-text="fixtureLabel"
        return-object
        :disabled="!filteringBy.fixtures"
        no-data-text="loading..."
        multiple
        solo
        flat
        clearable
        dense
        chips
        deletable-chips
        @change="selectedFixtures($event)"
        >
      </v-autocomplete>
    </div>

    <pre>{{ optionsData }}</pre>

    <v-combobox
      id="fixture-tagger"
      outline
      v-model="selected"
      :items="optionsData"
      item-value="fixtureID"
      item-text="fixtureLabel"
      no-data-text="loading..."
      multiple
      solo
      flat
      clearable
      dense
      chips
      deletable-chips
      @change="$emit('selected', selected)"
      >
    </v-combobox>
  </div>
</template>

<script>
import axios from "axios"
import moment from "moment"

export default {
  name: "FixtureTagger",

  props: {
    values: {
      type: Array,
      required: false
    }
  },

  data:() => ({
    optionsData: [],
    selected: [],
    seasons: [],
    filtersData: null,
    filteringBy: {
      squad: null,
      season: null,
      fixtures: []
    }
  }),

  mounted() {
    this.selected = JSON.parse(JSON.stringify(this.values))
    this.getData()
  },

  methods: {
    selectedSquad() {
      console.log("Selected Squad")
      this.seasons = []
      this.setSeasons()
    },

    selectedSeason() {
      console.log("Selected Season")
      this.getFixtures()
    },

    selectedFixtures($event) {
      console.log("Selected Fixtures", $event)

      this.optionsData = this.optionsData.concat($event);
      this.selected = this.selected.concat($event);

      console.log("optionsData", this.optionsData)
    },

    setSeasons() {
      for (const squad of this.filtersData) {
        if (squad.teamID === this.filteringBy.squad) {
          this.seasons = squad.seasons
        }
      }
    },

    getData() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_BASEURL}/fixtures/opta/filters`, { "headers": { "Authorization": jwt }})
        .then(response => {
          this.filtersData = response.data.body;
          console.log("filtersData", this.filtersData)
        })
        .catch(e => {
          console.error("Problem retrieving fixtures", e);
        });
      })
    },

    getFixtures(){
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_BASEURL}/fixtures/opta?clientMatches=true&teamID=${this.filteringBy.squad}&seasonID=${this.filteringBy.season}&pageSize=200`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("Fetch fixtures response: ", response);
          if(response.data.totalDataCount) {
            this.filteringBy.fixtures = response.data.body.map(fixture => {
              if (fixture.teamData) {
                return {
                  fixtureID: fixture.matchID,
                  fixtureLabel: `${moment(fixture.kickOffUTC).format("D MMM YYYY")} - ${fixture.matchID} - ${fixture.teamData[0].teamName} v ${fixture.teamData[1].teamName}`,
                }
              }
            });
          }
        })
        .catch(e => {
          console.error("Problem retrieving fixtures", e);
        });
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .fixture-tagger {

    &__filters {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 8px 8px 0;
      background:rgba(209, 216, 245, 0.6);
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }

      /deep/ .v-select {
        font-size: 12px;
      }

      /deep/ .v-chip {
        font-size: 10px;
      }
    }

    &__squad-select {
      margin-right: 16px;
    }
  }
</style>
