<template>
  <div class="data-group">
    <div class="header">
      <div class="title-container">
        <v-icon v-if="!isOpen" class="icon" @click="isOpen = true"
          >add_circle</v-icon
        >
        <v-icon v-else class="icon" @click="isOpen = false"
          >remove_circle</v-icon
        >
        <h3 class="data-group__title">{{ groupData.title }}</h3>
      </div>
      <v-icon class="icon" @click="deleteGroup()">delete</v-icon>
    </div>

    <div v-show="isOpen" class="data">
      <label class="label" for="title">Title</label>
      <v-text-field
        id="title"
        v-model="groupData.title"
        solo
        flat
      ></v-text-field>
      <label class="label" for="title">Items</label>
      <draggable
        v-if="groupData && groupData.items && groupData.items.length"
        v-model="groupData.items"
        :options="{
          ghostClass: 'ghost',
          dragClass: 'sortable-drag',
        }"
        @start="drag = true"
        @end="drag = false"
      >
        <div class="item" v-for="item in groupData.items" :key="item.id">
          <v-icon class="icon" @click="deleteItem(item.id)">delete</v-icon>

          <template v-if="item.hasOwnProperty('title')">
            <label class="label" for="itemTitle">Item title</label>
            <v-text-field
              id="itemTitle"
              v-model="item.title"
              solo
              flat
            ></v-text-field>
          </template>
          <template v-if="item.hasOwnProperty('description')">
            <label class="label" for="itemDescription">Item description</label>
            <v-textarea
              id="itemDescription"
              v-model="item.description"
              solo
              flat
              rows="4"
            ></v-textarea>
          </template>
          <template v-if="item.hasOwnProperty('published')">
            <v-switch
              class="switch"
              label="Published"
              inset
              v-model="item.published"
            ></v-switch>
          </template>
        </div>
      </draggable>

      <v-btn
        v-if="itemSchema"
        class="add-button"
        color="accent"
        @click="addItem()"
      >
        New Item
      </v-btn>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "DataGroup",

  props: {
    data: {
      type: Object,
      required: true,
    },
    itemSchema: {
      type: Object,
      required: false,
    },
    groupTitle: {
      type: String,
      required: false,
    },
  },

  components: {
    draggable,
  },

  watch: {
    groupData: {
      handler: function () {
        this.updatedContent();
      },
      deep: true,
    },
  },

  data: () => ({
    groupData: null,
    isOpen: false,
  }),

  created() {
    this.groupData = JSON.parse(JSON.stringify(this.data));
  },

  methods: {
    addItem() {
      const newItem = JSON.parse(JSON.stringify(this.itemSchema));

      newItem.id = this.$uuid.v1();

      this.groupData.items.push(newItem);
    },

    updatedContent() {
      this.$emit("updatedContent", this.groupData);
    },

    deleteGroup() {
      if (
        confirm(
          `Are you sure you want to delete this ${this.groupName || "group"}?`
        )
      ) {
        this.$emit("deleteGroup", this.groupData.id);
      }
    },

    deleteItem(id) {
      if (confirm("Are you sure you want to delete this item?")) {
        const index = this.groupData.items.findIndex(
          (element) => element.id === id
        );
        if (index > -1) {
          this.groupData.items.splice(index, 1);
        }
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.data-group {
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  background: #f8f8f8;
  overflow: hidden;
  border-radius: 10px;

  .header {
    cursor: grab;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #67247c;
    padding: 10px;
  }

  .title-container {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }
  }

  &__title {
    color: white;
    text-transform: uppercase;
  }

  .icon {
    color: white;
    cursor: pointer;
  }

  .data {
    padding: 10px;
  }

  .item {
    padding: 10px;
    padding-top: 20px;
    background: #fff;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 16px;
    position: relative;

    .icon {
      color: #67247c;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .switch {
    margin-top: 0;
  }
}

.ghost {
  opacity: 0;
}
</style>
