export default {
  PlayerHighlight: true, // Will require new module on stable release
  HubPages: true, // Will be added as part of pages on stable release
  MatchdayTakeover: true, // Will require new module on stable release
  TempSocialFeed: true,
  WhereToStream: true,
  PlayerProfileStats: false,
  videoFeeds: [
  {
    // feed1: {
      id: "sergnrynedfberbgbertb",
      hero: {
        heroType: "carousel",
        content: {
          body: "<p>Exclusive highlights, interviews and behind the scenes footage from UZtv</p>",
          backgroundImageKey: "5dab5280-c8a5-11eb-a4cf-4dcb609ca29b.png",
          widgetData: {
            title: "Latest",
            style: "dark",
            buttonStyle: "ghost-light",
            content: "",
            query: {
              queryType: "search",
              queryScope: "",
              category: "",
              team: "",
              searchString: "",
            },
            destinationUrl: "",
            destinationLinkText: ""
          }
        }
      },
      content: [
        {
          rowID: "0001",
          rowData: {
            widgetName: "Video Carousel",
            widgetID: "0001",
            widgetType: "VideoCarousel",
            widgetData: {
              title: "Latest",
              style: "light",
              buttonStyle: "ghost-dark",
              content: "",
              query: {
                queryType: "search",
                queryScope: "",
                category: "",
                team: "",
                searchString: "",
              },
              destinationUrl: "/videos/browse",
              destinationLinkText: "All videos"
            }
          }
        },
        {
          rowID: "0002",
          rowData: {
            widgetName: "Content",
            widgetID: "0002",
            widgetType: "ContentWidget",
            widgetData: {
              title: "Latest",
              style: "light",
              wysiwyg: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pulvinar lectus in feugiat egestas. Sed maximus metus tortor, quis malesuada purus lacinia et. Donec ut mauris vitae quam consectetur sagittis. Vestibulum ullamcorper et ipsum vitae dignissim. Suspendisse rhoncus nunc eget purus tristique viverra. Aliquam tincidunt tempor augue sed fringilla. Nunc orci turpis, blandit nec luctus et, tristique eget ipsum. Proin mollis lacinia nunc, sed convallis metus sodales at. Maecenas venenatis ornare augue, vitae rutrum ligula finibus vitae. Donec pretium turpis a egestas blandit. Nulla sagittis faucibus hendrerit. Ut euismod ullamcorper scelerisque. Aliquam ac elementum est, non efficitur nulla. Donec eu elit quam. Nunc varius dui id nisl pulvinar, quis ornare lorem tincidunt. Cras suscipit sapien ut augue eleifend fermentum.</p>
              <p>Etiam vehicula non ligula non suscipit. Etiam non ante ac magna euismod commodo. Vestibulum justo leo, euismod sit amet ex vitae, ullamcorper venenatis quam. Nunc ante augue, cursus pharetra rutrum sit amet, facilisis id magna. Nunc sed dignissim magna. Phasellus mollis gravida tellus consequat finibus. Phasellus non maximus tortor. Proin semper, nunc at rutrum vehicula, neque risus scelerisque metus, non tristique tellus ex quis purus. Ut iaculis fermentum efficitur. Duis quis ipsum sed nibh porta mattis eu at quam.</p>`
            }
          }
        },
        {
          rowID: "0003",
          rowData: {
            widgetName: "Video Carousel",
            widgetID: "0003",
            widgetType: "VideoCarousel",
            widgetData: {
              title: "First Team Highlights",
              content: "",
              style: "dark",
              buttonStyle: "ghost-dark",
              query: {
                queryType: "search",
                queryScope: "",
                category: "Highlights",
                team: "First%20Team",
                searchString: "",
              },
              destinationUrl: "/videos/browse?category=Highlights&team=First%20Team",
              destinationLinkText: "Browse more"
            }
          }
        },
        {
          rowID: "0004",
          rowData: {
            widgetName: "Spotlight Small",
            widgetID: "0004",
            widgetType: "SpotlightSmall",
            widgetData: {
              videoID: "0_7yqso7zu",
              customSubtitle: "From the Archive",
              style: "dark",
            }
          }
        },
        {
          rowID: "0005",
          rowData: {
            widgetName: "Spotlight Small",
            widgetID: "0005",
            widgetType: "SpotlightSmall",
            widgetData: {
              videoID: "0_7yqso7zu",
              customSubtitle: "From the Archive",
              style: "light",
            }
          }
        },
        {
          rowID: "0006",
          rowData: {
            widgetName: "Video Carousel",
            widgetID: "0006",
            widgetType: "VideoCarousel",
            widgetData: {
              title: "Features (Channel)",
              content: "",
              style: "light",
              buttonStyle: "ghost-light",
              query: {
                queryType: "section",
                queryScope: "en/feed/3b9c8af1-ca52-4e58-b038-b1b2900f2599/sections/?section=e3aa6d3f-8def-46e9-9171-f5ceb4646bb9",
                category: "",
                team: "",
                searchString: "",
              },
              destinationUrl: "/videos/channel/features",
              destinationLinkText: "See more"
            }
          },
        },
        {
          rowID: "0007",
          rowData: {
            widgetName: "Streaming Passes",
            widgetID: "0007",
            widgetType: "StreamingPasses",
            widgetData: {
              style: "dark",
              title: "Live Streaming Packages & Passes",
              description: "Further description",
              renderInlineHtml: false,
              highlightedPassId: ["6989dee0-3444-4b22-b30c-7fae80e14b30", "4"],
            }
          }
        },
        {
          rowID: "0008",
          rowData: {
            widgetName: "Video Carousel",
            widgetID: "0008",
            widgetType: "VideoCarousel",
            widgetData: {
              title: "Search for Newcastle",
              content: "",
              style: "dark",
              buttonStyle: "ghost-dark",
              query: {
                queryType: "search",
                queryScope: "",
                category: "",
                team: "",
                searchString: "Newcastle",
              },
              destinationUrl: "/videos/browse?s=Newcastle",
              destinationLinkText: "Search more"
            }
          }
        },
        {
          rowID: "0009",
          rowData: {
            widgetName: "Video Carousel",
            widgetID: "0009",
            widgetType: "VideoCarousel",
            widgetData: {
              title: "Riverside updates",
              content: "",
              style: "dark",
              buttonStyle: "ghost-dark",
              query: {
                queryType: "search",
                queryScope: "metaData.title:Riverside~1",
                category: "",
                team: "",
                searchString: "",
              },
              destinationUrl: "/videos/channel/riverside",
              destinationLinkText: "More"
            }
          }
        },
        {
          rowID: "0010",
          rowData: {
            widgetName: "Video Carousel",
            widgetID: "0010",
            widgetType: "VideoCarousel",
            widgetData: {
              title: "Antonee Robinson",
              content: "",
              style: "dark",
              buttonStyle: "ghost-dark",
              query: {
                queryType: "search",
                queryScope: "metaData.tags:Antonee%20Robinson",
                category: "",
                team: "",
                searchString: "",
              },
              destinationUrl: "/videos/player/antonee-robinson",
              destinationLinkText: "More"
            }
          }
        },
        {
          rowID: "0011",
          rowData: {
            widgetName: "Streamplay Feature",
            widgetID: "0011",
            widgetType: "StreamplayFeature",
            widgetData: {
              style: "dark",
              packagesLink: "https://google.co.uk",
              packagesText: "Packages link",
              filteredType: "",
              selectedEvent: "next",
            }
          }
        },
        {
          rowID: "0012",
          rowData: {
            widgetName: "Streamplay Feature",
            widgetID: "0012",
            widgetType: "StreamplayFeature",
            widgetData: {
              style: "light",
              packagesLink: "https://youtube.com",
              packagesText: "Packages page",
              filteredType: "fixture",
              selectedEvent: "next+1",
            }
          }
        },
        {
          rowID: "0013",
          rowData: {
            widgetName: "Streamplay Feature",
            widgetID: "0013",
            widgetType: "StreamplayFeature",
            widgetData: {
              style: "dark",
              packagesLink: "https://google.co.uk",
              packagesText: "View packaes",
              filteredType: "event",
              selectedEvent: "next",
            }
          }
        },
        {
          rowID: "0014",
          rowData: {
            widgetName: "Categories",
            widgetID: "0014",
            widgetType: "CategoryCarousel",
            widgetData: {
              title: "Categories",
              content: "",
              style: "dark",
              destinationUrl: "/videos/categories",
              destinationLinkText: "All Categories"
            }
          }
        },
        {
          rowID: "0015",
          rowData: {
            widgetName: "Video Memberships",
            widgetID: "0015",
            widgetType: "VideoMemberships",
            widgetData: {
              style: "dark",
              title: "Subscribe For More",
              description: "Further description",
              featuredMemberships: ["membersOnly", "paid"] // new
            }
          }
        },
        {
          rowID: "0016",
          rowData: {
            widgetName: "Video Memberships",
            widgetID: "0016",
            widgetType: "VideoMemberships",
            widgetData: {
              style: "light",
              title: "Subscribe For More",
              description: "Further description",
              featuredMemberships: ["membersOnly", "paid"] // new
            }
          }
        },
        {
          rowID: "0017",
          rowData: {
            widgetName: "Promo",
            widgetID: "0017",
            widgetType: "Promo",
            widgetData: {
              title: "This is a Title",
              description: "<p>This is a promo in light background</p>",
              style: "dark",
              buttonLink: "http://google.co.uk",
              buttonText: "CTA",
              buttonStyle: "ghost-dark",
              mediaLibraryID: "",
              mediaLibraryKey: "5dab5280-c8a5-11eb-a4cf-4dcb609ca29b.png"
            }
          }
        },
        {
          rowID: "0018",
          rowData: {
            widgetName: "Promo",
            widgetID: "0018",
            widgetType: "Promo",
            widgetData: {
              title: "Another Title",
              description: "<p>This is a promo in dark background</p>",
              style: "light",
              buttonLink: "http://youtube.com",
              buttonText: "Another CTA",
              buttonStyle: "primary-light", 
              mediaLibraryID: "",
              mediaLibraryKey: "5dab5280-c8a5-11eb-a4cf-4dcb609ca29b.png"
            }
          }
        },
        {
          rowID: "0019",
          rowData: {
            widgetName: "Video Faqs",
            widgetID: "0019",
            widgetType: "VideoFaqs",
            widgetData: {
              title: "FAQs",
              content: "FAQ data comes from global settings",
              style: "dark",
            }
          }
        },
        {
          rowID: "0020",
          rowData: {
            widgetName: "Video Faqs",
            widgetID: "0020",
            widgetType: "VideoFaqs",
            widgetData: {
              title: "FAQs",
              content: "FAQ data comes from global settings",
              style: "light",
            }
          }
        },
        {
          rowID: "0021",
          rowData: {
            widgetName: "Video Carousel",
            widgetID: "0021",
            widgetType: "VideoCarousel",
            widgetData: {
              title: "Testing",
              content: "",
              style: "dark",
              buttonStyle: "ghost-dark",
              query: {
                queryType: "search",
                queryScope: "(mediaData.entryId:0_qcm3j25q)%20OR%20(mediaData.entryId:0_k3j9lcoh)%20OR%20(mediaData.entryId:0_7yqso7zu)",
                category: "",
                team: "",
                searchString: "",
              },
              destinationUrl: "/videos/channel/riverside",
              destinationLinkText: "More"
            }
          }
        },
        {
          rowID: "0022",
          rowData: {
            widgetName: "Streamplay List",
            widgetID: "0022",
            widgetType: "StreamplayList",
            widgetData: {
              label: "Live & Upcoming",
              title: "Streamplay",
              description: "This contains fixtures only",
              style: "light",
              packagesLink: "https://youtube.com",
              packagesText: "Packages button",
              displayImages: true,
              filteredType: "fixture",
              missingDataMessage: "No data for upcoming fixtures",
              itemLimit: 4,
            }
          }
        },
        {
          rowID: "0023",
          rowData: {
            widgetName: "Streamplay List",
            widgetID: "0023",
            widgetType: "StreamplayList",
            widgetData: {
              label: "Live & Upcoming",
              title: "Dark option streamplay",
              description: "This contains events only",
              style: "dark",
              packagesLink: "https://www.urbanzoofc.com/",
              packagesText: "Go to packages",
              displayImages: true,
              filteredType: "event",
              missingDataMessage: "",
              itemLimit: 5,
            }
          }
        },
      ]
    // }
  },
  { 
    // feed2: {
    id: "dgbdfghnfghjgynhf",
      hero: {
        heroType: "nextLiveMatch",
        content: {
          body: "Bought or redeemed a pass? Make sure you're signed in to watch/listen",
          secondaryContentType: "relatedVideos", // relatedVideos || calendar
          heroMode: "dark", // dark || light
          backgroundImageKey: "5dab5280-c8a5-11eb-a4cf-4dcb609ca29b.png",
          backgroundImageID: "5dab5280-c8a5-11eb-a4cf-4dcb609ca29b",
          ctaButtonText: "Get a pass",
          ctaButtonUrl: "https://google.co.uk",
          widgetData: {
            title: "Latest",
            style: "light",
            buttonStyle: "ghost-dark",
            content: "",
            query: {
              queryType: "search",
              queryScope: "",
              category: "",
              team: "",
              searchString: "",
            },
            destinationUrl: "https://google.co.uk",
            destinationLinkText: "All Videos"
          }
        }
      },
      content: [
        {
          rowID: "2001",
          rowData: {
            widgetName: "Streamplay List",
            widgetID: "2001",
            widgetType: "StreamplayList",
            widgetData: {
              label: "Live and Upcoming",
              title: "Dark option streamplay",
              description: "This contains both matches and events",
              style: "dark",
              packagesLink: "https://google.co.uk",
              packagesText: "View Packages here",
              displayImages: true,
              filteredType: "live",
              missingDataMessage: "",
              itemLimit: 5,
            }
          }
        },
        {
          rowID: "2002",
          rowData: {
            widgetName: "Streaming Passes",
            widgetID: "2002",
            widgetType: "StreamingPasses",
            widgetData: {
              style: "dark",
              title: "Live Streaming Packages & Passes",
              description: "Further description",
              highlightedPassId: ["6989dee0-3444-4b22-b30c-7fae80e14b30", "4"],
            }
          }
        },
        {
          rowID: "2003",
          rowData: {
            widgetName: "Video Memberships",
            widgetID: "2003",
            widgetType: "VideoMemberships",
            widgetData: {
              style: "dark",
              title: "Subscribe For More",
              description: "Further description",
              featuredMemberships: ["membersOnly", "paid"] // new
            }
          }
        },
        {
          rowID: "2004",
          rowData: {
            widgetName: "Video Faqs",
            widgetID: "2004",
            widgetType: "VideoFaqs",
            widgetData: {
              title: "FAQs",
              content: "FAQ data comes from global settings",
              style: "dark",
            }
          }
        },
        {
          rowID: "2005",
          rowData: {
            widgetName: "Video Carousel",
            widgetID: "2005",
            widgetType: "VideoCarousel",
            widgetData: {
              title: "Testing",
              content: "",
              style: "dark",
              buttonStyle: "ghost-dark",
              query: {
                queryType: "search",
                queryScope: "(mediaData.entryId:0_qcm3j25q)%20OR%20(mediaData.entryId:0_k3j9lcoh)%20OR%20(mediaData.entryId:0_7yqso7zu)",
                category: "",
                team: "",
                searchString: "",
              },
              destinationUrl: "/videos/channel/riverside",
              destinationLinkText: "More"
            }
          }
        },
      ]
    // },
  },
  { 
    // feed3: {
    id: "sergnrynedfberbgbefghnhvhfdrg",
      hero: {
        heroType: "generic",
        content: {
          title: "FAQs",
          heroMode: "dark",
          backgroundImageKey: "5dab5280-c8a5-11eb-a4cf-4dcb609ca29b.png"
        }
      },
      content: [
        {
          rowID: "3001",
          rowData: {
            widgetName: "Content",
            widgetID: "3001",
            widgetType: "ContentWidget",
            widgetData: {
              title: "About",
              style: "light",
              wysiwyg: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pulvinar lectus in feugiat egestas. Sed maximus metus tortor, quis malesuada purus lacinia et. Donec ut mauris vitae quam consectetur sagittis. Vestibulum ullamcorper et ipsum vitae dignissim. Suspendisse rhoncus nunc eget purus tristique viverra. Aliquam tincidunt tempor augue sed fringilla. Nunc orci turpis, blandit nec luctus et, tristique eget ipsum. Proin mollis lacinia nunc, sed convallis metus sodales at. Maecenas venenatis ornare augue, vitae rutrum ligula finibus vitae. Donec pretium turpis a egestas blandit. Nulla sagittis faucibus hendrerit. Ut euismod ullamcorper scelerisque. Aliquam ac elementum est, non efficitur nulla. Donec eu elit quam. Nunc varius dui id nisl pulvinar, quis ornare lorem tincidunt. Cras suscipit sapien ut augue eleifend fermentum.</p>
              <p>Etiam vehicula non ligula non suscipit. Etiam non ante ac magna euismod commodo. Vestibulum justo leo, euismod sit amet ex vitae, ullamcorper venenatis quam. Nunc ante augue, cursus pharetra rutrum sit amet, facilisis id magna. Nunc sed dignissim magna. Phasellus mollis gravida tellus consequat finibus. Phasellus non maximus tortor. Proin semper, nunc at rutrum vehicula, neque risus scelerisque metus, non tristique tellus ex quis purus. Ut iaculis fermentum efficitur. Duis quis ipsum sed nibh porta mattis eu at quam.</p>`
            }
          }
        },
        {
          rowID: "3002",
          rowData: {
            widgetName: "Video Faqs",
            widgetID: "3002",
            widgetType: "VideoFaqs",
            widgetData: {
              title: "FAQs",
              content: "FAQ data comes from global settings",
              style: "dark",
            }
          }
        },
        {
          rowID: "3003",
          rowData: {
            widgetName: "Video Carousel",
            widgetID: "3003",
            widgetType: "VideoCarousel",
            widgetData: {
              title: "Testing",
              content: "",
              style: "dark",
              buttonStyle: "ghost-dark",
              query: {
                queryType: "search",
                queryScope: "(mediaData.entryId:0_qcm3j25q)%20OR%20(mediaData.entryId:0_k3j9lcoh)%20OR%20(mediaData.entryId:0_7yqso7zu)",
                category: "",
                team: "",
                searchString: "",
              },
              destinationUrl: "/videos/channel/riverside",
              destinationLinkText: "More"
            }
          }
        },
      ]
    // },
  },
  {
    // feed4: {
      id: "sergnrynedfberb465645645b",
      hero: {
        heroType: "generic",
        content: {
          title: "Subscriptions",
          heroMode: "dark",
          backgroundImageKey: "5dab5280-c8a5-11eb-a4cf-4dcb609ca29b.png"
        }
      },
      content: [
        {
          rowID: "4001",
          rowData: {
            widgetName: "Content",
            widgetID: "4001",
            widgetType: "ContentWidget",
            widgetData: {
              title: "About",
              style: "light",
              wysiwyg: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pulvinar lectus in feugiat egestas. Sed maximus metus tortor, quis malesuada purus lacinia et. Donec ut mauris vitae quam consectetur sagittis. Vestibulum ullamcorper et ipsum vitae dignissim. Suspendisse rhoncus nunc eget purus tristique viverra. Aliquam tincidunt tempor augue sed fringilla. Nunc orci turpis, blandit nec luctus et, tristique eget ipsum. Proin mollis lacinia nunc, sed convallis metus sodales at. Maecenas venenatis ornare augue, vitae rutrum ligula finibus vitae. Donec pretium turpis a egestas blandit. Nulla sagittis faucibus hendrerit. Ut euismod ullamcorper scelerisque. Aliquam ac elementum est, non efficitur nulla. Donec eu elit quam. Nunc varius dui id nisl pulvinar, quis ornare lorem tincidunt. Cras suscipit sapien ut augue eleifend fermentum.</p>
              <p>Etiam vehicula non ligula non suscipit. Etiam non ante ac magna euismod commodo. Vestibulum justo leo, euismod sit amet ex vitae, ullamcorper venenatis quam. Nunc ante augue, cursus pharetra rutrum sit amet, facilisis id magna. Nunc sed dignissim magna. Phasellus mollis gravida tellus consequat finibus. Phasellus non maximus tortor. Proin semper, nunc at rutrum vehicula, neque risus scelerisque metus, non tristique tellus ex quis purus. Ut iaculis fermentum efficitur. Duis quis ipsum sed nibh porta mattis eu at quam.</p>`
            }
          }
        },
        {
          rowID: "4002",
          rowData: {
            widgetName: "Streaming Passes",
            widgetID: "4002",
            widgetType: "StreamingPasses",
            widgetData: {
              style: "dark",
              buttonStyle: "ghost-dark",
              title: "Live Streaming Packages & Passes",
              description: "Further description",
              highlightedPassId: ["6989dee0-3444-4b22-b30c-7fae80e14b30", "4"],
            }
          }
        },
        {
          rowID: "4003",
          rowData: {
            widgetName: "Video Memberships",
            widgetID: "4003",
            widgetType: "VideoMemberships",
            widgetData: {
              style: "dark",
              title: "Subscribe For More",
              description: "Further description",
              featuredMemberships: ["membersOnly", "paid"] // new
            }
          }
        },
        {
          rowID: "4004",
          rowData: {
            widgetName: "Video Carousel",
            widgetID: "4004",
            widgetType: "VideoCarousel",
            widgetData: {
              title: "Testing",
              content: "",
              style: "dark",
              buttonStyle: "ghost-dark",
              query: {
                queryType: "search",
                queryScope: "(mediaData.entryId:0_qcm3j25q)%20OR%20(mediaData.entryId:0_k3j9lcoh)%20OR%20(mediaData.entryId:0_7yqso7zu)",
                category: "",
                team: "",
                searchString: "",
              },
              destinationUrl: "/videos/channel/riverside",
              destinationLinkText: "More"
            }
          }
        },
      ]
    }
  // }
  ]
}
