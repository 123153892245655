<template>
    <v-dialog
        class="upcoming-fixtures-widget"
        v-model="dialog"
        width="580"
        lazy
    >
        <slot slot="activator"></slot>

        <v-card>
            <v-card-title class="widget__heading"> Upcoming Fixtures </v-card-title>

            <v-card-text>
                <v-form
                    enctype="multipart/form-data"
                    ref="upcomingFixturesForm"
                    lazy-validation
                >

                    <v-text-field
                        id="title"
                        v-model="upcomingFixturesData.title"
                        persistent-hint
                        label="Section title"
                        required
                        class="mb-3"
                    ></v-text-field>

                    <!-- <div class="upcoming-fixture-widget">
            <input v-model="upcomingFixturesData.nextFixtures" type="radio" class="upcoming-fixture-widget__item upcoming-fixture-widget__item--one" name="nextFixtures" value="1" id="nextFixturesOne">
            <label for="nextFixturesOne">
              <span>Single Fixture</span>
            </label>
            <input v-model="upcomingFixturesData.nextFixtures" type="radio" class="upcoming-fixture-widget__item upcoming-fixture-widget__item--two" name="nextFixtures" value="2" id="nextFixturesTwo">
            <label for="nextFixturesTwo">
              <span>Two Fixtures</span>
            </label>
            <input v-model="upcomingFixturesData.nextFixtures" type="radio" class="upcoming-fixture-widget__item upcoming-fixture-widget__item--three" name="nextFixtures" value="3" id="nextFixturesThree">
            <label for="nextFixturesThree">
              <span>Three Fixtures</span>
            </label>
            <input v-model="upcomingFixturesData.nextFixtures" type="radio" class="upcoming-fixture-widget__item upcoming-fixture-widget__item--four" name="nextFixtures" value="4" id="nextFixturesFour">
            <label for="nextFixturesFour">
              <span>Four Fixtures</span>
            </label>
            <input v-model="upcomingFixturesData.nextFixtures" type="radio" class="upcoming-fixture-widget__item upcoming-fixture-widget__item--five" name="nextFixtures" value="5" id="nextFixturesFive">
            <label for="nextFixturesFive">
              <span>Five Fixtures</span>
            </label>
          </div> -->

                    <label
                        class="label"
                        for="nextFixtures"
                    >Fixtures to display</label>
                    <v-text-field
                        id="nextFixtures"
                        v-model="upcomingFixturesData.nextFixtures"
                        hint="To show all your upcoming fixtures put a large number in eg 100"
                        persistent-hint
                        label="Number of fixtures"
                        required
                        type="number"
                        class="mb-3"
                    ></v-text-field>

                    <match-picker-efl
                        :teamMandatory="false"
                        :includeMatch="false"
                        :currentSeason="true"
                        @updated="setMatchEfl($event)"
                    />

                    <v-btn
                        color="accent"
                        outline
                        flat
                        @click="dialog = false"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        color="accent"
                        :disabled="!upcomingFixturesData.competition"
                        depressed
                        @click="updateData()"
                    >
                        <div v-if="widgetData">Update upcoming fixtures</div>
                        <div v-else>Add upcoming fixtures</div>
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import MatchPickerEfl from "../Library/MatchPickerEfl.vue";

export default {
    name: "UpcomingFixturesWidgetEfl",

    props: {
        widgetData: {
            type: Object,
            required: false,
        },
    },

    components: { MatchPickerEfl },

    data: () => ({
        multiClubMatchesApi: process.env.VUE_APP_MULTICLUBMATCHESAPI,
        dialog: false,
        competitions: [],
        teams: [],
        upcomingFixturesData: {
            title: '',
            nextFixtures: "5",
            squad: null,
            season: null,
            squadName: null,
            competition: null,
            competitionName: null,
        },
    }),

    mounted() {
        console.log("Upcoming fixtures widget mounted");

        if (this.widgetData) {
            this.upcomingFixturesData = JSON.parse(JSON.stringify(this.widgetData));
        }
    },

    methods: {
        setMatchEfl(payload) {
            console.log("Set EFL Match", payload);

            this.upcomingFixturesData.squad = payload.teamID;
            this.upcomingFixturesData.season = payload.seasonID;
            this.upcomingFixturesData.squadName = payload.teamName;
            this.upcomingFixturesData.competition = payload.competitionID;
            this.upcomingFixturesData.competitionName = payload.competitionName;
        },

        updateData() {
            console.log("Update data:", this.upcomingFixturesData);
            this.$emit("updatedData", this.upcomingFixturesData);
            this.dialog = false;
        },
    },
};
</script>

<style
    lang="scss"
    scoped
>
.upcoming-fixture-widget {
    margin: 10px 0 30px;
    display: flex;
    justify-content: space-evenly;

    &__item {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        +label {
            cursor: pointer;
            text-align: center;

            &:before {
                content: "";
                width: 64px;
                height: 56px;
                display: block;
                cursor: pointer;
                margin: auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                opacity: 0.5;
            }

            span {
                font-size: 1rem;
                color: $purple;
                margin-top: 5px;
                display: block;
                border-radius: 100px;
                padding: 3px 6px;
            }
        }

        &:checked {
            +label {
                &:before {
                    opacity: 1;
                }

                span {
                    background-color: #67247c;
                    color: white;
                }
            }
        }

        &--one {
            +label:before {
                background-image: url("../../assets/upcoming-fixtures-1.svg");
            }
        }

        &--two {
            +label:before {
                background-image: url("../../assets/upcoming-fixtures-2.svg");
            }
        }

        &--three {
            +label:before {
                background-image: url("../../assets/upcoming-fixtures-3.svg");
            }
        }

        &--four {
            +label:before {
                background-image: url("../../assets/upcoming-fixtures-4.svg");
            }
        }

        &--five {
            +label:before {
                background-image: url("../../assets/upcoming-fixtures-5.svg");
            }
        }
    }
}
</style>
