var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accordion-form"},[_c('h1',[_vm._v(_vm._s(_vm.formHeading))]),(_vm.edit)?[_c('label',{staticClass:"label",attrs:{"for":"title"}},[_vm._v("Row Title")]),_c('v-text-field',{attrs:{"id":"title","solo":"","flat":""},model:{value:(_vm.formTitle),callback:function ($$v) {_vm.formTitle=$$v},expression:"formTitle"}}),_c('label',{staticClass:"label",attrs:{"for":"body"}},[_vm._v("Row Body")]),_c('tinymce-editor',{attrs:{"api-key":"rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca","init":{
      height: '500px',
      menubar: false,
      plugins: 'table link paste wordcount hr lists textpattern',
      toolbar:
        'undo redo | styleselect | bold italic | \
        link blockquote table | \
        hr | \
        bullist numlist | removeformat',
      resize: false,
      textpattern_patterns: [
        {start: '//button', replacement: '<a class=\'button \'>Button here</a>'}
      ],
      link_class_list: [
        {title: 'None', value: ''},
        {title: 'Primary on Dark', value: 'button button--primary-dark'},
        {title: 'Primary on Light', value: 'button button--primary-light'},
        {title: 'Ghost on Dark', value: 'button button--ghost-dark'},
        {title: 'Ghost on Light', value: 'button button--ghost-light'},
        {title: 'Label on Dark', value: 'button button--label-dark'},
        {title: 'Label on Light', value: 'button button--label-light'},
      ],
      style_formats: [
        {title: 'Headers', items: [
          //{title: 'Header 1', format: 'h1'},
          {title: 'Header 2', format: 'h2'},
          {title: 'Header 3', format: 'h3'},
          {title: 'Header 4', format: 'h4'},
          {title: 'Header 5', format: 'h5'},
          {title: 'Header 6', format: 'h6'}
        ]},
        {title: 'Inline', items: [
          {title: 'Bold', icon: 'bold', format: 'bold'},
          {title: 'Italic', icon: 'italic', format: 'italic'},
          {title: 'Underline', icon: 'underline', format: 'underline'},
          //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
          //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
          //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
          //{title: 'Code', icon: 'code', format: 'code'}
        ]},
        {title: 'Blocks', items: [
          {title: 'Paragraph', format: 'p'},
          {title: 'Blockquote', format: 'blockquote'},
          //{title: 'Div', format: 'div'},
          //{title: 'Pre', format: 'pre'}
        ]},
        {title: 'Alignment', items: [
          {title: 'Left', icon: 'alignleft', format: 'alignleft'},
          {title: 'Center', icon: 'aligncenter', format: 'aligncenter'},
          {title: 'Right', icon: 'alignright', format: 'alignright'},
          //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
        ]},
        {title: 'Small', selector: 'p', classes: 'small'}
      ],
      style_formats_merge: false,
      block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote'
    }},model:{value:(_vm.formBody),callback:function ($$v) {_vm.formBody=$$v},expression:"formBody"}}),_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){return _vm.saveRow()}}},[_vm._v(" Save Edits ")]),_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){return _vm.cancelEdit()}}},[_vm._v(" Cancel ")])]:[_c('label',{staticClass:"label",attrs:{"for":"body"}},[_vm._v("Row Title")]),_c('v-text-field',{attrs:{"id":"title","placeholder":"Insert title","solo":"","flat":""},model:{value:(_vm.formTitle),callback:function ($$v) {_vm.formTitle=$$v},expression:"formTitle"}}),_c('label',{staticClass:"label",attrs:{"for":"body"}},[_vm._v("Row Body")]),_c('tinymce-editor',{attrs:{"api-key":"rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca","init":{
      height: '500px',
      menubar: false,
      plugins: 'table link paste wordcount hr lists textpattern',
      toolbar:
        'undo redo | styleselect | bold italic | \
        link blockquote table | \
        hr | \
        bullist numlist | removeformat',
      resize: false,
      textpattern_patterns: [
        {start: '//button', replacement: '<a class=\'button \'>Button here</a>'}
      ],
      link_class_list: [
        {title: 'None', value: ''},
        {title: 'Primary on Dark', value: 'button button--primary-dark'},
        {title: 'Primary on Light', value: 'button button--primary-light'},
        {title: 'Ghost on Dark', value: 'button button--ghost-dark'},
        {title: 'Ghost on Light', value: 'button button--ghost-light'},
        {title: 'Label on Dark', value: 'button button--label-dark'},
        {title: 'Label on Light', value: 'button button--label-light'},
      ],
      style_formats: [
        {title: 'Headers', items: [
          //{title: 'Header 1', format: 'h1'},
          {title: 'Header 2', format: 'h2'},
          {title: 'Header 3', format: 'h3'},
          {title: 'Header 4', format: 'h4'},
          {title: 'Header 5', format: 'h5'},
          {title: 'Header 6', format: 'h6'}
        ]},
        {title: 'Inline', items: [
          {title: 'Bold', icon: 'bold', format: 'bold'},
          {title: 'Italic', icon: 'italic', format: 'italic'},
          {title: 'Underline', icon: 'underline', format: 'underline'},
          //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
          //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
          //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
          //{title: 'Code', icon: 'code', format: 'code'}
        ]},
        {title: 'Blocks', items: [
          {title: 'Paragraph', format: 'p'},
          {title: 'Blockquote', format: 'blockquote'},
          //{title: 'Div', format: 'div'},
          //{title: 'Pre', format: 'pre'}
        ]},
        {title: 'Alignment', items: [
          {title: 'Left', icon: 'alignleft', format: 'alignleft'},
          {title: 'Center', icon: 'aligncenter', format: 'aligncenter'},
          {title: 'Right', icon: 'alignright', format: 'alignright'},
          //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
        ]},
        {title: 'Small', selector: 'p', classes: 'small'}
      ],
      style_formats_merge: false,
      block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote'
    }},model:{value:(_vm.formBody),callback:function ($$v) {_vm.formBody=$$v},expression:"formBody"}}),_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){return _vm.saveRow()}}},[_vm._v(" Add to accordion ")]),_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){return _vm.cancelNewRow()}}},[_vm._v(" Cancel ")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }