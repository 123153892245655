<template>
    <v-flex
        v-if="data.length > 0"
        class="listings"
    >
        <div class="listings__header">
            <ul
                v-if="headers.length > 0"
                class="listings__header-items cols"
                :class="columns"
            >
                <li
                    class="listings__header-item"
                    v-for="(header, index) in headers"
                    :key="index"
                >
                    {{ header }}
                </li>
                <li class="listings__header-item">
                    Actions
                </li>
            </ul>
        </div>
        <div
            class="listings__data"
            v-for="(item, index) in data"
            :key="index"
            :data-id="item.playerID"
        >
            <ul
                :key="item.playerID"
                class="listings__data-items cols"
                :class="columns"
            >
                <li class="listings__data-item">
                    {{ item.optaId }}
                </li>
                <li class="listings__data-item">
                    {{ item.name }}
                </li>
                <li class="listings__data-item">
                    {{ item.position }}
                </li>
                <li class="listings__data-item">
                    {{ item.squadNumber }}
                </li>
                <li class="listings__data-item">
                    <span class="listings__header-item">Actions:</span>
                    <button
                        class="news-item__action"
                        type="button"
                        @click="dialogDelete = true, id = item.playerID, name = item.name"
                    >
                        <v-icon>delete</v-icon>
                    </button>
                    <button
                        class="news-item__action"
                        type="button"
                        @click="editItem(item.playerID)"
                    >
                        <v-icon>edit</v-icon>
                    </button>
                </li>
            </ul>
        </div>

        <template>
            <v-layout
                row
                justify-center
            >
                <v-dialog
                    v-model="dialogDelete"
                    persistent
                    max-width="360"
                >
                    <v-card>
                        <v-card-title class="headline">Delete player</v-card-title>
                        <v-card-text>Do you wish to delete <strong>{{ name }}</strong> from the squad listings?</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                depressed
                                @click="dialogDelete = false"
                            >Cancel</v-btn>
                            <v-btn
                                depressed
                                color="error"
                                @click="dialogDelete = false, deleteItem(id)"
                            >DELETE</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-layout>
        </template>
    </v-flex>
</template>





<script>
import axios from "axios"

export default {
    name: "Listings",

    props: {
        data: {
            type: Array,
            required: true
        },
        columns: {
            type: String,
            required: true
        },
        headers: {
            type: Array,
            required: false
        }
    },

    components: {},

    data: () => ({
        api: `${process.env.VUE_APP_BASEURL}/players/opta`,
        dialogDelete: false,
        id: false,
        name: false,
        squadListingsMap: [],
        firstTeamListings: [],
        ladiesTeamListings: [],
        u23TeamListings: []
    }),

    methods: {
        editItem(itemID) {
            this.$store.commit("startLoading");
            this.$router.push({
                path: `/player/${itemID}`
            })
        },

        deleteItem(itemID) {
            this.$store.commit("startLoading");
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "DELETE",
                    url: `${this.api}${itemID}`,
                    headers: { "Authorization": jwt }
                })
                    .then(response => {
                        console.log("Player deleted:", response);
                        this.$emit("getPlayers");
                        this.$store.commit("completeLoading");
                    })
                    .catch(e => {
                        console.error("Problem deleting player", e);
                        this.errorMessage = "Problem deleting player";
                        this.$store.commit("completeLoading");
                    });
            })
        }
    }

}
</script>

<style lang="scss">
%grid-100 {
    @media (min-width: 768px) {
        display: grid;
    }

    padding: 0;
    width: 100%;
}

.cols {
    @extend %grid-100;

    &-5 {
        grid-template-columns: 10% 30% 25% 25% 10%;
    }
}

.listings {
    width: 100%;

    &__title {
        padding-left: 20px;
    }

    &__data,
    &__header {
        width: 100%;
        padding: 25px 20px;
    }

    &__header {
        display: none;

        @media (min-width: 768px) {
            display: block;
        }

        &-item {
            font-weight: 500;
        }
    }

    &__data {
        border: 1px solid $lightgrey;
        margin: 2.5%;
        width: 95%;
        display: inline-block;

        @media (min-width: 768px) {
            border-left: 0;
            border-right: 0;
            border-bottom: 0;
        }

        @media (min-width: 640px) {
            width: 45%;
        }

        @media (min-width: 768px) {
            width: 100%;
            margin: 0;
        }

        .listings__header-item {
            display: inline-block;
            margin-right: 10px;

            @media (min-width: 768px) {
                display: none;
            }
        }

        &-item {
            font-size: 1rem;
            font-weight: lighter;

            @media (max-width: 767px) {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            &s {
                padding-left: 0;
            }
        }
    }
}
</style>
