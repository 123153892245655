import { render, staticRenderFns } from "./MatchesCarousel.vue?vue&type=template&id=32f452f7&scoped=true"
import script from "./MatchesCarousel.vue?vue&type=script&lang=js"
export * from "./MatchesCarousel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32f452f7",
  null
  
)

export default component.exports