<template>

  <v-dialog
      class="news-category-widget"
      v-model="dialog"
      width="580"
      lazy
    >

    <slot slot="activator"></slot>
      <v-form enctype="multipart/form-data" ref="singleFieldForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            class="widget__heading"
          >
            News Category
          </v-card-title>

          <v-card-text>
            <p class="widget__description">Shows the latest stories from your selected category</p>

            <label class="label" for="title">Title</label>
            <v-text-field
              id="title"
              outline
              
              v-model="updatedData.title"
              solo
              flat
              class="text-field--lableless"

            ></v-text-field>

            <label class="label" for="category">News Category</label>
            <v-select
              id="category"
              outline
              :rules="[rules.required]"
              v-model="updatedData.category"
              :items="categoryList"
              item-text="newsCategory"
              item-value="newsCategoryID"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
            ></v-select>

             <!-- item-value="newsCategoryID" something like this may be needed for getting the frontend working-->

            <label class="label" for="number">Number of stories</label>
            <v-select
              id="number"
              outline
              :rules="[rules.required]"
              v-model="updatedData.number"
              :items="['3', '6']"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
            ></v-select>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="accent"
              outline
              flat
              @click="validate()"
            >
              Post
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

  </v-dialog>

</template>

<script>
import axios from "axios"

export default {
  name: "NewsCategoryWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    dialog: false,
    valid: false,
    rules: {
      required: value => !!value || "Required."
    },
    updatedData: {
      title: null,
      category: null,
      number: null
    },
    categoryList: [],
    apiCategories: `${process.env.VUE_APP_NEWSAPI}/newscategories?pageSize=50`,
  }),

  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
    }

    this.fetchCategories()
  },

  methods: {
    fetchCategories() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        this.$store.commit("startLoading");
        axios
        .get(`${this.apiCategories}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("Fetch categories", response);
          this.categoryList = response.data.body;
          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving categories", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    updateData() {
      console.log("Update data:", this.updatedData);

      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
    },

    validate() {
      if (this.updatedData.title === "") {
        this.updatedData.title = null
      }

      if (this.updatedData.category === "") {
        this.updatedData.category = null
      }

      if (this.updatedData.number === "") {
        this.updatedData.number = null
      }

      this.errorMessage = ""
      if (this.$refs.singleFieldForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    }
  }
}
</script>

<style scoped lang='scss'>
.widget {
    &__description {
      margin: 0 0 20px;
      color: #7B6B80;
      font-size: 14px;
    }
  }
</style>
