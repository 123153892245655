export default {
  AccordionWidget: {
    enabled: true,
    includedInApp: true,
    includedInRSS: false,
    includedInWeb: true,
  },
  AdvertisingWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  AllFixturesWidget: {
    enabled: false,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  Anchor: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  CalendarWidget: {
    enabled: false,
    includedInApp: true,
    includedInRSS: false,
    includedInWeb: true,
  },
  ClubPromoWidget: {
    enabled: true,
    includedInApp: true,
    includedInRSS: false,
    includedInWeb: true,
  },
  ClubTVWidget: {
    enabled: true,
    includedInApp: true,
    includedInRSS: false,
    includedInWeb: true,
  },
  ContactWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  CtaWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  FileDownloadWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  FixturesListWidget: {
    enabled: true,
    includedInApp: true,
    includedInRSS: false,
    includedInWeb: true,
  },
  FootballBasicLineupWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  FootballBasicMatchStatsWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  FormWidget: {
    enabled: true,
    includedInApp: true,
    includedInRSS: false,
    includedInWeb: true,
  },
  FormAssemblyWidget: {
    enabled: false,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  GoogleFormsWidget: {
    enabled: false,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  GoogleMapsWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  GridImageWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  HorizontalLineWidget: {
    enabled: true,
    includedInApp: true,
    includedInRSS: false,
    includedInWeb: true,
  },
  InstagramWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  ImageGalleryWidget: {
    enabled: true,
    includedInApp: true,
    includedInRSS: false,
    includedInWeb: true,
  },
  ImageWidget: {
    enabled: true,
    includedInApp: true,
    includedInRSS: true,
    includedInWeb: true,
  },
  LeagueTableWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  LegacyImportWidget: {
    enabled: true,
    includedInApp: true,
    includedInRSS: true,
    includedInWeb: true,
  },
  ListWidget: {
    enabled: false,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  ListWidgetV2: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  MapDirectoryWidget: {
    enabled: true,
  },
  MatterportWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  NewsCarouselWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  NewsCategoryWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  OrganisationDetail: {
    enabled: false,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  OrganisationsWidget: {
    enabled: false,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  PlayerOfTheMatchWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  PlayerQuoteWidget: {
    enabled: false,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  PlayerStatsWidget: {
    enabled: false,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  PreviousMeetingsWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  QuoteWidget: {
    enabled: true,
    includedInApp: true,
    includedInRSS: false,
    includedInWeb: true,
  },
  RiddleWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  SharePriceWidget: {
    enabled: false,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  SoundcloudWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  SporcleWidget: {
    enabled: true,
    includedInApp: true,
    includedInRSS: false,
    includedInWeb: true,
  },
  StripeProductWidget: {
    enabled: false,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  SubscriptionsCTAWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  TextBlockWidget: {
    enabled: true,
    includedInApp: true,
    includedInRSS: true,
    includedInWeb: true,
  },
  ThirdPartyWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  ThirdPartyV2Widget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  TriviaWidget: {
    enabled: false,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  TweetWidget: {
    enabled: true,
    includedInApp: true,
    includedInRSS: false,
    includedInWeb: true,
  },
  UniqueWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  UpcomingFixturesWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  VimeoWidget: {
    enabled: true,
    includedInApp: false,
    includedInRSS: false,
    includedInWeb: true,
  },
  YoutubeWidgetV2: {
    enabled: true,
    includedInApp: true,
    includedInRSS: false,
    includedInWeb: true,
  },
  subMenus: {
    Forms: true,
    Fixtures: true,
    Football: true,
    News: true,
    Organisations: false,
    Player: false,
    Promotions: true,
    ThirdParty: true,
    Video: true,
  },
  UniqueWidgetList: [
    {
      name: "Button - Hospitality",
      widgetID: "ButtonHospitality",
    },
    {
      name: "Button - Tickets",
      widgetID: "ButtonTickets",
    },
    {
      name: "Button - Season Tickets",
      widgetID: "ButtonSeasonTickets",
    },
    {
      name: "Button - Tours",
      widgetID: "ButtonTours",
    },
    {
      name: "Button - Restaurant",
      widgetID: "ButtonRestaurant",
    },
    {
      name: "Celtic Pools Footer",
      widgetID: "CelticPoolsFooter",
    },
    {
      name: "Trip Advisor - Rating widget",
      widgetID: "TripAdvisorRating",
    },
    {
      name: "Trip Advisor - Review widget",
      widgetID: "TripAdvisorReview",
    },
  ],
};
