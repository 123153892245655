<template>
  <v-dialog
    class="formassembly-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
        FormAssembly
        </v-card-title>

        <v-card-text>

        <v-form enctype="multipart/form-data" v-model="valid" ref="formAssemblyForm" lazy-validation>

          <section>

            <label class="label" for="title">Title (optional)</label>
            <v-text-field
              id="title"
              outline
              v-model="formAssemblyData.title"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
            ></v-text-field>

            <label class="label" for="description">Description (optional)</label>
            <v-text-field
              id="description"
              outline
              v-model="formAssemblyData.description"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
            ></v-text-field>

            <label class="label" for="type">Embed Type</label>
            <v-select
              v-model="formAssemblyData.type"
              :items="[{text: 'i-frame', value: 'iframe'}, {text: 'html', value: 'html'}]"
            >
            </v-select>

            <div v-if="formAssemblyData.type === 'iframe'">
              <label class="label" for="description">FormAssembly Embed Code</label>
              <v-textarea
              id="widget-field"
              v-model="formAssemblyData.embed"
              :placeholder="iframePlaceholder"
              solo
              flat
              required
              :rules="[rules.required]"
              validate-on-blur
            ></v-textarea>

            </div>

            <div v-else>
              <p>Currently Gamechanger only supports i-frame FormAssembly embeds</p>
            </div>

            <v-btn
              color="accent"
              outline
              flat
              @click="dialog = false"
            >
              Cancel
            </v-btn>

            <v-btn
              color="accent"
              depressed
              :disabled="!formAssemblyData.embed"
              @click="validate()"
            >
              <span v-if="widgetData">Update FormAssembly Widget</span>
              <span v-else>Add FormAssembly Widget</span>
            </v-btn>
          </section>

        </v-form>

        </v-card-text>
      </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "FormAssemblyWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}`,
    dialog: false,
    valid: false,
    rules: {
      required: value => !!value || "Required."
    },
    iframePlaceholder: "<iframe src=\"https://tfaforms.com/YOUR_FORM_ID\" height=\"800\" width=\"600\" frameborder=\"0\"></iframe>",
    formAssemblyData: {
      title: null,
      description: null,
      type: null,
      embed: null
    }
  }),

  mounted() {
    console.log("FormAssembly widget mounted");
    
    if (this.widgetData) {
      this.formAssemblyData = JSON.parse(JSON.stringify(this.widgetData));
    }
  },

  methods: {

    validate() {
      this.errorMessage = ""
      if (this.$refs.formAssemblyForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },

    updateData() {
      if (this.formAssemblyData.title === "") {
        this.formAssemblyData.title = null
      }
      if (this.formAssemblyData.description === "") {
        this.formAssemblyData.description = null
      }
      if (this.formAssemblyData.embed === "") {
        this.formAssemblyData.embed = null
      }
      if (this.formAssemblyData.type === "") {
        this.formAssemblyData.type = null
      }

      console.log("Update data:", this.formAssemblyData);
      this.$emit("updatedData", this.formAssemblyData);
      this.dialog = false;
    },
  }
}
</script>

<style lang="scss" scoped>
  .edit {
    margin: 0 10px 20px 0;
    width: 100%;
  }

</style>
