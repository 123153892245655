<template>
  <v-dialog class="fixtures-list-widget" v-model="dialog" width="580" lazy>
    <slot slot="activator"></slot>

    <v-card>
      <v-card-title class="widget__heading"> Fixtures </v-card-title>

      <v-card-text>
        <v-card-text
          v-if="!addFixtureForm"
          class="fixtures-list-widget__add"
          @click="clearFixtureForm(), (addFixtureForm = true)"
        >
          <v-icon color="#fff" class="fixtures-list-widget__icon">add</v-icon>
          <span class="fixtures-list-widget__text">Add fixture</span>
        </v-card-text>

        <v-form
          v-if="addFixtureForm"
          enctype="multipart/form-data"
          v-model="valid"
          ref="fixtureWidgetForm"
          lazy-validation
        >
          <match-picker-efl
            v-if="client == 'efl'"
            @updated="setMatchEfl($event)"
          />
          <match-picker v-else @updated="setMatch($event)" />

          <v-btn color="accent" outline flat @click="addFixtureForm = false">
            Cancel
          </v-btn>

          <v-btn
            v-if="!singleFixtureListData.guid"
            color="accent"
            depressed
            :disabled="!singleFixtureListData.matchID || !allowAdd"
            @click="validate()"
          >
            Add fixture
          </v-btn>

          <v-btn
            v-if="singleFixtureListData.guid"
            color="accent"
            depressed
            :disabled="!matches.length"
            @click="validate(singleFixtureListData.guid)"
          >
            Update fixture
          </v-btn>
        </v-form>
      </v-card-text>

      <v-card-text v-if="multiFixtureListData.length">
        <draggable
          v-model="multiFixtureListData"
          :options="{
            ghostClass: 'ghost',
            dragClass: 'sortable-drag',
          }"
          @start="drag = true"
          @end="drag = false"
        >
          <transition-group
            name="list-complete"
            class="fixtures-list-widget__fixtures"
          >
            <div
              class="fixtures-list-widget__fixtures-item draggable-item"
              v-for="(fixture, key) in multiFixtureListData"
              :key="fixture.guid"
            >
              <div class="fixtures-list-widget__fixtures-item-left">
                <div
                  class="fixtures-list-widget__fixtures-item-crest"
                  v-if="fixture.matchDetails[0].matchHomeCrest"
                  :style="`background-image:url('${
                    fixture.matchDetails[0].matchHomeCrest.startsWith('http')
                      ? fixture.matchDetails[0].matchHomeCrest
                      : `${imagePath}/${fixture.matchDetails[0].matchHomeCrest}`
                  }')`"
                ></div>
                <ul class="fixtures-list-widget__fixtures-item-details">
                  <li>
                    {{ fixture.matchDetails[0].matchLabel }}
                  </li>
                  <li>
                    {{ fixture.matchDetails[0].seasonName }}
                  </li>
                </ul>
              </div>
              <div class="fixtures-list-widget__fixtures-item-action">
                <button type="button" @click="deleteFixtureItem(key)">
                  <v-icon>delete</v-icon>
                </button>
                <!-- <button type="button" @click="editFixtureItem(fixture.guid)">
                    <v-icon>edit</v-icon>
                  </button> -->
              </div>
            </div>
          </transition-group>
        </draggable>
      </v-card-text>

      <v-card-actions v-if="multiFixtureListData.length">
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          outline
          flat
          @click="postFixtures(), (dialog = false)"
        >
          Post
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import moment from "moment";
import MatchPicker from "../Library/MatchPicker.vue";
import MatchPickerEfl from "../Library/MatchPickerEfl.vue";

export default {
  name: "FixturesListWidget",

  components: {
    draggable,
    MatchPicker,
    MatchPickerEfl,
  },

  props: {
    widgetData: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/fixtures`,
    dialog: false,
    addFixtureForm: false,
    valid: false,
    fixturesConfig: [],
    singleFixtureListData: {
      squad: null,
      season: null,
      competition: null,
      matchID: null,
      matchDetails: null,
    },
    squads: [],
    seasons: [],
    competitions: [],
    matches: [],
    rules: {
      required: (value) => !!value || "Required.",
    },
    multiFixtureListData: [],
    matchHTMLLabel: "Select Match",
    imagePath: `${process.env.VUE_APP_IMAGEURL}`,
    allowAdd: false,
    client: process.env.VUE_APP_CLUB_ID,
  }),

  mounted() {
    console.log("Fixture list widget mounted");
    console.log(this.widgetData);
    if (this.widgetData) {
      this.multiFixtureListData = JSON.parse(
        JSON.stringify(this.widgetData.fixtures)
      );
    }
  },

  methods: {
    dataSorted() {
      this.allowAdd = true;
    },
    getFixtures(e) {
      this.allowAdd = false;
      this.singleFixtureListData.competition = e;
      this.$store.commit("startLoading");
      this.matchHTMLLabel = "Loading...";
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(
            `${this.api}/opta?clientMatches=true&teamID=${this.singleFixtureListData.squad}&seasonID=${this.singleFixtureListData.season}&pageSize=100`,
            { headers: { Authorization: jwt } }
          )
          .then((response) => {
            if (response.data.totalDataCount) {
              this.matches = response.data.body.map((match) => {
                return {
                  matchID: match.matchID,
                  matchLabel:
                    match.teamData[0].teamName +
                    " Vs. " +
                    match.teamData[1].teamName +
                    " - " +
                    moment(match.kickOffUTC).format("ddd, MMM Do YYYY, HH:mm"),
                  matchHomeCrest: match.teamData[0]
                    ? match.teamData[0].teamCrest
                    : "",
                  matchAwayCrest: match.teamData[1]
                    ? match.teamData[1].teamCrest
                    : " ",
                  seasonName: match.seasonName,
                  // competitionName: match.competitionName
                };
              });
              this.$store.commit("completeLoading");

              setTimeout(() => {
                this.dataSorted();
              }, 1000);

              this.matchHTMLLabel = "Select Match";
              console.log(this.matches);
            } else {
              this.matchHTMLLabel = "No matches found";
            }
          })
          .catch((e) => {
            console.error("Problem retrieving fixtures", e);
            this.$store.commit("completeLoading");
          });
      });
    },
    setMatch(e) {
      console.log("setting match", e);
      this.singleFixtureListData.matchID = e.matchID;
      this.singleFixtureListData.season = e.seasonID;
      this.singleFixtureListData.squad = e.teamID;

      this.getFixtures(e);
    },
    setMatchEfl(e) {
      console.log("setting match", e);
      this.singleFixtureListData.matchID = e.matchID;
      this.singleFixtureListData.season = e.seasonID;
      this.singleFixtureListData.squad = e.teamID;

      this.matches = [
        {
          matchID: e.matchID,
          matchLabel:
            e.matchData.homeTeam.name +
            " Vs. " +
            e.matchData.awayTeam.name +
            " - " +
            moment(e.matchData.kickOffDateUTC).format(
              "ddd, MMM Do YYYY, HH:mm"
            ),
          matchHomeCrest: e.matchData.homeTeam
            ? e.matchData.homeTeam.crest
            : "",
          matchAwayCrest: e.matchData.awayTeam
            ? e.matchData.awayTeam.crest
            : " ",
          seasonName: e.seasonID,
        },
      ];

      this.allowAdd = true;
    },
    validate(guid) {
      this.errorMessage = "";
      // if (this.$refs.fixtureWidgetForm.validate()) {
      // console.log('Validation passed', 'add fixture method fired', 'guid|',guid);
      this.addFixture(guid);
      // } else {
      //   this.errorMessage = 'Certain entries are invalid or required'
      // }
    },
    addFixture(guid) {
      this.singleFixtureListData.matchDetails = this.matches.filter(
        (match) => match.matchID === this.singleFixtureListData.matchID
      );
      if (!guid) {
        this.singleFixtureListData.guid = new Date().getTime();
        this.multiFixtureListData.push(this.singleFixtureListData);
      }
      this.clearFixtureForm();
      this.matches = [];
      this.addFixtureForm = false;
      console.log(this.multiFixtureListData);
    },
    postFixtures() {
      console.log("postFixtures", this.multiFixtureListData);
      this.$emit("updatedData", { fixtures: this.multiFixtureListData });
    },
    deleteFixtureItem(index) {
      console.log("Deleting index:", index);
      this.multiFixtureListData.splice(index, 1);
    },
    editFixtureItem(id) {
      console.log("Editting...", id);
      const editFixtureItem = this.multiFixtureListData.filter(
        (fixture) => fixture.guid === id
      );
      this.singleFixtureListData = editFixtureItem[0];
      console.log(this.singleFixtureListData);
      this.getSeasons(this.singleFixtureListData.squad);
      this.getCompetitions(this.singleFixtureListData.season);
      this.getFixtures(this.singleFixtureListData.competition);
      this.addFixtureForm = true;
    },
    clearFixtureForm() {
      this.singleFixtureListData = {
        squad: null,
        season: null,
        // competition: null,
        matchID: null,
        matchDetails: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-item {
  transition: all 0.5s, opacity 0.2s;
}
.fixtures-list-widget {
  &__add {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__icon {
    background-color: $purple;
    border-radius: 50rem;
    padding: 10px;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
  }
  &__text {
    color: $lightPurple;
    padding: 10px;
  }
  &__fixtures {
    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      border-top: 1px solid $lightgrey;
      padding: 20px;
      justify-content: space-between;
      &-details {
        padding-left: 20px;
        li {
          color: $grey;
          font-size: 0.9rem;
          margin-bottom: 5px;
        }
      }
      &-crest {
        width: 64px;
        height: 64px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      &:last-child {
        border-bottom: 1px solid $lightgrey;
      }
      &-action {
        display: flex;
      }
      &-left {
        display: flex;
        flex-flow: row;
      }
    }
  }
}
</style>
