<template>
  <section class="match-picker">
    <v-select
      class="item"
      v-if="fixturesConfig"
      :items="fixturesConfig"
      v-model="matchData.teamID"
      label="Select Team"
      item-value="teamID"
      item-text="teamName"
      @change="setSeasons(matchData.teamID)"
    >
    </v-select>

    <v-select
      class="item"
      :items="seasons"
      v-model="matchData.seasonID"
      label="Select Season"
      item-value="seasonID"
      item-text="seasonName"
      :disabled="matchData.teamID === null"
      @change="getFixtures()"
    >
    </v-select>

    <template v-if="matchData.seasonID">
      <p v-if="!matchesLoaded">loading matches...</p>
      <v-select
        v-else
        class="item"
        :items="matches"
        item-value="matchID"
        item-text="matchLabel"
        v-model="matchData.matchID"
        label="Select Match"
        :disabled="matchData.seasonID === null"
        @change="updateMatch()"
      >
      </v-select>
    </template>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "ContentFeedMatchPicker",

  mounted() {
    this.fetchFixturesConfig();
  },

  data: () => ({
    matchData: {
      teamID: null,
      seasonID: null,
      matchID: null,
    },
    seasons: [],
    matches: [],
    fixturesConfig: null,
    matchesLoaded: false,
  }),

  methods: {
    updateMatch() {
      this.$emit("updated", this.matchData);
    },

    fetchFixturesConfig() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${process.env.VUE_APP_BASEURL}/fixtures/opta/filters`, {
            headers: { Authorization: jwt },
          })
          .then((response) => {
            // console.log('fetchFixturesConfig: ', response);
            this.fixturesConfig = response.data.body;

            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem retrieving fixtures", e);
            this.$store.commit("completeLoading");
          });
      });
    },
    setSeasons(team) {
      console.log("seasons for which team?", team);
      this.matchData.teamID = team;

      for (const team of this.fixturesConfig) {
        console.log("team in fixtureConfig", team);
        if (team.teamID === this.matchData.teamID) {
          this.seasons = team.seasons;
        }
      }
    },

    getFixtures() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(
            `${process.env.VUE_APP_BASEURL}/fixtures/opta?clientMatches=true&teamID=${this.matchData.teamID}&seasonID=${this.matchData.seasonID}&pageSize=100`,
            { headers: { Authorization: jwt } }
          )
          .then((response) => {
            console.log("Fetch fixtures response: ", response);
            if (response.data.totalDataCount) {
              this.matches = response.data.body.map((match) => {
                return {
                  matchID: match.matchID,
                  matchLabel:
                    match.teamData[0].teamName +
                    " Vs. " +
                    match.teamData[1].teamName +
                    " - " +
                    moment(match.kickOff).format("ddd, MMM Do YYYY, HH:mm"),
                };
              });

              console.log(this.matches);
              this.matchesLoaded = true;
            }
          })
          .catch((e) => {
            console.error("Problem retrieving fixtures", e);
          });
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.match-picker {
  display: flex;
  flex-direction: row;
  padding: 0;
  border: 0;
  background-color: transparent;
  margin: 0;
}

.item {
  padding-right: 16px;
}
</style>
