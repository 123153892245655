<template>
    <section>
        <div class="section-header">
            <div class="title-container">
                <img
                    class="header-icon"
                    :src="require('../../../../assets/advert.svg')"
                    alt=""
                />
                <h2 class="section-title">Campaigns</h2>
            </div>
            <advert-modal @createAd="fetchAdverts()">
                <v-btn
                    class="add-button"
                    accent
                    type="button"
                    color="accent"
                    depressed
                ><v-icon medium>add</v-icon>Create New Advert</v-btn>
            </advert-modal>
        </div>
        <p class="section-description">
            Campaigns are a set of consistent advertising assets across multiple
            sizes. Assign campaigns to slots to display them live.
        </p>
        <p
            v-if="error"
            class="error-message"
        >{{ error }}</p>
        <p v-if="loading">Loading...</p>

        <ul v-else>
            <li
                v-for="ad in adsData"
                :key="ad.index"
                class="ad-item"
            >
                <div class="ad-item__top">
                    <h2 class="ad-item__title">
                        {{ ad.attributes.advertTitle }} - {{ ad.attributes.advertType }} ad
                    </h2>
                    <div class="ad-item__icons">
                        <confirmation-modal
                            @confirmed="copyAd(ad.id)"
                            class="ad-item__modal"
                            title="Copy content"
                            text="Are you sure you want to copy this ad?"
                        >
                            <v-icon class="ad-item__icon">content_copy</v-icon>
                        </confirmation-modal>
                        <advert-modal
                            :adData="ad"
                            :editing="true"
                            @editAd="fetchAdverts()"
                            class="icon-spacing"
                        >
                            <v-icon class="ad-item__icon">edit</v-icon>
                        </advert-modal>
                        <confirmation-modal
                            @confirmed="deleteAd(ad.id)"
                            class="ad-item__modal icon-spacing"
                        >
                            <v-icon class="ad-item__icon ad-item__icon--delete">delete</v-icon>
                        </confirmation-modal>
                    </div>
                </div>

                <div class="ad-item__ads">
                    <div class="ad-item__ad">
                        <div class="ad-item__ad-top mb-14">
                            <h6
                                v-if="ad"
                                class="ad-item__ad-title"
                            >
                                {{
                                    ad.attributes.advertType == "adition"
                                    ? "Adition Ad"
                                    : ad.attributes.advertData.url
                                        ? ad.attributes.advertData.url.length > 35
                                            ? ad.attributes.advertData.url.substring(0, 35) + "..."
                                            : ad.attributes.advertData.url
                                        : "No URL defined"
                                }}
                            </h6>
                        </div>
                        <p class="ad-item__ad-date">
                            Start:
                            <span v-if="ad.attributes.advertData.publicationData.startAt">
                                {{
                                    ad.attributes.advertData.publicationData.startAt
                                    | moment("DD/MM/YYYY (HH:mm:ss)")
                                }}
                            </span>
                            <span v-else>-</span>
                        </p>
                        <p class="ad-item__ad-date">
                            End:
                            <span v-if="ad.attributes.advertData.publicationData.finishAt">
                                {{
                                    ad.attributes.advertData.publicationData.finishAt
                                    | moment("DD/MM/YYYY (HH:mm:ss)")
                                }}
                            </span>
                            <span v-else>-</span>
                        </p>
                        <p class="ad-item__child-friendly">
                            Child Friendly: {{ ad.attributes.childFriendly ? 'Yes' : 'No' }}
                        </p>
                    </div>

                    <div
                        class="ad-item__ad"
                        v-if="ad.attributes.advertData.images &&
                            ad.attributes.advertData.images.desktop.key
                            "
                    >
                        <div class="ad-item__ad-top ad-item__ad-top--image mb-14">
                            <div
                                class="ad-item__image"
                                :style="`background-image: url('${imageURL}/${ad.attributes.advertData.images.desktop.key}')`"
                            ></div>
                        </div>
                        <div class="ad-item__ad-sizes">
                            <p class="ad-item__ad-date ad-item__ad-date--image">
                                Desktop Container size:
                                {{
                                    ad.attributes.advertData.images.desktop.imageSize || "None"
                                }}
                            </p>
                        </div>
                    </div>

                    <div
                        class="ad-item__ad"
                        v-if="ad.attributes.advertData.images &&
                                ad.attributes.advertData.images.mobile.key
                                "
                    >
                        <div class="ad-item__ad-top ad-item__ad-top--image mb-14">
                            <div
                                class="ad-item__image"
                                :style="`background-image: url('${imageURL}/${ad.attributes.advertData.images.mobile.key}')`"
                            ></div>
                        </div>
                        <div class="ad-item__ad-sizes">
                            <p class="ad-item__ad-date ad-item__ad-date--image">
                                Mobile Container size:
                                {{ ad.attributes.advertData.images.mobile.imageSize || "None" }}
                            </p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </section>
</template>

<script>
import axios from "axios";
import IconSelector from "@/components/Library/IconSelector";
import ConfirmationModal from "@/components/Library/GlobalModals/ConfirmationModal";
import AdvertModal from "./AdvertModal";

export default {
    name: "Advertising2Adverts",

    components: {
        IconSelector,
        ConfirmationModal,
        AdvertModal,
    },

    data: () => ({
        adsData: [],
        advertsAPI: process.env.VUE_APP_ADVERTISINGAPI,
        imageURL: process.env.VUE_APP_IMAGEURL,
        error: "",
        loading: false,
    }),

    created() {
        this.fetchAdverts();
    },

    methods: {
        fetchAdverts() {
            this.loading = true;
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.advertsAPI}/adverts?page.size=100`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log("Adverts fetched", response);
                        this.$emit("advertsFetched", response.data.data);
                        this.adsData = response.data.data;
                    })
                    .catch((e) => {
                        this.error = "An Error has occurred";
                        console.error("Problem fetching ads", e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        deleteAd(id) {
            this.loading = true;
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "DELETE",
                    url: `${this.advertsAPI}/adverts/${id}`,
                    headers: { Authorization: jwt },
                })
                    .then(() => {
                        this.fetchAdverts();
                    })
                    .catch((e) => {
                        window.alert("An Error has occurred");
                        console.error("Problem deleting ads", e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        copyAd(id) {
            if (!this.loading) {
                this.error = "";
                this.loading = true;

                const data = this.adsData.find((el) => el.id == id);
                data.id = this.$uuid.v1();
                data.attributes.advertTitle = data.attributes.advertTitle + " (copy)";
                delete data.links;

                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "POST",
                        url: `${this.advertsAPI}/adverts`,
                        headers: { Authorization: jwt },
                        data: {
                            data,
                        },
                    })
                        .then((response) => {
                            console.log("Advert Copied", response);
                            this.fetchAdverts();
                        })
                        .catch((e) => {
                            this.error = "An Error has occurred";
                            console.error("Problem creating ads", e);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                });
            }
        },
    },
};
</script>

<style scoped lang='scss'>
.section-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.header-icon {
    margin-right: 15px;
}

.title-container {
    display: flex;
    align-items: center;
}

.section-title {
    color: #67247c;
    font-size: 20px;
    font-weight: 500;
    margin-right: 10px;
}

.add-button {
    font-weight: 500;
    font-size: 14px;
    text-transform: none;
    margin: 0;

    i {
        font-weight: bold;
        margin-right: 10px;
    }
}

.section-description {
    max-width: 648px;
    color: #516173;
    font-size: 14px;
    margin-bottom: 24px;
}

ul {
    padding-left: 0;
}

.ad-item {
    padding: 24px;
    background: #ffffff;
    border: 1px solid #dfe3e9;
    border-radius: 4px;
    margin-bottom: 8px;

    &__top {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }

    &__title {
        margin-right: auto;
        font-size: 16px;
        font-weight: 500;
        color: #7b6b80;
    }

    &__icons {
        margin-left: 10px;
        display: flex;
        align-items: center;
    }

    &__icon {
        cursor: pointer;

        &--delete {
            margin-left: 0;
        }
    }

    &__ads {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    &__ad {
        background: #fafafa;
        border: 1px solid #dfe3e9;
        display: inline-block;
        padding: 14px;
        min-width: 174px;
        max-width: 400px;
        border-radius: 4px;
    }

    &__ad-top {
        display: flex;
        align-items: center;

        &--image {
            margin-bottom: 8px !important;
        }
    }

    &__ad-title {
        color: #7b6b80;
        font-size: 14px;
        font-weight: 500;
        margin-right: auto;
    }

    &__ad-edit {
        font-size: 14px;
        margin-left: 6px;
        cursor: pointer;
    }

    &__ad-date,
    &__child-friendly {
        margin-bottom: 6px;
        color: #7b6b80;
        font-size: 10px;
        font-weight: 500;

        &--image {
            margin-bottom: 0;
            text-align: center;
        }
    }

    &__modal {
        display: inline-block;
    }

    &__sizes {
        text-align: center;
    }

    &__image {
        width: 77px;
        padding-bottom: 28.125%;
        margin: auto;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }
}

.icon-spacing {
    margin-left: 24px;
}

.mb-14 {
    margin-bottom: 14px;
}

.error-message {
    margin: 10px 0;
    color: #d61244;
    font-size: 14px;
}
</style>
