<template>
  <v-dialog
    class="add-advert"
    v-model="dialog"
    persistent
    width="580"
    lazy
  >

    <slot slot="activator"></slot>
      <v-card>
        <v-card-title>Create Advert</v-card-title>

        <v-card-text>
          <v-text-field label="Advert name" placeholder="Advert name" v-model="advertData.advertName"/>

          <v-text-field label="Advert campaign" placeholder="Advert campaign" v-model="advertData.advertCampaign"/>

          <ImageUploader :title="'Advert image'" :widthOptionEnabled="false" :mediaLibraryID="advertData.mediaLibraryID" @update="updateImage($event)"/>

          <v-text-field label="Clickthrough URL" placeholder="https://" v-model="advertData.targetUrl"/>

          <v-switch v-model="advertData.childFriendly" :label="`Allow display on child friendly pages : ${advertData.childFriendly.toString()}`"></v-switch>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outline
            flat
            @click="cancel()"
          >
            Cancel
          </v-btn>

          <v-btn
            color="accent"
            outline
            flat
            :disabled="!advertData.advertName"
            @click="createAdvert()"
          >
            Add advert
          </v-btn>
        </v-card-actions>
      </v-card>

  </v-dialog>
</template>

<script>
import ImageUploader from "@/components/Library/ImageUploader"

export default {
  name: "AdvertisingAdditem",

  components: {
    ImageUploader
  },

  data: () => ({
    dialog: true,
    advertData: {
      advertName: "",
      advertCampaign: "",
      mediaLibraryID: null,
      targetUrl: "",
      childFriendly: false,
      weighting: 0
    }
  }),

  methods: {
    cancel() {
      this.$emit("cancelled")
    },

    createAdvert() {
      this.$emit("added", this.advertData)
    },

    updateImage(payload) {
      console.log("updateImage", payload)
      this.advertData.mediaLibraryID = payload.mediaLibraryID
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
