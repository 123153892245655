<template>
  <section class="entitlements">
    <inline-loader v-if="loading" />

    <template v-else>
      <h3 class="subheading">Advert slots</h3>
      <section class="section-container" v-for="advert in advertSlots" :key="advert.id">
        <v-layout row>
          <v-flex mr-5 class="third-x2">
            <!-- <pre>{{ advertSlots }}</pre> -->
            <div>
              <label class="label">Slot - {{ advert.id }}</label>
              <v-textarea
                v-model="advert.attributes.url"
                solo
                flat
              ></v-textarea>
            </div>
          </v-flex>

          <v-flex mt-4 class="third">
            <p>Advert slots have defined use cases and are preconfigured to run in the platform.</p>
            <p><i>Description: {{ advert.attributes.description }}</i></p>
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex mr-5 class="third-x2">
            <v-btn
              type="button"
              depressed
              color="success"
              @click="saveAdvertSlot(advert)"
            >
              Save Slot
            </v-btn>
          </v-flex>
        </v-layout>
      </section>

    </template>
  </section>
</template>

<script>
import axios from "axios"
import InlineLoader from "@/components/Library/InlineLoader"
import MiniHeader from "@/components/Library/MiniHeader"

export default {
  name: "StreamlineAdvertisingManager",

  components: {
    InlineLoader,
    MiniHeader
  },

  data:() => ({
    loading: false,
    advertSlots: [],
    newEntitlement: ""
  }),

  mounted() {
    this.fetchAdvertSlots()
  },

  methods: {
    saveAdvertSlot(advert) {
      console.log("Update Advert Slot")
      let url = ""

      if (advert.attributes.url.length) {
        url = advert.attributes.url
      }

      if (confirm("Are you sure you want to save this advert slot?")) {
        // this.creating = true

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "PATCH",
            url: `${process.env.VUE_APP_STREAMLINEAPI}/adverts/slots/${advert.id}`,
            headers: { "Authorization": jwt },
            data: {
              data: {
                type: "streamline-advert-slot",
                id: advert.id,
                attributes: {
                  url: url
                }
              }
            }
          })
          .then(response => {
            console.log("Create entitlement:", response);
            this.fetchAdvertSlots()
          })
          .catch(e => {
            console.error("Problem updated advert slot", e);
            this.$store.commit("completeLoading");
          });
        })
      }
    },

    fetchAdvertSlots() {
      this.loading = true

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/adverts/slots?page[size]=1000`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Fetch advert slots:", response);
          this.advertSlots = response.data.data
          this.$store.commit("completeLoading");
          this.loading = false
        })
        .catch(e => {
          console.error("Problem fetching advert slots", e);
          this.$store.commit("completeLoading");
          this.loading = false
        });
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .entitlements {

  }

  .half {
    width: 50%;
  }

  .third-x2 {
    width: 66.66%;
  }

  .third {
    width: 33.33%;
  }

  .section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #DFE3E9;
  }
</style>
