<template>
    <section>
        <div class="section-header">
            <div class="title-container">
                <icon-selector
                    class="header-icon"
                    icon="Slot"
                />
                <h2 class="section-title">Footer Ads</h2>
            </div>

            <v-btn
                v-if="isNewSlot"
                color="accent"
                @click="createFooterSlot()"
                :class="{ 'no-interaction': isCreatingSlot }"
            >
                <v-icon medium>add</v-icon>
                {{ isCreatingSlot ? 'Creating footer slot...' : 'Create Footer Slot' }}
            </v-btn>
        </div>
        <p class="section-description">
            The footer slots is where the footer adverts are defined. Unlike the other slots, the footer slot displays all adverts inside it in
            the order that they are placed and with a width of 300px and flexible height.
        </p>
        <p v-if="loading">Loading...</p>
        <advertising2-slot
            v-else
            @fetchSlots="$emit('fetchSlots')"
            :adverts="adverts"
            :slotData="footerSlot"
            :sortable="true"
        />
    </section>
</template>

<script>
import axios from 'axios'
import Advertising2Slot from './Advertising2Slot.vue'
import IconSelector from "@/components/Library/IconSelector";
import SlotModal from "./SlotModal";

export default {
    name: 'FooterSlot',

    components: {
        IconSelector,
        SlotModal,
        Advertising2Slot
    },

    props: {
        footerSlot: {
            type: Object,
            required: false,
        },
        isNewSlot: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: true,
        },
        adverts: {
            type: Array,
            required: false,
        },
    },

    data: () => ({
        advertsAPI: process.env.VUE_APP_ADVERTISINGAPI,
        isCreatingSlot: false,
    }),

    methods: {
        createFooterSlot() {
            const title = "footer slot"
            const id = "footer_slot"
            this.isCreatingSlot = true

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: `${this.advertsAPI}/slots/${id}`,
                    headers: { Authorization: jwt },
                    data: {
                        data: {
                            type: "slot",
                            id,
                            attributes: {
                                slotTitle: title,
                                advertSlotID: id,
                                adverts: [],
                            },
                        },
                    },
                })
                    .then((response) => {
                        this.$emit("slotCreated");
                        this.cancel();
                    })
                    .catch((e) => {
                        console.error("Problem editing slot", e);
                    })
                    .finally(() => {
                        this.isCreatingSlot = false;
                    });
            });
        }
    }
}
</script>

<style scoped lang='scss'>
.section-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.header-icon {
    margin-right: 15px;
    color: #67247c;
}

.title-container {
    display: flex;
    align-items: center;
}

.section-title {
    color: #67247c;
    font-size: 20px;
    font-weight: 500;
    margin-right: 10px;
}

.add-button {
    font-weight: 500;
    font-size: 14px;
    text-transform: none;
    margin: 0;

    i {
        font-weight: bold;
        margin-right: 10px;
    }
}

.section-description {
    max-width: 648px;
    color: #516173;
    font-size: 14px;
    margin-bottom: 24px;
}

.no-interaction {
    pointer-events: none;
}
</style>
