<template>
    <section>
        <v-btn
            class="success"
            @click="reload()"
        >Reload Feed</v-btn>

        <section
            class="content-grid"
            v-if="feedData"
        >
            <ul v-if="feedData">
                <section
                    class="form-card"
                    v-for="gridItem in feedData"
                    :key="gridItem.id"
                >
                    <div class="form-card__intro">
                        <h1 v-if="gridItem.contentType === 'news'">
                            <span class="content-type">News</span><br />{{
                                gridItem.content.postTitle
                            }}
                        </h1>

                        <h1 v-else-if="gridItem.contentType === 'streamVid'">
                            <span class="content-type">Video</span><br />{{
                                gridItem.content.title
                            }}
                            - {{ gridItem.content.streamID }}
                        </h1>

                        <h1 v-else-if="gridItem.contentType === 'streamlineVid'">
                            <span class="content-type">Video</span><br />{{
                                gridItem.content.title
                            }}
                            - {{ gridItem.content.streamID }}
                        </h1>

                        <template v-else-if="gridItem.contentType === 'instagramTemp'">
                            <h1><span class="content-type">Instagram</span><br /></h1>
                            <a
                                :href="gridItem.content.externalUrl"
                                target="_blank"
                                class="link"
                            >{{ gridItem.content.externalUrl }}</a>
                        </template>

                        <template v-else-if="gridItem.contentType === 'twitterTemp'">
                            <h1><span class="content-type">X</span><br /></h1>
                            <a
                                :href="gridItem.content.externalUrl"
                                target="_blank"
                                class="link"
                            >{{ gridItem.content.externalUrl }}</a>
                        </template>

                        <h1 v-else-if="gridItem.contentType === 'match'">
                            <span class="content-type">Match</span><br />{{
                                gridItem.content.teamData[0].teamName
                            }}
                            vs {{ gridItem.content.teamData[1].teamName }}
                        </h1>

                        <h1 v-else-if="gridItem.contentType === 'socialMediaEmbed'">
                            <span class="content-type">Social Media Embed</span><br />{{
                                gridItem.content.socialMediaType
                            }}
                        </h1>

                        <h1 v-else>
                            <span class="content-type">Promo</span><br />{{ gridItem.title }}
                        </h1>
                    </div>

                    <div
                        class="form-card__actions"
                        v-if="gridItem.contentType && gridItem.contentType == 'promo'"
                    >
                        <confirmation-modal
                            @confirmed="deletePromo(gridItem.agcID)"
                            title="PROMO DELETION"
                            text="Deleting this item will completely delete the promo and all its instances, not just this one. Do you want to continue?"
                        >
                            <button class="form-card__action">
                                <v-icon>delete</v-icon>
                            </button>
                        </confirmation-modal>
                    </div>
                    <div
                        class="form-card__actions"
                        v-else-if="gridItem.contentType && gridItem.contentType != 'news'"
                    >
                        <button
                            class="form-card__action"
                            @click="deleteItem(gridItem.agcID, gridItem.contentType)"
                        >
                            <v-icon>delete</v-icon>
                        </button>
                    </div>
                </section>
            </ul>
        </section>
    </section>
</template>

<script>
import axios from "axios";
import ConfirmationModal from "@/components/Library/GlobalModals/ConfirmationModal";

export default {
    name: "ContentFeedPreview",

    data: () => ({
        feedData: null,
        previewApi: `${process.env.VUE_APP_FEEDAPI_PREVIEW}?page.size=100`,
    }),

    components: {
        ConfirmationModal,
    },

    mounted() {
        this.fetchContent();
    },

    methods: {
        fetchContent() {
            console.warn(
                "fetching content from:",
                `${process.env.VUE_APP_FEEDAPI_PREVIEW}`
            );
            console.log("here", this.previewApi);
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: this.previewApi,
                    headers: {
                        Authorization: jwt,
                    },
                }).then((response) => {
                    console.log("aggregatedContentWebData", response.data.body);
                    this.mapToFeedV2(response.data);
                });
            });
        },
        mapToFeedV2(v1Data) {
            this.feedData = v1Data.data.map((item) => {
                return {
                    timestampOrder: item.attributes && item.attributes.timestamp,
                    contentType: item.attributes && item.attributes.contentType,
                    title: item.attributes && item.attributes.title,
                    agcID:
                        item.attributes &&
                        (item.attributes.agcID || item.attributes.agcPromoID),
                    content: {
                        postTitle: item.attributes && item.attributes.postTitle,
                        title:
                            item.attributes &&
                            item.attributes.content &&
                            item.attributes.content.title,
                        streamID:
                            item.attributes &&
                            item.attributes.content &&
                            item.attributes.content.streamID,
                        externalUrl:
                            item.attributes &&
                            item.attributes.content &&
                            item.attributes.content.externalUrl,
                        teamData:
                            item.attributes &&
                            item.attributes.matchData &&
                            item.attributes.matchData.teamData,
                        socialMediaType:
                            item.attributes &&
                            item.attributes.content &&
                            item.attributes.content.socialMediaType,
                    },
                };
            });
        },

        reload() {
            this.fetchContent();
        },

        deleteItem(agcID, option) {
            if (
                confirm(`Are you sure you want to remove this ${option} from the feed`)
            ) {
                console.log("deleting", agcID, option);
                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "DELETE",
                        url: `${process.env.VUE_APP_FEEDAPI}/feed/delete?agcID=${agcID}`,
                        headers: { Authorization: jwt },
                    })
                        .then((response) => {
                            console.log("feed item deleted");
                            this.fetchContent();
                        })
                        .catch((e) => {
                            console.error("Problem deleting feedItem", e);
                            this.status = "needsSaving";
                            this.errorMessage = "Problem posting custom match";
                        });
                });
            }
        },

        deletePromo(id) {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "DELETE",
                    url: `${process.env.VUE_APP_FEEDAPI}/promos/delete?agcPromoID=${id}`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log("Promo deleted:", response);
                        this.$emit("reloadPromos");
                    })
                    .catch((e) => {
                        console.error("Problem deleting advert", e);
                    });
            });
        },
    },
};
</script>

<style scoped lang='scss'>
.content-type {
    color: #67247c;
}

.link {
    font-size: 12px;
    word-break: break-all;
}

.form-card {
    // padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 0;
    // padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 80px;

    &__intro {
        flex-grow: 1;
        margin: 16px 16px 8px;
        font-size: 24px;
    }

    &__actions {
        background-color: #e6e8f1;
        height: 100%;
        padding: 24px 16px;
    }

    &__action {
        margin: 0 8px 0 0;

        i {
            color: #67247c;
        }
    }
}

// .content-grid {
//   display: grid;
//   grid-template-columns: repeat(3, 1fr)
// }

// .grid-item {
//   margin: 8px;
//   border: 1px solid grey;
//   max-height: 400px;
//   max-width: 400px;
//   position: relative;

//   &--promo {
//     color: #fff;
//     background-size: cover;
//     background-repeat: no-repeat;
//   }

//   &--streamVid, &--match {
//     color: #fff;
//     background-color: #000;
//   }

//   &--twitter {
//     background-color: #1DA1F2;
//     color: #000;
//   }

//   &--news {
//     .delete-icon {
//       display: none;
//     }
//   }

//   .delete-icon {
//     color: inherit;
//     position: absolute;
//     top: 0;
//     right: 0;
//   }
// }
</style>
