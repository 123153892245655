<template>

  <div class="accordion-form">
    <h1>{{ formHeading }}</h1>
    <!-- This is an existing row being edited -->
    <template v-if="edit">
      <label class="label" for="title">Row Title</label>
      <v-text-field
        id="title"
        v-model="formTitle"
        solo
        flat
      ></v-text-field>

      <label class="label" for="body">Row Body</label>
      <tinymce-editor v-model="formBody"
        api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
        :init="{
        height: '500px',
        menubar: false,
        plugins: 'table link paste wordcount hr lists textpattern',
        toolbar:
          'undo redo | styleselect | bold italic | \
          link blockquote table | \
          hr | \
          bullist numlist | removeformat',
        resize: false,
        textpattern_patterns: [
          {start: '//button', replacement: '<a class=\'button \'>Button here</a>'}
        ],
        link_class_list: [
          {title: 'None', value: ''},
          {title: 'Primary on Dark', value: 'button button--primary-dark'},
          {title: 'Primary on Light', value: 'button button--primary-light'},
          {title: 'Ghost on Dark', value: 'button button--ghost-dark'},
          {title: 'Ghost on Light', value: 'button button--ghost-light'},
          {title: 'Label on Dark', value: 'button button--label-dark'},
          {title: 'Label on Light', value: 'button button--label-light'},
        ],
        style_formats: [
          {title: 'Headers', items: [
            //{title: 'Header 1', format: 'h1'},
            {title: 'Header 2', format: 'h2'},
            {title: 'Header 3', format: 'h3'},
            {title: 'Header 4', format: 'h4'},
            {title: 'Header 5', format: 'h5'},
            {title: 'Header 6', format: 'h6'}
          ]},
          {title: 'Inline', items: [
            {title: 'Bold', icon: 'bold', format: 'bold'},
            {title: 'Italic', icon: 'italic', format: 'italic'},
            {title: 'Underline', icon: 'underline', format: 'underline'},
            //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
            //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
            //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
            //{title: 'Code', icon: 'code', format: 'code'}
          ]},
          {title: 'Blocks', items: [
            {title: 'Paragraph', format: 'p'},
            {title: 'Blockquote', format: 'blockquote'},
            //{title: 'Div', format: 'div'},
            //{title: 'Pre', format: 'pre'}
          ]},
          {title: 'Alignment', items: [
            {title: 'Left', icon: 'alignleft', format: 'alignleft'},
            {title: 'Center', icon: 'aligncenter', format: 'aligncenter'},
            {title: 'Right', icon: 'alignright', format: 'alignright'},
            //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
          ]},
          {title: 'Small', selector: 'p', classes: 'small'}
        ],
        style_formats_merge: false,
        block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote'
      }"></tinymce-editor>

      <v-btn
        color="accent"
        outline
        flat
        @click="saveRow()"
      >
        Save Edits
      </v-btn>

      <v-btn
        color="accent"
        outline
        flat
        @click="cancelEdit()"
      >
        Cancel
      </v-btn>      
    </template>

    <!-- This is a New Row being added -->
    <template v-else>
      <label class="label" for="body">Row Title</label>
      
      <v-text-field
        id="title"
        v-model="formTitle"
        placeholder="Insert title"
        solo
        flat
      ></v-text-field>

      <label class="label" for="body">Row Body</label>
      <tinymce-editor v-model="formBody"
        api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
        :init="{
        height: '500px',
        menubar: false,
        plugins: 'table link paste wordcount hr lists textpattern',
        toolbar:
          'undo redo | styleselect | bold italic | \
          link blockquote table | \
          hr | \
          bullist numlist | removeformat',
        resize: false,
        textpattern_patterns: [
          {start: '//button', replacement: '<a class=\'button \'>Button here</a>'}
        ],
        link_class_list: [
          {title: 'None', value: ''},
          {title: 'Primary on Dark', value: 'button button--primary-dark'},
          {title: 'Primary on Light', value: 'button button--primary-light'},
          {title: 'Ghost on Dark', value: 'button button--ghost-dark'},
          {title: 'Ghost on Light', value: 'button button--ghost-light'},
          {title: 'Label on Dark', value: 'button button--label-dark'},
          {title: 'Label on Light', value: 'button button--label-light'},
        ],
        style_formats: [
          {title: 'Headers', items: [
            //{title: 'Header 1', format: 'h1'},
            {title: 'Header 2', format: 'h2'},
            {title: 'Header 3', format: 'h3'},
            {title: 'Header 4', format: 'h4'},
            {title: 'Header 5', format: 'h5'},
            {title: 'Header 6', format: 'h6'}
          ]},
          {title: 'Inline', items: [
            {title: 'Bold', icon: 'bold', format: 'bold'},
            {title: 'Italic', icon: 'italic', format: 'italic'},
            {title: 'Underline', icon: 'underline', format: 'underline'},
            //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
            //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
            //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
            //{title: 'Code', icon: 'code', format: 'code'}
          ]},
          {title: 'Blocks', items: [
            {title: 'Paragraph', format: 'p'},
            {title: 'Blockquote', format: 'blockquote'},
            //{title: 'Div', format: 'div'},
            //{title: 'Pre', format: 'pre'}
          ]},
          {title: 'Alignment', items: [
            {title: 'Left', icon: 'alignleft', format: 'alignleft'},
            {title: 'Center', icon: 'aligncenter', format: 'aligncenter'},
            {title: 'Right', icon: 'alignright', format: 'alignright'},
            //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
          ]},
          {title: 'Small', selector: 'p', classes: 'small'}
        ],
        style_formats_merge: false,
        block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote'
      }"></tinymce-editor>

      <v-btn
        color="accent"
        outline
        flat
        @click="saveRow()"
      >
        Add to accordion
      </v-btn>

      <v-btn
        color="accent"
        outline
        flat
        @click="cancelNewRow()"
      >
        Cancel
      </v-btn>
    </template>
  </div>

</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "WidgetAccordionForm",

  components: {
    "tinymce-editor": Editor,
  },

  props: {
    formHeading: {
      type: String
    },
    title: {
      type: String
    },
    body: {
      type: String
    },
    edit: {
      type: Boolean
    }
  },

  data: () => ({
    formTitle: null,
    formBody: null,
  }),

  mounted() {
    if (this.title) {
      this.formTitle = JSON.parse(JSON.stringify(this.title));
    }
    if (this.body) {
      this.formBody = JSON.parse(JSON.stringify(this.body));
    }
  },

  methods: {
    saveRow() {
      console.log("Save 1", {
        body: this.formBody,
        title: this.formTitle
      })
      this.$emit("saveRow", {
        body: this.formBody,
        title: this.formTitle
      });
    },

    cancelNewRow() {
      if(confirm("Are you sure you want to cancel?")) {
        this.$emit("cancelEdit")
      }
    },

    cancelEdit() {
      if(confirm("If you cancel your edits will not be saved")) {
        this.$emit("cancelEdit")
      }
    }
  }
}
</script>

<style scoped lang='scss'>

.accordion-form {
  padding: 16px;
  margin: 16px -16px;
  background-color: #eee6f1;
  border-radius: 4px;
}

</style>
