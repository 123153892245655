<template>
  <section class="landing-page-card">
    <div class="landing-page-card__container">

      <img class="landing-page-card__image" :src="require(`@/${image}`)" alt="">

      <div class="landing-page-card-bottom">
        <p class="landing-page-card__heading">{{heading}}</p>

        <p class="landing-page-card__description">{{description}}</p>

        <v-btn v-if="buttonLink" :to="buttonLink" class="accent">{{buttonText}}</v-btn>
        <label class="landing-page-card__label" v-else>{{label}}</label>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "LandingPageCard",

  props: {
    heading: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false
    },
    buttonLink: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.landing-page-card {
  text-align: center;
  width: 265px;
  height:400px;
  border: 1px solid #DFE3E9;
  color: #67247C;
  border-radius: 5px;
  margin: 10px;
  background: #fff;

  &__container {
    padding: 10px;
  }

  &__image {
    width: 200px;
    height: 200px;
  }

  &__heading {
    font-size: 14px;
    font-weight: 500;
  }

  &__description {
    font-size: 13px;
    font-weight: 200;
  }

  &-bottom {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    height: 200px;
  }

  &__label {
    position: relative;
    top: -30px;
    font-size: 18px;
    padding-top: 20px;
  }
}

.accent {
  top: -20px;
}
</style>