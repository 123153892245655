<template>

  <div v-if="!editable" class="list-row">

    <div class="list-col list-col__1">
      <p><strong>Title: </strong>{{ title }}</p>
      <p><strong>Content: </strong>{{ content }}</p>
      <p><strong>Links To: </strong>{{ buttonLink }}</p>
    </div>

    <div class="list-col list-col__2">
      <v-btn
        color="accent"
        outline
        flat
        @click="deleteItem()"
      >
        Delete Item
      </v-btn>  

      <v-btn
        light
        outline
        flat
        @click="editRow()"
      >
        Edit Item
      </v-btn>
    </div>
  </div>

  <div v-else>
    <list-widget-item-form 
      :title="title" 
      :content="content" 
      :buttonLink="buttonLink" 
      :buttonText="buttonText"
      :buttonType="buttonType"
      :buttonIcon="buttonIcon"
      :buttonIconPosition="buttonIconPosition" 
      :buttonNewTab="buttonNewTab" 
      :imageID="imageID"
      :imageAlignRight="imageAlignRight"
      :edit="true" @saveRow="saveRow($event)" @cancelEdit="cancelEdit()" :formHeading="`Edit List Item`"/>
  </div>


</template>

<script>
import ListWidgetItemForm from "@/components/Widgets/ListWidgetComponents/ListWidgetItemForm"

export default {
  name: "ListWidgetItem",

  components: {
    ListWidgetItemForm
  },

  props: {
    title: {
      type: String,
      required: false
    },
    content: {
      type: String,
      required: false
    },
    buttonText: {
      type: String,
      required: false
    },
    buttonLink: {
      type: String,
      required: false
    },
    buttonType: {
      type: String,
      required: false
    },
    buttonIcon: {
      type: String,
      required: false
    },
    buttonIconPosition: {
      type: String,
      required: false
    },
    buttonNewTab: {
      type: Boolean,
      required: false
    },
    imageID: {
      type: String
    },
    imageAlignRight: {
      type: Boolean
    }
  },

  data:() => ({
    editable: false
  }),

  methods: {
    deleteItem() {
      this.$emit("deleteItem");
    },

    editRow() {
      this.$emit("hideOthers", true);
      this.editable = true
    },

    saveRow(payload) {
      this.$emit("saveRow", payload);
      this.$emit("hideOthers", false);
      this.editable = false
    },

    cancelEdit() {
      this.$emit("hideOthers", false);
      this.editable = false
    }
  }
}
</script>

<style scoped lang='scss'>

.list-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #eee6f1;
  border-radius: 4px;
  padding: 8px;
  margin: 8px;
  transition: all 0.5s, opacity 0.2s;

  &:hover {
    cursor: pointer;
  }
}

.list-col {
  display: flex;
  flex-direction: column;
  
  &__1 {
    width: 70%;
  }
  &__2 {
    width: 30%;
  }
}

</style>
