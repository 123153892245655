export default {
  aggregatedFeed: {
    showAllNews: 1,
  },
  allowUpsellInApp: false,
  pageTypes: [
    { value: "internal", text: "Website Page" },
    { value: "external", text: "External Page" },
    { value: "internalLink", text: "Internal Page Link" },
    // {value: 'homepage', text: 'Homepage'},
  ],
  platformSettings: {
    showWeb: true,
    showApp: false,
  },
  defaultNewsCardStyle: "Cover",
  playerManagement: "V1",
  customDataFields: {
    customDataFieldOneName: "",
    customDataFieldOneDescription: "",
    customDataFieldTwoName: "",
    customDataFieldTwoDescription: "",
    customDataFieldThreeName: "",
    customDataFieldThreeDescription: "",
  },
  customTags: [],
  clubColors: [
    {
      color: "#009b48",
    },
    {
      color: "#f2c136",
    },
    // {
    //   color: "#A8AAB7",
    // },
  ],
};
