<template>

  <div class="carousel-widget-form">
    <template>
      <label class="label" for="body">Card Heading</label>
      <v-text-field
        id="cardHeading"
        v-model="cardHeading"
        placeholder="Insert title"
        solo
        flat
      ></v-text-field>

      <label class="label" for="body">Heading Position</label>
      <v-select
        id="cardLayout"
        placeholder="Choose heading position"
        v-model="cardLayout"
        item-text='name'
        item-value='value'
        :items="[
          { name: 'Top Left', value: 'top-left' },
          { name: 'Top Right', value: 'top-right' },
          { name: 'Bottom Left', value: 'bottom-left' },
          { name: 'Bottom Right', value: 'bottom-right'}
        ]"
        solo
        flat
      >
      </v-select>

      <label v-if="isPlayerCard" class="label" for="body">Shirt Number</label>
      <v-text-field
        v-if="isPlayerCard"
        id="cardNumber"
        v-model="cardNumber"
        placeholder="Insert shirt number"
        solo
        flat
      ></v-text-field>

      <!-- <label v-if="isPlayerCard" class="label" for="body">App Search Term</label>
      <v-text-field
        v-if="isPlayerCard"
        id="cardSearchTerm"
        v-model="cardSearchTerm"
        placeholder="Insert app search term"
        solo
        flat
      ></v-text-field> -->

      <label class="label" for="body">Background Colour</label>
      <v-text-field
        id="cardColorHex"
        v-model="cardColorHex"
        placeholder="Insert colour HEX"
        solo
        flat
      ></v-text-field>

      <label class="label" for="body">Card Image</label>

      <ImageUploader :title="'Card Background Image'" :widthOptionEnabled="false" :mediaLibraryID="cardImageSettings.imageID" @update="updateImage($event, 'imageID')" />

      <label class="label" for="body">Card Image Hover? (Optional)</label>
      <v-checkbox
        v-model="isBackgroundHover">
      </v-checkbox>

      <ImageUploader v-if="isBackgroundHover" :title="'Card Hover Background Image'" :widthOptionEnabled="false" :mediaLibraryID="cardImageSettings.imageHoverID" @update="updateImage($event, 'imageHoverID')" />

      <label class="label" for="body">Card Link</label>
      <v-text-field
        id="cardLink"
        v-model="cardLink"
        placeholder="Insert card link"
        solo
        flat
      ></v-text-field>

      <v-btn
        color="accent"
        outline
        flat
        @click="saveRow()"
      >
        Add to list
      </v-btn>

      <v-btn
        color="accent"
        outline
        flat
        @click="cancelNewCard()"
      >
        Cancel
      </v-btn>
    </template>
  </div>
</template>

<script>
import ImageUploader from "@/components/Library/ImageUploader.vue"

export default {
  name: "CustomCarouselItemForm",

  components: {
    ImageUploader
  },

  props: {
    heading: {
      type: String
    },
    link: {
      type: String
    },
    number: {
      type: String
    },
    player: {
      type: Boolean
    },
    layout: {
      type: String
    },
    color: {
      type: String
    },
    imageID: {
      type: String
    },
    imageIDKey: {
      type: String
    },
    imageHoverID: {
      type: String
    },
    imageHoverIDKey: {
      type: String
    },
    imageHover: {
      type: Boolean
    },
    searchTerm: {
      type: String
    },
    edit: {
      type: Boolean
    }
  },

  data: () => ({
    addCard: null,
    editCard: null,
    cardHeading: null,
    cardNumber: null,
    isPlayerCard: null,
    cardLayout: null,
    cardColorHex: null,
    isBackgroundHover: null,
    cardLink: null,
    cardSearchTerm: null,
    cardImageSettings: {
      imageID: "",
      imageIDKey: "",
      imageHoverID: "",
      imageHoverIDKey: "",
    }
  }),

  mounted() {
    if (this.heading) {
      this.cardHeading = JSON.parse(JSON.stringify(this.heading));
    }
    if (this.link) {
      this.cardLink = JSON.parse(JSON.stringify(this.link));
    }
    if (this.number) {
      this.cardNumber = JSON.parse(JSON.stringify(this.number));
    }
    if (this.player) {
      this.isPlayerCard = JSON.parse(JSON.stringify(this.player));
    }
    if (this.layout) {
      this.cardLayout = JSON.parse(JSON.stringify(this.layout));
    }
    if (this.color) {
      this.cardColorHex = JSON.parse(JSON.stringify(this.color));
    }
    if (this.imageID) {
      this.cardImageSettings.imageID = JSON.parse(JSON.stringify(this.imageID));
    }
    if (this.imageIDKey) {
      this.cardImageSettings.imageIDKey = JSON.parse(JSON.stringify(this.imageIDKey));
    }
    if (this.imageHoverID) {
      this.cardImageSettings.imageHoverID = JSON.parse(JSON.stringify(this.imageHoverID));
    }
    if (this.imageHoverIDKey) {
      this.cardImageSettings.imageHoverIDKey = JSON.parse(JSON.stringify(this.imageHoverIDKey));
    }
    if (this.imageHover) {
      this.isBackgroundHover = JSON.parse(JSON.stringify(this.imageHover));
    }
    if (this.searchTerm) {
      this.cardSearchTerm = JSON.parse(JSON.stringify(this.searchTerm));
    }
  },

  methods: {
    saveRow() {
      console.log("Save 1", {
        id: this.$uuid.v1(),
        heading: this.cardHeading,
        number: this.cardNumber,
        player: this.isPlayerCard,
        layout: this.cardLayout,
        color: this.cardColorHex,
        imageHover: this.isBackgroundHover,
        link: this.cardLink,
        searchTerm: this.cardSearchTerm,
        cardImageSettings: {
          imageID: this.cardImageSettings.imageID,
          imageIDKey: this.cardImageSettings.imageIDKey,
          imageHoverID: this.cardImageSettings.imageHoverID,
          imageHoverIDKey: this.cardImageSettings.imageHoverIDKey,
        }
      })
      this.$emit("saveRow", {
        id: this.$uuid.v1(),
        heading: this.cardHeading,
        number: this.cardNumber,
        player: this.isPlayerCard,
        layout: this.cardLayout,
        color: this.cardColorHex,
        imageHover: this.isBackgroundHover,
        link: this.cardLink,
        searchTerm: this.cardSearchTerm,
        cardImageSettings: {
          imageID: this.cardImageSettings.imageID,
          imageIDKey: this.cardImageSettings.imageIDKey,
          imageHoverID: this.cardImageSettings.imageHoverID,
          imageHoverIDKey: this.cardImageSettings.imageHoverIDKey
        }
      });
    },

    cancelNewCard() {
      if(confirm("Are you sure you want to cancel?")) {
        this.$emit("cancelEdit")
      }
    },

    cancelEdit() {
      if(confirm("If you cancel your edits will not be saved")) {
        this.$emit("cancelEdit")
      }
    },

    updateImage(payload, target) {
      // console.log('update image test here', target)

      this.cardImageSettings[target] = payload.mediaLibraryID
      this.cardImageSettings[`${target}Key`] = payload.imageKey
    }
  }
}
</script>

<style scoped lang='scss'>

.list-item-form {
  padding: 16px;
  margin: 16px -16px;
  background-color: #eee6f1;
  border-radius: 4px;
}
</style>
