<template>
  <article class="package-item">

    <img v-if="item.attributes.imageData.landscape.imageKey" :src="`${imageDomain}/${item.attributes.imageData.landscape.imageKey}`" width="160" class="package-item__image" />

    <div class="package-item__inner">
      <div class="package-item__statuses">
        <p v-if="item.attributes.publicationData.published" class="status status--published">Published</p>
        <p v-else class="status status--draft">Draft</p>
      </div>

      <h2 class="package-item__subheading subheading">{{ item.attributes.title || '-- No title set --' }}</h2>
      <p class="package-item__label">Type: {{ item.attributes.packageType }}</p>
    </div>
    <v-btn :to="`/streamline/package/${item.id}`">Edit</v-btn>
  </article>
</template>

<script>
export default {
  name: "PackagesContent",

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data:() => ({
    imageDomain: process.env.VUE_APP_IMAGEURL
  })
}
</script>

<style scoped lang="scss">
  .package-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 140px;
    margin: 16px 0 48px;
    padding: 32px;
    background-color: #fff;
    border: 1px solid #dfe3e9;
    border-radius: 4px;

    &__image {
      max-width: 160px;
    }

    &__inner {
      padding: 0 0 0 32px;
      width: 100%;
    }

    &__id {
      margin: 0;
      font-size: 11px;
      opacity: 0.5;
      word-break: break-all;
    }

    &__subheading {
      margin: 0;
      padding: 5px 0 0;
    }

    &__label {
      margin: 4px 0;
      padding: 0;
      font-size: 12px;
      word-break: break-all;
    }

    &__statuses {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  .status {
    display: inline-block;
    padding: 3px 12px;
    margin: 0 8px 8px 0;
    font-size: 10px;
    color: #fff;
    background-color: #ccc;
    border-radius: 4px;

    &.status--draft {
      background-color: color(draftHighlight);
    }

    &.status--published {
      background-color: #67247c;
    }
  }
</style>
