<template>
  <form class="main-body">
    <h2 class="subheading">{{ heading }}</h2>

    <v-stepper v-model="stepperCount">
      <v-stepper-header class="stepper-header">
        <v-stepper-step color="accent" :complete="stepperCount > 1" step="1"
          >Label</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step color="accent" :complete="stepperCount > 2" step="2"
          >Style</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step color="accent" :complete="stepperCount > 3" step="3"
          >Summary</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="stepper-wrapper">
            <v-card class="mb-5" flat color="white lighten-1" height="200px">
              <label class="label">Label</label>
              <p class="stepper-label--mini">
                What type of location pins are you creating? For example, you
                may <br />
                want to fill your map with Supporters Club location pins.
              </p>

              <v-text-field
                class="stepper__list-item-input"
                v-model="updatedData.categoryTitle"
                solo
                flat
                placeholder="Enter Category Name"
                height="20"
              ></v-text-field>
            </v-card>
          </div>
          <div class="buttons-wrapper">
            <v-btn class="button-cancel" color="lightgrey" @click="cancelMap()">
              Cancel
            </v-btn>

            <div class="buttons-continue">
              <!-- <v-btn class="button-skip" flat @click="stepperCount = 2">
                Skip
              </v-btn> -->

              <v-btn color="accent" @click="stepperCount = 2"> Next </v-btn>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="stepper-wrapper">
            <v-card
              class="mb-5 ml-5 mr-5 stepper-2"
              flat
              color="white lighten-1"
              height="50px"
            >
              <label class="label">Colour</label>
              <p class="stepper-label--mini">
                Select a colour for the location pins that will represent
                <span class="stepper-label--inline">{{
                  updatedData.categoryTitle
                }}</span>
                on your map.
              </p>
            </v-card>
            <div class="colour-picker-container">
              <label class="colour-picker--heading">Pick a pin colour:</label>

              <div class="colour-picker-swatch">
                <div class="colour-picker-custom">
                  <input
                    class="colour-picker"
                    type="color"
                    id="circle-color-picker"
                    name="color-picker"
                    :value="customColor"
                    :v-model="customColor"
                    @change="updateCustomColor"
                    @click="showCustom = false"
                  />
                  <img
                    class="colour-picker-image"
                    v-if="showCustom"
                    src="../../../assets/map-directory/NoBackgroundColour.svg"
                    alt=""
                    @click="showCustomPicker"
                  />
                  <p class="colour-picker-custom--label">Custom</p>
                </div>
                <a
                  v-for="(colour, index) in clubColors"
                  :key="index"
                  @click="updateSelectedColor(colour.color)"
                >
                  <div
                    class="colour-picker-circle"
                    :style="`background-color: ${colour.color}`"
                  >
                    <p class="colour-picker-hex">{{ colour.color }}</p>
                  </div>
                </a>
              </div>
              <div class="selected-color-wrapper">
                <div
                  class="selected-color"
                  :style="`background-color: ${selectedColor}`"
                ></div>
                <p class="selected-color-label">{{ selectedColor }}</p>
              </div>
            </div>
          </div>
          <div class="buttons-wrapper">
            <v-btn
              class="button-cancel"
              color="lightgrey"
              @click="stepperCount = 1"
            >
              Back
            </v-btn>

            <div class="buttons-continue">
              <!-- <v-btn class="button-skip" flat @click="stepperCount = 3">
                Skip
              </v-btn> -->

              <v-btn color="accent" @click="stepperCount = 3"> Next </v-btn>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="stepper-wrapper">
            <v-card class="mb-5" flat color="white lighten-1" height="200px">
              <label class="label label-summary">Your Category</label>

              <div class="stepper-content-container">
                <div class="stepper-content">
                  <label class="stepper-content-label">Label</label>
                  <p class="stepper-content-label--heading">
                    {{ this.updatedData.categoryTitle }}
                  </p>
                </div>

                <div class="stepper-content">
                  <label class="stepper-content-label">Colour</label>
                  <!-- <svg width="52" height="52" viewBox="0 0 76 88" :fill="locationPinColor" xmlns="http://www.w3.org/2000/svg"><path d="M64 0V12H76V20H64V32H56V20H44V12H56V0H64ZM32 48C36.4 48 40 44.4 40 40C40 35.6 36.4 32 32 32C27.6 32 24 35.6 24 40C24 44.4 27.6 48 32 48ZM40 9V24H52V36H63.68C63.88 37.56 64 39.16 64 40.8C64 54.08 53.32 69.8 32 88C10.68 69.8 0 54.08 0 40.8C0 20.88 15.2 8 32 8C34.72 8 37.4 8.32 40 9Z" :fill="locationPinColor"/></svg> -->
                  <svg
                    class="stepper-content--icon"
                    :fill="categoryPinColor"
                    width="26"
                    height="30"
                    viewBox="0 0 26 30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 15.2866C13.8594 15.2866 14.5953 14.987 15.2078 14.3878C15.8203 13.7885 16.126 13.069 16.125 12.2293C16.125 11.3885 15.8187 10.6685 15.2062 10.0693C14.5937 9.47006 13.8583 9.17095 13 9.17197C12.1406 9.17197 11.4047 9.47159 10.7922 10.0708C10.1797 10.6701 9.87396 11.3896 9.875 12.2293C9.875 13.0701 10.1813 13.7901 10.7938 14.3893C11.4063 14.9885 12.1417 15.2876 13 15.2866ZM13 30C12.7917 30 12.5833 29.9618 12.375 29.8853C12.1667 29.8089 11.9844 29.707 11.8281 29.5796C8.02604 26.293 5.1875 23.2418 3.3125 20.426C1.4375 17.6102 0.5 14.9799 0.5 12.535C0.5 8.71338 1.75677 5.66879 4.27031 3.40127C6.78385 1.13376 9.69375 0 13 0C16.3073 0 19.2177 1.13376 21.7312 3.40127C24.2448 5.66879 25.501 8.71338 25.5 12.535C25.5 14.9809 24.5625 17.6117 22.6875 20.4275C20.8125 23.2433 17.974 26.294 14.1719 29.5796C14.0156 29.707 13.8333 29.8089 13.625 29.8853C13.4167 29.9618 13.2083 30 13 30Z"
                    />
                  </svg>
                </div>
              </div>
            </v-card>
          </div>
          <div class="buttons-wrapper">
            <v-btn
              class="button-cancel"
              color="lightgrey"
              @click="stepperCount = 2"
            >
              Back
            </v-btn>

            <div class="buttons-continue">
              <v-btn color="accent" @click="updateCategory()">
                {{ confirmButtonText ? "Continue" : "Update" }}
              </v-btn>
            </div>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </form>
</template>

<script>
import axios from "axios";
import ImageUploader from "@/components/Library/ImageUploader";
import MapPicker from "@/components/Library/MapPicker";
import Editor from "@tinymce/tinymce-vue";
import PageSelector from "@/components/Library/PageSelector";

export default {
  name: "EditMapCategories",

  components: {
    ImageUploader,
    MapPicker,
    "tinymce-editor": Editor,
    PageSelector,
  },

  created() {
    this.defaultSettings = require("../../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/defaultSettings.js");

    this.clubColors = this.defaultSettings.default.clubColors;

    if (this.$route.path != "/map-directory/categories/create") {
      this.getCategoryData();
      this.heading = "Editing Category";
      this.isExistingCategory = true;
      this.confirmButtonText = false;
    } else {
      this.heading = "Creating New Category";
      this.isExistingCategory = false;
      this.confirmButtonText = true;
      this.isLoaded = true;
    }

    // this.getCountries()
    // this.getOrganisationTypeFilters()
  },

  data: () => ({
    defaultSettings: null,
    api: process.env.VUE_APP_MAPDIRECTORYAPI,
    isExistingCategory: null,
    confirmButtonText: null,
    mapId: null,
    singleCategory: true,
    stepperCount: 0,
    locationPinColor: "red",
    heading: null,
    stepperHeight: "400px",
    showStepperAddress: false,
    mapToggleAuto: false,
    mapToggleFixed: false,
    updatedData: {
      categoryTitle: null,
      categoryColour: null,
    },
    clubColors: null,
    selectedColor: "#595b65",
    customColor: "#ffffff",
    showCustom: true,
    categoryPinColor: null,
  }),

  methods: {
    showCustomPicker() {
      this.showCustom = false;
    },

    updateSelectedColor(value) {
      const color = value.target ? value.target.value : value;
      this.selectedColor = color || this.customColor;
      this.categoryPinColor = color || this.customColor;
      this.updatedData.categoryColour = color || this.customColor;
    },

    updateCustomColor(value) {
      const color = value.target ? value.target.value : value;
      this.selectedColor = color;
      this.customColor = color;
      this.categoryPinColor = color || this.customColor;
      this.updatedData.categoryColour = color || this.customColor;
    },

    cancelMap() {
      this.$router.push({ path: "/map-directory" });
    },

    getCategoryData() {
      this.$store.commit("startLoading");
      this.categoryId = this.$route.params.id.substring(1);

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/categories/${this.categoryId}`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            (this.updatedData.categoryTitle =
              response.data.data.attributes.title),
              (this.updatedData.categoryColour =
                response.data.data.attributes.colour);

            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem receiving category data", e);
            this.$store.commit("completeLoading");
          });
      });
    },

    updateCategory() {
      this.$store.commit("startLoading");
      this.categoryId = this.$route.params.id.substring(1);

      if (this.isExistingCategory) {
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "PATCH",
            url: `${this.api}/categories/${this.categoryId}`,
            headers: { Authorization: jwt },
            data: {
              data: {
                attributes: {
                  title: this.updatedData.categoryTitle,
                  colour: this.updatedData.categoryColour,
                  locations: [],
                },
                type: "string",
                id: this.categoryId,
              },
            },
          })
            .then((response) => {
              this.$router.push({ path: "/map-directory?tab=1" });
              window.alert("Successfully Saved");
              this.$store.commit("completeLoading");
              this.$emit("categoryPinColour", this.updatedData.categoryColour);
            })
            .catch((e) => {
              console.error("ERROR PATCH category data", e);
              window.alert(
                "An error has occurred. Please ensure you defined a category name and a colour"
              );
              this.$store.commit("completeLoading");
            });
        });
      } else {
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${this.api}/categories`,
            headers: { Authorization: jwt },
            data: {
              data: {
                attributes: {
                  mapCategoryID: this.$uuid.v1(),
                  title: this.updatedData.categoryTitle,
                  colour: this.updatedData.categoryColour,
                  locations: [],
                },
                type: "string",
              },
            },
          })
            .then((response) => {
              this.$router.push({ path: "/map-directory?tab=1" });
              this.$store.commit("completeLoading");
            })
            .catch((e) => {
              console.error("ERROR POST category data", e);
              window.alert(
                "An error has occurred. Please ensure you defined a category name and a colour"
              );
              this.$store.commit("completeLoading");
            });
        });
      }
    },
  },
};
</script>

<style scoped lang='scss'>
// @import '../../styles/settings';

.stepper {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 90px;
  }

  &-content-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  &-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 130px;
    height: 130px;
    margin: 44px;
    border-radius: 4px;
    background-color: #f9f9f9;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

    &--icon {
      position: relative;
      top: -10px;
    }
  }

  &-content-label {
    font-size: 14px;
    font-weight: 200;
    text-transform: uppercase;
    color: #516173;

    &--mini {
      color: #a8aab7;
      font-size: 12px;
      font-weight: 500;
      text-transform: capitalize;
    }

    &--heading {
      font-size: 16px;
      font-weight: 500;
      color: #24002f;
      text-transform: capitalize;
    }
  }

  &-label--mini {
    color: #516173;
  }

  &-label--inline {
    color: #24002f;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
  }
}
.half {
  width: 100%;
}

.section-divider {
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dfe3e9;
}

.input {
  &__textfield {
    &--basic {
      margin-top: 0;
    }
  }
}

.faded {
  opacity: 0.5;
}

.stepper-header {
  box-shadow: none;
}

.buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.button-cancel {
  color: white;
}

.button-skip {
  color: purple;
  background-color: none;
  box-shadow: none;
}

.label-summary {
  width: 100%;
  text-align: center;
}

// .checkbox {

//   &-wrapper {
//     display: flex;
//     justify-content: center;
//   }

//   &-auto {
//     // background-color: red;
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     margin: 5px;
//     width: 200px;
//   }

//   &-label {
//     margin: 0;

//     &--mini {
//       color: #A8AAB7;
//     }
//   }

//   &-label-wrapper {
//     width: 150px;
//     height: 84px;
//   }

//   &-image {
//     width: 70px;
//     height: 70px;

//     &--mini {
//       width: 52px;
//       height: 52px;
//     }
//   }
// }

.fixed-address-lattitude {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.fixed-address-longtitude {
  display: flex;
}
.fixed-address__label {
  margin: 13px 0 0 0;
  width: 80px;
}

.colour-picker {
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }

  &-swatch {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 30px 0 0 0;
  }

  &-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 375px;
    height: 225px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }

  &-circle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
  }

  &-hex {
    font-size: 16px;
    color: #a8aab7;
    text-transform: uppercase;
    font-weight: 200;
    position: relative;
    top: 50px;
  }

  &-custom {
    position: relative;
    display: flex;
    flex-direction: column;

    &--label {
      font-size: 16px;
      color: #595b65;
      text-transform: uppercase;
      font-weight: 400;
      position: relative;
      top: 10px;
    }
  }

  &-image {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
  }

  &--heading {
    position: relative;
    top: 5px;
    font-size: 14px;
    color: #24002f;
  }
}
.selected-color {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #c2c5d6;
  width: calc(90% - 80px);
  border-radius: 50px;
  height: 20px;
  font-size: 16px;
  color: white;
  font-weight: 400;
  text-transform: uppercase;
  margin: 10px 0 0 0;

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: solid 1px rgba(0, 0, 0, 0.12);
  }

  &-label {
    position: relative;
    top: 3px;
    font-size: 16px;
    color: #a8aab7;
    font-weight: 200;
    text-transform: uppercase;
  }
}

#circle-color-picker {
  border: none !important;
  width: 70px;
  height: 70px;
  padding: 0;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

#circle-color-picker::-webkit-color-swatch-wrapper {
  padding: 0;
  border: none;
  cursor: pointer;
}

#circle-color-picker::-webkit-color-swatch {
  border-radius: 50%;
  padding: 0;
  border: none;
  cursor: pointer;
}
</style>
