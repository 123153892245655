<template>
  <v-card class="media-card" height="90px" width="300px" light>
    <v-layout py-1 pl-1>
      <v-flex shrink>
        <v-img
          v-if="
            mediaItem.fileType == 'image/jpeg' ||
            mediaItem.fileType == 'image/png'
          "
          height="80"
          width="100"
          :src="`${imageURL}/fit-in/150x150/filters:quality(20)/${mediaItem.key}`"
        ></v-img>

        <div v-else class="pdf-icon">
          <v-icon color="accent">insert_drive_file</v-icon>
        </div>
      </v-flex>
      <v-flex text-center>
        <v-container class="info-container">
          <p class="info-name">{{ mediaItem.name }}</p>
          <p class="info-date">
            {{ mediaItem.savedTimestamp | moment("DD/MM/YYYY (HH:mm:ss)") }}
          </p>

          <v-btn
            icon
            class="media-card__move-button"
            @click="toggleSelectItem()"
            v-if="!singleSelection"
          >
            <v-icon v-if="selected">check_box</v-icon>
            <v-icon v-else>check_box_outline_blank</v-icon>
          </v-btn>

          <button
            class="media-card__fav-button"
            @click="addFavourite(mediaItem)"
          >
            <v-icon>favorite</v-icon>
          </button>
        </v-container>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
export default {
  name: "MediaItem",

  props: {
    mediaItem: {
      type: Object,
      required: true,
    },
    includeManagementButtons: {
      type: Boolean,
      required: true,
    },
    singleSelection: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    imageURL: `${process.env.VUE_APP_IMAGEURL}`,
    selected: false,
  }),

  methods: {
    toggleSelectItem() {
      if (this.selected) {
        this.selected = false;
        this.$emit("itemRemoved", this.mediaItem.mediaLibraryID);
      } else {
        this.selected = true;
        this.$emit("itemSelected", this.mediaItem.mediaLibraryID);
      }
    },

    addFavourite(mediaLibraryItem) {
      if (!localStorage.mediaFavourites) {
        localStorage.mediaFavourites = [];
      }
      if (this.checkDuplicatesInFavourites(mediaLibraryItem) === false) {
        localStorage.mediaFavourites =
          localStorage.mediaFavourites + JSON.stringify(mediaLibraryItem) + "|";
      }
    },

    deleteFavourite(mediaLibraryItem) {
      var itemString = JSON.stringify(mediaLibraryItem) + "|";
      localStorage.mediaFavourites = localStorage.mediaFavourites.replace(
        itemString,
        ""
      );
      this.getFavourites();
    },

    checkDuplicatesInFavourites(mediaLibraryItem) {
      var isDup = false;
      var itemString = JSON.stringify(mediaLibraryItem) + "|";
      if (localStorage.mediaFavourites.includes(itemString)) {
        isDup = true;
      }
      return isDup;
    },
  },
};
</script>

<style lang="scss" scoped>
.media-card {
  position: relative;
  margin: 8px;

  &__move-button {
    position: absolute;
    bottom: -9px;
    right: -13px;
  }

  &__fav-button {
    position: absolute;
    top: 1px;
    right: 1px;
  }
}
.pdf-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100px;
  font-size: 40px;
}

img {
  height: 70px;
}

.info-container {
  padding: 10px;
}

p {
  color: grey;
}

.info-date {
  font-size: 10px;
}
</style>
