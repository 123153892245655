export default {
  data: [
    {
      sectionName: "Homepage mini promo",
      sectionDesc: "",
      model: "minipromo",
      fields: [
        {
          label: "Label",
          model: "minipromo_promolabel",
          type: "Text"
        },
        {
          label: "Promo text",
          model: "minipromo_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "minipromo_promolink",
          type: "Text"
        },
        {
          label: "Promo image (100px x 100px)",
          model: "minipromo_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Homepage promo - First",
      sectionDesc: "This is the first element in the promotional area on the homepage.",
      model: "promo_left",
      fields: [
        {
          label: "Title",
          model: "promo_left_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_left_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_left_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_left_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_left_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Homepage promo - Second",
      sectionDesc: "This is the second element in the promotional area on the homepage.",
      model: "promo_right",
      fields: [
        {
          label: "Title",
          model: "promo_right_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_right_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_right_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_right_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_right_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Homepage promo - Third",
      sectionDesc: "This is the third element in the promotional area on the homepage.",
      model: "promo_bottom_left",
      fields: [
        {
          label: "Title",
          model: "promo_bottom_left_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_bottom_left_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_bottom_left_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_bottom_left_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_bottom_left_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Homepage promo - Fourth",
      sectionDesc: "This is the fourth element in the promotional area on the homepage.",
      model: "promo_bottom_right",
      fields: [
        {
          label: "Title",
          model: "promo_bottom_right_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_bottom_right_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_bottom_right_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_bottom_right_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_bottom_right_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Highlighted Player",
      sectionDesc: "This defines the highlighted players on the stats components. Please use a player ID",
      model: "highlighted_players",
      fields: [
        {
          label: "Women team - Player ID",
          model: "highlighted_players_women",
          type: "Text"
        },
        {
          label: "Under 21 team - Player ID",
          model: "highlighted_players_u21",
          type: "Text"
        },
        {
          label: "Under 18 team - Player ID",
          model: "highlighted_players_u18",
          type: "Text"
        },
      ]
    },
    {
      sectionName: "Women's League Table Data",
      sectionDesc: "This defines stats data for the Women's League Table",
      model: "womens_league_table",
      fields: [
        {
          label: "Matches played",
          model: "played",
          type: "Text"
        },
        {
          label: "Wins",
          model: "wins",
          type: "Text"
        },
        {
          label: "Draws",
          model: "draws",
          type: "Text"
        },
        {
          label: "Loses",
          model: "loses",
          type: "Text"
        },
        {
          label: "Goal Difference",
          model: "goal_difference",
          type: "Text"
        },
        {
          label: "Points",
          model: "points",
          type: "Text"
        },
        {
          label: "League Position",
          model: "league_position",
          type: "Text"
        },
      ]
    },
  ]
}
