<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <p class="widget-info">This widget allows you to display all available StreamAMG passes.  You can 'feature' multiple passes to bring them to the front of the list.  If you choose 'Render Inline HTML' any style customisations in the stream data will pull through onto the pass.</p>

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()">Use this widget</v-btn>
    </template>

    <div class="widget-fieldset" v-else>
      <label class="label">Title</label>
      <v-text-field
        id="widgetData.title"
        solo
        flat
        v-model="updatedData.widgetData.title">
      </v-text-field>

      <label class="label">Description</label>
      <v-text-field
        id="widgetData.description"
        solo
        flat
        v-model="updatedData.widgetData.description">
      </v-text-field>

      <label class="label">Label</label>
      <v-text-field
        id="widgetData.label"
        solo
        flat
        v-model="updatedData.widgetData.label">
      </v-text-field>

      <label class="label">Filter type</label>
      <v-select
        id="widgetData.eventType"
        :items="filterOptions"
        solo
        flat
        v-model="updatedData.widgetData.filteredType">
      </v-select>

      <label class="label">Maximum number of items shown at first</label>
      <v-text-field
        id="widgetData.itemLimit"
        type="number"
        solo
        flat
        v-model="updatedData.widgetData.itemLimit">
      </v-text-field>

      <label class="label">Style</label>
      <v-select
        id="widgetData.style"
        :items="styleOptions"
        solo
        flat
        v-model="updatedData.widgetData.style">
      </v-select>

      <label class="label">Packages Link</label>
      <v-text-field
        id="widgetData.packagesLink"
        solo
        flat
        v-model="updatedData.widgetData.packagesLink">
      </v-text-field>

      <label class="label">Packages Text</label>
      <v-text-field
        id="widgetData.label"
        solo
        flat
        v-model="updatedData.widgetData.packagesText">
      </v-text-field>

      <label class="label">Display Images?</label>
      <v-select
        id="widgetData.style"
        :items="imageOptions"
        solo
        flat
        v-model="updatedData.widgetData.displayImages">
      </v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "StreamplayList",

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent()
      },
      deep: true
    }
  },

  props: {
    editData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    styleOptions: [{text: "Light Mode", value: "light"}, {text: "Dark Mode", value: "dark"}],
    imageOptions: [{text: "Yes, display images", value: true}, {text: "No, don't display images", value: false}],
    filterOptions: [{text: "All", value: ""}, {text: "Events", value: "event"}, {text: "Fixtures", value: "fixture"}],
    updatedData: {
      label: null,
      title: null,
      description: null,
      style: null,
      packagesLink: null,
      packagesText: null,
      displayImages: null,
      filteredType: null,
      itemLimit: 3,
    }
  }),

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.editData))
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "StreamplayList"
      this.updatedData.widgetName = "Streamplay List"
      this.updatedData.widgetID = this.$uuid.v1()
      this.updatedData.widgetData = {
        label: null,
        title: null,
        description: null,
        style: null,
        packagesLink: null,
        packagesText: null,
        displayImages: null,
        filteredType: "",
        itemLimit: 3
      }
      this.$emit("initialised")
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData)
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
