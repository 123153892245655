<template>
    <section class="player">
        <v-form
            v-model="valid"
            ref="playerForm"
            lazy-validation
        >
            <v-layout class="main-body">
                <v-flex>
                    <h2 class="subheading">User status</h2>
                    <div class="player__panel">
                        <v-flex
                            align-center
                            class="player__panel-details"
                        >
                            <v-layout>
                                <div class="flex sm12 md5">
                                    <p><strong>Created:</strong> {{ updatedData.created | moment('DD/MM/YYYY (HH:mm:ss)') }}</p>
                                    <p><strong>Modified:</strong> {{ updatedData.modified | moment('DD/MM/YYYY (HH:mm:ss)') }}</p>
                                    <p><strong>Status:</strong> {{ updatedData.status }} </p>
                                    <p
                                        class="help"
                                        v-if="updatedData.status === 'RESET_REQUIRED'"
                                    >This user should visit /login-reset-password and input the verification code that has been sent to
                                        their registered email address, and new password.</p>
                                    <p
                                        class="help"
                                        v-if="updatedData.status === 'FORCE_CHANGE_PASSWORD'"
                                    >This user has not logged in with their temporary credentials. If more than a week has elapsed since
                                        account creation these will need to be resent</p>
                                </div>
                                <div class="flex sm12 md5 offset-md2">
                                    <v-flex align-left>
                                        <v-layout>
                                            <template v-if="updatedData.enabled === true">
                                                <div class="flex sm12 md5">
                                                    User currently enabled
                                                </div>
                                                <div class="flex sm12 md5">
                                                    <v-btn
                                                        class="save-status"
                                                        color="#DC143C"
                                                        dark
                                                        type="button"
                                                        depressed
                                                        @click="disableUser()"
                                                    >
                                                        Disable User
                                                    </v-btn>
                                                </div>
                                            </template>
                                            <template v-else-if="updatedData.enabled === false">
                                                <div class="flex sm12 md5">
                                                    User currently disabled
                                                </div>
                                                <div class="flex sm12 md5">
                                                    <v-btn
                                                        class="save-status"
                                                        color="#5EA77E"
                                                        dark
                                                        type="button"
                                                        depressed
                                                        @click="enableUser()"
                                                    >
                                                        Enable User
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-layout>
                                        <v-layout>
                                            <template v-if="updatedData.status === 'FORCE_CHANGE_PASSWORD'">
                                                <div class="flex sm12 md5">
                                                    Resend Temporary Password
                                                </div>
                                                <div class="flex sm12 md5">
                                                    <v-btn
                                                        class="save-status"
                                                        color="#c0c0c0"
                                                        dark
                                                        type="button"
                                                        depressed
                                                        @click="resendTemporaryPassword()"
                                                    >
                                                        Resend Password
                                                    </v-btn>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="flex sm12 md5">
                                                    Reset the user's password
                                                </div>
                                                <div class="flex sm12 md5">
                                                    <v-btn
                                                        class="save-status"
                                                        color="accent"
                                                        dark
                                                        type="button"
                                                        depressed
                                                        @click="resetPassword()"
                                                    >
                                                        Reset Password
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-layout>
                                    </v-flex>
                                </div>
                            </v-layout>
                        </v-flex>
                    </div>

                    <h2 class="subheading">User details</h2>
                    <div class="player__panel">
                        <v-flex
                            align-center
                            class="player__panel-details"
                        >
                            <v-layout>
                                <div class="flex sm12 md5">
                                    <label
                                        class="label"
                                        for="firstName"
                                    >Email</label>
                                    <v-text-field
                                        id="username"
                                        readonly
                                        :rules="emailRules"
                                        validate-on-blur
                                        placeholder=""
                                        solo
                                        flat
                                        v-model="updatedData.username"
                                        @change="updateData()"
                                    ></v-text-field>
                                </div>
                                <div class="flex sm12 md5 offset-md2">
                                </div>
                            </v-layout>
                            <v-layout>
                                <div class="flex sm12 md5">
                                    <label
                                        class="label"
                                        for="firstName"
                                    >First name</label>
                                    <v-text-field
                                        id="firstName"
                                        :rules="[rules.required]"
                                        validate-on-blur
                                        placeholder="First name"
                                        solo
                                        flat
                                        v-model="updatedData.firstName"
                                        @change="updateData()"
                                    ></v-text-field>
                                </div>
                                <div class="flex sm12 md5 offset-md2">
                                    <label
                                        class="label"
                                        for="lastName"
                                    >Last name</label>
                                    <v-text-field
                                        id="lastName"
                                        :rules="[rules.required]"
                                        validate-on-blur
                                        placeholder="Last name"
                                        solo
                                        flat
                                        v-model="updatedData.lastName"
                                        @change="updateData()"
                                    ></v-text-field>
                                </div>
                            </v-layout>
                        </v-flex>
                    </div>

                    <h2
                        v-if="modules.default.MFA"
                        class="subheading"
                    >Multi-Factor Authentication</h2>
                    <div
                        v-if="modules.default.MFA"
                        class="player__panel"
                    >
                        <p v-if="disablingMfa">Multi-Factor Authentication will be disabled. Please save Admin User to confirm this action.
                        </p>
                        <template v-else-if="isMfaEnabled">
                            <p>Multi-Factor Authentication is enabled for this user.
                                <span v-if="userMfaSetting && userMfaSetting.length">Type: {{ this.userMfaSetting.join(", ").replaceAll("_",
                " ") }}</span>
                            </p>
                            <v-btn
                                class="initialise-button"
                                color="error"
                                v-on:click="disablingMfa = true"
                            >Disable MFA</v-btn>
                        </template>
                        <p v-else>Multi-Factor Authentication is disabled for this user.</p>

                    </div>

                    <h2 class="subheading">User permissions</h2>
                    <div class="player__panel">

                        <!-- <pre>{{ associatedUserGroups }}</pre>

          <pre>{{ allUserGroups }}</pre> -->

                        <table
                            class="permissions-table"
                            cellspacing="0"
                        >

                            <tr class="bottom-divider">
                                <td>Administrator</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.Administrator"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('Administrator') }}</p>
                                </td>
                            </tr>

                            <tr v-if="modules.default.News">
                                <td>News</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.News"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('News') }}</p>
                                </td>
                            </tr>

                            <tr v-if="modules.default.Pages">
                                <td>Content / Pages</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.Content"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('Content') }}</p>
                                </td>
                            </tr>

                            <tr v-if="modules.default.SiteConfig">
                                <td>Website Manager</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.WebsiteManager"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('WebsiteManager') }}</p>
                                </td>
                            </tr>

                            <tr v-if="modules.default.AppConfig">
                                <td>App Manager</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.AppManager"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('AppManager') }}</p>
                                </td>
                            </tr>

                            <tr v-if="modules.default.Videos">
                                <td>VideoManager</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.VideoManager"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('VideoManager') }}</p>
                                </td>
                            </tr>

                            <!-- <tr>
              <td>Homepage Manager</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.HomepageManager"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('HomepageManager') }}</p>
              </td>
            </tr> -->

                            <tr v-if="modules.default.Forms">
                                <td>Forms</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.Forms"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('Forms') }}</p>
                                </td>
                            </tr>

                            <tr
                                v-if="modules.default.Organisations"
                                class="bottom-divider"
                            >
                                <td>Organisations</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.Organisations"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('Organisations') }}</p>
                                </td>
                            </tr>

                            <tr v-if="modules.default.Fixtures">
                                <td>Fixtures</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.Fixtures"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('Fixtures') }}</p>
                                </td>
                            </tr>

                            <tr v-if="modules.default.Squads">
                                <td>Squads</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.Squads"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('Squads') }}</p>
                                </td>
                            </tr>

                            <tr
                                v-if="modules.default.MatchdayTakeover"
                                class="bottom-divider"
                            >
                                <td>Matchday Takeover</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.MatchdayTakeover"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('MatchdayTakeover') }}</p>
                                </td>
                            </tr>

                            <tr v-if="modules.default.OverlayPromos">
                                <td>Overlay Promos</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.OverlaysPromo"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('OverlaysPromo') }}</p>
                                </td>
                            </tr>

                            <tr v-if="modules.default.Advertising || modules.default.Lab">
                                <td>Advertising</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.Advertising"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('Advertising') }}</p>
                                </td>
                            </tr>

                            <tr
                                v-if="modules.default.Sponsors"
                                class="bottom-divider"
                            >
                                <td>Sponsors</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.Sponsors"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('Sponsors') }}</p>
                                </td>
                            </tr>

                            <tr
                                v-if="modules.default.SsoUsers || modules.default.Streamline"
                                class="bottom-divider"
                            >
                                <td>User Management</td>
                                <td>
                                    <v-switch
                                        v-model="associatedUserGroups.SSOUserManagement"
                                        @change="updateData()"
                                    >
                                    </v-switch>
                                </td>
                                <td>
                                    <p>{{ fetchDescription('SSOUserManagement') }}</p>
                                </td>
                            </tr>

                            <!-- <tr>
              <td>Events</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.Events"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('Events') }}</p>
              </td>
            </tr>

            <tr>
              <td>Polls</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.Polls"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('Polls') }}</p>
              </td>
            </tr>

            <tr>
              <td>SSOContentManager</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups.SSOContentManager"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ fetchDescription('SSOContentManager') }}</p>
              </td>
            </tr>  -->

                            <!-- <tr v-for="group in allUserGroups" :key="group.GroupName">
              <td>{{ group.GroupName }}</td>
              <td>
                <v-switch
                  v-model="associatedUserGroups[group.GroupName]"
                  @change="updateData()">
                </v-switch>
              </td>
              <td>
                <p>{{ group.Description }}</p>
              </td>
            </tr> -->

                        </table>
                    </div>
                </v-flex>
            </v-layout>
        </v-form>

        <StickyFooter
            :errorMessage="errorMessage"
            :status="status"
            :buttonText="'Save Admin User'"
            @buttonClicked="create()"
        />
    </section>
</template>

<script>
import axios from "axios"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
    name: "SquadManagement",

    components: {
        StickyFooter
    },

    props: {
        data: {
            type: Object,
            required: false
        }
    },

    data: () => {
        return {
            api: `${process.env.VUE_APP_BASEURL}/adminusers`,
            apiV2: `${process.env.VUE_APP_ADMINUSERSAPI}`,
            modules: null,
            updatedData: {},
            status: "saved",
            errorMessage: "",
            dateModalDob: null,
            dateModalDoj: null,
            valid: false,
            saveDisabled: true,
            allUserGroups: [],
            associatedUserGroups: {},
            rules: {
                required: value => !!value || "Required."
                // groups: value => !!this.updatedData.isContent || 'Required.'
            },
            emailRules: [
                value => !!value || "Email address is required",
                value => /.+@.+/.test(value) || "Email must be valid"
            ],
            isMfaEnabled: false,
            userMfaSetting: null,
            disablingMfa: false,
        }
    },

    created() {
        this.getUserMfaStatus()
    },

    mounted() {
        this.modules = require("../../config/features/" + process.env.VUE_APP_CLUB_ID + "/modules.js")

        this.updatedData = {
            username: null,
            firstName: null,
            lastName: null,
            created: null,
            modified: null,
            status: null,
            groups: null,
            enabled: null
        }

        this.getPermissionGroups()

        this.dataLoaded = true
        this.status = "needsSaving"
        this.$store.commit("completeLoading")
    },

    methods: {
        fetchDescription(thisGroupName) {
            for (const group of this.allUserGroups) {
                if (group.GroupName === thisGroupName) {
                    return group.Description
                }
            }

            return "No description"
        },

        getPermissionGroups() {
            console.log("enable user")
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.api}/usergroups`,
                    headers: { "Authorization": jwt }
                })
                    .then(response => {
                        console.log("User groups", response)
                        this.allUserGroups = JSON.parse(response.data.body)

                        this.getUser()
                    })
                    .catch(e => {
                        console.error("Problem getting groups", e);
                    });
            })
        },

        updateCurrentPermissions() {
            for (const permission of this.updatedData.groups) {
                console.log("updateCurrentPermissions", permission)
                this.associatedUserGroups[permission.GroupName] = true
            }
        },

        updateData() {
            this.status = "needsSaving";
            console.log(this.updatedData);
        },

        create() {
            this.errorMessage = ""
            if (this.$refs.playerForm.validate()) {
                console.log("Validation passed");
                this.addUser();
            } else {
                this.errorMessage = "Certain entries are invalid or required"
            }
        },

        disableUser() {
            console.log("disable user")
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.api}/disable/${this.$route.params.id}`,
                    headers: { "Authorization": jwt }
                })
                    .then(response => {
                        console.log("user disabled", response)
                        this.getUser()
                    })
                    .catch(e => {
                        console.error("Problem disabling user", e);
                        this.errorMessage = "Problem adding user";
                    });
            })
        },

        enableUser() {
            console.log("enable user")
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.api}/enable/${this.$route.params.id}`,
                    headers: { "Authorization": jwt }
                })
                    .then(response => {
                        console.log("user enabled", response)
                        this.getUser()
                    })
                    .catch(e => {
                        console.error("Problem disabling user", e);
                        this.errorMessage = "Problem enabling user";
                    });
            })
        },

        resetPassword() {
            console.log("reset password for user")
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.api}/resetpassword/${this.$route.params.id}`,
                    headers: { "Authorization": jwt }
                })
                    .then(response => {
                        console.log("user password reset", response)
                        this.getUser()
                    })
                    .catch(e => {
                        console.error("Problem resetting password for user", e);
                        this.errorMessage = "Problem adding user";
                    });
            })
        },

        resendTemporaryPassword() {
            var data = {
                data: {
                    type: "admin-user",
                    id: this.updatedData.sub,
                    "attributes": {
                        username: this.updatedData.username
                    }
                }
            }
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "POST",
                    url: `${this.apiV2}/resend`,
                    headers: { "Authorization": jwt },
                    data: data
                })
                    .then(response => {
                        console.log("user password resend", response)
                        this.getUser()
                    })
                    .catch(e => {
                        console.error("Problem resetting temp password for user", e);
                        this.errorMessage = "Problem resetting temp password for user";
                    });
            })
        },

        addUser() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                var data = {
                    firstName: this.updatedData.firstName,
                    lastName: this.updatedData.lastName,
                    username: this.updatedData.username,
                    email: this.updatedData.username
                }

                if (this.disablingMfa) {
                    data.totpMFAEnabled = false
                }

                const obj = this.associatedUserGroups

                const keys = Object.keys(obj);

                data.associatedUserGroups = keys.filter(function (key) {
                    return obj[key]
                });

                axios({
                    method: "POST",
                    url: `${this.api}/${this.$route.params.id}`,
                    headers: { "Authorization": jwt },
                    data
                })
                    .then(response => {
                        console.log("Saved player:", response);
                        console.log("USER Updated", response.data)
                        this.status = "saved";
                        if (response.data.success === true) {
                            console.log("Update user success")
                        }
                    })
                    .catch(e => {
                        console.error("Problem adding admin user", e);
                        this.status = "needsSaving";
                        this.errorMessage = "Problem adding user";
                    });
            })
        },

        getUser() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {

                axios({
                    method: "GET",
                    url: `${this.api}/${this.$route.params.id}`,
                    headers: { "Authorization": jwt }
                })
                    .then(response => {
                        console.log("retrieved user:", response);
                        var user = JSON.parse(response.data.body)

                        if (user.UserMFASettingList && user.UserMFASettingList.length) {
                            this.userMfaSetting = user.UserMFASettingList
                            this.isMfaEnabled = true
                        }

                        // extract attributes to become user properties
                        user.UserAttributes.forEach(function (element) {
                            user[element.Name] = element.Value
                        });

                        this.updatedData = {
                            username: user.Username,
                            firstName: user.given_name,
                            lastName: user.family_name,
                            created: user.UserCreateDate,
                            modified: user.UserLastModifiedDate,
                            status: user.UserStatus,
                            enabled: user.Enabled,
                            groups: user.Groups,
                            sub: user.sub
                        }

                        this.updateCurrentPermissions()

                        console.log("user:", user)

                    })
                    .catch(e => {
                        console.error("Problem adding admin user", e);
                        this.status = "needsSaving";
                        this.errorMessage = "Problem adding user";
                    });
            })
        },
    }
}
</script>

<style
    lang="scss"
    scoped
>
.player {
    &__panel {
        border: 1px solid $lightgrey;
        background-color: $white;
        padding: 20px 20px 0;
        margin-bottom: 20px;

        &-details {
            flex-direction: column;

            @media (min-width: 960px) {
                flex-direction: row;
            }
        }

        &-img {
            img {
                width: 100%;
            }
        }

        &-opta {
            &-cb {
                label {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.input {
    &__textfield {
        &--basic {
            margin-top: 0;
        }
    }
}

.permissions-table {
    tr:last-child td {
        border-bottom: 0 !important;
    }


    tr.bottom-divider td {
        border-bottom: 4px solid #ccc;
    }

    td {
        border-bottom: 1px solid #ebebeb;

        &:first-child {
            text-align: right;
            padding: 0 16px 0 0;
        }

        p {
            margin: 0;
            padding: 0 0 0 40px;
            font-size: 12px;
            text-align: right;
            opacity: 0.7;
        }
    }
}

.help {
    padding: 10px 10px 0;
    margin: 0;
    font-size: 13px;
    background-color: #f4f6d2;
    border: 1px solid #d6d8b0;
    margin-bottom: 16px;
    border-radius: 4px;
}
</style>
