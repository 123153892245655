<template>
  <section>
    <h3 class="subheading">Georestriction Profiles Manager</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 mt-4 class="half">
          <ol>
            <li v-for="profile in profiles" :key="profile.id" class="profile-item">
              <span>{{ profile.attributes.profileName }}</span>

              <button class="profile-item__edit" type="button" @click="editProfile(profile.id)">
                <v-icon>edit</v-icon>
              </button>
            </li>
          </ol>

          <v-btn
            type="button"
            depressed
            color="success"
            @click="createProfile()"
          >
            Create Georestriction Profile
          </v-btn>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Create reusable groups of countries for quick access across Packages, Videos, and Streams</p>
        </v-flex>
      </v-layout>
    </section>
  </section>
</template>

<script>
import axios from "axios"

export default {
  name: "GeorestrictionProfilesManager",

  data:() => ({
    profiles: null
  }),

  mounted() {
    this.fetchProfiles()
  },

  methods: {
    createProfile() {
      this.$router.push("/streamline/georestriction-profiles/create-profile")
    },

    editProfile(id) {
      this.$router.push(`/streamline/georestriction-profiles/${id}`)
    },

    fetchProfiles() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/countryProfiles/`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Fetch profiles:", response);
          this.profiles = response.data.data
        })
        .catch(e => {
          console.error("Problem fetching profiles", e);
        });
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .half {
    width: 50%;
  }

  .section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #DFE3E9;
  }

  .profile-item {
    position: relative;
    margin: 0 0 16px;
    padding: 16px 64px 16px 8px;
    background-color: #fff;
    border: 1px solid #dfe3e9;
    border-radius: 4px;

    &__edit {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
    }
  }
</style>
