<template>
    <v-dialog
        class="form-widget"
        v-model="dialog"
        width="580"
        lazy
    >

        <slot slot="activator"></slot>
        <v-form
            enctype="multipart/form-data"
            ref="singleFieldForm"
            v-model="valid"
            lazy-validation
        >
            <v-card>
                <v-card-title class="widget__heading">
                    Document
                </v-card-title>

                <v-card-text>
                    <p class="widget__description">Select the Document you wish to use</p>
                    <v-select
                        id="doc-select"
                        solo
                        flat
                        required
                        :rules="[rules.required]"
                        v-model="updatedData.content.doc"
                        :items="documents"
                        item-text="name"
                        item-value="id"
                        return-object
                        validate-on-blur
                        @change="selectedForm()"
                    ></v-select>
                    <p>Can’t find what you’re after? All Document are created in
                        <router-link to="/documents">Documents</router-link>
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="accent"
                        outline
                        flat
                        @click="validate()"
                    >
                        Post
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>

    </v-dialog>
</template>

<script>
import axios from "axios"

export default {
    name: "DocWidget",

    props: {
        widgetData: {
            type: Object,
            required: false
        }
    },

    data: () => ({
        api: `${process.env.VUE_APP_DOCUMENTSAPI}/documents?page.number=1&page.size=100`,
        dialog: false,
        valid: false,
        rules: {
            required: value => !!value || "Required."
        },
        documents: [],
        alldocumentData: null,
        selecteddocumentData: null,
        updatedData: {
            content: {
                doc: {
                    id: "",
                    name: ""
                }
            }

        }
    }),

    mounted() {
        if (this.widgetData) {
            this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
        }

        this.fetchDocList()
    },

    methods: {
        fetchDocList() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(this.api, { "headers": { "Authorization": jwt } })
                    .then(response => {
                        console.log("documents response", response);
                        const documentData = response.data.data;

                        this.alldocumentData = response.data.data;
                        console.log()
                        this.documents = documentData.map(item => {
                            return {
                                name: item.attributes.documentTitle,
                                id: item.id
                            }
                        });

                        this.$store.commit("completeLoading");
                    })
                    .catch(e => {
                        console.error("Problem retrieving documents", e);
                        this.$store.commit("completeLoading");
                    });
            })
        },

        selectedForm() {
            console.log("Selected form");

            this.selecteddocumentData = this.alldocumentData.filter(item => {
                return item.id === this.updatedData.content.doc.id
            })
        },

        updateData() {
            console.log("Update data:", this.updatedData);
            this.$emit("updatedData", this.updatedData);
            this.dialog = false;
        },

        validate() {
            this.errorMessage = ""
            if (this.$refs.singleFieldForm.validate()) {
                console.log("Validation passed");
                this.updateData();
            } else {
                this.errorMessage = "Certain entries are invalid or required"
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.widget {
    &__description {
        margin: 0 0 20px;
        color: #7B6B80;
        font-size: 14px;
    }
}
</style>
