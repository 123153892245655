<template>
  <div class="draggable-item__row" :class="createStatusClass">

    <div class="draggable-item__handle">
      <v-icon class="draggable-item__handle-icon">open_with</v-icon>

      <div class="draggable-item__details">
        <p class="draggable-item__name">{{ name }}</p>
        <p class="draggable-item__template">({{ template }})</p>
        <p class="draggable-item__slug">/{{ slug }}</p>
        <p class="draggable-item__child-count" v-if="childrenCount > 0">[{{ childrenCount }}]</p>
        <p class="draggable-item__status" v-if="status !== 'Published'" :class="createStatusClass">{{ status }}</p>
        <p class="draggable-item__label">
          <span v-if="postType === 'homepage'">Homepage</span>
          <span v-if="postType === 'external'">External Link</span>
          <span v-if="postType === 'internalLink'">Internal Page Link</span>
        </p>
      </div>
    </div>

    <div class="draggable-item__actions">
      <button v-if="childrenCount > 0 && !expandedChild" class="draggable-item__action" type="button" @click="expandChild">
        <v-icon>expand_less</v-icon>
      </button>

      <button v-if="childrenCount > 0 && expandedChild" class="draggable-item__action" type="button" @click="closeChild">
        <v-icon>expand_more</v-icon>
      </button>

      <button class="draggable-item__action" type="button" @click="editItem">
        <v-icon>edit</v-icon>
      </button>
    </div>

    <button class="draggable-item__duplicate" type="button" @click="dialogDuplicate = true, pageName = name">
      <v-icon small>file_copy</v-icon>
    </button>

    <button v-if="childrenCount === 0" class="draggable-item__delete" type="button" @click="dialogDelete = true, pageName = name">
      <v-icon small>delete</v-icon>
    </button>

    <v-dialog v-model="dialogDuplicate" persistent max-width="360">
      <v-card>
        <v-card-title class="headline">Duplicate page</v-card-title>
        <v-card-text>Do you wish to duplicate <strong>{{ pageName }}</strong>?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="dialogDuplicate = false">Cancel</v-btn>
          <v-btn depressed color="success" @click="dialogDuplicate  = false, duplicateItem(id)">Duplicate</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogDelete" persistent max-width="360">
      <v-card>
        <v-card-title class="headline">Delete page</v-card-title>
        <v-card-text>Do you wish to delete <strong>{{pageName}}</strong> from the pages?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="dialogDelete = false">Cancel</v-btn>
          <v-btn depressed color="error" @click="dialogDelete  = false, deleteItem(id)">DELETE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: "DraggableMenuRow",

  props: {
    name: {
      type: String,
      required: true
    },
    template: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    allowChildCreation: {
      type: Boolean,
      required: true
    },
    childrenCount: {
      type: Number,
      default: 0
    },
    postType: {
      type: String,
      required: false
    },
  },

  computed: {
    createStatusClass() {
      return `status-${this.status.toLowerCase().trim()}`;
    }
  },

  data: () => ({
    expandedChild: false,
    dialogDelete: false,
    dialogDuplicate: false,
    id: false,
    pageName: false
  }),

  methods: {
    editItem() {
      this.$emit("editItem");
    },

    duplicateItem() {
      this.$emit("duplicateItem");
    },

    deleteItem() {
      this.$emit("deleteItem");
    },

    closeChild() {
      console.log("Close");
      this.expandedChild = false;
      this.$emit("closeChild");
    },

    expandChild() {
      console.log("Expand");
      this.expandedChild = true;
      this.$emit("expandChild");
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/settings';

  .swap-enter-active,
  .swap-leave-active {
    transition: opacity .8s;
  }

  .swap-enter, .swap-leave-to {
    opacity: 0;
  }

  .draggable-item {

    &__row {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;

      &.status-draft {
        border-color: color(draftHighlightBorder);
      }

      &.status-drahiddenft {
        border-color: color(hiddenHighlightBorder);
      }

      &.status-new {
        border-color: color(newHighlightBorder);
      }
    }

    &__handle {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      padding: 15px;
      width: 100%;
      cursor: -webkit-grab;
      cursor: grab;
    }

    &__handle-icon {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }

    &__details {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
      padding: 0;
      margin: 0 0 0 45px;
      text-align: left;
    }

    &__name {
      margin: 0 8px 0 0;
    }

    &__template {
      margin: 0 8px 0 0;
    }

    &__child-count {
      margin: 0 8px 0 0;
    }

    &__status {
      padding: 3px 17px;
      margin: 1px 8px 0 5px;
      font-size: 12px;
      color: #fff;
      background-color: #E6E8F1;
      border-radius: 4px;

      &.status-draft {
        background-color: color(draftHighlight);
      }

      &.status-hidden {
        background-color: color(hiddenHighlight);
      }

      &.status-new {
        background-color: color(newHighlight);
      }
    }

    &__slug {
      position: absolute;
      bottom: -2px;
      left: 50px;
      margin: 0 8px;
      font-size: 10px;
      font-style: italic;
      opacity: 0.5;
    }

    &__actions {
      padding: 0 15px 0 0;
      width: 160px;
      text-align: right;
    }

    &__action {
      margin: 0 0 0 25px;
    }

    &__duplicate {
      position: absolute;
      top: 12px;
      left: -23px;
      padding: 3px;
      background-color: #ccc;
      border-radius: 4px 0 0 4px;
      opacity: 0;
      pointer-events: none;
      outline: none;
      transition: opacity 0.3s ease-in-out;
      z-index: 1;
    }

    &__delete {
      position: absolute;
      top: 12px;
      right: -23px;
      padding: 3px;
      background-color: #ccc;
      border-radius: 0 4px 4px 0;
      opacity: 0;
      pointer-events: none;
      outline: none;
      transition: opacity 0.3s ease-in-out;
      z-index: 1;
    }
  }

  .draggable-item__row:hover > .draggable-item__duplicate,
  .draggable-item__row:hover > .draggable-item__delete {
    opacity: 1;
    pointer-events: auto;
  }

</style>

<style>
  .sortable-chosen .draggable-item__duplicate
  .sortable-chosen .draggable-item__delete,
  .is-dragging .draggable-item__duplicate,
  .is-dragging .draggable-item__delete {
    display: none !important;
  }
</style>
