<template>
  <v-dialog
    class="previous-meetings-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
          Previous Fixtures
        </v-card-title>

        <v-card-text>

        <v-form enctype="multipart/form-data" v-model="valid" ref="previousFixturesForm" lazy-validation>

          <label class="label" for="squad">Select Squad</label>
          <v-select
          :items="squads"
          item-text="name"
          item-value="id"
          id="squad"
          v-model="previousFixturesData.teamID"
          outline
          validate-on-blur
          label="Select Squad"
          required
          :rules="[rules.required]"
          @change="previousFixturesData.opponentsID = null, setTeamName($event)"
          ></v-select>

          <label class="label" for="opponents">Select Opposition</label>
          <v-select
          :items="opponents"
          item-text="name"
          item-value="id"
          id="opponents"
          v-model="previousFixturesData.opponentsID"
          :disabled="!previousFixturesData.teamID || !opponents.length"
          outline
          validate-on-blur
          :label="oppenentsHTMLLabel"
          required
          :rules="[rules.required]"
          @change="setOppositionName($event)"
          ></v-select>

          <v-btn
            color="accent"
            outline
            flat
            @click="dialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="accent"
            depressed
            :disabled="!previousFixturesData.teamID || !previousFixturesData.opponentsID"
            @click="validate()"
          >
            <div v-if="widgetData">
              Update previous meetings
            </div>
            <div v-else>
              Add previous meetings
            </div>
          </v-btn>

        </v-form>

        </v-card-text>
      </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "PreviousMeetingsWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    apiFilter: `${process.env.VUE_APP_BASEURL}/fixtures/opta/filters`,
    apiPreviousOpponents: `${process.env.VUE_APP_BASEURL}/teams/opta/previousopponents`,
    dialog: false,
    valid: false,
    fixturesConfig: null,
    squads: [],
    opponents: [],
    rules: {
      required: value => !!value || "Required."
    },
    oppenentsHTMLLabel: "Select Opposition",
    previousFixturesData: {
      teamID: null,
      teamName: null,
      opponentsID: null,
      opponentsName: null
    }
  }),

  mounted() {
    console.log("Previous fixtures widget mounted");

    if (this.widgetData) {
      this.previousFixturesData = JSON.parse(JSON.stringify(this.widgetData));
      this.getOpposition(this.previousFixturesData.teamID);
    }

    this.$store.commit("startLoading");
    this.$getCurrentUserJwToken().subscribe((jwt) => {
      axios
      .get(this.apiFilter, { "headers": { "Authorization": jwt }})
      .then(response => {
        console.log("Opta filter response: ", response);
        this.fixturesConfig = response.data.body;

        this.squads = this.fixturesConfig.map(item => {
          return {name: item.teamName, id: item.teamID}
        });
        console.log("Squads", this.squads);
        this.$store.commit("completeLoading");
      })
      .catch(e => {
        console.error("Problem retrieving squads", e);
        this.$store.commit("completeLoading");
      });
    })
  },

  methods: {
    setTeamName(e) {
      const team = this.squads.filter(item => item.id === e);
      this.previousFixturesData.teamName = team[0].name;
      this.getOpposition(team[0].id);
    },
    setOppositionName(e) {
      const team = this.opponents.filter(item => item.id === e);
      this.previousFixturesData.opponentsName = team[0].name;
    },
    getOpposition(id) {
      this.$store.commit("startLoading");
      this.oppenentsHTMLLabel = "Loading...";
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.apiPreviousOpponents}?teamID=${id}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("Previous opponents: ", response);
          const opponents = response.data.body;

          this.opponents = opponents.map(item => {
            return {name: item.teamName, id: item.teamID}
          });
          console.log("Opponents", this.opponents);
          this.$store.commit("completeLoading");
          this.oppenentsHTMLLabel = "Select Opposition";
        })
        .catch(e => {
          console.error("Problem retrieving opponents", e);
          this.$store.commit("completeLoading");
          this.oppenentsHTMLLabel = "No matches found";
        });
      })
    },
    updateData() {
      console.log("Update data:", this.previousFixturesData);
      this.$emit("updatedData", this.previousFixturesData);
      this.dialog = false;
    },
    validate() {
      this.errorMessage = ""
      if (this.$refs.previousFixturesForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.previous-meetings-widget {
}
</style>
