<template>
  <div class="page-content">
    <v-tabs>
      <template
        v-if="
          !modules.default.LFCExclusive ||
          this.$route.path !== '/video-management/content/content-feeds/feed2'
        "
      >
        <v-tab :ripple="{ class: 'accent--text' }">Hero</v-tab>

        <v-tab-item px-0 class="main-body">
          <!-- <h2 class="subheading">Updating Hero</h2> -->

          <hero-editor
            v-if="loaded"
            :heroData="heroProper"
            @updatedData="updateHeroData($event)"
          />

          <!-- <h2 class="subheading">Updating Hero</h2> -->
          <!-- <section class="section-container">
          <p>Temporary json editor for adding the feed hero data.  <b>This must be a <a target="_blank" href="https://jsonformatter.curiousconcept.com/">valid JSON object</a>.</b></p>
          <v-textarea
            :disabled="!superUser"
            flat
            solo
            rows="30"
            v-model="heroJson"
            @input="updateStatus()" />
        </section> -->
        </v-tab-item>
      </template>

      <v-tab>Content</v-tab>

      <v-tab-item px-0 class="main-body">
        <!-- <h2 class="subheading">Updating Content</h2> -->

        <row-editor
          v-if="loaded"
          :contentData="contentProper"
          @updatedData="updateContentData($event)"
        />
        <!-- <h2 class="subheading">DEBUG - PARENT DATA - EDIT SCREEN</h2>
        <pre>{{contentProper}}</pre> -->
        <!-- <h2 class="subheading">Updating Content</h2>
        <section class="section-container">
          <p>Temporary json editor for adding the feed content data.  <b>This must be a <a target="_blank" href="https://jsonformatter.curiousconcept.com/">valid JSON array</a>.</b></p>
          <v-textarea
            :disabled="!superUser"
            flat
            solo
            rows="60"
            v-model="contentJson"
            @input="updateStatus()" />
        </section> -->
      </v-tab-item>
    </v-tabs>

    <StickyFooter
      :errorMessage="errorMessage"
      :status="status"
      :buttonText="'Save feed'"
      @buttonClicked="saveFeed()"
      :allowPreview="false"
    />
  </div>
</template>

<script>
import axios from "axios";
import StickyFooter from "@/components/Library/StickyFooter";
import HeroEditor from "@/components/VideosManager/Data/VideoContentFeeds/HeroEditor";
import RowEditor from "@/components/VideosManager/Data/VideoContentFeeds/RowEditor";

export default {
  name: "VideoContentFeedEdit",

  components: {
    HeroEditor,
    RowEditor,
    StickyFooter,
  },

  mounted() {
    // console.log('path', this.$route.path)
    if (this.$route.path != "/video-management/content/content-feeds/create") {
      this.getFeed(this.$route.params.id);
    }
    if (
      this.$store.state.user.email === "chrisreynolds@urbanzoo.io" ||
      this.$store.state.user.email === "adamhughes@urbanzoo.io"
    ) {
      this.superUser = true;
    }
  },

  data: () => ({
    api: process.env.VUE_APP_VIDEOS_API,
    loaded: false,
    superUser: false,
    errorMessage: null,
    status: "saved",
    feedID: null,
    heroJson: null,
    contentJson: null,
    heroProper: null,
    contentProper: null,
    modules: null,
  }),

  created() {
    this.modules = require("../../../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
  },

  methods: {
    getFeed(id) {
      this.$store.commit("startLoading");
      // console.log('getting single feed', id)
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/content/${id}`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            this.heroJson = JSON.stringify(response.data.data.attributes.hero);
            this.contentJson = JSON.stringify(
              response.data.data.attributes.content
            );
            this.heroProper = response.data.data.attributes.hero;
            this.contentProper = response.data.data.attributes.content;
            this.feedID = response.data.data.id;
            this.status = "saved";
            this.$store.commit("completeLoading");
            this.loaded = true;
          })
          .catch((e) => {
            console.error("Problem getting single feed", e);
            this.$store.commit("completeLoading");
            this.errorMessage = "Problem getting feed data";
          });
      });
    },

    saveFeed() {
      this.$store.commit("startLoading");
      const jsonHero = this.heroProper;
      const jsonContent = this.contentProper;
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: `${this.api}/content/${this.feedID}`,
          headers: { Authorization: jwt },
          data: {
            data: {
              id: this.feedID,
              type: "video-content",
              attributes: {
                hero: jsonHero,
                content: jsonContent,
              },
            },
          },
        })
          .then((response) => {
            console.log("saved feed", response);
            this.status = "saved";
            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem saving single feed", e);
            this.$store.commit("completeLoading");
            this.errorMessage = "Problem saving feed data";
          });
      });
    },

    updateStatus() {
      this.status = "needsSaving";
    },

    updateHeroData(heroData) {
      // console.log('updating hero', heroData)
      this.heroProper = JSON.parse(JSON.stringify(heroData));
      this.status = "needsSaving";
    },

    updateContentData(updatedData) {
      // console.log('updating content - top level parent','this', updatedData, 'needs to replace this', this.contentProper)
      this.contentProper = JSON.parse(JSON.stringify(updatedData));
      // console.log('save this back to the api', this.contentProper)
      this.status = "needsSaving";
    },
  },
};
</script>

<style scoped lang='scss'>
</style>
