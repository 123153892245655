<template>
  <v-dialog
    class="top-player-stats-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
        Top Player Stats
        </v-card-title>

        <v-card-text>

          <v-form enctype="multipart/form-data" v-model="valid" ref="topPlayerStatsForm" lazy-validation>

            <section>
              <!-- <input type="text" v-model="statID" placeholder="statID" /> -->
              <button @click="autofill()" type="button">Autofill</button>

              <template v-if="autofillData">
                <table>
                  <thead>
                    <th>Player</th>
                    <th>
                      {{ $store.state.insights.selectedPlayers.sortedBy }}
                    </th>
                    <th>
                      <v-select
                        v-model="secondaryStatID"
                        item-text='name'
                        item-value='value'
                        :items="[
                          { name: 'Touches', value: 'touches' },
                          { name: 'Passes', value: 'total_pass' },
                          { name: 'Duels Won', value: 'duel_won' },
                          { name: 'Fouls', value: 'fouls' }
                        ]"
                        solo
                        flat
                        required/>
                    </th>
                    <th>
                      <v-select
                        v-model="tertiaryStatID"
                        item-text='name'
                        item-value='value'
                        :items="[
                          { name: 'Touches', value: 'touches' },
                          { name: 'Passes', value: 'total_pass' },
                          { name: 'Duels Won', value: 'duel_won' },
                          { name: 'Fouls', value: 'fouls' }
                        ]"
                        solo
                        flat
                        required/>
                    </th>
                  </thead>
                  <tbody>
                    <tr v-for="player in $store.state.insights.selectedPlayers.players" :key="player.playerID">
                      <td>{{ player.firstName }} {{ player.surname }}</td>
                      <td>{{ player.playerStats[$store.state.insights.selectedPlayers.sortedBy] }}</td>
                      <td>{{ player.playerStats[secondaryStatID] }}</td>
                      <td>{{ player.playerStats[tertiaryStatID] }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>

              <v-btn
                color="accent"
                outline
                flat
                @click="dialog = false"
              >
                Cancel
              </v-btn>

              <v-btn
                color="accent"
                depressed
                @click="validate()"
              >
                Add Stats
              </v-btn>
            </section>

          </v-form>

        </v-card-text>
      </v-card>

  </v-dialog>
</template>

<script>

export default {
  name: "TopPlayerStatsWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    valid: false,
    dialog: false,
    secondaryStatID: null,
    tertiaryStatID: null,
    autofillData: false
  }),

  mounted() {
    console.log("Top Player Stats widget mounted");
  },

  methods: {
    autofill() {
      this.autofillData = true
      console.log("Autofill: ", this.$store.state.insights.selectedPlayers.players[0].playerStats)
    },

    validate() {
      this.errorMessage = ""
      if (this.$refs.topPlayerStatsForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },

    updateData() {
      console.log("Update share price data:");
      this.$emit("updatedData");
      this.dialog = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit {
    margin: 0 10px 20px 0;
    width: 100%;
  }
</style>