<template>

  <v-dialog
    lazy
    width="580"
    scrollable
    v-model="dialog"
    persistent>

    <slot slot="activator"></slot>

    <v-card class="section-container">
      <v-card-title class="widget__heading">Edit Advert</v-card-title>
      <v-card-text>

        <label class="label" for="enabled">Enabled</label>
        <v-select
          id="enabled"
          outline
          v-model="updatedData.enabled"
          item-text="name"
          item-value="id"
          :items="[
            {name: 'Yes, enable this advert', id: true},
            {name: 'No, do not show this advert', id: false}
          ]"
          solo
          flat
        ></v-select>

        <label class="label" for="advert-name">Advert Name</label>
        <v-text-field id="name" solo flat v-model="updatedData.advertName">
        </v-text-field>

        <label class="label" for="advert-ref">Advert Reference</label>
        <v-text-field id="advert-ref" solo flat v-model="updatedData.advertRef">
        </v-text-field>

        <label class="label" for="campaign-ref">Campaign Reference</label>
        <v-text-field id="campaign-ref" solo flat v-model="updatedData.campaignRef">
        </v-text-field>

        <label class="label" for="url">Link to url</label>
        <v-text-field type="url" id="url" solo flat v-model="updatedData.url">
        </v-text-field>

        <label class="label" for="enabled">Is child friendly?</label>
        <v-select
          id="enabled"
          outline
          v-model="updatedData.childFriendly"
          item-text="name"
          item-value="id"
          :items="[
            {name: 'Yes, show this on pages targeted towards children', id: true},
            {name: 'No, do not show this on pages targeted towards children', id: false}
          ]"
          solo
          flat
        ></v-select>

        <label class="label" for="enabled">Channel</label>
        <v-select
          id="enabled"
          outline
          v-model="updatedData.channel"
          item-text="name"
          item-value="id"
          :items="[
            {name: 'Show on all', id: ''},
            {name: 'Web Only', id: 'web'},
            {name: 'App Only', id: 'app'}
          ]"
          solo
          flat
        ></v-select>

        <label class="label" for="start">Start date</label>
        <datetime
          v-model="updatedData.timings.startUTC"
          type="datetime"></datetime>
        <p class="utc-note">This will start at {{ updatedData.timings.startUTC }}</p>

        <label class="label" for="end">End date</label>
        <datetime
          v-model="updatedData.timings.endUTC"
          type="datetime"></datetime>
        <p class="utc-note">This will end at {{ updatedData.timings.endUTC }}</p>

        <ImageUploader v-if="dialog" :title="'Square Advert'" :widthOptionEnabled="false" :mediaLibraryID="updatedData.images.square.mediaLibraryID" @update="updateImage($event, 'square')" :desc="'<p>Recommended size: 600px width by 600px height</p>'"/>

        <ImageUploader v-if="dialog" :title="'Banner Advert'" :widthOptionEnabled="false" :mediaLibraryID="updatedData.images.banner.mediaLibraryID" @update="updateImage($event, 'banner')" :desc="'<p>Recommended size: 1000px width by 100px height</p>'"/>

        <!-- <ImageUploader v-if="dialog" :title="'Billboard Advert'" :widthOptionEnabled="false" :mediaLibraryID="updatedData.images.billboard.mediaLibraryID" @update="updateImage($event, 'billboard')" :desc="'<p>Please refer to your image guidelines for safe zones and dimensions</p>'"/> -->

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          flat
          class=""
          @click="cancelAdvert();"
        >
          Cancel
        </v-btn>

        <v-btn
          v-if="creatingAdvert"
          flat
          class="submit-button accent"
          @click="addAdvert();"
        >
          Add
        </v-btn>

        <v-btn
          v-else
          flat
          class="submit-button accent"
          @click="editAdvert();"
        >
          Edit
        </v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import ImageUploader from "@/components/Library/ImageUploader"

export default {
  name: "EditAdvert",

  components: {
    ImageUploader
  },

  props: {
    advertData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    dialog: true,
    creatingAdvert: true,
    updatedData: {
      enabled: false,
      advertRef: "",
      advertName: "",
      campaignRef: "",
      childFriendly: true,
      channel: "",
      url: "",
      images: {
        banner: {
          imageKey: "",
          mediaLibraryID: ""
        },
        square: {
          imageKey: "",
          mediaLibraryID: ""
        },
        billboard: {
          imageKey: "",
          mediaLibraryID: ""
        }
      },
      timings: {
        startUTC: "",
        endUTC: ""
      }
    }
  }),

  mounted() {
    console.log("Mounted", this.advertData);
    if (this.advertData) {
      console.log("Editing advert")
      this.creatingAdvert = false
      this.updatedData = JSON.parse(JSON.stringify(this.advertData))
    } else {
      console.log("New advert")
      this.creatingAdvert = true
    }
  },
  
  methods: {
    addAdvert() {
      console.log("Add advert", this.updatedData)
      this.updatedData.id = this.$uuid.v1()
      this.$emit("addAdvert", this.updatedData);
    },

    editAdvert() {
      console.log("Edit advert", this.updatedData)
      this.$emit("editAdvert", this.updatedData);
    },

    cancelAdvert() {
      this.$emit("closeAdvert");
    },

    updateImage(payload, target) {
      console.log("updateImage", payload)
      this.updatedData.images[target].mediaLibraryID = payload.mediaLibraryID
      this.updatedData.images[target].imageKey = payload.imageKey
    },
  }
}
</script>

<style scoped lang="scss">

.section-container {
  margin: 0;
  padding: 0 32px 16px;
}

.section-divider {
  padding-top: 16px;
}

.submit-button {
  margin: 0;
  margin-left: auto;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
}

</style>
