var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-picker-row"},[_vm._l((_vm.currentPositions),function(position,index){return _c('div',{key:position.displayName,staticClass:"position",on:{"click":function($event){return _vm.openModal(index)}}},[_vm._v(" "+_vm._s(`${position.displayName} : ${ position.displayValue ? position.displayValue : position.value.includes("%") ? "Custom" : position.value }`)+" ")])}),_c('v-dialog',{staticClass:"dialog",attrs:{"width":"1200","lazy":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"modal"},[_c('v-tabs',{attrs:{"centered":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_vm._l((_vm.currentPositions),function(position,index){return [_c('v-tab',{key:`tab-${position.displayName}`},[_vm._v(_vm._s(position.displayName))]),_c('v-tab-item',{key:`item-${position.displayName}`,staticClass:"modal-item",on:{"mouseup":function($event){return _vm.stopDrag(index)}}},[_c('p',{staticClass:"help-text"},[_vm._v(" Position: "+_vm._s(position.displayValue ? position.displayValue : position.value.includes("%") ? "Custom" : position.value)+" ")]),(_vm.image)?_c('div',{staticClass:"image",style:(`background-image: url('${_vm.image}'); padding-bottom: ${_vm.getPadding(
                position
              )}; width: ${position.width}; background-position: ${
                _vm.currentPositions[index].value
              }`),attrs:{"id":`drag-image-${index}`},on:{"mousedown":function($event){return _vm.startDrag($event, index)}}}):_c('p',[_vm._v("No image found")]),_c('div',{staticClass:"modal-instructions"},[_c('div',{staticClass:"grid"},_vm._l((9),function(gridItem,index2){return _c('div',{key:index2,staticClass:"grid-item",class:{
                    'grid-item--active': _vm.activeGridItems[index] == index2,
                  },on:{"click":function($event){return _vm.selectPositionGrid(gridItem - 1, index)}}})}),0),_c('p',[_vm._v(" Drag the image, click on the grid or type inside the box to define a position ")]),_c('div',{staticClass:"button-group"},[_c('v-btn',{attrs:{"type":"button"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"accent","dark":"","type":"button"},on:{"click":function($event){return _vm.savePositions()}}},[_vm._v(" Save ")])],1)])])]})],2)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }