<template>

  <div v-if="tagsData.length" class="tags__listings">
    <ul class="tags__list">
      <li class="tags__list-item" v-for="tag in tagsData" :key="tag.id">
        {{tag.name}} <v-icon @click="deleteTag(tag.id)" color="accent" right :class="'tags__list-item-remove'">remove_circle</v-icon>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NewsTags",

  props: {
    tagsData: {
      type: Array,
      required: false
    }
  },

  data: () => ({
  }),

  mounted() {
  },

  methods: {
    deleteTag(id) {
      this.$emit("delete", id)
    }
  }

}
</script>

<style lang="scss" scoped>
  .tags {
    &__listings {
      width: 100%;
    }
    &__list {
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      &-item {
        padding: 7px 15px;
        background-color: rgba($purple, 0.2);
        border: 1px solid rgba($purple, 0.5);
        color: $purple;
        border-radius: 3px;
        margin-bottom: 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        &-remove {
          cursor: pointer;
        }
      }
    }
  }
</style>
