<template>
  <div class="list-stat">
    <h4 class="list-stat__title">{{ title }}</h4>
    <ol class="list-stat__list">
      <li v-for="(value, index) in data" :key="index" class="list-stat__item">
        <p class="list-stat__fact">{{ title.includes('Browser') ? value.browser : value.country_code }}</p>
        <span class="list-stat__value">{{ group === 'views' ? value.pageviews : value.uniques }}</span>
      </li>
    </ol>
  </div>
</template>

<script>

export default {
  name: "FathomPageDetailBlock",

  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  }
}

</script>

<style lang="scss" scoped>

 .list-stat {
    padding: 16px;
    color: #fff;
    background: linear-gradient(45deg, #24002F, #67247c);

    &__title {
      font-size: 14px;
      font-weight: 300;
    }

    &__list {
      padding: 0;
      list-style: none;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 8px 0 0;
      padding: 2px 4px;
      font-size: 12px;
      font-weight: 300;
      background: rgba(0, 0, 0, 0.2);
    }

    &__fact {
      color: #fff;
      text-decoration: none;
      margin-bottom: 0;
    }

    &__value {
      margin: 0 0 0 16px;
      font-weight: 500;
    }
  }

</style>