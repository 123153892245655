var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget-details"},[(!_vm.updatedData.widgetType)?[_c('p',{staticClass:"widget-info"},[_vm._v(" This widget displays a list of all your published categories in a carousel, with an optional link button. ")]),(!_vm.updatedData.widgetType)?_c('v-btn',{staticClass:"accent",on:{"click":function($event){return _vm.initialise()}}},[_vm._v("Use this widget")]):_vm._e()]:_c('div',{staticClass:"widget-fieldset"},[_c('p',{staticClass:"warning-text"},[_vm._v(" Category card content is controlled from the Global Settings, inside "),_c('v-btn',{staticClass:"accent",attrs:{"to":'/video-management/config?tab=2',"tag":"a","text":"","outline":"","small":"","flat":""}},[_vm._v("Categories")]),_c('br'),_vm._v("Please use this section to edit Text and Images. ")],1),_c('label',{staticClass:"label"},[_vm._v("Title")]),_c('v-text-field',{attrs:{"id":"widgetData.title","solo":"","flat":""},model:{value:(_vm.updatedData.widgetData.title),callback:function ($$v) {_vm.$set(_vm.updatedData.widgetData, "title", $$v)},expression:"updatedData.widgetData.title"}}),(_vm.$route.query.isApp !== 'true')?[_c('label',{staticClass:"label"},[_vm._v("Style")]),_c('v-select',{attrs:{"id":"widgetData.style","items":_vm.styleOptions,"solo":"","flat":""},model:{value:(_vm.updatedData.widgetData.style),callback:function ($$v) {_vm.$set(_vm.updatedData.widgetData, "style", $$v)},expression:"updatedData.widgetData.style"}}),(_vm.modules.default.LFCExclusive)?_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"mr-2":""}},[_c('ImageUploader',{attrs:{"title":'Background image (optional)',"widthOptionEnabled":false,"mediaLibraryID":_vm.updatedData.widgetData.imageData
                ? _vm.updatedData.widgetData.imageData.mediaLibraryID
                : ''},on:{"update":function($event){return _vm.updateImage($event)}}})],1)],1):_vm._e(),_c('label',{staticClass:"label"},[_vm._v("Button URL")]),_c('v-text-field',{attrs:{"id":"widgetData.destinationUrl","solo":"","flat":""},model:{value:(_vm.updatedData.widgetData.destinationUrl),callback:function ($$v) {_vm.$set(_vm.updatedData.widgetData, "destinationUrl", $$v)},expression:"updatedData.widgetData.destinationUrl"}}),_c('label',{staticClass:"label"},[_vm._v("Button Text")]),_c('v-text-field',{attrs:{"id":"widgetData.destinationLinkText","solo":"","flat":""},model:{value:(_vm.updatedData.widgetData.destinationLinkText),callback:function ($$v) {_vm.$set(_vm.updatedData.widgetData, "destinationLinkText", $$v)},expression:"updatedData.widgetData.destinationLinkText"}}),(_vm.modules.default.LFCExclusive)?[_c('label',{staticClass:"label"},[_vm._v("Categories (if none is chosen, all will be displayed)")]),(
            _vm.categories &&
            _vm.categories.length &&
            _vm.updatedData.widgetData &&
            _vm.updatedData.widgetData.categories
          )?[_vm._l((_vm.categories),function(category){return _c('p',{key:category.id,staticClass:"category",class:{
              'category--active':
                _vm.updatedData.widgetData.categoriesID.includes(category.id),
            },on:{"click":function($event){return _vm.handleSelection(category)}}},[_vm._v(" "+_vm._s(category.attributes.name)+" ")])}),_c('div',{staticClass:"selected-categories"},[_c('label',{staticClass:"label"},[_vm._v("Selected categories (drag and drop to reorder)")]),(
                _vm.updatedData.widgetData.categories &&
                _vm.updatedData.widgetData.categories.length
              )?[_c('draggable',{attrs:{"options":{
                  ghostClass: 'ghost',
                  dragClass: 'sortable-drag',
                }},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.updatedData.widgetData.categories),callback:function ($$v) {_vm.$set(_vm.updatedData.widgetData, "categories", $$v)},expression:"updatedData.widgetData.categories"}},[_c('transition-group',{staticClass:"featured__items",attrs:{"name":"list-complete"}},_vm._l((_vm.updatedData.widgetData.categories),function(category){return _c('p',{key:category.id,staticClass:"category category--selected"},[_vm._v(" "+_vm._s(category.attributes.title)+" "),_c('v-icon',{staticClass:"remove-icon",attrs:{"color":"accent","right":""},on:{"click":function($event){return _vm.handleSelection(category)}}},[_vm._v("remove_circle")])],1)}),0)],1)]:_c('p',[_vm._v("No Categories selected")])],2)]:_c('p',[_vm._v("Loading...")])]:_vm._e()]:_vm._e()],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }