<template>
  <section class="faq-accordian">

    <div v-for="(section, index) in faq" :key="section.id" class="faq-section" :class="visible === `faq-section--${index}`">

      <!-- <h3 class="subheading">{{section.title}}</h3> -->

      <v-icon class="faq-accordian__button" v-if="visible === `faq-section--${index}`" color="accent" right @click="togglePanel(`faq-section--${index}`)">remove_circle_outline</v-icon>
      <v-icon class="faq-accordian__button" v-else color="accent" right @click="togglePanel(`faq-section--${index}`)">add_circle_outline</v-icon>

      <v-text-field class="faq-accordian__section-name" solo flat v-model="section.title" placeholder="Enter Section Title"></v-text-field>

      <template v-if="visible === `faq-section--${index}`">
        <template  v-for="(question, index) in section.question">

        <div class="faq-accordian__question-container" :key="question.index" v-if="visible">
          <v-text-field class="faq-accordian__question-title" solo flat :key="question.index"  v-model="section.question[index].heading" placeholder="Enter Question Title"></v-text-field>
          <v-textarea class="faq-accordian__question-question" solo flat :key="question.index"  v-model="section.question[index].question" placeholder="Enter Section Question"></v-textarea>

          <div class="faq-accordian__action-container" :key="question.index">

            <button class="faq-accordian__delete" :key="question.index" @click="deleteQuestion(index, section.id)">
            <v-icon>delete</v-icon>
            </button>
          </div>

        </div>
        </template>
      </template>

      <v-btn
        color="accent"
        outline
        flat
        @click="addQuestion(index)"
      >
        Add Question
      </v-btn>

      <v-btn
        color="accent"
        outline
        flat
        @click="deleteRow(section.id)"
      >
        Delete Section
      </v-btn>

    </div>

      <v-btn
        color="accent"
        outline
        flat
        @click="addRow()"
      >
        Add Section
      </v-btn>

  </section>
</template>

<script>
export default {
  name: "VideosV3Accordian",

  data: () => ({
    faq: [],
    visible: "",
  }),

  methods: {

    addRow() {
      this.faq.push({
        id: this.$uuid.v1(),
        title: "",
        question: []
      })
    },

    deleteRow(section) {
      var item = this.faq.findIndex(item => item.id === section);
      this.faq.splice(item, 1);
    },

    addQuestion(index) {  
      this.faq[index].question.push({
        heading: "",
        question: ""
      })
    },

    deleteQuestion(index, section) {
      var sectionNumber = this.faq.findIndex(item => item.id === section);
      this.faq[sectionNumber].question.splice(index, 1);
    },

    togglePanel(panel) {
      if (panel === this.visible) {
        this.visible = ""
      } else {
      this.visible = panel
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-accordian {
  &__question-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__action-container {
    position: absolute;
    left: 100%;
    top: 50px;
  }

  &__button {
    position: relative;
    left: -45px;
    top: 40px;
  }
}

.faq-section {
  border-bottom: #67247C solid 1px;
}

.subheading {

  &:hover {
    cursor: pointer;
  }

  &__label {
    position: relative;
    top: -2px;
    margin: 0 0 0 8px;
    padding: 4px 8px;
    color: #67247C;
    font-size: 12px;
    font-weight: 400;
    background-color: #E6E8F1;
    border-radius: 4px;
  }
}
</style>