<template>

  <div v-if="!editable" class="list-row">

    <div class="list-col list-col__1">
      <p><strong>Title: </strong>{{ heading }}</p>
      <p><strong>Links To: </strong>{{ link }}</p>
    </div>

    <div class="list-col list-col__2">
      <v-btn
        color="accent"
        outline
        flat
        @click="deleteItem()"
      >
        Delete Item
      </v-btn>  

      <v-btn
        light
        outline
        flat
        @click="editRow()"
      >
        Edit Item
      </v-btn>
    </div>
  </div>

    <div v-else>
      <custom-carousel-item-form 
      :heading="heading"
      :link="link"
      :number="number"
      :player="player"
      :layout="layout"
      :color="color"
      :imageID="imageID"
      :imageHoverID="imageHoverID"
      :imageHover="imageHover"
      :searchTerm="searchTerm"
      :edit="true"
      @saveRow="saveRow($event)"
      @cancelEdit="cancelEdit()" />
    </div>

</template>

<script>
import CustomCarouselItemForm from "./CustomCarouselItemForm.vue";


export default {
  components: { CustomCarouselItemForm },
  name: "CarouselWidgetItem",

  props: {
    heading: {
      type: String
    },
    link: {
      type: String,
    },
    number: {
      type: String,
    },
    player: {
      type: Boolean,
    },
    layout: {
      type: String,
    },
    color: {
      type: String
    },
    imageID: {
      type: String
    },
    imageHoverID: {
      type: String
    },
    imageHover: {
      type: Boolean
    },
    searchTerm: {
      type: String
    }
  },

  data:() => ({
    editable: false
  }),

  methods: {
    deleteItem() {
      this.$emit("deleteItem");
    },

    editRow() {
      this.$emit("hideOthers", true);
      this.editable = true
    },

    saveRow(payload) {
      // console.log('Save 2', payload)
      this.$emit("saveRow", payload);

      // console.log('PAYLOAD HERE', payload)
      this.$emit("hideOthers", false);
      this.editable = false
    },

    cancelEdit() {
      this.$emit("hideOthers", false);
      this.editable = false
    }
  }
}
</script>

<style scoped lang='scss'>

.list-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #eee6f1;
  border-radius: 4px;
  padding: 8px;
  margin: 8px;
  transition: all 0.5s, opacity 0.2s;

  &:hover {
    cursor: pointer;
  }
}

.list-col {
  display: flex;
  flex-direction: column;
  
  &__1 {
    width: 70%;
  }
  &__2 {
    width: 30%;
  }
}

</style>
