<template>
  <v-dialog
    class="club-promo-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
        Image Grid Item
        </v-card-title>

        <v-card-text>

        <v-form enctype="multipart/form-data" v-model="valid" ref="gridImageForm" lazy-validation>

          <section>
            <label class="label" for="title">Title</label>
            <v-text-field
              id="title"
              outline
              v-model="gridImageData.title"
              solo
              flat
              class="text-field--lableless"
            ></v-text-field>

            <label class="label" for="description">Description</label>
            <v-text-field
              id="title"
              outline
              v-model="gridImageData.description"
              solo
              flat
              class="text-field--lableless"
            ></v-text-field>

            <label class="label" for="buttonText">CTA Text</label>
            <v-text-field
              id="buttonText"
              outline
              v-model="gridImageData.buttonText"
              solo
              flat
              class="text-field--lableless"
            ></v-text-field>

            <label class="label" for="buttonLink">CTA Link</label>
            <v-text-field
              id="buttonLink"
              outline
              v-model="gridImageData.buttonLink"
              solo
              flat
              class="text-field--lableless"
            ></v-text-field>

            <ImageUploader :title="'Image'" :widthOptionEnabled="false" :mediaLibraryID="gridImageData.imageID" @update="updateImage($event)" />

            <v-btn
              color="accent"
              outline
              flat
              @click="dialog = false"
            >
              Cancel
            </v-btn>

            <v-btn
              color="accent"
              depressed
              @click="validate()"
            >
              <span v-if="widgetData">Update image</span>
              <span v-else>Add image</span>
            </v-btn>
          </section>

        </v-form>

        </v-card-text>
      </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"
import ImageUploader from "@/components/Library/ImageUploader.vue"

export default {
  name: "GridImageWidget",

  components: {
    ImageUploader
  },

  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}`,
    dialog: false,
    valid: false,
    rules: {
      required: value => !!value || "Required."
    },
    gridImageData: {
      title: null,
      description: null,
      buttonText: null,
      buttonLink: null,
      imageID: null
    },
  }),

  mounted() {
    console.log("Grid Image widget mounted");

    if (this.widgetData) {
      this.gridImageData = JSON.parse(JSON.stringify(this.widgetData));
    }
  },

  methods: {
    updateImage(payload) {
      console.log("Update image:", payload);

      this.gridImageData.imageID = payload.mediaLibraryID
    },

    validate() {
      this.errorMessage = ""
      if (this.$refs.gridImageForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },

    updateData() {
      if (this.gridImageData.title === "") {
        this.gridImageData.title = null
      }
      if (this.gridImageData.description === "") {
        this.gridImageData.description = null
      }
      if (this.gridImageData.buttonText === "") {
        this.gridImageData.buttonText = null
      }
      if (this.gridImageData.buttonLink === "") {
        this.gridImageData.buttonLink = null
      }
      console.log("Update grid image data:", this.gridImageData);
      this.$emit("updatedData", this.gridImageData);
      this.dialog = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit {
    margin: 0 10px 20px 0;
    width: 100%;
  }
</style>
