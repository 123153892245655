<template>
  <v-dialog class="accordion-widget" v-model="dialog" width="580" lazy>
    <slot slot="activator"></slot>
    <v-form
      enctype="multipart/form-data"
      ref="accordionForm"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title class="widget__heading"> Advertising </v-card-title>

        <v-card-text>
          <p v-if="error" class="error-message">{{ error }}</p>
          <p v-else-if="loading">Loading...</p>
          <v-select
            v-else
            id="ads"
            :items="ads"
            item-value="id"
            item-text="attributes.advertTitle"
            v-model="updatedData.id"
            label="Advert"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" outline flat @click="validate()"> Post </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "AdvertisingWidget",

  components: {},

  props: {
    widgetData: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    error: "",
    advertsAPI: process.env.VUE_APP_ADVERTISINGAPI,
    rules: {
      required: (value) => !!value || "Required.",
    },
    updatedData: {
      id: null,
      title: "",
    },
    ads: [],
  }),

  created() {
    this.fetchAdverts();
  },

  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData));
    }
  },

  methods: {
    updateData() {
      // Modify object so title can be shown in the widget preview
      this.updatedData.title = this.ads
        .filter((item) => item.id === this.updatedData.id)
        .map((item) => {
          return item.attributes.advertTitle;
        })[0];

      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
    },

    fetchAdverts() {
      this.loading = true;
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.advertsAPI}/adverts`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log("Adverts fetched", response);
            this.ads = response.data.data;
          })
          .catch((e) => {
            this.error = "An Error has occurred";
            console.error("Problem fetching ads", e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },

    validate() {
      this.errorMessage = "";
      if (this.$refs.accordionForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
}

.list-complete-leave-active {
  position: absolute;
  width: 100%;
}

.widget {
  &__description {
    margin: 0 0 20px;
    color: #7b6b80;
    font-size: 14px;
  }
}

.error-message {
  margin: 10px 0;
  color: #d61244;
  font-size: 14px;
}
</style>
