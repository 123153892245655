<template>
    <v-card
        v-if="slotData && slotData.attributes"
        class="accordion__content"
    >
        <div class="accordion__top has-margin">
            <h2 class="accordion__content-title">
                {{ slotData.attributes.slotTitle }}
            </h2>
            <div class="accordion__content-options">
                <slot-modal
                    :slotID="slotData.id"
                    :slotTitle="slotData.attributes.slotTitle"
                    :adsData="adverts"
                    :selected="slotData.attributes.adverts"
                    :sortable="sortable"
                    @slotEdited="$emit('fetchSlots')"
                    v-if="adverts && (slotData.attributes.adverts && slotData.attributes.adverts.length)"
                >
                    <v-btn
                        class="accordion__button"
                        accent
                        type="button"
                        color="accent"
                        depressed
                    >
                        Manage Slot
                        <v-icon class="accordion__icon">add</v-icon>
                    </v-btn>
                </slot-modal>
                <!-- For dev use only, if turned into a user feature it may need additional ux improvements -->
                <!-- <confirmation-modal
                    v-if="isDevUser"
                    @confirmed="deleteSlot(slotData.id)"
                    class="accordion__modal"
                >
                    <v-icon class="accordion__delete">delete</v-icon>
                </confirmation-modal> -->
            </div>
        </div>
        <slot-modal
            :slotID="slotData.id"
            :slotTitle="slotData.attributes.slotTitle"
            :adsData="adverts"
            :selected="slotData.attributes.adverts"
            :sortable="sortable"
            @slotEdited="$emit('fetchSlots')"
            v-if="adverts && (slotData.attributes.adverts && !slotData.attributes.adverts.length)"
        >
            <v-btn
                class="accordion__button"
                accent
                type="button"
                color="accent"
                depressed
            >
                Manage Slot
                <v-icon class="accordion__icon">add</v-icon>
            </v-btn>
        </slot-modal>

        <div
            v-if="slotData.relationships &&
            slotData.relationships.adverts &&
            slotData.relationships.adverts.length
            "
            class="ad-item__ads"
        >
            <!-- {{ slotData.relationships.adverts }} -->
            <template v-for="ad in slotData.relationships.adverts">
                <div
                    class="ad-item__ad"
                    :class="[
            { 'is-disabled': checkDateValidity(ad) },
            { 'is-incomplete': checkDataValidity(ad) },
        ]"
                    :key="ad.id"
                >
                    <template v-if="ad.attributes">
                        <div class="ad-item__ad-top mb-14">
                            <h6 class="ad-item__ad-title">
                                {{
            ad.attributes.advertTitle
                ? ad.attributes.advertTitle.length > 35
                    ? ad.attributes.advertTitle.substring(0, 35) +
                    "..."
                    : ad.attributes.advertTitle
                : "No URL defined"
        }}
                            </h6>
                        </div>
                        <p class="ad-item__ad-date">
                            Start:
                            <span v-if="ad.attributes.advertData.publicationData.startAt
            ">
                                {{
            ad.attributes.advertData.publicationData.startAt
            | moment("DD/MM/YYYY (HH:mm:ss)")
        }}
                            </span>
                            <span v-else>-</span>
                        </p>
                        <p class="ad-item__ad-date">
                            End:
                            <span v-if="ad.attributes.advertData.publicationData.finishAt
            ">
                                {{
            ad.attributes.advertData.publicationData.finishAt
                                | moment("DD/MM/YYYY (HH:mm:ss)")
                                }}
                            </span>
                            <span v-else>-</span>
                        </p>
                        <p class="ad-item__child-friendly">
                            Child Friendly: {{ ad.attributes.childFriendly ? 'Yes' : 'No' }}
                        </p>
                    </template>
                    <p
                        class="mb-0"
                        v-else
                    >
                        Error: this advert might have been deleted
                    </p>
                </div>
            </template>
        </div>
    </v-card>
</template>

<script>
import SlotModal from "./SlotModal";
import ConfirmationModal from "@/components/Library/GlobalModals/ConfirmationModal";
import axios from 'axios'
import { devUsers } from '@/plugins/permissions'

export default {
    name: 'Advertising2Slot',

    components: {
        SlotModal,
        ConfirmationModal
    },

    props: {
        slotData: {
            type: Object,
            required: false,
        },
        adverts: {
            type: Array,
            required: false,
        },
        sortable: {
            type: Boolean,
            default: false,
        }
    },

    data: () => ({
        isDevUser: null,
        advertsAPI: process.env.VUE_APP_ADVERTISINGAPI,
    }),

    created() {
        if (devUsers && devUsers.includes(this.$store.state.user.email)) {
            this.isDevUser = true;
        }
    },

    methods: {
        checkDateValidity(ad) {
            if (
                ad.attributes &&
                ad.attributes.advertData &&
                ad.attributes.advertData.publicationData
            ) {
                if (!ad.attributes.advertData.publicationData.startAt) {
                    return true;
                } else if (
                    this.$moment(
                        ad.attributes.advertData.publicationData.finishAt
                    ).isBefore(this.$moment())
                ) {
                    return true;
                }
            } else {
                return true;
            }
        },

        checkDataValidity(ad) {
            if (ad.attributes && ad.attributes.advertType == "simple") {
                if (
                    ad.attributes.advertData.url &&
                    ad.attributes.advertData.images &&
                    ad.attributes.advertData.images.mobile &&
                    ad.attributes.advertData.images.mobile.key
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },

        deleteSlot(id) {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "DELETE",
                    url: `${this.advertsAPI}/slots/${id}`,
                    headers: { Authorization: jwt },
                })
                    .then(() => {
                        this.fetchAdverts();
                    })
                    .catch((e) => {
                        window.alert("An Error has occurred");
                        console.error("Problem deleting ads", e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        }
    }
}
</script>

<style
    scoped
    lang='scss'
>
.accordion {
    &__item {
        background-color: transparent !important;
        border-top: none !important;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        padding-top: 24px;
        padding-bottom: 24px;
        font-weight: 500;
        font-size: 14px;
        color: #516173;
    }

    &__content {
        // background: transparent;
        padding: 24px;
        margin-bottom: 8px;
        border-radius: 4px;
        border: 1px solid #dfe3e9;
    }

    &__content-title {
        font-weight: 500;
        font-size: 14px;
        color: #516173;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    &__content-options {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    &__button {
        margin: 0;
        border-radius: 4px;
        min-width: 200px;
        height: 40px;

        :deep(.v-btn__content) {
            justify-content: space-between;
        }
    }

    &__icon {
        font-weight: bold;
    }

    &__delete {
        cursor: pointer;
    }
}

.ad {
    background: #fafafa;
    border: 1px solid #dfe3e9;
    display: inline-block;
    padding: 14px;
    min-width: 174px;
    max-width: 300px;
    border-radius: 4px;
    margin-right: 8px;

    &__top {
        display: flex;
        align-items: center;
    }

    &__title {
        color: #7b6b80;
        font-size: 14px;
        font-weight: 500;
        margin-right: auto;
    }

    &__edit {
        font-size: 14px;
        margin-left: 6px;
        cursor: pointer;
    }

    &__date {
        margin-bottom: 6px;
        color: #7b6b80;
        font-size: 10px;
        font-weight: 500;
    }
}

.ad-item {
    &__ads {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    &__ad {
        background: #fafafa;
        border: 1px solid #dfe3e9;
        display: inline-block;
        padding: 14px;
        min-width: 174px;
        max-width: 400px;
        border-radius: 4px;
    }

    &__ad-top {
        display: flex;
        align-items: center;

        &--image {
            margin-bottom: 8px !important;
        }
    }

    &__ad-title {
        color: #7b6b80;
        font-size: 14px;
        font-weight: 500;
        margin-right: auto;
    }

    &__ad-edit {
        font-size: 14px;
        margin-left: 6px;
        cursor: pointer;
    }

    &__ad-date,
    &__child-friendly {
        margin-bottom: 6px;
        color: #7b6b80;
        font-size: 10px;
        font-weight: 500;

        &--image {
            margin-bottom: 0;
            text-align: center;
        }
    }
}

.is-disabled {
    opacity: 0.5;
}

.is-incomplete {
    border-color: #ff5252;
}

.has-margin {
    margin-bottom: 20px;
}
</style>
