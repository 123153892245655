<template>
  <section class="player main-body" v-if="dataReceived">
    <v-layout
      class="new-page-alert"
      row
      v-if="defaultSettings.default.playerManagement != 'V3'"
    >
      <v-flex mr-5>
        <v-btn
          class="accent"
          @click="
            editPlayerV3($route.params.playerID, $route.params.playerType)
          "
          >Visit Profile V3</v-btn
        >
      </v-flex>
      <v-flex mt-4>
        <p><i>Player Profile V3 requires content population.</i></p>
      </v-flex>
    </v-layout>

    <h3 class="subheading">Publish Settings</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="published" title="published"
            >Published</label
          >

          <v-select
            id="published"
            item-text="name"
            item-value="id"
            :items="[
              { name: 'Yes, publish the player', id: 1 },
              { name: 'No, do not display the player', id: 0 },
            ]"
            v-model="updatedData.published"
            solo
            flat
            :disabled="updatedData.playerSlug ? false : true"
            @change="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >After you have finished editing/publishing all your players
              please <b>trigger a full site build</b></i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label
            class="label"
            for="enable-profile-page"
            title="enableProfilePage"
            >Enable Profile Page (Web)</label
          >

          <v-select
            id="enable-profile-page"
            item-text="name"
            item-value="id"
            :items="[
              { name: 'Yes, enable the web profile page', id: true },
              { name: 'No, hide the web profile page', id: false },
            ]"
            v-model="updatedData.enableProfilePage"
            solo
            flat
            @change="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Players can only be published on web if a slug is present. The
              Profile page will only display if selected and the player is
              published</i
            >
          </p>
        </v-flex>
      </v-layout>
    </section>

    <template v-if="!isCustom">
      <h3 class="subheading">Opta data</h3>

      <section class="section-container">
        <div class="player__panel">
          <v-layout align-center class="player__panel-details">
            <div class="flex md5 mr1">
              <table class="opta__table">
                <tr>
                  <th class="opta__table-heading">First name</th>
                  <td>{{ this.updatedData.firstName }}</td>
                </tr>

                <tr>
                  <th class="opta__table-heading">Middle name</th>
                  <td>{{ this.updatedData.middleName }}</td>
                </tr>

                <tr>
                  <th class="opta__table-heading">Surname</th>
                  <td>{{ this.updatedData.surname }}</td>
                </tr>

                <tr>
                  <th class="opta__table-heading">Known name</th>
                  <td>{{ this.updatedData.knownName }}</td>
                </tr>

                <tr>
                  <th class="opta__table-heading">Shirt number</th>
                  <td>{{ this.updatedData.shirtNumber }}</td>
                </tr>

                <tr>
                  <th class="opta__table-heading">Position</th>
                  <td>{{ this.updatedData.position }}</td>
                </tr>

                <tr>
                  <th class="opta__table-heading">Real Position</th>
                  <td>{{ this.updatedData.realPosition }}</td>
                </tr>

                <tr>
                  <th class="opta__table-heading">Join Date</th>
                  <td>{{ this.updatedData.joinDate }}</td>
                </tr>

                <tr>
                  <th class="opta__table-heading">Loan</th>
                  <td>{{ this.updatedData.loan }}</td>
                </tr>

                <tr>
                  <th class="opta__table-heading">On Loan from</th>
                  <td>{{ this.updatedData.onLoanFrom }}</td>
                </tr>

                <tr>
                  <th class="opta__table-heading">DOB</th>
                  <td>{{ this.updatedData.dob }}</td>
                </tr>

                <tr>
                  <th class="opta__table-heading">Country</th>
                  <td>{{ this.updatedData.country }}</td>
                </tr>

                <tr>
                  <th class="opta__table-heading">Height</th>
                  <td>{{ this.updatedData.height }}</td>
                </tr>

                <tr>
                  <th class="opta__table-heading">Weight</th>
                  <td>{{ this.updatedData.weight }}</td>
                </tr>
              </table>
            </div>
            <div class="player__panel-opta flex md4 mr1">
              <img
                src="@/assets/partner-assets/opta.png"
                width="120px"
                alt="Opta"
              />
            </div>
            <!-- <div class="flex sm12 md3">
            <v-checkbox v-model="updatedData.optaShowStats" label="Stats enabled" class="player__panel-opta-cb" @change="updateData()"></v-checkbox>
          </div> -->
          </v-layout>
        </div>
      </section>
    </template>

    <h3 class="subheading">Player Details</h3>
    <section class="section-container">
      <v-layout>
        <p class="flex md4">Team: {{ teamID }} | Is Custom: {{ isCustom }}</p>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="firstName_Custom" title="firstName_Custom"
            >First name</label
          >
          <v-text-field
            id="firstName_Custom"
            v-model="updatedData.firstName_Custom"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="middleName_Custom" title="middleName_Custom"
            >Middle Name</label
          >
          <v-text-field
            id="middleName_Custom"
            v-model="updatedData.middleName_Custom"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Not in use</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="surname_Custom" title="surname_Custom"
            >Surname</label
          >
          <v-text-field
            id="surname_Custom"
            v-model="updatedData.surname_Custom"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="knownName_Custom" title="knownName_Custom"
            >Known Name</label
          >
          <v-text-field
            id="knownName_Custom"
            v-model="updatedData.knownName_Custom"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Caution: Known Name should be used only for players who use a
              single name (e.g. Pele). In most cases do not use this.</i
            >
          </p>
          <p><i>Known Name replaces First Name and Surname.</i></p>
        </v-flex>
      </v-layout>

      <v-layout row class="section-divider">
        <v-flex mr-5 class="half">
          <label class="label" for="playerSlug" title="playerSlug"
            >Player Slug</label
          >
          <v-text-field
            id="playerSlug"
            v-model="updatedData.playerSlug"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>This is for the url</p>
          <p>
            <i
              >Please do not use accented characters for slugs as your page may
              not build or share correctly</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="position_Custom" title="position_Custom"
            >Position</label
          >
          <v-select
            id="position"
            :items="positions"
            v-model="updatedData.position_Custom"
            solo
            flat
            @change="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="!modules.default.LFCExclusive">
        <v-flex mr-5 class="half">
          <label
            class="label"
            for="realPosition_Custom"
            title="realPosition_Custom"
            >Real Position</label
          >
          <v-text-field
            id="realPosition_Custom"
            v-model="updatedData.realPosition_Custom"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="!modules.default.LFCExclusive">
        <v-flex mr-5 class="half">
          <label
            class="label"
            for="squadCaptain"
            title="playerProfileData.squadCaptain"
            >Squad Captain</label
          >

          <v-select
            id="squadCaptain"
            item-text="name"
            item-value="id"
            :items="[
              { name: 'This is the captain', id: true },
              { name: 'Not the captain', id: false },
            ]"
            v-model="updatedData.playerProfileData.squadCaptain"
            solo
            flat
            @change="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This can apply a label to the player in some locations. Turning
              this on does not remove it from other players.</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label
            class="label"
            for="shirtNumber_Custom"
            title="shirtNumber_Custom"
            >Shirt Number</label
          >
          <v-text-field
            id="shirtNumber_Custom"
            v-model="updatedData.shirtNumber_Custom"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="!modules.default.LFCExclusive">
        <v-flex mr-5 class="half">
          <label
            class="label"
            for="clubIdNumber"
            title="playerProfileData.clubIdNumber"
            >Club ID Number</label
          >
          <v-text-field
            id="clubIdNumber"
            v-model="updatedData.playerProfileData.clubIdNumber"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This is an optional field in case your club assigns a club ID, or
              heritage number to a player</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout
        row
        class="section-divider"
        v-if="!modules.default.LFCExclusive"
      >
        <v-flex mr-5 class="half">
          <label class="label" for="debut" title="playerProfileData.debut"
            >Debut</label
          >
          <v-text-field
            id="debut"
            v-model="updatedData.playerProfileData.debut"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="!modules.default.LFCExclusive">
        <v-flex mr-5 class="half">
          <label class="label" for="dob_Custom" title="dob_Custom"
            >Date of Birth</label
          >
          <v-text-field
            id="dob_Custom"
            v-model="updatedData.dob_Custom"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="!modules.default.LFCExclusive">
        <v-flex mr-5 class="half">
          <label class="label" for="country_Custom" title="country_Custom"
            >Country</label
          >
          <v-text-field
            id="country_Custom"
            v-model="updatedData.country_Custom"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="!modules.default.LFCExclusive">
        <v-flex mr-5 class="half">
          <label
            class="label"
            for="customHeight"
            title="playerProfileData.customHeight"
            >Height</label
          >
          <v-text-field
            id="customHeight"
            v-model="updatedData.playerProfileData.customHeight"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="!modules.default.LFCExclusive">
        <v-flex mr-5 class="half">
          <label
            class="label"
            for="customWeight"
            title="playerProfileData.customWeight"
            >Weight</label
          >
          <v-text-field
            id="customWeight"
            v-model="updatedData.playerProfileData.customWeight"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="!modules.default.LFCExclusive">
        <v-flex mr-5 class="half">
          <label class="label" for="joinDate_Custom" title="joinDate_Custom"
            >Join Date</label
          >
          <v-text-field
            id="joinDate_Custom"
            v-model="updatedData.joinDate_Custom"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading" v-if="!modules.default.LFCExclusive">
      Loaned Player
    </h3>
    <section class="section-container" v-if="!modules.default.LFCExclusive">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="loan_Custom" title="loan_Custom"
            >Loaned in</label
          >
          <v-select
            id="loan_Custom"
            item-text="name"
            item-value="id"
            :items="[
              { name: 'Yes, this player is loaned in', id: true },
              { name: 'No, this player is not loaned in', id: false },
            ]"
            v-model="updatedData.loan_Custom"
            solo
            flat
            @change="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <template v-if="updatedData.loan_Custom">
        <v-layout row>
          <v-flex mr-5 class="half">
            <label
              class="label"
              for="onLoanFrom_Custom"
              title="onLoanFrom_Custom"
              >On Loan From</label
            >
            <v-text-field
              id="onLoanFrom_Custom"
              v-model="updatedData.onLoanFrom_Custom"
              solo
              flat
              @change="updateData()"
            ></v-text-field>
          </v-flex>
          <v-flex mt-4 class="half">
            <p><i>-</i></p>
          </v-flex>
        </v-layout>
      </template>
    </section>

    <h3 class="subheading">Media</h3>
    <section class="section-container">
      <v-layout row v-if="!modules.default.LFCExclusive">
        <v-flex mr-5 class="half">
          <label
            class="label"
            for="advancedHeaderStyle"
            title="playerProfileData.advancedHeaderStyle"
            >Advanced Header Style</label
          >

          <v-select
            id="advancedHeaderStyle"
            item-text="name"
            item-value="id"
            :items="[
              { name: 'Yes, use image led player profiles', id: true },
              { name: 'No, use the headshot', id: false },
            ]"
            v-model="updatedData.playerProfileData.advancedHeaderStyle"
            solo
            flat
            @change="updateData()"
          >
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >This turns on the advanced image led Player Profiles. If turned
              off we will use the headshot image.</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout row v-if="!modules.default.LFCExclusive">
        <v-flex mr-2>
          <ImageUploader
            :title="'Web Profile Hero Image'"
            :widthOptionEnabled="false"
            :mediaLibraryID="updatedData.playerProfileData.profileImage"
            @update="updateImage($event, 'profileImage')"
            :desc="'<p>For use in the player profile header on web.</p><p>Recommended size: width: 1920px | height: 1080px</p>'"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-2>
          <ImageUploader
            mb-3
            :title="'App Profile Hero Image'"
            :widthOptionEnabled="false"
            :mediaLibraryID="updatedData.playerProfileData.appProfileImage"
            @update="updateImage($event, 'appProfileImage')"
            :desc="'<p>For use only within the player profile on the app.</p><p>Recommended size: width: 600px | height: 900px</p>'"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-2>
          <ImageUploader
            mb-3
            :title="'Squad Image'"
            :widthOptionEnabled="false"
            :mediaLibraryID="updatedData.playerProfileData.squadImage"
            @update="updateImage($event, 'squadImage')"
            :desc="'<p>For display on the squads page</p><p>Recommended size: width: 720px | height: 768px</p>'"
          />
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-2>
          <ImageUploader
            mb-3
            :title="'Player Headshot'"
            :widthOptionEnabled="false"
            :mediaLibraryID="updatedData.playerProfileData.playerHeadshot"
            @update="updateImage($event, 'playerHeadshot')"
            :desc="'<p>For use around the site</p><p>Recommended size: width: 400px | height: 400px</p><p>*Important* Centre the subjects head within the image</p>'"
          />
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading" v-if="!modules.default.LFCExclusive">Gallery</h3>
    <section class="section-container" v-if="!modules.default.LFCExclusive">
      <v-layout row>
        <v-flex mr-5 class="half">
          <ImageGalleryWidget @imageArrayUploaded="updateGallery($event)">
            <v-btn class="player-gallery" color="accent" type="button">
              <template v-if="updatedData.playerProfileData.gallery">
                Manage Player Gallery ({{
                  updatedData.playerProfileData.gallery.length
                }}
                items)
              </template>
              <template v-else> Add Player Gallery </template>
            </v-btn>
          </ImageGalleryWidget>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading" v-if="!modules.default.LFCExclusive">
      Social Media
    </h3>
    <section class="section-container" v-if="!modules.default.LFCExclusive">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="twitter" title="playerProfileData.twitter"
            >X</label
          >
          <v-text-field
            id="twitter"
            v-model="updatedData.playerProfileData.twitter"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>Please include the full url e.g. https://twitter.com/FIFAcom</i>
          </p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="twitter" title="playerProfileData.threads"
            >Threads</label
          >
          <v-text-field
            id="threads"
            v-model="updatedData.playerProfileData.threads"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>Please include the full url e.g. https://threads.com/FIFAcom</i>
          </p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label
            class="label"
            for="instagram"
            title="playerProfileData.instagram"
            >Instagram</label
          >
          <v-text-field
            id="instagram"
            v-model="updatedData.playerProfileData.instagram"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i
              >Please include the full url e.g. https://instagram.com/FIFAcom</i
            >
          </p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="tiktok" title="playerProfileData.tiktok"
            >TikTok</label
          >
          <v-text-field
            id="tiktok"
            v-model="updatedData.playerProfileData.tiktok"
            solo
            flat
            @change="updateData()"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>
            <i>Please include the full url e.g. https://tiktok.com/FIFAcom</i>
          </p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading" v-if="!modules.default.LFCExclusive">Info</h3>
    <section class="section-container" v-if="!modules.default.LFCExclusive">
      <v-layout row>
        <v-flex mb-4>
          <label
            class="label"
            for="biography"
            title="playerProfileData.biography"
            >Biography</label
          >

          <tinymce-editor
            id="biography"
            v-model="updatedData.playerProfileData.biography"
            @onChange="updateData()"
            api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
            :init="{
              height: '500px',
              menubar: false,
              relative_urls: false,
              plugins: 'link paste wordcount hr textpattern code help',
              toolbar:
                'undo redo | styleselect | bold italic | \
              link blockquote | \
              removeformat | \ code | \ help',
              help_tabs: [
                {
                  name: 'embedding', // new tab called custom1
                  title: 'Embedding',
                  items: [
                    {
                      type: 'htmlpanel',
                      html: `<p>The code section is enabled to allow for custom html entry and embedding of some content.</p>
                    <p>Please take note that not all embeds will work. Generally only iframe ones which do not require a script tag loading will work in this way</p>`,
                    },
                  ],
                },
              ],
              resize: false,
              textpattern_patterns: [
                {
                  start: '//button',
                  replacement: '<a class=\'button \'>Button here</a>',
                },
              ],
              link_class_list: [
                { title: 'None', value: '' },
                {
                  title: 'Primary on Dark',
                  value: 'button button--primary-dark',
                },
                {
                  title: 'Primary on Light',
                  value: 'button button--primary-light',
                },
                { title: 'Ghost on Dark', value: 'button button--ghost-dark' },
                {
                  title: 'Ghost on Light',
                  value: 'button button--ghost-light',
                },
                { title: 'Label on Dark', value: 'button button--label-dark' },
                {
                  title: 'Label on Light',
                  value: 'button button--label-light',
                },
              ],
              style_formats: [
                {
                  title: 'Headers',
                  items: [
                    //{title: 'Header 1', format: 'h1'},
                    { title: 'Header 2', format: 'h2' },
                    { title: 'Header 3', format: 'h3' },
                    { title: 'Header 4', format: 'h4' },
                    { title: 'Header 5', format: 'h5' },
                    { title: 'Header 6', format: 'h6' },
                  ],
                },
                {
                  title: 'Inline',
                  items: [
                    { title: 'Bold', icon: 'bold', format: 'bold' },
                    { title: 'Italic', icon: 'italic', format: 'italic' },
                    {
                      title: 'Underline',
                      icon: 'underline',
                      format: 'underline',
                    },
                    //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                    //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                    //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                    // {title: 'Code', icon: 'code', format: 'code'}
                  ],
                },
                {
                  title: 'Blocks',
                  items: [
                    { title: 'Paragraph', format: 'p' },
                    { title: 'Blockquote', format: 'blockquote' },
                    //{title: 'Div', format: 'div'},
                    //{title: 'Pre', format: 'pre'}
                  ],
                },
                {
                  title: 'Alignment',
                  items: [
                    { title: 'Left', icon: 'alignleft', format: 'alignleft' },
                    {
                      title: 'Center',
                      icon: 'aligncenter',
                      format: 'aligncenter',
                    },
                    {
                      title: 'Right',
                      icon: 'alignright',
                      format: 'alignright',
                    },
                    //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
                  ],
                },
                { title: 'Small', selector: 'p', classes: 'small' },
              ],
              style_formats_merge: false,
              block_formats:
                'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote',
            }"
          ></tinymce-editor>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="quote" title="playerProfileData.quote"
            >Quote</label
          >
          <v-textarea
            id="quote"
            v-model="updatedData.playerProfileData.quote"
            solo
            flat
            @change="updateData()"
          ></v-textarea>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label
            class="label"
            for="traits-1"
            title="playerProfileData.traits[0]"
            >Trait 1</label
          >
          <v-text-field
            id="traits-1"
            v-model="updatedData.playerProfileData.traits[0]"
            solo
            flat
            @change="updateTrait(0)"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label
            class="label"
            for="traits-2"
            title="playerProfileData.traits[1]"
            >Trait 2</label
          >
          <v-text-field
            id="traits-2"
            v-model="updatedData.playerProfileData.traits[1]"
            solo
            flat
            @change="updateTrait(1)"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label
            class="label"
            for="traits-3"
            title="playerProfileData.traits[1]"
            >Trait 3</label
          >
          <v-text-field
            id="traits-3"
            v-model="updatedData.playerProfileData.traits[2]"
            solo
            flat
            @change="updateTrait(2)"
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>-</i></p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading" v-if="!modules.default.LFCExclusive">Facts</h3>
    <section class="section-container" v-if="!modules.default.LFCExclusive">
      <v-layout>
        <div class="flex sm12 md5">
          <label class="label" for="customFactHeading"
            >Facts Section Heading</label
          >
          <v-text-field
            id="customFactHeading"
            type="text"
            placeholder="Section Heading"
            solo
            flat
            v-model="updatedData.playerProfileData.facts.factHeading"
            @change="updateFactHeading()"
          >
          </v-text-field>
        </div>
      </v-layout>

      <v-layout>
        <div class="flex sm12 md5">
          <label class="label" for="customFactLabel1">Fact label</label>
          <v-text-field
            id="customFactLabel1"
            type="text"
            placeholder="Label"
            solo
            flat
            v-model="updatedData.playerProfileData.facts.fact1.label"
            @change="updateFact('fact1', 'label')"
          >
          </v-text-field>
        </div>

        <div class="flex sm12 md6 offset-md1">
          <label class="label" for="customFactContent1">Fact content</label>
          <v-text-field
            id="customFactContent1"
            type="text"
            placeholder="Content"
            solo
            flat
            v-model="updatedData.playerProfileData.facts.fact1.content"
            @change="updateFact('fact1', 'content')"
          >
          </v-text-field>
        </div>
      </v-layout>

      <v-layout>
        <div class="flex sm12 md5">
          <label class="label" for="customFactLabel2">Fact label</label>
          <v-text-field
            id="customFactLabel2"
            type="text"
            placeholder="Label"
            solo
            flat
            v-model="updatedData.playerProfileData.facts.fact2.label"
            @change="updateFact('fact2', 'label')"
          >
          </v-text-field>
        </div>

        <div class="flex sm12 md6 offset-md1">
          <label class="label" for="customFactContent2">Fact content</label>
          <v-text-field
            id="customFactContent2"
            type="text"
            placeholder="Content"
            solo
            flat
            v-model="updatedData.playerProfileData.facts.fact2.content"
            @change="updateFact('fact2', 'content')"
          >
          </v-text-field>
        </div>
      </v-layout>

      <v-layout>
        <div class="flex sm12 md5">
          <label class="label" for="customFactLabel3">Fact label</label>
          <v-text-field
            id="customFactLabel3"
            type="text"
            placeholder="Label"
            solo
            flat
            v-model="updatedData.playerProfileData.facts.fact3.label"
            @change="updateFact('fact3', 'label')"
          >
          </v-text-field>
        </div>

        <div class="flex sm12 md6 offset-md1">
          <label class="label" for="customFactContent3">Fact content</label>
          <v-text-field
            id="customFactContent3"
            type="text"
            placeholder="Content"
            solo
            flat
            v-model="updatedData.playerProfileData.facts.fact3.content"
            @change="updateFact('fact3', 'content')"
          >
          </v-text-field>
        </div>
      </v-layout>

      <v-layout>
        <div class="flex sm12 md5">
          <label class="label" for="customFactLabel4">Fact label</label>
          <v-text-field
            id="customFactLabel4"
            type="text"
            placeholder="Label"
            solo
            flat
            v-model="updatedData.playerProfileData.facts.fact4.label"
            @change="updateFact('fact4', 'label')"
          >
          </v-text-field>
        </div>

        <div class="flex sm12 md6 offset-md1">
          <label class="label" for="customFactContent4">Fact content</label>
          <v-text-field
            id="customFactContent4"
            type="text"
            placeholder="Content"
            solo
            flat
            v-model="updatedData.playerProfileData.facts.fact4.content"
            @change="updateFact('fact4', 'content')"
          >
          </v-text-field>
        </div>
      </v-layout>

      <v-layout>
        <div class="flex sm12 md5">
          <label class="label" for="customFactLabel5">Fact label</label>
          <v-text-field
            id="customFactLabel5"
            type="text"
            placeholder="Label"
            solo
            flat
            v-model="updatedData.playerProfileData.facts.fact5.label"
            @change="updateFact('fact5', 'label')"
          >
          </v-text-field>
        </div>

        <div class="flex sm12 md6 offset-md1">
          <label class="label" for="customFactContent5">Fact content</label>
          <v-text-field
            id="customFactContent5"
            type="text"
            placeholder="Content"
            solo
            flat
            v-model="updatedData.playerProfileData.facts.fact5.content"
            @change="updateFact('fact5', 'content')"
          >
          </v-text-field>
        </div>
      </v-layout>
    </section>

    <h3 class="subheading" v-if="!modules.default.LFCExclusive">Retail</h3>
    <section class="section-container" v-if="!modules.default.LFCExclusive">
      <v-layout align-center class="player__panel-details">
        <div class="flex sm12 md5">
          <label class="label" for="primaryRetailLink"
            >Retail Link (primary)</label
          >
          <v-text-field
            id="primaryRetailLink"
            placeholder="https://"
            solo
            flat
            v-model="updatedData.playerProfileData.primaryRetailLink"
            @change="updateData()"
          ></v-text-field>
        </div>
        <div class="flex sm12 md2 offset-md1">
          <label class="label" for="shirtType">Display Product</label>
          <v-select
            id="shirtType"
            :items="['', 'Home', 'Away', 'Goalkeeper', 'Custom']"
            v-model="updatedData.playerProfileData.shirtType"
            solo
            flat
            @change="updateRetailImage()"
          >
          </v-select>
        </div>
      </v-layout>
      <v-layout row v-if="updatedData.playerProfileData.shirtType === 'Custom'">
        <v-flex mr-2>
          <ImageUploader
            mb-3
            :title="'Custom Retail Image'"
            :widthOptionEnabled="false"
            :mediaLibraryID="
              updatedData.playerProfileData.customShirtMediaLibraryID
            "
            @update="updateImage($event, 'customShirt')"
            :desc="'<p>Recommended size: width: 400px | height: 400px</p><p>.png with transparency</p>'"
          />
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading" v-if="!modules.default.LFCExclusive">
      Sponsorships
    </h3>
    <section class="section-container" v-if="!modules.default.LFCExclusive">
      <v-layout align-center class="player__panel-details">
        <div class="flex sm12 md5">
          <ImageUploader
            mb-3
            :title="'Sponsor Image'"
            :widthOptionEnabled="false"
            :mediaLibraryID="
              updatedData.playerProfileData.playerSponsorData.sponsorLogo
            "
            @update="updateImage($event, 'sponsorLogo')"
          />
        </div>
        <div class="player__panel--column">
          <div class="flex sm12 md6 offset-md1">
            <label class="label" for="sponsorName">Sponsor Name</label>
            <v-text-field
              id="sponsorName"
              placeholder="Sponsor Name"
              solo
              flat
              v-model="
                updatedData.playerProfileData.playerSponsorData.sponsorName
              "
              @change="updateData()"
            ></v-text-field>
          </div>
          <div class="flex sm12 md6 offset-md1">
            <label class="label" for="sponsorLink">Sponsor Link</label>
            <v-text-field
              id="sponsorLink"
              placeholder="https://"
              solo
              flat
              v-model="
                updatedData.playerProfileData.playerSponsorData.sponsorLink
              "
              @change="updateData()"
            ></v-text-field>
          </div>
        </div>
      </v-layout>

      <v-layout align-center class="player__panel-details">
        <div class="flex sm12 md5">
          <ImageUploader
            mb-3
            :title="'Secondary Sponsor Image'"
            :widthOptionEnabled="false"
            :mediaLibraryID="
              updatedData.playerProfileData.playerSponsorSecondaryData
                .sponsorLogo
            "
            @update="updateImage($event, 'secondarySponsorLogo')"
          />
        </div>
        <div class="player__panel--column">
          <div class="flex sm12 md6 offset-md1">
            <label class="label" for="sponsorName">Sponsor Name</label>
            <v-text-field
              id="sponsorName"
              placeholder="Sponsor Name"
              solo
              flat
              v-model="
                updatedData.playerProfileData.playerSponsorSecondaryData
                  .sponsorName
              "
              @change="updateData()"
            ></v-text-field>
          </div>
          <div class="flex sm12 md6 offset-md1">
            <label class="label" for="sponsorLink">Sponsor Link</label>
            <v-text-field
              id="sponsorLink"
              placeholder="https://"
              solo
              flat
              v-model="
                updatedData.playerProfileData.playerSponsorSecondaryData
                  .sponsorLink
              "
              @change="updateData()"
            ></v-text-field>
          </div>
        </div>
      </v-layout>
    </section>

    <StickyFooter
      v-if="isNewPlayer"
      :errorMessage="errorMessage"
      :status="status"
      :buttonText="'Add New Player'"
      @buttonClicked="addNewPlayer()"
    />
    <StickyFooter
      v-else
      :errorMessage="errorMessage"
      :status="status"
      :buttonText="'Save Player'"
      @buttonClicked="savePlayer()"
    />
  </section>
</template>

<script>
import axios from "axios";
import Editor from "@tinymce/tinymce-vue";
import ImageUploader from "@/components/Library/ImageUploader";
import ImageGalleryWidget from "@/components/Widgets/ImageGalleryWidget";
import Nationalities from "@/components/Library/Data/NationalitiesData";
import SquadPositions from "@/components/Library/Data/SquadPositionsData";
import StickyFooter from "@/components/Library/StickyFooter";

export default {
  name: "SquadManagement",

  components: {
    "tinymce-editor": Editor,
    ImageGalleryWidget,
    ImageUploader,
    StickyFooter,
  },

  props: {
    data: {
      type: Object,
      required: false,
    },
  },

  data: () => {
    return {
      api: `${process.env.VUE_APP_BASEURL}/players`,
      dataReceived: false,
      updatedData: {},
      Nationalities,
      SquadPositions,
      status: "saved",
      errorMessage: "",
      dateModalDob: null,
      dateModalDoj: null,
      readonly: true,
      isCustom: false,
      dataType: null,
      teamID: null,
      seasonID: null,
      isNewPlayer: false,
      positions: [],
      defaultSettings: null,
      modules: null,
    };
  },

  created() {
    this.defaultSettings = require("../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/defaultSettings.js");
    this.modules = require("../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");
  },

  mounted() {
    console.log("dataType", this.dataType);
    if (this.$route.params.playerType === "custom") {
      this.isCustom = true;
    } else {
      this.isCustom = false;
    }

    if (process.env.VUE_APP_SPORT === "football") {
      this.positions = [
        "Goalkeeper",
        "Defender",
        "Midfielder",
        "Forward",
        "Staff",
      ];
    } else if (process.env.VUE_APP_SPORT === "rugby-league") {
      this.positions = [
        "Back Row",
        "Half Back",
        "Hooker",
        "Outside Back",
        "Prop",
        "Utility",
        "Staff",
      ];
    }

    this.teamID = this.$route.params.teamID;
    this.seasonID = this.$route.params.seasonID;

    this.updatedData = {
      published: 0,
      enableProfilePage: true,
      optaShowStats: null,
      playerID: null,
      firstName_Custom: null,
      middleName_Custom: null,
      surname_Custom: null,
      shirtNumber_Custom: null,
      position_Custom: null,
      realPosition: null,
      dob_Custom: null,
      country_Custom: null,
      loan_Custom: null,
      onLoanFrom_Custom: null,
      joinDate_Custom: null,
      voiceBiography: null,
      productIdHome: null,
      productIdAway: null,
      playerSlug: null,
      playerProfileData: {
        hasVideo: false,
        advancedHeaderStyle: false,
        profileImage: null,
        appProfileImage: null,
        squadImage: null,
        playerHeadshot: null,
        traits: [],
        facts: {
          factHeading: null,
          fact1: {
            label: null,
            content: null,
          },
          fact2: {
            label: null,
            content: null,
          },
          fact3: {
            label: null,
            content: null,
          },
          fact4: {
            label: null,
            content: null,
          },
          fact5: {
            label: null,
            content: null,
          },
        },
        instagram: null,
        twitter: null,
        threads: null,
        tiktok: null,
        biography: null,
        quote: null,
        primaryRetailLink: null,
        shirtType: null,
        customShirtImageKey: null,
        customShirtMediaLibraryID: null,
        clubIdNumber: null,
        squadCaptain: null,
        playerSponsorData: {
          sponsorLogo: null,
          sponsorName: null,
          sponsorLink: null,
        },
        playerSponsorSecondaryData: {
          sponsorLogo: null,
          sponsorName: null,
          sponsorLink: null,
        },
        gallery: [],
        debut: null,
      },
    };

    if (this.$route.params.playerID === "createplayer") {
      this.dataLoaded = true;
      this.isNewPlayer = true;
      this.dataReceived = true;
      this.status = "needsSaving";
      this.$store.commit("completeLoading");
    } else {
      this.fetchPlayer();
      this.saveDisabled = true;
    }
  },

  methods: {
    updateGallery(payload) {
      console.log("Update Gallery", payload);
      this.updatedData.playerProfileData.gallery = payload.gallery;
      this.updateData();
    },

    updateFactHeading() {
      if (this.updatedData.playerProfileData.facts.factHeading === "") {
        this.updatedData.playerProfileData.facts.factHeading = null;
      }
    },

    updateFact(index, key) {
      console.log(
        "this.updatedData.playerProfileData.facts[index][key]",
        this.updatedData.playerProfileData.facts
      );
      if (this.updatedData.playerProfileData.facts[index][key] === "") {
        this.updatedData.playerProfileData.facts[index][key] = null;
      }

      this.$emit("update", this.updatedData);
      this.status = "needsSaving";
      console.log(this.updatedData);
    },

    updateTrait(index) {
      if (this.updatedData.playerProfileData.traits[index] === "") {
        this.updatedData.playerProfileData.traits.splice(index, 1);
      }

      this.$emit("update", this.updatedData);
      this.status = "needsSaving";
      console.log(this.updatedData);
    },

    updateData() {
      this.$emit("update", this.updatedData);
      this.status = "needsSaving";
      console.log(this.updatedData);
    },

    updateRetailImage() {
      if (this.updatedData.playerProfileData.shirtType != "Custom") {
        this.updatedData.playerProfileData.customShirtImageKey = null;
        this.updatedData.playerProfileData.customShirtMediaLibraryID = null;
      }
      this.updateData();
    },

    editImage(payload) {
      console.log("Edit image: ", payload);
      this.updatedData.leadingImageID = payload;

      this.updateData();
    },

    fetchPlayer() {
      this.$store.commit("startLoading");

      let fetchEndpoint = `${this.api}/opta/getsingle?seasonID=${this.seasonID}&teamID=${this.teamID}&playerID=${this.$route.params.playerID}`;

      if (this.isCustom) {
        fetchEndpoint = `${this.api}/custom/getsingle?seasonID=${this.seasonID}&teamID=${this.teamID}&playerID=${this.$route.params.playerID}`;
      }

      console.log("fetchEndpoint", fetchEndpoint);

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(`${fetchEndpoint}`, { headers: { Authorization: jwt } })
          .then((response) => {
            console.log("Fetch player response 1: ", response);

            const tempData = response.data.body;

            if (!tempData.published) {
              tempData.published = 0;
            }

            const mergedPlayerProfileData = {
              hasVideo: false,
              advancedHeaderStyle: false,
              profileImage: null,
              squadImage: null,
              playerHeadshot: null,
              appProfileImage: null,
              traits: [],
              facts: {
                fact1: {
                  label: null,
                  content: null,
                },
                fact2: {
                  label: null,
                  content: null,
                },
                fact3: {
                  label: null,
                  content: null,
                },
                fact4: {
                  label: null,
                  content: null,
                },
                fact5: {
                  label: null,
                  content: null,
                },
              },
              primaryRetailLink: null,
              shirtType: null,
              customShirtImageKey: null,
              customShirtMediaLibraryID: null,
              clubIdNumber: null,
              squadCaptain: null,
              instagram: null,
              twitter: null,
              threads: null,
              tiktok: null,
              biography: null,
              playerSponsorData: {
                sponsorLogo: null,
                sponsorLink: null,
                sponsorName: null,
              },
              playerSponsorSecondaryData: {
                sponsorLogo: null,
                sponsorLink: null,
                sponsorName: null,
              },
              debut: null,
              ...tempData.playerProfileData,
            };

            if (tempData.playerProfileData) {
              if (tempData.playerProfileData.facts) {
                mergedPlayerProfileData.facts = {
                  ...tempData.playerProfileData.facts,
                };
              }
            }

            console.log("mergedPlayerProfileData: ", mergedPlayerProfileData);
            console.log(
              "playerProfileData not yet merged: ",
              tempData.playerProfileData
            );

            tempData.playerProfileData = mergedPlayerProfileData;

            console.log(
              "playerProfileData merged: ",
              tempData.playerProfileData
            );

            this.updatedData = tempData;

            this.dataReceived = true;

            this.$store.commit("completeLoading");
          })
          .catch((e) => {
            console.error("Problem retrieving player", e);
            this.$store.commit("completeLoading");
          });
      });
    },

    addNewPlayer() {
      this.status = "saving";
      this.$store.commit("startLoading");

      const saveEndpoint = `${this.api}/custom/add?seasonID=${this.seasonID}&teamID=${this.teamID}`;

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${saveEndpoint}`,
          headers: { Authorization: jwt },
          data: {
            published: this.updatedData.published,
            enableProfilePage: this.updatedData.enableProfilePage,
            firstName_Custom:
              this.updatedData.firstName_Custom != ""
                ? this.updatedData.firstName_Custom
                : null,
            knownName_Custom:
              this.updatedData.knownName_Custom != ""
                ? this.updatedData.knownName_Custom
                : null,
            middleName_Custom:
              this.updatedData.middleName_Custom != ""
                ? this.updatedData.middleName_Custom
                : null,
            surname_Custom:
              this.updatedData.surname_Custom != ""
                ? this.updatedData.surname_Custom
                : null,
            position_Custom: this.updatedData.position_Custom,
            realPosition_Custom:
              this.updatedData.realPosition_Custom != ""
                ? this.updatedData.realPosition_Custom
                : null,
            shirtNumber_Custom:
              this.updatedData.shirtNumber_Custom != ""
                ? this.updatedData.shirtNumber_Custom
                : null,
            joinDate_Custom:
              this.updatedData.joinDate_Custom != ""
                ? this.updatedData.joinDate_Custom
                : null,
            loan_Custom: this.updatedData.loan_Custom,
            onLoanFrom_Custom:
              this.updatedData.onLoanFrom_Custom != ""
                ? this.updatedData.onLoanFrom_Custom
                : null,
            dob_Custom:
              this.updatedData.dob_Custom != ""
                ? this.updatedData.dob_Custom
                : null,
            country_Custom:
              this.updatedData.country_Custom != ""
                ? this.updatedData.country_Custom
                : null,
            showStats: false,
            playerProfileData: this.updatedData.playerProfileData,
            primaryRetailLink:
              this.updatedData.primaryRetailLink != ""
                ? this.updatedData.primaryRetailLink
                : null,
            playerSlug:
              this.updatedData.playerSlug != ""
                ? this.updatedData.playerSlug
                : null,
          },
        })
          .then((response) => {
            console.log("Saved player:", response);
            this.$store.commit("completeLoading");
            this.isNewPlayer = false;
            this.$router.push({
              path: `/team-management/player/${response.data.playerID}`,
            });
            this.status = "saved";
          })
          .catch((e) => {
            console.error("Problem saving player", e);
            this.$store.commit("completeLoading");
            this.status = "needsSaving";
            this.errorMessage = "Problem saving player";
          });
      });
    },

    savePlayer() {
      this.status = "saving";
      this.$store.commit("startLoading");

      let saveEndpoint = `${this.api}/opta/updatesingle?seasonID=${this.seasonID}&teamID=${this.teamID}&playerID=${this.$route.params.playerID}`;

      let showStats = false;
      if (!this.isCustom) {
        showStats = this.updatedData.optaShowStats;
      }

      if (this.updatedData.playerProfileData.quote === "") {
        this.updatedData.playerProfileData.quote = null;
      }

      if (this.updatedData.playerProfileData.twitter === "") {
        this.updatedData.playerProfileData.twitter = null;
      }

      if (this.updatedData.playerProfileData.threads === "") {
        this.updatedData.playerProfileData.threads = null;
      }

      if (this.updatedData.playerProfileData.instagram === "") {
        this.updatedData.playerProfileData.instagram = null;
      }

      if (this.updatedData.playerProfileData.tiktok === "") {
        this.updatedData.playerProfileData.tiktok = null;
      }

      if (this.updatedData.playerProfileData.biography === "") {
        this.updatedData.playerProfileData.biography = null;
      }

      if (this.updatedData.playerProfileData.debut === "") {
        this.updatedData.playerProfileData.debut = null;
      }

      if (this.updatedData.playerProfileData.primaryRetailLink === "") {
        this.updatedData.playerProfileData.primaryRetailLink = null;
      }

      if (this.updatedData.playerProfileData.profileImage === "") {
        this.updatedData.playerProfileData.profileImage = null;
      }

      if (this.updatedData.playerProfileData.squadImage === "") {
        this.updatedData.playerProfileData.squadImage = null;
      }

      if (this.updatedData.playerProfileData.playerHeadshot === "") {
        this.updatedData.playerProfileData.playerHeadshot = null;
      }

      if (this.updatedData.playerProfileData.appProfileImage === "") {
        this.updatedData.playerProfileData.appProfileImage = null;
      }

      if (this.isCustom) {
        saveEndpoint = `${this.api}/custom/updatesingle?seasonID=${this.seasonID}&teamID=${this.teamID}&playerID=${this.$route.params.playerID}`;
      }

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${saveEndpoint}`,
          headers: { Authorization: jwt },
          data: {
            playerID: this.$route.params.playerID,
            published: this.updatedData.published,
            enableProfilePage: this.updatedData.enableProfilePage,
            firstName_Custom:
              this.updatedData.firstName_Custom != ""
                ? this.updatedData.firstName_Custom
                : null,
            knownName_Custom:
              this.updatedData.knownName_Custom != ""
                ? this.updatedData.knownName_Custom
                : null,
            middleName_Custom:
              this.updatedData.middleName_Custom != ""
                ? this.updatedData.middleName_Custom
                : null,
            surname_Custom:
              this.updatedData.surname_Custom != ""
                ? this.updatedData.surname_Custom
                : null,
            position_Custom: this.updatedData.position_Custom,
            realPosition_Custom:
              this.updatedData.realPosition_Custom != ""
                ? this.updatedData.realPosition_Custom
                : null,
            shirtNumber_Custom:
              this.updatedData.shirtNumber_Custom != ""
                ? this.updatedData.shirtNumber_Custom
                : null,
            joinDate_Custom:
              this.updatedData.joinDate_Custom != ""
                ? this.updatedData.joinDate_Custom
                : null,
            loan_Custom: this.updatedData.loan_Custom,
            onLoanFrom_Custom:
              this.updatedData.onLoanFrom_Custom != ""
                ? this.updatedData.onLoanFrom_Custom
                : null,
            dob_Custom:
              this.updatedData.dob_Custom != ""
                ? this.updatedData.dob_Custom
                : null,
            country_Custom:
              this.updatedData.country_Custom != ""
                ? this.updatedData.country_Custom
                : null,
            showStats: showStats == true ? 1 : 0,
            playerProfileData: this.updatedData.playerProfileData,
            primaryRetailLink:
              this.updatedData.primaryRetailLink != ""
                ? this.updatedData.primaryRetailLink
                : null,
            shirtType: this.updatedData.shirtType,
            playerSlug:
              this.updatedData.playerSlug != ""
                ? this.updatedData.playerSlug
                : null,
          },
        })
          .then((response) => {
            console.log("Saved player:", response);
            this.$store.commit("completeLoading");
            this.status = "saved";
            // fetch to ensure that the slug is not a duplicate
            this.fetchPlayer();
          })
          .catch((e) => {
            console.error("Problem saving player", e);
            this.$store.commit("completeLoading");
            this.status = "needsSaving";
            this.errorMessage = "Problem saving player";
          });
      });
    },

    updateImage(payload, src) {
      console.log("Update image:", payload);
      switch (src) {
        case "profileImage":
          this.updatedData.playerProfileData.profileImage =
            payload.mediaLibraryID;
          break;
        case "appProfileImage":
          this.updatedData.playerProfileData.appProfileImage =
            payload.mediaLibraryID;
          this.updatedData.playerProfileData.appProfileImageKey =
            payload.imageKey;
          break;
        case "squadImage":
          this.updatedData.playerProfileData.squadImage =
            payload.mediaLibraryID;
          break;
        case "playerHeadshot":
          this.updatedData.playerProfileData.playerHeadshot =
            payload.mediaLibraryID;
          break;
        // case 'productHomeImage':
        //   this.updatedData.productIdHomeImageSrc = payload.mediaLibraryID
        //   break;
        // case 'productAwayImage':
        //   this.updatedData.productIdAwayImageSrc = payload.mediaLibraryID
        //   break;
        case "sponsorLogo":
          this.updatedData.playerProfileData.playerSponsorData.sponsorLogo =
            payload.mediaLibraryID;
          break;
        case "secondarySponsorLogo":
          this.updatedData.playerProfileData.playerSponsorSecondaryData.sponsorLogo =
            payload.mediaLibraryID;
          break;
        case "customShirt":
          this.updatedData.playerProfileData.customShirtImageKey =
            payload.imageKey;
          this.updatedData.playerProfileData.customShirtMediaLibraryID =
            payload.mediaLibraryID;
          break;
      }
      this.updateData();
    },

    editPlayerV3(playerID, playerType) {
      this.$store.commit("startLoading");
      this.$router.push({
        // path: `/player/${playerID}`,
        name: "Player Editor V3",
        params: {
          playerID: playerID,
          playerType: playerType,
          teamID: this.teamID,
          seasonID: this.seasonID,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-page-alert {
  align-items: center;
}

.half {
  width: 50%;
}

.section-divider {
  margin-bottom: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dfe3e9;
}

.break {
  margin: 16px 0 32px;
  opacity: 0.3;
}

.checkbox-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  &__input {
    margin: auto 0 auto 0;
    width: auto;
    flex: none;
    padding: 0;
  }

  &__label {
    padding-top: 1px;
  }
}

.opta {
  &__table-heading {
    padding: 4px 16px 4px 0;
    text-align: left;
  }
}

.player {
  &__checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__panel {
    border: 1px solid $lightgrey;
    background-color: $white;
    padding: 20px;
    margin-bottom: 20px;
    &-details {
      flex-direction: column;
      @media (min-width: 960px) {
        flex-direction: row;
      }
    }
    &-img {
      img {
        width: 100%;
      }
    }
    &-opta {
      &-cb {
        label {
          margin-bottom: 0;
        }
      }
    }
    &--column {
      flex-direction: column;
      width: 100%;
    }
  }
}
.input {
  &__textfield {
    &--basic {
      margin-top: 0;
    }
  }
}

.note {
  padding: 10px;
  margin: 0 10px 30px;
  font-size: 13px;
  font-style: italic;
  background-color: #f4f6d2;
  border: 1px solid #d6d8b0;
  opacity: 0;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  transform: translateX(-30px);
}

.has-note:hover .note {
  opacity: 0.8;
  transform: translateX(0);
}
</style>