<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <p class="widget-info">This widget allows you to feature a specific video.  The title and description will come from video metadata but you can also add an optional label.</p>

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()">Use this widget</v-btn>
    </template>

    <div class="widget-fieldset" v-else>

      <label class="label">Style</label>
      <v-select
        id="widgetData.style"
        :items="styleOptions"
        solo
        flat
        v-model="updatedData.widgetData.style">
      </v-select>

      <label class="label">Packages Link</label>
      <v-text-field
        id="widgetData.packagesLink"
        solo
        flat
        v-model="updatedData.widgetData.packagesLink">
      </v-text-field>

      <label class="label">Packages Text</label>
      <v-text-field
        id="widgetData.packagesText"
        solo
        flat
        v-model="updatedData.widgetData.packagesText">
      </v-text-field>

      <label class="label">Filter Type</label>
      <v-select
        id="widgetData.style"
        :items="filterOptions"
        solo
        flat
        v-model="updatedData.widgetData.filteredType">
      </v-select>

      <label class="label">Selected Event</label>
      <v-select
        id="widgetData.style"
        :items="eventOptions"
        solo
        flat
        v-model="updatedData.widgetData.selectedEvent">
      </v-select>

    </div>
  </div>
</template>

<script>
export default {
  name: "StreamplayFeature",

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent()
      },
      deep: true
    }
  },

  props: {
    editData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    styleOptions: [{text: "Light Mode", value: "light"},{text: "Dark Mode", value: "dark"}],
    buttonStyleOptions: ["", "primary-light", "primary-dark", "ghost-light", "ghost-dark", "label-light", "label-dark"],
    filterOptions: [{text: "All", value: ""},{text: "Event", value: "event"},{text: "Fixture", value: "fixture"}],
    eventOptions: [{text: "Next", value: "next"},{text: "Next + 1", value: "next+1"}],
    updatedData: {
      widgetData: {
        style: null,
        packagesLink: null,
        packagesText: null,
        filteredType: null,
        selectedEvent: null
      }
    }
  }),

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.editData))
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "StreamplayFeature"
      this.updatedData.widgetName = "Streamplay Feature"
      this.updatedData.widgetID = this.$uuid.v1()
      this.updatedData.widgetData = {
        style: null,
        packagesLink: null,
        packagesText: null,
        filteredType: null,
        selectedEvent: null
      }
      this.$emit("initialised")
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData)
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
