<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <p class="widget-info">
        This widget gives you a video carousel with options to use team and
        category filters or build a custom search to return the content you
        want.
      </p>

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()"
        >Use this widget</v-btn
      >
    </template>

    <div class="widget-fieldset" v-else>
      <template v-if="allowTitle">
        <label class="label">Title</label>
        <v-text-field
          id="widgetData.title"
          solo
          flat
          v-model="updatedData.widgetData.title"
        >
        </v-text-field>
      </template>

      <template v-if="$route.query.isApp !== 'true'">
        <template v-if="allowStyle">
          <label class="label">Style</label>
          <v-select
            id="widgetData.style"
            :items="styleOptions"
            solo
            flat
            v-model="updatedData.widgetData.style"
          >
          </v-select>
        </template>

        <v-layout row v-if="modules.default.LFCExclusive">
          <v-flex mr-2>
            <ImageUploader
              :title="'Background image (optional)'"
              :widthOptionEnabled="false"
              :mediaLibraryID="
                updatedData.widgetData.imageData
                  ? updatedData.widgetData.imageData.mediaLibraryID
                  : ''
              "
              @update="updateImage($event)"
            />
          </v-flex>
        </v-layout>
      </template>
      <query-builder
        @updateQuery="buildQuery($event)"
        :queryData="updatedData.widgetData.query"
        :additionalQueryTypes="
          modules.default.StreamlinePlaylists && $route.query.isApp !== 'true'
            ? ['playlist']
            : null
        "
      />

      <template v-if="$route.query.isApp !== 'true'">
        <label class="label">Link URL</label>
        <v-text-field
          id="widgetData.button"
          solo
          flat
          v-model="updatedData.widgetData.destinationUrl"
        >
        </v-text-field>

        <label class="label">Link Text</label>
        <v-text-field
          id="widgetData.buttonText"
          solo
          flat
          v-model="updatedData.widgetData.destinationLinkText"
        >
        </v-text-field>

        <label class="label">Link Style</label>
        <v-select
          id="widgetData.buttonStyle"
          :items="buttonStyleOptions"
          solo
          flat
          v-model="updatedData.widgetData.buttonStyle"
        >
        </v-select>
      </template>
    </div>
  </div>
</template>

<script>
import QueryBuilder from "@/components/VideosManager/Data/QueryBuilder.vue";
import ImageUploader from "@/components/Library/ImageUploader";

export default {
  name: "VideoCarousel",

  components: {
    QueryBuilder,
    ImageUploader,
  },

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent();
      },
      deep: true,
    },
  },

  props: {
    editData: {
      type: Object,
      required: true,
    },
    allowTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    allowStyle: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data: () => ({
    styleOptions: [
      { text: "Light Mode", value: "light" },
      { text: "Dark Mode", value: "dark" },
    ],
    buttonStyleOptions: [
      "",
      "primary-light",
      "primary-dark",
      "ghost-light",
      "ghost-dark",
      "label-light",
      "label-dark",
    ],
    updatedData: {
      title: null,
      style: null,
      imageData: null,
      query: {
        queryType: null,
        queryScope: null,
        category: null,
        team: null,
        searchString: null,
      },
      buttonStyle: null,
      destinationUrl: null,
      destinationLinkText: null,
    },
    modules: null,
  }),

  created() {
    this.modules = require("../../../../../../config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");

    if (["LFC"].includes(process.env.VUE_APP_NAME)) {
      this.styleOptions.push({ text: "Club Mode", value: "club" });
    }
  },

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.editData));
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "VideoCarousel";
      this.updatedData.widgetName = "Video Carousel";
      this.updatedData.widgetID = this.$uuid.v1();
      this.updatedData.widgetData = {
        title: null,
        style: null, // Background colour - light || dark || club
        imageData: null,
        query: {
          queryType: null,
          queryScope: null,
          category: null,
          team: null,
          searchString: null,
        },
        buttonStyle: null,
        destinationUrl: null,
        destinationLinkText: null,
      };
      this.$emit("initialised");
    },

    buildQuery(event) {
      this.updatedData.widgetData.query = JSON.parse(JSON.stringify(event));
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData);
    },

    updateImage(image) {
      this.updatedData.widgetData.imageData = image;
    },
  },
};
</script>

<style scoped lang='scss'>
</style>
