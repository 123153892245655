export default {
  data: [
    {
      sectionName: "Homepage Hero",
      sectionDesc: "Add the details for each slide here",
      model: "homepageHero",
      fields: [
        {
          label: "Slide 1 - Title",
          model: "homepageHero__title1",
          type: "Text"
        },
        {
          label: "Slide 1 - Description",
          model: "homepageHero__description1",
          type: "Text"
        },
        {
          label: "Slide 1 - Button URL",
          model: "homepageHero_link1",
          type: "Text"
        },
        {
          label: "Slide 1 - Button Text",
          model: "homepageHero_linkText1",
          type: "Text"
        },
        {
          label: "Slide 1 - Image",
          model: "homepageHero_image1",
          type: "Image"
        },
        {
          label: "Display Second Slide",
          model: "homepageBanner_display2",
          type: "Checkbox"
        },
        {
          label: "Slide 2 - Title",
          model: "homepageHero__title2",
          type: "Text"
        },
        {
          label: "Slide 2 - Description",
          model: "homepageHero__description2",
          type: "Text"
        },
        {
          label: "Slide 2 - Button URL",
          model: "homepageHero_link2",
          type: "Text"
        },
        {
          label: "Slide 2 - Button Text",
          model: "homepageHero_linkText2",
          type: "Text"
        },
        {
          label: "Slide 2 - Image",
          model: "homepageHero_image2",
          type: "Image"
        },
        {
          label: "Display Third Slide",
          model: "homepageBanner_display3",
          type: "Checkbox"
        },
        {
          label: "Slide 3 - Title",
          model: "homepageHero__title3",
          type: "Text"
        },
        {
          label: "Slide 3 - Description",
          model: "homepageHero__description3",
          type: "Text"
        },
        {
          label: "Slide 3 - Button URL",
          model: "homepageHero_link3",
          type: "Text"
        },
        {
          label: "Slide 3 - Button Text",
          model: "homepageHero_linkText3",
          type: "Text"
        },
        {
          label: "Slide 3 - Image",
          model: "homepageHero_image3",
          type: "Image"
        },
        {
          label: "Display Fourth Slide",
          model: "homepageBanner_display4",
          type: "Checkbox"
        },
        {
          label: "Slide 4 - Title",
          model: "homepageHero__title4",
          type: "Text"
        },
        {
          label: "Slide 4 - Description",
          model: "homepageHero__description4",
          type: "Text"
        },
        {
          label: "Slide 4 - Button URL",
          model: "homepageHero_link4",
          type: "Text"
        },
        {
          label: "Slide 4 - Button Text",
          model: "homepageHero_linkText4",
          type: "Text"
        },
        {
          label: "Slide 4 - Image",
          model: "homepageHero_image4",
          type: "Image"
        },
      ]
    },
    {
      sectionName: "Homepage Banner",
      sectionDesc: "This appears below the hero on your homepage",
      model: "homepageBanner",
      fields: [
        {
          label: "Display",
          model: "homepageBanner_display",
          type: "Checkbox"
        },
        {
          label: "Title",
          model: "homepageBanner__title",
          type: "Text"
        },
        {
          label: "Description",
          model: "homepageBanner__description",
          type: "Text"
        },
        {
          label: "CTA URL",
          model: "homepageBanner_link",
          type: "Text"
        },
        {
          label: "CTA Text",
          model: "homepageBanner_linkText",
          type: "Text"
        },
        {
          label: "Open in New Tab?",
          model: "homepageBanner_newTab",
          type: "Checkbox"
        },
        {
          label: "Image",
          model: "homepageBanner_image",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Event Cards",
      sectionDesc: "Add the correct page slugs to diplay events on the homepage.",
      model: "homepageEvents",
      fields: [
        {
          label: "Article One Slug",
          model: "homepageEvents__one",
          type: "Text"
        },
        {
          label: "Article Two Slug",
          model: "homepageEvents__two",
          type: "Text"
        },
        {
          label: "Article Three Slug",
          model: "homepageEvents__three",
          type: "Text"
        },
        {
          label: "Article Four Slug",
          model: "homepageEvents__four",
          type: "Text"
        },
        {
          label: "CTA URL",
          model: "homepageEvents__buttonUrl",
          type: "Text"
        },
        {
          label: "CTA Text",
          model: "homepageEvents__buttonText",
          type: "Text"
        }
      ]
    },
    {
      sectionName: "Homepage promo title",
      sectionDesc: "This will set the section title and description above the promo section",
      model: "homepagePromo__title",
      fields: [
        {
          label: "Title",
          model: "category_title",
          type: "Text"
        },
        {
          label: "Description",
          model: "category_description",
          type: "Text"
        }
      ]
    },
    {
      sectionName: "Homepage promo - left",
      sectionDesc: "This is for the left hand side (top on smaller screens) promotional on the homepage",
      model: "promo_left",
      fields: [
        {
          label: "Title",
          model: "promo_left_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_left_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_left_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_left_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_left_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Homepage promo - right",
      sectionDesc: "This is for the right hand side (bottom on smaller screens) promotional area on the homepage",
      model: "promo_right",
      fields: [
        {
          label: "Title",
          model: "promo_right_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_right_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_right_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_right_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_right_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Featured Stats",
      sectionDesc: "This is for the featured stats section at the bottom of the homepage.",
      model: "featuredStats",
      fields: [
        {
          label: "CTA URL",
          model: "featuredStats__ctaUrl",
          type: "Text"
        },
        {
          label: "CTA Text",
          model: "featuredStats__ctaText",
          type: "Text"
        }
      ]
    }
  ]
}
