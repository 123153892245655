import { render, staticRenderFns } from "./FootballBasicLineupWidget.vue?vue&type=template&id=b669b474&scoped=true"
import script from "./FootballBasicLineupWidget.vue?vue&type=script&lang=js"
export * from "./FootballBasicLineupWidget.vue?vue&type=script&lang=js"
import style0 from "./FootballBasicLineupWidget.vue?vue&type=style&index=0&id=b669b474&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b669b474",
  null
  
)

export default component.exports