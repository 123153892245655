<template>
  <transition name="swap" mode="out-in">
    <div class="draggable-item__row" v-if="!editing">

      <div class="draggable-item__handle">
        <v-icon class="draggable-item__handle-icon">open_with</v-icon>

        <div class="draggable-item__details">
          <p class="draggable-item__name">{{ name }}</p>
        </div>
      </div>

      <div class="draggable-item__actions">
        <button class="draggable-item__action" type="button" @click="deleteItem">
          <v-icon>delete</v-icon>
        </button>

        <button v-if="allowChildCreation" class="draggable-item__action" type="button" @click="addChildItem">
          <v-icon>playlist_add</v-icon>
        </button>

        <button class="draggable-item__action" type="button" @click="editItem">
          <v-icon>edit</v-icon>
        </button>
      </div>

    </div>

    <div class="edit-item" v-else>
      <v-form v-model="form.valid" ref="form" lazy-validation v-on:submit.prevent="validate(form)" class="edit-item__form">
        <h5 class="edit-item__name">{{ name }}</h5>

        <div class="edit-item__fields">
          <v-text-field
            v-model="form.pageName"
            placeholder="Page name"
            :rules="form.rules"
            solo
            flat
            required
          ></v-text-field>

          <v-text-field
            v-model="form.pageSlug"
            placeholder="/page-url"
            :rules="form.rules"
            solo
            flat
            required
          ></v-text-field>
        </div>

        <v-btn
          class="edit-item__button"
          :disabled="!form.valid"
          color="accent"
          type="submit"
          depressed
        >
          Done
        </v-btn>
      </v-form>
    </div>
  </transition>
</template>

<script>
export default {
  name: "DraggableMenuRow",

  props: {
    name: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    allowChildCreation: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    editing: false,
    form: {
      valid: false,
      pageName: null,
      pageSlug: null,
      rules: [
        v => !!v || "Field is required"
      ],
    },
  }),

  created() {
    this.form.pageName = this.name;
    this.form.pageSlug = this.slug;
  },

  methods: {
    editItem() {
      this.editing = !this.editing
    },

    deleteItem() {
      console.log("Delete Item");

      if (confirm("Are you sure you want to delete this item?")) {
        this.$emit("deleteItem");
      }
    },

    addChildItem() {
      this.$emit("addChild");
    },

    validate(form) {
      console.log(form);
      this.editing = !this.editing;

      if (form.valid) {
        console.log("Update Item is valid");
        this.$emit("updateItem", form);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .swap-enter-active,
  .swap-leave-active {
    transition: opacity .8s;
  }

  .swap-enter, .swap-leave-to {
    opacity: 0;
  }

  .draggable-item {

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    &__handle {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      padding: 15px;
      cursor: -webkit-grab;
      cursor: grab;
    }

    &__handle-icon {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }

    &__details {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
      padding: 0;
      margin: 0 0 0 45px;
      text-align: left;
    }

    &__name {
      margin: 0 8px 0 0;
    }

    &__actions {
      padding: 0 15px;
    }

    &__action {
      margin: 0 0 0 25px;
    }
  }

  .edit-item {
    margin: 15px 0;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;

    &__form {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
    }

    &__name {
      align-self: flex-start;
      padding: 0 40px 0 60px;
      font-size: 15px;
      font-weight: 300;
    }

    &__fields {
      flex-grow: 1;
    }

    &__button {
      align-self: flex-end;
      margin: 0 0 25px 40px;
    }
  }
</style>
