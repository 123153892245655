<template>
    <v-dialog
        class="dialog"
        v-model="dialog"
        width="400"
        scrollable
        lazy
        persistent
    >
        <slot slot="activator"></slot>
        <v-card class="card">
            <v-card-title
                v-if="slotID"
                class="card-title mb-0"
            >Select Ad</v-card-title>
            <v-card-title
                v-else
                class="card-title mb-0"
            >Create Slot</v-card-title>
            <p
                class="slot-error"
                v-if="error"
            >{{ error }}</p>
            <v-card-text v-if="slotID">
                <h3 class="slot-title">{{ slotTitle }}</h3>
                <v-tabs v-model="activeTab">
                    <v-tab
                        v-show="includeTabs"
                        :ripple="{ class: 'accent--text' }"
                    >Simple Ad</v-tab>

                    <v-tab-item
                        px-0
                        class=""
                    >
                        <template v-if="simpleAds && simpleAds.length">
                            <div
                                v-if="sortable"
                                class="sortable-ads"
                            >
                                <p class="sortable-ads__disclaimer">Drag and Drop to reorder</p>
                                <draggable
                                    v-if="selectedAds.length"
                                    v-model="selectedAds"
                                    :options="{
            ghostClass: 'ghost',
            dragClass: 'sortable-drag',
        }"
                                    @start="drag = true"
                                    @end="drag = false"
                                    class="sortable-ads__ads"
                                >
                                    <div
                                        v-for="ad in selectedAds"
                                        :key="ad.id"
                                        class="sortable-ads__ad"
                                    >
                                        <v-icon class="sortable-ads__icon">open_with</v-icon>
                                        <h3 class="sortable-ads__title">{{ ad.attributes.advertTitle }}</h3>
                                    </div>
                                </draggable>
                            </div>
                            <v-select
                                id="ads"
                                :items="simpleAds"
                                item-text="attributes.advertTitle"
                                multiple
                                return-object
                                v-model="selectedAds"
                                label="Ads"
                                @change="filterSelected('simple')"
                            ></v-select>
                        </template>
                        <p
                            class="mt-4 text-md-center"
                            v-else
                        >No Ads found</p>
                    </v-tab-item>

                    <v-tab
                        v-show="includeTabs"
                        :ripple="{ class: 'accent--text' }"
                    >Adition</v-tab>

                    <v-tab-item
                        px-0
                        class=""
                    >
                        <template v-if="aditionAds && aditionAds.length">
                            <v-select
                                id="ads"
                                :items="aditionAds"
                                item-text="attributes.advertTitle"
                                multiple
                                return-object
                                v-model="selectedAds"
                                label="Ads"
                                @change="filterSelected('adition')"
                            ></v-select>
                        </template>
                        <p
                            class="mt-4 text-md-center"
                            v-else
                        >No Ads found</p>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
            <v-card-text v-else>
                <label
                    class="label"
                    for="slotTitle"
                >Slot Title</label>
                <v-text-field
                    id="slotTitle"
                    placeholder="Slot title"
                    solo
                    flat
                    v-model="newSlotTitle"
                ></v-text-field>
            </v-card-text>
            <v-card-actions class="modal-buttons">
                <v-btn
                    accent
                    type="button"
                    color="accent"
                    depressed
                    @click="slotID ? selectAd() : createSlot()"
                    :class="{ 'no-actions': loading }"
                >
                    {{ loading ? "Loading..." : "Save" }}
                </v-btn>
                <v-btn
                    text
                    flat
                    @click="cancel()"
                > Cancel </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
import MediaLibraryPopup from "@/components/Library/MediaLibraryPopup";
import ConfirmationModal from "@/components/Library/GlobalModals/ConfirmationModal";
import draggable from "vuedraggable";

export default {
    name: "SlotModal",

    components: {
        MediaLibraryPopup,
        ConfirmationModal,
        draggable
    },

    props: {
        adsData: {
            type: Array,
            required: false,
        },
        slotID: {
            type: String,
            required: false,
        },
        slotTitle: {
            type: String,
            required: false,
        },
        selected: {
            type: Array,
            required: false,
        },
        sortable: {
            type: Boolean,
            default: false,
        }
    },

    data: () => ({
        advertsAPI: process.env.VUE_APP_ADVERTISINGAPI,
        dialog: false,
        error: '',
        loading: false,
        selectedAds: [],
        simpleAds: [],
        aditionAds: [],
        activeTab: 0,
        advertising2sdk: null,
        includeTabs: false,
        newSlotTitle: '',
    }),

    created() {
        this.advertising2sdk = this.$gcFeatures("Advertising2");
        this.includeTabs = this.advertising2sdk.hasMultipleProviders;
    },

    watch: {
        dialog() {
            if (this.dialog && this.selected && this.selected.length) {
                this.selectedAds = this.selected.map((selected) => {
                    return this.adsData.find((ad) => {
                        return selected.advertID == ad.id;
                    });
                });
            }

            if (
                this.selectedAds &&
                this.selectedAds[0] &&
                this.selectedAds[0].attributes.advertType == "adition"
            ) {
                this.activeTab = 1;
            }
            this.simpleAds = this.adsData.filter(
                (ad) => ad.attributes.advertType == "simple"
            );
            this.aditionAds = this.adsData.filter(
                (ad) => ad.attributes.advertType == "adition"
            );
        },
    },

    methods: {
        selectAd() {
            if (this.loading == false) {
                this.loading = true;
                this.error = "";
                let adverts;
                if (Array.isArray(this.selectedAds)) {
                    adverts = this.selectedAds.map((ad) => {
                        if (ad && ad.id) {
                            return { advertID: ad.id };
                        }
                    });
                    adverts = adverts.filter((ad) => ad != undefined);
                } else {
                    adverts = [{ advertID: this.selectedAds.id }];
                }

                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "PATCH",
                        url: `${this.advertsAPI}/slots/${this.slotID}`,
                        headers: { Authorization: jwt },
                        data: {
                            data: {
                                type: "slot",
                                id: this.slotID,
                                attributes: {
                                    slotID: this.slotID,
                                    slotTitle: this.slotTitle,
                                    adverts,
                                },
                            },
                        },
                    })
                        .then((response) => {
                            console.log("Slot Edited", response);
                            this.$emit("slotEdited");
                            this.cancel();
                        })
                        .catch((e) => {
                            this.error = "An Error has occurred";
                            console.error("Problem editing slot", e);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                });
            }
        },

        createSlot() {
            this.error = ''
            this.loading = true
            if (!this.newSlotTitle) {
                this.error = "Slot Title is required"
                return
            }
            let id = this.newSlotTitle.replaceAll(' ', '_').toLowerCase()
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: `${this.advertsAPI}/slots/${id}`,
                    headers: { Authorization: jwt },
                    data: {
                        data: {
                            type: "slot",
                            id,
                            attributes: {
                                slotTitle: this.newSlotTitle,
                                advertSlotID: id,
                                adverts: [],
                            },
                        },
                    },
                })
                    .then((response) => {
                        this.$emit("slotEdited");
                        this.cancel();
                    })
                    .catch((e) => {
                        this.error = "An Error has occurred";
                        console.error("Problem editing slot", e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        cancel() {
            this.selectedAds = [];
            this.dialog = false;
        },

        filterSelected(type) {
            if (Array.isArray(this.selectedAds)) {
                this.selectedAds = this.selectedAds.filter((ad) => {
                    if (ad && ad.attributes) {
                        return ad.attributes.advertType == type;
                    }
                });
            }
        },
    },
};
</script>

<style
    scoped
    lang='scss'
>
.modal-buttons {
    display: flex;
    justify-content: center;
    box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.16);
}

.card-title {
    font-size: 20px;
    color: #24002f;
    margin-bottom: 15px;
}

.slot-title {
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
}

.no-actions {
    pointer-events: none;
}

.slot-error {
    padding: 0 16px;
    color: #ff5252;
}

.sortable-ads {
    margin-bottom: 8px;

    &__ads {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__ad {
        background: #fafafa;
        border: 1px solid #dfe3e9;
        display: block;
        padding: 8px;
        width: 100%;
        border-radius: 4px;
        cursor: move;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__icon {
        font-size: 18px;
    }

    &__disclaimer {
        color: #7b6b80;
        font-size: 12px;
    }

    &__title {
        font-size: 14px;
        font-weight: normal;
    }
}
</style>
