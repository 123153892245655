<template>
  <v-dialog
    class="streamline-embed-widget"
    v-model="dialog"
    width="580"
    lazy
  >
    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
        Streamline Video
        </v-card-title>

        <v-card-text>

        <v-form enctype="multipart/form-data" v-model="valid" ref="streamlineEmbed" lazy-validation>

          <section>
            <label class="label" for="title">Title</label>
            <v-text-field
              id="title"
              outline
              v-model="updatedData.title"
              solo
              flat
              class="text-field--lableless"
            ></v-text-field>

            <label class="label" for="description">Video ID</label>
            <v-text-field
              id="description"
              outline
              v-model="updatedData.id"
              solo
              flat
              :rules="[rules.required]"
              class="text-field--lableless"
            ></v-text-field>


            <label class="label" for="buttonType">Stream Type</label>
            <v-select
              id="buttonType"
              :items="[{text: 'On Demand', value: 'onDemand'}, {text: 'Live', value: 'live'}]"
              v-model="updatedData.streamType"
              solo
              flat
              :rules="[rules.required]"
            ></v-select>

            <v-btn
              color="accent"
              outline
              flat
              @click="dialog = false"
            >
              Cancel
            </v-btn>

            <v-btn
              color="accent"
              depressed
              :disabled="!updatedData.streamType || !updatedData.id"
              @click="validate()"
            >
              <span v-if="widgetData">Update video</span>
              <span v-else>Add video</span>
            </v-btn>
          </section>

        </v-form>

        </v-card-text>
      </v-card>

  </v-dialog>
</template>

<script>
// import axios from 'axios'
import ImageUploader from "@/components/Library/ImageUploader.vue"

export default {
  name: "ClubPromoWidget",

  components: {
    ImageUploader
  },

  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}`,
    dialog: false,
    valid: false,
    rules: {
      required: value => !!value || "Required."
    },
    updatedData: {
      title: null,
      id: null,
      streamType: null,
    },
  }),

  mounted() {
    console.log("Streamline embed widget mounted", this.widgetData);
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData));
    }
  },

  methods: {

    validate() {
      this.errorMessage = ""
      if (this.$refs.streamlineEmbed.validate() ) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },

    updateData() {
      console.log("Update data:", this.clubPromoData);
      if (this.updatedData.title === "") {
        this.updatedData.title = null
      }
      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit {
    margin: 0 10px 20px 0;
    width: 100%;
  }
</style>
