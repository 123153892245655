<template>
  <v-dialog
    class="folder-add"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>
      <v-card>
        <v-card-title>Add a folder</v-card-title>

        <v-card-text>
          <v-text-field :placeholder="'Folder name'" v-model="folderName"/>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="accent"
            outline
            flat
            :disabled="!folderName"
            @click="addFolder()"
          >
            Add folder
          </v-btn>
        </v-card-actions>
      </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "FolderAdd",

  data: () => ({
    dialog: false,
    folderName: ""
  }),

  methods: {
    addFolder() {
      this.$emit("addFolder", this.folderName)
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
