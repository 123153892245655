<template>
  <article class="entitlement-item">
    <div>
      <h2 class="entitlement-item__id subheading">{{ entitlementID }}</h2>

      <p v-if="createdAt" class="entitlement-item__on">Created on {{ createdAt | moment('DD/MM/YY kk:mm (Z)') }}</p>
      <p v-else class="entitlement-item__on">Created on setup</p>

      <p class="entitlement-item__by">by {{ createdBy }}</p>
    </div>

    <div class="entitlement-item__ctas">
      <v-btn v-if="allowDelete" icon small @click="deleteItem()"><v-icon small>delete</v-icon></v-btn>
    </div>
  </article>
</template>

<script>
import axios from "axios"

export default {
  name: "EntitlementsManagerItem",

  props: {
    allowDelete: {
      type: Boolean,
      default: true
    },
    createdAt: {
      type: String,
      required: false
    },
    createdBy: {
      type: String,
      required: true
    },
    entitlementID: {
      type: String,
      required: true
    }
  },

  methods: {
    deleteItem() {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios
          .delete(`${process.env.VUE_APP_STREAMLINEAPI}/entitlements/${this.entitlementID}`, { "headers": { "Authorization": jwt }})
          .then(response => {
            console.log("response", response)

            this.$emit("deleted")
          })
          .catch(e => {
            console.error("Problem deleting entitlement", e);
          });
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .entitlement-item {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 80px;
    margin: 0 0 16px;
    padding: 16px 0 0 16px;
    background-color: #fff;
    border: 1px solid #dfe3e9;
    border-radius: 4px;

    &__id {
      margin: 0 0 4px;
      font-size: 14px;
    }

    &__by,
    &__on {
      padding: 0;
      margin: 0;
      font-size: 10px;
      opacity: 0.5;
      word-break: break-all;
    }

    &__ctas {
      margin: auto 0 0 auto;
    }
  }
</style>
