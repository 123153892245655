<template>
  <section class="tab-buffer">

    <v-layout row>
      <v-flex mr-2>
        <label class="label" for="page-name">Page Title</label>
        <v-text-field
          id="page-name"
          v-model="updatedData.pageName"
          solo
          flat
          required
          @change="updateData()"
        ></v-text-field>
      </v-flex>

      <v-flex ml-2>
        <label class="label" for="page-title">Page Slug</label>
        <v-text-field
          id="page-slug"
          v-model="updatedData.pageSlug"
          solo
          flat
          required
          @change="updateData()"
        ></v-text-field>
      </v-flex>

    </v-layout>

    <v-layout row>
      <v-flex mr-2>
        <label class="label" for="page-summary">Page Summary</label>
        <v-textarea
          id="page-summary"
          v-model="updatedData.pageSummary"
          solo
          flat
          required
          @change="updateData()"
        ></v-textarea>
      </v-flex>
    </v-layout>

    <SavedBy :saveData="saveData"/>

  </section>
</template>

<script>
import SavedBy from "@/components/Library/SavedBy"

export default {
  name: "VideoConfiguration",

  components: {
    SavedBy
  },

  props: {
    data: {
      type: Object,
      required: true
    },
    saveData: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      updatedData: {}
    }
  },

  mounted() {
    this.updatedData = {
      pageID: this.data.pageID,
      pageName: this.data.pageName,
      pageSlug: this.data.pageSlug,
      pageSummary: this.data.pageSummary,
    }
  },

  methods: {
    updateData() {
      this.$emit("update", this.updatedData)
    }
  }
}
</script>

<style lang="scss" scoped>
  .narrow-column {
    max-width: 380px;
    width: 100%;
  }
</style>
