<template>
  <section class="entitlements">
    <inline-loader v-if="loading" />

    <template v-else>
      <h3 class="subheading">Available entitlements</h3>
      <section class="section-container">
        <v-layout row>
          <v-flex mr-5 class="third-x2">
            <entitlements-manager-item
              v-for="entitlement in entitlements" :key="entitlement.id"
              :allowDelete="entitlement.attributes.allowDelete"
              :createdAt="entitlement.attributes.publicationData ? entitlement.attributes.publicationData.createdAt : ''"
              :createdBy="entitlement.attributes.publicationData ? entitlement.attributes.publicationData.createdBy : 'System'"
              :entitlementID="entitlement.id"
              @deleted="fetchEntitlements()"
            />
          </v-flex>
          <v-flex mt-4 class="third">
            <p>This is a list of all entitlements you have available in the system</p>
          </v-flex>
        </v-layout>
      </section>

    </template>

    <h3 class="subheading">Create a new entitlement</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <template v-if="creating">
            <inline-loader />
          </template>

          <template v-else>
            <label class="label" for="entitlement">Entitlement</label>
            <v-text-field
              id="entitlement"
              v-model="newEntitlement"
              solo
              flat
              @input="sanitize()"
            ></v-text-field>

            <v-btn
              type="button"
              depressed
              color="success"
              @click="createEntitlement()"
            >
              Create Entitlement
            </v-btn>
          </template>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Create a new entitlement for match passes or any new membership levels.</p>
          <p>This entitlement will then need to be added to any assets</p>
        </v-flex>
      </v-layout>
    </section>
  </section>
</template>

<script>
import axios from "axios"
import InlineLoader from "@/components/Library/InlineLoader"
import MiniHeader from "@/components/Library/MiniHeader"
import EntitlementsManagerItem from "./EntitlementsManagerItem.vue"

export default {
  name: "EntitlementsManager",

  components: {
    InlineLoader,
    MiniHeader,
    EntitlementsManagerItem
  },

  data:() => ({
    loading: false,
    creating: false,
    entitlements: [],
    newEntitlement: ""
  }),

  mounted() {
    this.fetchEntitlements()
  },

  methods: {
    sanitize() {
      this.newEntitlement = this.newEntitlement.replace(/[^a-zA-Z0-9]/g,"-");
    },

    createEntitlement() {
      console.log("Create Entitlement")
      if (confirm("Are you sure you want to create a new entitlement?")) {
        this.creating = true

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${process.env.VUE_APP_STREAMLINEAPI}/entitlements`,
            headers: { "Authorization": jwt },
            data: {
              data: {
                type: "streamline-entitlement",
                attributes: {
                  entitlement: this.newEntitlement
                }
              }
            }
          })
          .then(response => {
            console.log("Create entitlement:", response);
            this.newEntitlement = ""
            this.creating = false

            this.fetchEntitlements()
          })
          .catch(e => {
            console.error("Problem creating entitlement", e);
            this.creating = false
            this.$store.commit("completeLoading");
          });
        })
      }
    },

    fetchEntitlements() {
      this.loading = true

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/entitlements?page[size]=1000`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Fetch entitlements:", response);
          this.entitlements = response.data.data
          this.$store.commit("completeLoading");
          this.loading = false
        })
        .catch(e => {
          console.error("Problem fetching entitlements", e);
          this.$store.commit("completeLoading");
          this.loading = false
        });
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .entitlements {

  }

  .half {
    width: 50%;
  }

  .third-x2 {
    width: 66.66%;
  }

  .third {
    width: 33.33%;
  }

  .section-divider {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #DFE3E9;
  }
</style>
