<template>
  <section class="preview">
    <template v-if="widgetType === 'TextBlockWidget'">
      <!-- <p>:TODO - Pass in column width</p> -->
      <div
        v-if="widgetData.content === null"
        class="preview__content"
        :class="{ widgetType }"
      >
        No content added
      </div>
      <div
        v-else
        v-html="`${widgetData.content}`"
        class="preview__content"
        :class="{ widgetType }"
      ></div>

      <div class="preview__actions">
        <button
          class="preview__action-button"
          type="button"
          @click="deleteWidget()"
        >
          Delete
        </button>
        <TextBlockWidget
          :widgetData="widgetData"
          @updatedData="editWidget($event)"
          class="preview__action-button"
          >Edit</TextBlockWidget
        >
      </div>
    </template>

    <template v-else-if="widgetType === 'GridImageWidget'">
      <div
        class="preview__image-wrap"
        :style="`background-image: url('${imageSrc}');`"
      >
        <div class="preview__header">
          <v-icon large color="#fff" class="preview__icon">insert_photo</v-icon>
        </div>

        <div class="preview__actions">
          <button
            class="preview__action-button"
            type="button"
            @click="deleteWidget()"
          >
            Delete
          </button>
          <GridImageWidget
            class="preview__action-button"
            :widgetData="widgetData"
            @updatedData="editImage($event)"
            >Replace</GridImageWidget
          >
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import axios from "axios";
import TextBlockWidget from "@/components/Widgets/TextBlockWidget";
import GridImageWidget from "@/components/Widgets/GridImageWidget";

export default {
  name: "GridPreview",

  components: {
    TextBlockWidget,
    GridImageWidget,
  },

  props: {
    widgetType: {
      type: String,
      required: true,
    },
    widgetData: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      updatedData: [],
      imageSrc: null,
    };
  },

  mounted() {
    console.log("Grid preview mounted: ", this.widgetData);
    if (this.widgetType === "GridImageWidget") {
      this.fetchImage(this.widgetData.imageID);
    } else if (this.widgetType === "ImageGalleryWidget") {
      this.fetchImage(this.widgetData.gallery[0].imageID);
    } else if (this.widgetType === "TriviaWidget") {
      this.fetchImage(this.widgetData.imageID);
    }
  },

  methods: {
    editWidget(payload) {
      this.$emit("edit", payload);
    },

    deleteWidget() {
      this.$emit("delete");
    },

    editImage(payload) {
      console.log("Image edited");
      this.$emit("edit", payload);
    },

    fetchImage(mediaLibraryID) {
      if (mediaLibraryID) {
        console.log("Fetch image dfsfds", mediaLibraryID);
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "GET",
            url: `${process.env.VUE_APP_BASEURL}/media/${mediaLibraryID}`,
            headers: { Authorization: jwt },
          })
            .then((response) => {
              console.log("Fetch image response:", response);

              this.imageSrc = `${response.data.imageHandlerURL}/${response.data.body.key}`;
            })
            .catch((e) => {
              console.error("Problem fetching image", e);
            });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  position: relative;
  // padding: 0 0 40px;
  background-color: #eee6f1;
  border-radius: 4px;
  //NEW
  margin: 0;
  height: 160px;
  overflow: scroll;

  &__image-wrap {
    // min-height: 500px;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    overflow: hidden;
  }

  &__image {
    height: 300px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 30px 10px;
    margin: 14px 0 0 0;

    &-icon {
      background-color: $purple;
      padding: 10px;
      display: flex;
      align-items: center;
      color: $white;
      border-radius: 3px;
      i {
        margin: 0 5px;
      }
      &--transparent {
        background-color: transparent;
      }
      &--purple {
        margin-right: 10px;
      }
    }
    &-count {
      padding-left: 10px;
    }
  }

  &__heading {
    color: #67247c;
    font-size: 32px;
    font-weight: 500;
    &--inline {
      padding-left: 20px;
    }

    &--error {
      color: red;
    }
  }

  &__icon {
    position: absolute;
    top: 8px;
    left: 8px;
    font-size: 55px;
  }

  &__content {
    height: 100px;
    padding: 16px;
    color: #7b6b80;
    font-weight: 300;
    overflow: hidden;

    &--sentacecase {
      text-transform: capitalize;
    }
  }

  &__actions {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 30px 0 0;
    padding: 10px 30px;
    height: 40px;
    color: #fff;
    background-color: #9352a7;
    border-radius: 0 0 4px 4px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.9;
    }
  }

  &__action-button {
    margin: 0 0 0 20px;
  }

  &__icon {
    &--custom {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 32px;
      height: 32px;
    }
    &--quote {
      background-image: url("../../assets/quote.svg");
    }
    &--club-tv {
      background-image: url("../../assets/video-camera.svg");
    }
    &--youtube {
      background-image: url("../../assets/youtube.svg");
    }
    &--instagram {
      background-image: url("../../assets/instagram.svg");
    }
    &--twitter {
      background-image: url("../../assets/x.svg");
    }
    &--threads {
      background-image: url("../../assets/threads.svg");
    }
    &--trophy {
      background-image: url("../../assets/trophy.svg");
    }
    &--fixtures {
      background-image: url("../../assets/pitch-white.svg");
      margin: 0;
    }
    &--upcoming-fixture {
      background-image: url("../../assets/upcoming-fixtures.svg");
    }
    &--gallery {
      background-image: url("../../assets/gallery-white.svg");
    }
    &--trivia {
      background-image: url("../../assets/trivia.svg");
    }
    &--list {
      background-image: url("../../assets/list.svg");
    }
    &--list-white {
      background-image: url("../../assets/list-white.svg");
    }
    &--video {
      background-image: url("../../assets/video.svg");
    }
    &--custom-code {
      background-image: url("../../assets/custom.svg");
    }
    &--promo {
      background-image: url("../../assets/promotions.svg");
    }
    &--club {
      background-image: url("../../assets/club.svg");
    }
    &--pitch {
      background-image: url("../../assets/pitch.svg");
    }
    &--stats {
      background-image: url("../../assets/statistics.svg");
    }
    &--file {
      background-image: url("../../assets/file.svg");
    }
    &--envelope {
      background-image: url("../../assets/envelope.svg");
    }
    &--sporcle {
      background-image: url("../../assets/trivia.svg");
    }
    &--line {
      background-image: url("../../assets/vertical-align.svg");
    }
    &--buzzsprout {
      background-image: url("../../assets/buzzsprout.svg");
    }
    &--soundcloud {
      background-image: url("../../assets/soundcloud.svg");
    }
    &--map {
      background-image: url("../../assets/map.svg");
    }
    &--360 {
      background-image: url("../../assets/360.svg");
    }
    &--subscriptions {
      background-image: url("../../assets/subscriptions.svg");
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 40px 0 0;

  &__button {
    margin: 0 30px;
  }
}
</style>
