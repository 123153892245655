<template>
  <section class="content-builder">

    <MiniHeader :heading="title" :hasButton="false"/>

    <section class="region">
      <h2 class="region__heading">Content Builder</h2>

      <div class="add-row-wrap">
        <button class="add-row" @click="addFirstRow()">Add row <span class="add-row__icon"><v-icon color="#fff">add</v-icon></span></button>
      </div>

      <transition-group name="list-complete" tag="ol">
        <li v-for="(row, index) in updatedData" :key="row.rowID" class="row-wrap">
          <div class="row">

            <div class="row__move-buttons">
              <button v-if="index !== 0" class="row__move-up" type="button" @click="moveRowUp(index)">
                <v-icon small>keyboard_arrow_up</v-icon>
              </button>

              <button v-if="(index + 1) !== updatedData.length" class="row__move-down" type="button" @click="moveRowDown(index)">
                <v-icon small>keyboard_arrow_down</v-icon>
              </button>
            </div>

            <article v-if="row.rowData" class="widget">

              <WidgetSelector
                v-if="row.rowData.widgetType === 'Empty'"
                class="add-widget"
                @widgetAdded="addWidget($event, index)">

                <v-icon color="#fff" class="add-widget__icon">add</v-icon> <span class="add-widget__text">Add Content</span>
              </WidgetSelector>

              <WidgetPreview
                v-else
                :widgetName="row.rowData.widgetName"
                :widgetType="row.rowData.widgetType"
                :widgetData="row.rowData.widgetData"
                :widgetChannels="row.rowData.widgetChannels"
                :enableTargeting="enableTargeting"
                @edit="editWidget($event, index)"
                @updateChannels="updateChannels($event, index)"
                @delete="deleteRow(index)"
              ></WidgetPreview>

            </article>

            <button class="row__delete" type="button" @click="deleteRow(index)">
              <v-icon small>delete</v-icon>
            </button>

          </div>

          <div class="add-row-wrap">
            <button @click="addRow(index)" class="add-row">Add row <span class="add-row__icon"><v-icon color="#fff">add</v-icon></span></button>
          </div>
        </li>

      </transition-group>
    </section>

  </section>
</template>

<script>
import MiniHeader from "@/components/Library/MiniHeader"
import WidgetPreview from "@/components/Library/WidgetPreview"
import WidgetSelector from "@/components/Library/WidgetSelector"

export default {
  name: "ContentBuilder",

  components: {
    MiniHeader,
    WidgetPreview,
    WidgetSelector
  },

  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    saveData: {
      type: Object,
      required: true
    },
    template: {
      type: String,
      required: false
    },
    enableTargeting: {
      type: Boolean,
      required: true
    }
  },

  data: function () {
    return {
      updatedData: []
    }
  },

  watch: {
    updatedData() {
      console.log("--- Data updated ---");
      this.$emit("update", this.updatedData)
    }
  },

  mounted() {
    console.log("Page Builder mounted with: ", this.data);
    this.updatedData = this.data;
  },

  methods: {
    addFirstRow() {
      const location = this.updatedData;
      const emptyRow = {
        rowID: this.$uuid.v1(),
        rowData: {
          widgetID: this.$uuid.v1(),
          widgetType: "Empty"
        }
      }
      location.unshift(emptyRow);
      this.$emit("statusChange");
      console.log("addFirstRow", this.updatedData);
    },

    addRow(index) {
      const nextIndex = index + 1;
      const location = this.updatedData;
      const emptyRow = {
        rowID: this.$uuid.v1(),
        rowData: {
          widgetID: this.$uuid.v1(),
          widgetType: "Empty"
        }
      }
      location.splice(nextIndex, 0, emptyRow);
      this.$emit("statusChange");
      console.log("addRow", this.updatedData);
    },

    deleteRow(index) {
      console.log("Delete row: ", index);

      if (confirm("Are you sure you want to delete this row?")) {
        const location = this.updatedData;

        location.splice(index, 1);
      }

      this.$emit("statusChange");
    },

    moveRowDown(index) {
      console.log("Move row down: ", index);
      const location = this.updatedData;
      const element = this.updatedData[index];

      location.splice(index, 1);
      location.splice((index + 1), 0, element);
    },

    moveRowUp(index) {
      console.log("Move row up: ", index);

      const location = this.updatedData;
      const element = this.updatedData[index];

      location.splice(index, 1);
      location.splice((index - 1), 0, element);
    },

    addWidget(payload, rowIndex) {
      const location = this.updatedData[rowIndex].rowData;

      location.widgetName = payload.widgetName;
      location.widgetType = payload.widgetType;
      location.widgetData = payload.widgetData;
      this.$emit("statusChange");
    },

    editWidget(payload, rowIndex) {
      console.log("Edit widget:", payload);
      const location = this.updatedData[rowIndex].rowData;
      location.widgetData = payload
      this.$emit("statusChange");
      // Sergie: Some widgetData may not update immediately, the below forces UI updates
      this.$forceUpdate();
    },

    updateChannels(payload, rowIndex) {
      console.log("Update widget channels:", payload);
      const location = this.updatedData[rowIndex].rowData;
      location.widgetChannels = payload
      this.$emit("statusChange");
    },

    deleteWidget(rowIndex) {
      console.log("Delete widget");

      if (confirm("Are you sure you want to delete this widget?")) {
        const location = this.updatedData[rowIndex].rowData;

        location.widgetName = "Widget Removed";
        location.widgetType = "Empty";
        location.widgetData = {};
        this.$emit("statusChange");
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .header {
    margin: 0 0 40px;
    padding: 40px;
    background-color: #ebebeb;
    border: 1px solid rgba(209,216,245,0.6);
    border-radius: 4px;
  }

  .region {
    padding: 40px;
    background-color: #fff;
    border: 1px solid rgba(209,216,245,0.6);
    border-radius: 4px;

    &__heading {
      margin: 0 0 20px;
      color: #67247C;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .row-wrap {
    transition: all 0.5s, opacity 0.2s;
  }

  .row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 40px;

    &__delete {
      position: absolute;
      top: 50%;
      right: -21px;
      transform: translateY(-50%);
      padding: 3px;
      background-color: #ccc;
      border-radius: 0 4px 4px 0;
      opacity: 0;
      pointer-events: none;
      outline: none;
      transition: opacity 0.3s ease-in-out;
      z-index: 1;
    }

    &__move-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 10px 0 -26px;
      height: 60px;
    }

    &__move-up {
      margin: 0 0 auto;
    }

    &__move-down {
      margin: auto 0 0;
    }
  }

  .row:hover .row__delete {
    opacity: 1;
    pointer-events: auto;
  }

  .add-row-wrap {
    width: 100%;
  }

  .add-row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0 0 40px;
    width: 100%;
    color: #959595;

    &:before {
      content: '';
      position: absolute;
      top: 17px;
      right: 120px;
      left: 0;
      border-bottom: 1px dashed #959595;
    }

    &__icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 10px;
      height: 34px;
      width: 34px;
      background-color: #959595;
      border-radius: 2px;
    }
  }

  .add-row:hover {
    color: #67247C;

    .add-row:before {
      border-bottom-color: #B391BD;
    }

    .add-row__icon {
      background-color: #67247C;
    }
  }

  .add-widget {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
    margin: 20px 0 0;
    padding: 30px 0;
    min-height: 240px;
    text-align: center;
    color: #67247C;
    text-align: center;
    background-color: #F9F9F9;
    border: 1px dashed #DFE3E9;
    border-radius: 4px;

    &__text {
      display: block;
      margin: 8px 0 0;
      font-weight: 500;
    }

    &__icon {
      display: block;
      margin: auto;
      height: 51px;
      width: 51px;
      font-size: 40px;
      line-height: 51px;
      background-color: #67247C;
      border-radius: 50%;
    }
  }

  .widget {
    flex-grow: 1;
    flex-basis: 0;
    margin: 0;

    &:nth-child(3) {
      margin-left: 40px;
    }

    &__edit,
    &__delete {
      margin: 30px 20px;
    }
  }
</style>
