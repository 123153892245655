<template>
  <v-dialog
    class="unique-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>
      <v-form enctype="multipart/form-data" ref="uniqueWidgetForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            class="widget__heading"
          >
            Unique Widget
          </v-card-title>

          <v-card-text>
            <p class="widget__description">Select a Unique Widget</p>

            <label class="label" for="widget-select">Widgets available</label>
            <v-select
              id="widget-select"
              solo
              flat
              required
              :rules="[rules.required]"
              v-model="updatedData.content"
              :items="uniqueWidgets"
              item-text="name"
              item-value="widgetID"
              validate-on-blur
            ></v-select>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="accent"
              outline
              flat
              @click="validate()"
            >
              Post
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "UniqueWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    dialog: false,
    valid: false,
    rules: {
      required: value => !!value || "Required."
    },
    uniqueWidgets: [],
    updatedData: {
      content: null
    }
  }),

  created() {
    const widgetsConfig = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/widgets.js")

    console.log("widgetsConfig", widgetsConfig)

    this.uniqueWidgets = widgetsConfig.default.UniqueWidgetList
  },

  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
    }
  },

  methods: {
    updateData() {
      console.log("Update data:", this.updatedData);

      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
    },

    validate() {
      this.errorMessage = ""
      if (this.$refs.uniqueWidgetForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .widget {
    &__description {
      margin: 0 0 20px;
      color: #7B6B80;
      font-size: 14px;
    }
  }
</style>
