<template>
    <section class="news-editor">
        <p
            v-if="isAltLang"
            class="alt-lang-notice"
        >
            This post is marked as being written for the
            <i><u>{{ isAltLang }}</u></i>
            category and should be written in the relevant language
        </p>

        <v-tabs v-model="active">
            <v-tab :ripple="{ class: 'accent--text' }">Configuration</v-tab>
            <v-tab-item
                px-0
                class="main-body"
            >
                <NewsConfiguration
                    v-if="dataLoaded"
                    :data="configData"
                    :saveData="saveData"
                    :livePagePublishedDate="livePagePublishedDate"
                    :categories="categoriesList"
                    @update="updateConfigData($event)"
                    @updateCategoryLanguage="updateCategoryLanguage($event)"
                />

                <p
                    v-if="editing.inProgress"
                    class="editor-notice"
                >
                    This post is being editing by {{ editing.editorName }} (<a :href="`mailto:${editing.editorEmail}`">{{ editing.editorEmail
                    }}</a>) at {{ editing.startedEditingDateTime | moment("kk:mm DD/MM/YY") }}
                </p>
            </v-tab-item>

            <v-tab :ripple="{ class: 'accent--text' }">SEO</v-tab>
            <v-tab-item
                px-0
                class="main-body"
            >
                <EditSEO
                    v-if="dataLoaded"
                    :data="seoData"
                    :saveData="saveData"
                    @update="updateSeoData($event)"
                />
            </v-tab-item>

            <v-tab :ripple="{ class: 'accent--text' }">Content</v-tab>
            <v-tab-item
                px-0
                class="main-body"
            >
                <ContentBuilder
                    v-if="dataLoaded"
                    :data="content"
                    :title="configData.postTitle"
                    :saveData="saveData"
                    :enableTargeting="true"
                    @update="updateContentData($event)"
                    @statusChange="status = 'needsSaving'"
                />
            </v-tab-item>

            <template v-if="modules.default.Voice">
                <v-tab :ripple="{ class: 'accent--text' }">Voice</v-tab>
                <v-tab-item
                    px-0
                    class="main-body"
                >
                    <EditVoice
                        v-if="dataLoaded"
                        :data="voiceData"
                        :saveData="saveData"
                        @update="updateVoiceData($event)"
                    />
                </v-tab-item>
            </template>

            <template v-if="modules.default.RelatedContentTagging">
                <v-tab :ripple="{ class: 'accent--text' }">Tags</v-tab>

                <v-tab-item
                    px-0
                    class="main-body"
                >
                    <v-layout :class="'tags__layout'">
                        <div :class="'tags__section'">
                            <div class="flex listings__title">
                                <MiniHeader
                                    :heading="'Players'"
                                    :buttonText="'Add player tag'"
                                    :hasButton="true"
                                    @buttonClicked="
                                        (addPlayerTagDialog = true),
                                        (playerDataTag.id = null),
                                        (playerDataTag.name = null)
                                        "
                                />
                                <NewsTags
                                    :tagsData="tagsData.playerTags"
                                    @delete="deletePlayerTag"
                                />
                            </div>
                        </div>
                        <div :class="'tags__section'">
                            <div class="flex listings__title">
                                <MiniHeader
                                    :heading="'Squads'"
                                    :buttonText="'Add squad tag'"
                                    :hasButton="true"
                                    @buttonClicked="
                                        (addSquadTagDialog = true),
                                        (squadDataTag.id = null),
                                        (squadDataTag.name = null)
                                        "
                                />
                                <NewsTags
                                    v-if="tagsData.squadTags.length"
                                    :tagsData="tagsData.squadTags"
                                    @delete="deleteSquadTag"
                                />
                            </div>
                        </div>
                        <div :class="'tags__section'">
                            <div class="flex listings__title">
                                <MiniHeader
                                    :heading="'Fixtures'"
                                    :buttonText="'Add fixture tag'"
                                    :hasButton="true"
                                    @buttonClicked="
                                        (addFixtureTagDialog = true),
                                        (fixtureDataTag.id = null),
                                        (fixtureDataTag.name = null),
                                        (seasons = []),
                                        (singleFixtureListData.season = null),
                                        (competitions = []),
                                        (matches = []),
                                        (singleFixtureListData.squad = null)
                                        "
                                />
                                <NewsTags
                                    v-if="tagsData.fixtureTags.length"
                                    :tagsData="tagsData.fixtureTags"
                                    @delete="deleteFixtureTag"
                                />
                            </div>
                        </div>

                        <v-layout
                            row
                            justify-center
                        >
                            <v-dialog
                                v-model="addPlayerTagDialog"
                                max-width="580"
                            >
                                <v-card>
                                    <v-card-title class="headline">Add player tag</v-card-title>
                                    <v-card-text>
                                        <v-form
                                            enctype="multipart/form-data"
                                            v-model="addPlayerTagValid"
                                            ref="addPlayerTagForm"
                                            lazy-validation
                                        >
                                            <label
                                                class="label"
                                                for="squads"
                                            >Select Squad</label>
                                            <v-select
                                                v-if="tagsConfigData.squads"
                                                id="squads"
                                                :items="tagsConfigData.squads"
                                                item-text="name"
                                                item-value="id"
                                                :disabled="!tagsConfigData.squads"
                                                v-model="playerDataTag.squad"
                                                outline
                                                required
                                                :rules="[rules.required]"
                                                validate-on-blur
                                                label="Please select a squad"
                                                @change="
                                                    updateSquadTag($event);
                                                getPlayers(playerDataTag.squad);
                                                "
                                            ></v-select>

                                            <label
                                                class="label"
                                                for="player"
                                            >Select Player</label>
                                            <v-select
                                                v-if="tagsConfigData.players"
                                                id="player"
                                                :items="tagsConfigData.players"
                                                item-text="name"
                                                item-value="id"
                                                :disabled="!playerDataTag.squad"
                                                v-model="playerDataTag.id"
                                                outline
                                                required
                                                :rules="[rules.required]"
                                                validate-on-blur
                                                label="Please select a player"
                                                @change="updatePlayerTag($event)"
                                            ></v-select>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            depressed
                                            @click="addPlayerTagDialog = false"
                                        >Cancel</v-btn>
                                        <v-btn
                                            depressed
                                            :disabled="!playerDataTag.id"
                                            color="accent"
                                            @click="(addPlayerTagDialog = false), addPlayerTag()"
                                        >
                                            Add Tag
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <v-dialog
                                v-model="addSquadTagDialog"
                                max-width="580"
                            >
                                <v-card>
                                    <v-card-title class="headline">Add squad tag</v-card-title>
                                    <v-card-text>
                                        <v-form
                                            enctype="multipart/form-data"
                                            v-model="addSquadTagValid"
                                            ref="addSquadTagForm"
                                            lazy-validation
                                        >
                                            <label
                                                class="label"
                                                for="squads"
                                            >Select Squad</label>
                                            <v-select
                                                v-if="tagsConfigData.squads"
                                                id="squads"
                                                :items="tagsConfigData.squads"
                                                item-text="name"
                                                item-value="id"
                                                :disabled="!tagsConfigData.squads"
                                                v-model="squadDataTag.id"
                                                outline
                                                required
                                                :rules="[rules.required]"
                                                validate-on-blur
                                                label="Please select a squad"
                                                @change="updateSquadTag($event)"
                                            ></v-select>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            depressed
                                            @click="addSquadTagDialog = false"
                                        >Cancel</v-btn>
                                        <v-btn
                                            depressed
                                            :disabled="!squadDataTag.id"
                                            color="accent"
                                            @click="(addSquadTagDialog = false), addSquadTag()"
                                        >
                                            Add Tag
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <v-dialog
                                v-model="addFixtureTagDialog"
                                max-width="580"
                            >
                                <v-card>
                                    <v-card-title class="headline">Add fixture tag</v-card-title>
                                    <v-card-text>
                                        <v-form
                                            enctype="multipart/form-data"
                                            v-model="addFixtureTagValid"
                                            ref="addFixtureTagForm"
                                            lazy-validation
                                        >
                                            <match-picker @updated="getFixtures($event)" />
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            depressed
                                            @click="addFixtureTagDialog = false"
                                        >Cancel</v-btn>
                                        <v-btn
                                            depressed
                                            :disabled="!fixtureDataTag.id"
                                            color="accent"
                                            @click="(addFixtureTagDialog = false), addFixtureTag()"
                                        >
                                            Add Tag
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-layout>
                    </v-layout>
                </v-tab-item>
            </template>

            <template v-if="advancedMode">
                <v-tab :ripple="{ class: 'accent--text' }">Advanced Mode</v-tab>
                <v-tab-item
                    px-0
                    class="main-body"
                >
                    <h3 class="subheading">JSON content entry</h3>
                    <section class="section-container">
                        <v-layout row>
                            <v-flex
                                mr-5
                                class="half"
                            >
                                <label
                                    class="label"
                                    for="config-data"
                                >configData*</label>
                                <v-textarea
                                    id="config-data"
                                    v-model="configDataString"
                                    solo
                                    flat
                                    @change="updateAdvanced()"
                                ></v-textarea>
                            </v-flex>
                            <v-flex
                                mt-4
                                class="half"
                            >
                                <p><i>*Required - configData object as JSON</i></p>
                            </v-flex>
                        </v-layout>

                        <v-layout row>
                            <v-flex
                                mr-5
                                class="half"
                            >
                                <label
                                    class="label"
                                    for="seo-data"
                                >seoData*</label>
                                <v-textarea
                                    id="seo-data"
                                    v-model="seoDataString"
                                    solo
                                    flat
                                    @change="updateAdvanced()"
                                ></v-textarea>
                            </v-flex>
                            <v-flex
                                mt-4
                                class="half"
                            >
                                <p><i>*Required - seoData object as JSON</i></p>
                            </v-flex>
                        </v-layout>

                        <v-layout row>
                            <v-flex
                                mr-5
                                class="half"
                            >
                                <label
                                    class="label"
                                    for="content-data"
                                >content*</label>
                                <v-textarea
                                    id="content-data"
                                    v-model="contentString"
                                    solo
                                    flat
                                    @change="updateAdvanced()"
                                ></v-textarea>
                            </v-flex>
                            <v-flex
                                mt-4
                                class="half"
                            >
                                <p><i>*Required - configData object as JSON</i></p>
                            </v-flex>
                        </v-layout>
                    </section>
                </v-tab-item>
            </template>
            <v-tab
                v-if="configData.postStatus === 'Published'"
                :ripple="{ class: 'accent--text' }"
            >Analytics</v-tab>
            <v-tab-item
                px-0
                class="main-body"
            >
                <fathom-page-analytics :path="configData.postSlug.substring(1)" />
            </v-tab-item>
        </v-tabs>

        <v-dialog
            v-if="editing"
            v-model="dialogEditing"
            persistent
            max-width="360"
        >
            <v-card>
                <v-card-title class="headline">
                    <v-icon class="warning-icon">warning_amber</v-icon>
                    <span>This post is being edited</span>
                </v-card-title>
                <v-card-text>
                    <p>
                        This post is open and being edited by {{ editing.editorName }} (<a :href="`mailto:${editing.editorEmail}`">{{
                            editing.editorEmail }}</a>) at
                        {{ editing.startedEditingDateTime | moment("kk:mm DD/MM/YY") }}.
                    </p>
                    <p>
                        This could mean it's still being edited, or that someone has left
                        the edit screen open.
                    </p>
                    <p>If you continue, yours or their <b>data may be lost</b>.</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        depressed
                        @click="
                            dialogEditing = false;
                        startedEditing();
                        "
                    >Continue anyway</v-btn>
                    <v-btn
                        depressed
                        color="accent"
                        @click="$router.push('/news')"
                    >Go back</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <StickyFooter
            :errorMessage="errorMessage"
            :status="status"
            :buttonText="'Save and Close'"
            :secondaryButtonText="'Save and Continue'"
            @buttonClicked="savePost(true)"
            @secondaryButtonClicked="savePost(false)"
            :allowPreview="true"
            @previewRequested="previewPost()"
        />
    </section>
</template>

<script>
import axios from "axios";
import ContentBuilder from "@/components/Library/ContentBuilder";
import EditSEO from "@/components/Library/EditSEO";
import FathomPageAnalytics from "@/components/Library/Fathom/FathomPageAnalytics";
import EditVoice from "@/components/News/EditVoice";
import moment from "moment";
import MatchPicker from "../Library/MatchPicker.vue";
import MiniHeader from "@/components/Library/MiniHeader";
import NewsConfiguration from "@/components/News/NewsConfiguration";
import NewsTags from "@/components/News/NewsTags";
import StickyFooter from "@/components/Library/StickyFooter";
import * as Sentry from "@sentry/browser";

export default {
    name: "NewsContent",

    components: {
        ContentBuilder,
        EditSEO,
        FathomPageAnalytics,
        MatchPicker,
        MiniHeader,
        NewsConfiguration,
        NewsTags,
        StickyFooter,
        EditVoice,
    },

    data: () => ({
        modules: null,
        api: `${process.env.VUE_APP_NEWSAPI}/news`,
        newsApiV2: `${process.env.VUE_APP_NEWSAPI_V2}`,
        apiFixtures: `${process.env.VUE_APP_BASEURL}/fixtures`,
        apiCategories: `${process.env.VUE_APP_NEWSAPI}/newscategories?pageSize=50`,
        preview: `${process.env.VUE_APP_NEWSAPI}/news/sendintopreview`,
        status: "saved",
        errorMessage: "",
        active: null,
        dataLoaded: false,
        isAltLang: false,
        categoriesList: [],
        postID: null,
        featured: undefined,
        featuredOrder: undefined,
        advancedMode: false,
        configDataString: null,
        seoDataString: null,
        contentString: null,
        livePagePublishedDate: "",
        dialogEditing: false,
        isEditingOwner: false,
        editing: {
            editorEmail: "",
            editorName: "",
            inProgress: "",
            startedEditingDateTime: "",
        },
        configData: {
            postTitle: "",
            postAuthor: null,
            postStatus: "Draft",
            postSlug: "",
            postCategory: null,
            postIcon: null,
            breakingNews: 0,
            breakingNewsEnd: "",
            heroStyleLarge: "Deep",
            heroStyleSmall: "Deep",
            mediaLibraryID: null,
            heroSmallMediaLibraryID: null,
            listingMediaLibraryID: null,
            publishedDateTime: "",
            scheduled: 0,
            unschedule: 0,
            unscheduleDateTime: "",
            childFriendly: false,
            disableAdvertising: false,
            showInFeed: 0,
            feedWidth: "single",
            cardStyle: "",
            sendToOneFootball: false,
        },
        seoData: {
            metaTitle: null,
            keywords: null,
            description: null,
        },
        voiceData: {
            hideFromVoice: false,
            textToVoice: null,
        },
        saveData: {
            author: "AUTHOR NOT SET",
            authorImage: "/favicon-32x32.png",
            lastSave: null,
        },
        rules: {
            required: (value) => !!value || "Required.",
        },
        content: [],
        addPlayerTagDialog: false,
        addPlayerTagValid: false,
        addSquadTagDialog: false,
        addSquadTagValid: false,
        addFixtureTagDialog: false,
        addFixtureTagValid: false,
        tagsConfigData: {
            squads: [],
            players: [],
        },
        playerDataTag: {
            squad: null,
            id: null,
            name: null,
        },
        squadDataTag: {
            id: null,
            name: null,
        },
        singleFixtureListData: {
            squad: null,
            season: null,
            competition: null,
        },
        squads: [],
        seasons: [],
        competitions: [],
        matches: [],
        customMatches: [],
        matchesMerged: [],
        fixtureDataTag: {
            id: null,
            name: null,
        },
        tagsData: {
            playerTags: [],
            squadTags: [],
            fixtureTags: [],
        },
        // defaultShowInFeed: null,
        defaultSettings: null,
    }),

    created() {
        this.modules = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");
        this.defaultSettings = require("../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/defaultSettings.js");
    },

    mounted() {
        this.$store.commit("completeLoading");

        const userCreds = this.$store.state.user.email.split("@");
        if (userCreds[1] === "urbanzoo.io") {
            this.advancedMode = true;
        }

        if (this.$route.params.id != "create-post") {
            this.fetchPage();
        } else {
            this.dataLoaded = true;
        }

        this.fetchCategories();
        this.getSquads();
        // this.getFixtures();

        this.configData.showInFeed =
            this.defaultSettings.default.aggregatedFeed.showAllNews;
        this.configData.cardStyle =
            this.defaultSettings.default.defaultNewsCardStyle;
    },

    beforeRouteLeave(to, from, next) {
        if (this.dialogEditing) {
            // If editing dialog is open assume they are leaving immediately
            next();
        } else if (this.isEditingOwner) {
            console.log("finishedEditing before route change");
            // If editing dialog is closed clear edit data when leaving
            this.finishedEditing().then(() => {
                next();
            });
        } else {
            next();
        }
    },

    // beforeDestroy() {
    //   if (!this.dialogEditing) {
    //     this.clearEditing()
    //   }
    // },

    watch: {
        data: function () {
            this.status = "needsSaving";
        },
    },

    methods: {
        startedEditing() {
            this.isEditingOwner = true;

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: `${this.newsApiV2}/news/edit-in-progress/${this.$route.params.id}`,
                    headers: { Authorization: jwt },
                    data: {
                        data: {
                            type: "news",
                            id: `${this.$route.params.id}`,
                            attributes: {
                                editing: {
                                    inProgress: 1,
                                    editorName: `${this.$store.state.user.firstName} ${this.$store.state.user.lastName}`,
                                    editorEmail: this.$store.state.user.email,
                                    startedEditingDateTime: moment().utc().format(),
                                },
                            },
                        },
                    },
                })
                    .then((response) => {
                        console.log("Started editing", response);

                        this.editing = {
                            editorEmail: response.data.data.attributes.editing.editorEmail,
                            editorName: response.data.data.attributes.editing.editorName,
                            inProgress: response.data.data.attributes.editing.inProgress,
                            startedEditingDateTime:
                                response.data.data.attributes.editing.startedEditingDateTime,
                        };
                    })
                    .catch((e) => {
                        console.error("Problem updating edit state", e);
                    });
            });
        },

        finishedEditing() {
            return new Promise((resolve, reject) => {
                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "PATCH",
                        url: `${this.newsApiV2}/news/edit-in-progress/${this.$route.params.id}`,
                        headers: { Authorization: jwt },
                        data: {
                            data: {
                                type: "news",
                                id: `${this.$route.params.id}`,
                                attributes: {
                                    editing: {
                                        inProgress: 0,
                                        editorName: `${this.$store.state.user.firstName} ${this.$store.state.user.lastName}`,
                                        editorEmail: this.$store.state.user.email,
                                        startedEditingDateTime: moment().utc().format(),
                                    },
                                },
                            },
                        },
                    })
                        .then((response) => {
                            console.log("Finished editing", response);
                            resolve("Finished editing");
                        })
                        .catch((e) => {
                            console.error("Problem updating edit state", e);
                            Sentry.captureMessage("Problem finishing editing");
                            resolve("Problem finishing editing");
                        });
                });
            });
        },

        basicValidation() {
            console.log(
                "Basic validation",
                this.configData.postTitle,
                this.configData.postCategory,
                this.configData.publishedDateTime
            );

            // If not a scheduled post AND not got a published date and time AND is published
            if (
                !this.configData.scheduled &&
                !this.configData.publishedDateTime &&
                this.configData.publishedDateTime == ""
            ) {
                this.createPublishNowDate();
            }

            if (
                this.configData.postTitle == null ||
                this.configData.postTitle == "" ||
                this.configData.postCategory == null
            ) {
                alert("Please enter a title and category");
                return false;
            } else {
                if (this.configData.publishedDateTime == "") {
                    alert(
                        "There is no published date. A published date must be set before a post can be saved"
                    );
                    return false;
                } else {
                    return true;
                }
            }
        },

        createPublishNowDate() {
            this.configData.publishedDateTime = moment().utc().format();
            console.log("Create Publish Now Date", this.configData.publishedDateTime);
        },

        fetchPage() {
            this.errorMessage = "";
            this.$store.commit("startLoading");

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios
                    .get(`${this.api}/${this.$route.params.id}`, {
                        headers: { Authorization: jwt },
                    })
                    .then((response) => {
                        console.log("Fetching page:", response);
                        this.postID = response.data.body.postID;
                        this.featured = response.data.body.featured;
                        this.featuredOrder = response.data.body.featuredOrder;

                        if (response.data.body.editing) {
                            this.editing = {
                                editorEmail: response.data.body.editing.editorEmail,
                                editorName: response.data.body.editing.editorName,
                                inProgress: response.data.body.editing.inProgress,
                                startedEditingDateTime:
                                    response.data.body.editing.startedEditingDateTime,
                            };
                        }

                        this.configData = {
                            postTitle: response.data.body.postTitle,
                            postAuthor: response.data.body.postAuthor,
                            postSlug: response.data.body.postSlug,
                            postSlugSuffix: response.data.body.postSlugSuffix,
                            postStatus: response.data.body.postStatus,
                            postCategory: response.data.body.postCategory,
                            postIcon: response.data.body.postIcon,
                            breakingNews: response.data.body.breakingNews,
                            breakingNewsEnd: response.data.body.breakingNewsEnd,
                            heroStyleLarge: response.data.body.heroStyleLarge,
                            heroStyleSmall: response.data.body.heroStyleSmall,
                            postSummary: response.data.body.postSummary,
                            publishedDateTime: response.data.body.publishedDateTime,
                            scheduled: response.data.body.scheduled,
                            unschedule: response.data.body.unschedule,
                            unscheduleDateTime: response.data.body.unscheduleDateTime,
                            mediaLibraryID: response.data.body.mediaLibraryID,
                            heroSmallMediaLibraryID:
                                response.data.body.heroSmallMediaLibraryID,
                            listingMediaLibraryID: response.data.body.listingMediaLibraryID,
                            childFriendly: response.data.body.childFriendly,
                            disableAdvertising: response.data.body.disableAdvertising,
                            showInFeed: response.data.body.showInFeed,
                            feedWidth: response.data.body.feedWidth,
                            cardStyle: response.data.body.cardStyle,
                            sendToOneFootball: response.data.body.sendToOneFootball ? 1 : 0,
                        };

                        this.seoData = {
                            metaTitle: response.data.body.metaTitle,
                            keywords: response.data.body.keywords,
                            description: response.data.body.description,
                        };

                        this.voiceData = {
                            hideFromVoice: response.data.body.hideFromVoice,
                            textToVoice: response.data.body.textToVoice,
                        };

                        this.tagsData = response.data.body.tagsData;

                        this.saveData = {
                            lastSave: response.data.body.savedTimestamp,
                        };

                        this.content = response.data.body.content;

                        if (this.content === undefined) {
                            this.content = [];
                        }

                        if (this.configData.postStatus === "Published") {
                            // Set copy of published date if page is live
                            this.livePagePublishedDate = JSON.parse(
                                JSON.stringify(this.configData.publishedDateTime)
                            );
                        }

                        if (this.configData) {
                            this.configDataString = JSON.stringify(this.configData);
                        }

                        if (this.seoData) {
                            this.seoDataString = JSON.stringify(this.seoData);
                        }

                        if (this.content) {
                            this.contentString = JSON.stringify(this.content);
                        }

                        if (this.editing.inProgress) {
                            this.dialogEditing = true;
                        } else {
                            this.startedEditing();
                        }

                        this.dataLoaded = true;
                        this.$store.commit("completeLoading");
                    })
                    .catch((e) => {
                        console.error("Problem retrieving post: ", e);
                        this.$store.commit("completeLoading");
                        this.errorMessage = "Problem retrieving post";
                    });
            });
        },

        savePost(leavePost) {
            if (this.basicValidation()) {
                this.status = "saving";
                this.errorMessage = "";
                console.log("saving news: ", this.postID, this.configData);

                if (this.postID !== null) {
                    this.$getCurrentUserJwToken().subscribe((jwt) => {
                        axios({
                            method: "POST",
                            url: `${this.api}`,
                            headers: { Authorization: jwt },
                            data: {
                                postID: this.postID,
                                postTitle: this.configData.postTitle,
                                postSlug: this.configData.postSlug,
                                postSlugSuffix: this.configData.postSlugSuffix,
                                postAuthor:
                                    this.configData.postAuthor != ""
                                        ? this.configData.postAuthor
                                        : null,
                                postSummary:
                                    this.configData.postSummary != ""
                                        ? this.configData.postSummary
                                        : null,
                                postCategory: this.configData.postCategory,
                                postIcon: this.configData.postIcon,
                                breakingNews: this.configData.breakingNews,
                                breakingNewsEnd: this.configData.breakingNewsEnd,
                                heroStyleLarge: this.configData.heroStyleLarge,
                                heroStyleSmall: this.configData.heroStyleSmall,
                                postStatus: this.configData.postStatus,
                                publishedDateTime: this.configData.publishedDateTime,
                                scheduled: this.configData.scheduled,
                                unschedule: this.configData.unschedule
                                    ? this.configData.unschedule
                                    : 0,
                                unscheduleDateTime: this.configData.unscheduleDateTime
                                    ? this.configData.unscheduleDateTime
                                    : null,
                                mediaLibraryID:
                                    this.configData.mediaLibraryID != ""
                                        ? this.configData.mediaLibraryID
                                        : null,
                                heroSmallMediaLibraryID:
                                    this.configData.heroSmallMediaLibraryID != ""
                                        ? this.configData.heroSmallMediaLibraryID
                                        : null,
                                listingMediaLibraryID:
                                    this.configData.listingMediaLibraryID != ""
                                        ? this.configData.listingMediaLibraryID
                                        : null,
                                childFriendly: this.configData.childFriendly,
                                disableAdvertising: this.configData.disableAdvertising,
                                keywords:
                                    this.seoData.keywords != "" ? this.seoData.keywords : null,
                                description:
                                    this.seoData.description != ""
                                        ? this.seoData.description
                                        : null,
                                metaTitle:
                                    this.seoData.metaTitle != "" ? this.seoData.metaTitle : null,
                                hideFromVoice: this.voiceData.hideFromVoice,
                                textToVoice:
                                    this.voiceData.textToVoice != ""
                                        ? this.voiceData.textToVoice
                                        : null,
                                content: this.content,
                                tagsData: this.tagsData != "" ? this.tagsData : null,
                                featured: this.featured,
                                featuredOrder: this.featuredOrder,
                                showInFeed: this.configData.showInFeed,
                                feedWidth: this.configData.feedWidth,
                                cardStyle: this.configData.cardStyle,
                                sendToOneFootball: this.configData.sendToOneFootball ? 1 : 0,
                            },
                        })
                            .then((response) => {
                                console.log("Saved post:", response);

                                if (response.data.success) {
                                    this.status = "saved";
                                    this.errorMessage = "";
                                    // this.configData.postSlug = response.data.body.postSlug;
                                    if (leavePost) {
                                        this.$router.push({ path: "/news" });
                                    }
                                } else if (
                                    response.data.errorMessage === "Existing breaking news"
                                ) {
                                    this.status = "needsSaving";
                                    this.errorMessage = "A breaking news article already exists";
                                    Sentry.captureMessage(
                                        "Problem saving post [error: Mendelevium]"
                                    );
                                } else if (
                                    response.data.message.includes("Post slug already in use")
                                ) {
                                    this.status = "needsSaving";
                                    this.errorMessage = response.data.message;
                                    Sentry.captureMessage(
                                        "Duplicate post slug [error: Californium]"
                                    );
                                } else if (
                                    response.data.message.includes("Invalid post slug format")
                                ) {
                                    this.status = "needsSaving";
                                    this.errorMessage = response.data.message;
                                    Sentry.captureMessage("Invalid post slug [error: Krypton]");
                                } else {
                                    console.error("Problem saving post");
                                    this.status = "needsSaving";
                                    this.errorMessage = "Problem saving post [error: Beryllium]";
                                    Sentry.captureMessage(
                                        "Problem saving post [error: Beryllium]"
                                    );
                                }
                            })
                            .catch((e) => {
                                console.error("Problem saving post", e);
                                this.status = "needsSaving";
                                this.errorMessage = "Problem saving post [error: Beryllium]";
                                Sentry.captureMessage("Problem saving post [error: Beryllium]");
                            });
                    });
                } else {
                    this.createPost(leavePost);
                }
            }
        },

        createPost(leavePost) {
            console.log("Create post", this.seoData);
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "POST",
                    url: `${this.api}`,
                    headers: { Authorization: jwt },
                    data: {
                        postTitle: this.configData.postTitle,
                        postAuthor:
                            this.configData.postAuthor != ""
                                ? this.configData.postAuthor
                                : null,
                        postStatus: this.configData.postStatus,
                        postSlugSuffix: this.isAltLang
                            ? null
                            : this.configData.postSlugSuffix,
                        postSummary:
                            this.configData.postSummary != ""
                                ? this.configData.postSummary
                                : null,
                        postCategory: this.configData.postCategory,
                        postIcon: this.configData.postIcon,
                        breakingNews: this.configData.breakingNews,
                        breakingNewsEnd: this.configData.breakingNewsEnd,
                        heroStyleLarge: this.configData.heroStyleLarge,
                        heroStyleSmall: this.configData.heroStyleSmall,
                        mediaLibraryID:
                            this.configData.mediaLibraryID != ""
                                ? this.configData.mediaLibraryID
                                : null,
                        heroSmallMediaLibraryID:
                            this.configData.heroSmallMediaLibraryID != ""
                                ? this.configData.heroSmallMediaLibraryID
                                : null,
                        listingMediaLibraryID:
                            this.configData.listingMediaLibraryID != ""
                                ? this.configData.listingMediaLibraryID
                                : null,
                        publishedDateTime: this.configData.publishedDateTime,
                        scheduled: this.configData.scheduled,
                        unschedule: this.configData.unschedule
                            ? this.configData.unschedule
                            : 0,
                        unscheduleDateTime: this.configData.unscheduleDateTime
                            ? this.configData.unscheduleDateTime
                            : null,
                        childFriendly: this.configData.childFriendly,
                        disableAdvertising: this.configData.disableAdvertising,
                        keywords:
                            this.seoData.keywords != "" ? this.seoData.keywords : null,
                        description:
                            this.seoData.description != "" ? this.seoData.description : null,
                        metaTitle:
                            this.seoData.metaTitle != "" ? this.seoData.metaTitle : null,
                        hideFromVoice: this.voiceData.hideFromVoice,
                        textToVoice:
                            this.voiceData.textToVoice != ""
                                ? this.voiceData.textToVoice
                                : null,
                        content: this.content,
                        tagsData: this.tagsData != "" ? this.tagsData : null,
                        showInFeed: this.configData.showInFeed,
                        feedWidth: this.configData.feedWidth,
                        cardStyle: this.configData.cardStyle,
                        sendToOneFootball: this.configData.sendToOneFootball ? 1 : 0,
                    },
                })
                    .then((response) => {
                        if (response.data.success) {
                            console.log("Saved post:", response);
                            const guid = response.data.guid;
                            this.postID = guid;

                            if (leavePost) {
                                this.$router.push({ path: "/news" });
                            } else {
                                if (guid) {
                                    this.$router.push({ path: `/news/${response.data.guid}` });
                                }
                            }
                            this.status = "saved";
                        } else if (
                            response.data.message.includes("Post slug already in use")
                        ) {
                            this.status = "needsSaving";
                            this.errorMessage = "Amend Post slug and resave";
                            Sentry.captureMessage("Duplicate post slug [error: Californium]");
                        }
                    })
                    .catch((e) => {
                        console.error("Problem creating post", e);
                        this.status = "needsSaving";
                        this.errorMessage = "Problem creating post [error: Magnesium]";
                        Sentry.captureMessage("Problem creating post [error: Magnesium]");
                    });
            });
        },

        updateCategoryLanguage(payload) {
            this.isAltLang = payload;
        },

        updateConfigData(payload) {
            console.log("updateConfigData: ", payload);

            this.status = "needsSaving";
            this.configData = payload;

            this.configDataString = JSON.stringify(this.configData, null, 4);
        },

        updateSeoData(payload) {
            console.log("updateSeoData: ", payload);

            this.status = "needsSaving";
            this.seoData = payload;

            this.seoDataString = JSON.stringify(this.seoData, null, 4);
        },

        updateVoiceData(payload) {
            console.log("updateVoiceData: ", payload);

            this.status = "needsSaving";
            this.voiceData = payload;
        },

        updateContentData(payload) {
            console.log("updateContentData: ", payload);

            this.status = "needsSaving";
            this.content = payload;

            this.contentString = JSON.stringify(this.content, null, 4);
        },

        updateAdvanced() {
            console.log("updateAdvanced");
            if (this.configDataString) {
                this.configData = JSON.parse(this.configDataString);
            }

            if (this.seoDataString) {
                this.seoData = JSON.parse(this.seoDataString);
            }

            if (this.contentString) {
                this.content = JSON.parse(this.contentString);
            }

            this.status = "needsSaving";
        },

        getSquads() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                this.$store.commit("startLoading");
                axios
                    .get(`${this.apiFixtures}/opta/filters`, {
                        headers: { Authorization: jwt },
                    })
                    .then((response) => {
                        const optaData = response.data.body;
                        this.tagsConfigData.squads = optaData.map((item) => {
                            return {
                                id: item.teamID,
                                name: item.teamName,
                                seasons: item.seasons,
                            };
                        });
                        console.log(this.tagsConfigData.squads);
                        this.$store.commit("completeLoading");
                    })
                    .catch((e) => {
                        console.error("Problem retrieving fixtures", e);
                        this.$store.commit("completeLoading");
                    });
            });
        },

        getPlayers(teamID) {
            console.log("getPlayers");
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                this.tagsConfigData.players = [];
                axios
                    .get(
                        `${process.env.VUE_APP_WEBAPI}/squads/opta/basic?teamID=${teamID}`,
                        { headers: { Authorization: jwt } }
                    )
                    .then((response) => {
                        console.log("response:", response);
                        const players = response.data.body;
                        this.tagsConfigData.players = players.map((player) => {
                            return {
                                id: player.playerID,
                                name: player.firstName + " " + player.surname,
                            };
                        });
                        console.log("Players", this.tagsConfigData.players);
                    })
                    .catch((e) => {
                        console.log("Unable to load players:", e);
                    });
            });
        },

        updatePlayerTag(e) {
            this.tagsConfigData.players.filter((player) => {
                if (player.id === e) {
                    this.playerDataTag.name = player.name;
                }
            });
        },

        updateSquadTag(e) {
            this.tagsConfigData.squads.filter((squad) => {
                if (squad.id === e) {
                    this.squadDataTag.name = squad.name;
                }
            });
        },

        addPlayerTag() {
            const duplicate = this.tagsData.playerTags.filter(
                (player) => player.id === this.playerDataTag.id
            );
            if (!duplicate.length) {
                const data = JSON.parse(JSON.stringify(this.playerDataTag));
                this.tagsData.playerTags.push(data);
                console.log(this.tagsData.playerTags);

                this.status = "needsSaving";
            }
        },

        addSquadTag() {
            const duplicate = this.tagsData.squadTags
                ? this.tagsData.squadTags.filter(
                    (squad) => squad.id === this.squadDataTag.id
                )
                : [];
            if (!duplicate.length) {
                const data = JSON.parse(JSON.stringify(this.squadDataTag));
                this.tagsData.squadTags.push(data);
                console.log(this.tagsData.squadTags);

                this.status = "needsSaving";
            }
        },

        addFixtureTag() {
            const duplicate = this.tagsData.fixtureTags
                ? this.tagsData.fixtureTags.filter(
                    (fixture) => fixture.id === this.fixtureDataTag.id
                )
                : [];
            if (!duplicate.length) {
                this.fixtureDataTag.teamID = this.singleFixtureListData.squad;
                this.fixtureDataTag.seasonID = this.singleFixtureListData.season;
                const data = JSON.parse(JSON.stringify(this.fixtureDataTag));
                this.tagsData.fixtureTags.push(data);
                console.log(this.tagsData.fixtureTags);

                this.status = "needsSaving";
            }
        },

        deletePlayerTag(id) {
            const tags = this.tagsData.playerTags.filter((tag) => {
                if (tag.id !== id) {
                    return tag;
                }
            });
            this.tagsData.playerTags = tags;

            this.status = "needsSaving";
        },

        deleteSquadTag(id) {
            const tags = this.tagsData.squadTags.filter((tag) => {
                if (tag.id !== id) {
                    return tag;
                }
            });
            this.tagsData.squadTags = tags;

            this.status = "needsSaving";
        },

        deleteFixtureTag(id) {
            const tags = this.tagsData.fixtureTags.filter((tag) => {
                if (tag.id !== id) {
                    return tag;
                }
            });
            this.tagsData.fixtureTags = tags;

            this.status = "needsSaving";
        },

        getSeasons(e) {
            this.singleFixtureListData.squad = e;
            const season = this.tagsConfigData.squads.filter(
                (item) => item.id === this.singleFixtureListData.squad
            );
            console.log(season);
            if (season[0].seasons) {
                this.seasons = season[0].seasons;
                console.log(this.seasons);
            } else {
                this.seasons = "No season data has been found";
                console.log("No season data has been found");
            }
        },

        getCompetitions(e) {
            this.singleFixtureListData.season = e;
            const competition = this.seasons.filter(
                (item) => item.seasonID === this.singleFixtureListData.season
            );

            if (competition[0].competitions) {
                this.competitions = competition[0].competitions;
                console.log(this.competitions);
            } else {
                this.competitions = "No competition data has been found";
                console.log("No competition data has been found");
            }
        },

        getFixtures(e) {
            this.singleFixtureListData.competition = e;
            this.$store.commit("startLoading");
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                console.log("getting normal fixtures", e);
                axios
                    .get(
                        `${this.apiFixtures}/opta?clientMatches=true&teamID=${e.teamID}&seasonID=${e.seasonID}&pageSize=100`,
                        { headers: { Authorization: jwt } }
                    )
                    .then((response) => {
                        console.log("Fetch fixtures response: ", response);
                        if (response.data.totalDataCount) {
                            this.matches = response.data.body.map((match) => {
                                if (match.teamData) {
                                    return {
                                        matchID: match.matchID,
                                        matchLabel:
                                            match.teamData[0].teamName +
                                            " Vs. " +
                                            match.teamData[1].teamName +
                                            " - " +
                                            moment(match.kickOffUTC).format(
                                                "ddd, MMM Do YYYY, HH:mm"
                                            ),
                                        matchTagLabel:
                                            match.teamData[0].teamName +
                                            " Vs. " +
                                            match.teamData[1].teamName,
                                    };
                                } else {
                                    console.warn("probably a deleted fixture");
                                }
                            });
                            this.$store.commit("completeLoading");
                        }
                        this.setMatchTag(e);
                    })
                    .catch((e) => {
                        console.error("Problem retrieving fixtures", e);
                        this.$store.commit("completeLoading");
                    });
            });
        },

        setMatchTag(e) {
            console.log("setting tag", e);
            this.fixtureDataTag.id = e.matchID;
            // this.fixtureDataTag.id = e;
            const matchLabel = this.matches.filter((match) => {
                if (match && match.matchID === e.matchID) {
                    return match.matchTagLabel;
                }
            });
            this.fixtureDataTag.name = matchLabel[0].matchTagLabel;
            this.$store.commit("completeLoading");
        },

        fetchCategories() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                this.$store.commit("startLoading");
                axios
                    .get(`${this.apiCategories}`, { headers: { Authorization: jwt } })
                    .then((response) => {
                        console.log("Fetch categories", response);
                        this.categoriesList = response.data.body;
                        this.$store.commit("completeLoading");
                    })
                    .catch((e) => {
                        console.error("Problem retrieving categories", e);
                        this.$store.commit("completeLoading");
                    });
            });
        },

        previewPost() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "POST",
                    url: `${this.preview}`,
                    headers: { Authorization: jwt },
                    data: {
                        postTitle: this.configData.postTitle,
                        postAuthor:
                            this.configData.postAuthor != ""
                                ? this.configData.postAuthor
                                : null,
                        postStatus: this.configData.postStatus,
                        postSummary:
                            this.configData.postSummary != ""
                                ? this.configData.postSummary
                                : null,
                        postCategory: this.configData.postCategory,
                        postIcon: this.configData.postIcon,
                        heroStyleLarge: this.configData.heroStyleLarge,
                        heroStyleSmall: this.configData.heroStyleSmall,
                        mediaLibraryID:
                            this.configData.mediaLibraryID != ""
                                ? this.configData.mediaLibraryID
                                : null,
                        heroSmallMediaLibraryID:
                            this.configData.heroSmallMediaLibraryID != ""
                                ? this.configData.heroSmallMediaLibraryID
                                : null,
                        listingMediaLibraryID:
                            this.configData.listingMediaLibraryID != ""
                                ? this.configData.listingMediaLibraryID
                                : null,
                        publishedDateTime: this.configData.publishedDateTime,
                        scheduled: this.configData.scheduled,
                        childFriendly: this.configData.childFriendly,
                        disableAdvertising: this.configData.disableAdvertising,
                        keywords:
                            this.seoData.keywords != "" ? this.seoData.keywords : null,
                        description:
                            this.seoData.description != "" ? this.seoData.description : null,
                        metaTitle:
                            this.seoData.metaTitle != "" ? this.seoData.metaTitle : null,
                        hideFromVoice: this.voiceData.hideFromVoice,
                        textToVoice:
                            this.voiceData.textToVoice != ""
                                ? this.voiceData.textToVoice
                                : null,
                        content: this.content,
                        tagsData: this.tagsData != "" ? this.tagsData : null,
                        showInFeed: this.configData.showInFeed,
                        feedWidth: this.configData.feedWidth,
                        cardStyle: this.configData.cardStyle,
                        sendToOneFootball: this.configData.sendToOneFootball ? 1 : 0,
                    },
                })
                    .then((response) => {
                        const previewUrl = `https://${process.env.VUE_APP_PREVIEW_LINK}/news/_/preview/${response.data.postID}`;

                        console.log(`Previewing at: ${previewUrl}`);

                        // Create a dummy input to copy the string array inside it
                        var dummy = document.createElement("input");

                        // Add it to the document
                        document.body.appendChild(dummy);

                        // Set its ID
                        dummy.setAttribute("id", "dummy_id");

                        // Output the array into it
                        document.getElementById("dummy_id").value = previewUrl;

                        // Select it
                        dummy.select();

                        // Copy its contents
                        document.execCommand("copy");

                        // Remove it as its not needed anymore
                        document.body.removeChild(dummy);

                        this.errorMessage = "Preview url copied to clipboard";

                        const win = window.open(`${previewUrl}`, "_blank");
                        win.focus();

                        setTimeout(() => {
                            this.errorMessage = "";
                        }, 10000);
                    })
                    .catch((e) => {
                        console.error("Problem previewing post: ", e);
                        this.errorMessage = "Problem previewing post";
                        Sentry.captureMessage("Problem previewing post [error: Calcium]");
                    });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.tags {
    &__layout {
        flex-direction: column;
    }

    &__section {
        width: 100%;
        border-bottom: 1px solid $lightgrey;
        margin-bottom: 20px;
    }
}

.alt-lang-notice {
    margin: 0;
    padding: 4px 8px;
    color: #000;
    font-size: 12px;
    text-align: center;
    background-color: color(warning);
    border-top: 1px solid darken(color(warning), 10%);
    border-bottom: 1px solid darken(color(warning), 10%);
    z-index: 10;
}

.warning-icon {
    padding-right: 8px;
    color: rgb(236, 148, 48);
}

.editor-notice {
    padding: 0;
    margin: 16px 0 0 10px;
    color: #7b6b80;
    font-size: 14px;
    font-weight: 300;
}
</style>
