<template>
    <section>
        <v-tabs>
            <template v-if="advertising2Enabled">

                <v-tab :ripple="{ class: 'accent--text' }">Footer Ads</v-tab>

                <v-tab-item
                    px-0
                    class="main-body"
                >
                    <footer-slot
                        :isNewSlot="isNewFooterSlot"
                        :footerSlot="footerSlot"
                        :loading="loading"
                        :adverts="adverts"
                        @slotCreated="fetchSlots()"
                        @fetchSlots="fetchSlots()"
                    />

                </v-tab-item>

                <v-tab :ripple="{ class: 'accent--text' }">Slots</v-tab>

                <v-tab-item
                    px-0
                    class="main-body"
                >
                    <advertising2-slots
                        :slots="slots"
                        :adverts="adverts"
                        :loading="loading"
                        @fetchSlots="fetchSlots()"
                    />
                </v-tab-item>

                <!-- <v-tab :ripple="{ class: 'accent--text' }">Groups</v-tab>

                <v-tab-item px-0 class="main-body">
                    <advertising2-groups />
                </v-tab-item> -->

                <v-tab :ripple="{ class: 'accent--text' }">Campaigns</v-tab>

                <v-tab-item
                    px-0
                    class="main-body"
                >
                    <advertising2-adverts @advertsFetched="adverts = $event" />
                </v-tab-item>
            </template>

            <template v-if="advertisingEnabled">
                <v-tab :ripple="{ class: 'accent--text' }">Legacy Footer Ads</v-tab>
                <v-tab-item
                    px-0
                    class="main-body"
                >
                    <advertising-manager />
                </v-tab-item>
            </template>
        </v-tabs>
    </section>
</template>

<script>
import axios from "axios";
import FooterSlot from "./Advertising2Slots/FooterSlot.vue";
import Advertising2Slots from "./Advertising2Slots/Advertising2Slots.vue";
import Advertising2Groups from "./Advertising2Groups.vue";
import Advertising2Adverts from "./Advertising2Adverts/Advertising2Adverts.vue";
import AdvertisingManager from "../AdvertisingManager.vue";

export default {
    name: "AdvertisingManager2",

    data: () => ({
        advertsAPI: process.env.VUE_APP_ADVERTISINGAPI,
        adverts: null,
        advertisingEnabled: null,
        advertising2Enabled: null,
        slots: null,
        footerSlot: null,
        isNewFooterSlot: false,
        loading: false,
    }),

    components: {
        AdvertisingManager,
        Advertising2Slots,
        Advertising2Groups,
        Advertising2Adverts,
        FooterSlot
    },

    created() {
        this.advertisingEnabled =
            this.$gcPermissions().getUserPermissions("Advertising");
        this.advertising2Enabled =
            this.$gcPermissions().getUserPermissions("Advertising2");
        this.fetchSlots()
    },

    methods: {
        fetchSlots() {
            this.isNewFooterSlot = false;
            this.loading = true;
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.advertsAPI}/slots`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        console.log("Slots fetched", response);
                        const slots = response.data.data;
                        const index = slots.findIndex(obj => obj.attributes.advertSlotID === "footer_slot");
                        if (index !== -1) {
                            this.footerSlot = slots.splice(index, 1)[0]
                            this.isNewFooterSlot = false
                        } else {
                            this.isNewFooterSlot = true
                        }
                        this.slots = slots
                    })
                    .catch((e) => {
                        console.error("Problem fetching slots", e);
                    }).finally(() => {
                        this.loading = false
                    })

            });
        },
    }
};
</script>

<style
    scoped
    lang='scss'
></style>
