<template>
  <section>
    <div class="data-groups">
      <h2 class="data-groups__title">{{ title || "Data Groups" }}</h2>
      <ul class="items">
        <li class="item" v-for="group in data" :key="group.id">
          <h4>{{ group.title }}</h4>
          <span v-if="group.items"
            >{{ group.items.length }} item{{
              group.items.length == 1 ? "" : "s"
            }}</span
          >
        </li>
      </ul>
      <v-btn
        class="data-groups__button"
        dark
        color="accent"
        type="button"
        v-on:click="openModal()"
        >Manage {{ title || "Data Groups" }}</v-btn
      >
    </div>

    <v-dialog class="modal" v-model="isModalOpen" persistent width="1200" lazy>
      <v-card>
        <v-card-title class="header-wrapper">
          <span class="title">
            {{ title || "Data Group" }}
          </span>
          <v-btn class="add-button" color="accent" @click="addGroup()">
            New {{ groupTitle || "Group" }}
          </v-btn>
        </v-card-title>

        <draggable
          v-if="modalData.length"
          v-model="modalData"
          :options="{
            ghostClass: 'ghost',
            dragClass: 'sortable-drag',
          }"
          @start="drag = true"
          @end="drag = false"
        >
          <data-group
            v-for="group in modalData"
            :groupTitle="groupTitle"
            :itemSchema="itemSchema"
            :data="group"
            :key="group.id"
            @updatedContent="updateGroup(group.id, $event)"
            @deleteGroup="deleteGroup($event)"
          />
        </draggable>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outline flat @click="cancel()"> Cancel </v-btn>

          <v-btn color="accent" outline flat @click="addData()">
            Add Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import DataGroup from "./DataGroup.vue";

export default {
  name: "DataGroups",

  props: {
    title: {
      type: String,
      required: false,
    },
    groupTitle: {
      type: String,
      required: false,
    },
    data: {
      type: Array,
      required: true,
    },
    groupSchema: {
      type: Object,
      required: true,
    },
    itemSchema: {
      type: Object,
      required: false,
    },
  },

  components: {
    DataGroup,
    draggable,
  },

  data: () => ({
    isModalOpen: false,
    modalData: null,
  }),

  created() {
    this.resetData();
  },

  methods: {
    resetData() {
      this.modalData = JSON.parse(JSON.stringify(this.data));
    },

    openModal() {
      this.resetData();
      this.isModalOpen = true;
    },

    addGroup() {
      const newGroup = JSON.parse(JSON.stringify(this.groupSchema));

      newGroup.title = this.groupTitle || "New Group";

      if (this.itemSchema) {
        newGroup.items = [];
      }

      newGroup.id = this.$uuid.v1();

      this.modalData.push(newGroup);
    },

    updateGroup(id, newData) {
      const index = this.modalData.findIndex((element) => element.id === id);
      if (index !== -1) {
        this.modalData[index] = newData;
      }
    },

    addData() {
      this.$emit("addData", this.modalData);
      this.isModalOpen = false;
      this.resetData();
    },

    cancel() {
      this.resetData();
      this.isModalOpen = false;
    },

    deleteGroup(id) {
      const index = this.modalData.findIndex((element) => element.id === id);
      if (index > -1) {
        this.modalData.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.data-groups {
  background: #f8f8f8;
  border: 1px solid #ebebeb;
  padding: 10px;

  &__title {
    color: #67247c;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__button {
    margin-left: 0;
  }
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  .title {
    color: #67247c;
  }
  .add-button {
    margin-left: 10px;
  }
}

.items {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .item {
    margin-right: 10px;
    padding: 4px 20px;
    color: #fff;
    background: rgba($color: #000, $alpha: 0.7);
    border-radius: 10px;
    text-align: center;
  }
}
</style>
