
import { defineComponent, onMounted, ref, Ref } from "vue";
import GenericForm from "./GenericForm/GenericForm.vue";
import { IGenericFormItem } from "../../../types/genericData"
//@ts-ignore
import StickyFooter from "@/components/Library/StickyFooter.vue";
import GenericChildManager from "./GenericChildManager.vue";

export default defineComponent({
    name: "GenericItem",
    components: { GenericForm, StickyFooter, GenericChildManager },

    props: {
        genericData: {
            type: Object,
            required: true,
        },
    },

    setup(props) {
        // Setup
        const { loading, error, genericGetSingle, genericItemModel, genericOriginalItemModel, genericEdit, editLoading, editError } = props.genericData.useGenericData();
        onMounted(async () => {

            await genericGetSingle(itemID)
            editSchema.value = props.genericData.edit.schema.map((item: IGenericFormItem) => {
                if (genericItemModel.value.attributes && item.key) {
                    item.value = genericItemModel.value.attributes[item.key]
                }

                return item
            })
        })

        //@ts-ignore
        const itemID = window.globalThis.vueinstance._route.params.id

        // Edit schema
        let editSchema: Ref<IGenericFormItem[]> = ref([])

        // Form Data + Patch + Sticky Footer
        const formData: { [key: string]: any } = {}

        function updateFormData(inputData: { [key: string]: any }) {
            status.value = "needsSaving"
            formData[inputData.key] = inputData.value
        }

        const status = ref('saved')
        async function handleSaveItem() {
            status.value = "saving"
            await genericEdit(formData, itemID)

            if (!editError.value) {
                status.value = 'saved'
            } else {
                status.value = "needsSaving"
            }
        }
        async function addChild(childID: string, property: string) {
            genericOriginalItemModel.value.attributes[property].push(childID)
            await genericEdit(genericOriginalItemModel.value.attributes, genericItemModel.value.id)
            await genericGetSingle(itemID)
        }
        async function removeChild(childID: string, property: string) {
            genericOriginalItemModel.value.attributes[property] = genericOriginalItemModel.value.attributes[property].filter((child: string) => child !== childID)
            await genericEdit(genericOriginalItemModel.value.attributes, genericItemModel.value.id)
            await genericGetSingle(itemID)
        }

        return { genericItemModel, loading, error, editSchema, status, handleSaveItem, editError, updateFormData, addChild, removeChild }
    }
})
