<!--

  @TODO:
    - Hook into api

-->

<template>
  <section class="video-content">
    <v-tabs v-model="active">

      <v-tab :ripple="{ class: 'accent--text' }">Configuration</v-tab>
      <v-tab-item px-0 class="main-body">
        <VideoConfiguration v-if="dataLoaded" :data="configData" :saveData="saveData" @update="updateConfigData($event)"/>
      </v-tab-item>

      <v-tab :ripple="{ class: 'accent--text' }">SEO</v-tab>
      <v-tab-item px-0 class="main-body">
        <EditSEO v-if="dataLoaded" :data="seoData" :saveData="saveData" @update="updateSeoData($event)"/>
      </v-tab-item>

      <v-tab :ripple="{ class: 'accent--text' }">Content</v-tab>
      <v-tab-item px-0 class="main-body">

        <h2 class="subheading">Video Item</h2>

        <VideoPreview v-if="dataLoaded" :data="videoPreviewData"/>

        <h2 class="subheading">Supporting Content</h2>

        <VideoEditor v-if="dataLoaded" :data="videoEditData" @update="updateVideoEditData($event)" />

        <SavedBy :saveData="saveData"/>

      </v-tab-item>

    </v-tabs>

    <StickyFooter :errorMessage="errorMessage" :status="status" :buttonText="'Save Page'" @buttonClicked="savePage()" />
    
  </section>
</template>

<script>
import axios from "axios"
import VideoConfiguration from "@/components/Videos/VideoConfiguration"
import VideoPreview from "@/components/Videos/VideoPreview"
import VideoEditor from "@/components/Videos/VideoEditor"
import EditSEO from "@/components/Library/EditSEO"
import StickyFooter from "@/components/Library/StickyFooter"
import SavedBy from "@/components/Library/SavedBy"

export default {
  name: "VideoContent",

  components: {
    VideoConfiguration,
    VideoPreview,
    VideoEditor,
    EditSEO,
    StickyFooter,
    SavedBy
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/videos`,
    active: null,
    dataLoaded: true, // SET TO FALSE WITH REAL DATA
    status: "saved",
    errorMessage: "",
    guid: null,
    saveData: {
      author: "AUTHOR NOT SET",
      authorImage: "/favicon-32x32.png",
      lastSave: null,
    },
    configData: {
      pageID: null,
      pageName: null,
      pageSlug: null,
      pageSummary: null,
    },
    seoData: {
      metaTitle: null,
      keywords: null,
      description: null
    },
    videoPreviewData: {
      platform: "streamAMG",
      thumbnail: require("../../assets/TEMP/image-preview.jpg"),
      videoSource: "",
      duration: "0.36",
      publishedDate: "2019-02-11T17:03:33",
      tags: [
        "Goal",
        "Free Kick",
        "Watford",
        "Digne"
      ]
    },
    videoEditData: {
      leadingImageID: "568ee380-50ad-11e9-a7a6-d1997147c3e8",
      summary: "Test summary",
    }
  }),

  mounted() {
    console.log("Edit video mounted");
    // this.fetchPage();
    this.$store.commit("completeLoading");
  },

  methods: {
    fetchPage() {
      this.errorMessage = ""
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}/${this.$route.params.id}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("fetchData response: ", response);

          this.guid = response.data.body.guid

          this.configData = {
            pageID: response.data.body.pageID,
            pageName: response.data.body.pageName,
            pageSlug: `/${response.data.body.pageID}`,
            pageSummary: response.data.body.pageSummary,
          }

          this.seoData = {
            metaTitle: response.data.body.metaTitle,
            keywords: response.data.body.keywords,
            description: response.data.body.description,
          }

          this.saveData = {
            lastSave: response.data.body.savedTimestamp
          }

          this.videoEditData = {
            leadingImageID: response.data.body.leadingImageID,
            summary: response.data.body.videoSummary
          }

          if (this.videoEditData === undefined) {
            this.videoEditData = {}
          }

          this.dataLoaded = true
          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving page: ", e);
          this.$store.commit("completeLoading");
          this.errorMessage = "Problem retrieving page"
        });
      })
    },

    savePage() {
      console.log("Going to save:", this.guid)
      this.status = "saving";
      this.errorMessage = ""
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}`,
          headers: { "Authorization": jwt },
          data: {
            guid: this.guid,
            pageID: this.configData.pageID,
            pageName: this.configData.pageName,
            pageSlug: this.configData.pageSlug,
            pageSummary: this.configData.pageSummary,
            keywords: this.seoData.keywords,
            description: this.seoData.description,
            metaTitle: this.seoData.metaTitle,
            leadingImageID: this.videoEditData.leadingImageID,
            summary: this.videoEditData.videoSummary
          }
        })
        .then(response => {
          console.log("Saved Page:", response);
          this.status = "saved";
        })
        .catch(e => {
          console.error("Problem saving page: ", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem saving page"
        });
      })
    },

    updateConfigData(payload) {
      console.log("updateConfigData: ", payload);
      this.status = "needsSaving";
      this.configData = payload
    },

    updateSeoData(payload) {
      console.log("updateSeoData: ", payload);
      this.status = "needsSaving";
      this.seoData = payload
    },

    updateVideoEditData(payload) {
      console.log("updateVideoEditData: ", payload);
      this.status = "needsSaving";
      this.videoEditData = payload
    }
  }

}
</script>
