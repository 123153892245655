<template>
  <section>
    <div v-for="section in faq" :key="section.title" class="section">
      <pre>
        <p>{{section.title}}</p>
        <ul>{{section.questions}}</ul>

      </pre>

      <v-btn
        color="accent"
        outline
        flat
        @click="addQuestion(section.id)"
      >
        Add Question
      </v-btn>
      
    </div>

      <v-btn
        color="accent"
        outline
        flat
        @click="addRow()"
      >
        Add Section
      </v-btn>
  </section>
</template>

<script>
export default {
  name: "VideosV2Accordian",

  data: () => ({
    faq: [
      {
        id: "0",
        title: "ONE",
        questions: [
          {
            question: "This is my question."
          },
        ]
      },
      {
        id: "1",
        title: "TWO",
        questions: [
          {
            question: "This is my question."
          },
        ]
      },
      {
        id: "2",
        title: "THREE",
        questions: [
          {
            question: "This is my question."
          },
        ]
      },
    ]
  }),

  methods: {

    addRow() {
      console.log("Add Row")
      this.faq.push({
        sectionTitle: "",
        questions: [
          {
            question: ""
          }
        ]
      })
    },

    addQuestion(id) {
      this.faq[id].questions.push(
        {
          question: "This is a NEW question."
        },
      )
    }
  }
}
</script>

<style>

</style>