<template>
  <section class="tab-buffer">

    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="hide-from-Voice">Hide from Voice</label>
          <v-select
            id="hide-from-Voice"
            v-model="updatedData.hideFromVoice"
            item-text='name'
            item-value='value'
            :items="[
              { name: 'Hide from Voice Devices', value: 1 },
              { name: 'Allow on Voice Devices', value: 0 }
            ]"
            solo
            flat
            @change="updateData()">
          </v-select>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>By default all articles will be pushed to Voice devices. This can be prevented on a per article basis</i></p>
        </v-flex>
      </v-layout>

      <v-layout column>
        <v-flex>
          <label class="label" for="hide-from-Voice">Text to Voice</label>
          <v-textarea
            rows="20"
            id="meta-description"
            v-model="updatedData.textToVoice"
            solo
            flat
            required
            @change="updateData()"
          ></v-textarea>
        </v-flex>
        <v-flex mt-2>
          <p><i>It is recommended to write out a Voice version of your article, however if you do not then we will create the voice data from the main content.</i></p>
        </v-flex>
      </v-layout>
    </section>

    <SavedBy :saveData="saveData"/>

  </section>
</template>

<script>
import SavedBy from "@/components/Library/SavedBy"

export default {
  name: "EditVoice",

  components: {
    SavedBy
  },

  props: {
    data: {
      type: Object,
      required: true
    },
    saveData: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      updatedData: {},
      contentPage: false
    }
  },

  mounted() {
    console.log("Edit Voice mounted");
    this.updatedData = {
      hideFromVoice: this.data.hideFromVoice,
      textToVoice: this.data.textToVoice
    }
  },

  methods: {
    updateData() {
      this.$emit("update", this.updatedData)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/settings';

  .half {
    width: 50%;
  }

  .disclaimer {
    color: $grey;
  }

</style>>
