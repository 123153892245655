<template>
  <section class="main-body">
    <MiniHeader :heading="'Judo Campaigns'" :buttonText="'Add Judo Campaign'" :hasButton="true" @buttonClicked="createRoverItem()" />

    <p><a href="https://www.judo.app/" target="_blank">What is Judo?</a></p>

    <div class="filters">
      <div class="filters__order">
        <label>Order by</label>
        <v-select
          v-model="orderBy"
          item-text='name'
          item-value='value'
          :items="[
            { name: 'Display Order', value: 'forcedOrder' },
            { name: 'Published Date', value: 'dateTimeAdded' }
          ]"
          solo
          flat
          required
          @change="fetchRoverData()"/>
      </div>
    </div>

    <ol class="config-selector__list" v-if="roverDataLoaded">
      <li class="config-selector__item" v-for="item in roverItems" :key="item.roverID" :class="[`forcedOrder-${item.forcedOrder}`]">
        <div class="config-selector__inner">
          <p v-if="item.hasExpired" class="status status--expired">Expired</p>
          <p v-else-if="item.published" class="status status--published">Published</p>
          <p v-else class="status status--draft">Draft</p>
          <h2 class="config-selector__subheading subheading">{{ item.title }}</h2>
          <p class="id-label">ID: {{ item.roverID }}</p>
          <p class="id-label">Access: {{ item.accessLevel || 'all' }}</p>
        </div>
        <v-btn :to="`/judo/${item.roverID}`">Edit</v-btn>
      </li>
    </ol>

    <div class="pagination">
      <v-pagination
        v-model="pageNumber"
        :length="pageCount"
        circle
        color="accent">
      </v-pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios"
import MiniHeader from "@/components/Library/MiniHeader"

export default {
  name: "JudoDashboard",

  components: {
    MiniHeader
  },

  data: () => ({
    api: `${process.env.VUE_APP_ROVER}/rover`,
    roverItems: null,
    roverDataLoaded: false,
    pageCount: 0,
    pageSize: 10,
    pageNumber: 1,
    orderBy: "forcedOrder" // dateTimeAdded, forcedOrder
  }),

  watch: {
    pageNumber(value, oldValue) {
      console.log("Page number changed");
      if (oldValue) {
        this.roverDataLoaded = false
        this.fetchRoverData()
      }
    },
  },

  mounted() {
    console.log("Rover dashboard mounted");
    this.fetchRoverData();
  },

  methods: {
    fetchRoverData() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}?pageSize=${this.pageSize}&pageNumber=${this.pageNumber}&orderBy=${this.orderBy}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("fetchRoverData: ", response);
          this.roverItems = response.data.body;
          this.pageCount = Math.ceil(response.data.totalCount / this.pageSize);
          this.roverDataLoaded = true;

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving rover data", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    createRoverItem() {
       this.$router.push({ path: "judo/create" })
    }
  }
}
</script>

<style scoped lang="scss">
  .config-selector {
    &__list {
      padding: 0;
      margin: 20px 0 0;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      margin: 0 0 16px;
      border: 1px solid #67247c;
      border-radius: 6px;
    }

    &__subheading {
      margin: 0;
      padding: 5px 0 0;
    }
  }

  .id-label {
    color: #666;
    font-size: 12px;
    font-style: italic;
    opacity: 0.7;
    margin: 3px 0 0;
  }

  .status {
    display: inline-block;
    padding: 3px 12px;
    margin: 0 8px 8px 0;
    font-size: 10px;
    color: #fff;
    background-color: #E6E8F1;
    border-radius: 4px;

    &.status--expired {
      background-color: color(hiddenHighlight);
    }

    &.status--draft {
      background-color: color(draftHighlight);
    }

    &.status--published {
      background-color: color(newHighlight);
    }
  }

  .filters {
    display: flex;
    flex-direction: row;
    &__order {
      margin: 0 0 0 auto;
      max-width: 240px;
    }
  }
</style>
