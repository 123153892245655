<template>
  <v-dialog
    class="stripe-product-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>
      <v-form enctype="multipart/form-data" ref="singleFieldForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            class="widget__heading"
          >
            Stripe Product
          </v-card-title>

          <v-card-text>
            <p class="widget__description">Stripe products are managed from your <a href="https://dashboard.stripe.com/" target="_blank">Stripe account</a>. Choose your product and price that you would like to use.</p>

            <label class="label" for="product-select">Products</label>
            <v-select
              id="product-select"
              solo
              flat
              required
              :rules="[rules.required]"
              v-model="updatedData.content.product"
              :items="products"
              item-text="name"
              item-value="id"
              return-object
              validate-on-blur
              @change="enableSave = false; fetchPrices()"
            ></v-select>

            <label class="label" for="price-select">Prices</label>
            <v-select
              v-if="pricesForProduct.length"
              id="price-select"
              solo
              flat
              required
              :rules="[rules.required]"
              v-model="updatedData.content.price"
              :items="pricesForProduct"
              item-text="createdName"
              item-value="id"
              return-object
              validate-on-blur
              @change="enableSave = true"
            ></v-select>

            <p v-else>Select a product to see the prices available</p>

            <label class="label" for="button-text">Buy button text override</label>
            <v-text-field id="button-text" flat solo type="text" v-model="updatedData.content.buttonText" placeholder="Buy"/>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!enableSave"
              color="accent"
              outline
              flat
              @click="validate()"
            >
              Post
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "StripeProductWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    productsApi: `${process.env.VUE_APP_STRIPE_API}/v1/products`,
    pricesApi: `${process.env.VUE_APP_STRIPE_API}/v1/prices`,
    dialog: false,
    valid: false,
    rules: {
      required: value => !!value || "Required."
    },
    products: [],
    pricesForProduct: [],
    enableSave: false,
    updatedData: {
      content: {
        buttonText: null,
        price: {
          createdName: null,
          id: null
        },
        product: {
          name: null,
          id: null
        }
      },
    }
  }),

  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
    }

    this.fetchProducts()
  },

  methods: {
    fetchProducts() {
      axios
      .get(this.productsApi)
      .then(response => {
        console.log("Products response", response);
        const productsResponse = response.data.data;

        this.products = productsResponse.map(item => {
          return {
            name: item.name,
            id: item.id
          }
        });

        this.$store.commit("completeLoading");
      })
      .catch(e => {
        console.error("Problem retrieving forms", e);
        this.$store.commit("completeLoading");
      });
    },

    fetchPrices() {
      axios
      .get(this.pricesApi)
      .then(response => {
        console.log("Prices response", response);
        const pricesResponse = response.data.data;

        this.pricesForProduct = pricesResponse.filter(item => {
          return item.product === this.updatedData.content.product.id
        }).map(item => {
          return {
            createdName: `${item.id} - (${item.type} ${item.currency} ${item.unit_amount / 100})`,
            id: item.id
          }
        });

        this.$store.commit("completeLoading");
      })
      .catch(e => {
        console.error("Problem retrieving forms", e);
        this.$store.commit("completeLoading");
      });
    },

    updateData() {
      console.log("Update data:", this.updatedData);
      
      if (this.buttonText === "") {
        this.buttonText = null
      }

      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
    },

    validate() {
      this.errorMessage = ""
      if (this.$refs.singleFieldForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .widget {
    &__description {
      margin: 0 0 20px;
      color: #7B6B80;
      font-size: 14px;
    }
  }
</style>
