<template>
  <div class="position-picker-row">
    <div
      @click="openModal(index)"
      class="position"
      v-for="(position, index) in currentPositions"
      :key="position.displayName"
    >
      {{
        `${position.displayName} : ${
          position.displayValue
            ? position.displayValue
            : position.value.includes("%")
            ? "Custom"
            : position.value
        }`
      }}
    </div>
    <v-dialog class="dialog" v-model="dialog" width="1200" lazy>
      <v-card class="modal">
        <v-tabs v-model="activeTab" centered>
          <template v-for="(position, index) in currentPositions">
            <v-tab :key="`tab-${position.displayName}`">{{
              position.displayName
            }}</v-tab>
            <v-tab-item
              class="modal-item"
              :key="`item-${position.displayName}`"
              @mouseup="stopDrag(index)"
            >
              <p class="help-text">
                Position:
                {{
                  position.displayValue
                    ? position.displayValue
                    : position.value.includes("%")
                    ? "Custom"
                    : position.value
                }}
              </p>

              <div
                v-if="image"
                :id="`drag-image-${index}`"
                @mousedown="startDrag($event, index)"
                :style="`background-image: url('${image}'); padding-bottom: ${getPadding(
                  position
                )}; width: ${position.width}; background-position: ${
                  currentPositions[index].value
                }`"
                class="image"
              >
                <!-- Future version to handle an image in the background showing the full width -->
                <!-- <img 
              v-if="image" 
              :src="`${image}`"
              :id="`drag-image2-${index}`"
              :style="`width: ${position.width}; background-position: ${currentPositions[index].value}`" 
              class="image image--full" /> -->
              </div>
              <p v-else>No image found</p>
              <div class="modal-instructions">
                <div class="grid">
                  <div
                    class="grid-item"
                    :class="{
                      'grid-item--active': activeGridItems[index] == index2,
                    }"
                    v-for="(gridItem, index2) in 9"
                    :key="index2"
                    @click="selectPositionGrid(gridItem - 1, index)"
                  ></div>
                </div>
                <p>
                  Drag the image, click on the grid or type inside the box to
                  define a position
                </p>
                <div class="button-group">
                  <v-btn type="button" @click="dialog = false"> Cancel </v-btn>
                  <v-btn
                    color="accent"
                    dark
                    type="button"
                    @click="savePositions()"
                  >
                    Save
                  </v-btn>
                </div>
              </div>
            </v-tab-item>
          </template>
        </v-tabs>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PositionPicker",

  props: {
    data: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },

  watch: {
    data() {
      this.currentPositions = JSON.parse(JSON.stringify(this.data));
    },
  },

  data: () => ({
    dialog: false,
    isPickerOpen: false,
    activeTab: 1,
    imageDomain: `${process.env.VUE_APP_IMAGEURL}`,
    image: null,
    currentPositions: [],
    activeGridItems: [],
    isDragOn: false,
    dragStartX: null,
    dragStartY: null,
    positionReference: {
      "left top": "0% 0%",
      "center top": "50% 0%",
      "right top": "100% 0%",
      "left center": "0% 50%",
      "center center": "50% 50%",
      "right center": "100% 50%",
      "left bottom": "0% 100%",
      "center bottom": "50% 100%",
      "right bottom": "100% 100%",
    },
  }),

  created() {
    this.currentPositions = JSON.parse(JSON.stringify(this.data));

    this.defineImage();
    this.currentPositions.forEach((element, index) => {
      switch (element.value) {
        case "left top":
          this.activeGridItems[index] = 0;
          break;
        case "center top":
          this.activeGridItems[index] = 1;
          break;
        case "right top":
          this.activeGridItems[index] = 2;
          break;
        case "left center":
          this.activeGridItems[index] = 3;
          break;
        case "center center":
          this.activeGridItems[index] = 4;
          break;
        case "right center":
          this.activeGridItems[index] = 5;
          break;
        case "left bottom":
          this.activeGridItems[index] = 6;
          break;
        case "center bottom":
          this.activeGridItems[index] = 7;
          break;
        case "right bottom":
          this.activeGridItems[index] = 8;
          break;

        default:
          break;
      }
    });
  },

  methods: {
    openModal(index) {
      this.activeTab = index;
      this.dialog = true;
    },

    getPadding(position) {
      const padding = parseFloat(position.padding) / 100;
      const width = parseFloat(position.width) / 100;
      const result = padding * width;
      const resultInPercentage = (result * 100).toFixed(2) + "%";
      return resultInPercentage;
    },

    defineImage() {
      switch (true) {
        case this.item.customThumbnail:
          if (this.item.image && this.item.image.imageKey) {
            this.image = `${this.imageDomain}/fit-in/900x900/filters:quality(20)/${this.item.image.imageKey}`;
          }
          break;

        case this.item.data.attributes.imageData.landscape.imageKey !==
          "undefined":
          this.image = `${this.imageDomain}/${this.item.data.attributes.imageData.landscape.imageKey}`;
          break;

        case this.item.data.attributes.assetData.playbackIds[0].policy ===
          "signed":
          this.image = `https://image.mux.com/${this.item.data.attributes.assetData.playbackIds[0].id}/thumbnail.jpg?token=${this.item.data.attributes.assetData.thumbnailToken}`;
          break;

        case this.item.data.attributes.assetData.playbackIds[0].id:
          this.image = `https://image.mux.com/${this.item.data.attributes.assetData.playbackIds[0].id}/thumbnail.jpg?`;
          break;

        default:
          break;
      }
    },

    selectPositionGrid(position, index) {
      this.activeGridItems[index] = position;
      switch (position) {
        case 0:
          this.currentPositions[index].value = "left top";
          this.currentPositions[index].displayValue = "left top";
          break;

        case 1:
          this.currentPositions[index].value = "center top";
          this.currentPositions[index].displayValue = "center top";
          break;

        case 2:
          this.currentPositions[index].value = "right top";
          this.currentPositions[index].displayValue = "right top";
          break;

        case 3:
          this.currentPositions[index].value = "left center";
          this.currentPositions[index].displayValue = "left center";
          break;

        case 4:
          this.currentPositions[index].value = "center center";
          this.currentPositions[index].displayValue = "center center";
          break;

        case 5:
          this.currentPositions[index].value = "right center";
          this.currentPositions[index].displayValue = "right center";
          break;

        case 6:
          this.currentPositions[index].value = "left bottom";
          this.currentPositions[index].displayValue = "left bottom";
          break;

        case 7:
          this.currentPositions[index].value = "center bottom";
          this.currentPositions[index].displayValue = "center bottom";
          break;

        case 8:
          this.currentPositions[index].value = "right bottom";
          this.currentPositions[index].displayValue = "right bottom";
          break;

        default:
          break;
      }
    },

    startDrag(e, index) {
      this.dragStartX = e.clientX;
      this.dragStartY = e.clientY;

      this.activeGridItems[index] = null;
      this.currentPositions[index].displayValue = "Custom";

      if (!this.currentPositions[index].value.includes("%")) {
        this.currentPositions[index].value =
          this.positionReference[this.currentPositions[index].value];
      }

      const image = document.getElementById(`drag-image-${index}`);
      image.addEventListener("mousemove", this.onMove);
      image.style.transition = "0s";

      // Future version to handle an image in the background showing the full width
      // const image2 = document.getElementById(`drag-image2-${index}`)
      // image2.addEventListener("mousemove", this.onMove)
      // image2.style.transition = '0s'
      this.movingImage = index;
    },

    stopDrag(index) {
      const image = document.getElementById(`drag-image-${index}`);
      image.removeEventListener("mousemove", this.onMove);
      image.style.transition = "";
      // Future version to handle an image in the background showing the full width
      // const image2 = document.getElementById(`drag-image2-${index}`)
      // image2.removeEventListener("mousemove", this.onMove);
      // image2.style.transition = ''
      this.movingImage = null;
      this.$forceUpdate();
    },

    onMove(e) {
      let diffX = -(e.clientX - this.dragStartX);
      const nums = this.currentPositions[this.movingImage].value.split(" ");
      const num1 = nums[0].replace("%", "");
      diffX = Number(diffX) + Number(num1);
      if (diffX < 0) diffX = 0;
      if (diffX > 100) diffX = 100;

      let diffY = -(e.clientY - this.dragStartY);
      const num2 = nums[1].replace("%", "");
      diffY = Number(diffY) + Number(num2);
      if (diffY < 0) diffY = 0;
      if (diffY > 100) diffY = 100;

      this.currentPositions[this.movingImage].value = `${diffX}% ${diffY}%`;

      this.dragStartX = e.clientX;
      this.dragStartY = e.clientY;
    },

    savePositions() {
      this.$emit("positionSelected", this.currentPositions);
      this.dialog = false;
    },
  },
};
</script>

<style scoped lang='scss'>
.position-picker {
  position: relative;
  display: flex;
  align-items: center;
}
.position {
  color: white;
  background: #67247c;
  padding: 3px 12px;
  border-radius: 4px;
  font-size: 10px;
  cursor: pointer;
  transition: 0.3s;
  margin-right: 10px;
  &:hover {
    opacity: 0.7;
  }
}

.grid {
  border-radius: 4px;
  overflow: hidden;
  width: 80px;
  height: 80px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background: #888;
  gap: 4px;
  margin-right: 10px;
  flex-shrink: 0;
}

.grid-item {
  cursor: pointer;
  background: #fff;

  &--active {
    background: #67247c;
    cursor: initial;
  }
}

.help-text {
  text-align: center;
}

.dialog {
  display: none;
}

.modal-item {
  padding: 20px 0 0;
  overflow-x: hidden;
}

.image {
  height: 0;
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: move;
  transition: 0.3s;
  border: 3px solid rgba($color: #000000, $alpha: 0.7);
  position: relative;

  &--full {
    height: unset;
    pointer-events: none;
    z-index: -1;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.modal-instructions {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  background: #ccc;
  p {
    margin-right: 10px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}

.button-group {
  margin-left: auto;
}

.featured__items {
  .v-dialog__container.dialog {
    display: none !important;
  }
}
</style>
