<template>
  <section class="page-content">
    <h2 v-if="isExistingForm" class="form-title">
      <span class="form-title__intro">Viewing Form: </span
      >{{ updatedData.name_admin }}
    </h2>
    <h2 v-else class="form-title">
      <span class="form-title__intro">Creating New Form: </span
      >{{ updatedData.name_admin }}
    </h2>
    <v-tabs v-model="active" v-if="updatedData.formMeta">
      <template v-if="isExistingForm">
        <v-tab :ripple="{ class: 'accent--text' }">Responses</v-tab>
        <v-tab-item px-0 class="main-body">
          <single-form-responses :formID="formId" />
        </v-tab-item>
      </template>

      <v-tab :ripple="{ class: 'accent--text' }">Details</v-tab>
      <v-tab-item px-0 class="main-body">
        <v-form v-model="valid" ref="formDetails" lazy-validation>
          <section class="form-section">
            <h1>Admin Details</h1>
            <v-layout row>
              <v-flex mr-2>
                <label class="label" for="form-name">Form Name</label>
                <v-text-field
                  id="form-name"
                  v-model="updatedData.name_admin"
                  solo
                  flat
                  :rules="[rules.required]"
                  validate-on-blur
                  @change="updateData()"
                ></v-text-field>
              </v-flex>

              <v-flex ml-2>
                <label class="label" for="form-description"
                  >Form Description</label
                >
                <v-text-field
                  id="form-description"
                  v-model="updatedData.description_admin"
                  solo
                  flat
                  :rules="[rules.required]"
                  validate-on-blur
                  @change="updateData()"
                ></v-text-field>
              </v-flex>

              <v-flex ml-2>
                <label class="label" for="form-id">FormID</label>
                <v-text-field
                  id="form-id"
                  v-model="updatedData.formID"
                  solo
                  flat
                  :readonly="true"
                  @change="updateData()"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </section>

          <section class="form-section">
            <h1>Form Options</h1>
            <v-layout row>
              <v-flex mr-2>
                <label class="label" for="form-meta-title"
                  >Form Title (optional)</label
                >
                <v-text-field
                  id="form-meta-title"
                  v-model="updatedData.formMeta.title"
                  solo
                  flat
                  @change="updateData()"
                ></v-text-field>
              </v-flex>

              <v-flex ml-2>
                <label class="label" for="form-meta-description"
                  >Form Description (optional)</label
                >
                <v-text-field
                  id="form-meta-description"
                  v-model="updatedData.formMeta.description"
                  solo
                  flat
                  @change="updateData()"
                ></v-text-field>
              </v-flex>

              <v-flex ml-2>
                <label class="label" for="form-meta-submit-text"
                  >Submit button text</label
                >
                <v-text-field
                  id="form-meta-submit-text"
                  v-model="updatedData.formMeta.submitButtonText"
                  solo
                  flat
                  :rules="[rules.required]"
                  validate-on-blur
                  @change="updateData()"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-2>
                <label class="label" for="form-email-options"
                  >Send to options</label
                >
                <div v-if="updatedData.formMeta.sendToOptions.length">
                  <table>
                    <th>Group Name</th>
                    <th>Email Address</th>
                    <th>Address ID</th>
                    <tr
                      v-for="(address, index) in updatedData.formMeta
                        .sendToOptions"
                      :key="index"
                    >
                      <td>
                        <v-text-field
                          v-model="address.name"
                          solo
                          flat
                          placeholder="Group Name"
                          @change="updateData()"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="address.sendTo"
                          solo
                          flat
                          placeholder="Semi-colon seperated emails"
                          @change="updateData()"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="address.id"
                          solo
                          flat
                          @change="updateData()"
                          placeholder="Unique identifier for this group"
                        ></v-text-field>
                      </td>
                      <td>
                        <button
                          type="button"
                          class="form-card__action"
                          @click="deleteEntry(index)"
                        >
                          <v-icon>delete</v-icon>
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
                <p v-else>At least one sendto option is required</p>
              </v-flex>

              <v-flex ml-2 class="add-address">
                <v-btn @click="addAddress()">Add new Address</v-btn>
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex mr-2>
                <label class="label" for="form-meta-sendto"
                  >Default Destination</label
                >
                <v-select
                  v-if="updatedData.formMeta.sendToOptions.length"
                  :items="updatedData.formMeta.sendToOptions"
                  id="form-meta-sendto"
                  v-model="updatedData.formMeta.sendTo"
                  item-text="name"
                  item-value="id"
                  solo
                  flat
                  :rules="[rules.required]"
                  validate-on-blur
                  @change="updateData()"
                ></v-select>
                <p v-else>
                  Add at least one sendto option, then select the default group
                </p>
              </v-flex>
            </v-layout>
          </section>

          <!-- Hidden - not available at first release 04/08/2020 -->
          <!-- <section class="form-section">
              <h1>Advanced Options</h1>
              <v-layout row>
                <v-flex mr-2 md4 sm6>
                  <label class="label" for="form-meta-mail-select">Allow user to select destination email</label>
                  <v-select
                    id="form-meta-mail-select"
                    v-model="updatedData.formMeta.allowUserSelectEmail"
                    :items="[{'name': 'true', 'value': true}, {'name': 'false', 'value': false}]"
                    item-text='name'
                    item-value='value'
                    solo
                    flat
                    required
                    @change="updateData()"
                  ></v-select>
                </v-flex> -->

          <!-- :TODO SSO Pre-population option 
                <v-flex ml-2>
                  <label class="label" for="form-meta-auto-populate">Pre-populate logged in user details</label>
                  <v-select
                    id="form-meta-auto-populate"
                    v-model="updatedData.formMeta.autoPopulate"
                    :items="[{'name': 'true', 'value': true}, {'name': 'false', 'value': false}]"
                    solo
                    flat
                    item-text='name'
                    item-value='value'
                    required
                    @change="updateData()"
                  ></v-select>
                </v-flex> -->
          <!-- </v-layout>
            </section> -->
        </v-form>
      </v-tab-item>

      <v-tab :ripple="{ class: 'accent--text' }">Fields</v-tab>
      <v-tab-item px-0 class="main-body">
        <v-layout row>
          <v-flex
            class="form-builder-wrapper"
            :class="!formBody ? 'form-builder-wrapper--alt' : 'form-builder'"
            mr-2
          >
            <span class="form-builder-wrapper__header">
              <h2>Form Builder</h2>
              <v-btn
                class="form-builder-button"
                type="button"
                @click="toggleFormBuilder()"
                ><span v-if="!formBuilder">Show Form Builder</span
                ><span v-else>Show JSON</span></v-btn
              >
            </span>

            <div v-if="!formBuilder">
              <label class="label" for="form-fields">Form JSON</label>
              <v-textarea
                class="json-editor"
                id="form-fields"
                v-model="formBodyJSON"
                height="600"
                outline
                flat
              ></v-textarea>
              <v-btn
                class="form-builder-button"
                type="button"
                @click="saveJson()"
                >Save Changes</v-btn
              >
            </div>

            <form-builder
              v-else-if="formBuilder"
              :form="formBody"
              :existingField="existingField"
              @formUpdating="updateFields($event)"
              :replyEmailId="replyEmailId"
            />
          </v-flex>

          <v-flex class="form-preview" ml-2 v-if="formBody">
            <h2>Form Preview</h2>
            <form-preview
              :fields="formBody"
              @removeField="removeField($event)"
              @editField="editField($event)"
              @moveUp="moveUp($event)"
              @moveDown="moveDown($event)"
            />
          </v-flex>
        </v-layout>
      </v-tab-item>
    </v-tabs>

    <sticky-footer
      :errorMessage="errorMessage"
      :status="saveStatus"
      @buttonClicked="isExistingForm ? saveForm() : addForm()"
      :allowPreview="false"
    />
  </section>
</template>

<script>
import StickyFooter from "@/components/Library/StickyFooter";
import FormBuilder from "@/components/Forms/FormBuilder";
import FormPreview from "@/components/Forms/FormPreview";
import SingleFormResponses from "@/components/Forms/SingleFormResponses";
import axios from "axios";
export default {
  name: "FormEdit",

  components: {
    FormBuilder,
    FormPreview,
    StickyFooter,
    SingleFormResponses,
  },

  data: () => ({
    active: null,
    formId: null,
    formData: null,
    updatedData: {},
    buttonText: "",
    pageText: "",
    formBody: null,
    formObj: null,
    isExistingForm: false,
    saveError: "",
    saveStatus: "needsSaving",
    valid: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    errorMessage: "",
    formBuilder: true,
    formBodyJSON: "",

    existingField: null,
    replyEmailId: false,
  }),

  watch: {
    existingField: function (val) {
      if (val) {
        console.log("existingFieldExists", this.existingField);
      }
    },
  },

  mounted() {
    if (this.$route.path != "/forms/create") {
      this.formId = this.$route.params.id.substring(1);
      this.getFormData();
      this.buttonText = "Update Form";
      this.isExistingForm = true;
    } else {
      this.updatedData = {
        name_admin: null,
        description_admin: null,
        formMeta: {
          title: null,
          description: null,
          sendTo: null,
          submitButtonText: null,
          allowUserSelectEmail: false,
          autoPopulate: false,
          sendToOptions: [],
        },
        fields: [],
      };
      this.buttonText = "Add New Form";
    }
  },

  methods: {
    getFormData() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(
            `${process.env.VUE_APP_BASEURL}/formlayouts/getsingle?formID=${this.formId}`,
            { headers: { Authorization: jwt } }
          )

          .then((response) => {
            this.updatedData = response.data.body;
            if (response.data.body.fields) {
              (this.formBody = response.data.body.fields), undefined, 4;
              this.formBodyJSON = JSON.stringify(this.formBody, undefined, 4);
            } else {
              this.formBody = [];
            }
            this.checkReplyEmailExists();
          })
          .catch((e) => {
            console.error("Problem retrieving form data", e);
          });
      });
    },

    updateData() {
      this.saveStatus = "needsSaving";
    },

    addForm() {
      this.saveStatus = "saving";
      this.errorMessage = "";
      if (this.$refs.formDetails.validate()) {
        this.saveStatus = "saving";
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${process.env.VUE_APP_BASEURL}/formlayouts/add`,
            headers: { Authorization: jwt },
            data: {
              name_admin: this.updatedData.name_admin,
              description_admin: this.updatedData.description_admin,
              formMeta: {
                title: this.updatedData.formMeta.title
                  ? this.updatedData.formMeta.title
                  : null,
                description: this.updatedData.formMeta.description
                  ? this.updatedData.formMeta.description
                  : null,
                sendTo: this.updatedData.formMeta.sendTo,
                submitButtonText: this.updatedData.formMeta.submitButtonText,
                allowUserSelectEmail:
                  this.updatedData.formMeta.allowUserSelectEmail,
                autoPopulate: this.updatedData.formMeta.autoPopulate,
                sendToOptions: this.updatedData.formMeta.sendToOptions,
              },
              fields: this.formBody ? this.formBody : null,
            },
          })
            .then((response) => {
              console.log("form response", response);
              if (response.data.success && response.data.formID) {
                this.saveStatus = "saved";
                this.$router.push("/forms");
              } else {
                console.error("Problem creating form", e);
                this.saveStatus = "needsSaving";
                this.errorMessage = "Problem creating form";
              }
            })
            .catch((e) => {
              console.error("Problem creating form", e);
              this.saveStatus = "needsSaving";
              this.errorMessage = "Problem creating form";
            });
        });
      } else {
        this.errorMessage = "Certain entries are invalid or required";
        this.saveStatus = "needsSaving";
        console.error("Problem saving form", this.errorMessage);
      }
    },

    saveForm() {
      this.saveStatus = "saving";
      this.errorMessage = "";
      if (this.$refs.formDetails.validate()) {
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${process.env.VUE_APP_BASEURL}/formlayouts/updatesingle`,
            headers: { Authorization: jwt },
            data: {
              formID: this.updatedData.formID,
              name_admin: this.updatedData.name_admin,
              description_admin: this.updatedData.description_admin,
              formMeta: {
                title: this.updatedData.formMeta.title
                  ? this.updatedData.formMeta.title
                  : null,
                description: this.updatedData.formMeta.description
                  ? this.updatedData.formMeta.description
                  : null,
                sendTo: this.updatedData.formMeta.sendTo
                  ? this.updatedData.formMeta.sendTo
                  : null,
                submitButtonText: this.updatedData.formMeta.submitButtonText,
                allowUserSelectEmail:
                  this.updatedData.formMeta.allowUserSelectEmail,
                autoPopulate: this.updatedData.formMeta.autoPopulate,
                sendToOptions: this.updatedData.formMeta.sendToOptions,
              },
              fields: this.formBody ? this.formBody : null,
            },
          })
            .then((response) => {
              this.saveStatus = "saved";
            })
            .catch((e) => {
              console.error("Problem saving form", e);
              this.saveStatus = "needsSaving";
              this.errorMessage = "Problem saving form";
            });
        });
      } else {
        this.errorMessage = "Certain entries are invalid or required";
        this.saveStatus = "needsSaving";
        console.error("Problem saving form", this.errorMessage);
      }
    },

    addAddress() {
      this.updatedData.formMeta.sendToOptions.push({
        sendTo: null,
        name: null,
        id: Math.random().toString(36).slice(2),
      });
    },

    deleteEntry(entry) {
      this.updatedData.formMeta.sendToOptions.splice(entry, 1);
    },

    toggleFormBuilder() {
      this.formBuilder =
        this.formBuilder === true
          ? (this.formBuilder = false)
          : (this.formBuilder = true);
    },

    updateFields(formFields) {
      this.formBody = formFields;
      this.updateFormJSON();
      this.checkReplyEmailExists();
    },

    updateFormJSON() {
      this.formBodyJSON = JSON.stringify(this.formBody, undefined, 4);
    },

    removeField(field) {
      this.formBody.splice(field, 1);
      this.updateFormJSON();
      this.checkReplyEmailExists();
    },

    editField(field) {
      this.existingField = field;
    },

    saveJson() {
      if (confirm("Are you sure you have edited the JSON correctly?")) {
        this.formBody = JSON.parse(this.formBodyJSON);
      } else {
        console.log("JSON Edit cancelled");
      }
    },

    moveUp(field) {
      var item = this.formBody[field];
      this.formBody.splice(field, 1);
      this.formBody.splice(field - 1, 0, item);
    },

    moveDown(field) {
      var item = this.formBody[field];
      this.formBody.splice(field, 1);
      this.formBody.splice(field + 1, 0, item);
    },

    moveRowDown(index) {
      const location = this.updatedData;
      const element = this.updatedData[index];

      location.splice(index, 1);
      location.splice(index + 1, 0, element);
    },

    moveRowUp(index) {
      const location = this.updatedData;
      const element = this.updatedData[index];

      location.splice(index, 1);
      location.splice(index - 1, 0, element);
    },

    checkReplyEmailExists() {
      if (this.formBody && this.formBody.length) {
        const replyEmailId = this.formBody.find((el, index) => {
          return el.fieldOptions && el.fieldOptions.replyEmail;
        });
        this.replyEmailId = replyEmailId && replyEmailId.id;
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.form-section {
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 16px 16px 8px;
  margin-bottom: 16px;
  background-color: #fff;
}

.form-title {
  border-bottom: 1px solid #67247c;
  background-color: #fff;
  color: #24002f;
  padding: 0 16px;
  font-weight: 500;

  @media screen and (min-width: 701px) {
    padding: 0 52px 16px;
  }

  &__intro {
    opacity: 0.7;
  }
}

input {
  background-color: white;
  border: 1px solid #67247c;
  margin: 8px 0;
}

.form {
  &__meta {
    display: flex;
    flex-direction: column;
  }
}

.form-builder-button {
  margin: 8px 0;
}

.add-address {
  align-self: flex-end;
  margin-bottom: 8px;
}

table {
  th {
    text-align: left;
    font-weight: normal;
  }
  /deep/ .v-text-field__details {
    display: none;
  }
}

.form-builder-wrapper {
  min-width: 50%;
  width: 50%;
  padding-right: 16px;
  border-right: 1px dashed #ccc;

  &__header {
    display: flex;
    justify-content: space-between;
  }
  &--alt {
    width: 50%;
  }
}

.json-editor {
  background-color: #fff;

  /deep/ .v-text-field__details {
    display: none;
  }

  /deep/ .v-input__slot {
    margin-bottom: 0;
  }
}
</style>
