export default {
	"AdditionalPageTypes": true,
	"Admin": true,
	"Advertising": true,
	Advertising2: {
		adServers: ["simple"],
		adSizes: ["mpu", "banner", "banner-large"],
		allowedRoles: ["dev"]
	},
	"AggregatedContentFeed": true,
	"AllowCustomFields": false,
	"AppConfig": true,
	"AppEcommerceManager": true,
	"AppScorePredictor": false,
	"AppSocialManager": true,
	"AppTicketingManager": true,
	"BreakingNews": false,
	"BuildManager": true,
	"Calendar": false,
	"CustomCommentary": true,
	"CustomLineups": true,
	"CustomTables": true,
	"CustomTags": true,
	"Dashboard": true,
	"Fixtures": true,
	"Forms": true,
	"HomepageManager": true,
	"HomepageNewsPromo": true,
	"HomepagePlayerHighlight": true,
	"Lab": false,
	"MatchdayTakeover": true,
	"Media": true,
	"News": true,
	"OptaIntegration": true,
	"Organisations": false,
	"OverlayPromos": true,
	"Pages": true,
	"PlatformOptions": true,
	"PlayerVoting": true,
	"PreventEntitlementEmails": false,
	"Profile": true,
	"RelatedContentTagging": true,
	"RewardsManager": false,
	"RewardPoints": false,
	"Rover": false,
	"Settings": false,
	"SiteConfig": true,
	"Sponsors": true,
	"Squads": true,
	"SsoUsers": true,
	"StreamAMGIntegration": true,
	"Streamline": false,
	"StreamlineAdverts": false,
	"StreamlinePromoCodes": false,
	"Videos": false,
	"Voice": false,
	"WebStats": true
}
