<template>
  <v-dialog class="cta-widget" v-model="dialog" width="580" lazy>
    <slot slot="activator"></slot>

    <v-card>
      <v-card-title class="widget__heading"> Call To Action </v-card-title>

      <v-card-text>
        <v-form
          enctype="multipart/form-data"
          v-model="valid"
          ref="ctaForm"
          lazy-validation
        >
          <section>
            <label class="label" for="title">Title (optional)</label>
            <v-text-field
              id="title"
              outline
              v-model="ctaData.title"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
            ></v-text-field>

            <label class="label" for="description"
              >Description (optional)</label
            >
            <v-text-field
              id="description"
              outline
              v-model="ctaData.description"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
            ></v-text-field>

            <div class="columns">
              <div class="col col-1">
                <p class="widget__heading">
                  Primary Call To Action<br />(required)
                </p>

                <label class="label" for="ctaText">CTA Text</label>
                <v-text-field
                  id="ctaText"
                  outline
                  v-model="ctaData.ctaText"
                  solo
                  flat
                  class="text-field--lableless"
                  validate-on-blur
                ></v-text-field>

                <label class="label" for="ctaLink">Link</label>
                <v-text-field
                  id="ctaLink"
                  outline
                  v-model="ctaData.ctaLink"
                  solo
                  flat
                  class="text-field--lableless"
                  validate-on-blur
                ></v-text-field>

                <label class="label" for="ctaType">Button Type</label>
                <v-select v-model="ctaData.ctaType" :items="ctaTypes">
                </v-select>

                <label class="label" for="ctaIcon">Icon</label>
                <v-select v-model="ctaData.ctaIcon" :items="ctaIcons">
                </v-select>

                <label v-if="ctaData.ctaIcon" class="label" for="ctaIconLayout"
                  >Icon Position</label
                >
                <v-select
                  v-if="ctaData.ctaIcon"
                  v-model="ctaData.ctaIconLayout"
                  :items="ctaIconPositions"
                >
                </v-select>

                <label class="label" for="ctaNewTab">Open in New Tab?</label>
                <v-checkbox v-model="ctaData.ctaNewTab"> </v-checkbox>
              </div>

              <div class="col col-2">
                <p class="widget__heading">
                  Secondary Call To Action<br />(optional)
                </p>

                <label class="label" for="cta2Text">CTA Text</label>
                <v-text-field
                  id="cta2Text"
                  outline
                  v-model="ctaData.cta2Text"
                  solo
                  flat
                  class="text-field--lableless"
                  validate-on-blur
                ></v-text-field>

                <label class="label" for="cta2Link">Link</label>
                <v-text-field
                  id="cta2Link"
                  outline
                  v-model="ctaData.cta2Link"
                  solo
                  flat
                  class="text-field--lableless"
                  validate-on-blur
                ></v-text-field>

                <label class="label" for="cta2Type">Button Type</label>
                <v-select v-model="ctaData.cta2Type" :items="ctaTypes">
                </v-select>

                <label class="label" for="cta2Icon">Icon</label>
                <v-select v-model="ctaData.cta2Icon" :items="ctaIcons">
                </v-select>

                <label
                  v-if="ctaData.cta2Icon"
                  class="label"
                  for="cta2IconLayout"
                  >Icon Position</label
                >
                <v-select
                  v-if="ctaData.cta2Icon"
                  v-model="ctaData.ctaIcon2Layout"
                  :items="ctaIconPositions"
                >
                </v-select>

                <label class="label" for="cta2NewTab">Open in New Tab?</label>
                <v-checkbox v-model="ctaData.cta2NewTab"> </v-checkbox>

                <v-btn color="accent" depressed @click="clearCta()"
                  >Clear this call to action
                </v-btn>
              </div>
            </div>

            <v-btn color="accent" outline flat @click="dialog = false">
              Cancel
            </v-btn>

            <v-btn
              color="accent"
              depressed
              :disabled="!ctaData.ctaText && !ctaData.ctaLink"
              @click="validate()"
            >
              <span v-if="widgetData">Update Call To Action</span>
              <span v-else>Add Call To Action</span>
            </v-btn>
          </section>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "CtaWidget",

  props: {
    widgetData: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}`,
    dialog: false,
    valid: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    ctaData: {
      title: null,
      description: null,
      ctaText: null,
      ctaLink: null,
      ctaType: null,
      ctaIcon: null,
      ctaIconLayout: null,
      ctaNewTab: null,
      cta2Text: null,
      cta2Link: null,
      cta2Type: null,
      cta2Icon: null,
      ctaIcon2Layout: null,
      cta2NewTab: null,
    },
    ctaTypes: [
      "primary-dark",
      "primary-light",
      "ghost-dark",
      "ghost-light",
      "label-dark",
      "label-light",
    ],
    ctaIcons: [
      "",
      "Shirt",
      "Player",
      "Search",
      "SignIn",
      "Tickets",
      "Burger",
      "Article",
      "Match",
      "Hospitality",
      "Facebook",
      "Twitter",
      "Instagram",
      "Snapchat",
      "Youtube",
      "Whistle",
      "Attendance",
      "Ball",
      "Camera",
      "Video",
      "Basket",
      "Close",
      "ChevronLeft",
      "ChevronRight",
      "Plus",
      "Info",
      "Feed",
      "Lineups",
      "Stats",
      "Scores",
      "Quiz",
      "Download",
      "Tick",
      "Padlock",
      "Phone",
      "Email",
      "X",
      "Threads",
    ],
    ctaIconPositions: ["left", "right"],
  }),

  mounted() {
    console.log("CTA widget mounted");

    if (this.widgetData) {
      this.ctaData = JSON.parse(JSON.stringify(this.widgetData));
    }
  },

  methods: {
    validate() {
      this.errorMessage = "";
      if (this.$refs.ctaForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required";
      }
    },

    updateData() {
      if (this.ctaData.title === "") {
        this.ctaData.title = null;
      }
      if (this.ctaData.description === "") {
        this.ctaData.description = null;
      }
      if (this.ctaData.ctaText === "") {
        this.ctaData.ctaText = null;
      }
      if (this.ctaData.ctaLink === "") {
        this.ctaData.ctaLink = null;
      }
      if (this.ctaData.ctaType === "") {
        this.ctaData.ctaType = null;
      }
      if (this.ctaData.cta2Text === "") {
        this.ctaData.cta2Text = null;
      }
      if (this.ctaData.cta2Link === "") {
        this.ctaData.cta2Link = null;
      }
      if (this.ctaData.cta2Type === "") {
        this.ctaData.cta2Type = null;
      }

      console.log("Update data:", this.ctaData);
      this.$emit("updatedData", this.ctaData);
      this.dialog = false;
    },

    clearCta() {
      console.log("clearing CTA2", this.cta2Text);
      this.ctaData.cta2Text = null;
      this.ctaData.cta2Link = null;
      this.ctaData.cta2Type = null;
      this.ctaData.cta2Icon = null;
      this.ctaData.ctaIcon2Layout = null;
      this.ctaData.cta2NewTab = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.edit {
  margin: 0 10px 20px 0;
  width: 100%;
}

.columns {
  display: flex;
  flex-direction: row;
}

.col {
  flex-basis: 50%;
}

.col-1 {
  border-right: 1px solid #67247c;
  padding-right: 8px;
}

.col-2 {
  padding-left: 8px;
}
</style>
