<template>
  <v-dialog
    class="fixtures-list-widget"
    v-model="dialog"
    width="580"
    lazy
  >
    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
          Calendar
        </v-card-title>

        <v-card-text>

        <v-form enctype="multipart/form-data" ref="calendarForm" v-model="valid" lazy-validation>
          <label class="label" for="title">Title</label>
          <v-text-field
            id="title"
            outline
            v-model="sectionTitle"
            solo
            flat
            class="text-field--lableless"
            validate-on-blur
          ></v-text-field>

          <label class="label" for="teams">Teams</label>
          <v-select
          :items="teams"
          item-text="teamName"
          item-value="teamID"
          id="teams"
          v-model="selectedTeams"
          outline
          validate-on-blur
          return-object
          label="Select Teams"
          multiple
          ></v-select>
          
          <label class="label" for="events">Event Type</label>
          <v-select
          :items="eventTypes"
          item-text="name"
          item-value="value"
          id="events"
          v-model="selectedEvents"
          outline
          required
          :rules="[rules.required]"
          return-object
          validate-on-blur
          label="Select Event Types"
          multiple
          ></v-select>

          <template v-if="modules.default.Streamline">
            <label class="label" for="events">Stream Available</label>
            <v-select
            :items="[
              { name: 'Show any calendar items', value: false},
              { name: 'Show only calendar items with streaming available', value: true},
            ]"
            item-text="name"
            item-value="value"
            id="events"
            v-model="hasStreamingOnly"
            outline
            validate-on-blur
            label="Select streaming availability"
            ></v-select>
          </template>

          <label class="label" for="userFilter">Allow users to filter through events</label>
          <v-select
          :items="[
            { name: 'Yes, let users have an option to filter through events', value: true},
            { name: 'No, always show all items', value: false},
          ]"
          item-text="name"
          item-value="value"
          id="userFilter"
          v-model="allowUserFilter"
          outline
          required
          validate-on-blur
          label="User filtering permission"
          ></v-select>

          <!-- <label class="label" for="events">Background Mode</label>
          <v-select
          :items="[
            { name: 'Light Mode', value: 'light'},
            { name: 'Dark Mode', value: 'dark'},
            { name: 'Club Mode', value: 'club'},
          ]"
          item-text="name"
          item-value="value"
          id="events"
          v-model="mode"
          outline
          validate-on-blur
          label="Select the background mode"
          ></v-select> -->

          <v-btn
            color="accent"
            outline
            flat
            @click="dialog = false"
          >
            Cancel
          </v-btn>

          <v-btn 
            :disabled="!selectedEvents.length"
            color="accent"
            depressed
            @click="postCalendar()"
          >
            Add calendar
          </v-btn>

        </v-form>

        </v-card-text>

      </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "CalendarWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    modules: null,
    dialog: false,
    teams: null,
    eventTypes: [
      { name: "Match/Fixture", value: "match"},
      { name: "Events/Other", value: "event"},
      ],
    sectionTitle: null,
    selectedTeams: [],
    selectedEvents: [],
    hasStreamingOnly: false,
    allowUserFilter: true,
    // mode: 'light',
    rules: {
      required: value => !!value || "Required."
    },
    valid: false,
  }),

  created() {
    this.modules = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")

    if (this.widgetData) {
      const { sectionTitle, teams, eventTypes, hasStreamingOnly, allowUserFilter } = JSON.parse(JSON.stringify(this.widgetData))
      this.sectionTitle = sectionTitle
      this.selectedTeams = teams
      this.selectedEvents = eventTypes
      this.hasStreamingOnly = hasStreamingOnly
      this.allowUserFilter = allowUserFilter

      // this.mode = mode
    }
    this.fetchFixturesConfig()
  },

  methods: {
    fetchFixturesConfig() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_BASEURL}/fixtures/opta/filters`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("fetchFixturesConfig: ", response);
          this.teams = response.data.body;

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving fixtures", e);
          this.$store.commit("completeLoading");
        });
      })
    },
    
    postCalendar() {
      const data = {
        sectionTitle: this.sectionTitle,
        teams: this.selectedTeams,
        eventTypes: this.selectedEvents,
        hasStreamingOnly: this.hasStreamingOnly,
        allowUserFilter: this.allowUserFilter,
        // mode: this.mode
      }
      this.$emit("updatedData", data);
      this.dialog = false
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
