<template>
  <div class="list-widget-form">
    <h1>{{ formHeading }}</h1>

    <template>
      <label class="label" for="body">Row Title</label>

      <v-text-field
        id="title"
        v-model="formTitle"
        placeholder="Insert title"
        solo
        flat
      ></v-text-field>

      <label class="label" for="body">Row Body</label>
      <tinymce-editor
        v-model="formBody"
        api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
        :init="{
          height: '300px',
          menubar: false,
          relative_urls: false,
          plugins: 'table link paste wordcount hr lists textpattern',
          toolbar:
            'undo redo | styleselect | bold italic | \
          link blockquote table | \
          hr | \
          bullist numlist | removeformat',
          resize: false,
          textpattern_patterns: [
            {
              start: '//button',
              replacement: '<a class=\'button \'>Button here</a>',
            },
          ],
          link_class_list: [
            { title: 'None', value: '' },
            { title: 'Primary on Dark', value: 'button button--primary-dark' },
            {
              title: 'Primary on Light',
              value: 'button button--primary-light',
            },
            { title: 'Ghost on Dark', value: 'button button--ghost-dark' },
            { title: 'Ghost on Light', value: 'button button--ghost-light' },
            { title: 'Label on Dark', value: 'button button--label-dark' },
            { title: 'Label on Light', value: 'button button--label-light' },
          ],
          style_formats: [
            {
              title: 'Headers',
              items: [
                //{title: 'Header 1', format: 'h1'},
                { title: 'Header 2', format: 'h2' },
                { title: 'Header 3', format: 'h3' },
                { title: 'Header 4', format: 'h4' },
                { title: 'Header 5', format: 'h5' },
                { title: 'Header 6', format: 'h6' },
              ],
            },
            {
              title: 'Inline',
              items: [
                { title: 'Bold', icon: 'bold', format: 'bold' },
                { title: 'Italic', icon: 'italic', format: 'italic' },
                { title: 'Underline', icon: 'underline', format: 'underline' },
                //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                //{title: 'Code', icon: 'code', format: 'code'}
              ],
            },
            {
              title: 'Blocks',
              items: [
                { title: 'Paragraph', format: 'p' },
                { title: 'Blockquote', format: 'blockquote' },
                //{title: 'Div', format: 'div'},
                //{title: 'Pre', format: 'pre'}
              ],
            },
            {
              title: 'Alignment',
              items: [
                { title: 'Left', icon: 'alignleft', format: 'alignleft' },
                { title: 'Center', icon: 'aligncenter', format: 'aligncenter' },
                { title: 'Right', icon: 'alignright', format: 'alignright' },
                //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
              ],
            },
            { title: 'Small', selector: 'p', classes: 'small' },
          ],
          style_formats_merge: false,
          block_formats:
            'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote',
        }"
      ></tinymce-editor>

      <ImageUploader
        :title="'List Item Image (optional)'"
        :widthOptionEnabled="false"
        :mediaLibraryID="formImageID"
        @update="updateImage($event)"
      />
      <label class="label" for="imageAlignRight">Align Image Right?</label>
      <v-checkbox v-model="formImageAlignRight"> </v-checkbox>

      <label class="label" for="buttonText">Button Text</label>
      <v-text-field
        id="buttonText"
        outline
        v-model="formButtonText"
        solo
        flat
        class="text-field--lableless"
        validate-on-blur
      ></v-text-field>

      <label class="label" for="buttonLink">Link</label>
      <v-text-field
        id="buttonLink"
        outline
        v-model="formButtonLink"
        solo
        flat
        class="text-field--lableless"
        validate-on-blur
      ></v-text-field>

      <label class="label" for="buttonType">Button Type</label>
      <v-select v-model="formButtonType" :items="buttonTypes"> </v-select>

      <label class="label" for="buttonIcon">Icon</label>
      <v-select v-model="formButtonIcon" :items="buttonIcons"> </v-select>

      <label v-if="buttonIcon" class="label" for="buttonIconPosition"
        >Button Icon Position</label
      >
      <v-select
        v-if="buttonIcon"
        v-model="formButtonIconPosition"
        :items="buttonIconPositions"
      >
      </v-select>

      <label class="label" for="ctaNewTab">Open in New Tab?</label>
      <v-checkbox v-model="formButtonNewTab"> </v-checkbox>

      <v-btn color="accent" outline flat @click="saveRow()">
        Add to list
      </v-btn>

      <v-btn color="accent" outline flat @click="cancelNewRow()">
        Cancel
      </v-btn>
    </template>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import ImageUploader from "@/components/Library/ImageUploader.vue";

export default {
  name: "ListWidgetItemForm",

  components: {
    "tinymce-editor": Editor,
    ImageUploader,
  },

  props: {
    formHeading: {
      type: String,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    buttonType: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    buttonLink: {
      type: String,
    },
    buttonIcon: {
      type: String,
    },
    buttonIconPosition: {
      type: String,
    },
    buttonNewTab: {
      type: Boolean,
    },
    imageID: {
      type: String,
    },
    imageAlignRight: {
      type: Boolean,
    },
    edit: {
      type: Boolean,
    },
  },

  data: () => ({
    formTitle: null,
    formBody: null,
    formImageID: null,
    formImageAlignRight: null,
    formButtonText: null,
    formButtonLink: null,
    formButtonType: null,
    formButtonNewTab: null,
    formButtonIcon: null,
    formButtonIconPosition: null,
    //Button Options
    buttonTypes: [
      "primary-dark",
      "primary-light",
      "ghost-dark",
      "ghost-light",
      "label-dark",
      "label-light",
    ],
    buttonIcons: [
      "",
      "Shirt",
      "Player",
      "Search",
      "SignIn",
      "Tickets",
      "Burger",
      "Article",
      "Match",
      "Hospitality",
      "Facebook",
      "Twitter",
      "Instagram",
      "Snapchat",
      "Youtube",
      "Whistle",
      "Attendance",
      "Ball",
      "Camera",
      "Video",
      "Basket",
      "Close",
      "ChevronLeft",
      "ChevronRight",
      "Plus",
      "Info",
      "Feed",
      "Lineups",
      "Stats",
      "Scores",
      "Quiz",
      "Download",
      "Tick",
      "Padlock",
      "Phone",
      "Email",
      "X",
      "Threads",
    ],
    buttonIconPositions: ["left", "right"],
  }),

  mounted() {
    if (this.title) {
      this.formTitle = JSON.parse(JSON.stringify(this.title));
    }
    if (this.content) {
      this.formBody = JSON.parse(JSON.stringify(this.content));
    }
    if (this.imageID) {
      this.formImageID = JSON.parse(JSON.stringify(this.imageID));
    }
    if (this.imageAlignRight) {
      this.formImageAlignRight = JSON.parse(
        JSON.stringify(this.imageAlignRight)
      );
    }
    if (this.buttonText) {
      this.formButtonText = JSON.parse(JSON.stringify(this.buttonText));
    }
    if (this.buttonLink) {
      this.formButtonLink = JSON.parse(JSON.stringify(this.buttonLink));
    }
    if (this.buttonType) {
      this.formButtonType = JSON.parse(JSON.stringify(this.buttonType));
    }
    if (this.buttonIcon) {
      this.formButtonIcon = JSON.parse(JSON.stringify(this.buttonIcon));
    }
    if (this.buttonIconPosition) {
      this.formButtonIconPosition = JSON.parse(
        JSON.stringify(this.buttonIconPosition)
      );
    }
    if (this.buttonNewTab) {
      this.formButtonNewTab = JSON.parse(JSON.stringify(this.buttonNewTab));
    }
  },

  methods: {
    saveRow() {
      console.log("Save 1", {
        content: this.formBody,
        title: this.formTitle,
        buttonText: this.formButtonText,
        buttonLink: this.formButtonLink,
        buttonType: this.formButtonType,
        buttonIcon: this.formButtonIcon,
        buttonIconPosition: this.formButtonIconPosition,
        buttonNewTab: this.formButtonNewTab,
        imageID: this.formImageID,
        imageAlignRight: this.formImageAlignRight,
      });
      this.$emit("saveRow", {
        content: this.formBody,
        title: this.formTitle,
        buttonText: this.formButtonText,
        buttonLink: this.formButtonLink,
        buttonType: this.formButtonType,
        buttonIcon: this.formButtonIcon,
        buttonIconPosition: this.formButtonIconPosition,
        buttonNewTab: this.formButtonNewTab,
        imageID: this.formImageID,
        imageAlignRight: this.formImageAlignRight,
      });
    },

    cancelNewRow() {
      if (confirm("Are you sure you want to cancel?")) {
        this.$emit("cancelEdit");
      }
    },

    cancelEdit() {
      if (confirm("If you cancel your edits will not be saved")) {
        this.$emit("cancelEdit");
      }
    },

    updateImage(payload) {
      console.log("image payload", payload);
      this.formImageID = payload.mediaLibraryID;
    },
  },
};
</script>

<style scoped lang='scss'>
.list-item-form {
  padding: 16px;
  margin: 16px -16px;
  background-color: #eee6f1;
  border-radius: 4px;
}
</style>
