export default {
  footerSponsors: true,

  loadingScreen_App: false,
  homepageNews_App: false,
  newsListing_App: false,
  matchCentre_FirstTeam_App: false,
  fixturesPage_FirstTeam_App: false,
  playerOfTheMatch_App: false,
  scorePredictor_App: false,

  homepageNews_Web: false,
  newsListing_Web: false,
  matchCentre_FirstTeam_Web: false,
  fixturesPage_FirstTeam_Web: false,
  playerOfTheMatch_Web: false,
}
