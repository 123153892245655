<template>
  <v-dialog class="fixtures-list-widget" v-model="dialog" width="580" lazy>
    <slot slot="activator"></slot>

    <v-card>
      <v-card-title class="widget__heading"> All Fixtures </v-card-title>

      <v-card-text>
        <!-- <v-card-text v-if="!addFixtureForm" class="fixtures-list-widget__add" @click="clearFixtureForm(), addFixtureForm = true">
          <v-icon color="#fff" class="fixtures-list-widget__icon">add</v-icon> <span class="fixtures-list-widget__text">Add fixture</span>
        </v-card-text> -->

        <!-- <v-form v-if="addFixtureForm" enctype="multipart/form-data" v-model="valid" ref="fixtureWidgetForm" lazy-validation> -->
        <v-form
          enctype="multipart/form-data"
          v-model="valid"
          ref="fixtureWidgetForm"
          lazy-validation
        >
          <label class="label" for="squad">Select Squad</label>
          <v-select
            :items="squads"
            item-text="name"
            item-value="id"
            id="squad"
            v-model="fixtureListData.squad"
            outline
            required
            :rules="[rules.required]"
            validate-on-blur
            label="Select Squad"
            @change="
              seasons = [];
              fixtureListData.season = null;
              getSeasons($event);
            "
          ></v-select>

          <label class="label" for="season">Select Season</label>
          <v-select
            :items="seasons"
            item-text="seasonName"
            item-value="seasonID"
            id="season"
            :disabled="!fixtureListData.squad"
            v-model="fixtureListData.season"
            outline
            required
            :rules="[rules.required]"
            validate-on-blur
            label="Select Season"
            @change="valid = true"
          ></v-select>

          <!-- <label class="label" for="season">Select Competition</label>
          <v-select
          :items="competitions"
          item-text="competitionName"
          item-value="competitionID"
          id="season"
          :disabled="!multiFixtureListData.season"
          v-model="multiFixtureListData.competition"
          outline
          required
          :rules="[rules.required]"
          validate-on-blur
          label="Select Competition"
          @change="matches = []; getFixtures($event);"
          ></v-select> -->

          <!-- <label class="label" for="match">Select Match</label>
          <v-select
          :items="matches"
          item-text="matchLabel"
          item-value="matchID"
          id="match"
          :disabled="!singleFixtureListData.competition || !matches.length"
          v-model="singleFixtureListData.matchID"
          outline
          required
          :rules="[rules.required]"
          validate-on-blur
          :label="matchHTMLLabel"
          @change="setMatch($event)"
          ></v-select> -->

          <v-btn color="accent" outline flat @click="dialog = false">
            Cancel
          </v-btn>

          <v-btn v-if="valid" color="accent" depressed @click="postFixtures()">
            Add fixtures
          </v-btn>
        </v-form>
      </v-card-text>

      <!-- <v-card-text v-if="multiFixtureListData.length"> -->

      <!-- <draggable
            v-model="multiFixtureListData"
            :options="{
              ghostClass: 'ghost',
              dragClass: 'sortable-drag'
            }"
            @start="drag=true"
            @end="drag=false">
            <transition-group name="list-complete" class="fixtures-list-widget__fixtures">
              <div class="fixtures-list-widget__fixtures-item draggable-item" v-for="(fixture, key) in multiFixtureListData" :key="fixture.guid" @delete="">
                <div class="fixtures-list-widget__fixtures-item-left">
                  <div class="fixtures-list-widget__fixtures-item-crest" :style="`background-image:url('${fixture.matchDetails[0].matchHomeCrest}')`"></div>
                  <ul class="fixtures-list-widget__fixtures-item-details">
                    <li>
                      {{fixture.matchDetails[0].matchLabel}}
                    </li>
                    <li>
                      {{fixture.matchDetails[0].seasonName}}
                    </li>
                    <li>
                      {{fixture.matchDetails[0].competitionName}}
                    </li>
                  </ul>
                </div>
                <div class="fixtures-list-widget__fixtures-item-action" >
                  <button type="button" @click="deleteFixtureItem(key)">
                    <v-icon>delete</v-icon>
                  </button>
                  <button type="button" @click="editFixtureItem(fixture.guid)">
                    <v-icon>edit</v-icon>
                  </button>
                </div>
              </div>
          </transition-group>
        </draggable> -->

      <!-- </v-card-text> -->
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import moment from "moment";

export default {
  name: "AllFixturesWidget",

  components: {
    draggable,
  },

  props: {
    widgetData: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/fixtures`,
    dialog: false,
    // addFixtureForm: false,
    valid: false,
    fixturesConfig: [],
    fixtureListData: {
      squad: null,
      season: null,
      squadName: null,
    },
    squads: [],
    seasons: [],
    // competitions: [],
    rules: {
      required: (value) => !!value || "Required.",
    },
    matchHTMLLabel: "Select Match",
  }),

  mounted() {
    console.log("All Fixture list mounted");
    console.log(this.widgetData);
    if (this.widgetData) {
      this.fixtureListData = JSON.parse(
        JSON.stringify(this.widgetData.fixtures)
      );
    }
    this.$store.commit("startLoading");
    this.$getCurrentUserJwToken().subscribe((jwt) => {
      axios
        .get(`${this.api}/opta/filters`, { headers: { Authorization: jwt } })
        .then((response) => {
          console.log("response: ", response);
          this.fixturesConfig = response.data.body;

          this.squads = this.fixturesConfig.map((item) => {
            return { name: item.teamName, id: item.teamID };
          });

          this.$store.commit("completeLoading");
        })
        .catch((e) => {
          console.error("Problem retrieving fixtures", e);
          this.$store.commit("completeLoading");
        });
    });
  },

  methods: {
    getSeasons(e) {
      this.fixtureListData.squad = e;
      const season = this.fixturesConfig.filter(
        (item) => item.teamID === this.fixtureListData.squad
      );

      if (season[0].seasons) {
        this.seasons = season[0].seasons;
        console.log(this.seasons);
      } else {
        this.seasons = "No season data has been found";
        console.log("No season data has been found");
      }
    },
    // getCompetitions(e){
    //   this.singleFixtureListData.season = e;
    //   const competition = this.seasons.filter(item => item.seasonID === this.singleFixtureListData.season);

    //   if(competition[0].competitions) {
    //     this.competitions = competition[0].competitions;
    //     console.log(this.competitions);
    //   }
    //   else {
    //     this.competitions = 'No competition data has been found'
    //     console.log('No competition data has been found');
    //   }
    // },

    postFixtures() {
      this.setTeamName(this.fixtureListData.squad);
      console.log("postAllFixtures", this.fixtureListData);
      this.$emit("updatedData", { fixtures: this.fixtureListData });
    },

    setTeamName(e) {
      const team = this.squads.filter((item) => item.id === e);
      this.fixtureListData.squadName = team[0].name;
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-item {
  transition: all 0.5s, opacity 0.2s;
}
.fixtures-list-widget {
  &__add {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__icon {
    background-color: $purple;
    border-radius: 50rem;
    padding: 10px;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
  }
  &__text {
    color: $lightPurple;
    padding: 10px;
  }
  &__fixtures {
    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      border-top: 1px solid $lightgrey;
      padding: 20px;
      justify-content: space-between;
      &-details {
        padding-left: 20px;
        li {
          color: $grey;
          font-size: 0.9rem;
          margin-bottom: 5px;
        }
      }
      &-crest {
        width: 64px;
        height: 64px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      &:last-child {
        border-bottom: 1px solid $lightgrey;
      }
      &-action {
        display: flex;
      }
      &-left {
        display: flex;
        flex-flow: row;
      }
    }
  }
}
</style>
