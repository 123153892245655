<template>
  <div class="season-tagger">
    <v-combobox
      id="season-tagger"
      outline
      v-model="selected"
      :items="optionsData"
      item-value="name"
      item-text="name"
      no-data-text="loading..."
      multiple
      solo
      flat
      clearable
      dense
      chips
      deletable-chips
      @change="$emit('selected', selected)"
      >
    </v-combobox>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "SquadTagger",

  props: {
    values: {
      type: Array,
      required: false
    }
  },

  data:() => ({
    optionsData: [
      "2022", "2021", "2020"
    ],
    selected: null
  }),

  mounted() {
    this.selected = JSON.parse(JSON.stringify(this.values))
    // this.getSquads()
  }
}
</script>

<style scoped lang="scss">
  .team-tagger {

  }
</style>
