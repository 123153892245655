<template>
  <v-dialog
    class="contact-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
        Contact
        </v-card-title>

        <v-card-text>

        <v-form enctype="multipart/form-data" v-model="valid" ref="contactForm" lazy-validation>

          <section>
            <label class="label" for="title">Title</label>
            <v-text-field
              id="title"
              outline
              v-model="contactData.title"
              solo
              flat
              :rules="[rules.required]"
              class="text-field--lableless"
              validate-on-blur
            ></v-text-field>

            <label class="label" for="text">Contact Text</label>
            <tinymce-editor v-model="contactData.text"
              api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
              :init="{
              height: '180px',
              menubar: false,
              relative_urls: false,
              plugins: 'link paste wordcount hr lists textpattern',
              toolbar:
                'undo redo | styleselect | bold italic | \
                link | \
                hr | \
                bullist numlist | removeformat',
              resize: false,
              textpattern_patterns: [
                {start: '//button', replacement: '<a class=\'button \'>Button here</a>'}
              ],
              link_class_list: [
                {title: 'None', value: ''},
                {title: 'Primary on Dark', value: 'button button--primary-dark'},
                {title: 'Primary on Light', value: 'button button--primary-light'},
                {title: 'Ghost on Dark', value: 'button button--ghost-dark'},
                {title: 'Ghost on Light', value: 'button button--ghost-light'},
                {title: 'Label on Dark', value: 'button button--label-dark'},
                {title: 'Label on Light', value: 'button button--label-light'},
              ],
              style_formats: [
                {title: 'Headers', items: [
                  //{title: 'Header 1', format: 'h1'},
                  {title: 'Header 2', format: 'h2'},
                  {title: 'Header 3', format: 'h3'},
                  {title: 'Header 4', format: 'h4'},
                  {title: 'Header 5', format: 'h5'},
                  {title: 'Header 6', format: 'h6'}
                ]},
                {title: 'Inline', items: [
                  {title: 'Bold', icon: 'bold', format: 'bold'},
                  {title: 'Italic', icon: 'italic', format: 'italic'},
                  {title: 'Underline', icon: 'underline', format: 'underline'},
                  //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                  //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                  //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                  //{title: 'Code', icon: 'code', format: 'code'}
                ]},
                {title: 'Blocks', items: [
                  {title: 'Paragraph', format: 'p'},
                  {title: 'Blockquote', format: 'blockquote'},
                  //{title: 'Div', format: 'div'},
                  //{title: 'Pre', format: 'pre'}
                ]},
                {title: 'Alignment', items: [
                  {title: 'Left', icon: 'alignleft', format: 'alignleft'},
                  {title: 'Center', icon: 'aligncenter', format: 'aligncenter'},
                  {title: 'Right', icon: 'alignright', format: 'alignright'},
                  //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
                ]},
                {title: 'Small', selector: 'p', classes: 'small'}
              ],
              style_formats_merge: false,
              block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote'
            }"></tinymce-editor>

            <label class="label editor-buffer" for="number">Contact Number</label>
            <v-text-field
              id="number"
              outline
              v-model="contactData.number"
              solo
              flat
            
              class="text-field--lableless"
              validate-on-blur
            ></v-text-field>

            <!-- <label class="label" for="buttonText">Button Text</label>
            <v-text-field
              id="buttonText"
              outline
              v-model="contactData.buttonText"
              solo
              flat
              :rules="[rules.required]"
              class="text-field--lableless"
              validate-on-blur
            ></v-text-field> -->

            <label class="label" for="buttonLink">Email Address</label>
            <v-text-field
              id="buttonLink"
              outline
              v-model="contactData.buttonLink"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
            ></v-text-field>

            <ImageUploader :title="'Profile Image (optional)'" :widthOptionEnabled="false" :mediaLibraryID="contactData.imageID" @update="updateImage($event)" />

            <label class="label" for="fullName">Full Name (optional)</label>
            <v-text-field
              id="fullName"
              outline
              v-model="contactData.fullName"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
            ></v-text-field>

            <label class="label" for="jobTitle">Job Title (optional)</label>
            <v-text-field
              id="jobTitle"
              outline
              v-model="contactData.jobTitle"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
            ></v-text-field>

            <label class="label" for="text">Disclaimer Text (optional)</label>
            <tinymce-editor v-model="contactData.disclaimerText"
              api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
              :init="{
              height: '100px',
              menubar: false,
              relative_urls: false,
              plugins: 'link paste wordcount hr lists textpattern',
              toolbar:
                'undo redo | bold italic | \ link | \ | removeformat',
              resize: false,
              textpattern_patterns: [
                {start: '//button', replacement: '<a class=\'button \'>Button here</a>'}
              ],
              link_class_list: [
                {title: 'None', value: ''},
                {title: 'Primary on Dark', value: 'button button--primary-dark'},
                {title: 'Primary on Light', value: 'button button--primary-light'},
                {title: 'Ghost on Dark', value: 'button button--ghost-dark'},
                {title: 'Ghost on Light', value: 'button button--ghost-light'},
                {title: 'Label on Dark', value: 'button button--label-dark'},
                {title: 'Label on Light', value: 'button button--label-light'},
              ],
              block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote'
            }"></tinymce-editor>

            <v-btn
              color="accent"
              outline
              flat
              @click="dialog = false"
            >
              Cancel
            </v-btn>

            <v-btn
              color="accent"
              depressed
              :disabled="!contactData.title"
              @click="validate()"
            >
              <span v-if="widgetData">Update contact</span>
              <span v-else>Add contact</span>
            </v-btn>
          </section>

        </v-form>

        </v-card-text>
      </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"
import Editor from "@tinymce/tinymce-vue";
import ImageUploader from "@/components/Library/ImageUploader.vue"
export default {
  name: "ContactWidget",
  components: {
    "tinymce-editor": Editor,
    ImageUploader
  },
  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },
  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}`,
    dialog: false,
    valid: false,
    rules: {
      required: value => !!value || "Required."
    },
    contactData: {
      title: null,
      text: null,
      imageID: null,
      number: null,
      buttonLink: null,
    },
  }),
  mounted() {
    console.log("Contact widget mounted");
    if (this.widgetData) {
      this.contactData = JSON.parse(JSON.stringify(this.widgetData));
    }
  },
  methods: {
    updateImage(payload) {
      console.log("Update image:", payload);
      this.contactData.imageID = payload.mediaLibraryID
    },
    validate() {
      this.errorMessage = ""
      if (this.$refs.contactForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },
    updateData() {
      if (this.contactData.number === "") {
        this.contactData.number = null
      }
      if (this.contactData.buttonLink === "") {
        this.contactData.buttonLink = null
      }
      this.$emit("updatedData", this.contactData);
      this.dialog = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit {
    margin: 0 10px 20px 0;
    width: 100%;
  }
  .editor-buffer {
    margin-top: 32px;
  }
</style>
