<template>
  <section class="fav-library">
    <template v-for="image in favourites" >
      <div @click="mediaItemSelect(image)" :key="image.mediaLibraryID" :class="{'selected' : image.mediaLibraryID === singleSelected}">
        <MediaItem
          :mediaItem="image"
          :includeManagementButtons="false"
          :singleSelection="true"/>
      </div>
    </template>
  </section>
</template>

<script>
import MediaItem from "@/components/Media/MediaItem.vue"

export default {
  name: "MediaFavourites",

  components: {
    MediaItem
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/media`,
    favourites: [],
    singleSelected: null
  }),

  mounted() {
    this.getFavourites()
  },

  methods: {
    mediaItemSelect(mediaItem) {
      console.log("mediaItem", mediaItem)
      this.singleSelected = mediaItem.mediaLibraryID
      this.$emit("selectedImage", this.singleSelected)
    },

    getFavourites() {
      this.favourites = []
      var vm = this
      var localFavourites = localStorage.mediaFavourites.split("|")
      console.log(localFavourites)
      localFavourites.forEach(function(item) {
        if(item.length > 0){
          var pushItem = JSON.parse(item)
          vm.favourites.push(pushItem)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
  .fav-library {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
  }

  .selected {
    border: 2px solid purple;
  }
</style>
