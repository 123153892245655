<template>
  <v-dialog
    class="upcoming-fixtures-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>

      <v-card>
        <v-card-title
          class="widget__heading"
        >
          Upcoming Fixtures
        </v-card-title>

        <v-card-text>

        <v-form enctype="multipart/form-data" v-model="valid" ref="upcomingFixturesForm" lazy-validation>
          <!-- <div class="upcoming-fixture-widget">
            <input v-model="upcomingFixturesData.nextFixtures" type="radio" class="upcoming-fixture-widget__item upcoming-fixture-widget__item--one" name="nextFixtures" value="1" id="nextFixturesOne">
            <label for="nextFixturesOne">
              <span>Single Fixture</span>
            </label>
            <input v-model="upcomingFixturesData.nextFixtures" type="radio" class="upcoming-fixture-widget__item upcoming-fixture-widget__item--two" name="nextFixtures" value="2" id="nextFixturesTwo">
            <label for="nextFixturesTwo">
              <span>Two Fixtures</span>
            </label>
            <input v-model="upcomingFixturesData.nextFixtures" type="radio" class="upcoming-fixture-widget__item upcoming-fixture-widget__item--three" name="nextFixtures" value="3" id="nextFixturesThree">
            <label for="nextFixturesThree">
              <span>Three Fixtures</span>
            </label>
            <input v-model="upcomingFixturesData.nextFixtures" type="radio" class="upcoming-fixture-widget__item upcoming-fixture-widget__item--four" name="nextFixtures" value="4" id="nextFixturesFour">
            <label for="nextFixturesFour">
              <span>Four Fixtures</span>
            </label>
            <input v-model="upcomingFixturesData.nextFixtures" type="radio" class="upcoming-fixture-widget__item upcoming-fixture-widget__item--five" name="nextFixtures" value="5" id="nextFixturesFive">
            <label for="nextFixturesFive">
              <span>Five Fixtures</span>
            </label>
          </div> -->

          <label class="label" for="nextFixtures">Fixtures to display</label>
          <v-text-field id="nextFixtures" 
            v-model="upcomingFixturesData.nextFixtures"
            hint="To show all your upcoming fixtures put a large number in eg 100"
            persistent-hint
            label="Number of fixtures"
            required
            type="number"
            :rules="[rules.required]"
          ></v-text-field>

          <label class="label" for="fixtureType">Home, Away or Both Fixtures</label>
          <v-select
          :items="['Home', 'Away', 'Both']"
          id="fixtureType"
          v-model="upcomingFixturesData.homeAwayBoth"
          outline
          validate-on-blur
          required
          :rules="[rules.required]"
          label="Select fixture type"
          ></v-select>

          <label class="label" for="squad">Select Squad</label>
          <v-select
          :items="squads"
          item-text="name"
          item-value="id"
          id="squad"
          v-model="upcomingFixturesData.teamID"
          outline
          validate-on-blur
          required
          :rules="[rules.required]"
          label="Select Squad"
          @change="setTeamName($event)"
          ></v-select>

          <v-btn
            color="accent"
            outline
            flat
            @click="dialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="accent"
            depressed
            @click="validate()"
          >
            <div v-if="widgetData">
              Update upcoming fixtures
            </div>
            <div v-else>
                Add upcoming fixtures
            </div>
          </v-btn>

        </v-form>

        </v-card-text>
      </v-card>

  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "UpcomingFixturesWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    },
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/fixtures/opta/filters`,
    dialog: false,
    valid: false,
    fixturesConfig: null,
    squads: [],
    rules: {
      required: value => !!value || "Required."
    },
    upcomingFixturesData: {
      nextFixtures: "5",
      homeAwayBoth: "Both",
      teamID: null,
      teamName: null
    }
  }),

  mounted() {
    console.log("Upcoming fixtures widget mounted");

    if (this.widgetData) {
      this.upcomingFixturesData = JSON.parse(JSON.stringify(this.widgetData));
    }

    this.$store.commit("startLoading");
    this.$getCurrentUserJwToken().subscribe((jwt) => {
      axios
      .get(this.api, { "headers": { "Authorization": jwt }})
      .then(response => {
        console.log("Opta filter response: ", response);
        this.fixturesConfig = response.data.body;

        this.squads = this.fixturesConfig.map(item => {
          return {name: item.teamName, id: item.teamID}
        });
        console.log("Squads", this.squads);
        this.$store.commit("completeLoading");
      })
      .catch(e => {
        console.error("Problem retrieving squads", e);
        this.$store.commit("completeLoading");
      });
    })
  },

  methods: {
    setTeamName(e) {
      const team = this.squads.filter(item => item.id === e);
      this.upcomingFixturesData.teamName = team[0].name;
    },
    validate() {
      this.errorMessage = ""
      if (this.$refs.upcomingFixturesForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },
    updateData() {
      console.log("Update data:", this.upcomingFixturesData);
      this.$emit("updatedData", this.upcomingFixturesData);
      this.dialog = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.upcoming-fixture-widget {
    margin: 10px 0 30px;
    display: flex;
    justify-content: space-evenly;
    &__item {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      + label {
          cursor: pointer;
          text-align: center;
          &:before {
              content: '';
              width: 64px;
              height: 56px;
              display: block;
              cursor: pointer;
              margin: auto;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              opacity: 0.5;
          }
          span {
            font-size: 1rem;
            color: $purple;
            margin-top: 5px;
            display: block;
            border-radius: 100px;
            padding: 3px 6px;
          }
      }
      &:checked {
        + label{
          &:before {
              opacity: 1.0;
          }
          span {
            background-color: #67247c;
            color: white;
          }
        }
      }
      &--one {
        + label:before {
          background-image: url('../../assets/upcoming-fixtures-1.svg');
        }
      }
      &--two {
        + label:before {
          background-image: url('../../assets/upcoming-fixtures-2.svg');
        }
      }
      &--three {
        + label:before {
          background-image: url('../../assets/upcoming-fixtures-3.svg');
        }
      }
      &--four {
        + label:before {
          background-image: url('../../assets/upcoming-fixtures-4.svg');
        }
      }
      &--five {
        + label:before {
          background-image: url('../../assets/upcoming-fixtures-5.svg');
        }
      }
    }
}
</style>
