<template>
  <!-- Sergie: Currently unused, can probably be deleted but I'm keeping it until release -->
  <section>
    <div class="section-header">
      <div class="title-container">
        <icon-selector class="header-icon" icon="Group" />
        <h2 class="section-title">Groups</h2>
      </div>
      <v-btn
        class="add-button"
        @click="openModal()"
        accent
        type="button"
        color="accent"
        depressed
        ><v-icon medium>add</v-icon>Create New Group</v-btn
      >
    </div>
    <p class="section-description">
      Groups are a collection of multiple advert collections for displaying a
      series of adverts in one slot. A Group is required in order to placed into
      a Slot.
    </p>
    <v-dialog
      class="dialog"
      v-model="dialog"
      width="400"
      scrollable
      lazy
      persistent
    >
      <v-card>
        <v-card-title class="card-title"
          >{{ editing ? "Edit" : "Create New" }} Group</v-card-title
        >

        <div class="data-area">
          <label class="label" for="groupName">Group Name</label>
          <v-text-field
            id="groupName"
            solo
            flat
            v-model="modalData.title"
          ></v-text-field>
        </div>

        <v-card-actions class="modal-buttons">
          <v-btn
            accent
            type="button"
            color="accent"
            depressed
            @click="editLink()"
          >
            Save
          </v-btn>
          <v-btn text flat @click="cancel()"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import IconSelector from "@/components/Library/IconSelector";

export default {
  name: "Advertising2Groups",

  components: {
    IconSelector,
  },

  data: () => ({
    dialog: false,
    editing: false,
    modalData: {
      title: "",
    },
  }),

  methods: {
    openModal(editing = false) {
      this.dialog = true;
      this.editing = editing;
    },

    cancel() {
      this.dialog = false;
      this.modalData = {
        title: null,
        // Need more data
      };
    },
  },
};
</script>

<style scoped lang='scss'>
.section-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.header-icon {
  margin-right: 15px;
  color: #67247c;
}
.title-container {
  display: flex;
  align-items: center;
}
.section-title {
  color: #67247c;
  font-size: 20px;
  font-weight: 500;
  margin-right: 10px;
}
.add-button {
  font-weight: 500;
  font-size: 14px;
  text-transform: none;
  margin: 0;
  i {
    font-weight: bold;
    margin-right: 10px;
  }
}
.section-description {
  max-width: 648px;
  color: #516173;
  font-size: 14px;
  margin-bottom: 24px;
}

.card-title {
  font-size: 20px;
  color: #24002f;
  margin-bottom: 15px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.16);
}

.card-title {
  margin-bottom: 0;
}

.data-area {
  padding-left: 16px;
  padding-right: 16px;
}

.label {
  font-size: 12px;
  font-weight: 500;
  color: #24002f;
}
</style>
