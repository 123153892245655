<template>
    <form class="main-body">
        <h2 class="subheading">{{ heading }}</h2>

        <section class="section-container">
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Membership Title</label>
                    <v-text-field
                        id="field"
                        v-model="membershipsAdmin.title"
                        solo
                        flat
                        required
                        @input="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will set the title for this current membership option.</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Entitlements</label>
                    <v-text-field
                        id="field"
                        v-model="membershipsAdmin.mappedEntitlements"
                        solo
                        flat
                        required
                        @input="updateData()"
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will set the entitlements for this current membership
                            option.</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout
                v-if="!modules.default.LFCExclusive"
                row
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Render Label</label>
                    <v-select
                        v-model="membershipsAdmin.renderLabel"
                        item-text="name"
                        item-value="value"
                        solo
                        flat
                        :items="[
                            { name: 'Yes, render label', value: true },
                            { name: `No, don't render label`, value: false },
                        ]"
                    />
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will set the render label for this current membership
                            option.</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout
                v-if="!modules.default.LFCExclusive"
                row
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Branding Background Colour</label>
                    <v-text-field
                        id="field"
                        v-model="membershipsAdmin.backgroundColour"
                        solo
                        flat
                        required
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will set the branding background colour for this current
                            membership option. (Hex Value)</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout
                v-if="!modules.default.LFCExclusive"
                row
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Branding Text Colour</label>
                    <v-text-field
                        id="field"
                        v-model="membershipsAdmin.textColour"
                        solo
                        flat
                        required
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will set the branding text colour for this current
                            membership option. (Hex Value)</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout
                v-if="!modules.default.LFCExclusive"
                row
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Display Image</label>
                    <v-select
                        v-model="membershipsAdmin.displayImage"
                        item-text="name"
                        item-value="value"
                        solo
                        flat
                        :items="[
                            { name: 'Yes, display image', value: true },
                            { name: 'No, do not display image', value: false },
                        ]"
                    />
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will set the display image to true or false for this current
                            membership option.</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <ImageUploader
                        :title="'Logo'"
                        :widthOptionEnabled="false"
                        :mediaLibraryID="membershipsAdmin.logoMediaLibraryID"
                        @update="updateImage($event, 'logoMediaLibraryID', 'logoKey')"
                    />
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will set the logo for this current membership option.</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout
                v-if="!modules.default.LFCExclusive"
                row
                :style="'margin-bottom: 16px'"
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Description</label>
                    <!-- <v-text-field
            id="field"
            v-model="membershipsAdmin.description"
            solo
            flat
            required
          ></v-text-field> -->
                    <tinymce-editor
                        v-model="membershipsAdmin.description"
                        api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
                        :init="{
                            height: '300px',
                            menubar: false,
                            relative_urls: false,
                            plugins: 'table link paste wordcount hr lists textpattern code',
                            toolbar:
                                'undo redo | styleselect | bold italic forecolor | \
              link blockquote table | \
              alignleft aligncenter alignright | \
              hr | \
              bullist numlist | removeformat | \ code',
                            resize: false,
                            custom_colors: false,
                            color_map: [
                                '212121',
                                'Black',
                                'aaaaaa',
                                'Gray',
                                'FFFFFF',
                                'White',
                            ],
                            style_formats: [
                                //{title: 'Headers', items: [
                                //{title: 'Header 1', format: 'h1'},
                                //{title: 'Header 2', format: 'h2'},
                                //{title: 'Header 3', format: 'h3'},
                                //{title: 'Header 4', format: 'h4'},
                                //{title: 'Header 5', format: 'h5'},
                                //{title: 'Header 6', format: 'h6'}
                                //]},
                                {
                                    title: 'Inline',
                                    items: [
                                        { title: 'Bold', icon: 'bold', format: 'bold' },
                                        { title: 'Italic', icon: 'italic', format: 'italic' },
                                        {
                                            title: 'Underline',
                                            icon: 'underline',
                                            format: 'underline',
                                        },
                                        //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                                        //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                                        //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                                        //{title: 'Code', icon: 'code', format: 'code'}
                                    ],
                                },
                                {
                                    title: 'Blocks',
                                    items: [
                                        { title: 'Paragraph', format: 'p' },
                                        { title: 'Blockquote', format: 'blockquote' },
                                        //{title: 'Div', format: 'div'},
                                        //{title: 'Pre', format: 'pre'}
                                    ],
                                },
                                {
                                    title: 'Alignment',
                                    items: [
                                        { title: 'Left', icon: 'alignleft', format: 'alignleft' },
                                        {
                                            title: 'Center',
                                            icon: 'aligncenter',
                                            format: 'aligncenter',
                                        },
                                        {
                                            title: 'Right',
                                            icon: 'alignright',
                                            format: 'alignright',
                                        },
                                        //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
                                    ],
                                },
                                { title: 'Small', selector: 'p', classes: 'small' },
                            ],
                            style_formats_merge: false,
                            textpattern_patterns: [
                                {
                                    start: '//button',
                                    replacement: '<a class=\'button \'>Button here</a>',
                                },
                            ],
                            link_class_list: [
                                { title: 'None', value: '' },
                                {
                                    title: 'Primary on Dark',
                                    value: 'button button--primary-dark',
                                },
                                {
                                    title: 'Primary on Light',
                                    value: 'button button--primary-light',
                                },
                                { title: 'Ghost on Dark', value: 'button button--ghost-dark' },
                                {
                                    title: 'Ghost on Light',
                                    value: 'button button--ghost-light',
                                },
                                { title: 'Label on Dark', value: 'button button--label-dark' },
                                {
                                    title: 'Label on Light',
                                    value: 'button button--label-light',
                                },
                            ],
                            block_formats:
                                'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote',
                        }"
                    ></tinymce-editor>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will set the description for this current membership
                            option.</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Benefits</label>
                </v-flex>
                <v-flex class="half desktop">
                    <p>
                        <i>Add a line for each benefit that you want to be displayed within
                            the upgrade notice and in the memberships widget.</i>
                    </p>
                </v-flex>
            </v-layout>

            <v-flex mr-5>
                <v-tabs>
                    <v-tab :ripple="{ class: 'accent--text' }">Web</v-tab>

                    <v-tab-item
                        px-0
                        class=""
                    >
                        <div
                            class="benefit"
                            v-for="(benefit, index) in membershipsAdmin.benefits"
                            :key="index"
                        >
                            <v-text-field
                                id="field"
                                v-model="membershipsAdmin.benefits[index]"
                                solo
                                flat
                                required
                            ></v-text-field>

                            <IconPicker
                                v-if="modules.default.LFCExclusive"
                                @iconSelected="selectIcon($event, index)"
                                :selectedIcon="membershipsAdmin.benefitIcons[index]"
                                class="icon-picker"
                            />

                            <v-btn @click="deleteBenefit(benefit)">Delete</v-btn>
                        </div>

                        <v-btn
                            class="accent"
                            @click="addBenefit()"
                        >
                            <span>Add Benefit</span>
                        </v-btn>
                    </v-tab-item>

                    <v-tab :ripple="{ class: 'accent--text' }">Android</v-tab>
                    <v-tab-item
                        px-0
                        class=""
                    >
                        <div
                            class="benefit"
                            v-for="(benefit, index) in membershipsAdmin.androidBenefits"
                            :key="index"
                        >
                            <v-text-field
                                id="field"
                                v-model="membershipsAdmin.androidBenefits[index]"
                                solo
                                flat
                                required
                            ></v-text-field>

                            <v-btn @click="deleteBenefitAndroid(benefit)">Delete</v-btn>
                        </div>

                        <v-btn
                            class="accent"
                            @click="membershipsAdmin.androidBenefits.push('')"
                        >
                            <span>Add Benefit</span>
                        </v-btn>
                    </v-tab-item>

                    <v-tab :ripple="{ class: 'accent--text' }">iOS</v-tab>
                    <v-tab-item
                        px-0
                        class=""
                    >
                        <div
                            class="benefit"
                            v-for="(benefit, index) in membershipsAdmin.iosBenefits"
                            :key="index"
                        >
                            <v-text-field
                                id="field"
                                v-model="membershipsAdmin.iosBenefits[index]"
                                solo
                                flat
                                required
                            ></v-text-field>

                            <v-btn @click="deleteBenefitIOS(benefit)">Delete</v-btn>
                        </div>
                        <v-btn
                            class="accent"
                            @click="membershipsAdmin.iosBenefits.push('')"
                        >
                            <span>Add Benefit</span>
                        </v-btn>
                    </v-tab-item>
                </v-tabs>
            </v-flex>

            <v-layout
                v-if="!modules.default.LFCExclusive"
                row
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Locked Video Title</label>
                    <v-text-field
                        id="field"
                        v-model="membershipsAdmin.lockedVideoTitle"
                        solo
                        flat
                        required
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will set the locked video title for this current membership
                            option.</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout
                v-if="!modules.default.LFCExclusive"
                row
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Locked Video Message</label>
                    <v-text-field
                        id="field"
                        v-model="membershipsAdmin.lockedVideoMessage"
                        solo
                        flat
                        required
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will set the locked video message for this current
                            membership option.</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout
                v-if="!modules.default.LFCExclusive"
                row
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Button Text</label>
                    <v-text-field
                        id="field"
                        v-model="membershipsAdmin.buttonText"
                        solo
                        flat
                        required
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will set the button text for this current membership
                            option.</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout
                v-if="!modules.default.LFCExclusive"
                row
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Button Link</label>
                    <v-text-field
                        id="field"
                        v-model="membershipsAdmin.buttonLink"
                        solo
                        flat
                        required
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will set the button link for this current membership
                            option.</i>
                    </p>
                </v-flex>
            </v-layout>
            <v-layout
                v-if="!modules.default.LFCExclusive"
                row
            >
                <v-flex
                    mr-5
                    class="half"
                >
                    <label
                        class="label"
                        for="field"
                    >Login Button Text</label>
                    <v-text-field
                        id="field"
                        v-model="membershipsAdmin.loginButtonText"
                        solo
                        flat
                        required
                    ></v-text-field>
                </v-flex>
                <v-flex
                    mt-4
                    class="half"
                >
                    <p>
                        <i>This will set the login button text for this current membership
                            option.</i>
                    </p>
                </v-flex>
            </v-layout>
        </section>

        <StickyFooter
            :errorMessage="errorMessage"
            :status="status"
            :buttonText="'Save Membership'"
            @buttonClicked="
                isNewMembership ? postMembershipSettings() : saveMembershipSettings()
                "
        />
    </form>
</template>

<script>
import axios from "axios";
import Editor from "@tinymce/tinymce-vue";
import ImageUploader from "@/components/Library/ImageUploader";
import StickyFooter from "@/components/Library/StickyFooter";
import IconPicker from "@/components/Library/IconPicker";

export default {
    name: "EditMemberships",

    components: {
        "tinymce-editor": Editor,
        ImageUploader,
        StickyFooter,
        IconPicker,
    },

    created() {
        this.modules = require("../../../../config/features/" +
            process.env.VUE_APP_CLUB_ID +
            "/modules.js");

        // console.log('route', this.$route.params)
        if (this.$route.path == "/video-management/memberships/create-membership") {
            this.heading = "Create Membership";
            this.isNewMembership = true;
        } else {
            this.configId = this.$route.params.id;
            this.heading = "Edit Membership";
            this.getEditMembershipSettings();
        }
    },

    data: () => ({
        modules: null,
        isNewMembership: null,
        api: process.env.VUE_APP_VIDEOS_API,
        updatedData: [],
        configId: "",
        status: null,
        errorMessage: null,
        membershipsAdmin: {
            title: "",
            description: "",
            backgroundColour: "",
            textColour: "",
            mappedEntitlements: "",
            benefits: [],
            benefitIcons: [],
            androidBenefits: [],
            iosBenefits: [],
            logoKey: "",
            logoMediaLibraryID: "",
            lockedVideoTitle: "",
            lockedVideoMessage: "",
            buttonText: "",
            buttonLink: "",
            loginButtonText: "",
            renderLabel: "",
            displayImage: "",
        },
    }),

    methods: {
        getEditMembershipSettings() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.api}/memberships/${this.$route.params.id}`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        this.configId = response.data.data.id;
                        (this.membershipsAdmin.title = response.data.data.attributes.title),
                            (this.membershipsAdmin.description =
                                response.data.data.attributes.description),
                            (this.membershipsAdmin.backgroundColour =
                                response.data.data.attributes.backgroundColour),
                            (this.membershipsAdmin.textColour =
                                response.data.data.attributes.textColour),
                            (this.membershipsAdmin.mappedEntitlements =
                                response.data.data.attributes.mappedEntitlements),
                            (this.membershipsAdmin.androidBenefits = response.data.data
                                .attributes.androidBenefits
                                ? response.data.data.attributes.androidBenefits
                                : []),
                            (this.membershipsAdmin.iosBenefits = response.data.data.attributes
                                .iosBenefits
                                ? response.data.data.attributes.iosBenefits
                                : []),
                            (this.membershipsAdmin.benefits = response.data.data.attributes
                                .benefits
                                ? response.data.data.attributes.benefits
                                : []),
                            (this.membershipsAdmin.benefitIcons = response.data.data
                                .attributes.benefitIcons
                                ? response.data.data.attributes.benefitIcons
                                : []),
                            (this.membershipsAdmin.logoKey =
                                response.data.data.attributes.logoKey),
                            (this.membershipsAdmin.logoMediaLibraryID =
                                response.data.data.attributes.logoMediaLibraryID);
                        (this.membershipsAdmin.lockedVideoTitle =
                            response.data.data.attributes.lockedVideoTitle),
                            (this.membershipsAdmin.lockedVideoMessage =
                                response.data.data.attributes.lockedVideoMessage),
                            (this.membershipsAdmin.buttonText =
                                response.data.data.attributes.buttonText),
                            (this.membershipsAdmin.buttonLink =
                                response.data.data.attributes.buttonLink),
                            (this.membershipsAdmin.loginButtonText =
                                response.data.data.attributes.loginButtonText),
                            (this.membershipsAdmin.renderLabel =
                                response.data.data.attributes.renderLabel),
                            (this.membershipsAdmin.displayImage =
                                response.data.data.attributes.displayImage);
                    })
                    .catch((e) => {
                        console.error("Problem getting EDIT MEMBERSHIPS settings", e);
                        this.status = "needsSaving";
                    });
            });
        },

        postMembershipSettings() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "POST",
                    url: `${this.api}/memberships`,
                    headers: { Authorization: jwt },
                    data: {
                        data: {
                            type: "video-membership",
                            attributes: {
                                androidBenefits: this.membershipsAdmin.androidBenefits
                                    ? this.membershipsAdmin.androidBenefits
                                    : [],
                                iosBenefits: this.membershipsAdmin.iosBenefits
                                    ? this.membershipsAdmin.iosBenefits
                                    : [],
                                benefits: this.membershipsAdmin.benefits
                                    ? this.membershipsAdmin.benefits
                                    : [],
                                benefitIcons: this.membershipsAdmin.benefitIcons
                                    ? this.membershipsAdmin.benefitIcons
                                    : [],
                                mappedEntitlements: this.membershipsAdmin.mappedEntitlements,
                                title: this.membershipsAdmin.title,
                                description: this.membershipsAdmin.description,
                                logoKey: this.membershipsAdmin.logoKey,
                                logoMediaLibraryID: this.membershipsAdmin.logoMediaLibraryID,
                                backgroundColour: this.membershipsAdmin.backgroundColour,
                                textColour: this.membershipsAdmin.textColour,
                                lockedVideoTitle: this.membershipsAdmin.lockedVideoTitle,
                                lockedVideoMessage: this.membershipsAdmin.lockedVideoMessage,
                                buttonText: this.membershipsAdmin.buttonText,
                                buttonLink: this.membershipsAdmin.buttonLink,
                                loginButtonText: this.membershipsAdmin.loginButtonText,
                                renderLabel: this.membershipsAdmin.renderLabel
                                    ? (this.membershipsAdmin.renderLabel = true)
                                    : (this.membershipsAdmin.renderLabel = false),
                                displayImage: this.membershipsAdmin.displayImage ? true : false,
                            },
                        },
                    },
                })
                    .then((response) => {
                        console.log("saved settings:", response);
                        this.status = "saved";
                        this.isNewMembership = false;
                        this.configId = response.data.data.id;
                    })
                    .catch((e) => {
                        console.error("Problem saving POST EDIT MEMBERSHIP settings", e);
                        this.status = "needsSaving";
                        this.errorMessage = "Problem saving POST EDIT MEMBERSHIP settings";
                    });
            });
        },

        saveMembershipSettings() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: `${this.api}/memberships/${this.configId}`,
                    headers: { Authorization: jwt },
                    data: {
                        data: {
                            id: this.configId,
                            type: "video-membership",
                            attributes: {
                                androidBenefits: this.membershipsAdmin.androidBenefits
                                    ? this.membershipsAdmin.androidBenefits
                                    : [],
                                iosBenefits: this.membershipsAdmin.iosBenefits
                                    ? this.membershipsAdmin.iosBenefits
                                    : [],
                                benefits: this.membershipsAdmin.benefits
                                    ? this.membershipsAdmin.benefits
                                    : [],
                                benefitIcons: this.membershipsAdmin.benefitIcons
                                    ? this.membershipsAdmin.benefitIcons
                                    : [],
                                mappedEntitlements: this.membershipsAdmin.mappedEntitlements,
                                title: this.membershipsAdmin.title,
                                description: this.membershipsAdmin.description,
                                logoKey: this.membershipsAdmin.logoKey,
                                logoMediaLibraryID: this.membershipsAdmin.logoMediaLibraryID,
                                backgroundColour: this.membershipsAdmin.backgroundColour,
                                textColour: this.membershipsAdmin.textColour,
                                lockedVideoTitle: this.membershipsAdmin.lockedVideoTitle,
                                lockedVideoMessage: this.membershipsAdmin.lockedVideoMessage,
                                buttonText: this.membershipsAdmin.buttonText,
                                buttonLink: this.membershipsAdmin.buttonLink,
                                loginButtonText: this.membershipsAdmin.loginButtonText,
                                renderLabel: this.membershipsAdmin.renderLabel
                                    ? this.membershipsAdmin.renderLabel
                                    : false,
                                displayImage: this.membershipsAdmin.displayImage
                                    ? this.membershipsAdmin.displayImage
                                    : false,
                            },
                        },
                    },
                })
                    .then((response) => {
                        console.log("saved settings:", response);
                        this.status = "saved";
                    })
                    .catch((e) => {
                        console.error("Problem saving PATCH EDIT MEMBERSHIP settings", e);
                        this.status = "needsSaving";
                        this.errorMessage = "Problem saving PATCH EDIT MEMBERSHIP settings";
                    });
            });
        },

        updateData() {
            this.status = "needsSaving";
        },

        updateImage(payload, id, key) {
            if (payload.mediaLibraryID) {
                this.membershipsAdmin[id] = payload.mediaLibraryID;
                this.membershipsAdmin[key] = payload.imageKey;
            } else {
                this.membershipsAdmin[id] = null;
                this.membershipsAdmin[key] = null;
            }
        },

        addBenefit() {
            this.membershipsAdmin.benefits.push("");
        },

        deleteBenefit(key) {
            var itemToDelete = this.membershipsAdmin.benefits.findIndex(
                (element) => element === key
            );
            this.membershipsAdmin.benefits.splice(itemToDelete, 1);
            this.membershipsAdmin.benefitIcons.splice(itemToDelete, 1);
        },

        deleteBenefitAndroid(key) {
            var itemToDelete = this.membershipsAdmin.androidBenefits.findIndex(
                (element) => element === key
            );
            this.membershipsAdmin.androidBenefits.splice(itemToDelete, 1);
        },

        deleteBenefitIOS(key) {
            var itemToDelete = this.membershipsAdmin.iosBenefits.findIndex(
                (element) => element === key
            );
            this.membershipsAdmin.iosBenefits.splice(itemToDelete, 1);
        },

        selectIcon(payload, index) {
            this.$set(this.membershipsAdmin.benefitIcons, index, payload);
            this.updateData();
            this.$forceUpdate();
        },
    },
};
</script>

<style scoped lang='scss'>
.desktop {
    @media screen and (max-width: 759px) {
        display: none;
    }
}

.half {
    width: 50%;
}

.benefit {
    display: flex;
    flex-direction: row;
}

.icon-picker {
    margin: 0 10px;
}

:deep(.v-tabs__bar) {
    margin-bottom: 10px;
    padding-left: 0;
}
</style>
