<template>
  <div class="stat-card" v-if="statistic">
    <v-layout row>
      <v-flex mr-5 class="half">
        <label class="label">Chart Type</label>
        <v-select
          :items="statisticTypes"
          v-model="editableData.displayType"
          solo
          flat 
          @change="setData(editableData.displayType)"/>

      </v-flex>
      <v-flex mr-4 class="half">
        <template v-if="statistic.displayType === 'circleGraph'">
          <label class="label">Option</label>
          <v-select
            :items="circleChartOptions"
            item-text="label"
            item-value="graphType"
            v-model="editableData.data.graphType"
            solo
            flat
            @change="setCircleData(editableData.data.graphType)" />
        </template>

        <template v-if="statistic.displayType === 'keyStat'">
          <label class="label">Option</label>
          <v-select
            :items="stats"
            v-model="editableData.data.dataSet[0].key"
            solo
            flat
            @change="setKeyData(editableData.data.dataSet[0].key)"
             />
        </template>
      </v-flex>
    </v-layout>

    <template v-if="editableData.displayType === 'circleGraph'">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label">Text Size</label>
          <v-select
            :items="['small', 'medium', 'large']"
            v-model="editableData.data.textSize"
            solo
            flat 
          />
        </v-flex>
        <v-flex mr-4 class="half">
        </v-flex>
      </v-layout>
    </template>

    <template v-if="editableData.displayType === 'groupedStats'">
      <template v-for="(group, index) in editableData.data.groups">
        <div class="stat-group-wrapper" :key="index">
          <label class="label" >Group {{index + 1}}</label>
          <label class="label" for="group-name">Group name (required)</label>
            <v-text-field
              id="group-name"
              v-model="group.type"
              solo
              flat
              required
            ></v-text-field>
  
          <v-layout row >
            <template v-for="(stat, subIndex) in group.dataSet">
              <div class="stat-item" :key="subIndex">
              <label class="label" >Statistic {{subIndex + 1}}</label>
              <v-select
                  :items="stats"
                  v-model="stat.key"
                  solo
                  flat 
                  @change="setFieldData(stat.key, index, subIndex)"/>
              </div>
            </template>
          </v-layout>
        </div>
      </template>
    </template>
  </div>

</template>

<script>

export default {
  name: "PlayerStatistics",

  props: {
    statistic: {
      type: Object,
      required: true
    },
    availableStats: {
      type: Object,
      required: true
    }
  },

  watch: {
    "editableData": {
      handler: function () {
        this.$emit("updatedPlayerStats", this.editableData)
      },
      deep: true
    }
  },

  created() {
    this.editableData = this.statistic
    this.stats = Object.keys(this.availableStats)
  },

  data: () => ({
    playerApi: `${process.env.VUE_APP_WEBAPI}/players/opta/getsingle?playerSlug=`,
    editableData: null,
    statisticTypes: [
       {text: "Circle Graph", value: "circleGraph"},
       {text: "Key Statistic", value: "keyStat"},
       {text: "Group of statistics", value: "groupedStats"},
    ],
    circleChartOptions: [
      {graphType: "CleanSheets", label: "Clean Sheet %", totalKey: ["Appearances"], partKey: "Clean Sheets" },
      {graphType: "ShotStopping", label: "Total Saves %", totalKey: ["Saves Made", "Goals Conceded"], partKey: "Saves Made"},
      {graphType: "Passing", label: "Pass Completion %", totalKey: ["Total Passes"], partKey: "Total Successful Passes ( Excl Crosses & Corners ) "},
      {graphType: "Tackling", label: "Tackles Won %", totalKey: ["Total Tackles"], partKey: "Tackles Won"},
      {graphType: "Heading", label: "Headers Won %", totalKey: ["Aerial Duels"], partKey: "Aerial Duels won"},
      {graphType: "Shooting", label: "Shooting Accuracy %", totalKey: ["Total Shots"], partKey: "Shots Off Target (inc woodwork)"},
      {graphType: "Scoring", label: "Goal Conversion %", totalKey: ["Total Shots"], partKey: "Goals"},
    ],
    stats: []
  }),

  methods: {
    setData(type) {
      console.warn("method for: ", type)
      this.editableData.displayType = type
      switch (type) {
        case "circleGraph":
          this.editableData.data = {
            graphType: "",
            label: "",
            totalKey: "",
            partKey: "",
            textSize: ""
          }
        break;
        case "keyStat":
          this.editableData.data = {
            "dataSet": [
              {
                "label": "",
                "key": ""
              }
            ]
          }
        break;
        case "groupedStats":
          this.editableData.data = {
            groups: [
              {
                type: "",
                dataSet: [
                  {
                    label: "",
                    key: ""
                  },
                  {
                    label: "",
                    key: ""
                  },
                  {
                    label: "",
                    key: ""
                  }
                ]
              },
              {
                type: "",
                dataSet: [
                  {
                    label: "",
                    key: ""
                  },
                  {
                    label: "",
                    key: ""
                  },
                  {
                    label: "",
                    key: ""
                  }
                ]
              }
            ]
          }  
        break;
      }
    },

    setCircleData(type) {
      for (const option of this.circleChartOptions) {
        if (option.graphType === type) {
          this.editableData.data.label = option.label
          this.editableData.data.totalKey = option.totalKey
          this.editableData.data.partKey = option.partKey
        }
      }
    },

    setKeyData(option) {
      this.editableData.data.dataSet[0].label = option
    },

    setFieldData(option, index, subindex) {
      this.editableData.data.groups[index].dataSet[subindex].label = option
    }
  }

}
</script>

<style scoped lang='scss'>

.stat-item {
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0;
  }
}

</style>
