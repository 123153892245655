<template>

<section class="response-card-wrapper">

  <section class="response-card">

    <div class="response-card__intro">
      <p>{{responseID}}
      <p>Submitted at: <strong>{{submitted | moment('DD/MM/YYYY (HH:mm:ss)')}}</strong></p>
      <button class="view-more" type="button" @click="viewResponse(responseID)">{{buttonText}}</button>
    </div>
    
    <div class="response-card__actions">
      <button class="response-card__action" @click="deleteResponse(responseID)">
        <v-icon>delete</v-icon>
      </button>
    </div>
    
  </section>

  <div class="show-details" v-if="showMore && responseDetails">
    <div v-html="responseDetails.message"></div>
      <button v-if="isSpam" class="view-more" type="button" @click="notSpam(responseID)">Not Spam</button>
    </div>

</section>

</template>

<script>
import axios from "axios"
export default {
  name: "ResponseListItem",

  props: {
    isSpam: {
      type: Number,
      required: true
    },
    submitted: {
      type: Number,
      required: true
    },
    responseID: {
      type: String,
      required: true
    }
  },

  data: () => ({
    showMore: false,
    responseDetails: null,
    buttonText: "View Details"
  }),

  methods: {
    deleteResponse(responseID) {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        console.warn("deleting this response", responseID)
        if (confirm("Are you sure you want to delete this response.  This action is permanent")) {
          console.warn("deleting from", `${process.env.VUE_APP_FORMAPI}/completedforms/delete?postedDataID=${responseID}`)
          axios
          .delete(`${process.env.VUE_APP_FORMAPI}/completedforms/delete?postedDataID=${responseID}`, { "headers": { "Authorization": jwt }})
          .then(response => {
            this.$emit("deleted")
          })
          .catch(e => {
            console.error("Problem deleting response", e);
            this.$store.commit("completeLoading");
          })
        } else {
          console.warn("keeping this response", responseID)
        }
      })
    },

    viewResponse(key) {
      if( this.showMore === false) {
        this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_FORMAPI}/completedforms/getsingle?postedDataID=${key}`, { "headers": { "Authorization": jwt }}) 
        .then(response => {
          console.log("getting single", response, "requestURL",  `${process.env.VUE_APP_FORMAPI}/completedforms/getsingle?postedDataID=${key}`, "Authorization", jwt )
          this.$store.commit("completeLoading")
          this.responseDetails = response.data.body
          this.showMore = true
          this.buttonText = "Hide Details"
        })
        .catch(e => {
          console.error("Problem retrieving response", e);
          this.$store.commit("completeLoading");
        })
      })
      } else {
        this.showMore = false
        this.buttonText = "View Details"
      } 
    },

    notSpam(key) {
      if (confirm("Are you sure this message is not spam?")) {

        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios({
            method: "POST",
            url: `${process.env.VUE_APP_FORMAPI}/completedforms/updatespam?googleSpam=0&postedDataID=${key}`,
            headers: { "Authorization": jwt }
          })
          .then(response => {
            this.$emit("updated")
          })
          .catch(e => {
            console.error("Problem changing spam", e);
            this.$store.commit("completeLoading");
          })
        })
      }
    }
  }
}
</script>

<style scoped lang='scss'>

@import '../../styles/global.scss';

.response-card-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 15px 0;

  &:hover {
    background-color: #E6E8F1;
  }
}

.response-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &__intro {
    flex-grow: 1;
    margin: 16px 16px 8px;

    h1 {
      font-size: 16px;
    }

    p {
      margin-bottom: 4px;
    }
  }

  &__actions {
    height: 100%;
    padding: 24px 16px;
  }

  &__action {
    margin: 0 8px 0 0;

    i {
      color: #67247c;
    }  
  }
}

.view-more {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 0 0 0 -4px;
}

.show-details {
  margin: 8px 16px 8px;

  ul {
    padding-left: 0;
  }
}

.spam-button {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 8px 4px 32px;
  margin: 0 0 0 -4px;
  background-color: #ff5252;
  color: #000;

  &:before {
    content: '';
    position: absolute;
    background: transparent url('../../assets/alert.svg') center no-repeat;
    left: 6px;
    top: 6px;
    height: 16px;
    width: 16px;
  }
}

</style>
