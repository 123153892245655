<template>

    <section class="settings">

        <div class="main">
            <v-text-field label="Content Feed Title">
            </v-text-field>
        </div>

        <template v-if="updatedData">
            <section
                class="promo-section"
                v-for="(promo, index) in updatedData"
                :key="index"
            >
                <div class="row">
                    <div class="field-wrapper">
                        <div class="item">
                            <v-text-field
                                label="Promo Title"
                                v-model="promo.title"
                                box
                                background-color="#fff"
                            >
                            </v-text-field>
                        </div>
                        <div class="item">
                            <v-text-field
                                label="Promo Link"
                                v-model="promo.url"
                                box
                                background-color="#fff"
                            >
                            </v-text-field>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="field-wrapper">
                        <div class="item">
                            <v-text-field
                                label="Promo Locations"
                                v-model="promo.locations"
                                box
                                :rules="[v => /^(\d+(,\d+)*)?$/.test(v) || 'Comma separated list of numbers or single number only']"
                                background-color="#fff"
                            >
                            </v-text-field>
                        </div>
                        <div class="item">
                            <p class="item-help">Add a comma seperated list of the spaces in the feed that you would like your promo to
                                appear</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="item">
                        <label
                            class="label"
                            for="hide-in-app"
                        >Hide in app?</label>
                        <v-checkbox
                            id="hide-in-app"
                            v-model="promo.hideInApp"
                        />
                    </div>
                </div>

                <!-- <div class="row">
            <div class="field-wrapper">
            <div class="item">
              <v-text-field 
                label="Promo Tracking"
                v-model="promo.tracking"
                box
                background-color="#fff">
              </v-text-field>
            </div>
            <div class="item">
              <p class="item-help">Add your tracking code that you wish to be used in the promo</p>
            </div>
            </div>
          </div> -->

                <ImageUploader
                    title="Promo Image"
                    :widthOptionEnabled="false"
                    :mediaLibraryID="promo.mediaLibraryID"
                    @update="updateImage($event, index)"
                />

                <v-btn
                    v-if="promo.agcPromoID"
                    type="button"
                    @click="updatePromo(promo, index)"
                >Update Promo</v-btn>
                <v-btn
                    v-else
                    type="button"
                    @click="addPromo(promo, index)"
                >Add new Promo</v-btn>
                <v-btn
                    type="button"
                    @click="deletePromo(promo, index)"
                    color="error"
                >Remove Promo</v-btn>

            </section>

            <v-btn @click="newPromo()">Add another</v-btn>

        </template>

    </section>

</template>

<script>
import axios from "axios"
import ImageUploader from "@/components/Library/ImageUploader"

export default {
    name: "ContentFeedSettings",

    components: {
        ImageUploader
    },

    props: {
        promoData: {
            type: Array,
            required: true
        }
    },

    data: () => ({
        updatedData: []
    }),

    created() {
        this.setData()
    },

    methods: {
        setData() {
            this.updatedData = this.promoData
        },

        addPromo(newPromo) {
            this.$store.commit("startLoading");

            this.errorMessage = ""
            this.saveStatus = "saving"
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "POST",
                    url: `${process.env.VUE_APP_FEEDAPI}/promos/add`,
                    headers: { "Authorization": jwt },
                    data: {
                        title: newPromo.title != "" ? newPromo.title : null,
                        locations: newPromo.locations != "" ? Array.isArray(newPromo.locations) ? newPromo.locations : newPromo.locations.split(",").map(Number) : [],
                        tracking: newPromo.tracking != "" ? newPromo.tracking : null,
                        url: newPromo.url != "" ? newPromo.url : null,
                        mediaLibraryID: newPromo.mediaLibraryID,
                        hideInApp: newPromo.hideInApp ? true : false
                    }
                })
                    .then(() => {
                        this.$emit("reloadPromos")
                    })
                    .catch(e => {
                        console.error("Problem creating promo", e)
                        // this.saveStatus = 'needsSaving'
                        this.errorMessage = "Problem creating post"
                    })
            })
        },

        updatePromo(promo, index) {
            console.log("Promo Update:", promo)
            this.$emit("updatePromo", {
                promo: promo,
                index: index
            })
        },

        deletePromo(promo, index) {
            if (confirm("Are you sure you want to delete this promo")) {
                if (promo.agcPromoID) {
                    this.$getCurrentUserJwToken().subscribe((jwt) => {
                        axios({
                            method: "DELETE",
                            url: `${process.env.VUE_APP_FEEDAPI}/promos/delete?agcPromoID=${promo.agcPromoID}`,
                            headers: { "Authorization": jwt }
                        })
                            .then(response => {
                                console.log("Promo deleted:", response);
                                this.$emit("reloadPromos")
                            })
                            .catch(e => {
                                console.error("Problem deleting advert", e);
                            });
                    })
                } else {
                    console.warn("no promoID just remove the object", index)
                    this.updatedData.splice(index, 1)
                    this.$emit("reloadPromos")
                }
            }
        },

        newPromo() {
            this.updatedData.push({
                title: "",
                locations: [],
                tracking: "",
                url: "",
                mediaLibraryID: "",
                hideInApp: false
            })
            console.log("updated Data", this.updatedData)
        },

        updateImage(payload, index) {

            this.updatedData[index].mediaLibraryID = payload.mediaLibraryID
        },
    }
}
</script>

<style
    scoped
    lang='scss'
>
.text-input {
    background-color: #fff;
    border: 1px solid #67247C;
    border-radius: 6px;
}

section {
    padding: 20px;
    margin: 0 0 40px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.promo-section {
    background-color: #fafafa;
}

.field-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 8px;
    margin-bottom: 16px;
}

.item {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;

    &:nth-child(odd) {
        padding-right: 16px;
    }
}

.item-help {
    // margin: auto 0 0 0; 
}

.label-main {
    font-size: 16px;
    font-weight: bold;
}
</style>
