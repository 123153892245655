var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"menu-name"},[(_vm.editName)?[_c('v-text-field',{attrs:{"placeholder":"Navigation Name","solo":"","flat":"","required":""},model:{value:(_vm.navigationName),callback:function ($$v) {_vm.navigationName=$$v},expression:"navigationName"}}),_c('v-btn',{attrs:{"color":"accent","type":"button","depressed":""},on:{"click":function($event){return _vm.saveName()}}},[_vm._v(" Done ")])]:[_c('button',{staticClass:"menu-name__display",attrs:{"type":"button"},on:{"click":function($event){return _vm.allowEditName()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")]),_c('h3',{staticClass:"menu-name__heading"},[_vm._v(_vm._s(_vm.navigationName))])],1)]],2),_c('section',{staticClass:"edit-menu"},[_c('draggable',{attrs:{"options":{
        group: 'menu',
        ghostClass: 'ghost',
        dragClass: 'sortable-drag',
      }},model:{value:(_vm.navigation),callback:function ($$v) {_vm.navigation=$$v},expression:"navigation"}},[_c('transition-group',{attrs:{"name":"list-complete","tag":"ol"}},_vm._l((_vm.navigation),function(level1Item,level1Index){return _c('li',{key:level1Item.id,staticClass:"draggable-item"},[_c('DraggableMenuRow',{key:level1Item.id,attrs:{"name":level1Item.name,"slug":level1Item.slug,"allowChildCreation":true},on:{"updateItem":function($event){return _vm.updateItem($event, level1Index)},"deleteItem":function($event){return _vm.deleteItem(level1Index)},"addChild":function($event){return _vm.addChildItem(level1Index)}}}),(level1Item.menu)?_c('draggable',{staticClass:"child-menu",class:{'is-dragging': _vm.childMenuDragging},attrs:{"options":{
                group: 'menu',
                ghostClass: 'ghost',
                dragClass: 'sortable-drag',
              }},on:{"start":_vm.childMenuChosen,"end":_vm.childMenuUnchosen},model:{value:(level1Item.menu),callback:function ($$v) {_vm.$set(level1Item, "menu", $$v)},expression:"level1Item.menu"}},[_c('transition-group',{attrs:{"name":"list-complete","tag":"ol"}},_vm._l((level1Item.menu),function(level2Item,level2Index){return _c('li',{key:level2Item.id,staticClass:"draggable-item"},[_c('DraggableMenuRow',{attrs:{"name":level2Item.name,"slug":level2Item.slug,"allowChildCreation":true},on:{"updateItem":function($event){return _vm.updateItem($event, level1Index, level2Index)},"deleteItem":function($event){return _vm.deleteItem(level1Index, level2Index)},"addChild":function($event){return _vm.addChildItem(level1Index, level2Index)}}}),(level2Item.menu)?_c('draggable',{staticClass:"child-menu",class:{'is-dragging': _vm.childMenuDragging},attrs:{"options":{
                        group: 'menu',
                        ghostClass: 'ghost',
                        dragClass: 'sortable-drag',
                      }},on:{"start":_vm.childMenuChosen,"end":_vm.childMenuUnchosen},model:{value:(level2Item.menu),callback:function ($$v) {_vm.$set(level2Item, "menu", $$v)},expression:"level2Item.menu"}},[_c('transition-group',{attrs:{"name":"list-complete","tag":"ol"}},_vm._l((level2Item.menu),function(level3Item,level3Index){return _c('li',{key:level3Item.id,staticClass:"draggable-item"},[_c('DraggableMenuRow',{attrs:{"name":level3Item.name,"slug":level3Item.slug,"allowChildCreation":false},on:{"updateItem":function($event){return _vm.updateItem($event, level1Index, level2Index, level3Index)},"deleteItem":function($event){return _vm.deleteItem(level1Index, level2Index, level3Index)}}})],1)}),0)],1):_vm._e()],1)}),0)],1):_vm._e()],1)}),0)],1),_c('div',{staticClass:"bottom-right-button"},[_c('v-btn',{attrs:{"color":"accent","type":"button","depressed":""},on:{"click":function($event){return _vm.addMenuItem()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("add")]),_vm._v(" Add Menu Item ")],1)],1)],1),_c('StickyFooter',{attrs:{"errorMessage":_vm.errorMessage,"status":_vm.status,"buttonText":'Save Page'},on:{"buttonClicked":function($event){return _vm.saveMenu()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }