export default {
    aggregatedFeed: {
        showAllNews: 1,
    },
    allowUpsellInApp: false,
    pageTypes: [
        { value: "internal", text: "Website Page" },
        { value: "external", text: "External Page" },
        { value: "internalLink", text: "Internal Page Link" },
        // {value: 'homepage', text: 'Homepage'},
    ],
    platformSettings: {
        showWeb: true,
        showApp: true,
    },
    defaultNewsCardStyle: "Stack",
    playerManagement: "V1",
    playerPageDefaultLayout: {
        pageStructure: {
            bio: "club", // 'hide' | 'light' | 'dark' | 'club'
            stats: "show", // 'hide' | 'show'
            history: "hide", // 'hide' | 'light' | 'dark' | 'club'
            awards: "hide", // 'hide' | 'light' | 'dark' | 'club'
            gallery: "light", // 'hide' | 'light' | 'dark' | 'club'
            news: "light", // 'hide' | 'light' | 'dark' | 'club'
            retail: "club", // 'hide' | 'light' | 'dark' | 'club'
            video: null, // 'hide' | 'light' | 'dark' | 'club'
        },
        displayHeadshot: "hero", // hero | 'headshot',
        mobileGalleryOption: 3, // 2 | 3 | 4 | 5
        desktopGalleryOption: 5, // 2 | 3 | 4 | 5
        quoteSize: "medium", // 'small' | 'medium' | 'large'
        newsStyle: "carousel", // 'slider' | 'carousel'
    },
    customDataFields: {
        customDataFieldOneName: "",
        customDataFieldOneDescription: "",
        customDataFieldTwoName: "",
        customDataFieldTwoDescription: "",
        customDataFieldThreeName: "",
        customDataFieldThreeDescription: "",
    },
    videoSessionSSOUser: "57feacd3-3e28-4e0c-93de-005dc254f4d7",
    customTags: [],
    clubColors: [
        {
            color: "#56052B",
        },
        {
            color: "#95BFE5",
        },
        {
            color: "#A28543",
        },
    ],
};
