<template>
  <v-dialog class="accordion-widget" v-model="dialog" width="580" lazy>
    <slot slot="activator"></slot>
    <v-form
      enctype="multipart/form-data"
      ref="accordionForm"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title class="widget__heading"> Accordion </v-card-title>

        <v-card-text>
          <label class="label" for="accordion-title">Accordion Title</label>
          <v-text-field
            id="accordion-title"
            v-model="updatedData.accordionTitle"
            placeholder="Accordion Title"
            solo
            flat
            validate-on-blur
          ></v-text-field>

          <label class="label" for="accordion-description"
            >Accordion Description</label
          >

          <tinymce-editor
            v-model="updatedData.accordionDescription"
            api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
            :init="{
              height: '180px',
              menubar: false,
              relative_urls: false,
              plugins: 'link paste wordcount hr lists textpattern',
              toolbar:
                'undo redo | styleselect | bold italic | \
                link | \
                hr | \
                bullist numlist | removeformat',
              resize: false,
              textpattern_patterns: [
                {
                  start: '//button',
                  replacement: '<a class=\'button \'>Button here</a>',
                },
              ],
              link_class_list: [
                { title: 'None', value: '' },
                {
                  title: 'Primary on Dark',
                  value: 'button button--primary-dark',
                },
                {
                  title: 'Primary on Light',
                  value: 'button button--primary-light',
                },
                { title: 'Ghost on Dark', value: 'button button--ghost-dark' },
                {
                  title: 'Ghost on Light',
                  value: 'button button--ghost-light',
                },
                { title: 'Label on Dark', value: 'button button--label-dark' },
                {
                  title: 'Label on Light',
                  value: 'button button--label-light',
                },
              ],
              style_formats: [
                {
                  title: 'Headers',
                  items: [
                    //{title: 'Header 1', format: 'h1'},
                    { title: 'Header 2', format: 'h2' },
                    { title: 'Header 3', format: 'h3' },
                    { title: 'Header 4', format: 'h4' },
                    { title: 'Header 5', format: 'h5' },
                    { title: 'Header 6', format: 'h6' },
                  ],
                },
                {
                  title: 'Inline',
                  items: [
                    { title: 'Bold', icon: 'bold', format: 'bold' },
                    { title: 'Italic', icon: 'italic', format: 'italic' },
                    {
                      title: 'Underline',
                      icon: 'underline',
                      format: 'underline',
                    },
                    //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
                    //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
                    //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
                    //{title: 'Code', icon: 'code', format: 'code'}
                  ],
                },
                {
                  title: 'Blocks',
                  items: [
                    { title: 'Paragraph', format: 'p' },
                    { title: 'Blockquote', format: 'blockquote' },
                    //{title: 'Div', format: 'div'},
                    //{title: 'Pre', format: 'pre'}
                  ],
                },
                {
                  title: 'Alignment',
                  items: [
                    { title: 'Left', icon: 'alignleft', format: 'alignleft' },
                    {
                      title: 'Center',
                      icon: 'aligncenter',
                      format: 'aligncenter',
                    },
                    {
                      title: 'Right',
                      icon: 'alignright',
                      format: 'alignright',
                    },
                    //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
                  ],
                },
                { title: 'Small', selector: 'p', classes: 'small' },
              ],
              style_formats_merge: false,
              block_formats:
                'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote',
            }"
          ></tinymce-editor>

          <!-- Draggable a la list Widget -->
          <draggable
            v-model="updatedData.content"
            :options="{
              ghostClass: 'ghost',
              dragClass: 'sortable-drag',
            }"
            @start="drag = true"
            @end="drag = false"
          >
            <transition-group name="list-complete">
              <WidgetAccordionItem
                v-for="(item, index) in updatedData.content"
                :index="index"
                :key="item.id"
                :title="item.title"
                :body="item.body"
                @deleteItem="deleteRow(index)"
                @saveRow="rowUpdated($event, index)"
              />
            </transition-group>
          </draggable>

          <v-btn
            v-if="!addRow"
            color="accent"
            outline
            flat
            @click="addRow = true"
          >
            Add Item
          </v-btn>

          <div v-if="addRow">
            <widget-accordion-form
              :formHeading="`Add Item`"
              @saveRow="addNewItem($event)"
              @cancelEdit="cancelNewRow()"
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" outline flat @click="validate()"> Post </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import draggable from "vuedraggable";
import WidgetAccordionItem from "@/components/Library/WidgetAccordionItem";
import WidgetAccordionForm from "@/components/Library/WidgetAccordionForm";

export default {
  name: "AccordionWidget",

  components: {
    "tinymce-editor": Editor,
    draggable,
    WidgetAccordionItem,
    WidgetAccordionForm,
  },

  props: {
    widgetData: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    dialog: false,
    valid: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    newItem: {
      id: null,
      title: null,
      body: null,
    },
    updatedData: {
      accordionTitle: null,
      accordionDescription: null,
      content: [],
    },

    addRow: false,
  }),

  mounted() {
    if (this.widgetData) {
      console.log("Widget Data", this.widgetData);
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData));
    }
  },

  methods: {
    addNewItem(payload) {
      const newItem = {
        id: this.$uuid.v1(),
        title: payload.title,
        body: payload.body,
      };

      this.updatedData.content.push(JSON.parse(JSON.stringify(newItem)));

      this.newItem = {
        id: null,
        title: null,
        body: null,
      };

      this.addRow = false;
    },

    addItem() {
      console.log("Add item to accordion");
      this.newItem.id = this.$uuid.v1();

      this.updatedData.content.push(JSON.parse(JSON.stringify(this.newItem)));

      this.newItem = {
        id: null,
        title: null,
        body: null,
      };

      this.addRow = false;
    },

    rowUpdated(payload, index) {
      if (payload.title) {
        this.updatedData.content[index].title = payload.title;
      } else {
        this.updatedData.content[index].title = null;
      }

      if (payload.body) {
        this.updatedData.content[index].body = payload.body;
      } else {
        this.updatedData.content[index].body = null;
      }
    },

    updateData() {
      console.log("Update data:", this.updatedData);

      if (this.updatedData.accordionTitle === "") {
        this.updatedData.accordionTitle = null;
      }
      if (this.updatedData.accordionDescription === "") {
        this.updatedData.accordionDescription = null;
      }

      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
    },
    validate() {
      this.errorMessage = "";
      if (this.$refs.accordionForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required";
      }
    },

    deleteRow(rowIndex) {
      if (confirm("Are you sure you want to delete this accordion row")) {
        this.updatedData.content.splice(rowIndex, 1);
      }
    },

    cancelNewRow() {
      this.newItem = {
        id: null,
        title: null,
        body: null,
      };
      this.addRow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
}

.list-complete-leave-active {
  position: absolute;
  width: 100%;
}

.widget {
  &__description {
    margin: 0 0 20px;
    color: #7b6b80;
    font-size: 14px;
  }
}
</style>
