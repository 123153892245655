<template>
  <section class="live-streaming">
    <MiniHeader :heading="''" :buttonText="'Create new Stream endpoint'" :hasButton="true" @buttonClicked="createStream()" />

    <div class="live-streaming__refresh-wrap">
      <v-btn icon class="live-streaming__refresh" @click="fetchStreams()">
        <v-icon>refresh</v-icon>
      </v-btn>

      <span>Last updated: {{ timeNowFormatted }} secs ago</span>
    </div>

    <inline-loader v-if="loading" />

    <template v-else>
      <div v-if="activeStreams.length" class="live-streaming__active-streams">
        <h2 class="live-streaming__active-subheading subheading">Active Streams</h2>

        <live-streaming-listing-item v-for="stream in activeStreams" :key="stream.id" :stream="stream" />
      </div>

      <live-streaming-listing-item v-for="stream in idleStreams" :key="stream.id" :stream="stream" />
    </template>
  </section>
</template>

<script>
import axios from "axios"
import moment from "moment"
import InlineLoader from "@/components/Library/InlineLoader"
import LiveStreamingListingItem from "./LiveStreamingListingItem.vue"
import MiniHeader from "@/components/Library/MiniHeader"

export default {
  name: "LiveStreamingListing",

  components: {
    InlineLoader,
    LiveStreamingListingItem,
    MiniHeader
  },

  data:() => ({
    revealInfo: false,
    loading: false,
    streams: [],
    timer: null,
    timeNow: null,
    timeNowFormatted: null,
    idleStreams: [],
    activeStreams: [],
  }),

  mounted() {
    this.fetchStreams()
  },

  beforeDestroy() {
    clearTimeout(this.timer)
  },

  methods: {
    createStream() {
      if (confirm("Please only create a new stream endpoint if required")) {
        this.$router.push({ path: "/streamline/live/create-stream" })
      }
    },

    fetchStreams() {
      this.loading = true

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/streams?page[size]=1000`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Fetch streams:", response);
          const allStreams = response.data.data

          this.idleStreams = allStreams.filter(stream => {
            console.log("stream: ", stream)

            if (stream.attributes.assetData.status != "active") {
              return stream
            }
          });

          this.activeStreams = allStreams.filter(stream => {
            console.log("stream: ", stream)

            if (stream.attributes.assetData.status === "active") {
              return stream
            }
          });

          // this.streams = allStreams
          this.$store.commit("completeLoading");
          this.loading = false
          // this.status = 'saved';

          this.timeNow = moment()

          this.updateTimer()
        })
        .catch(e => {
          console.error("Problem fetching streams", e);
          this.$store.commit("completeLoading");
          // this.status = 'needsSaving';
          // this.errorMessage = 'Problem fetching video';
          this.loading = false
        });
      })
    },

    updateTimer() {
      this.timeNowFormatted = moment().diff(moment(this.timeNow), "seconds")

      this.timer = setTimeout(() => {
        this.updateTimer();
        console.log("Update streams timer")
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
  .live-streaming {
    position: relative;

    &__refresh-wrap {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      top: 8px;
      left: 0;
    }

    &__refresh {
      margin: 0 4px 0 0;
    }

    &__active-streams {
      padding: 0;
      margin: 0 0 48px;
      border-bottom: 1px solid #DFE3E9;
    }
  }
</style>
