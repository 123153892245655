<template>
  <section class="responses">

      <div class="response-filters">
      <v-switch
        v-model="showSpam"
        label="Show Spam?"
      ></v-switch>
      
      <p class="response-message response-message--alert" v-if="totalResponses === 0" >No Responses Found</p>
      <p class="response-message" v-else>Showing page {{pageNumber}} of {{totalResponses}} results</p>

    </div>

      <v-btn v-if="allResponses.length"
        class="mini-header__button"
        color="accent"
        type="button"
        depressed
        @click="runExport()">
        Export Responses as CSV
      </v-btn>

      <ul>
        <li v-for="response in allResponses" :key="response.postedDataID">
          <response-list-item 
            :name="response.name_admin"
            :description="response.description_admin"
            :isSpam="response.googleSpam" 
            :submitted="response.addedUTCTimestamp"
            :responseID="response.postedDataID"
            @deleted="getResponses()"
            @updated="getResponses()" />
        </li>
      </ul>

      <div class="pagination" v-if="pageCount > 1">
      <v-pagination
        v-model="pageNumber"
        :length="pageCount"
        circle
        total-visible="7"
        color="accent">
      </v-pagination>
    </div>

    <router-view></router-view>
  </section>
</template>

<script>
import axios from "axios"
import ResponseListItem from "@/components/Forms/ResponseListItem"

export default {
  name: "SingleFormResponses",

  components: {
    ResponseListItem
  },

  watch: {
    showSpam: function(val) {
      if (val) {
        this.getResponses()
      } else {
        this.getResponses()
      }
    },
    pageNumber(value, oldValue) {
      console.log("Page number changed");
      if (oldValue) {
        this.responsesLoaded = false
        this.getResponses()
      }
    }
  },

  props: {
    formID: {
      type: String
    }
  },

  data: () => ({
    responsesLoaded: false,
    totalResponses: null,
    allResponses: null,
    pageSize: 10,
    showSpam: false,
    pageNumber: 1,
    pageCount: 1,
    spam: null,
    formRequired: null
  }),

  mounted() {
    this.formRequired = this.formID
    this.getResponses()
  },

  methods: {
    getResponses() {
      if (this.showSpam) {
        this.spam = 1
      } else {
        this.spam = 0
      }
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${process.env.VUE_APP_FORMAPI}/completedforms?pageSize=${this.pageSize}&pageNumber=${this.pageNumber}&googleSpam=${this.spam}&formID=${this.formRequired}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("have I got any forms", response, "requestURL",  `${process.env.VUE_APP_FORMAPI}/completedforms?pageSize=${this.pageSize}&pageNumber=${this.pageNumber}&googleSpam=${this.spam}&formID=${this.formRequired}`, "Authorization", jwt )
          this.allResponses = response.data.body
          this.totalResponses = response.data.totalDataCount
          this.pageCount = Math.ceil(response.data.totalDataCount / this.pageSize);
          this.responsesLoaded = true
          this.$store.commit("completeLoading")
        })
         .catch(e => {
          console.error("Problem retrieving responses", e);
          this.$store.commit("completeLoading");
        })
      })
    },

    runExport() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        console.log("get download", `${process.env.VUE_APP_FORMAPI}/completedforms/download-file?formID=${this.formRequired}`, { "headers": { "Authorization": jwt }})
        axios
        .get(`${process.env.VUE_APP_FORMAPI}/completedforms/download-file?formID=${this.formRequired}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          if (response.data.success === true) {
            console.error("file download available", response.data)
            if (confirm("Your file has been generated.  Click OK to save")) {
              window.location.href=response.data.file
            }
          }
          else {
            console.error("fail download")
            alert("There has been an error creating your file.  If this problem persists please create a support ticket.")
          }
        })
        .catch(e => {
          console.error("Problem downloading form responses", e);
        })
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.response-message {

  &--alert {
    color: #ff5252;
    font-weight: bold;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background: transparent url('../../assets/alert.svg') center no-repeat;
      left: -20px;
      top: 2px;
      height: 16px;
      width: 16px;
    }
  }
}

.responses {
  ul {
    padding: 0;
  }
}

.response-filters {
  display: flex;
  align-items: center;
}

</style>