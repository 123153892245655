<template>
  <v-dialog
    class="playerofthematch-widget"
    v-model="dialog"
    width="580"
    lazy
  >

    <slot slot="activator"></slot>
      <v-form enctype="multipart/form-data" ref="singleFieldForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            class="widget__heading"
          >
            Player of the Match
          </v-card-title>

          <v-card-text>
            <p class="widget__description">Set up your player of the match vote</p>

            <label class="label" for="title">Title</label>
            <v-text-field
              id="title"
              outline
              :rules="[rules.required]"
              v-model="updatedData.content.title"
              solo
              flat
              class="text-field--lableless"
              validate-on-blur
            ></v-text-field>

            <label class="label" for="squad">Select Squad</label>
            <v-select
            :items="fetchedData.squads"
            item-text="name"
            item-value="id"
            id="squad"
            v-model="updatedData.content.squadID"
            outline
            required
            :rules="[rules.required]"
            validate-on-blur
            label="Select Squad"
            @change="seasons = []; updatedData.content.seasonID = null; getSeasons($event);"
            ></v-select>

            <label class="label" for="season">Select Season</label>
            <v-select
            :items="fetchedData.seasons"
            item-text="seasonName"
            item-value="seasonID"
            id="season"
            :disabled="!updatedData.content.squadID"
            v-model="updatedData.content.seasonID"
            outline
            required
            :rules="[rules.required]"
            validate-on-blur
            label="Select Season"
            @change="valid = true; getFixtures($event);"
            ></v-select>

            <label class="label" for="match">Select Match</label>
            <v-select
            :items="fetchedData.matches"
            item-text="matchLabel"
            item-value="matchID"
            id="match"
            :disabled="!updatedData.content.seasonID || !fetchedData.matches.length"
            v-model="updatedData.content.matchID"
            outline
            required
            :rules="[rules.required]"
            validate-on-blur
            :label="matchHTMLLabel"
            ></v-select>

            <v-checkbox
              v-model="updatedData.content.requireLogin"
              label="Require login?">
            </v-checkbox>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="accent"
              outline
              flat
              @click="validate()"
            >
              Post
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

  </v-dialog>
</template>

<script>
import axios from "axios"
import moment from "moment"

export default {
  name: "PlayerOfTheMatchWidget",

  props: {
    widgetData: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    dialog: false,
    valid: false,
    apiFixtures: `${process.env.VUE_APP_BASEURL}/fixtures`,
    rules: {
      required: value => !!value || "Required."
    },
    fixturesConfig: {},
    fetchedData: {
      squads: [],
      seasons: [],
      matches: []
    },
    matchHTMLLabel: "Select Match",
    updatedData: {
      content: {
        requireLogin: false,
        seasonID: null,
        squadID: null,
        matchID: null,
        title: "Player of the match"
      },
    }
  }),

  mounted() {
    if (this.widgetData) {
      this.updatedData = JSON.parse(JSON.stringify(this.widgetData))
    }

    this.getSquads();
  },

  methods: {
    updateData() {
      console.log("Update data:", this.updatedData);

      this.$emit("updatedData", this.updatedData);
      this.dialog = false;
    },

    validate() {
      if (this.updatedData.content.matchID === "") {
        this.updatedData.content.matchID = null
      }

      if (this.updatedData.content.title === "") {
        this.updatedData.content.title = null
      }

      this.errorMessage = ""
      if (this.$refs.singleFieldForm.validate()) {
        console.log("Validation passed");
        this.updateData();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },

    getSquads() {
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.apiFixtures}/opta/filters`, { "headers": { "Authorization": jwt }})
        .then(response => {
          this.fixturesConfig = response.data.body;

          this.fetchedData.squads = this.fixturesConfig.map(item => {
            return {name: item.teamName, id: item.teamID}
          });
          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving fixtures", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    getSeasons(e){
      this.updatedData.content.squadID = e;
      const season = this.fixturesConfig.filter(item => item.teamID === this.updatedData.content.squadID);

      if(season[0].seasons) {
        this.fetchedData.seasons = season[0].seasons;
        console.log(this.fetchedData.seasons);
      }
      else {
        this.fetchedData.seasons = "No season data has been found"
        console.log("No season data has been found");
      }
    },

    getFixtures(e){
      this.updatedData.content.seasonID = e;
      this.$store.commit("startLoading");
      this.matchHTMLLabel = "Loading...";

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.apiFixtures}/opta?clientMatches=true&teamID=${this.updatedData.content.squadID}&seasonID=${this.updatedData.content.seasonID}&pageSize=100`,  { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("Fetch fixtures response: ", response);
          if(response.data.totalDataCount) {
            this.fetchedData.matches = response.data.body.filter(match => {
              return !match.isCustom
            }).map(match => {
              return {
                matchID: match.matchID,
                matchLabel: match.teamData[0].teamName + " Vs. " + match.teamData[1].teamName + " - " + moment(match.kickOff).format("ddd, MMM Do YYYY, HH:mm") + " - " + match.matchID,
                matchTagLabel: match.teamData[0].teamName + " Vs. " + match.teamData[1].teamName
              }
            });
            this.$store.commit("completeLoading");
            this.matchHTMLLabel = "Select Match";
            console.log(this.fetchedData.matches);
          }
          else {
            this.matchHTMLLabel = "No matches found";
          }
        })
        .catch(e => {
          console.error("Problem retrieving fixtures", e);
          this.$store.commit("completeLoading");
        });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .widget {
    &__description {
      margin: 0 0 20px;
      color: #7B6B80;
      font-size: 14px;
    }
  }
</style>
