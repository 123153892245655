export default {
  data: [
    {
      sectionName: "Page Listing",
      sectionDesc: "This is for the page listing area on the homepage",
      model: "page_listing",
      fields: [
        {
          label: "Title",
          model: "page_listing_title",
          type: "Text"
        },
        {
          label: "View All link",
          model: "page_listing_link",
          type: "Text"
        },
        {
          label: "Link text",
          model: "page_listing_linktext",
          type: "Text"
        },
        {
          label: "Listing 1 - page ID",
          model: "page_listing_pageID_1",
          type: "Text"
        },
        {
          label: "Listing 2 - page ID",
          model: "page_listing_pageID_2",
          type: "Text"
        },
        {
          label: "Listing 3 - page ID",
          model: "page_listing_pageID_3",
          type: "Text"
        },
        {
          label: "Listing 4 - page ID",
          model: "page_listing_pageID_4",
          type: "Text"
        },
        {
          label: "Listing 5 - page ID",
          model: "page_listing_pageID_5",
          type: "Text"
        },
        {
          label: "Listing 6 - page ID",
          model: "page_listing_pageID_6",
          type: "Text"
        }
      ]
    },
    {
      sectionName: "Volunteer Area",
      sectionDesc: "This is for the title and supporting text for the volunteer promotional area on the homepage",
      model: "promo_area",
      fields: [
        {
          label: "Intro Text",
          model: "promo_area_promointro",
          type: "Text"
        },
        {
          label: "Heading",
          model: "promo_area_promotitle",
          type: "Text"
        }
      ]
    },
    {
      sectionName: "Volunteer Team - left",
      sectionDesc: "This is for the left hand side (top on smaller screens) volunteer promotional area on the homepage",
      model: "promo_left",
      fields: [
        {
          label: "Title",
          model: "promo_left_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_left_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_left_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_left_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_left_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Volunteer Team - right",
      sectionDesc: "This is for the right hand side (bottom on smaller screens) volunteer  promotional area on the homepage",
      model: "promo_right",
      fields: [
        {
          label: "Title",
          model: "promo_right_promotitle",
          type: "Text"
        },
        {
          label: "Description",
          model: "promo_right_promotext",
          type: "Text"
        },
        {
          label: "Promo link",
          model: "promo_right_promolink",
          type: "Text"
        },
        {
          label: "Promo link text",
          model: "promo_right_promolinktext",
          type: "Text"
        },
        {
          label: "Promo image",
          model: "promo_right_promoimage",
          type: "Image"
        }
      ]
    },
    {
      sectionName: "Homepage Stats",
      sectionDesc: "This is for the stats section towards the bottom of the homepage",
      model: "homepage_stats",
      fields: [
        {
          label: "Section Heading",
          model: "homepage_stats_heading",
          type: "Text"
        },
        {
          label: "Thinking Icon Stat",
          model: "homepage_stats_people_engaged",
          type: "Text"
        },
        {
          label: "Thinking Icon Label",
          model: "homepage_stats_people_engaged_label",
          type: "Text"
        },
        {
          label: "Calendar Icon Stat",
          model: "homepage_stats_sessions_hosted",
          type: "Text"
        },
        {
          label: "Calendar Icon Label",
          model: "homepage_stats_sessions_hosted_label",
          type: "Text"
        },
        {
          label: "Teacher Icon Stat",
          model: "homepage_stats_session_hours",
          type: "Text"
        },
        {
          label: "Teacher Icon Label",
          model: "homepage_stats_session_hours_label",
          type: "Text"
        },
        {
          label: "Donate Icon Stat",
          model: "homepage_stats_people_supported",
          type: "Text"
        },
        {
          label: "Donate Icon Label",
          model: "homepage_stats_people_supported_label",
          type: "Text"
        },
        {
          label: "CTA Link",
          model: "homepage_stats_cta_link",
          type: "Text"
        },
        {
          label: "CTA Link Text",
          model: "homepage_stats_cta_link_text",
          type: "Text"
        }
      ]
    },
  ]
}
