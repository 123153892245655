<template>
  <section class="news-management main-body">
   
    <template>
      <v-container fluid>
        <v-layout>
          <v-flex class="directory-listing"  pa-2 >
            <v-card
              color="purple lighten-5"
            >
              <v-card-text>
                <div @click="setCurrentDirectory('root')" class='home-directory' >
                  <v-icon right>folder</v-icon>&nbsp;Home
                </div>
                <div dense v-for="directory in directories" :key="directory.mediaDirectoryID">
                  <MediaTreeFolder :directory="directory" @selectDirectory="setCurrentDirectory($event)"/>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex style="max-width:80%;" pa-2>
            <v-card
              class="management-buttons"
              light
              color="purple lighten-5"
              v-if="includeManagementButtons"
            >
              <!-- <v-card-text>
               Current Directory: {{ currentMediaDirectory }}
              </v-card-text> -->

              <FileUploadModal @imageUploaded="addImage($event)" :imageUploadOnly="false">
                <v-btn class="image-editor__image-upload" color="accent" type="button" depressed>
                  <v-icon small>add</v-icon>
                  Upload File
                </v-btn>
              </FileUploadModal>

              <FolderAdd @addFolder="addFolder($event)">
                <span class="ma-2 select-folder-button v-btn v-btn--flat v-btn--outline v-btn--depressed theme--light accent--text" color="purple darken-4" dark>Add Child Folder</span>
              </FolderAdd>

              <FolderRename @renameFolder="renameFolder($event)">
                <span class="ma-2 select-folder-button v-btn v-btn--flat v-btn--outline v-btn--depressed theme--light accent--text" color="purple darken-4" dark>Rename Folder</span>
              </FolderRename>

              <v-btn class="ma-2" outline color="purple darken-4" dark @click="deleteFolder()">Delete Folder</v-btn>

              <FolderSelector v-show="readyToMoveItems.length" @moveItems="moveItems($event)">
                <span class="ma-2 v-btn purple theme--dark darken-4">Move Items</span>
              </FolderSelector>
              

            </v-card>

            <v-card light color="purple lighten-5" >
             <v-card-text v-if="totalCount > 0 && loadingItems===false">
                Showing {{items.length}} items of {{totalCount}} in this folder | Page: {{ pageNumber }}
              </v-card-text>
              <v-card-text v-else>
                <span v-if="loadingItems===false" class="no-items-message">
                  There are currently no media assets in this folder
                  </span>
              </v-card-text>
              <v-container>
                <v-layout wrap v-if="loadingItems === false">
                  <v-flex grow v-for="mediaItem in items" :key="mediaItem.mediaLibraryID" @click="mediaItemSelect(mediaItem)" >
                    <MediaItem
                      :class="{'is-selected' : mediaItem.mediaLibraryID === singleSelected}"
                      :mediaItem="mediaItem"
                      :includeManagementButtons="includeManagementButtons"
                      :singleSelection="singleSelection"
                      @itemRemoved="removeFromSelectArray($event)"
                      @itemSelected="addToSelectArray($event)"/>
                  </v-flex>
                </v-layout>

                <v-layout wrap v-else>
                  <div class="loading">
                  Retrieving media assets
                  </div>
                </v-layout>

                <div class="pagination" v-if="pageCount > 1">
                  <v-pagination
                    v-model="pageNumber"
                    :length="pageCount"
                    circle
                    color="accent">
                  </v-pagination>
                </div>
              
              </v-container>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </template>


  </section>
</template>
<script>
import axios from "axios"
import MiniHeader from "@/components/Library/MiniHeader"
import FileUploadModal from "@/components/Library/FileUploadModal"
import MediaItem from "@/components/Media/MediaItem.vue"
import FolderSelector from "@/components/Media/FolderSelector.vue"
import FolderAdd from "@/components/Media/FolderAdd.vue"
import FolderRename from "@/components/Media/FolderRename.vue"
//import MediaTreeFolder from '@/components/Media/MediaTreeFolder'
//import AppNavigation from '@/components/AppNavigation'
//import store from './store'

export default {
  name: "MediaManagement",

  beforeCreate: function () {
    this.$options.components.MediaTreeFolder = require("@/components/Media/MediaTreeFolder.vue").default
    this.$options.components.MediaItem = require("@/components/Media/MediaItem.vue").default
  },

  components: {
    MiniHeader,
    FileUploadModal,
    FolderSelector,
    FolderAdd,
    FolderRename
  },

  props: {
    includeManagementButtons: {
      type: Boolean,
      default: true
    },
    singleSelection: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    currentMediaDirectory: {
      get () {
        console.log("DIR", this.$store.state.currentMediaDirectory)
        return this.$store.state.currentMediaDirectory
      }
    }
  },
  watch: {
    currentMediaDirectory (mediaDirectoryID) {
      // Our fancy notification (2).
      //console.log('Directory changed to', mediaDirectoryID)
      this.listMediaItems(mediaDirectoryID)
      this.loadingItems = true
    },
    pageNumber(value, oldValue) {
      console.log("pageNumber changed", value);
      this.listMediaItems(this.$store.state.currentMediaDirectory)
    },
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/media`,
    totalCount: null,
    directories: [],
    items:[],
    pageNumber: 2,
    pageCount: 1,
    pageSize: 30,
    opened: [],
    loadingItems: false,
    readyToMoveItems: [],
    singleSelected: null
  }),


  mounted() {
    this.listMediaItems();
    this.listDirectories();
    this.initialiseLibraryState();
  },

  methods: {
    addImage() {
      this.listMediaItems(this.$store.state.currentMediaDirectory)
    },

    mediaItemSelect(mediaItem) {
      if (this.singleSelection) {
        console.log("mediaItem", mediaItem)
        this.singleSelected = mediaItem.mediaLibraryID
        this.$emit("selectedImage", this.singleSelected)
      }
    },

    setCurrentDirectory(mediaDirectoryID){
      console.log("setCurrentDirectory", mediaDirectoryID)
      this.pageCount = 1
      this.pageNumber = 1
      this.$store.commit("saveCurrentMediaDirectory", mediaDirectoryID)
    },

    initialiseLibraryState(){
      // load opened states
      if(localStorage.mediaLibraryOpened){
        this.opened =  localStorage.mediaLibraryOpened.split(",")
      } else {
        localStorage.mediaLibraryOpened = ""
      }
    },
    listDirectories(){  
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}/directories/`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("directories", response)
          this.directories = response.data.directories
        })
        .catch(e => {
          console.error("Problem retrieving pages", e);
          this.$store.commit("completeLoading");
        });
      })
    },
    
    listMediaItems(mediaDirectoryID){
      this.items = []

      if(mediaDirectoryID){
        console.log("loading media items for directory", this.currentMediaDirectory)
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          axios
          .get(`${this.api}/directory/${mediaDirectoryID}?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`, { "headers": { "Authorization": jwt }})
          .then(response => {
            this.items = response.data.mediaItems
            this.totalCount = response.data.totalCount
            this.pageCount = Math.ceil(response.data.totalCount / this.pageSize);
            this.loadingItems = false
          })
          .catch(e => {
            this.loadingItems = false
            console.error("Problem retrieving pages", e);
          });
        })
      }
    },

    addToSelectArray(payload) {
      console.log("Add to select array", payload)
      this.readyToMoveItems.push(payload)
    },

    removeFromSelectArray(payload) {
      console.log("Remove from select array", payload)
      const index = this.readyToMoveItems.indexOf(payload)
      this.readyToMoveItems.splice(-index, 1)
    },

    addFolder(folderName) {
      console.log("Add folder", folderName)

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/directory/add`,
          headers: { "Authorization": jwt },
          data: {
            directoryName: folderName,
            parentDirectoryID: this.$store.state.currentMediaDirectory
          }
        })
        .then(response => {
          console.log("Folder added:", response);
          this.$store.commit("saveCurrentMediaDirectory", response.data.body.mediaDirectoryID)
          this.listDirectories()
        })
        .catch(e => {
          console.error("Problem adding folder", e);
        });
      })
    },

    renameFolder(folderName) {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/directory/rename`,
          headers: { "Authorization": jwt },
          data: {
            directoryName: folderName,
            mediaDirectoryID: this.$store.state.currentMediaDirectory
          }
        })
        .then(response => {
          console.log("Folder renamed:", response);
          this.$store.commit("saveCurrentMediaDirectory", response.data.body.mediaDirectoryID)
          this.listDirectories()
        })
        .catch(e => {
          console.error("Problem adding folder", e);
        });
      })
    },

    deleteFolder() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "DELETE",
          url: `${this.api}/directory/${this.$store.state.currentMediaDirectory}`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Folder deleted:", response);
          this.$store.commit("saveCurrentMediaDirectory", "root")
          this.listDirectories()
        })
        .catch(e => {
          console.error("Problem moving items", e);
        });
      })
    },

    moveItems(directoryTarget) {
      console.log("directoryTarget", directoryTarget);
      console.log("readyToMoveItems", this.readyToMoveItems);

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/directory/move-items`,
          headers: { "Authorization": jwt },
          data: {
            mediaItems: this.readyToMoveItems,
            mediaDirectoryID: directoryTarget
          }
        })
        .then(response => {
          console.log("Items moved:", response);
          // this.listMediaItems(this.$store.state.currentMediaDirectory)
          this.readyToMoveItems = []
          this.$store.commit("saveCurrentMediaDirectory", directoryTarget)
        })
        .catch(e => {
          console.error("Problem moving items", e);
        });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .directory-listing{
    max-width: 300px;
    min-width: 300px;
  }
  .management-buttons {
    padding: 20px;
  }
  .directory-navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 320px;
  }
  .v-icon--right {
    margin-left: 1px; 
  
  }
  .items-listing{
    display: flex;
  }
  .is-selected {
    border: 2px solid purple;
  }

  .no-items-message{
    text-align: center;
    color: purple;
  }
  .loading {
     background-image: url('../../assets/loading.svg');
     color: purple;
     background-position: center;
     background-size: 150px 150px;
     width: 100%;
     min-height: 300px;
  }
  .home-directory{
    cursor: pointer;
  }
</style>
