var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"fixtures-list-widget",attrs:{"width":"580","lazy":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._t("default",null,{"slot":"activator"}),_c('v-card',[_c('v-card-title',{staticClass:"widget__heading"},[_vm._v(" Calendar ")]),_c('v-card-text',[_c('v-form',{ref:"calendarForm",attrs:{"enctype":"multipart/form-data","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('label',{staticClass:"label",attrs:{"for":"title"}},[_vm._v("Title")]),_c('v-text-field',{staticClass:"text-field--lableless",attrs:{"id":"title","outline":"","solo":"","flat":"","validate-on-blur":""},model:{value:(_vm.sectionTitle),callback:function ($$v) {_vm.sectionTitle=$$v},expression:"sectionTitle"}}),_c('label',{staticClass:"label",attrs:{"for":"teams"}},[_vm._v("Teams")]),_c('v-select',{attrs:{"items":_vm.teams,"item-text":"teamName","item-value":"teamID","id":"teams","outline":"","validate-on-blur":"","return-object":"","label":"Select Teams","multiple":""},model:{value:(_vm.selectedTeams),callback:function ($$v) {_vm.selectedTeams=$$v},expression:"selectedTeams"}}),_c('label',{staticClass:"label",attrs:{"for":"events"}},[_vm._v("Event Type")]),_c('v-select',{attrs:{"items":_vm.eventTypes,"item-text":"name","item-value":"value","id":"events","outline":"","required":"","rules":[_vm.rules.required],"return-object":"","validate-on-blur":"","label":"Select Event Types","multiple":""},model:{value:(_vm.selectedEvents),callback:function ($$v) {_vm.selectedEvents=$$v},expression:"selectedEvents"}}),(_vm.modules.default.Streamline)?[_c('label',{staticClass:"label",attrs:{"for":"events"}},[_vm._v("Stream Available")]),_c('v-select',{attrs:{"items":[
            { name: 'Show any calendar items', value: false},
            { name: 'Show only calendar items with streaming available', value: true},
          ],"item-text":"name","item-value":"value","id":"events","outline":"","validate-on-blur":"","label":"Select streaming availability"},model:{value:(_vm.hasStreamingOnly),callback:function ($$v) {_vm.hasStreamingOnly=$$v},expression:"hasStreamingOnly"}})]:_vm._e(),_c('label',{staticClass:"label",attrs:{"for":"userFilter"}},[_vm._v("Allow users to filter through events")]),_c('v-select',{attrs:{"items":[
          { name: 'Yes, let users have an option to filter through events', value: true},
          { name: 'No, always show all items', value: false},
        ],"item-text":"name","item-value":"value","id":"userFilter","outline":"","required":"","validate-on-blur":"","label":"User filtering permission"},model:{value:(_vm.allowUserFilter),callback:function ($$v) {_vm.allowUserFilter=$$v},expression:"allowUserFilter"}}),_c('v-btn',{attrs:{"color":"accent","outline":"","flat":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"disabled":!_vm.selectedEvents.length,"color":"accent","depressed":""},on:{"click":function($event){return _vm.postCalendar()}}},[_vm._v(" Add calendar ")])],2)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }