<template>
    <li class="item">
        <div
            v-if="match.attributes"
            class="item__wrapper"
        >
            <div class="item__info">
                <div class="item__top">
                    <span class="item__id">Match: {{ match.id }}</span> - <span class="item__date">
                        {{
                            match.attributes.kickOffDateUTC
                            | moment("DD/MM/YY - kk:mm")
                        }}
                    </span>

                </div>
                <h3
                    v-if="match.attributes.awayTeam && match.attributes.homeTeam"
                    class="item__title"
                >
                    {{
                        `${match.attributes.homeTeam.name} - ${match.attributes.awayTeam.name}`
                    }}
                </h3>

            </div>
            <div class="item__selection">
                <v-select
                    id="publish-state"
                    :items="[
                        { name: 'No', value: 'no' },
                        { name: 'Global', value: 'global' },
                        { name: 'International', value: 'international' },
                        { name: 'GB Only', value: 'gb_only' },
                    ]"
                    item-value="value"
                    item-text="name"
                    v-model="broadcastStatus"
                    label="Broadcast"
                    @change="handleButtonStatus"
                ></v-select>
                <template v-if="status === 'saving'">
                    <v-btn
                        class="save-status"
                        color="accent"
                        type="button"
                        depressed
                        @click="updateBroadcast"
                    >

                        <v-img
                            :src="require('../../assets/gamechanger-bolt.svg')"
                            class="saving__icon pulse"
                            contain
                            height="15"
                            width="15"
                        ></v-img>

                        Saving
                    </v-btn>
                </template>

                <template v-else-if="status === 'saved'">
                    <v-btn
                        class="save-status"
                        color="#5EA77E"
                        dark
                        type="button"
                        depressed
                        @click="updateBroadcast"
                    >
                        <v-icon
                            class="saving__icon"
                            small
                        >check</v-icon>

                        Saved
                    </v-btn>
                </template>

                <template v-else>
                    <v-btn
                        class="save-status"
                        :disabled="status == 'disabled'"
                        color="accent"
                        type="button"
                        depressed
                        @click="updateBroadcast"
                    >
                        {{ buttonText }}
                    </v-btn>
                </template>
                <p v-if="error">{{ error }}</p>
            </div>
        </div>
    </li>
</template>

<script>
import axios from 'axios'

export default {
    name: "Broadcastitem",

    props: {
        match: {
            type: Object,
            required: true,
        }
    },

    data: () => ({
        api: `${process.env.VUE_APP_MULTICLUBMATCHESAPI}`,
        broadcastStatus: "no",
        status: "disabled",
        buttonText: "save",
        error: null,
    }),

    created() {
        this.broadcastStatus = this.match.attributes.isBroadcast || "no"
    },

    methods: {
        handleButtonStatus() {
            this.status = "needsSaving"
        },

        updateBroadcast() {
            this.status = "saving"

            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: `${this.api}/matches/${this.match.id}`,
                    headers: { Authorization: jwt },
                    data: {
                        data: {
                            id: this.match.id,
                            type: this.match.type,
                            attributes: {
                                isBroadcast: this.broadcastStatus
                            }
                        },
                    }
                })
                    .then((response) => {
                        this.status = "saved"
                    }).catch((err) => {
                        this.error = "An error has occurred"
                        this.status = "needsSaving"
                    })
            });
        },
    }
}
</script>

<style scoped lang='scss'>
.item {
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 21px;
        border-radius: 8px;
        border: 1px solid var(--Grey-Border, #E0E3E8);
        background: var(--White, #FFF);
        padding: 11px;
        align-items: flex-start;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &__info {
        flex: 1;
    }

    &__selection {
        flex: 1;
    }

    &__id {
        margin-bottom: 10px;
        display: block;
        font-weight: bold;
    }

    &__top {
        display: flex;
        gap: 10px;

    }

    &__title {
        color: var(--efl-blue-opacity-60, rgba(14, 19, 47, 0.60));
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        margin-bottom: 23px;
    }

    &__date {
        color: var(--Grey-Flat, var(--Grey-Flat, #A8AAB7));
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;
    }

    &__info {}
}

.save-status {
    display: flex;
    margin-left: auto;
    text-transform: none;

    &--secondary {
        padding: 8px;
        font-size: 10px;
        opacity: 0.75;
    }
}

.saving__icon {
    margin: 0 10px 0 0;

    &.pulse {
        animation-duration: 0.6s;
        animation-name: pulse;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    30% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}
</style>
