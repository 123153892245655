

import { defineComponent, ref } from "vue";
import ListItem from "@/components/Library/ListItem.vue"
import { useDocuments } from "../../composables/useDocuments.ts"
import { DateTime } from "luxon";
export default defineComponent({
    name: "DocumentListItem",
    props: {
        name: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        id: {
            type: String,
            default: ""
        },
        lastModified: {
            required: true,
            type: String
        }
    },
    components: {
        ListItem
    },

    setup(props) {
        const formatedTime = ref(props.lastModified ? getDateTime(props.lastModified) : getCurrentTime())
        const { deleteDocument } = useDocuments();
        function goToItem() {
            setTimeout(() => {
                console.log(window.globalThis)
                //@ts-ignore
                window.globalThis.vueinstance.$router.push(
                    { name: "Documents Edit", params: { id: props.id } }
                )
            }, 400)

        }
        function deleteItem() {
            deleteDocument(props.id)
        }
        function getDateTime(receivedDate: string) {
            return DateTime.fromISO(receivedDate).toFormat("ff")
        }
        function getCurrentTime() {
            return DateTime.now()
        }
        return {
            goToItem,
            deleteItem,
            formatedTime
        }
    }
})
