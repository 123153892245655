<template>
  <div class="widget-details">
    <template v-if="!updatedData.widgetType">
      <p class="widget-info">This widget gives you a rich text editor to create blocks of content in your feed.</p>

      <v-btn class="accent" v-if="!updatedData.widgetType" @click="initialise()">Use this widget</v-btn>
    </template>

    <div class="widget-fieldset" v-else>
      <label class="label">Title</label>
      <v-text-field
        id="widgetData.title"
        solo
        flat
        v-model="updatedData.widgetData.title">
      </v-text-field>

      <template v-if="$route.query.isApp !== 'true'">
        <label class="label">Style</label>
        <v-select
          id="widgetData.style"
          :items="styleOptions"
          solo
          flat
          v-model="updatedData.widgetData.style">
        </v-select>
      </template>

      <tinymce-editor 
        v-model="updatedData.widgetData.wysiwyg"
        api-key="rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca"
        :init="{
        height: '300px',
        menubar: false,
        relative_urls: false,
        plugins: 'table link paste wordcount hr lists textpattern code',
        toolbar: $route.query.isApp !== 'true' ?
          'undo redo | styleselect | bold italic forecolor | \
          link blockquote table | \
          alignleft aligncenter alignright | \
          hr | \
          bullist numlist | removeformat | \ code' : '',
        resize: false,
        custom_colors: false,
        color_map: [
          '212121', 'Black',
          'aaaaaa', 'Gray',
          'FFFFFF', 'White'
        ],
        style_formats: [
          {title: 'Headers', items: [
            //{title: 'Header 1', format: 'h1'},
            {title: 'Header 2', format: 'h2'},
            {title: 'Header 3', format: 'h3'},
            {title: 'Header 4', format: 'h4'},
            {title: 'Header 5', format: 'h5'},
            {title: 'Header 6', format: 'h6'}
          ]},
          {title: 'Inline', items: [
            {title: 'Bold', icon: 'bold', format: 'bold'},
            {title: 'Italic', icon: 'italic', format: 'italic'},
            {title: 'Underline', icon: 'underline', format: 'underline'},
            //{title: 'Strikethrough', icon: 'strikethrough', format: 'strikethrough'},
            //{title: 'Superscript', icon: 'superscript', format: 'superscript'},
            //{title: 'Subscript', icon: 'subscript', format: 'subscript'},
            //{title: 'Code', icon: 'code', format: 'code'}
          ]},
          {title: 'Blocks', items: [
            {title: 'Paragraph', format: 'p'},
            {title: 'Blockquote', format: 'blockquote'},
            //{title: 'Div', format: 'div'},
            //{title: 'Pre', format: 'pre'}
          ]},
          {title: 'Alignment', items: [
            {title: 'Left', icon: 'alignleft', format: 'alignleft'},
            {title: 'Center', icon: 'aligncenter', format: 'aligncenter'},
            {title: 'Right', icon: 'alignright', format: 'alignright'},
            //{title: 'Justify', icon: 'alignjustify', format: 'alignjustify'}
          ]},
          {title: 'Small', selector: 'p', classes: 'small'}
        ],
        style_formats_merge: false,
        textpattern_patterns: [
          {start: '//button', replacement: '<a class=\'button \'>Button here</a>'}
        ],
        link_class_list: [
          {title: 'None', value: ''},
          {title: 'Primary on Dark', value: 'button button--primary-dark'},
          {title: 'Primary on Light', value: 'button button--primary-light'},
          {title: 'Ghost on Dark', value: 'button button--ghost-dark'},
          {title: 'Ghost on Light', value: 'button button--ghost-light'},
          {title: 'Label on Dark', value: 'button button--label-dark'},
          {title: 'Label on Light', value: 'button button--label-light'},
        ],
        block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6; Blockquote=blockquote'
      }"></tinymce-editor>

    </div>

  </div>

</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "VideoFaqs",

  components: {
     "tinymce-editor": Editor
  },

  watch: {
    updatedData: {
      handler: function () {
        this.updatedContent()
      },
      deep: true
    }
  },

  props: {
    editData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    styleOptions: [{text: "Light Mode", value: "light"}, {text: "Dark Mode", value: "dark"}],
    updatedData: {
      title: null,
      content: null,
      style: null, // Background colour - light || dark
    }
  }),

  mounted() {
    this.updatedData = JSON.parse(JSON.stringify(this.editData))
  },

  methods: {
    initialise() {
      this.updatedData.widgetType = "ContentWidget"
      this.updatedData.widgetName = "Content"
      this.updatedData.widgetID = this.$uuid.v1()
      this.updatedData.widgetData = {
        title: null,
        wysiwyg: null,
        style: null, // Background colour - light || dark
      }
      this.$emit("initialised")
    },

    updatedContent() {
      this.$emit("updatedWidget", this.updatedData)
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
